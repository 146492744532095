import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useSearchParams} from 'react-router-dom';
import {Row} from 'react-table';
import {useSelector} from 'react-redux';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';
import DateUtils from 'utils/dateUtils';

import useToggle from 'hooks/useToggle';
import NW2Button from 'view/components/NW2Button';
import BookingRequestDetails from '../BookingDetails/BookingRequestDetails';
import BookingDetails from '../BookingDetails/BookingDetails';
import NW2Loader from 'view/components/NW2Loader/NW2Loader';
import useInterval from '../../../../hooks/useInterval';
import {getOrderChangesById} from 'store/bookingsSupplier/actions';

import {useQuery} from 'hooks/useQuery';
import {useTableData} from 'view/supplier/hooks/useTableData';
import {OfferCreate} from 'view/venue/Offer/components/OfferCreate/OfferCreate';
import {useRfpHandlers} from '../../hooks/useRfpHandlers';
import {RequestBookings} from './Requests/RequestBookings';
import {BookingsListRoomsTable} from './BookingsListRoomsTable';
import {bookingOverviewAmountSearchTabs} from '../BookingsList/helpers';
import {
  setBookingOrderChanges,
  setCancellationPolicy,
} from 'store/bookingsSupplier/reducer';
import {useActions} from 'hooks/useActions';
import {getCurrencySymbol} from 'utils/stringUtils';
import {useOfferHandlers} from 'view/venue/Offer/hooks/useOfferHandlers';
import {useRequestPendingList} from 'view/venue/Offer/hooks/useRequestPendingList';
import {checkOfferRequestRedirectStatus} from 'view/venue/Offer/helpers';

import {ERoomType} from 'types/dto/ERoomType.type';
import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {
  EBookingOrders,
  EBookingOrdersSortBy,
  EOrderShowStatus,
} from 'types/bookingOrders';
import {EOfferStatus, ERequestStatus} from 'types/offer';
import {
  Actions,
  BtnShowMore,
  Container,
  Header,
  HeaderTitle,
  LoadingBox,
  Wrapper,
} from './BookingsListRooms.styles';
import {getRequestsCount} from 'store/offers/apiActions';
import {setTopNotification} from 'store/app/appSlice';
import {ETopNotificationId} from 'types/app';
import {getPackages} from 'store/packages/apiActions';

export type TFetchSupplierOrders = {
  searchTabParam?: EBookingOrders;
  sortByParam?: EBookingOrdersSortBy;
  limit?: number;
};

const PAGE_LIMIT = 10;

function BookingsListRooms() {
  const dispatch = useAppDispatch();

  const {isRTC, orderNumber} = useQuery();

  useEffect(() => {
    if (isRTC && orderNumber) {
      dispatch(getOrderChangesById(String(orderNumber)));
    }
  }, [dispatch, isRTC, orderNumber]);

  const {
    getAmountOfBookings,
    getSupplierOrders,
    getSupplierOrderDetailsById,
    getOfferRequestById,
    getOfferById,
    setSupplierBookingDetails,
    setOfferRequestedDetails,
    getRequests,
    getOffers,
    getOfferRequestByToken,
  } = useActions();
  const [searchParams, setSearchParams] = useSearchParams();
  const {token} = useQuery();

  const [showAllData, setShowAllData] = useState(false);
  const [alreadyVisitedDetails, setAlreadyVisitedDetails] = useState<string[]>(
    [],
  );

  const [isDetailsMultiDay, setDetailsMultiDay] = useState(false);
  const [isOffer, setOffer] = useState(false);
  const [isOfferRequestDetails, setOfferRequestDetails] = useState(false);
  const venueId = useAppSelector(
    ({venueDetails}) => venueDetails.supplierVenueId,
  );

  const upcomingOrders = useAppSelector(
    ({bookingsSupplier}) => bookingsSupplier[EBookingOrders.UPCOMING_ORDERS],
  );
  const rfpOrders = useAppSelector(
    ({bookingsSupplier}) => bookingsSupplier[EBookingOrders.RFP_ORDERS],
  );
  const historyOrders = useAppSelector(
    ({bookingsSupplier}) => bookingsSupplier[EBookingOrders.ORDERS_HISTORY],
  );

  const allRequests = useAppSelector(({offers}) => offers.requests);
  const allOffers = useAppSelector(({offers}) => offers.offers);

  const isBookingsLoading = useAppSelector(
    (state) => state.bookingsSupplier.isLoading,
  );

  const [isDataLoading, setDataLoading] = useState(true);

  const currency: string = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.currency`),
  );

  const venueTimeZone: string = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.location.timeZone`),
  );

  const currentRequestByToken = useAppSelector(
    ({offers}) => offers.supplierOfferRequest,
  );
  const packages = useAppSelector(({packages}) => packages.packages);

  const {id, checkIn, checkOut, status} = currentRequestByToken;

  const spaceType = ERoomType.MEETING_ROOM;

  const {pendingRequestsList} = useRequestPendingList();

  const rfpOrdersList = rfpOrders[spaceType] || [];

  const totalUpcomingRecords = upcomingOrders.totalRecords;
  const totalHistoryRecords = historyOrders.totalRecords;

  const currencySymbol = getCurrencySymbol(currency);

  const [searchTab, setSearchTab] = useState<EBookingOrders>(
    EBookingOrders.UPCOMING_ORDERS,
  );

  const fetchSupplierOrders = useCallback(
    ({
      searchTabParam = searchTab,
      sortByParam = EBookingOrdersSortBy.DATE_END,
      limit = PAGE_LIMIT,
    }: TFetchSupplierOrders) => {
      return getSupplierOrders({
        page: 0,
        offerPage: 0,
        limit,
        searchTab: searchTabParam,
        venueId,
        sortBy: sortByParam,
        spaceType,
      });
    },
    [venueId, searchTab, spaceType, getSupplierOrders],
  );

  const getOrders = useCallback(() => {
    setDataLoading(true);

    const getOrders = fetchSupplierOrders({});
    const getOrdersRfp = fetchSupplierOrders({
      searchTabParam: EBookingOrders.RFP_ORDERS,
      limit: 1000,
    });

    const getAllRequests = getRequests(venueId, [
      ...(searchTab === EBookingOrders.ORDERS_HISTORY
        ? [
            ERequestStatus.REQUEST_DECLINED,
            ERequestStatus.REQUEST_CANCELED,
            ERequestStatus.REQUEST_EXPIRED,
            ERequestStatus.REQUEST_PENDING,
          ]
        : [ERequestStatus.REQUEST_PENDING]),
    ]);

    const getAllOffers = getOffers(venueId, [
      ...(searchTab === EBookingOrders.ORDERS_HISTORY
        ? [EOfferStatus.OFFER_DECLINED, EOfferStatus.OFFER_ACCEPTING_EXPIRED]
        : [EOfferStatus.OFFER_PENDING]),
    ]);

    Promise.all([getOrders, getOrdersRfp, getAllRequests, getAllOffers]).then(
      () => {
        setDataLoading(false);
      },
    );

    getAmountOfBookings({...bookingOverviewAmountSearchTabs, venueId});
  }, [
    fetchSupplierOrders,
    getAmountOfBookings,
    getOffers,
    getRequests,
    searchTab,
    venueId,
  ]);

  useEffect(() => {
    if (!venueId) return;

    // initial data
    getOrders();
    if (token) {
      getOfferRequestByToken(token as string);
    }
  }, [getOfferRequestByToken, getOrders, token, venueId]);

  useInterval(() => {
    if (venueId) {
      // ToDo interval
      // getting data every 15 min
      getOrders();
    }
  }, 900000);

  // Table data
  const {
    updatedNotPendingRequests,
    updatedHistoryOffers,
    updatedUpcomingOffers,
  } = useTableData(allRequests, allOffers);

  const filteredNotPendingRequests = updatedNotPendingRequests.filter(
    (item) =>
      !(
        (item.status === ERequestStatus.REQUEST_DECLINED ||
          item.status === ERequestStatus.REQUEST_EXPIRED) &&
        item.offerId
      ),
  );

  const historyOffersAndRequests = useMemo(
    () => [...filteredNotPendingRequests, ...updatedHistoryOffers],
    [updatedHistoryOffers, filteredNotPendingRequests],
  );

  const data = useMemo(() => {
    switch (searchTab) {
      case EBookingOrders.UPCOMING_ORDERS: {
        if (upcomingOrders[spaceType]?.length) {
          return [...upcomingOrders[spaceType], ...updatedUpcomingOffers];
        }
        return [...updatedUpcomingOffers];
      }

      case EBookingOrders.ORDERS_HISTORY: {
        if (historyOrders[spaceType]?.length) {
          return [...historyOrders[spaceType], ...historyOffersAndRequests];
        }
        return [...historyOffersAndRequests];
      }

      case EBookingOrders.RFP_ORDERS:
      default:
        return [];
    }
  }, [
    historyOffersAndRequests,
    historyOrders,
    searchTab,
    spaceType,
    upcomingOrders,
    updatedUpcomingOffers,
  ]);

  const tableData = useMemo(() => {
    const sortedByDate = _orderBy(data, [
      function (o) {
        return o.showStatus === EOrderShowStatus.UNREAD ? -1 : 0;
      },
      'checkOutDate',
    ]);

    if (showAllData) return sortedByDate;

    return sortedByDate.slice(0, PAGE_LIMIT);
  }, [data, showAllData]);

  // action buttons
  const onShowUpcoming = () => {
    setSearchTab(EBookingOrders.UPCOMING_ORDERS);
    setShowAllData(false);
  };

  const onShowHistory = () => {
    setSearchTab(EBookingOrders.ORDERS_HISTORY);
    setShowAllData(false);
  };

  const onShowMore = useCallback(() => {
    setShowAllData(true);
    const getLimit = (): number => {
      switch (searchTab) {
        case EBookingOrders.UPCOMING_ORDERS:
          return totalUpcomingRecords;
        case EBookingOrders.ORDERS_HISTORY:
          return totalHistoryRecords;
        default:
          return PAGE_LIMIT;
      }
    };

    const limit = getLimit();

    if (limit !== 0) {
      fetchSupplierOrders({limit});
    }
  }, [
    fetchSupplierOrders,
    totalHistoryRecords,
    totalUpcomingRecords,
    searchTab,
  ]);

  const isUpcomingShowMore =
    searchTab === EBookingOrders.UPCOMING_ORDERS &&
    totalUpcomingRecords + updatedUpcomingOffers.length > PAGE_LIMIT;
  const isHistoryShowMore =
    searchTab === EBookingOrders.ORDERS_HISTORY &&
    totalHistoryRecords + historyOffersAndRequests.length > PAGE_LIMIT;
  const isShowMoreVisible =
    tableData.length === PAGE_LIMIT &&
    (isUpcomingShowMore || isHistoryShowMore);

  const handleShowDetails = useCallback(
    ({
      orderId,
      isMultiDay,
      isRequest,
      isOffer,
      orderNumber,
      isStatusUnread,
    }: {
      orderId?: string;
      orderNumber?: string;
      isMultiDay: boolean;
      isRequest?: boolean;
      isOffer?: boolean;
      isStatusUnread?: boolean;
    }) => {
      if (isRequest && orderId) {
        setOfferRequestDetails(true);
        getOfferRequestById(orderId);
        setDetailsMultiDay(isMultiDay);
        return;
      }

      if (isOffer && orderId) {
        setOfferRequestDetails(true);
        getOfferById({id: orderId});
        setDetailsMultiDay(isMultiDay);
        return;
      }

      if (orderId && orderNumber)
        getSupplierOrderDetailsById({orderNumber, isStatusUnread, orderId});

      setDetailsMultiDay(isMultiDay);
      if (!packages.length) {
        dispatch(getPackages(Number(venueId)));
      }
    },
    [
      getOfferById,
      getOfferRequestById,
      getSupplierOrderDetailsById,
      dispatch,
      venueId,
      packages,
    ],
  );

  const onShowOrderRequestDetails = useCallback(
    (
        orderId: string,
        isMultiDay: boolean,
        orderNumber: string,
        isRequest?: boolean,
      ) =>
      () => {
        setAlreadyVisitedDetails((prevState) => [...prevState, orderId]);
        handleShowDetails({orderId, isMultiDay, isRequest, orderNumber});
      },
    [handleShowDetails],
  );

  const onShowBookingChangesDetails = (
    orderNumber: string,
    orderId: string,
    isMultiDay: boolean,
  ) => {
    handleShowDetails({orderId, orderNumber, isMultiDay});
  };

  const onShowOrderDetails = useCallback(
    (row: Row<Record<string, never>>) => () => {
      const {
        isRequest,
        isOffer,
        orderNumber,
        orderId,
        showStatus,
        checkInDate,
        checkOutDate,
      } = row.original;

      const isMultiDay = !DateUtils.isSameDay(checkInDate, checkOutDate);
      setOffer(isOffer);
      handleShowDetails({
        orderNumber,
        isMultiDay,
        isRequest,
        isOffer,
        orderId,
        isStatusUnread: showStatus === EOrderShowStatus.UNREAD,
      });
    },
    [handleShowDetails],
  );

  const onCloseOrderDetails = useCallback(() => {
    setOffer(false);
    setOfferRequestDetails(false);
    setDetailsMultiDay(false);
    setSupplierBookingDetails(null);
    dispatch(setBookingOrderChanges([]));
    setOfferRequestedDetails({});
    dispatch(setCancellationPolicy(''));
    searchParams.delete('token');
    searchParams.delete('isRTC');
    searchParams.delete('orderNumber');
    setSearchParams(searchParams);

    dispatch(
      getRequestsCount(venueId, (count) => {
        if (count) {
          dispatch(
            // immediately update top notification
            setTopNotification({
              id: ETopNotificationId.PENDING_REQUEST,
            }),
          );
        }
      }),
    );
  }, [
    setSupplierBookingDetails,
    setOfferRequestedDetails,
    dispatch,
    searchParams,
    setSearchParams,
    venueId,
  ]);

  const rfpHandlers = useRfpHandlers({
    orders: rfpOrders[spaceType],
    getOrders,
    onCloseOrderDetails,
  });

  const offerHandlers = useOfferHandlers({
    getOffers: getOrders,
    onCloseOrderDetails,
  });

  const changeHandlers = {rfpHandlers, offerHandlers};

  // create offer
  const [isCreateOfferShowed, toggleShowCreateOffer] = useToggle(false);
  const onShowOfferCreate = useCallback(
    (requestId: string, hasData?: boolean) => (e?: SyntheticEvent) => {
      e?.stopPropagation();
      toggleShowCreateOffer();

      if (!hasData) {
        getOfferRequestById(requestId);
      }
    },
    [getOfferRequestById, toggleShowCreateOffer],
  );

  // if request was already declined or cancelled and need open it from email
  useEffect(() => {
    if (token && currentRequestByToken) {
      const isMultiDay = !DateUtils.isSameDay(checkIn, checkOut);

      if (checkOfferRequestRedirectStatus(status)) {
        handleShowDetails({
          orderId: id,
          isMultiDay,
          isRequest: true,
          isOffer: false,
        });
      }
    }
  }, [
    checkIn,
    checkOut,
    currentRequestByToken,
    handleShowDetails,
    id,
    status,
    token,
  ]);

  const isShowRequestBookings =
    pendingRequestsList?.length > 0 || rfpOrdersList.length > 0;

  return (
    <Wrapper>
      <Header>
        <HeaderTitle>Meeting Bookings Overview</HeaderTitle>
      </Header>

      {isDataLoading ? (
        <LoadingBox>
          <NW2Loader />
        </LoadingBox>
      ) : (
        <div>
          <Container>
            {isShowRequestBookings && (
              <RequestBookings
                pendingRequests={pendingRequestsList}
                rfpOrders={rfpOrdersList}
                alreadyVisitedDetails={alreadyVisitedDetails}
                onShowOrderDetails={onShowOrderRequestDetails}
                changeHandlers={changeHandlers}
                onShowOfferCreate={onShowOfferCreate}
                isOffer={isOffer}
                onShowBookingChangesDetails={onShowBookingChangesDetails}
              />
            )}

            <Actions>
              <NW2Button
                onClick={onShowUpcoming}
                size='small'
                buttonType='badge'
                active={searchTab === EBookingOrders.UPCOMING_ORDERS}
                disabled={isDataLoading}
              >
                Upcoming
              </NW2Button>

              <NW2Button
                onClick={onShowHistory}
                size='small'
                buttonType='badge'
                active={searchTab === EBookingOrders.ORDERS_HISTORY}
                disabled={isDataLoading}
              >
                History
              </NW2Button>
            </Actions>

            <BookingsListRoomsTable
              currencySymbol={currencySymbol}
              isLoading={isBookingsLoading}
              onShowOrderDetails={onShowOrderDetails}
              data={tableData}
            />

            {isOfferRequestDetails ? (
              <BookingRequestDetails
                isMultiDay={isDetailsMultiDay}
                venueTimeZone={venueTimeZone}
                onCloseOrderDetails={onCloseOrderDetails}
                onShowDeclineModal={offerHandlers.onShowDeclineModal}
                isOffer={isOffer}
                onShowOfferCreate={onShowOfferCreate}
                changeHandlers={changeHandlers}
                onShowOrderDetails={onShowOrderRequestDetails}
              />
            ) : (
              <BookingDetails
                isMultiDay={isDetailsMultiDay}
                venueTimeZone={venueTimeZone}
                onCloseOrderDetails={onCloseOrderDetails}
                getOrders={fetchSupplierOrders}
                onShowConfirmModal={rfpHandlers.onShowConfirmModal}
                onShowDeclineModal={rfpHandlers.onShowDeclineModal}
                isRTC={Boolean(isRTC)}
              />
            )}

            {isShowMoreVisible && (
              <BtnShowMore
                onClick={onShowMore}
                disabled={isBookingsLoading}
                size='small'
              >
                show more bookings
              </BtnShowMore>
            )}
          </Container>

          {isCreateOfferShowed && (
            <OfferCreate
              onToggleShow={toggleShowCreateOffer}
              onCloseOrderDetails={onCloseOrderDetails}
            />
          )}
        </div>
      )}
    </Wrapper>
  );
}

export default BookingsListRooms;
