import styled from 'styled-components';

import {Link} from 'view/components/Typography';

import {
  lgBp,
  mdBp,
  offsetDef,
  offsetXLg,
  pagePadding,
  whiteColor,
  fontWeightBold,
  offsetXXXLg,
} from 'constants/styleVars';

const VenueOffersContainer = styled.div`
  display: grid;
  grid-gap: ${offsetXXXLg};
`;

const Header = styled.div`
  display: grid;

  @media (min-width: ${mdBp}px) {
    grid-gap: calc(${pagePadding}*2);
  }

  @media (min-width: ${lgBp}px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: ${pagePadding};
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${whiteColor};
  margin-right: ${offsetXLg};
`;

const AddressBlock = styled.div<{isAlternative?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${offsetDef};

  > button {
    display: none;
  }

  @media (min-width: ${mdBp}px) {
    > button {
      display: ${({isAlternative}) => (isAlternative ? 'none' : 'inline-flex')};
      min-width: 120px;
    }
  }

  @media (min-width: ${lgBp}px) {
    justify-content: flex-start;
  }
`;

const ShareChangeButtonBlock = styled.div`
  display: grid;
  grid-gap: ${pagePadding};
  grid-template-columns: 1fr 1fr;
  align-content: center;

  @media (min-width: ${mdBp}px) {
    display: none;
  }
`;

const VenueOfferTitle = styled.div`
  word-break: break-word;
  font-size: 34px;
  line-height: 34px;
  color: ${whiteColor};
  font-weight: ${fontWeightBold};
`;

export {
  ShareChangeButtonBlock,
  AddressBlock,
  StyledLink,
  Header,
  VenueOffersContainer,
  VenueOfferTitle,
};
