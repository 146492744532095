import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {ICustomerBillingAddress, IUser} from 'types/dto/IUser.types';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import {
  EAppEnvironment,
  EAppTheme,
  ENW2MultiModalActiveComponentId,
  IMultiModalActiveComponent,
  INW2MultiModalActiveComponent,
  ISharePopup,
  ITopNotification,
  TAuthorizationPopups,
  TDeviceType,
} from 'types/app';
import {IAppReducer} from './types';
import {LoginUserData} from 'types/account';

const initUserData: IUser = {
  id: 0,
  email: '',
  role: EUserRoleCognito.ROLE_GUEST,
  firstName: '',
  lastName: '',
  companyName: '',
  phone: '',
  billingAddress: [],
  defaultBillingAddress: undefined,
  isLoggedIntoSystem: false,
};

const initialState: IAppReducer = {
  environment: null,
  token: '',
  corporateAccountId: null,
  authorizationPopups: {
    loginTitle: '',
    loginDescription: '',
    openLoginPopup: false,
    openRegisterPopup: false,
    openPasswordPopup: false,
    openForgotPasswordPopup: false,
    setCustomerBillingAddressPopup: false,
    isPaymentMethodsPopup: false,
  },
  openSidebarMenu: false,
  // data used by data sharing popup
  sharePopupProps: {
    id: 'EMPTY',
  },
  user: initUserData,
  navbarHeight: 0,
  headerFixed: false,
  headerCollapsed: false,
  multiModalActiveComponent: {id: ''}, // Passing id as empty string hides the modal.
  nw2MultiModalActiveComponent: {id: ENW2MultiModalActiveComponentId.EMPTY}, // Passing id as empty string hides the modal.
  topNotifications: [],
  theme: EAppTheme.THEME_DARK,
  deviceType: {
    width: 0,
    height: 0,
    isMobile: false,
    isTablet: false,
    isDesktop: false,
  },
  searchFocusedInput: '', // used by header search section inputs
  // initialLocation is used to store some locations, pathname or search in case of possible resets
  initialLocation: {
    pathname: '',
    search: '',
  },
  isGoogleMapLoaded: false,
  // Variable to show some specific functionality only on dev env
  isDevEnvironment:
    window.location.host === process.env.REACT_APP_DEVELOPMENT_DOMAIN ||
    process.env.NODE_ENV === 'development',
  isAuthConfigured: false,
  redirectLink: '',
  isBTCApp: false,
  isRegistrationAction: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppEnvironment(state, action: PayloadAction<EAppEnvironment>) {
      state.environment = action.payload;
    },
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    setCognitoUserRole(state, action: PayloadAction<EUserRoleCognito>) {
      state.user.role = action.payload;
    },
    setUser(state, action: PayloadAction<LoginUserData>) {
      const userData = action.payload;
      state.user.email = userData.email;
      state.user.isLoggedIntoSystem = userData.isLoggedIntoSystem;
      state.user.expiresAt = userData.expiresAt;

      if (userData.role) {
        state.user.role = userData.role;
      }
    },
    setUserCustomer(state, action: PayloadAction<Partial<IUser>>) {
      const customer = action.payload;
      if (!customer) return;
      state.user = {...state.user, ...customer};
    },
    setCustomerBillingAddresses(
      state,
      action: PayloadAction<ICustomerBillingAddress[]>,
    ) {
      state.user.billingAddress = action.payload;
    },
    setCustomerDefaultBillingAddress(
      state,
      action: PayloadAction<ICustomerBillingAddress | undefined>,
    ) {
      if (action.payload) {
        state.user.defaultBillingAddress = action.payload;
      } else {
        state.user.defaultBillingAddress = state.user.billingAddress.find(
          (item) => item.isDefault,
        );
      }
    },
    setOpenLoginPopup(state, action: PayloadAction<TAuthorizationPopups>) {
      const {
        openLoginPopup,
        loginTitle = 'Welcome back!',
        loginDescription = 'Log back into your account below.',
      } = action.payload;
      state.authorizationPopups.openLoginPopup = openLoginPopup;
      state.authorizationPopups.loginTitle = loginTitle;
      state.authorizationPopups.loginDescription = loginDescription;
    },
    setOpenRegisterPopup(state, action: PayloadAction<TAuthorizationPopups>) {
      const {openRegisterPopup} = action.payload;
      state.authorizationPopups.openRegisterPopup = openRegisterPopup;
    },
    setOpenPasswordPopup(state, action: PayloadAction<TAuthorizationPopups>) {
      const {openPasswordPopup} = action.payload;
      state.authorizationPopups.openPasswordPopup = openPasswordPopup;
    },
    setOpenForgotPasswordPopup(
      state,
      action: PayloadAction<TAuthorizationPopups>,
    ) {
      const {openForgotPasswordPopup} = action.payload;
      state.authorizationPopups.openForgotPasswordPopup =
        openForgotPasswordPopup;
    },
    setCustomerBillingAddressPopup(
      state,
      action: PayloadAction<TAuthorizationPopups>,
    ) {
      const {setCustomerBillingAddressPopup} = action.payload;
      state.authorizationPopups.setCustomerBillingAddressPopup =
        setCustomerBillingAddressPopup;
    },
    setIsPaymentMethodsPopup(
      state,
      action: PayloadAction<TAuthorizationPopups>,
    ) {
      const {isPaymentMethodsPopup} = action.payload;
      state.authorizationPopups.isPaymentMethodsPopup = isPaymentMethodsPopup;
    },
    setOpenSidebarMenu(state, action: PayloadAction<boolean>) {
      state.openSidebarMenu = action.payload;
    },
    openSharePopup(state, action: PayloadAction<ISharePopup>) {
      state.sharePopupProps = action.payload;
    },
    closeSharePopup(state) {
      state.sharePopupProps = {id: 'EMPTY'};
    },
    setNavbarHeight(state, action: PayloadAction<number>) {
      state.navbarHeight = action.payload;
    },
    setHeaderFixed(state, action: PayloadAction<boolean>) {
      state.headerFixed = action.payload;
    },
    setHeaderCollapsed(state, action: PayloadAction<boolean>) {
      state.headerCollapsed = action.payload;
    },
    setMultiModalComponent(
      state,
      action: PayloadAction<IMultiModalActiveComponent>,
    ) {
      state.multiModalActiveComponent = action.payload;
    },
    setNW2MultiModalComponent(
      state,
      action: PayloadAction<INW2MultiModalActiveComponent>,
    ) {
      state.nw2MultiModalActiveComponent = action.payload;
    },
    setCorporateAccountId(state, action: PayloadAction<number | null>) {
      state.corporateAccountId = action.payload;
    },
    setTopNotification(state, action: PayloadAction<ITopNotification>) {
      if (
        !state.topNotifications.some((item) => item?.id === action.payload.id)
      ) {
        state.topNotifications.push(action.payload);
      }
    },
    setAppTheme(state, action: PayloadAction<EAppTheme>) {
      state.theme = action.payload;
    },
    setDeviceType(state, action: PayloadAction<TDeviceType>) {
      state.deviceType = action.payload;
    },
    setIsGoogleMapLoaded(state, action: PayloadAction<boolean>) {
      state.isGoogleMapLoaded = action.payload;
    },
    onSignOutUser: (state) => {
      state.token = '';
      state.corporateAccountId = null;
      state.user = initUserData;
      state.topNotifications = [];
    },
    setExpiresAt(state, action: PayloadAction<number>) {
      state.user.expiresAt = action.payload;
    },
    setIsAuthConfigured(state, action: PayloadAction<boolean>) {
      state.isAuthConfigured = action.payload;
    },
    setRedirectLink(state, action: PayloadAction<string>) {
      state.redirectLink = action.payload;
    },
    setIsBTCApp(state, action: PayloadAction<boolean>) {
      state.isBTCApp = action.payload;
    },
    setIsRegistrationAction(state, action: PayloadAction<boolean>) {
      state.isRegistrationAction = action.payload;
    },
  },
});

export const {
  setAppEnvironment,
  setToken,
  setCognitoUserRole,
  setUser,
  setUserCustomer,
  setCustomerBillingAddresses,
  setOpenLoginPopup,
  setOpenRegisterPopup,
  setOpenPasswordPopup,
  setOpenForgotPasswordPopup,
  setCustomerBillingAddressPopup,
  setIsPaymentMethodsPopup,
  setOpenSidebarMenu,
  openSharePopup,
  closeSharePopup,
  setNavbarHeight,
  setHeaderFixed,
  setHeaderCollapsed,
  setMultiModalComponent,
  setNW2MultiModalComponent,
  setCorporateAccountId,
  setTopNotification,
  setAppTheme,
  setDeviceType,
  setIsGoogleMapLoaded,
  onSignOutUser,
  setExpiresAt,
  setIsAuthConfigured,
  setCustomerDefaultBillingAddress,
  setRedirectLink,
  setIsBTCApp,
  setIsRegistrationAction,
} = appSlice.actions;

export default appSlice.reducer;
