import React, {ReactNode, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import {DebouncedFunc} from 'lodash';

import BusinessInformationView from '../BusinessInformationView';
import {useAppDispatch} from 'store/hooks';

import {EditPaymentForm} from '../components/EditPaymentForm';
import {EVenueProfilePaymentSections} from 'view/venue/NW2VenueProfile/types';
import {LegalEntityTypeCaption, IVenue} from 'types/venue';
import {TSupplierBusinessInfo} from 'view/components/NW2Forms/NW2SupplierLegalDetailsForm/types';
import {
  getLegalContact,
  getPeopleOfInterest,
  setInitialPeopleOfInterestValues,
} from '../helpers';
import {
  clearAdyenOnBoardingErrorList,
  setLoading,
  updatePublicVenueById,
} from 'store/venue/actions';
import {
  TMissingBusinessInfo,
  TMissingCompanyDetailsFields,
} from 'types/supplier';
import {PATH_TO_REDUCER_GLOBAL_VENUE_DATA} from 'constants/venue';

export type TFormProps = {
  onSubmit: (values: TSupplierBusinessInfo) => void;
  initialValues: Record<string, unknown>;
  editForm: ReactNode;
  viewForm: ReactNode;
  isEditorShowed: boolean;
  onEditorShow: () => void;
  onEditorHide: () => void;
  isFormDirty: boolean;
  isFormSending: boolean;
  processFormFields: DebouncedFunc<({dirtyFields}: any) => void>;
  missingCompanyDetailsFields: TMissingCompanyDetailsFields;
};

type TVenuePayoutForms = Record<
  string,
  {
    viewForm: ReactNode;
    editForm: ReactNode;
  }
>;

const usePayout = (
  venue: IVenue,
  sectionName: EVenueProfilePaymentSections,
): TFormProps => {
  const dispatch = useAppDispatch();
  const [isEditorShowed, setEditorShowed] = useState(false);
  const [isFormDirty, setFormDirty] = useState(false);

  const isLoading = useSelector((state) => _get(state, 'venue.loading'));

  const missingBusinessInfo: TMissingBusinessInfo = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_GLOBAL_VENUE_DATA}.missingBusinessInfo`),
  );

  const adyenOnBoardingErrorList = useSelector((state) =>
    _get(
      state,
      `${PATH_TO_REDUCER_GLOBAL_VENUE_DATA}.adyenOnBoardingErrorList`,
    ),
  );

  const hasAdyenOnBoardingErrors = adyenOnBoardingErrorList.length > 0;

  const forms: TVenuePayoutForms = {
    [EVenueProfilePaymentSections.BUSINESS_INFORMATION]: {
      viewForm: <BusinessInformationView legalContact={venue.legalContact} />,
      editForm: <EditPaymentForm />,
    },
  };

  const {legalContact, peopleOfInterest, id, payAccStatus} = venue;
  const initialPeopleOfInterest = useMemo(
    () => setInitialPeopleOfInterestValues(peopleOfInterest),
    [peopleOfInterest],
  );

  const initialValues = useMemo(
    () => ({
      legalContact: {
        ...legalContact,
        legalPhone: `+${legalContact.legalPhone.phoneCountryCode} ${legalContact.legalPhone.phone}`,
        legalEntityType: LegalEntityTypeCaption[legalContact.legalEntityType],
      },
      localLegalEntityType:
        LegalEntityTypeCaption[legalContact.legalEntityType],
      ...initialPeopleOfInterest,
    }),
    [legalContact, initialPeopleOfInterest],
  );

  const onEditorShow = () => {
    setEditorShowed(true);
  };
  const onEditorHide = () => {
    setEditorShowed(false);
  };

  const onSubmit = (formValues: TSupplierBusinessInfo) => {
    const legalContact = getLegalContact(formValues);
    const peopleOfInterest = getPeopleOfInterest(formValues);
    const apiPayload = {legalContact, peopleOfInterest, payAccStatus, id};

    if (hasAdyenOnBoardingErrors) {
      dispatch(clearAdyenOnBoardingErrorList());
    }
    dispatch(setLoading(true));
    dispatch(updatePublicVenueById(apiPayload, onEditorHide, sectionName));
  };

  const processFormFields = _debounce(({dirtyFields}) => {
    setFormDirty(!!Object.keys(dirtyFields).length);
  }, 150);

  return {
    onSubmit,
    initialValues,
    isEditorShowed:
      !venue.accountHolderCode &&
      (isEditorShowed ||
        missingBusinessInfo.hasMissingFields ||
        hasAdyenOnBoardingErrors),
    onEditorShow,
    onEditorHide,
    isFormDirty,
    isFormSending: isLoading,
    processFormFields,
    editForm: forms[sectionName].editForm,
    viewForm: forms[sectionName].viewForm,
    missingCompanyDetailsFields: missingBusinessInfo.missingFields,
  };
};

export default usePayout;
