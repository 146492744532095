import React, {useEffect, useMemo, useRef, KeyboardEvent} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Form} from 'react-final-form';

import {useAppDispatch, useAppSelector} from 'store/hooks';
import _get from 'lodash/get';
import {Spin} from 'antd';

import NW2Button from 'view/components/NW2Button/NW2Button';
import Icon from 'view/components/Icon';
import BookPreviewComponents from 'view/venue/NW2BookingPreview/components/BookPreviewComponents';
import {NW2BookingTotal} from './components/NW2BookingTotal';
import {useIntersectionObserver} from 'hooks/useIntersectionObserver';
import {ProcessingPaymentBlock} from 'view/components/AdyenPayment/components';

import {findCoverImage} from 'utils/helpers';
import {getAddressStringFromLocation} from 'utils/stringUtils';
import {useBookingInitialData} from './hooks/useBookingInitialData';
import useFlutterWebView from 'utils/bridgeBTC/useFlutterWebView';
import {useLoggedInUser} from 'hooks/useLoggedInUser';
import useSearchData from 'view/venue/hooks/useSearchData';
import useOrderSubmit from 'view/venue/hooks/useOrderSubmit';
import LocalStorageService from 'infra/common/localStorage.service';

import {TImage} from 'types/app';
import {
  TGroupedDeskUnitsByDay,
  TGroupedUnitsByDay,
} from 'types/dto/IBooking.types';
import {NW2Container} from 'view/mainLanding/MainLanding.styles';
import {
  StyledHeader,
  StyledMain,
  StyledTitle,
  StyledWrapper,
} from './NW2BookingPreview.styles';
import {useVenueDetailsData} from '../hooks/useVenueDetailsData';
import {useDeskBookingInitialData} from './hooks/useDeskBookingInitialData';
import {ERoomType} from 'types/dto/ERoomType.type';
import {useMultiDayVenueDetailsData} from '../NW2VenueDetails/useMultiDayVenueDetails';
import {useQuery} from 'hooks/useQuery';
import useParamsVenueId from '../hooks/useParamsVenueId';
import {
  cleanCustomerSelectedPackages,
  updateCustomerSelectedPackages,
} from 'store/customer/customerSlice';
import {addPackageBookingPreview} from 'store/venues/actions';
import {getPackages} from 'store/packages/apiActions';

const NW2BookingPreview = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const locationData = useLocation();
  const {paramsVenueId} = useParamsVenueId();

  const {roomType = ''} = useQuery();

  const orderNumber = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.orderNumber,
  );

  const details = useAppSelector(({venueDetails}) => venueDetails.details);

  const packages = useAppSelector(({packages}) => packages.packages);

  const isMeetingRoom = roomType === ERoomType.MEETING_ROOM;

  useMultiDayVenueDetailsData(paramsVenueId);

  const {venueDetails, isRfp} = useVenueDetailsData();

  const {currency, documents, location, name, accommodationId, id} =
    venueDetails;

  const venueAddress = getAddressStringFromLocation(location);
  const venueCoverImage = useMemo(
    () => findCoverImage((documents || []) as TImage[]),
    [documents],
  );

  useEffect(() => {
    if (!orderNumber) return;
    const query: any = new URLSearchParams(locationData.search);

    query.set('venueId', String(accommodationId)); // todo check is it need
    navigate({
      pathname: `/booking/${orderNumber}`,
      search: query.toString(),
    });
  }, [dispatch, orderNumber, navigate, accommodationId, locationData.search]);

  const {formInitialValues, onGoToVenueDetails} = useBookingInitialData({
    id: paramsVenueId,
    isMeetingRoom,
  });

  const isInstant =
    venueDetails.type === 'INSTANT' ||
    venueDetails.type === 'INSTANT_CORPORATE';

  const {
    isRfp: isDeskRfp,
    formInitialValues: formDeskInitialValues,
    onGoToVenueDetails: onGoToDeskVenueDetails,
  } = useDeskBookingInitialData({
    id: paramsVenueId,
    isOffer: false,
    isMeetingRoom,
  });

  // // handle sticky total behavior
  const headerRef = useRef(null);
  const headerEntry = useIntersectionObserver(headerRef, {});
  const isTotalSticky: boolean | undefined = !headerEntry?.isIntersecting;

  const {isBTCApp} = useFlutterWebView();
  const {onFormSubmit} = useOrderSubmit();

  const {isLoggedInUser} = useLoggedInUser();
  const previewUnits: TGroupedUnitsByDay[] = useSelector((state) =>
    _get(state, `venuesReducer.bookingPreview.bookingMaskPreviewUnits`, []),
  );

  const previewDeskUnits: TGroupedDeskUnitsByDay[] = useSelector((state) =>
    _get(state, `venuesReducer.bookingPreview.deskPreviewUnits`, []),
  );
  const {querySearchData} = useSearchData();

  useEffect(() => {
    if (!isLoggedInUser && !previewUnits.length) {
      const guestBookingPreviewData = {
        previewUnits: isMeetingRoom ? previewUnits : previewDeskUnits,
        venueId: paramsVenueId,
        querySearchData,
      };

      LocalStorageService.setByKey(
        'bookingPreviewData',
        JSON.stringify(guestBookingPreviewData),
      );
    }
  }, [
    previewUnits,
    isLoggedInUser,
    paramsVenueId,
    querySearchData,
    isMeetingRoom,
    previewDeskUnits,
  ]);

  // TODO: REFACTOR
  useEffect(() => {
    if (details?.accommodationId) {
      const id = Number(details.accommodationId);
      dispatch(getPackages(id)); // todo check packages
      dispatch(cleanCustomerSelectedPackages());
    }
  }, [details?.accommodationId, dispatch]);

  // Applying package from multidetails
  useEffect(() => {
    if (
      details &&
      packages.length &&
      previewUnits.length &&
      details.days.some((d) => d.package)
    ) {
      details.days.forEach((day, index) => {
        if (day.package) {
          const unitToApplyPackage = day.rooms.find(
            (room) => room.position === day.package?.position,
          )?.units[0]?.unitId;

          const packageToApply = packages.find(
            (p) => p.id === day.package?.packageId,
          );

          if (unitToApplyPackage && packageToApply) {
            dispatch(
              updateCustomerSelectedPackages({
                newPackage: packageToApply,
                dayIndex: index,
                unitId: unitToApplyPackage,
              }),
            );

            dispatch(addPackageBookingPreview());
          }
        }
      });
    }
  }, [details, dispatch, packages, previewUnits.length]);

  useEffect(() => {
    return () => {
      LocalStorageService.removeItemByKey('bookingPreviewData');
    };
  }, []);

  // prevent keypress enter
  const onKeyPress = (e: KeyboardEvent) => {
    e.key === 'Enter' && e.preventDefault();
  };

  // TODO: task NWRK-2780, NWRK-2781
  // const onFormChange = (formData: FormState<any>) => {
  //   const adyenCardAction = LocalStorageService.getByKey('AdyenCardAction');
  //   if (
  //     adyenCardAction === EAdyenCardAction.PAYMENT ||
  //     adyenCardAction === EAdyenCardAction.ADD
  //   )
  //     return;
  //   LocalStorageService.setByKey(
  //     'userData',
  //     JSON.stringify({
  //       ...formData.values,
  //     }),
  //   );
  // };

  return (
    <Spin spinning={!accommodationId && !id}>
      <StyledWrapper>
        <NW2Container>
          <StyledHeader ref={headerRef}>
            <NW2Button
              buttonType='secondary'
              minimized
              size='small'
              onClick={
                isMeetingRoom ? onGoToVenueDetails : onGoToDeskVenueDetails
              }
              icon={<Icon transparent icon='NW2_MOVE_BACK' />}
            />
            <StyledTitle>Confirm & Book</StyledTitle>
          </StyledHeader>

          <Form
            onSubmit={onFormSubmit}
            initialValues={
              isMeetingRoom ? formInitialValues : formDeskInitialValues
            }
            keepDirtyOnReinitialize
            render={({handleSubmit}) => (
              <form onSubmit={handleSubmit} onKeyDown={onKeyPress}>
                <StyledMain>
                  <div>
                    <ProcessingPaymentBlock />
                    <BookPreviewComponents
                      isShowLoginModal={!isLoggedInUser && !isBTCApp}
                    />
                  </div>
                  <NW2BookingTotal
                    currency={currency}
                    venueName={name}
                    venueCoverImage={venueCoverImage}
                    isRfp={!isInstant && (isMeetingRoom ? isRfp : isDeskRfp)}
                    isTotalSticky={isTotalSticky}
                    venueAddress={venueAddress}
                  />
                </StyledMain>

                {/*TODO: task NWRK-2780, NWRK-2781*/}
                {/* do not remove because we may need it in some nearest future */}
                {/*<FormSpy*/}
                {/*  subscription={{values: true}}*/}
                {/*  onChange={onFormChange}*/}
                {/*/>*/}
              </form>
            )}
          />
        </NW2Container>
      </StyledWrapper>
    </Spin>
  );
};

export default NW2BookingPreview;
