import React from 'react';
import DateUtils, {DateFormats} from 'utils/dateUtils';
import Space from '../Space';

import {
  TGroupedDeskUnitsByDay,
  TGroupedUnitsByDay,
} from 'types/dto/IBooking.types';
import {offsetXXLg} from 'constants/styleVars';
import {StyledLeftSideItem, SubtitleWrapper} from '../Space.styles';

type TProps = {
  previewUnits: (TGroupedUnitsByDay | TGroupedDeskUnitsByDay)[];
};

const {getHoursAndMinutes, getDateWithoutHours} = DateUtils;

const BookingLeftSideSpaces = ({previewUnits}: TProps) => {
  return (
    <>
      {previewUnits.map(
        (
          {
            checkInDate,
            selectedPackage,
            checkOutDate,
            units,
            foodAndBeverage,
            bedrooms,
          },
          index,
        ) => {
          const dateText = getDateWithoutHours(
            checkInDate,
            DateFormats['1 Jan 2023'],
          );
          const duration = DateUtils.getTotalHours(checkInDate, checkOutDate);
          const timeRangeText = `(${getHoursAndMinutes(
            checkInDate,
          )} - ${getHoursAndMinutes(checkOutDate)})`;

          const title = (
            <>
              <span>{`DAY ${index + 1}`}</span>
              <SubtitleWrapper>
                <div>{dateText}</div>
                <div>{timeRangeText}</div>
              </SubtitleWrapper>
            </>
          );

          const children = (
            <Space
              isMultiDay
              units={units}
              dayIndex={index}
              previewFoodAndBeverage={foodAndBeverage}
              bedrooms={bedrooms}
              duration={duration}
              packagesPreview={selectedPackage}
            />
          );

          return (
            <StyledLeftSideItem
              key={index}
              item={{
                title,
                children,
              }}
              isHidden={false}
              paddingTop={index === 0 ? `${offsetXXLg} !important` : undefined}
              paddingBottom={
                index === previewUnits.length - 1
                  ? undefined
                  : `${offsetXXLg} !important`
              }
            />
          );
        },
      )}
    </>
  );
};

export default BookingLeftSideSpaces;
