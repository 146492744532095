import React, {memo} from 'react';

import {RequestUnitExtras} from '../OfferRequestDetails/RequestUnitExtras';

import {getFilteredExtrasByType} from 'utils/venueUtils';

import {EResourcesType, IExtrasOption} from 'types/dto/IExtras.type';
import {IOfferUnitExtra} from 'types/offer';
import {ExtrasTitle} from 'view/supplier/Bookings/BookingDetails/BookingDetails.styles';

interface IProps {
  unitExtras: IOfferUnitExtra[];
  extrasOption?: IExtrasOption[];
  margin?: string;
  currencySymbol?: string;
  isOffer?: boolean;
  isRequestWithUnitId?: boolean;
}
export const AccommodationExtras = memo(
  ({
    unitExtras,
    extrasOption = [],
    currencySymbol,
    isOffer,
    isRequestWithUnitId,
    margin,
  }: IProps) => {
    const filteredBedrooms = getFilteredExtrasByType(
      unitExtras,
      EResourcesType.BEDROOM,
      extrasOption,
    );

    if (!filteredBedrooms.length) return null;

    return (
      <>
        {/* TODO: check with accommodation period */}
        <ExtrasTitle margin={margin}>Accommodation</ExtrasTitle>
        {/* <TitleContainer>
            <DatePeriod margin={`${offsetXLg} 0 0`}>
              {DateUtils.getDatePeriod(date, 1)}
            </DatePeriod>
          </TitleContainer> */}

        <RequestUnitExtras
          data={filteredBedrooms}
          isOffer={isOffer}
          currencySymbol={currencySymbol}
          isRequestWithUnitId={isRequestWithUnitId}
          isBedroom
        />
      </>
    );
  },
);
