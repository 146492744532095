import {useCallback} from 'react';

import {IDay, IDayOfWeek} from 'types/dto/IDay.type';
import {
  getDayWorkingHours,
  isDayActive,
  isFullDayAccess,
} from 'utils/operationalHoursUtils';

export function useOpeningHoursValue({workDays}: {workDays: IDay[]}) {
  const getValue = useCallback(
    (day: IDayOfWeek) => {
      const active = isDayActive(workDays, day);
      const fullDayAccess = isFullDayAccess(workDays, day);

      return getDayWorkingHours({day, active, fullDayAccess, workDays});
    },
    [workDays],
  );

  return [getValue];
}
