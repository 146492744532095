import styled from 'styled-components';

import {
  borderRadiusDef,
  borderRadiusMLg,
  blackColor,
  NW2Gray200Color,
  NW2Gray900Color,
  whiteColor,
  fontSizeLg,
  fontSizeMd,
  fontSizeSm,
  fontWeightBold,
  fontWeightExtraBold,
  lineHeightLg,
  lineHeightMd,
  lineHeightXL,
  offsetDef,
  offsetSm,
  offsetXLg,
  offsetXSm,
  offsetXXLg,
  offsetXXSm,
  offsetXXXSm,
} from 'constants/styleVars';

export const UnitCardWrapper = styled.div`
  margin-bottom: ${offsetXXLg};
`;

export const UnitCardContainer = styled.div`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  color: ${NW2Gray900Color};
  background-color: ${whiteColor};
  padding: ${offsetXLg};
  border: 1px solid ${NW2Gray200Color};
  border-radius: ${borderRadiusMLg};
`;

export const UnitCardDayName = styled.div`
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
  font-weight: ${fontWeightExtraBold};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-bottom: ${offsetXSm};
`;

export const UnitCardDay = styled.div`
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
  color: ${NW2Gray900Color};
  margin-bottom: ${offsetXLg};
`;

export const UnitCardTitle = styled.div<{mb?: number}>`
  font-size: ${fontSizeLg};
  line-height: ${lineHeightXL};
  font-weight: ${fontWeightBold};
  color: ${blackColor};
  margin-bottom: ${({mb}) => (mb ? mb + 'px' : '')};
`;

export const UnitCardHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${offsetXLg};
  margin-bottom: ${offsetDef};
`;

export const UnitCardHeaderImg = styled.div`
  height: 150px;

  img {
    width: 100%;
    height: 100%;
    border-radius: ${borderRadiusDef};
    object-fit: cover;
  }
`;

export const UnitCardItem = styled.div<{mb?: number}>`
  margin-bottom: ${({mb}) => (mb ? mb + 'px' : offsetXXSm)};
`;

export const UnitCardFooter = styled.div`
  border-top: 1px solid ${NW2Gray200Color};
  padding-top: ${offsetXLg};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UnitCardExtraPrice = styled.span`
  text-align: right;
  white-space: nowrap;
`;

export const UnitCardRentalPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AmenitiesBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${offsetXLg};
  grid-row-gap: ${offsetXXSm};
`;

export const AmenityItem = styled.div`
  display: flex;
  gap: ${offsetSm};
  line-height: 1.2;
`;

export const AmenityItemIcon = styled.div`
  padding-top: ${offsetXXXSm};
`;

export const SubTotalPrice = styled.div`
  font-size: ${fontSizeLg};
  line-height: ${lineHeightXL};
  font-weight: ${fontWeightBold};
`;
