import React, {useState} from 'react';
import {DateTime} from 'luxon';
import {useAppSelector} from 'store/hooks';

import AddMeetingRequest from './AddMeetingRequest/AddMeetingRequest';
import AddFoodBeverageRequest from './AddFoodBeverageRequest/AddFoodBeverageRequest';
import NW2NumericInput from 'view/components/NW2NumericButtonInput/NW2NumericButtonInput';

import {getDayIndexesWithZeroRooms} from './utils';
import {getDayTimeRangeText} from '../utils';
import {
  AddDayButtonWrapper,
  DateLabel,
  DateWrap,
  ItemsWrapper,
  ItemWrapper,
  LeftSideItemBlock,
  ListWrapper,
  ParticipantsTitle,
  ParticipantsWrap,
  SubsectionTitle,
  TwoColumnWrapper,
} from './MeetingRequestsList.styles';
import DateUtils, {DateFormats, getDateTime} from 'utils/dateUtils';
import {AddAccommodationRequest} from './AddAccommodationRequest/AddAccommodationRequest';
import {IUseMeetingRoomData} from '../hooks/useMeetingRoomData';
import NW2Button from 'view/components/NW2Button';
import {getISOString} from 'utils/dateUtils';
import {AddPrePostAccommodationRequest} from './AddAccommodationRequest/AddPrePostAccommodationRequest';
import {useFeatureToggle} from 'hooks/useFeatureToggle';
import {EFeatureToggles} from 'constants/featureToggles';
import {EMPTY_ARRAY, MAX_EXTRAS_NUMBER} from 'constants/app';

interface IProps extends IUseMeetingRoomData {
  firstDayDate?: string;
  lastDayDate?: string;
}

const MeetingRequestList = ({
  meetingRequestData,
  firstDayDate,
  lastDayDate,
  addRoom,
  deleteRoom,
  deleteAllRoom,
  deleteFoodBeverage,
  setDefaultRoomList,
  setSelectedEquipment,
  setSelectedFoodBeverage,
  setIsSectionExpanded,
  addAccommodation,
  removeAccommodation,
  deleteDay,
  maxParticipants,
  bedroomsCatering,
  handleSetBedroomsCatering,
  removeBedroomsCatering,
  addDay,
  preMeetDaysCount,
  updateParticipants,
}: IProps) => {
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  const [isBedroomsEnabled] = useFeatureToggle(EFeatureToggles.SEARCH_BEDROOMS);

  const [editedFoodBeverageDaysIndex, setEditedFoodBeverageDaysIndex] =
    useState<number[]>(EMPTY_ARRAY);
  const [editedAccommodationDaysIndex, setEditedAccommodationDaysIndex] =
    useState<(number | undefined)[]>(EMPTY_ARRAY);

  const dateFormat = DateFormats['1 Jan, 2023'];
  const getPrevNextDayButton = (date: DateTime, onClickHandler: () => void) => {
    if (!isBedroomsEnabled) return null;

    const stringDate = date.toFormat(dateFormat);
    const disabled = DateUtils.isBefore(
      getISOString(date.startOf('day')),
      getISOString(DateTime.now().startOf('day')),
    );

    return (
      <TwoColumnWrapper>
        <div>{stringDate}</div>
        <AddDayButtonWrapper>
          <NW2Button
            size='small'
            buttonType='tertiary'
            onClick={onClickHandler}
            disabled={disabled}
          >
            Add accommodation
          </NW2Button>
        </AddDayButtonWrapper>
      </TwoColumnWrapper>
    );
  };

  const addPrevDayButton = getPrevNextDayButton(
    getDateTime(firstDayDate).plus({days: -1}),
    addDay(true),
  );
  const addNextDayButton = getPrevNextDayButton(
    getDateTime(lastDayDate).plus({days: 1}),
    addDay(false),
  );

  const dayIndexesWithNoZeroRooms = meetingRequestData.reduce(
    (prev, current, index) => (current.rooms.length ? [...prev, index] : prev),
    [] as number[],
  );

  const daysLengthWithNoZeroRooms = dayIndexesWithNoZeroRooms.length;

  return (
    <ListWrapper isMobile={isMobile}>
      {addPrevDayButton}
      {meetingRequestData.map(
        (
          {
            startDate,
            rooms,
            accommodationData,
            foodBeverageData,
            isSectionExpanded,
            isPreMeet,
            isPostMeet,
            participants: dayParticipants,
          },
          index,
          allDays,
        ) => {
          const isRooms = !!rooms.length;
          const isFoodBeverageEnabled = !!foodBeverageData && isRooms;
          const isAccommodationEnabled = !!accommodationData && isRooms;
          const isCustomerChangedFoodAndBeverage =
            editedFoodBeverageDaysIndex.includes(index);
          const isCustomerChangedAccommodation =
            editedAccommodationDaysIndex.includes(index);

          const isPreOrPostMeet = isPreMeet || isPostMeet;

          const dateText = DateTime.fromISO(startDate).toFormat(dateFormat);
          const timeRangeText = isRooms ? getDayTimeRangeText(rooms) : null;

          const isFirstDay = !index;
          const isLastDay = allDays.length - 1 === index;

          const isAtBorderOfRange = getDayIndexesWithZeroRooms(
            index,
            dayIndexesWithNoZeroRooms,
          );

          const isAroundBorderOfRange = getDayIndexesWithZeroRooms(
            index,
            dayIndexesWithNoZeroRooms,
            true,
          );

          const isRemovableAll =
            allDays.length === daysLengthWithNoZeroRooms
              ? (isFirstDay || isLastDay) && allDays.length > 2
              : daysLengthWithNoZeroRooms > 2
              ? isAroundBorderOfRange
              : isAtBorderOfRange;

          //remove days rules
          const meetDaysCount = allDays.filter(
            ({isPostMeet, isPreMeet}) => !isPostMeet && !isPreMeet,
          ).length;

          const firstOrLastIndex = index === 0 || index === allDays.length - 1;

          const isDayToDelete =
            meetDaysCount > 1
              ? firstOrLastIndex
              : isPreOrPostMeet && firstOrLastIndex;
          //

          if (isPreOrPostMeet) {
            return (
              <ItemsWrapper key={dateText}>
                <TwoColumnWrapper>
                  <LeftSideItemBlock>
                    <div>
                      <SubsectionTitle>
                        {isPreMeet ? 'PRE-ARRIVAL' : 'POST-EVENT'}
                      </SubsectionTitle>
                      <DateLabel>{dateText}</DateLabel>
                    </div>
                    {timeRangeText && <div>({timeRangeText})</div>}
                  </LeftSideItemBlock>

                  <ItemWrapper>
                    <AddPrePostAccommodationRequest
                      accommodationData={accommodationData}
                      isSectionExpanded={isSectionExpanded}
                      setIsSectionExpanded={setIsSectionExpanded(index)}
                      meetingRooms={rooms}
                      maxParticipants={maxParticipants}
                      isPreOrPostMeet={isPreOrPostMeet}
                      addAccommodation={addAccommodation(index, true)}
                      removeAccommodation={removeAccommodation(index)}
                      deleteDay={deleteDay(index, isDayToDelete)}
                      isDayToDelete={isDayToDelete}
                      bedroomsCatering={bedroomsCatering}
                      handleSetBedroomsCatering={handleSetBedroomsCatering}
                      removeBedroomsCatering={removeBedroomsCatering}
                      date={startDate}
                    />
                  </ItemWrapper>
                </TwoColumnWrapper>
              </ItemsWrapper>
            );
          }

          return (
            <ItemsWrapper key={dateText}>
              <TwoColumnWrapper>
                <LeftSideItemBlock>
                  <DateWrap>
                    <div>
                      <SubsectionTitle>
                        DAY {index + 1 - preMeetDaysCount}
                      </SubsectionTitle>
                      <DateLabel>{dateText}</DateLabel>
                    </div>
                    {timeRangeText && <div>({timeRangeText})</div>}
                  </DateWrap>

                  <ParticipantsWrap>
                    <ParticipantsTitle>Participants</ParticipantsTitle>
                    <NW2NumericInput
                      minValue={maxParticipants}
                      maxValue={MAX_EXTRAS_NUMBER}
                      initialValue={dayParticipants}
                      onChange={(value) =>
                        updateParticipants({
                          dayIndex: index,
                          participants: value,
                          isFoodBeverageEnabled,
                          isCustomerChangedFoodAndBeverage,
                          isAccommodationEnabled,
                          isCustomerChangedAccommodation,
                        })
                      }
                      shouldResetCurrentNumber
                    />
                  </ParticipantsWrap>
                </LeftSideItemBlock>

                <ItemWrapper>
                  <AddMeetingRequest
                    meetingRooms={rooms}
                    isSectionExpanded={isSectionExpanded}
                    setIsSectionExpanded={setIsSectionExpanded(index)}
                    isRemovableAll={isRemovableAll}
                    addMeetingRoom={addRoom(index)}
                    deleteDay={deleteDay(index, isDayToDelete)}
                    isDayToDelete={isDayToDelete}
                    deleteFoodBeverage={deleteFoodBeverage(index)}
                    deleteAllMeetingRoom={deleteAllRoom(index)}
                    deleteMeetingRoom={deleteRoom(index)}
                    setDefaultRoomList={setDefaultRoomList(index)}
                    setSelectedEquipment={setSelectedEquipment(index)}
                    isMobile={isMobile}
                    isAccommodationEnabled={isAccommodationEnabled}
                    isCustomerChangedFoodAndBeverage={
                      isCustomerChangedFoodAndBeverage
                    }
                    isCustomerChangedAccommodation={
                      isCustomerChangedAccommodation
                    }
                  />

                  <AddFoodBeverageRequest
                    meetingRooms={rooms}
                    deleteFoodBeverage={deleteFoodBeverage(index)}
                    foodBeverageData={foodBeverageData}
                    setSelectedFoodBeverage={setSelectedFoodBeverage(index)}
                    setEditedFoodBeverageDaysIndex={
                      setEditedFoodBeverageDaysIndex
                    }
                    dayIndex={index}
                  />

                  {isBedroomsEnabled && (
                    <AddAccommodationRequest
                      accommodationData={accommodationData}
                      meetingRooms={rooms}
                      addAccommodation={addAccommodation(index)}
                      removeAccommodation={removeAccommodation(index)}
                      bedroomsCatering={bedroomsCatering}
                      handleSetBedroomsCatering={handleSetBedroomsCatering}
                      removeBedroomsCatering={removeBedroomsCatering}
                      date={startDate}
                      setEditedAccommodationDaysIndex={
                        setEditedAccommodationDaysIndex
                      }
                      dayIndex={index}
                    />
                  )}
                </ItemWrapper>
              </TwoColumnWrapper>
            </ItemsWrapper>
          );
        },
      )}
      {addNextDayButton}
    </ListWrapper>
  );
};

export default MeetingRequestList;
