import React from 'react';
import {IUnit} from 'types/dto/IUnit.types';
import {useAppSelector} from 'store/hooks';

import NW2SpaceDetailsDescription from './NW2SpaceDetailsDescription';
import CustomList from 'view/components/CustomList';

import {UNIT_NAME_TYPE_MAP} from 'constants/venue';
import {ERoomType} from 'types/dto/ERoomType.type';
import {pluralize} from 'utils/helpers';
import {getFloorName} from 'utils/getFloorName';
import {getCurrencySymbol} from 'utils/stringUtils';
import {dimensionsConverter, formatFloatWithOneNumber} from 'utils/venueUtils';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {EAmenityType} from 'types/amenities';
import {ERoomSchema} from 'types/venue';
import {
  ServiceTitle,
  Container,
  UnitName,
  ServicesContainer,
  StyledNW2SpaceDetailsSwitch,
} from '../NW2SpaceDetails.styles';
import {offsetXXSm} from 'styles/configs/offset';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';

type TProps = {
  unit: IUnit;
};

export function NW2SpaceDetailsContent({unit}: TProps) {
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  const isMultiRooms = useAppSelector(
    ({search}) => search.searchCriteria.multiRooms,
  );

  const {venueDetails} = useVenueDetailsData();

  const accommodationType = venueDetails.accommodationType;
  const currency = venueDetails.currency;

  if (!unit) return null;

  const {
    name,
    amenities,
    capacity,
    floor,
    roomType,
    type,
    priceDay,
    priceHour,
    unitCapacities = null,
    unitDimensions = {area: 0, ceilingHeight: 0},
  } = unit;

  const currencySymbol = getCurrencySymbol(currency);

  const getAmenitiesListByType = (typeToCompare: EAmenityType) =>
    amenities
      ?.filter((item) => item.type === typeToCompare)
      .map((item) => {
        return {value: item.name};
      });

  const deskAccessibilityList = getAmenitiesListByType(
    EAmenityType.CORPORATE_OFFICE_WORK_DESK_ACCESSIBILITY,
  );

  const roomAccessibilityList = getAmenitiesListByType(
    EAmenityType.CORPORATE_OFFICE_MEETING_ROOM_ACCESSIBILITY,
  );

  const deskEquippedList = getAmenitiesListByType(
    EAmenityType.CORPORATE_OFFICE_WORK_DESK_EQUIPMENT,
  );

  const roomEquippedList = getAmenitiesListByType(
    EAmenityType.CORPORATE_OFFICE_MEETING_ROOM_EQUIPMENT,
  );

  const deskConditionsList = getAmenitiesListByType(
    EAmenityType.CORPORATE_OFFICE_WORK_DESK,
  );

  const roomConditionsList = getAmenitiesListByType(
    EAmenityType.CORPORATE_OFFICE_MEETING_ROOM,
  );

  const deskConditionsAccessList = [
    ...deskConditionsList,
    ...deskAccessibilityList,
  ];

  const roomConditionsAccessList = [
    ...roomConditionsList,
    ...roomAccessibilityList,
  ];

  const lisLeftOffset = '14px';

  const isOffice = accommodationType === EAccommodationType.CORPORATE_OFFICE;

  const isMeetingRoom = roomType === ERoomType.MEETING_ROOM;

  const areaInFeet = dimensionsConverter(
    'm',
    String(unitDimensions.area),
    true,
    0,
  );

  const ceilingInFeet = dimensionsConverter(
    'm',
    String(unitDimensions.ceilingHeight),
    false,
    0,
  );

  const spaceArea = `${unitDimensions.area} square ${pluralize(
    'metres',
    Number(unitDimensions.area),
    false,
  )} / ${areaInFeet} square ${pluralize('foot', areaInFeet, false)}`;

  const spaceCeilingHeight = `${pluralize(
    'metres',
    Number(unitDimensions.ceilingHeight),
  )} / ${pluralize('foot', ceilingInFeet)}
  `;

  const unitSetupList = unitCapacities?.map((el) => ({
    value: ERoomSchema[el.setupStyle],
  }));

  return (
    <Container>
      <div>
        <UnitName>{name}</UnitName>
        <NW2SpaceDetailsDescription unit={unit} />
      </div>
      <ServicesContainer>
        {isMeetingRoom ? (
          <>
            <div>
              <ServiceTitle>Size</ServiceTitle>
              <CustomList
                offsetLeft={lisLeftOffset}
                offsetItemBottom={offsetXXSm}
                list={[{value: spaceArea}]}
              />
            </div>

            <div>
              <ServiceTitle>Ceiling height</ServiceTitle>
              <CustomList
                offsetLeft={lisLeftOffset}
                offsetItemBottom={offsetXXSm}
                list={[{value: spaceCeilingHeight}]}
              />
            </div>
          </>
        ) : (
          <>
            <div>
              <ServiceTitle>Space Type</ServiceTitle>
              <CustomList
                offsetLeft={lisLeftOffset}
                offsetItemBottom={offsetXXSm}
                list={[{value: UNIT_NAME_TYPE_MAP[type]}]}
              />
            </div>

            <div>
              <ServiceTitle>Capacity</ServiceTitle>
              <CustomList
                offsetLeft={lisLeftOffset}
                offsetItemBottom={offsetXXSm}
                list={[{value: pluralize('desk', capacity)}]}
              />
            </div>
          </>
        )}

        <div>
          <ServiceTitle>Located on</ServiceTitle>
          <CustomList
            offsetLeft={lisLeftOffset}
            offsetItemBottom={offsetXXSm}
            list={[{value: getFloorName(floor)}]}
          />
        </div>

        {!isOffice && (
          <div>
            <ServiceTitle>
              {isMeetingRoom ? 'Room' : 'Desk'} rental
            </ServiceTitle>
            <CustomList
              offsetLeft={lisLeftOffset}
              offsetItemBottom={offsetXXSm}
              list={[
                {
                  value: `${formatFloatWithOneNumber(
                    priceHour,
                  )} ${currencySymbol}  per hour`,
                },
                {
                  value: `${formatFloatWithOneNumber(
                    priceDay,
                  )} ${currencySymbol} per day`,
                },
              ]}
            />
          </div>
        )}

        {!!deskConditionsAccessList.length && !isMeetingRoom && (
          <div>
            <ServiceTitle>Conditions & Access</ServiceTitle>
            <CustomList
              offsetLeft={lisLeftOffset}
              offsetItemBottom={offsetXXSm}
              list={deskConditionsAccessList}
            />
          </div>
        )}

        {!!roomConditionsAccessList.length && isMeetingRoom && (
          <div>
            <ServiceTitle>Conditions & Access</ServiceTitle>
            <CustomList
              offsetLeft={lisLeftOffset}
              offsetItemBottom={offsetXXSm}
              list={roomConditionsAccessList}
            />
          </div>
        )}

        {!!deskEquippedList.length && !isMeetingRoom && (
          <div>
            <ServiceTitle>Equipped with</ServiceTitle>
            <CustomList
              offsetLeft={lisLeftOffset}
              offsetItemBottom={offsetXXSm}
              list={deskEquippedList}
            />
          </div>
        )}

        {!!roomEquippedList.length && isMeetingRoom && (
          <div>
            <ServiceTitle>Equipped with</ServiceTitle>
            <CustomList
              offsetLeft={lisLeftOffset}
              offsetItemBottom={offsetXXSm}
              list={roomEquippedList}
            />
          </div>
        )}

        {isMeetingRoom && !isMultiRooms && unitSetupList && (
          <div>
            <ServiceTitle>Setups available</ServiceTitle>
            <CustomList
              offsetLeft={lisLeftOffset}
              offsetItemBottom={offsetXXSm}
              list={unitSetupList}
            />
          </div>
        )}
      </ServicesContainer>
      {isMobile && (
        <StyledNW2SpaceDetailsSwitch roomType={roomType} isTextShowed />
      )}
    </Container>
  );
}

export default NW2SpaceDetailsContent;
