import {useEffect} from 'react';
import {useQuery} from 'hooks/useQuery';
import {useDispatch} from 'react-redux';

import LocalStorageService from 'infra/common/localStorage.service';
import {setIsPaymentInProcess} from 'store/payment/paymentSlice';

export function useRedirectResult() {
  const dispatch = useDispatch();
  const {redirectResult} = useQuery();

  useEffect(() => {
    if (!redirectResult) return;
    dispatch(setIsPaymentInProcess(!!redirectResult));
  }, [dispatch, redirectResult]);

  return {
    redirectResult,
    isRedirectResult: !!redirectResult,
    formValues: LocalStorageService.getByKey('userData'),
  };
}
