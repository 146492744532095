import React, {useEffect, useMemo, useState} from 'react';
import {useField, useForm} from 'react-final-form';
import {State} from 'country-state-city';

import useCountryList from 'hooks/useCountriesList';
import {NW2FormItemSelect} from 'view/components/NW2FormItem/NW2FormItem';
import {requiredFieldRules} from 'utils/finalFormFieldRules';

import {NW2Gray600Color} from 'styles/configs/colors';
import {IDropdownListOption} from 'view/components/NW2FormItem/types';
import {setSelectOptions} from 'view/components/NW2FormItem/components/Select/utils';
import {FormInnerContainer} from 'view/venue/NW2VenueProfile/components/NMMPayout/components/EditPaymentForm/EditPaymentForm.styles';
import {ESupplierBusinessInfoFormFields} from 'view/components/NW2Forms/NW2SupplierLegalDetailsForm/types';

const getCountryCode = (countryName: string, countriesList: any[]) =>
  countriesList.find(({country}) => country === countryName)?.countryCode;

export function CountryStateFormSelects() {
  const [regionList, setRegionList] = useState<IDropdownListOption[]>([]);

  const form = useForm();
  const countryFormInput = useField('legalContact.country');
  const {countriesList} = useCountryList();

  const countryOptions = useMemo(
    () =>
      setSelectOptions(countriesList, {key: 'countryCode', value: 'country'}),
    [countriesList],
  );

  useEffect(() => {
    const countryCode = getCountryCode(
      countryFormInput.input.value,
      countriesList,
    );
    if (countryCode) {
      const states = State.getStatesOfCountry(countryCode);
      const regionsList = setSelectOptions(states, {
        key: 'isoCode',
        value: 'name',
      });

      setRegionList(regionsList);
      form.change(ESupplierBusinessInfoFormFields.COUNTRY_CODE, countryCode);
    }
  }, [countryFormInput.input.value, countriesList, form]);

  return (
    <>
      <FormInnerContainer>
        <NW2FormItemSelect
          name={ESupplierBusinessInfoFormFields.COUNTRY}
          label='Country*'
          placeholder='Country*'
          labelColor={NW2Gray600Color}
          rules={requiredFieldRules('country')}
          options={countryOptions}
          isCapitalLetterInOptions
          showAllValidationErrors
        />
      </FormInnerContainer>
      <FormInnerContainer>
        <NW2FormItemSelect
          name={ESupplierBusinessInfoFormFields.REGION}
          label='State or province*'
          placeholder='State or province*'
          labelColor={NW2Gray600Color}
          disabled={!regionList.length}
          rules={requiredFieldRules('state or province')}
          options={regionList}
          isCapitalLetterInOptions
          showAllValidationErrors
        />
      </FormInnerContainer>
    </>
  );
}
