import React from 'react';
import styled from 'styled-components';

import Icon from 'view/components/Icon';
import DateUtils, {DateFormats} from 'utils/dateUtils';

import {StyledSpan} from 'view/components/Typography';

import {
  fontSizeXSm,
  fontWeightNormal,
  offsetXXSm,
  offsetXXXSm,
} from 'constants/styleVars';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
`;

const DateContainer = styled(StyledSpan)`
  margin-top: ${offsetXXXSm};
  line-height: 1;
`;
const DayOfWeek = styled(StyledSpan)`
  margin-left: ${offsetXXSm};
`;

const TimeRange = styled(StyledSpan)`
  margin-left: 5px;
`;

type TProps = {
  sectionData: {date: any; dayTimeRange: string};
};

function UnitSectionTitle({sectionData}: TProps) {
  const {date, dayTimeRange} = sectionData;

  const dateText = DateUtils.getDateWithoutHours(date as Date);
  const dayOfWeek = DateUtils.getDateWithoutHours(
    date as Date,
    DateFormats['(Wed)'],
  );
  return (
    <HeaderContainer>
      <Icon icon='NW2_CALENDAR' transparent />
      <DateContainer fontWeight={fontWeightNormal}>
        {dateText}
        <DayOfWeek fontSize={fontSizeXSm}>{dayOfWeek}</DayOfWeek>
        <TimeRange fontSize={fontSizeXSm}>{dayTimeRange}</TimeRange>
      </DateContainer>
    </HeaderContainer>
  );
}

export default UnitSectionTitle;
