import {DateTime} from 'luxon';
import {parsePhoneNumber} from 'react-phone-number-input';
import {State} from 'country-state-city';

import {
  ELegalEntityType,
  EPersonOfInterestType,
  ILegalPhone,
  IPeopleOfInterest,
  IVenueLegalContact,
  LegalEntityTypeCaption,
} from 'types/venue';
import {DateFormats} from 'utils/dateUtils';
import {
  ESupplierBusinessInfoFormFields,
  TSupplierBusinessInfo,
} from 'view/components/NW2Forms/NW2SupplierLegalDetailsForm/types';
import {getISOString} from 'utils/dateUtils';

const isEqual = (
  arg0: IPeopleOfInterest | undefined,
  arg1: IPeopleOfInterest | undefined,
) => {
  if (!arg0 || !arg1) return false;
  return (
    Object.is(arg0.firstName, arg1.firstName) &&
    Object.is(arg0.lastName, arg1.lastName) &&
    Object.is(arg0.dateOfBirth, arg1.dateOfBirth)
  );
};

const setInitialPeopleOfInterestValues = (
  peopleOfInterest: IPeopleOfInterest[],
) => {
  const initialPeopleOfInterests = peopleOfInterest.reduce(
    (prev, {type, dateOfBirth, ...rest}) => {
      return {
        ...prev,
        [type]: {
          type,
          dateOfBirth: dateOfBirth
            ? DateTime.fromISO(dateOfBirth).toFormat(DateFormats['31.12.2000'])
            : null,
          ...rest,
        },
      };
    },
    {} as Record<EPersonOfInterestType, IPeopleOfInterest>,
  );

  return {
    ...initialPeopleOfInterests,
    [ESupplierBusinessInfoFormFields.BUSINESS_OWNER_SWITCH]: isEqual(
      initialPeopleOfInterests.SIGNATORY,
      initialPeopleOfInterests.OWNER,
    ),
    [ESupplierBusinessInfoFormFields.CONTROLLER_SWITCH]: isEqual(
      initialPeopleOfInterests.SIGNATORY,
      initialPeopleOfInterests.CONTROLLER,
    ),
  };
};

const convertEntityTypeCaptionToEnumValue = {
  [LegalEntityTypeCaption[ELegalEntityType.BUSINESS]]:
    ELegalEntityType.BUSINESS,
  [LegalEntityTypeCaption[ELegalEntityType.INDIVIDUAL]]:
    ELegalEntityType.INDIVIDUAL,
};

const getPeopleOfInterest = ({
  SIGNATORY,
  OWNER,
  CONTROLLER,
  legalContact,
  isControllerSwitchActive,
  isBusinessSwitchActive,
}: TSupplierBusinessInfo) => {
  const peopleOfInterest = {
    [ELegalEntityType.BUSINESS]: {
      SIGNATORY,
      OWNER: isBusinessSwitchActive ? {...SIGNATORY, id: OWNER.id} : OWNER,
      CONTROLLER: isControllerSwitchActive
        ? {...SIGNATORY, id: CONTROLLER.id}
        : CONTROLLER,
    },
    [ELegalEntityType.INDIVIDUAL]: {OWNER},
  };

  const currentTypePeople: Record<string, IPeopleOfInterest> =
    peopleOfInterest[
      convertEntityTypeCaptionToEnumValue[legalContact.legalEntityType]
    ];

  return Object.keys(currentTypePeople).map((item) => {
    return {
      ...currentTypePeople[item],
      dateOfBirth: getISOString(
        DateTime.fromFormat(
          currentTypePeople[item].dateOfBirth,
          DateFormats['31.12.2000'],
        ),
      ),
      type: item as EPersonOfInterestType,
    };
  });
};

const getLegalContact = ({legalContact}: TSupplierBusinessInfo) => {
  const parsedPhoneNumber = parsePhoneNumber(legalContact.legalPhone);

  const legalEntityType =
    convertEntityTypeCaptionToEnumValue[legalContact.legalEntityType];

  const isBusinessLegalEntityType =
    legalEntityType === ELegalEntityType.BUSINESS;

  const {registrationNumber, companyName, ...restDetails} = legalContact;

  return {
    ...restDetails,
    ...(isBusinessLegalEntityType ? {registrationNumber, companyName} : {}),
    legalEntityType,
    legalPhone: {
      phoneCountryCode: parsedPhoneNumber?.countryCallingCode,
      phone: parsedPhoneNumber?.nationalNumber,
      fullPhoneNumber: parsedPhoneNumber?.number,
      phoneCountry: parsedPhoneNumber?.country,
    },
  };
};

const requiredIndividualLegalContactFields: (keyof IVenueLegalContact)[] = [
  'legalEntityType',
  'buildingNumber',
  'city',
  'country',
  'legalPhone',
  'postalCode',
  'region',
  'streetName',
  'taxNumber',
];

const requiredBusinessLegalContactFields: (keyof IVenueLegalContact)[] = [
  ...requiredIndividualLegalContactFields,
  'companyName',
  'registrationNumber',
];

const legalContactFieldNames: Record<string, string> = {
  legalEntityType: 'Legal entity',
  buildingNumber: 'Building no.',
  city: 'City',
  country: 'Country',
  legalPhone: 'Telephone number',
  postalCode: 'Postal Code',
  region: 'State or province',
  streetName: 'Street name',
  taxNumber: 'Company Tax ID',
  companyName: 'Legal business name',
  registrationNumber: 'Registration number',
  SIGNATORY: 'Signatory',
  OWNER: 'Business owner',
  CONTROLLER: 'Controller',
};

const requiredBusinessInformationFields = {
  [ELegalEntityType.BUSINESS]: requiredBusinessLegalContactFields,
  [ELegalEntityType.INDIVIDUAL]: requiredIndividualLegalContactFields,
};

const checkMissingLegalPhoneData = ({
  phone,
  phoneCountry,
  phoneCountryCode,
}: ILegalPhone) => !(phone && phoneCountryCode && phoneCountry);

const checkMissingRegionData = (legalContact: IVenueLegalContact) => {
  return (
    !!State.getStatesOfCountry(legalContact.countryCode).length &&
    !legalContact.region
  );
};

const findMissingLegalData = ({
  legalContact,
  peopleOfInterest,
}: {
  legalContact: IVenueLegalContact;
  peopleOfInterest: IPeopleOfInterest[];
}) => {
  const isIndividualEntityType =
    legalContact.legalEntityType === ELegalEntityType.INDIVIDUAL;

  const missingBusinessInformationFields = requiredBusinessInformationFields[
    legalContact.legalEntityType || ELegalEntityType.BUSINESS // todo remove || ELegalEntityType.BUSINESS after migration is done
  ].filter((field) =>
    field === 'legalPhone'
      ? checkMissingLegalPhoneData(legalContact.legalPhone)
      : field === 'region'
      ? checkMissingRegionData(legalContact)
      : !legalContact[field],
  );

  const missingOperatingExecutives = Object.keys(EPersonOfInterestType).filter(
    (interestType) => {
      if (
        isIndividualEntityType &&
        interestType !== EPersonOfInterestType.OWNER
      )
        return false;

      const isPersonExist = peopleOfInterest.filter(
        ({type, dateOfBirth, firstName, lastName}) => {
          if (!(dateOfBirth && firstName && lastName)) return false;

          return isIndividualEntityType
            ? type === EPersonOfInterestType.OWNER
            : type === interestType;
        },
      ).length;

      if (!isPersonExist) return interestType;
    },
  );

  return {
    missingFields: {
      OE: missingOperatingExecutives,
      BI: missingBusinessInformationFields,
    },
    hasMissingFields: !!(
      missingOperatingExecutives.length ||
      missingBusinessInformationFields.length
    ),
  };
};

export {
  isEqual,
  setInitialPeopleOfInterestValues,
  convertEntityTypeCaptionToEnumValue,
  getPeopleOfInterest,
  getLegalContact,
  requiredBusinessInformationFields,
  findMissingLegalData,
  legalContactFieldNames,
};
