import React, {useEffect, useState} from 'react';

import {
  InputWrapper,
  ChangeButton,
  AddButton,
  StyledInput,
  Label,
} from './NW2NumericButtonInput.styles';
import Icon from 'view/components/Icon/Icon';

type TProps = {
  disabled?: boolean;
  maxValue?: number;
  minValue?: number;
  initialValue?: number;
  onChange?: (val: number) => void;
  shouldResetCurrentNumber?: boolean;
};

function NW2NumericButtonInput({
  initialValue = 0,
  maxValue = 99,
  minValue = 0,
  disabled,
  onChange,
  shouldResetCurrentNumber = false,
}: TProps) {
  const [currentNumber, setCurrentNumber] = useState(initialValue);
  const [showAddButton, setShowAddButton] = useState(true);

  useEffect(() => {
    if (shouldResetCurrentNumber) setCurrentNumber(initialValue);
  }, [initialValue, shouldResetCurrentNumber]);

  useEffect(() => {
    if (currentNumber < 1 && !showAddButton) {
      setShowAddButton(true);
    }
    if (currentNumber > 0 && showAddButton) {
      setShowAddButton(false);
    }
  }, [currentNumber, showAddButton]);

  const incrementHandler = () => {
    setCurrentNumber(currentNumber + 1);
    if (onChange) onChange(currentNumber + 1);
  };
  const decrementHandler = () => {
    const number = currentNumber > minValue ? currentNumber - 1 : currentNumber;
    setCurrentNumber(number);
    if (onChange) onChange(number);
  };
  const addHandler = () => {
    setCurrentNumber(1);
    if (onChange) onChange(1);
  };

  return (
    <div>
      {currentNumber ? (
        <InputWrapper>
          <ChangeButton
            type='button'
            disabled={disabled || currentNumber === minValue}
            onClick={decrementHandler}
          >
            <Icon transparent icon='MINUS' />
          </ChangeButton>
          <StyledInput readOnly value={currentNumber} />
          <ChangeButton
            type='button'
            disabled={disabled || currentNumber === maxValue}
            onClick={incrementHandler}
          >
            <Icon transparent icon='PLUS' />
          </ChangeButton>
        </InputWrapper>
      ) : (
        <AddButton type='button' disabled={disabled} onClick={addHandler}>
          <Icon transparent icon='PLUS_CIRCLED' />
          <Label>Add</Label>
        </AddButton>
      )}
    </div>
  );
}

export default NW2NumericButtonInput;
