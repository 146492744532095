const AdyenErrors: Record<string, string> = {
  'INV-PAY-011':
    'Unfortunately, it was not possible to process your payment. Please try again.',
  'INV-PAY-012':
    'Unfortunately, it was not possible to process your payment. Please try again.',
  'INV-PAY-013':
    'The credit card used for the transaction is blocked. Please try again with another credit card.',
  'INV-PAY-014':
    'The card used for the transaction has expired. Please try again with another credit card.',
  'INV-PAY-015':
    'Unfortunately, it was not possible to process your payment. Please try again.',
  'INV-PAY-016':
    'The entered card number was declined. Please double-check it and try again or try again with another credit card.',
  'INV-PAY-017':
    'Unfortunately, it was not possible to process your payment. Please try again.',
  'INV-PAY-018':
    'Unfortunately, it was not possible to process your payment. Please try again with another credit card.',
  'INV-PAY-019':
    'Unfortunately, the authentication process was not executed successfully. Please try again.',
  'INV-PAY-020':
    'Unfortunately, it was not possible to process your payment. Please try again with another credit card.',
  'INV-PAY-021': 'The transaction was cancelled. Please try again.',
  'INV-PAY-022':
    'The payment process was interrupted before completion. Please try again.',
  'INV-PAY-023': 'The specified PIN is incorrect or invalid. Please try again.',
  'INV-PAY-024':
    'You have exceeded the number of allowed PIN entry attempts. Please try again later.',
  'INV-PAY-025':
    'Unfortunately, it was not possible to process your payment. Please try again.',
  'INV-PAY-026':
    'Unfortunately, it was not possible to process your payment. Please try again with another credit card.',
  'INV-PAY-027':
    'Unfortunately, it was not possible to process your payment. Please try again.',
  'INV-PAY-028':
    'Unfortunately, it was not possible to process your payment. Please try again.',
  'INV-PAY-029':
    'Unfortunately, it was not possible to process your payment. Please try again.',
  'INV-PAY-030':
    'The entered CVC code was refused. Please double-check it and try again or try again with another credit card.',
  'INV-PAY-031':
    'Unfortunately, it was not possible to process your payment. Please try again with another credit card.',
  'INV-PAY-032':
    'The payment process was interrupted before completion. Please try again.',
  'INV-PAY-033':
    'Unfortunately, it was not possible to process your payment. Please try again.',
  'INV-PAY-034':
    'The allowed withdrawal amount on provided credit card was exceeded. Please try again with another credit card.',
  'INV-PAY-035':
    'The number of allowed payments with provided credit card was exceeded. Please try again with another credit card.',
  'INV-PAY-036':
    'Unfortunately, it was not possible to process your payment. Please try again with another credit card.',
  'INV-PAY-037':
    'Unfortunately, it was not possible to process your payment. Please try again and enter the online PIN if required.',
  'INV-PAY-038':
    'Unfortunately, it was not possible to process your payment. Please try again with another credit card.',
  'INV-PAY-039':
    'Unfortunately, it was not possible to process your payment. Please try again with another credit card.',
  'INV-PAY-040':
    'Unfortunately, it was not possible to process your payment. Please try again and enter the mobile PIN if required.',
  'INV-PAY-041':
    'Unfortunately, it was not possible to process your payment. Please try again and fulfill the 3D Secure process correctly.',
  'INV-PAY-042':
    'Unfortunately, it was not possible to process your payment. Please try again and fulfill the 3D Secure process correctly.',
  'INV-PAY-043':
    'Unfortunately, it was not possible to process your payment. Please try again.',
};

export default AdyenErrors;
