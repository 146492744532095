import React, {FC} from 'react';

import DateUtils from 'utils/dateUtils';
import {pluralize} from 'utils/helpers';

import {
  ChangeIcon,
  FlexContainer,
  ParticipantsContainer,
  Separator,
  TimeRangeContainer,
} from './NW2BookingUnitCard.styles';
import {EBookingSummaryTheme} from '../../../../../components/NW2SummaryBlock/types';

type TProps = {
  unitCheckInDate: string;
  unitCheckOutDate: string;
  timeRangeState: {
    isHighlighted: boolean;
  };
  participantsState: {
    isHighlighted: boolean;
    arrowIcon: string;
  };
  theme: EBookingSummaryTheme;
  isMeetingRoom: boolean;
  participants?: number | null;
};
const BookingUnitCardTimeRangeAndParticipants: FC<TProps> = ({
  theme,
  timeRangeState,
  participantsState,
  unitCheckOutDate,
  unitCheckInDate,
  isMeetingRoom,
  participants,
}) => {
  return (
    <FlexContainer marginBottom={4}>
      <TimeRangeContainer
        colorTheme={theme}
        isHighlighted={timeRangeState.isHighlighted}
      >
        {DateUtils.getHoursAndMinutesFromBE(unitCheckInDate)} -{' '}
        {DateUtils.getHoursAndMinutesFromBE(unitCheckOutDate)}
      </TimeRangeContainer>
      {isMeetingRoom && (
        <>
          <Separator colorTheme={theme} />
          <ParticipantsContainer
            colorTheme={theme}
            isHighlighted={participantsState.isHighlighted}
          >
            {pluralize('people', participants || 0)}
            {participantsState.isHighlighted && (
              <ChangeIcon
                icon={participantsState.arrowIcon}
                iconColor={false}
                marginLeft={6}
              />
            )}
          </ParticipantsContainer>
        </>
      )}
    </FlexContainer>
  );
};

export default BookingUnitCardTimeRangeAndParticipants;
