import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import {NW2FormItemInput} from 'view/components/NW2FormItem/NW2FormItem';
import {StepExtrasPrices} from './components/StepExtrasPrices';
import {StepHint} from './components/StepHint';
import {OfferCreateLayout} from './components/OfferCreateLayout';
import {LastStep} from './components/LastStep';

import {PageContent} from 'view/venue/NW2VenueProfile/components/AddSpace/styles';
import {
  ECreateOfferFormFields,
  ICommonProps,
  IRequestMeeting,
  TCapacityType,
  TCreateOfferFormValues,
  TPreviewState,
} from './types';
import {
  getFilteredExtrasByType,
  getRoomRentalPriceByDateRange,
  getSeatingPlanByParticipants,
} from 'utils/venueUtils';
import {nameFieldRules} from 'utils/finalFormFieldRules';
import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {EEventType} from 'types/venue';
import {
  EResourcesType,
  IExtrasOption,
  IExtrasResponse,
} from 'types/dto/IExtras.type';
import useSpaces from 'view/venue/hooks/useSpaces';
import {useUpdateExtras} from './hooks/useUpdateExtras';
import {useActions} from 'hooks/useActions';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {getCancellationPolicyText} from 'store/customer/apiActions';
import DateUtils from 'utils/dateUtils';
import {getCurrencySymbol} from 'utils/stringUtils';
import {offsetDef} from 'constants/styleVars';
import {useUnitsExtras} from './hooks/useUnitsExtras';
import {StepBedroomsPrices} from './components/StepBedroomsPrices';
import {getBedroomsFromState, getCreateOfferPayload} from './helpers';
import {useInitialData} from './hooks/useInitialData';
import {usePaidDurationOptions} from '../../../NW2VenueProfile/components/AddSpace/hooks/usePaidDurationOptions';
import {getFlatMapUnits} from 'view/customer/helpers';
import {getCoverImage} from 'view/venue/helpers/spaces';
import {IOfferUnitExtra} from 'types/offer';

export function OfferCreateOnboardedVenue({
  activeUnitId,
  activeForm,
  formState,
  formStatus,
  handleClose,
  handleScrollToUnit,
  handleSetFiles,
  handleSetItemsToOffer,
  itemsToOffer,
  page,
  setActiveUnitId,
  setFormState,
  setPage,
  setItemsToOffer,
  setFormStatus,
  postEvents,
  preArrivals,
  requestDays,
  requestMeetings,
  requestUnits,
  requestRooms,
  prices,
  hasEquipment,
  hasCatering,
}: ICommonProps) {
  const dispatch = useAppDispatch();

  const {createOffer} = useActions();

  const venueId: number = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.id`),
  );
  const currencyKey = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.currency`),
  );
  const currency = getCurrencySymbol(currencyKey);
  const allExtrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );
  const extrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );
  const requestId = useAppSelector(({offers}) => offers.offerDetails.id);

  // initializers
  const [isSubmitting, setSubmitting] = useState(false);
  const [previewState, setPreviewState] = useState<TPreviewState>({});

  const isMultiDay = requestUnits.length > 1;

  const firstMeetingRoomId = requestMeetings[0]?.id;
  const isFirstUnit = activeUnitId === firstMeetingRoomId;
  const isLastUnit = activeUnitId === requestUnits[requestUnits.length - 1]?.id;
  const lastMeetingRoomId = requestMeetings[requestMeetings.length - 1]?.id;
  const isLastUnitMeeting =
    !postEvents.length && activeUnitId === lastMeetingRoomId;

  const requestedUnitDetails = requestUnits.find(
    ({id}) => id === activeUnitId,
  ) as unknown as IRequestMeeting;

  const isPreArrival =
    requestedUnitDetails?.eventType === EEventType.PRE_ARRIVAL;
  const isPostEvent = requestedUnitDetails?.eventType === EEventType.POST_EVENT;
  const isPreOrPostEvent = isPreArrival || isPostEvent;

  const requestedCapacity = requestedUnitDetails?.participants || 0;
  const seatingPlanName = getSeatingPlanByParticipants(requestedCapacity);
  const requestedSeatingPlan =
    requestedUnitDetails?.setupStyle || seatingPlanName;

  const [onUpdateExtras] = useUpdateExtras({venueId, extrasOption});

  const unitsExtras = useUnitsExtras({
    requestMeetings,
    extrasOption,
  });

  const {
    allEquipmentExtras,
    allCateringExtras,
    initialExtras,
    filteredAvailableExtras,
  } = unitsExtras;

  // bedrooms
  const activeUnitBedroomExtras = useMemo(
    () =>
      getFilteredExtrasByType(
        requestedUnitDetails?.bedrooms || [],
        EResourcesType.BEDROOM,
        extrasOption,
      ),
    [extrasOption, requestedUnitDetails?.bedrooms],
  );

  const paidDurationOptions = usePaidDurationOptions();

  // handle active steps
  const [activeStep, setActiveStep] = useState('');

  // init units
  const {handleInitUnit} = useInitialData({
    handleSetFiles,
    initialExtras,
    paidDurationOptions,
    requestedSeatingPlan,
  });

  const [spaces] = useSpaces();
  const venueSpaces = getFlatMapUnits(spaces);

  const requestMeetingRooms = requestRooms.flatMap(({items}) => items);

  /**
   * navigation statuses
   * active - unit filling in progress
   * done - unit filling complete
   * hold - unit already prefilled & haven't any additional steps to fill (equipment or FB for first unit or bedrooms for any day)
   */
  const [navStatus, setNavStatus] = useState<
    Record<
      string,
      {
        status: 'done' | 'active' | 'hold' | '';
        prevId?: string;
        direction: 'fwd' | 'back';
      }
    >
  >({});

  // go to configure next item
  const handleGoNextRequestedItem = useCallback(() => {
    if (isLastUnit) return;

    const unitIdx = requestUnits.findIndex(({id}) => id === activeUnitId);
    const nextUnitId = requestUnits[unitIdx + 1]?.id;

    if (nextUnitId) {
      setPage(0);
      setActiveUnitId(nextUnitId);
      handleScrollToUnit(nextUnitId);

      setNavStatus((prev) => ({
        ...prev,
        [activeUnitId]: {
          ...prev[activeUnitId],
          status: prev[activeUnitId]?.status === 'hold' ? 'hold' : 'done',
          direction: 'fwd',
        },
        [nextUnitId]: {status: 'active', direction: 'fwd'},
      }));

      const currDay = requestMeetingRooms.find(
        (room) => room?.id === nextUnitId,
      );

      if (currDay) {
        const unit = venueSpaces.find(({id}) => id === currDay.unitId);
        if (unit) {
          handleInitUnit(unit);
        }
      }
    }
  }, [
    activeUnitId,
    handleInitUnit,
    handleScrollToUnit,
    isLastUnit,
    requestMeetingRooms,
    requestUnits,
    setActiveUnitId,
    setPage,
    venueSpaces,
  ]);

  /**
   * step titles
   * we handle the active step outside the form wizard by the title of this step
   */

  const EQUIPMENT_PRICES_TITLE = 'What’s the price of the following equipment?';
  const CATERING_PRICES_TITLE = 'What’s the price of the following services?';
  const BEDROOMS_PRICES_TITLE =
    'How much do you want to charge per room for accommodation?';
  const LAST_STEP_TITLE =
    'Almost there! Just review terms & conditions and submit your offer';
  const SUPPLIER_TITLE = 'Let your customer know who created this offer';

  // NAVIGATION
  const unitIdx = requestUnits.findIndex(({id}) => id === activeUnitId);
  const prevUnit = requestUnits[unitIdx - 1];

  const setActiveFormDone = useCallback(() => {
    setNavStatus((prev) => ({
      ...prev,
      [activeUnitId]: {status: 'done', direction: 'fwd'},
    }));
  }, [activeUnitId]);

  const goToStartPage = useCallback(() => {
    setFormStatus('');
    setNavStatus({});

    if (requestedUnitDetails?.eventType !== EEventType.DAY) {
      setFormState((prev) => ({...prev, [activeUnitId]: {}}));
    }
  }, [
    activeUnitId,
    requestedUnitDetails?.eventType,
    setFormState,
    setFormStatus,
  ]);

  const handlePrevUnitSelection = useCallback(
    (prevUnitId?: string) => {
      if (requestUnits[0]?.id === activeUnitId) {
        // go to start page if it 1st unit selection
        goToStartPage();
      }

      const onGoPrevItem = (prevId: string) => {
        setNavStatus((prev) => ({
          ...prev,
          [activeUnitId]: {status: '', direction: 'back'},
          [prevId]: {status: 'active', direction: 'back'},
        }));

        const previousUnit = requestUnits.find(({id}) => id === prevId);

        if (requestedUnitDetails?.eventType !== EEventType.DAY) {
          setFormState((prev) => ({...prev, [activeUnitId]: {}}));
          setPreviewState((prev) => ({...prev, [activeUnitId]: {}}));
        }

        if (previousUnit?.eventType === EEventType.DAY) {
          // reset meeting bedrooms
          // @ts-ignore
          setFormState((prev) => ({
            ...prev,
            [prevId]: {...prev[prevId], [prevId]: {}},
          }));
        }

        setItemsToOffer((prev) =>
          prev.filter(({requestItemId}) => requestItemId !== prevId),
        );

        setActiveUnitId(prevId);
        handleScrollToUnit(prevId);
      };

      if (prevUnitId) {
        onGoPrevItem(prevUnitId);
        return;
      }

      if (prevUnit) {
        const prevUnitId = prevUnit.id;

        if (navStatus[prevUnitId].status === 'hold') {
          const prevNavId = navStatus[prevUnitId].prevId;

          if (prevNavId) {
            onGoPrevItem(prevNavId);
          } else {
            goToStartPage();
          }

          return;
        }

        onGoPrevItem(prevUnitId);

        setActiveUnitId(prevUnitId);
      }
    },
    [
      activeUnitId,
      goToStartPage,
      handleScrollToUnit,
      navStatus,
      prevUnit,
      requestUnits,
      requestedUnitDetails?.eventType,
      setActiveUnitId,
      setFormState,
      setItemsToOffer,
    ],
  );

  useEffect(() => {
    /**
     * redirect to next space selection page if not all days are filled
     */
    if (activeStep === SUPPLIER_TITLE) {
      if (isLastUnit || isLastUnitMeeting) {
        return;
      }

      if (
        (!isFirstUnit ||
          (isFirstUnit &&
            !emptyEquipmentExtras.current?.length &&
            !emptyCateringExtras.current?.length)) &&
        !activeUnitBedroomExtras.length
      ) {
        setNavStatus((prev) => ({
          ...prev,
          [activeUnitId]: {
            status: 'hold',
            prevId: prevUnit?.id,
            direction: prev[activeUnitId]?.direction,
          },
        }));
      }

      if (navStatus[activeUnitId]?.status === 'active') return;

      if (
        navStatus[activeUnitId]?.status === 'hold' &&
        navStatus[activeUnitId]?.direction === 'back'
      ) {
        handlePrevUnitSelection(navStatus[activeUnitId]?.prevId);
        return;
      }

      // get next item in the requestUnits
      handleGoNextRequestedItem();
    }
  }, [
    activeStep,
    activeUnitBedroomExtras.length,
    activeUnitId,
    handleGoNextRequestedItem,
    handlePrevUnitSelection,
    isFirstUnit,
    isLastUnit,
    isLastUnitMeeting,
    navStatus,
    prevUnit?.id,
  ]);

  const {removeUnitSubtotalPrice} = prices;
  const handleResetBedroomItem = useCallback(() => {
    removeUnitSubtotalPrice(activeUnitId);

    // @ts-ignore
    setFormState((prev) => ({
      ...prev,
      [activeUnitId]: {...prev[activeUnitId], [activeUnitId]: {}},
    }));
    setItemsToOffer((prev) =>
      prev.filter(({requestItemId}) => requestItemId !== activeUnitId),
    );
  }, [activeUnitId, removeUnitSubtotalPrice, setFormState, setItemsToOffer]);
  // NAVIGATION END

  const [isUnitsLoaded, setUnitsLoaded] = useState(false);

  useEffect(() => {
    if (isUnitsLoaded || !requestDays.length) return;

    requestMeetingRooms.forEach((item) => {
      if (!item) return;
      const unit = venueSpaces.find(({id}) => id === item.unitId);

      if (unit && item.id) {
        if (item.id === firstMeetingRoomId) {
          handleInitUnit(unit);
        }

        const {priceHour, priceHalfDay, priceDay} = unit;

        // set room rental prices
        prices.setRoomPrice(
          item.id,
          getRoomRentalPriceByDateRange({
            priceHour,
            priceHalfDay,
            priceDay,
            checkIn: item.checkIn,
            checkOut: item.checkOut,
          }),
        );

        // @ts-ignore
        setFormState((prev) => ({
          ...prev,
          [item.id]: {
            [ECreateOfferFormFields.NAME]: unit.name,
            [ECreateOfferFormFields.IMAGES]: unit.documents,
            [ECreateOfferFormFields.DESCRIPTION]: unit.description,
            [ECreateOfferFormFields.FLOOR]: String(unit.floor),
            [ECreateOfferFormFields.CAPACITY]: unit.unitCapacities?.reduce(
              (obj, item) => {
                obj[item.setupStyle] = {
                  capacity: item.capacity,
                };
                return obj;
              },
              {} as TCapacityType,
            ),
            [ECreateOfferFormFields.AMENITIES]: unit.amenities,
            [ECreateOfferFormFields.DIMENSIONS]: unit.unitDimensions,
            [ECreateOfferFormFields.COVER_IMAGE]: getCoverImage(unit.documents)
              ?.url,
            [ECreateOfferFormFields.PRICE_HOUR]: unit.priceHour,
            [ECreateOfferFormFields.PRICE_HALF_DAY]: unit.priceHalfDay,
            [ECreateOfferFormFields.PRICE_DAY]: unit.priceDay,
            [ECreateOfferFormFields.PAID_DURATION]: unit.minimumPaidDuration,
            ...initialExtras[item.id],
          },
        }));

        setPreviewState((prev) => ({
          ...prev,
          [item.id]: {
            assignRoomType: 'existed',
            isEquipmentInitialised: true,
            isCateringInitialised: true,
            isAmenitiesHidden: false,
            isAmenitiesFilled: true,
            isCapacityInitialised: true,
            isFloorsInitialised: true,
            isDimensionsInitialised: true,
          },
        }));
      }
    });

    setUnitsLoaded(true);
  }, [
    firstMeetingRoomId,
    handleInitUnit,
    initialExtras,
    isUnitsLoaded,
    prices,
    requestDays.length,
    requestMeetingRooms,
    setFormState,
    venueSpaces,
  ]);

  const availableExtras: IExtrasResponse[] = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.extras`),
  );

  const emptyEquipmentExtras = useRef<IExtrasOption[] | null>(null);
  const emptyCateringExtras = useRef<IExtrasOption[] | null>(null);

  useEffect(() => {
    if (!emptyEquipmentExtras.current && allEquipmentExtras.length) {
      emptyEquipmentExtras.current = allEquipmentExtras.filter(
        ({code}) => !filteredAvailableExtras.some((item) => item.code === code),
      );
    }
  }, [allEquipmentExtras, filteredAvailableExtras]);

  useEffect(() => {
    if (!emptyCateringExtras.current && allCateringExtras.length) {
      emptyCateringExtras.current = allCateringExtras.filter(
        ({code}) => !filteredAvailableExtras.some((item) => item.code === code),
      );
    }
  }, [allCateringExtras, filteredAvailableExtras]);

  const handlePrevUnitSelectionFromBedrooms = () => {
    handleResetBedroomItem();

    if (
      isFirstUnit &&
      (emptyEquipmentExtras.current?.length ||
        emptyCateringExtras.current?.length)
    ) {
      return;
    }

    handlePrevUnitSelection();
  };

  // submit
  const onSubmit = useCallback(
    async (values: TCreateOfferFormValues) => {
      setSubmitting(true);

      const mapExtras = (unitExtras: IOfferUnitExtra[]) =>
        unitExtras.map(({accommodationExtraId}) => ({
          extraId: accommodationExtraId as number,
          price: {
            value: availableExtras.find(
              (extra) => extra.accommodationExtraId === accommodationExtraId,
            )?.price,
          },
        }));

      const roomsToOffer = requestRooms.flatMap(
        ({bedrooms, items, eventType}) =>
          items?.flatMap(({extras, unitId, id}, idx) => [
            {
              eventType,
              requestItemId: id,
              unitId,
              extras: mapExtras(extras),
              bedrooms: idx === 0 ? mapExtras(bedrooms) : [],
            },
          ]),
      );

      const mergedMap = new Map();

      roomsToOffer.forEach((item) =>
        mergedMap.set(item?.requestItemId, {...item}),
      );
      itemsToOffer.forEach((item) =>
        mergedMap.set(item.requestItemId, {
          ...mergedMap.get(item.requestItemId),
          ...item,
        }),
      );

      const mappedItemsToOffer = Array.from(mergedMap.values());

      const payload = getCreateOfferPayload({
        accommodationId: venueId,
        creatorName: values[ECreateOfferFormFields.SUPPLIER] as string,
        requestId,
        itemsToOffer: mappedItemsToOffer,
        requestDays,
      });

      // create offer
      if (requestedUnitDetails?.id) {
        createOffer(
          payload,
          () => {
            setSubmitting(false);
            setFormStatus('finished');
            setPage(0);
          },
          () => {
            setSubmitting(false);
          },
        );
      }
    },
    [
      availableExtras,
      createOffer,
      itemsToOffer,
      requestDays,
      requestId,
      requestRooms,
      requestedUnitDetails?.id,
      setFormStatus,
      setPage,
      venueId,
    ],
  );

  const bedroomsExtras = useMemo(
    () => getBedroomsFromState(formState, activeUnitId),
    [activeUnitId, formState],
  );

  const stepPages = [
    // 10
    {
      title: EQUIPMENT_PRICES_TITLE,
      subTitle: `(price per item/day in ${currency})`,
      fieldName: ECreateOfferFormFields.PRICES_EQUIPMENT,
      isHidden:
        !emptyEquipmentExtras.current?.length ||
        !isFirstUnit ||
        isPreOrPostEvent,
      onNextClick: async () => {
        if (
          !emptyCateringExtras.current?.length ||
          !activeUnitBedroomExtras.length
        ) {
          // go to next unit
          setActiveFormDone();
        }

        onUpdateExtras(activeForm[ECreateOfferFormFields.PRICES_EQUIPMENT]);
      },
      onBackClick: handlePrevUnitSelection,
      content: (
        <PageContent width={346}>
          <StepHint
            title='Pro tip!'
            text={`If a third party is providing these services, please consider
               that HRS' 10% commission is calculated from the total price.`}
            isCentered={false}
          />

          <StepExtrasPrices
            fieldName={ECreateOfferFormFields.PRICES_EQUIPMENT}
            extras={emptyEquipmentExtras.current || []}
          />
        </PageContent>
      ),
    },

    // 11
    {
      title: CATERING_PRICES_TITLE,
      subTitle: `(price per item/day in ${currency})`,
      fieldName: ECreateOfferFormFields.PRICES_FOOD_AND_BEVERAGE,
      isHidden:
        !emptyCateringExtras.current?.length ||
        !isFirstUnit ||
        isPreOrPostEvent,
      onNextClick: () => {
        onUpdateExtras(
          activeForm[ECreateOfferFormFields.PRICES_FOOD_AND_BEVERAGE],
        );

        if (!activeUnitBedroomExtras.length) {
          // go to next unit
          setActiveFormDone();
        }
      },
      onBackClick: () => {
        if (!emptyEquipmentExtras.current?.length) {
          handlePrevUnitSelection();
        }
      },
      content: (
        <PageContent width={368}>
          <StepHint
            title='Pro tip!'
            text={`If a third party is providing these services, please consider
               that HRS' 10% commission is calculated from the total price.`}
            isCentered={false}
          />

          <StepExtrasPrices
            fieldName={ECreateOfferFormFields.PRICES_FOOD_AND_BEVERAGE}
            extras={emptyCateringExtras.current || []}
          />
        </PageContent>
      ),
    },

    {
      title: BEDROOMS_PRICES_TITLE,
      subTitle: DateUtils.getFormattedDayMonthTime({
        date: requestedUnitDetails?.checkIn,
        showDateOnly: true,
      }),
      fieldName: ECreateOfferFormFields.PRICES_BEDROOMS,
      titleWidth: 422,
      isTitleCentered: true,
      isHidden: !activeUnitBedroomExtras.length,
      stepTotalCount: isPreOrPostEvent && !isLastUnit && 1,
      onNextClick: () => {
        // set bedroom items to payload
        handleSetItemsToOffer({
          bedrooms: Object.entries(
            getBedroomsFromState(formState, activeUnitId) || {},
          ).map(([code, price]) => ({
            extraId: allExtrasOption.find((item) => item.code === code)
              ?.id as number,
            price: {
              value: Number(price),
            },
          })),
        });

        // go to next unit
        setActiveFormDone();
      },
      onBackClick: handlePrevUnitSelectionFromBedrooms,
      content: (
        <PageContent width={346}>
          <StepHint
            title='Pro tip!'
            text={`The accommodation price will be specific to this offer
             and will not be applied to any future requests.`}
            isCentered={false}
          />

          <StepBedroomsPrices
            activeUnitId={activeUnitId}
            fieldName={`${activeUnitId}.${ECreateOfferFormFields.PRICES_BEDROOMS}`}
            bedrooms={activeUnitBedroomExtras}
            isInitialized={previewState[activeUnitId]?.isBedroomsInitialised}
            formStateValue={bedroomsExtras}
          />
        </PageContent>
      ),
    },

    // 12
    {
      title: SUPPLIER_TITLE,
      fieldName: ECreateOfferFormFields.SUPPLIER,
      onBackClick: () => {
        if (isPreOrPostEvent) {
          handleResetBedroomItem();

          return;
        } else {
          if (isLastUnit) {
            if (
              !emptyEquipmentExtras.current?.length ||
              !emptyCateringExtras.current?.length
            ) {
              handlePrevUnitSelection();
            } else {
              goToStartPage();
            }
          }
        }
      },
      content: (
        <PageContent width={284}>
          <NW2FormItemInput
            name={ECreateOfferFormFields.SUPPLIER}
            type='text'
            label='Your name'
            placeholder='Your name'
            rules={nameFieldRules}
            showAllValidationErrors
          />
        </PageContent>
      ),
    },

    // 13
    {
      title: LAST_STEP_TITLE,
      titleWidth: 446,
      isTitleCentered: true,
      titlePaddingTop: offsetDef,
      isHidden: isPreOrPostEvent && !isLastUnit,
      fieldName: '',
      content: <LastStep />,
    },
  ];

  const maxCapacity =
    activeForm[ECreateOfferFormFields.CAPACITY]?.[requestedSeatingPlan]
      ?.capacity || requestedCapacity;

  useEffect(() => {
    const showStep = (stepName: string, val: boolean) => {
      setPreviewState((prev) => ({
        ...prev,
        [activeUnitId]: {...prev[activeUnitId], [stepName]: val},
      }));
    };

    if (isFirstUnit) {
      if (activeStep === EQUIPMENT_PRICES_TITLE) {
        showStep('isEquipmentInitialised', true);
      }

      if (activeStep === CATERING_PRICES_TITLE) {
        showStep('isCateringInitialised', true);
      }
    }

    if (activeStep === BEDROOMS_PRICES_TITLE) {
      showStep('isBedroomsInitialised', true);
    }

    if (activeStep === LAST_STEP_TITLE) {
      dispatch(
        getCancellationPolicyText(Number(maxCapacity), false, isMultiDay),
      );
    }
  }, [
    activeStep,
    activeUnitId,
    dispatch,
    isFirstUnit,
    isMultiDay,
    maxCapacity,
  ]);

  return (
    <OfferCreateLayout
      activeUnitId={activeUnitId}
      isSubmitting={isSubmitting}
      requestUnits={requestUnits}
      prices={prices}
      page={page}
      setPage={setPage}
      handleClose={handleClose}
      formState={formState}
      formStatus={formStatus}
      onSubmit={onSubmit}
      stepPages={stepPages}
      setFormState={setFormState}
      setFormStatus={setFormStatus}
      setActiveUnitId={setActiveUnitId}
      setActiveStep={setActiveStep}
      previewState={previewState}
      requestDays={requestDays}
      preArrivals={preArrivals}
      postEvents={postEvents}
      hasEquipment={hasEquipment}
      hasCatering={hasCatering}
      currency={currency}
      firstMeetingRoomId={firstMeetingRoomId}
      unitsExtras={unitsExtras}
    />
  );
}
