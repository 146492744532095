import React, {memo} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';
import getSymbolFromCurrency from 'currency-symbol-map';

import PriceViewMode from './PricesViewMode';
import PriceForm from './PricesForm';

import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {ICommonProps} from '../../types';
import {EPricesFormFields} from './PricesForm.types';

interface IProps extends ICommonProps {
  initialValues: {
    [EPricesFormFields.PRICE_HOUR]: number;
    [EPricesFormFields.PRICE_HALF_DAY]: number;
    [EPricesFormFields.PRICE_DAY]: number;
    [EPricesFormFields.PAID_DURATION]?: string;
  };
  priceHour: number;
  priceHalfDay: number;
  priceDay: number;
  minimumPaidDuration: number;
  hasPriceHalfDay?: boolean;
}

const Price = ({
  initialValues,
  priceHour,
  priceHalfDay,
  priceDay,
  minimumPaidDuration,
  isSending,
  updateSpace,
  isEditorShowed,
  onEditorShowed,
  hasPriceHalfDay,
}: IProps) => {
  const currency: string = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.currency`),
  );

  const currencySymbol = getSymbolFromCurrency(currency);

  return isEditorShowed ? (
    <PriceForm
      initialValues={initialValues}
      isSending={isSending}
      updateSpace={updateSpace}
      currencySymbol={currencySymbol}
      onEditorShowed={onEditorShowed}
      hasPriceHalfDay={hasPriceHalfDay}
    />
  ) : (
    <PriceViewMode
      priceHour={priceHour}
      priceHalfDay={priceHalfDay}
      priceDay={priceDay}
      minimumPaidDuration={minimumPaidDuration}
      currencySymbol={currencySymbol}
    />
  );
};

export default memo(Price);
