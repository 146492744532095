import React, {ChangeEvent} from 'react';
import {useForm} from 'react-final-form';
import styled, {css} from 'styled-components';

import Icon from 'view/components/Icon';

import {
  borderColor,
  cardBoxShadow,
  formItemDropdownContainerZIndex,
  offsetXSm,
  whiteColor,
} from 'constants/styleVars';

import {IDropdownListOption, TInputVariant} from '../../../types';
import {inputStylesMap} from '../../../NW2FormItem.styles';

const Dropdown = styled.ul<{variant: TInputVariant; maxHeight: string}>(
  ({maxHeight, variant}) => {
    const inputStyle = inputStylesMap[variant];

    return css`
      list-style: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      max-height: ${maxHeight};
      overflow-y: auto;
      z-index: ${formItemDropdownContainerZIndex};
      background: ${whiteColor};
      box-shadow: ${cardBoxShadow};
      padding: ${inputStyle.selectDropdownListPadding};
      border-radius: ${inputStyle.selectDropdownListBorderRadius};
      margin-top: ${inputStyle.selectDropdownListTopSpacing};
    `;
  },
);

const DropdownItem = styled.li<{isActive?: boolean; variant: TInputVariant}>(
  ({isActive, variant}) => {
    const inputStyle = inputStylesMap[variant];

    return css`
      display: flex;
      align-items: center;
      gap: ${offsetXSm};
      background-color: ${isActive
        ? inputStyle.selectDropdownActiveItemBg
        : 'transparent'};
      padding: ${inputStyle.selectDropdownItemPadding};
      transition: background-color 0.15s;
      cursor: pointer;

      &:first-of-type {
        border-radius: ${`${inputStyle.selectDropdownListBorderRadius} ${inputStyle.selectDropdownListBorderRadius} 0 0`};
      }

      &:last-of-type {
        border-radius: ${`0 0 ${inputStyle.selectDropdownListBorderRadius} ${inputStyle.selectDropdownListBorderRadius}`};
      }

      &:hover {
        background-color: ${inputStyle.selectDropdownActiveItemBg};
      }

      & + & {
        border-top: 1px solid ${borderColor};
      }
    `;
  },
);

type TProps = {
  focused: boolean;
  inputValue: string | string[];
  name: string;
  optionsList: IDropdownListOption[];
  onBlurHandler: () => void;
  multiple?: boolean;
  variant?: TInputVariant;
  maxHeight: string;
  onChange?: (value: string | ChangeEvent<HTMLSelectElement>) => void;
};

export function DropdownList({
  focused,
  inputValue,
  name,
  optionsList,
  onBlurHandler,
  multiple,
  variant = 'primary',
  maxHeight,
  onChange,
}: TProps) {
  const form = useForm();

  const onDropdownClick = (value: string) => () => {
    if (Array.isArray(inputValue) && inputValue.includes(value)) {
      return form.change(name, [
        ...inputValue.filter((item) => item !== value),
      ]);
    }

    if (multiple) {
      return form.change(name, [...inputValue, value]);
    }
    onBlurHandler();
    if (onChange) return onChange(value);
    form.change(name, value);
  };

  if (!focused || !optionsList?.length) return null;

  return (
    <Dropdown variant={variant} maxHeight={maxHeight}>
      {optionsList.map(({key, text, value}: IDropdownListOption) => {
        const isActive = inputValue === value;

        return (
          <DropdownItem
            key={key}
            isActive={isActive}
            onClick={onDropdownClick(value)}
            variant={variant}
          >
            {isActive && <Icon icon='NW2_CHECKMARK' size={20} />}
            {text}
          </DropdownItem>
        );
      })}
    </Dropdown>
  );
}
