import React, {memo} from 'react';

import Icon from '../Icon';

import {formatDate} from 'utils/stringUtils';
import styled from 'styled-components';

interface IProps {
  checkInDate: string;
  checkOutDate: string;
  isMultiDay: boolean;
  iconMargin?: string;
}

const StyledIcon = styled(Icon)<{iconMargin?: string}>`
  margin: ${({iconMargin}) => iconMargin || '0'};
`;

function DateRangeString({
  checkInDate,
  checkOutDate,
  isMultiDay,
  iconMargin,
}: IProps) {
  return !isMultiDay ? (
    <>{formatDate(checkInDate || checkOutDate, 'DD/MM/YY')}</>
  ) : (
    <>
      <span>{`${formatDate(checkInDate, 'DD/MM/YY')} `}</span>
      <StyledIcon icon='NW2_ARROW_RIGHT_THIN' iconMargin={iconMargin} />
      <span> {` ${formatDate(checkOutDate, 'DD/MM/YY')}`}</span>
    </>
  );
}

export default memo(DateRangeString);
