import {Auth} from 'aws-amplify';
import {notification} from 'antd';

import {isEmailValid} from 'infra/common/venueValidation';
import {useState} from 'react';

export type TSubmitConfirmForgotPassword = {
  email: string;
  code: string;
  newPassword: string;
  callback?: () => void;
  finallyCallback?: () => void;
};

function useForgotPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const errorHandler = (error: Error) => {
    const notificationOptions = {
      message: (error as Error)?.message || 'Something went wrong',
      duration: 4,
    };
    notification.error(notificationOptions);
    setIsLoading(false);
  };
  const submitForgotPassword = (userEmail: string, callback?: () => void) => {
    const isValid = isEmailValid(userEmail);
    if (!isValid) return;
    setIsLoading(true);

    Auth.forgotPassword(userEmail)
      .then(() => {
        if (callback) callback();
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  const submitConfirmationForgotPassword = ({
    email,
    code,
    newPassword,
    callback,
    finallyCallback,
  }: TSubmitConfirmForgotPassword) => {
    setIsLoading(true);
    // Collect confirmation code and new password, then
    Auth.forgotPasswordSubmit(email, code, newPassword)
      .then(() => {
        if (callback) callback();
        setIsLoading(false);
      })
      .catch(errorHandler)
      .finally(finallyCallback);
  };

  return {submitForgotPassword, submitConfirmationForgotPassword, isLoading};
}

export default useForgotPassword;
