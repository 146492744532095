import React from 'react';
import {useDispatch} from 'react-redux';
import {useAppSelector} from 'store/hooks';

import NW2ModalTwoColumns from 'view/components/NW2ModalTwoColumns';

import {NW2PaymentMethodsOptions} from './NW2PaymentMethodsOptions';
import {NW2PaymentMethodsList} from './NW2PaymentMethodsList';
import {setIsPaymentMethodsPopup} from 'store/app/appSlice';

export function NW2PaymentMethodsPopup() {
  const dispatch = useDispatch();
  const isShowed =
    useAppSelector(({app}) => app.authorizationPopups.isPaymentMethodsPopup) ??
    false;
  const onToggleShow = () =>
    dispatch(setIsPaymentMethodsPopup({isPaymentMethodsPopup: false}));

  return (
    <NW2ModalTwoColumns
      title='Payment Methods'
      isShowed={isShowed}
      onToggleShow={onToggleShow}
      colLeft={<NW2PaymentMethodsOptions />}
      colRight={<NW2PaymentMethodsList />}
      isRightColumnBeforeLeft
    />
  );
}
