import React, {SyntheticEvent, useMemo} from 'react';
import DateUtils from 'utils/dateUtils';

import {RequestBlockInfo} from './RequestBlockInfo';

import {ISearchOrder} from 'types/bookingOrders';
import {IRfpHandlers} from 'view/supplier/hooks/useRfpHandlers';
import {IOfferHandlers} from 'view/venue/Offer/hooks/useOfferHandlers';
import {ISearchRequestOfferDto} from 'types/dto/ISearchRequestOfferDto.type';
import {Container, RequestWrapper, Title} from './styles';

interface IProps {
  pendingRequests: ISearchRequestOfferDto[];
  rfpOrders: ISearchOrder[];
  alreadyVisitedDetails: string[];
  onShowOrderDetails: (
    orderId: string,
    isMultiDay: boolean,
    orderNumber: string,
    isRequest?: boolean,
  ) => () => void;
  changeHandlers: {
    rfpHandlers: IRfpHandlers;
    offerHandlers: IOfferHandlers;
  };
  onShowOfferCreate: (requestId: string) => (e?: SyntheticEvent) => void;
  isOffer: boolean;
  onShowBookingChangesDetails: any;
}

export const RequestBookings = ({
  pendingRequests,
  rfpOrders,
  alreadyVisitedDetails,
  onShowOrderDetails,
  changeHandlers,
  onShowOfferCreate,
  isOffer,
  onShowBookingChangesDetails,
}: IProps) => {
  const sortedData = useMemo(
    () =>
      [...rfpOrders, ...pendingRequests].sort((a, b) =>
        DateUtils.isBefore(b.createdAt, a.createdAt) ? 1 : -1,
      ),
    [pendingRequests, rfpOrders],
  );

  return (
    <Container>
      <Title>New requests</Title>
      <RequestWrapper>
        {sortedData.map((request) => (
          <RequestBlockInfo
            key={request.createdAt}
            data={request}
            alreadyVisitedDetails={alreadyVisitedDetails}
            onShowOrderDetails={onShowOrderDetails}
            changeHandlers={changeHandlers}
            onShowOfferCreate={onShowOfferCreate}
            isOffer={isOffer}
            onShowBookingChangesDetails={onShowBookingChangesDetails}
          />
        ))}
      </RequestWrapper>
    </Container>
  );
};
