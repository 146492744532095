import {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {DateTime} from 'luxon';

import {
  setEndDateForVenuesSearch,
  setMultiSearchTimeData,
  setStartDateForVenuesSearch,
} from 'store/search/searchSlice';
import {checkInTime, checkOutTime} from 'constants/defaultOperationalTimes';
import {
  TDateType,
  TMultiDateChangeFn,
  TMultiDateType,
  TTimeChangeFn,
} from './NW2DatePicker/NW2MultiDatePicker.types';
import {EEventType} from 'types/venue';
import {ITimeData} from 'types/dto/ISearch.types';
import {DEFAULT_TIME_DATA, loopOverDates} from './utils';
import {useAppSelector} from 'store/hooks';
import DateUtils, {getISOString} from 'utils/dateUtils';
import {getDateStringFromTime} from 'utils/stringUtils';
import {getFilteredUnitsByEventType} from 'utils/venueUtils';

interface IReturnProps {
  startDate: Date | null;
  endDate: Date | null;
  timeData: ITimeData[];
  onDateChange: TMultiDateChangeFn;
  onTimeStartChange: TTimeChangeFn;
  onTimeEndChange: TTimeChangeFn;
  onReset: () => void;
  onChangeTimeComplete: (callback?: () => void) => void;
}

export function useMultiDatePicker(): IReturnProps {
  const dispatch = useDispatch();

  const storeTimeData = useAppSelector(({search}) => search.initialTimeData);
  const roomType = useAppSelector(({search}) => search.searchCriteria.roomType);

  const [startPickerDate, setStartPickerDate] = useState('');
  const [endPickerDate, setEndPickerDate] = useState('');
  const [timeData, setTimeData] = useState<ITimeData[]>([]);

  const [isDatepickerInitialised, setIsDatepickerInitialised] = useState(false);
  useEffect(() => {
    // INIT
    if (isDatepickerInitialised) return;

    const meetDaysTimeData = getFilteredUnitsByEventType(storeTimeData);

    const timeStart = meetDaysTimeData[0].timeStart;
    const timeEnd = meetDaysTimeData.at(-1)?.timeEnd || '';

    setStartPickerDate(timeStart);
    setEndPickerDate(timeEnd);
    setIsDatepickerInitialised(true);

    const isMultiDay = !DateUtils.isSameDay(timeStart, timeEnd);
    const searchTimeData = [
      {
        date: timeStart,
        timeEnd,
        timeStart,
        eventType: EEventType.DAY,
      },
    ];

    setTimeData(isMultiDay ? meetDaysTimeData : searchTimeData);
  }, [isDatepickerInitialised, storeTimeData]);

  const onTimeChange = useCallback(
    ({
      key,
      index,
      payload,
    }: {
      key: 'timeStart' | 'timeEnd';
      index: number;
      payload: TDateType;
    }) => {
      const newTimeData = timeData.map((data, dateIndex) => {
        if (index === dateIndex) {
          return {
            ...data,
            [key]: getISOString(payload),
          };
        }

        return data;
      });

      dispatch(setMultiSearchTimeData(newTimeData));
      setTimeData(newTimeData);
    },
    [dispatch, timeData],
  );

  const onTimeStartChange = useCallback(
    (index: number) => (payload: TDateType) => {
      onTimeChange({key: 'timeStart', index, payload});
    },
    [onTimeChange],
  );

  const onTimeEndChange = useCallback(
    (index: number) => (payload: TDateType) => {
      onTimeChange({key: 'timeEnd', index, payload});
    },
    [onTimeChange],
  );

  const onDateChange = useCallback(
    (payload: TMultiDateType) => {
      const handleSetData = (startDate: string, endDate = '') => {
        const timeDates = loopOverDates(startDate, endDate);

        setTimeData(timeDates);
        setStartPickerDate(startDate);
        setEndPickerDate(endDate);

        dispatch(setMultiSearchTimeData(timeDates));
        dispatch(
          setStartDateForVenuesSearch(
            getDateStringFromTime(timeDates[0].timeStart, startDate),
          ),
        );
        dispatch(
          setEndDateForVenuesSearch(
            getDateStringFromTime(timeDates[0].timeEnd, endDate || startDate),
          ),
        );
      };

      if (Array.isArray(payload)) {
        const [start, end] = payload;

        const startDate = start ? getISOString(DateTime.fromJSDate(start)) : '';
        const endDate = end ? getISOString(DateTime.fromJSDate(end)) : '';

        handleSetData(startDate, endDate);
      } else {
        handleSetData(getISOString(payload));
      }
    },
    [dispatch],
  );

  const onReset = useCallback(() => {
    // reset local state
    setStartPickerDate('');
    setEndPickerDate('');
    setTimeData(DEFAULT_TIME_DATA);

    // reset store state
    dispatch(setStartDateForVenuesSearch(checkInTime));
    dispatch(setEndDateForVenuesSearch(checkOutTime));
    dispatch(setMultiSearchTimeData(DEFAULT_TIME_DATA));
  }, [dispatch]);

  useEffect(() => {
    // reset on room type change
    onReset();
  }, [onReset, roomType]);

  const onChangeTimeComplete = useCallback(
    (callback?: () => void) => {
      const timeStartForBackend = timeData[0]?.timeStart;
      const timeEndForBackend = timeData[timeData.length - 1]?.timeEnd;

      dispatch(
        setStartDateForVenuesSearch(
          getDateStringFromTime(timeStartForBackend, timeStartForBackend),
        ),
      );
      dispatch(
        setEndDateForVenuesSearch(
          getDateStringFromTime(timeEndForBackend, timeEndForBackend),
        ),
      );
      dispatch(setMultiSearchTimeData(timeData));

      if (typeof callback === 'function') {
        callback();
      }
    },
    [dispatch, timeData],
  );

  return {
    startDate: startPickerDate
      ? DateTime.fromISO(startPickerDate).toJSDate()
      : null,
    endDate: endPickerDate ? DateTime.fromISO(endPickerDate).toJSDate() : null,
    timeData,
    onDateChange,
    onTimeStartChange,
    onTimeEndChange,
    onChangeTimeComplete,
    onReset,
  };
}
