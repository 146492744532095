import {
  EBookableWith,
  EResourcesType,
  IExtrasOption,
} from 'types/dto/IExtras.type';
import {EBookingStatus} from 'types/booking';
import {ERoomType} from 'types/dto/ERoomType.type';
import {IUnit} from 'types/dto/IUnit.types';
import {EEventType, ERoomSchemaNames} from 'types/venue';
import {Routes} from 'constants/routes';
import DateUtils from './dateUtils';
import {IOfferUnitExtra, IOfferUnitPrice} from 'types/offer';
import {getExtraNumericInputVisibility} from './helpers';
import {IOfferRequestReviewUnitExtra} from 'view/venue/Offer/components/OfferRequestReviewUnitCard/types';
import {IExtraResponse} from '../types/dto/IPublicVenue';
import {TSearchCriteriaExtra} from 'types/search';

export const filterByAvailability = <T>(
  extras: (T & {bookableWith: EBookableWith[]})[],
  roomType: ERoomType,
) =>
  extras?.filter((extra) =>
    extra.bookableWith.includes(roomType as unknown as EBookableWith),
  );

export const filterPreviewExtrasByExtraType = <T>(
  extras?: (T & {extraType?: EResourcesType})[],
  extraType?: EResourcesType,
) => extras?.filter((extra) => extra.extraType === extraType) || [];

export const replaceToComma = (price: string): string => {
  return price.replace('.', ',');
};

export const getFilteredExtrasByType = (
  unitExtras: IOfferUnitExtra[],
  type: EResourcesType,
  extrasOption: IExtrasOption[],
) =>
  unitExtras
    .map(({code, quantity, totalPrice}) => {
      const extra = extrasOption.find((extra) => extra.code === code);
      if (extra) {
        return {
          ...extra,
          quantity,
          totalPrice,
        };
      }
    })
    .filter((extra) => extra?.type === type)
    .sort((a, b) =>
      getExtraNumericInputVisibility(a?.name) ===
      getExtraNumericInputVisibility(b?.name)
        ? 0
        : getExtraNumericInputVisibility(a?.name)
        ? -1
        : 1,
    ) as IOfferRequestReviewUnitExtra[];

export const getFilteredUnitsByEventType = <T extends {eventType?: EEventType}>(
  units: T[],
  eventType = EEventType.DAY,
): T[] =>
  units?.filter((unit) => !unit.eventType || unit.eventType === eventType) ||
  [];

export const formatFloatWithOneNumber = (
  price?: number | string | null,
): string => {
  if (!price) return '0,00';
  const priceToFixed = Number(price).toFixed(2);
  return replaceToComma(priceToFixed);
};

export const getShowCancelBookingButton = (bookingStatus?: EBookingStatus) => {
  const isBookingConfirmed = bookingStatus === EBookingStatus.CONFIRMED;
  const isBookingPending = bookingStatus === EBookingStatus.RFP_PENDING;
  const isRTCPending = bookingStatus === EBookingStatus.RTC_PENDING;

  return isBookingConfirmed || isBookingPending || isRTCPending;
};

type TDistance = 'km' | 'mi';
export const distanceConverter = (type: TDistance, value: number): number => {
  const COEFFICIENT = 1.609;

  if (type === 'km') {
    const kms = Number(value) * COEFFICIENT;
    return +kms.toFixed(2);
  }

  if (type === 'mi') {
    const miles = Number(value) / COEFFICIENT;

    return +miles.toFixed(2);
  }

  return 0;
};

type TDimension = 'm' | 'ft';
export const dimensionsConverter = (
  type: TDimension,
  value: string | number,
  isSquare?: boolean,
  precision = 2,
): number => {
  const COEFFICIENT = isSquare ? 10.7639 : 3.28084;

  if (type === 'm') {
    const meters = Number(value) * COEFFICIENT;
    return +meters.toFixed(precision);
  }

  if (type === 'ft') {
    const feet = Number(value) / COEFFICIENT;

    return +feet.toFixed(precision);
  }

  return 0;
};

export const findRfpStatus = (el: IUnit) => el?.isRfp || el?.rfp;
export const findNotAvailableStatus = (el: IUnit) => !el.available;

enum EParticipantQuantity {
  Small = 9,
  Average = 20,
  Large = 50,
}

export const getSeatingPlanByParticipants = (
  participants: number,
): ERoomSchemaNames => {
  switch (true) {
    case participants > EParticipantQuantity.Large:
      return ERoomSchemaNames.THEATRE;
    case participants > EParticipantQuantity.Average:
      return ERoomSchemaNames.CLASSROOM;
    case participants > EParticipantQuantity.Small:
      return ERoomSchemaNames.U_SHAPE;
    default:
      return ERoomSchemaNames.BLOCK;
  }
};

export const getHmdRegLink = (link: string) =>
  link.substring(link.indexOf(Routes.registerVenue));

interface IGetRoomRentalPriceByDateRange {
  priceHour?: string | number;
  priceHalfDay?: string | number;
  priceDay?: string | number;
  checkOut: string;
  checkIn: string;
}

export const getRoomRentalPriceByDateRange = ({
  priceHour,
  priceHalfDay,
  priceDay,
  checkOut,
  checkIn,
}: IGetRoomRentalPriceByDateRange): number => {
  if (!checkOut || !checkIn) return 0;

  // calculate room rental price by booked time
  const bookedHours = DateUtils.getTotalHours(checkIn, checkOut);
  const hourlyPriceSum = priceHour ? Number(priceHour) * bookedHours : 0;

  if (
    priceHalfDay &&
    bookedHours < 6 &&
    Number(priceHalfDay) <= hourlyPriceSum
  ) {
    return Number(priceHalfDay);
  }

  if (priceDay && bookedHours >= 6 && Number(priceDay) <= hourlyPriceSum) {
    return Number(priceDay);
  }

  return hourlyPriceSum;
};

export const getPriceRange = (prices: IOfferUnitPrice[]) => {
  const pricesMap = prices.map((item) => item.value);

  return {
    min: Math.min(...pricesMap),
    max: Math.max(...pricesMap),
  };
};

export const convertExtraItem = <
  T extends IExtraResponse | TSearchCriteriaExtra,
>(
  extras: T[],
) =>
  extras.map(({quantity, code}) => ({
    quantity,
    code,
  }));
