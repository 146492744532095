import React from 'react';

import TextPopover from 'view/venue/CreateVenue/pages/ContactDetails/components/ContactDetailsForm/TextPopover';
import useLegalEntityField from '../../../hooks/useLegalEntityField';

import {NW2FormItemInput} from 'view/components/NW2FormItem/NW2FormItem';
import {requiredFieldRules} from 'utils/finalFormFieldRules';
import {ESupplierBusinessInfoFormFields} from 'view/components/NW2Forms/NW2SupplierLegalDetailsForm/types';

import {pagePadding} from 'styles/configs/offset';
import {FormGroup} from 'view/auth/utils/auth.styles';
import {SectionTitle} from 'view/components/NMMSection/NMMSection.styles';
import {fontSizeSm} from 'styles/configs/typography';
import {NW2Gray600Color} from 'styles/configs/colors';
import {FormInnerContainer} from '../EditPaymentForm.styles';
import {INPUT_HEIGHT} from 'view/components/NW2FormItem/NW2FormItem.styles';

export function LegalInformationBlock() {
  const isEntityTypeIndividual = useLegalEntityField();

  if (isEntityTypeIndividual) return null;
  return (
    <FormGroup columnNumber={1} gap={pagePadding}>
      <SectionTitle titleMarginBottom='0' titleFontSize={fontSizeSm}>
        LEGAL INFORMATION
      </SectionTitle>
      <FormInnerContainer>
        <NW2FormItemInput
          name={ESupplierBusinessInfoFormFields.REGISTRATION_NUMBER}
          type='text'
          label='Registration number*'
          placeholder='Registration number*'
          labelColor={NW2Gray600Color}
          rules={requiredFieldRules('Registration number', {maxLength: 21})}
        />
        <TextPopover textIndex={2} iconHeight={INPUT_HEIGHT} />
      </FormInnerContainer>
      <FormInnerContainer>
        <NW2FormItemInput
          name={ESupplierBusinessInfoFormFields.TAX_NUMBER}
          type='text'
          label='Company Tax ID*'
          placeholder='Company Tax ID*'
          labelColor={NW2Gray600Color}
          rules={requiredFieldRules('Company Tax ID', {maxLength: 21})}
        />
        <TextPopover textIndex={3} iconHeight={INPUT_HEIGHT} />
      </FormInnerContainer>
    </FormGroup>
  );
}
