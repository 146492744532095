import {
  EEventType,
  ERoomSchemaNames,
  IUnitDimensions,
  IVenue,
} from 'types/venue';
import {ICustomer} from './dto/IUser.types';
import {TImage} from './app';
import {EResourcesCode} from './dto/IExtras.type';
import {ILocation} from './dto/ILocation.types';
import {IAmenity} from './dto/IAmenity';

export enum ERequestStatus {
  REQUEST_PENDING = 'REQUEST_PENDING', // CUSTOMER Request pending DETAILS => Request sent
  REQUEST_DECLINED = 'REQUEST_DECLINED', // CUSTOMER Declined by venue DETAILS => Request Declined
  REQUEST_CANCELED = 'REQUEST_CANCELED', // DETAILS => Request Cancelled
  REQUEST_EXPIRED = 'REQUEST_EXPIRED',
}

export enum EOfferStatus {
  OFFER_PENDING = 'OFFER_PENDING', // Offer sent CUSTOMER Offer received DETAILS => go on Step 3
  OFFER_DECLINED = 'OFFER_DECLINED', // CUSTOMER Declined by booker DETAILS => Offer declined
  OFFER_CONFIRMED = 'OFFER_CONFIRMED',
  OFFER_ACCEPTING_EXPIRED = 'OFFER_ACCEPTING_EXPIRED', // first OFFER_PENDING => then become EXPIRED and show normal Expired
}

export interface IOfferUnitPrice {
  value: number;
  currency: string;
}

export interface IOfferUnitExtra {
  quantity: number;
  extraId?: number;
  code?: EResourcesCode;
  totalPrice?: IOfferUnitPrice;
  price?: IOfferUnitPrice;
  accommodationExtraId: number;
}

export type TOfferUnitInfo = {
  documents: TImage[];
  unitName: string;
  unitDimensions?: IUnitDimensions;
};

export interface IRequestUnit {
  id: string;
  checkIn: string;
  checkOut: string;
  updatedAt?: string;
  createdAt?: string;
  participants: number;
  setupStyle: ERoomSchemaNames;
  extras: IOfferUnitExtra[];
  eventType: EEventType;
  unitId?: number;
}

export interface IOfferUnit extends IRequestUnit {
  unitQuantity?: number;
  totalPrice?: IOfferUnitPrice;
  maxParticipants?: number;
}

export interface IOfferRequestUnit extends IOfferUnit {
  unitInfo?: TOfferUnitInfo;
  unitPrice?: IOfferUnitPrice;
  packageId?: number;
  packagePrice?: number;
}

interface IOfferRequest {
  id: string;
  accommodationId: number;
  customer: ICustomer;
  createdAt: string;
  updatedAt: string;
  cancelledAt: string;
  resolvedAt: string;
  checkIn: string;
  checkOut: string;
  declinedReason: string;
  declinedMessage: string;
  resolverName: string;
  eventName: string;
  expirationDate: string;
  price: IOfferUnitPrice;
  number: string;
  isAlternative: boolean;
  creatorName: string;
  sentAt: string | null;
  company: string | null;
  optionDate: string;
  groupNumber: string;
  groupId: string;
  venue: IVenue;
  totalRequestPrice?: IOfferUnitPrice | null;
}

export interface IRequest extends IOfferRequest {
  offerId: string;
  items: IRequestUnit[];
  status: ERequestStatus;
}

export interface IOffer extends IOfferRequest {
  orderId: string;
  items: IOfferRequestUnit[];
  status: EOfferStatus;
}

export enum ERequestDetailsSections {
  STATUS = 'Status',
  PRE_ARRIVAL = 'Pre-arrival',
  REQUEST_DETAILS = 'Request details',
  OFFER_DETAILS = 'Offer details',
  POST_EVENT = 'Post event',
  TERMS_AND_CONDITIONS = 'Terms & conditions',
  ADDITIONAL_ACTIONS = 'Additional actions',
  OPTION_DATE = 'Option period',
}

export enum ERequestDetailsType {
  // Request
  SINGLE_PENDING = 'SINGLE_PENDING',
  MULTI_PENDING = 'MULTI_PENDING',

  SINGLE_DECLINED = 'SINGLE_DECLINED',
  MULTI_DECLINED = 'MULTI_DECLINED',

  SINGLE_CANCELED = 'SINGLE_CANCELED',
  MULTI_CANCELED = 'MULTI_CANCELED',

  SINGLE_EXPIRED = 'SINGLE_EXPIRED',
  MULTI_EXPIRED = 'MULTI_EXPIRED',

  // Offer
  SINGLE_OFFER_CREATED = 'SINGLE_OFFER_CREATED',
  MULTI_OFFER_CREATED = 'MULTI_OFFER_CREATED',

  SINGLE_OFFER_PENDING = 'SINGLE_OFFER_PENDING',
  MULTI_OFFER_PENDING = 'MULTI_OFFER_PENDING',

  SINGLE_OFFER_DECLINED = 'SINGLE_OFFER_DECLINED',
  MULTI_OFFER_DECLINED = 'MULTI_OFFER_DECLINED',

  SINGLE_OFFER_CANCELED = 'SINGLE_OFFER_CANCELED',
  MULTI_OFFER_CANCELED = 'MULTI_OFFER_CANCELED',

  SINGLE_OFFER_CONFIRMED = 'SINGLE_OFFER_CONFIRMED',
  MULTI_OFFER_CONFIRMED = 'MULTI_OFFER_CONFIRMED',

  SINGLE_OFFER_SENDING_EXPIRED = 'SINGLE_OFFER_SENDING_EXPIRED',
  MULTI_OFFER_SENDING_EXPIRED = 'MULTI_OFFER_SENDING_EXPIRED',

  SINGLE_OFFER_ACCEPTING_EXPIRED = 'SINGLE_OFFER_ACCEPTING_EXPIRED',
  MULTI_OFFER_ACCEPTING_EXPIRED = 'MULTI_OFFER_ACCEPTING_EXPIRED',
}

export type TOfferDetails = IRequestV2 | IOfferV2 | Record<string, never>;

export enum EDeclineItemType {
  RFP = 'RFP',
  OFFER = 'OFFER',
  CUSTOMER = 'CUSTOMER',
  NOT_REGISTERED_SUPPLIER = 'NOT_REGISTERED_SUPPLIER',
  REQUEST_GROUP = 'REQUEST_GROUP',
  SINGLE_REQUEST = 'SINGLE_REQUEST',
}

// v2 requests
export interface IRequestDayItem {
  id: string;
  unitId: number;
  checkIn: string;
  checkOut: string;
  updatedAt: string;
  createdAt: string;
  participants: number;
  setupStyle: ERoomSchemaNames;
  eventType: EEventType;
  extras: IOfferUnitExtra[];
  unitPrice: IOfferUnitPrice;
  unitInfo: TOfferUnitInfo;
  roomSetup?: ERoomSchemaNames;
  capacity?: number;
  amenities?: IAmenity[];
}

export interface IOfferRequestDay {
  id?: string;
  eventType: EEventType;
  bedrooms: IOfferUnitExtra[];
  checkIn: string;
  checkOut: string;
  participants?: number;
  foodAndBeverage?: IOfferUnitExtra[];
  updatedAt?: string;
  createdAt?: string;
}

export interface IRequestDay extends IOfferRequestDay {
  items?: IRequestDayItem[];
  dayPrice?: IOfferUnitPrice;
  totalPrice?: IOfferUnitPrice;
}

export interface IRequestV2 extends IOfferRequest {
  offerId: string;
  status: ERequestStatus;
  days: IRequestDay[];
  totalRequestPrice: IOfferUnitPrice | null;
}

// V2 offers
export interface IOfferDayItem extends IRequestDayItem {
  requestItemId: string;
  totalPrice?: IOfferUnitPrice;
  maxParticipants: number;
}

export interface IOfferDay extends IOfferRequestDay {
  requestDayId: string;
  totalPrice?: IOfferUnitPrice;
  items: IOfferDayItem[];
  dayPrice?: IOfferUnitPrice;
}

export interface IOfferV2 extends IOfferRequest {
  orderId: string;
  status: EOfferStatus;
  days: IOfferDay[];
}

// shortlist
export type TShortListItem = {
  id: number;
  name: string;
  location: ILocation;
  img: string;
};

export type TShortList = {
  // todo clarify
  items: TShortListItem[];
  isMaxItemsReached: boolean;
  checkIn: string;
  checkOut: string;
  isMultiDay: boolean;
  participants?: number;
  hasEquipment?: boolean;
  hasCatering?: boolean;
  hasBedrooms?: boolean;
};

export interface IGroupDetails {
  requests: IRequestV2[];
  offers: IOfferV2[];
  groupStatus: EGroupStatus;
}

export type TCompareList = {
  items: IVenue[];
  isLoading: boolean;
};

export enum EGroupStatus {
  GROUP_REQUEST_PENDING = 'GROUP_REQUEST_PENDING',
  GROUP_CANCELLED = 'GROUP_CANCELLED',
  GROUP_EXPIRED = 'GROUP_EXPIRED',
  GROUP_OFFER_PENDING = 'GROUP_OFFER_PENDING',
  GROUP_PROCESSED = 'GROUP_PROCESSED',
}

export interface IRequestFormPayload {
  days: IRequestDay[];
  customer: {
    fullName: string;
    email: string;
    company: string;
    phone: string;
    id?: number;
  };
  destination: string;
  comment: string;
}
