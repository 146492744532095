import React, {FC, useEffect, useState} from 'react';
import queryString from 'query-string';
import {useSelector} from 'react-redux';
import {useNavigate, useLocation} from 'react-router-dom';
import _get from 'lodash/get';

import {useAppDispatch} from 'store/hooks';
import AddUserButton from './components/AddUserButton';
import Icon from 'view/components/Icon/Icon';
import PageContent from 'view/common/PageContent';
import CompanyUsers from './components/CompanyUsers';
import SiderMenu from '../components/SiderMenu';
import useGetCorporateAccountCompanyName from 'hooks/useGetCorporateAccountCompanyName';
import NWLink from 'view/components/NWLink';

import {PageTitle} from 'view/components/Typography';
import {NoItemsBlock, NoItemsContent, NoItemsText} from '../common.styles';
import {RootState} from 'store/types';
import {EMPTY_OBJECT} from 'constants/app';
import {Routes} from 'constants/routes';
import {getCorporateAccountCompanyUsers} from 'store/admin/apiActions';
import {
  setCorporateAccountCompanyUsers,
  setTotalCorporateAccountCompanyUsers,
} from 'store/admin/adminSlice';

const CompanyAccount: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState('');

  const queryData = queryString.parse(location.search.slice(1)) || EMPTY_OBJECT;
  const companyIdFromQuery = queryData.id || '';
  const companyId = +companyIdFromQuery;

  const totalUsers: number = useSelector((state: RootState) =>
    _get(state, 'admin.totalCorporateAccountCompanyUsers'),
  );

  const page: number = useSelector((state: RootState) =>
    _get(state, 'admin.corporateAccountCompanyUsersPage'),
  );

  const size: number = useSelector((state: RootState) =>
    _get(state, 'admin.corporateAccountCompanyUsersSize'),
  );

  const loading: boolean = useSelector((state: RootState) =>
    _get(state, 'admin.corporateAccountCompanyUsersLoading'),
  );

  /* Get company name hook */
  useGetCorporateAccountCompanyName({companyId, setCompanyName});

  /* Fetch company users */
  useEffect(() => {
    if (companyId) {
      dispatch(
        getCorporateAccountCompanyUsers({
          id: companyId,
          page,
          size,
        }),
      );
    }

    return () => {
      dispatch(setCorporateAccountCompanyUsers([]));
      dispatch(setTotalCorporateAccountCompanyUsers(0));
    };
  }, [page, size, companyId, dispatch]);

  const goToCorporateAccounts = () =>
    navigate({pathname: Routes.companiesOverview});

  return (
    <>
      <SiderMenu />

      <PageContent hasSider>
        <NWLink
          secondary
          text='Accounts overview'
          onClick={goToCorporateAccounts}
        />
        <PageTitle>{companyName ? companyName : ''}</PageTitle>

        {totalUsers || loading ? (
          <CompanyUsers companyId={companyId} />
        ) : (
          <NoItemsBlock>
            <NoItemsContent>
              <Icon icon='DOCUMENT' secondary />
              <NoItemsText>No users added yet</NoItemsText>
              <AddUserButton companyId={companyId} />
            </NoItemsContent>
          </NoItemsBlock>
        )}
      </PageContent>
    </>
  );
};

export default CompanyAccount;
