import React from 'react';
import {useSelector} from 'react-redux';
import {useAppSelector} from 'store/hooks';
import _get from 'lodash/get';

import {PaymentMethodsListItem} from './PaymentMethodsListItem';
import {TStoredCard} from 'store/payment/types';
import {findDefaultCreditCard} from 'view/components/AdyenPayment/helpers';

import {
  Container,
  StyledParagraph,
  StyledRadioGroupWrapper,
  Title,
  Wrapper,
} from 'view/components/PaymentMethodsView/NW2PaymentMethodsView.styles';

export function NW2PaymentMethodsList() {
  const userId = useAppSelector(({app}) => app.user.id);
  const storedPaymentMethods: TStoredCard[] = useSelector((state) =>
    _get(state, 'payment.paymentMethodsResponse.storedPaymentMethods', []),
  );
  const defaultCreditCardId = useSelector((state) =>
    _get(state, 'payment.defaultCreditCardId'),
  );
  const isDesktop = useAppSelector(({app}) => app.deviceType.isDesktop);

  const defaultCardDetails = findDefaultCreditCard(
    storedPaymentMethods,
    defaultCreditCardId,
    userId,
  );

  if (!defaultCardDetails) return null;
  return (
    <Container>
      <Title>Your credit cards</Title>
      {isDesktop && (
        <Wrapper>
          <div />
          <StyledParagraph>Name on card</StyledParagraph>
          <StyledParagraph>Expiry date</StyledParagraph>
          <StyledParagraph>Actions</StyledParagraph>
        </Wrapper>
      )}
      <StyledRadioGroupWrapper>
        {storedPaymentMethods.map((card: TStoredCard) => (
          <PaymentMethodsListItem
            key={card.id}
            isDesktop={isDesktop}
            defaultCardDetails={defaultCardDetails}
            {...card}
          />
        ))}
      </StyledRadioGroupWrapper>
    </Container>
  );
}
