import React, {memo} from 'react';
import {DateTime} from 'luxon';
import DatePicker, {ReactDatePickerProps} from 'react-datepicker';
import {useAppSelector} from 'store/hooks';

import {NW2MultiDatePickerMobileWithCustomHeader} from './NW2MultiDatePickerMobileWithCustomHeader';

import {ERoomType} from 'types/dto/ERoomType.type';
import {StyledWrapper} from './NW2MultiDatePicker.styles';

interface IProps extends ReactDatePickerProps {
  isSingleDayDatePicker?: boolean;
  withMonthYearDropdown?: boolean;
  showMonthYearDropdown?: boolean;
}

export const NW2MultiDatePicker = memo(
  ({
    startDate,
    endDate,
    onChange,
    isSingleDayDatePicker,
    withMonthYearDropdown,
    showMonthYearDropdown = true,
    ...rest
  }: IProps) => {
    const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

    const roomType = useAppSelector(
      ({search}) => search.searchCriteria.roomType,
    );

    const config = {
      minDate: DateTime.now().toJSDate(),
      timeIntervals: 60,
      timeFormat: 'HH:mm',
      timeCaption: 'time',
      dateFormat: 'd MMM, yyyy',
      calendarStartDay: 1,
      inline: true,
    };

    const configForMonthYearDropdown = withMonthYearDropdown
      ? {
          maxDate: DateTime.now().plus({years: 100}).toJSDate(),
          showMonthYearDropdown,
          scrollableMonthYearDropdown: true,
        }
      : {};

    const configForSingleDayDatePicker = isSingleDayDatePicker
      ? {
          selectsRange: false,
          endDate: null,
        }
      : {};

    const endDateForPicker = roomType !== ERoomType.WORK_SPACE ? endDate : null;
    const selectsRange = roomType !== ERoomType.WORK_SPACE;

    const datePickerConfig = {
      selected: startDate,
      onChange,
      startDate,
      endDate: endDateForPicker,
      selectsRange,
      ...config,
      ...configForMonthYearDropdown,
      ...configForSingleDayDatePicker,
      ...rest,
    };

    return (
      <StyledWrapper
        isMobile={isMobile}
        withMonthYearDropdown={withMonthYearDropdown}
      >
        {!isMobile ? (
          <DatePicker {...datePickerConfig} />
        ) : (
          <NW2MultiDatePickerMobileWithCustomHeader {...datePickerConfig} />
        )}
      </StyledWrapper>
    );
  },
);
