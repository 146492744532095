import {ApiType, IApi} from '../common/http.service';
import {
  EGroupStatus,
  EOfferStatus,
  ERequestStatus,
  IOfferV2,
  IRequest,
  IRequestFormPayload,
  IRequestV2,
} from 'types/offer';
import {
  ICreateOfferPayload,
  IDeclineSupplierOfferByIdPayload,
  IDeclineSupplierOfferByTokenPayload,
} from 'store/offers/types';
import {
  IBookingOrderResponse,
  IOfferOrderPayload,
} from 'types/dto/IBooking.types';
import {ISearchGroupDto} from 'types/dto/ISearchGroupDto.type';

const offersRepository = (api: IApi) => ({
  getOfferRequestById: async (id: string): Promise<IRequestV2> =>
    await api.get(ApiType.Offers, `requests/v2/${id}`),

  getOfferById: async (id: string): Promise<IOfferV2> =>
    await api.get(ApiType.Offers, `offers/v2/${id}`),

  getOfferRequestByToken: async (token: string): Promise<IRequestV2> =>
    await api.get(ApiType.Offers, `requests/v2/token/${token}`),

  requestOffer: async (payload: IRequestV2): Promise<IRequestV2[]> =>
    await api.add(ApiType.Offers, `groups`, payload),

  getGroupById: async (id: string): Promise<IRequestV2[]> =>
    await api.get(ApiType.Offers, `groups/${id}`),

  getGroupsByStatus: async ({
    customerId,
    status,
  }: {
    customerId: number;
    status: EGroupStatus[];
  }): Promise<ISearchGroupDto[]> =>
    await api.get(
      ApiType.Offers,
      `groups?customerId=${customerId}&status=${status}`,
    ),

  requestGroupCancel: async (orderId: string): Promise<IRequestV2[]> =>
    await api.update(ApiType.Offers, `groups/cancel/${orderId}`),

  getOfferRequests: async ({
    id,
    status,
    isCustomer,
  }: {
    id: number;
    status: ERequestStatus;
    isCustomer?: boolean;
  }) =>
    await api.get(
      ApiType.Offers,
      `requests?${
        isCustomer ? 'userId' : 'accommodationId'
      }=${id}&status=${status}`,
    ),

  requestDecline: async (id: string): Promise<IRequestV2> =>
    await api.update(ApiType.Offers, `requests/v2/cancel/${id}`),

  declineOfferRequestByToken: async ({
    token,
    reason,
    message,
    resolverName,
  }: IDeclineSupplierOfferByTokenPayload): Promise<IRequestV2> =>
    await api.update(ApiType.Offers, `requests/v2/decline/token/${token}`, {
      declineReason: reason,
      additionalMessage: message,
      resolverName,
    }),

  declineOfferRequestById: async ({
    orderId,
    reason,
    message,
    resolverName,
  }: IDeclineSupplierOfferByIdPayload): Promise<IRequestV2> =>
    await api.update(ApiType.Offers, `requests/v2/decline/${orderId}`, {
      declineReason: reason,
      additionalMessage: message,
      resolverName,
    }),

  declineOfferById: async ({
    orderId,
    reason,
    message,
  }: IDeclineSupplierOfferByIdPayload): Promise<IRequestV2> =>
    await api.update(ApiType.Offers, `offers/v2/decline/${orderId}`, {
      declineReason: reason,
      additionalMessage: message,
    }),

  cancelOfferById: async ({
    orderId,
    reason,
    message,
    resolverName,
  }: IDeclineSupplierOfferByIdPayload): Promise<IRequest> =>
    await api.update(ApiType.Offers, `offers/cancel/${orderId}`, {
      // todo looks like we haven't it on BE side, need to check
      declineReason: reason,
      additionalMessage: message,
      resolverName,
    }),

  createOffer: async (payload: ICreateOfferPayload): Promise<IOfferV2> =>
    await api.add(ApiType.Offers, 'offers/v2', payload),

  acceptOffer: async (
    data: IOfferOrderPayload,
  ): Promise<IBookingOrderResponse> =>
    await api.update(ApiType.Offers, `offers/accept`, data),

  getOffers: async ({
    id,
    status,
    isCustomer,
  }: {
    id: number;
    status: EOfferStatus;
    isCustomer?: boolean;
  }) =>
    await api.get(
      ApiType.Offers,
      `offers?${
        isCustomer ? 'userId' : 'accommodationId'
      }=${id}&status=${status}`,
    ),

  getRequestsCount: async (id: number) =>
    await api.get(ApiType.Offers, `requests/count?accommodationId=${id}`),

  getOfferCreator: async (orderId: string) =>
    await api.get(ApiType.Offers, `offers/creator?orderId=${orderId}`),

  sendRequestForm: async (payload: IRequestFormPayload) =>
    await api.add(ApiType.Offers, `requests/support`, payload),
});

export default offersRepository;
