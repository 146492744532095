import React, {CSSProperties, ReactNode} from 'react';
import {Popover} from 'antd';
import {AbstractTooltipProps} from 'antd/lib/tooltip';

import Icon from 'view/components/Icon';

import {
  DrawerTitle,
  PopoverTitle,
  StyledDrawer,
  StyledPopoverTitleWrapper,
  PopoverGlobalStyle,
} from './NW2PopoverDrawer.styles';
import {modalSmallWidth} from 'constants/styleVars';

export enum ENW2PopoverDrawerPlacement {
  TOP_LEFT = 'topLeft',
  TOP = 'top',
  TOP_RIGHT = 'topRight',
  LEFT_TOP = 'leftTop',
  LEFT = 'left',
  LEFT_BOTTOM = 'leftBottom',
  RIGHT_TOP = 'rightTop',
  RIGHT = 'right',
  RIGHT_BOTTOM = 'rightBottom',
  BOTTOM_LEFT = 'bottomLeft',
  BOTTOM = 'bottom',
  BOTTOM_RIGHT = 'bottomRight',
}

interface IProps extends Partial<AbstractTooltipProps> {
  header?: ReactNode;
  body: ReactNode;
  drawerFooter?: ReactNode | null;
  popoverBodyStyle?: CSSProperties;
  overlayInnerStyle?: CSSProperties;
  popoverWidth?: string;
  drawerHeight?: string;
  isMobile: boolean;
  clickable?: boolean;
  onClose?: () => void;
  handleVisibleChange?: (val: boolean) => void;
}

function NW2PopoverDrawer({
  header,
  body,
  placement,
  drawerFooter = null,
  popoverBodyStyle,
  overlayInnerStyle,
  popoverWidth,
  drawerHeight,
  isMobile,
  clickable,
  open,
  onClose,
  handleVisibleChange,
  children,
}: IProps) {
  const popoverTrigger = clickable ? 'click' : 'hover';

  const popoverTitle = () => (
    <StyledPopoverTitleWrapper>
      <PopoverTitle>{header}</PopoverTitle>
      {clickable && <Icon icon='NW2_CLOSE' onClick={onClose} />}
    </StyledPopoverTitleWrapper>
  );

  const popoverStyle = {
    ...popoverBodyStyle,
    width: popoverWidth || modalSmallWidth,
  };

  return (
    <>
      {isMobile ? (
        <>
          <StyledDrawer
            title={<DrawerTitle>{header}</DrawerTitle>}
            footer={drawerFooter}
            onClose={onClose}
            open={open}
            height={drawerHeight || 'auto'}
            closeIcon={<Icon icon='NW2_CLOSE' />}
            placement='bottom'
            destroyOnClose
          >
            {body}
          </StyledDrawer>
          {children}
        </>
      ) : (
        <Popover
          title={header ? popoverTitle : null}
          content={body}
          trigger={popoverTrigger}
          open={open}
          onOpenChange={handleVisibleChange}
          overlayStyle={popoverStyle}
          overlayInnerStyle={overlayInnerStyle}
          placement={placement}
          overlayClassName='custom-popover'
        >
          {children}
        </Popover>
      )}

      <PopoverGlobalStyle />
    </>
  );
}

export default NW2PopoverDrawer;
