import React from 'react';
import DateUtils, {DateFormats} from 'utils/dateUtils';

import {offsetXXLg} from 'constants/styleVars';
import {
  DefaultLeftSideItem,
  SubtitleWrapper,
} from 'view/venue/NW2BookingPreview/components/Space/Space.styles';
import {useAppSelector} from 'store/hooks';
import {EEventType} from 'types/venue';
import {DefaultRequestSpace} from 'view/venue/NW2BookingPreview/components/Space/DefaultRequestSpace';

type TProps = {
  hasPreArrivals?: boolean;
  hasPostEvents?: boolean;
  hasDots?: boolean;
  isDesktop?: boolean;
};

const {getHoursAndMinutes, getDateWithoutHours} = DateUtils;

export const DefaultRequestLeftSideSpaces = ({
  hasPreArrivals,
  hasPostEvents,
  hasDots,
  isDesktop,
}: TProps) => {
  const meetingDays = useAppSelector(
    ({search}) => search.multiSearchPayload,
  ).filter(({eventType}) => eventType === EEventType.DAY);

  const isMultiDay = meetingDays.length > 1;

  return (
    <>
      {meetingDays.map((day, dayIndex) => {
        const {
          checkIn,
          checkOut,
          bedrooms,
          roomFilters = [],
          foodAndBeverage = [],
          maxParticipants,
        } = day;

        const dateText = getDateWithoutHours(
          checkIn,
          DateFormats['1 Jan 2023'],
        );
        const timeRangeText = `(${getHoursAndMinutes(
          checkIn,
        )} - ${getHoursAndMinutes(checkOut)})`;

        const title = (
          <>
            <span>{isMultiDay ? `DAY ${dayIndex + 1}` : 'Your room'}</span>
            <SubtitleWrapper>
              <div>
                <div>{dateText}</div>
                <div>{timeRangeText}</div>
              </div>
              <div>
                Participants: <b>{`${maxParticipants}`}</b>
              </div>
            </SubtitleWrapper>
          </>
        );

        const children = (
          <DefaultRequestSpace
            units={roomFilters}
            foodAndBeverage={foodAndBeverage}
            bedrooms={bedrooms}
            hasDots={hasDots}
            hasDates
          />
        );

        return (
          <DefaultLeftSideItem
            key={dayIndex}
            item={{
              title,
              children,
            }}
            isHidden={false}
            paddingTop={
              hasPreArrivals && dayIndex === 0
                ? `${offsetXXLg} !important`
                : undefined
            }
            paddingBottom={
              !hasPostEvents && dayIndex === meetingDays.length - 1
                ? undefined
                : `${offsetXXLg} !important`
            }
            isNoBorderTop={hasPreArrivals}
            isDesktop={isDesktop}
          />
        );
      })}
    </>
  );
};
