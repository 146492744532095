import React from 'react';
import styled from 'styled-components';

import UnitSectionTitle from './components/UnitSectionTitle';
import UnitSectionContent from './components/UnitSectionContent';
import MeetingRequestsAccordionItem from '../../../MeetingRequestsAccordionItem';

import {borderRadiusDef, NW2Gray600Color, offsetDef} from 'constants/styleVars';
import {TSummaryData} from 'types/search';
import {TBedroomsCatering} from '../../../AddAccommodationRequest/types';

const StyledMeetingRequestsAccordionItem = styled(MeetingRequestsAccordionItem)`
  padding: ${offsetDef};
  border: 1px solid ${NW2Gray600Color} !important;
  border-radius: ${borderRadiusDef};

  & + & {
    margin-top: ${offsetDef};
  }
`;

type TProps = {
  bedroomsCatering: TBedroomsCatering;
  summaryData: TSummaryData[];
  setIsSectionExpanded: (
    dayIndex: number,
  ) => (isSectionExpanded: boolean) => void;
};

function UnitsSection({
  bedroomsCatering,
  summaryData,
  setIsSectionExpanded,
}: TProps) {
  return (
    <>
      {summaryData.map((item, index) => {
        const sectionData = {
          date: item.startDate,
          dayTimeRange: item.dayTimeRange || '',
        };
        return (
          <StyledMeetingRequestsAccordionItem
            key={item.startDate}
            title={<UnitSectionTitle sectionData={sectionData} />}
            content={
              <UnitSectionContent
                bedroomsCatering={bedroomsCatering}
                summaryDataItem={item}
              />
            }
            isExpanded={item.isSectionExpanded}
            onExpandHandler={setIsSectionExpanded(index)}
          />
        );
      })}
    </>
  );
}

export default UnitsSection;
