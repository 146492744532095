import React, {SyntheticEvent} from 'react';
import DateUtils from 'utils/dateUtils';

import DateRangeString from 'view/components/DateRangeString';
import NMMSubmitSection from 'view/components/NMMSubmitSection';

import {BlockCell} from './BlockCell';
import {DeclineModal} from 'view/components/RequestDecline/DeclineModal';
import {getRequestDataFromType} from './helpers';
import {getExpiresIn, getHoldUp} from 'utils/helpers';
import {formatPriceTwoDigitsOrDash} from 'view/venue/NW2VenueProfile/components/NMMResources/helpers';
import {getCurrencySymbol} from 'utils/stringUtils';

import {BlockWrapper, CellsWrapper} from './styles';
import {ERequestCellName} from 'types/dto/IBookingType.type';
import {EBookingOrderStatus, ISearchOrder} from 'types/bookingOrders';
import {IRfpHandlers} from '../../../hooks/useRfpHandlers';
import {EDeclineItemType, ERequestStatus} from 'types/offer';
import {IOfferHandlers} from 'view/venue/Offer/hooks/useOfferHandlers';
import {ISearchRequestOfferDto} from 'types/dto/ISearchRequestOfferDto.type';
import {offsetDef} from 'constants/styleVars';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../../store/types';
import _get from 'lodash/get';
import {EUserRoleCognito} from '../../../../../types/dto/EUserRoleCognito';

interface IProps {
  isOffer: boolean;
  data: ISearchOrder | ISearchRequestOfferDto;
  alreadyVisitedDetails: string[];
  onShowBookingChangesDetails: (
    orderNumber: string,
    orderId: string,
  ) => () => void;
  onShowOrderDetails: (
    orderId: string,
    isMultiDay: boolean,
    orderNumber: string,
    isRequest?: boolean,
  ) => () => void;
  changeHandlers: {
    rfpHandlers: IRfpHandlers;
    offerHandlers: IOfferHandlers;
  };
  onShowOfferCreate: (requestId: string) => (e?: SyntheticEvent) => void;
}

export const RequestBlockInfo = ({
  isOffer,
  data,
  alreadyVisitedDetails,
  onShowOrderDetails,
  changeHandlers,
  onShowOfferCreate,
  onShowBookingChangesDetails,
}: IProps) => {
  const {
    totalPrice,
    company,
    checkInDate,
    checkOutDate,
    currency,
    orderNumber,
    orderId,
    status,
    createdAt,
    expirationDate,
    hasAccommodation,
  } = getRequestDataFromType(data, 'orderId');

  // TODO: Remove with updates of Agent View
  const isAgent =
    useSelector((state: RootState) => _get(state, 'app.user.role')) ===
    EUserRoleCognito.ROLE_AGENT;
  const isMultiDay = !DateUtils.isSameDay(checkInDate, checkOutDate);

  const {offerHandlers, rfpHandlers} = changeHandlers;

  const isOfferRequest = status === ERequestStatus.REQUEST_PENDING;
  const isRequestOrOffer = isOfferRequest || isOffer;
  const isRTCPendingStatus = status === EBookingOrderStatus.RTC_PENDING;

  const isRequestWithBedroom = isOfferRequest && hasAccommodation;

  const {onShowConfirmModal, onShowDeclineModal} = isRequestOrOffer
    ? offerHandlers
    : rfpHandlers;

  const currencySymbol = getCurrencySymbol(currency);
  const holdUp = getHoldUp(
    isOfferRequest ? createdAt : DateUtils.convertDateToLocal(createdAt),
  );

  const expiresIn = getExpiresIn(expirationDate);

  return (
    <>
      <BlockWrapper
        onClick={onShowOrderDetails(
          orderId,
          isMultiDay,
          orderNumber,
          isOfferRequest,
        )}
      >
        <CellsWrapper>
          <BlockCell
            data={isOfferRequest ? expiresIn : `${holdUp} ago`}
            title={
              isOfferRequest
                ? ERequestCellName.EXPIRES_IN
                : ERequestCellName.RECEIVED
            }
          />
          <BlockCell
            data={
              <DateRangeString
                isMultiDay={isMultiDay}
                checkInDate={checkInDate}
                checkOutDate={checkOutDate}
              />
            }
            title={
              isRTCPendingStatus
                ? ERequestCellName.DATE
                : ERequestCellName.REQUESTED_DATE
            }
          />
          <BlockCell
            data={
              isRequestWithBedroom
                ? '-'
                : totalPrice
                ? formatPriceTwoDigitsOrDash(totalPrice, currencySymbol)
                : '-'
            }
            title={
              isOfferRequest
                ? ERequestCellName.POTENTIAL_WORTH
                : isRTCPendingStatus
                ? ERequestCellName.NEW_TOTAL
                : ERequestCellName.TOTAL_PRICE
            }
            noWrap
          />
          <BlockCell
            data={company || '-'}
            title={ERequestCellName.COMPANY}
            isLongData
          />
        </CellsWrapper>

        {!isAgent && (
          <NMMSubmitSection
            submitLabel={
              isOfferRequest
                ? 'propose'
                : isRTCPendingStatus
                ? 'Confirm changes'
                : 'confirm'
            }
            cancelLabel='decline'
            handleSubmit={
              isRTCPendingStatus
                ? () => onShowBookingChangesDetails(orderNumber, orderId)
                : isOfferRequest
                ? onShowOfferCreate(orderId)
                : onShowConfirmModal(orderId)
            }
            handleCancel={
              isRTCPendingStatus
                ? () => onShowBookingChangesDetails(orderNumber, orderId)
                : onShowDeclineModal(orderId)
            }
            isLoading={false}
            gap={offsetDef}
            margin='0'
          />
        )}
      </BlockWrapper>

      <DeclineModal
        orderId={orderId}
        changeHandlers={changeHandlers}
        checkInDate={checkInDate}
        checkOutDate={checkOutDate}
        company={company}
        declineItem={EDeclineItemType.RFP}
        currencySymbol={currencySymbol}
        holdUp={holdUp}
        alreadyVisitedDetails={alreadyVisitedDetails}
        isRequest={isOfferRequest}
        isMultiDay={isMultiDay}
        isOffer={isOffer}
        orderNumber={orderNumber}
        totalPrice={totalPrice}
        onShowOrderDetails={onShowOrderDetails}
        isRequestWithBedroom={isRequestWithBedroom}
      />
    </>
  );
};
