import {useEffect} from 'react';
import _get from 'lodash/get';
import {useSelector} from 'react-redux';

import {getOperationalTimes} from 'store/venueDetails/apiActions';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {RootState} from 'store/types';

export function useOperationalTimes() {
  const dispatch = useAppDispatch();

  const {
    error,
    details,
    operationalTimes,
    operationalTimesLoading: loading,
  } = useAppSelector(({venueDetails}) => venueDetails);

  const accommodationIdFromRoom = details?.accommodationId;

  const accommodationIdFromWorkDesk = useAppSelector(
    ({workDesks}) => workDesks.workDesksDetails?.id,
  );

  const accommodationIdFromSupplier = useSelector((state: RootState) =>
    _get(state, 'venue.venue.id'),
  );

  const isOperationalTimes = !!operationalTimes.length;

  const accommodationId =
    accommodationIdFromRoom ||
    accommodationIdFromWorkDesk ||
    accommodationIdFromSupplier;

  useEffect(() => {
    if (accommodationId && !isOperationalTimes && !loading && !error) {
      dispatch(getOperationalTimes(accommodationId));
    }
  }, [accommodationId, isOperationalTimes, loading, error, dispatch]);

  return {operationalTimes, loading};
}
