import React from 'react';

import BookingChangeSidebarBodyInfoChanges from './BookingChangeSidebarBodyInfoChanges';
import Icon from 'view/components/Icon';
import {formatDate} from 'utils/stringUtils';
import {pluralize} from 'utils/helpers';

import {formatFloatWithOneNumber} from 'utils/venueUtils';
import {TUnitInfo} from 'types/bookingOrders';
import {ERoomSchema, ERoomSchemaNames} from 'types/venue';
import {IBookedExtrasChanges, IBookedChanges} from '../types';
import {
  StyledBlock,
  StyledExtrasTotalPrice,
  StyledLine,
  StyledMeetingRoomName,
  StyledNewChange,
  StyledPrevChange,
  StyledSection,
  Divider,
} from '../BookingChangeOverviewSidebar.styles';
import BookingChangeSidebarBodyPackageChanges from './BookingChangeSidebarBodyPackageChanges';

export const extrasChangeLine = (
  change: IBookedExtrasChanges,
  index: number,
) => (
  <StyledLine key={index}>
    {change.bookedQuantity && (
      <>
        <StyledPrevChange>
          {`${change.bookedQuantity?.prev ?? '0'} x ${
            change.extraName?.prev || change.extraName?.new
          }`}
        </StyledPrevChange>
        <Icon justify='center' disabledColor icon='NW2_ARROW_RIGHT_THIN' />
        <StyledNewChange>
          {`${change.bookedQuantity?.new} x ${
            change.extraName?.new || change.extraName?.prev
          }`}
        </StyledNewChange>
      </>
    )}
  </StyledLine>
);

type TProps = {
  checkInDate: IBookedChanges<string> | string;
  checkOutDate: IBookedChanges<string> | string;
  participants?: IBookedChanges<number>;
  packageId?: IBookedChanges<number>;
  packagePrice?: IBookedChanges<number>;
  currentParticipants?: number | null;
  prevTotalPriceForPackageSet?: number | null;
  newTotalPriceForPackageSet?: number | null;
  setupStyle?: IBookedChanges<ERoomSchemaNames>;
  equipmentChanges?: IBookedExtrasChanges[];
  prevPriceEquipment: number;
  newPriceEquipment: number;
  currencySymbol?: string;
  unitInfo: TUnitInfo;
};
const BookingChangeOverviewSidebarUnit = ({
  checkInDate,
  checkOutDate,
  participants,
  setupStyle,
  equipmentChanges,
  prevPriceEquipment,
  newPriceEquipment,
  currencySymbol,
  unitInfo,
  packageId,
  packagePrice,
  currentParticipants,
  prevTotalPriceForPackageSet,
  newTotalPriceForPackageSet,
}: TProps) => {
  const formatCheckTime = (
    date: IBookedChanges<string> | string,
    format: 'prev' | 'new',
  ) => {
    const time = typeof date === 'string' ? date : date[format];

    return formatDate(time, 'hh:mm', 'en-US');
  };

  const prevCheckInTime = formatCheckTime(checkInDate, 'prev');
  const prevCheckOutTime = formatCheckTime(checkOutDate, 'prev');
  const newCheckInTime = formatCheckTime(checkInDate, 'new');
  const newCheckOutTime = formatCheckTime(checkOutDate, 'new');
  const isCheckTimeChanged =
    !!(typeof checkInDate !== 'string' && checkInDate?.new) ||
    !!(typeof checkOutDate !== 'string' && checkOutDate?.new);
  const isEquipmentTotalPriceChanged = prevPriceEquipment !== newPriceEquipment;

  const isBookingWithPackage = packageId?.new || packageId?.prev;

  return (
    <StyledBlock>
      <StyledSection>
        <StyledMeetingRoomName>{unitInfo.unitName}</StyledMeetingRoomName>
        {
          <StyledSection>
            {isCheckTimeChanged && (
              <BookingChangeSidebarBodyInfoChanges
                previousValue={`${prevCheckInTime} - ${prevCheckOutTime}`}
                newValue={`${newCheckInTime} - ${newCheckOutTime}`}
              />
            )}
            {isBookingWithPackage && (
              <BookingChangeSidebarBodyPackageChanges
                packageId={packageId}
                packagePrice={packagePrice}
                participants={participants}
                currentParticipants={currentParticipants}
                currencySymbol={currencySymbol}
                prevTotalPriceForPackageSet={prevTotalPriceForPackageSet}
                newTotalPriceForPackageSet={newTotalPriceForPackageSet}
              />
            )}
            {participants && !isBookingWithPackage && (
              <BookingChangeSidebarBodyInfoChanges
                previousValue={pluralize('People', participants.prev)}
                newValue={pluralize('People', participants.new)}
              />
            )}
            {setupStyle && (
              <BookingChangeSidebarBodyInfoChanges
                previousValue={ERoomSchema[setupStyle.prev]}
                newValue={ERoomSchema[setupStyle.new]}
              />
            )}
          </StyledSection>
        }
      </StyledSection>
      <StyledSection>{equipmentChanges?.map(extrasChangeLine)}</StyledSection>
      {isEquipmentTotalPriceChanged && (
        <StyledExtrasTotalPrice>
          <StyledPrevChange>{`${formatFloatWithOneNumber(
            prevPriceEquipment,
          )} ${currencySymbol}`}</StyledPrevChange>
          <Icon justify='center' disabledColor icon='NW2_ARROW_RIGHT_THIN' />
          <StyledNewChange>{`${formatFloatWithOneNumber(
            newPriceEquipment,
          )} ${currencySymbol}`}</StyledNewChange>
        </StyledExtrasTotalPrice>
      )}
      <Divider />
    </StyledBlock>
  );
};

export default BookingChangeOverviewSidebarUnit;
