import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import _get from 'lodash/get';
import {useAppSelector} from 'store/hooks';
import _uniqBy from 'lodash/uniqBy';

import NW2Button from 'view/components/NW2Button';
import ExtraItem from './ExtraItem';

import {getCurrencySymbol} from 'utils/stringUtils';
import {IExtrasResponse} from 'types/dto/IExtras.type';
import {getFoodAndBeverageQuantity} from 'view/components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/components/AddFoodBeverageRequest/helpers';
import {offsetDef} from 'styles/configs/offset';
import {StyledExtrasList} from '../Space.styles';
import {ISummaryExtra} from 'types/dto/IBooking.types';
import {IOfferUnitExtra} from 'types/offer';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';
import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';

type TProps = {
  extras: IExtrasResponse[];
  chosenExtras?: (ISummaryExtra | IOfferUnitExtra)[];
  initMaxToShow: number;
  dayId: number;
  unitId?: number;
  isFoodAndBeverage?: boolean;
  participants?: number;
  duration?: number;
  isPreview?: boolean;
  isPriceHidden?: boolean;
  checkInDate?: string;
  checkOutDate?: string;
};

export const StyledButton = styled(NW2Button)`
  margin-top: ${offsetDef};
`;

const ExtrasList = ({
  extras,
  chosenExtras = [],
  initMaxToShow,
  dayId,
  unitId = 0,
  isFoodAndBeverage = false,
  participants = 1,
  duration = 0,
  isPreview,
  isPriceHidden,
  checkInDate,
  checkOutDate,
}: TProps) => {
  const [isShowMore, setIsShowMore] = useState(false);
  const [initialChosenExtras, setInitialChosenExtras] = useState<
    IExtrasResponse[]
  >([]);

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const venueCurrency: string = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.currency`),
  );

  const {venueDetails} = useVenueDetailsData();

  const currencySymbol = getCurrencySymbol(
    venueDetails.currency || venueCurrency,
  );

  const checkedExtras = (extra: IExtrasResponse) =>
    chosenExtras?.some(({code}) => code === extra.code);

  const chosenExtrasOnly = extras.filter(
    (extra) =>
      (checkedExtras(extra) || extra.price === 0) &&
      !initialChosenExtras.find(({code}) => code === extra.code),
  );

  const offerExtras = chosenExtras.filter((extra) =>
    extras?.some(({code}) => code === extra.code),
  );

  //we set initial checked extras only once on first render
  useEffect(() => {
    setInitialChosenExtras(chosenExtras as IExtrasResponse[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const notChosenExtrasOnly = extras.filter(
    (extra) => !checkedExtras(extra) && extra.price !== 0,
  );

  const secondPartExtras = [...notChosenExtrasOnly, ...chosenExtrasOnly].sort(
    (a, b) => {
      // If prices are equal, sort by name (in alphabetical order)
      if (a.price === b.price) {
        return a.name.localeCompare(b.name);
      }

      return b.price - a.price;
    },
  );

  const allSortedExtras = _uniqBy(
    [...initialChosenExtras, ...secondPartExtras],
    'code',
  );

  const chosenExtrasQuantity = [...initialChosenExtras, ...chosenExtrasOnly]
    .length;

  const maxToShow =
    initMaxToShow >= chosenExtrasQuantity
      ? initMaxToShow
      : chosenExtrasQuantity;

  const isMoreMax = allSortedExtras.length > maxToShow;

  const extrasToShow =
    isMoreMax && !isShowMore
      ? allSortedExtras.slice(0, maxToShow)
      : allSortedExtras;

  const showMoreHandler = () => setIsShowMore(!isShowMore);

  return (
    <StyledExtrasList isPreview={isPreview}>
      {(isPreview ? offerExtras : extrasToShow)?.map((extra) => {
        const chosenExtra = chosenExtras?.find(
          ({code}) => extra.code === code,
        ) as ISummaryExtra;

        const previewExtra = {...extra, name: extra.code};

        const foodAndBeverageQuantity = getFoodAndBeverageQuantity({
          extra: (isPreview ? previewExtra : extra) as IExtrasResponse,
          duration,
          participants,
        });

        const chosenQuantity = chosenExtra
          ? chosenExtra?.chosenQuantity || chosenExtra?.quantity
          : isFoodAndBeverage
          ? foodAndBeverageQuantity
          : 1;

        return (
          <ExtraItem
            key={extra.code}
            extra={
              'quantity' in extra
                ? (extra as IExtrasResponse | IOfferUnitExtra)
                : (extra as IExtrasResponse)
            }
            operationalTimes={venueDetails.operationalTimes}
            currencySymbol={currencySymbol}
            chosenQuantity={chosenQuantity}
            dayId={dayId}
            unitId={unitId}
            isPriceHidden={isPriceHidden}
            checkInDate={checkInDate}
            checkOutDate={checkOutDate}
            isPreview={isPreview ?? false}
            isExtraChosen={
              chosenExtra?.code === (extra as IExtrasResponse)?.code
            }
          />
        );
      })}
      {isMoreMax && !isShowMore && !isPreview && (
        <StyledButton
          size='responsiveAdaptive'
          buttonType='tertiary'
          onClick={showMoreHandler}
          fullWidth={isMobile}
        >
          Show more options
        </StyledButton>
      )}
    </StyledExtrasList>
  );
};

export default ExtrasList;
