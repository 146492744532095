import React from 'react';

import Slider from 'view/components/Slider/Slider';

import Slide1 from 'img/slide-1.jpg';
import Slide2 from 'img/slide-2.jpg';
import Slide3 from 'img/slide-3.jpg';
import styled from 'styled-components';
import {
  contentMaxWidthLg,
  contentMaxWidthXs,
  fontWeightExtraBold,
  lgBp,
  mdBp,
  offsetDef,
  whiteColor,
} from 'constants/styleVars';
import {NW2Container} from 'view/mainLanding/MainLanding.styles';

export const Description = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 6px;
  font-size: 30px;
  z-index: 1;
  font-weight: ${fontWeightExtraBold};
  color: ${whiteColor};
  line-height: 0.74;
  text-transform: uppercase;
  text-align: left;
  opacity: 0.5;

  @media (min-width: ${mdBp}px) {
    font-size: 66px;
    bottom: 0;
  }
  @media (min-width: ${lgBp}px) {
    font-size: 87px;
  }
`;

const HeroSliderContainer = styled.div`
  position: relative;

  .image-gallery-bullets {
    width: 100%;
    z-index: 1;
    bottom: 6px;
    padding-right: ${offsetDef};
    padding-left: ${offsetDef};
  }

  .image-gallery-slide {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      z-index: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), transparent);
    }
  }

  .image-gallery-bullets-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: auto;
    margin-right: auto;
    max-width: ${contentMaxWidthXs};

    > button {
      border-radius: 2px;
      box-shadow: none;
      width: 10px;
      height: 10px;
      background: ${whiteColor};
      border: 0;
      opacity: 0.5;

      &:focus,
      &:hover {
        opacity: 1;
        background: ${whiteColor};
      }

      &.active {
        opacity: 1;
        background: ${whiteColor};
      }
    }

    > button + button {
      margin-top: 50px;
    }
  }

  @media (min-width: ${mdBp}px) {
    .image-gallery-bullets {
      bottom: 0;
    }
    .image-gallery-bullets-container {
      > button {
        width: 12px;
        height: 12px;
      }
      > button + button {
        margin-top: 67px;
      }
    }
  }

  @media (min-width: ${lgBp}px) {
    .image-gallery-bullets-container {
      max-width: ${contentMaxWidthLg};
      > button {
        width: 20px;
        height: 20px;
      }
      > button + button {
        margin-top: 83px;
      }
    }
  }
`;

const SliderTitle = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 40px;
  z-index: 1;
  padding: 0;
  font-weight: ${fontWeightExtraBold};
  color: ${whiteColor};
  font-size: 36px;
  line-height: 1;
  text-transform: uppercase;

  span {
    position: relative;
    display: inline-block;
    &:before {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      right: 0;
      height: 4px;
      background: ${whiteColor};
      @media (min-width: ${mdBp}px) {
        height: 9px;
        bottom: -${offsetDef};
      }
    }
  }

  @media (min-width: ${mdBp}px) {
    font-size: 50px;
    bottom: 80px;
  }
  @media (min-width: ${lgBp}px) {
    font-size: 63px;
    bottom: 100px;
  }
`;

const imageGalleryItems = [
  {
    text: (
      <Description>
        <NW2Container>how things stand</NW2Container>
      </Description>
    ),
    originalAlt: 'how things stand',
    original: Slide1,
  },
  {
    text: (
      <Description>
        <NW2Container>illogical costs</NW2Container>
      </Description>
    ),
    originalAlt: 'illogical costs',
    original: Slide2,
  },
  {
    text: (
      <Description>
        <NW2Container>empty offices</NW2Container>
      </Description>
    ),
    originalAlt: 'empty offices',
    original: Slide3,
  },
];

function HeroSlider() {
  return (
    <HeroSliderContainer>
      <SliderTitle>
        <NW2Container>
          flexibility & <br /> sustainability <span>over</span>
        </NW2Container>
      </SliderTitle>
      <Slider
        imageGalleryItems={imageGalleryItems}
        imageMaxHeight={450}
        slideDuration={700}
        slideInterval={7000}
        showNav={false}
        showBullets
        showIndex={false}
        autoPlay
      />
    </HeroSliderContainer>
  );
}

export default HeroSlider;
