import React, {useMemo} from 'react';

import PrePostDateBlock from './PrePostDateBlock';

import {AccommodationExtras} from '../AccommodationExtras/AccommodationExtras';

import {useAppSelector} from 'store/hooks';

import {formatFloatWithOneNumber} from 'utils/venueUtils';
import {Container} from './AccommodationBlock.styles';
import {MainWrapper} from './AccommodationBlock.styles';
import {IExtrasOption} from 'types/dto/IExtras.type';
import {
  DaySubTotal,
  DaySubTotalPrice,
  UnitDayPrice,
} from 'view/supplier/Bookings/BookingDetails/BookingDetails.styles';
import {IOfferUnitExtra} from 'types/offer';
import {offsetXLg} from 'constants/styleVars';
import {EEventType} from 'types/venue';

type TProps = {
  eventType: EEventType;
  bedrooms: IOfferUnitExtra[];
  extrasOption?: IExtrasOption[];
  isPreArrival?: boolean;
  date: string;
  isOffer: boolean;
  currencySymbol?: string;
  isRequestWithUnitId?: boolean;
  titleMargin?: string;
};

const AccommodationBlock = ({
  bedrooms,
  extrasOption,
  isPreArrival,
  date,
  isOffer,
  currencySymbol,
  isRequestWithUnitId,
  titleMargin,
  eventType,
}: TProps) => {
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  const daySubTotalPrice = useMemo(
    () =>
      bedrooms.reduce((acc: number, item: any) => {
        if (item.totalPrice) {
          return acc + item.totalPrice.value;
        }
        return acc;
      }, 0),
    [bedrooms],
  );

  return (
    <MainWrapper isMobile={isMobile} isPreArrival={isPreArrival}>
      <PrePostDateBlock date={date} eventType={eventType} />
      <Container>
        <AccommodationExtras
          unitExtras={bedrooms}
          extrasOption={extrasOption}
          currencySymbol={currencySymbol}
          isOffer={isOffer}
          isRequestWithUnitId={isRequestWithUnitId}
          margin={titleMargin}
        />

        {isOffer && (
          <UnitDayPrice margin={`${offsetXLg} 0 0`}>
            <DaySubTotal>Day sub-total:</DaySubTotal>
            <DaySubTotalPrice>
              {formatFloatWithOneNumber(daySubTotalPrice)}
              &nbsp; {currencySymbol}
            </DaySubTotalPrice>
          </UnitDayPrice>
        )}
      </Container>
    </MainWrapper>
  );
};

export default AccommodationBlock;
