import React from 'react';

import TextPopover from '../../../CreateVenue/pages/ContactDetails/components/ContactDetailsForm/TextPopover';

import DateUtils, {DateFormats} from 'utils/dateUtils';
import {EPersonOfInterestType, IPeopleOfInterest} from 'types/venue';

import {SubTitle, InfoRow, GridBox} from './NMMPayout.styles';
import {offsetXSm} from 'styles/configs/offset';

type TProps = {
  peopleOfInterest: IPeopleOfInterest[];
};

const personConfig: {[key: string]: {title: string; textIndex: number}} = {
  [EPersonOfInterestType.SIGNATORY]: {
    title: 'Signatory',
    textIndex: 4,
  },
  [EPersonOfInterestType.OWNER]: {
    title: 'Business owner',
    textIndex: 5,
  },
  [EPersonOfInterestType.CONTROLLER]: {
    title: 'Controller',
    textIndex: 6,
  },
};

const OperatingExecutivesView = ({peopleOfInterest}: TProps) => {
  if (!peopleOfInterest) return null;

  const sortBy = [
    EPersonOfInterestType.SIGNATORY,
    EPersonOfInterestType.OWNER,
    EPersonOfInterestType.CONTROLLER,
  ];

  const customSort = (
    data: IPeopleOfInterest[],
    sortBy: EPersonOfInterestType[],
  ): IPeopleOfInterest[] => {
    const sortByObject: {[key: string]: number} = sortBy.reduce(
      (obj, item, index) => {
        return {
          ...obj,
          [item]: index,
        };
      },
      {},
    );
    return data.sort((a, b) => sortByObject[a.type] - sortByObject[b.type]);
  };

  const makeDateOfBirthText = (dateOfBirth: string) => {
    const separator = '';
    const arr = DateUtils.getDateWithoutHours(
      dateOfBirth,
      DateFormats['01 Jan 2023'],
    ).split(separator);

    const currentNumberOfDay = +(arr[0] + arr[1]);
    const daysWithoutPostfix = [1, 2, 3, 21, 22, 23];

    const skipPostfix = daysWithoutPostfix.some(
      (day) => currentNumberOfDay === day,
    );

    arr.splice(2, 0, skipPostfix ? '' : 'th');

    return 'born ' + arr.join(separator);
  };

  const sortedPersonOfInterests = customSort(peopleOfInterest, sortBy);

  return (
    <GridBox columnNumber={3}>
      {sortedPersonOfInterests.map(
        ({id, type, firstName, lastName, dateOfBirth}) => (
          <div key={id}>
            <SubTitle>
              {personConfig[type].title}
              <TextPopover
                textIndex={personConfig[type].textIndex}
                marginLeft={offsetXSm}
              />
            </SubTitle>
            <InfoRow>
              {firstName} {lastName}
            </InfoRow>
            <InfoRow>{makeDateOfBirthText(dateOfBirth)}</InfoRow>
          </div>
        ),
      )}
    </GridBox>
  );
};

export default OperatingExecutivesView;
