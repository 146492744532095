import {AxiosResponse} from 'axios';
import {
  formatPhoneNumberIntl,
  parsePhoneNumber,
  type Value,
} from 'react-phone-number-input';

import api from 'infra/common/http.service';
import venueRepository from 'infra/repository/VenueRepository';
import {asyncDebounce} from 'utils/asyncDebounce';
import {ILegalPhone} from 'types/venue';

import {EVenueProfilePages, VenueProfileHash} from './types';

export const ICON_SIZE = 20;

export const checkEmail = async (value: string): Promise<AxiosResponse> =>
  await venueRepository(api).getIsEmailAvailable(value);
export const debouncedSearch = asyncDebounce(
  async (value: string) => await checkEmail(value),
  300,
);

export const setLegalPhoneNumber = (legalPhoneNumber: string): ILegalPhone => {
  const parsedPhone = parsePhoneNumber(legalPhoneNumber || '');
  const value = parsedPhone
    ? {
        phoneCountryCode: parsedPhone.countryCallingCode,
        phone: parsedPhone.nationalNumber,
        fullPhoneNumber: parsedPhone.number,
        phoneCountry: parsedPhone.country,
      }
    : {
        fullPhoneNumber: legalPhoneNumber,
      };

  return trimPhoneNumber(value);
};

export const getLegalPhoneNumber = (legalPhone: ILegalPhone | null): string => {
  if (!legalPhone) return '';
  if ('fullPhoneNumber' in legalPhone) return legalPhone.fullPhoneNumber || '';
  if (!legalPhone.phoneCountryCode || !legalPhone.phone) return '';
  return `+${legalPhone.phoneCountryCode} ${legalPhone.phone}`;
};

export const replaceNonNumberValue = (value: string) =>
  value.replace(/[^\d]+/g, '');

export const trimPhoneNumber = (value: ILegalPhone): ILegalPhone => {
  const nonNumberValue = replaceNonNumberValue(value.fullPhoneNumber || '');
  if ('phoneCountryCode' in value) {
    return {
      ...value,
      fullPhoneNumber: formatPhoneNumberIntl(`+${nonNumberValue}` as Value),
    };
  }

  return {
    ...value,
    fullPhoneNumber: nonNumberValue ? `+${nonNumberValue}` : '',
  };
};

export const parseNameString = (firstName?: string, lastName?: string) => {
  if (firstName && lastName) return `${firstName} ${lastName}`;
  if (firstName && !lastName) return `${firstName}`;
  if (!firstName && lastName) return `${lastName}`;
  if (!firstName && !lastName) return `-`;
};

export const getDecimalWithCount = (value: number, count = 1) => {
  const decimalPartLength = value.toString().match(/\.(\d+)/)?.[1].length;
  return decimalPartLength ? value.toFixed(count) : value;
};

export const getPage = (hash: string) => {
  switch (hash) {
    // TODO: do not remove in case we will need adyen in future
    // case VenueProfileHash[EVenueProfilePages.PAYMENT]:
    //   return EVenueProfilePages.PAYMENT;
    case VenueProfileHash[EVenueProfilePages.CONTACTS]:
      return EVenueProfilePages.CONTACTS;
    case VenueProfileHash[EVenueProfilePages.DETAILS]:
      return EVenueProfilePages.DETAILS;
    case VenueProfileHash[EVenueProfilePages.PAYMENT]:
    case VenueProfileHash[EVenueProfilePages.INFO]:
    default:
      return EVenueProfilePages.INFO;
  }
};
