import {IBookingType, IGetBookingsPayload} from 'types/dto/IBookingType.type';
import {
  IGetBookingsFailureAction,
  IGetBookingsRequestAction,
  IGetBookingsSuccessAction,
  ISetActiveBookingsTab,
  ISetWorkdeskOrderReadStatus,
} from './types';
import {
  GET_BOOKINGS_FAILURE,
  GET_BOOKINGS_SUCCESS,
  IS_BOOKINGS_LOADING,
  SET_BOOKINGS_TYPE,
  SET_WORKDESK_ORDER_READ_STATUS,
} from 'store/bookings/constants';
import {
  addBooker,
  setTodayOverviewBookingTableData,
} from 'view/supplier/Bookings/BookingsList/helpers';
import {TContainer} from 'app';
import {IGetBookingOrdersResponse} from 'types/bookingOrders';
import {RootState} from '../types';

const setIsBookingsLoading = (
  payload: Record<string, unknown>,
): IGetBookingsRequestAction => ({
  type: IS_BOOKINGS_LOADING,
  payload,
});

const getBookingsSuccess = (
  payload: Record<string, unknown>,
  bookingType: IBookingType,
): IGetBookingsSuccessAction => ({
  type: GET_BOOKINGS_SUCCESS,
  payload,
  bookingType,
});

const getBookingsFailure = (
  payload: Record<string, unknown>,
): IGetBookingsFailureAction => ({
  type: GET_BOOKINGS_FAILURE,
  payload,
});

export const setActiveBookingsTab = (
  payload: IBookingType,
): ISetActiveBookingsTab => ({
  type: SET_BOOKINGS_TYPE,
  payload,
});

export const setWorkdeskOrderReadStatus = (
  payload: string,
): ISetWorkdeskOrderReadStatus => ({
  type: SET_WORKDESK_ORDER_READ_STATUS,
  payload,
});

// will be replaced to getSupplierOrders after update workdesk table
export const getBookingsAction =
  (payload: IGetBookingsPayload) =>
  (
    dispatch: any,
    getState: () => RootState,
    {bookingsContainer}: TContainer,
  ): void => {
    const {bookingsType, pagination, tableDataKey} = payload;
    const isTodaysOverview = bookingsType === IBookingType.ARRIVING_TODAY;
    const venueId = getState().venueDetails.supplierVenueId;
    dispatch(setIsBookingsLoading({bookingsType, tableDataKey, state: true}));

    bookingsContainer.getBookings({
      payload: {...payload, venueId},
      onSuccess: (data: IGetBookingOrdersResponse) => {
        const bookerAddedData = addBooker(data.pageItems);

        const bookingData = isTodaysOverview
          ? setTodayOverviewBookingTableData(bookerAddedData)
          : bookerAddedData;

        dispatch(
          getBookingsSuccess(
            {
              ...(tableDataKey
                ? {data: bookingData[tableDataKey as string].data, tableDataKey}
                : {data: bookingData}),
              pagination: {
                current: pagination?.current || 1,
                pageSize: pagination?.pageSize || 0,
                total: data.totalFilteredRecords,
              },
            },
            bookingsType,
          ),
        );
      },
      onError: (error: any) =>
        dispatch(getBookingsFailure({bookingsType, state: false, error})),
    });
  };
