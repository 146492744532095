import React, {Dispatch, ReactNode, SetStateAction, useMemo} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import Icon from 'view/components/Icon';
import NW2Button from 'view/components/NW2Button';
import ModalOverlay from 'view/components/ModalOverlay';
import NW2Loader from 'view/components/NW2Loader/NW2Loader';
import NW2ModalDrawer from 'view/components/NW2ModalDrawer';

import {FormWizard, FormWizardPage} from 'view/components/NW2Forms';
import {FinishPage} from './FinishPage';
import {FormPreview} from './FormPreview';
import {StartPage} from './StartPage';

import {
  EOfferStatus,
  IOfferDay,
  IOfferRequestDay,
  IRequestDay,
} from 'types/offer';
import {useActions} from 'hooks/useActions';
import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import useToggle from 'hooks/useToggle';

import {
  Header,
  Main,
} from 'view/venue/NW2VenueProfile/components/AddSpace/styles';
import {
  Container,
  LoaderContainer,
  ModalExitActions,
  ModalExitBody,
  ModalExitIcon,
  StyledWrapper,
} from '../OfferCreate.styles';
import {
  IPreviewPrices,
  IRequestMeeting,
  TCreateOfferFormValues,
  TFormState,
  TPreviewState,
} from '../types';
import {IAmenity} from 'types/dto/IAmenity';
import {IReturnUnitsExtrasProps} from '../hooks/useUnitsExtras';

const OFFER_LEFT_CONTAINER = 'offerLeftContainer';

type TStepPage = {
  title: string;
  fieldName: string;
  titleWidth?: number;
  isTitleCentered?: boolean;
  isButtonNextHidden?: boolean;
  buttonsSize?: string;
  isHidden?: boolean;
  onBackClick?: () => void;
  onNextClick?: () => void;
  content: ReactNode;
};

interface IProps {
  activeUnitId: string;
  formState: TFormState;
  formStatus: 'started' | 'finished' | '';
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  setActiveUnitId: Dispatch<SetStateAction<string>>;
  setFormState: Dispatch<SetStateAction<TFormState>>;
  setFormStatus: Dispatch<SetStateAction<'started' | 'finished' | ''>>;
  handleClose: () => void;

  isSubmitting: boolean;
  requestUnits: IRequestMeeting[];
  prices: IPreviewPrices;
  initialState?: TCreateOfferFormValues;
  onSubmit: (values: TCreateOfferFormValues) => Promise<void>;
  stepPages: TStepPage[];
  setActiveStep: Dispatch<SetStateAction<string>>;
  previewState: TPreviewState;
  requestDays: IRequestDay[] | IOfferDay[];
  preArrivals: IOfferRequestDay[];
  postEvents: IOfferRequestDay[];
  hasEquipment: boolean;
  hasCatering: boolean;
  roomAmenities?: IAmenity[];
  currency: string;
  firstMeetingRoomId: string;
  unitsExtras: IReturnUnitsExtrasProps;
  isAlternative?: boolean;
}
export function OfferCreateLayout({
  activeUnitId,
  isSubmitting,
  requestUnits,
  prices,
  page,
  setPage,
  handleClose,
  initialState,
  formState,
  formStatus,
  onSubmit,
  stepPages,
  setActiveStep,
  previewState,
  requestDays,
  preArrivals,
  postEvents,
  hasEquipment,
  hasCatering,
  roomAmenities,
  currency,
  firstMeetingRoomId,
  unitsExtras,
  setActiveUnitId,
  setFormState,
  setFormStatus,
  isAlternative,
}: IProps) {
  const {getOffers} = useActions();

  const venueId: number = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.id`),
  );
  const venueZone: string = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.location.timeZone`),
  );

  const handleSetFormState = ({values}: {values: TCreateOfferFormValues}) => {
    if (!Object.keys(values).length) return;

    setFormState((prev) => ({
      ...prev,
      [activeUnitId]: {...prev[activeUnitId], ...values},
    }));
  };

  const handleScrollToTop = () => {
    const leftContainerElement = document.getElementById(OFFER_LEFT_CONTAINER);
    leftContainerElement?.scrollTo({top: 0});
  };

  const handleSetPage = (page: number) => {
    setPage(page);
    handleScrollToTop();
  };

  // start flow
  const handleStart = () => {
    setFormStatus('started');
    setActiveUnitId(requestUnits[0]?.id || '');
    handleScrollToTop();
  };

  const handleFinish = () => {
    handleClose();

    getOffers(venueId, [
      EOfferStatus.OFFER_PENDING,
      EOfferStatus.OFFER_DECLINED,
      EOfferStatus.OFFER_ACCEPTING_EXPIRED,
    ]);
  };

  /**
   * all unitId's should persist, for correct payload
   * spaces are saving asynchronously, so need to wait the all
   */
  const isSaveOfferDisabled = isSubmitting || !prices.totalPrice;

  // modal exit
  const [isModalExitShowed, toggleModalExitShowed] = useToggle(false);

  const handleCloseOfferCreation = () => {
    if (formStatus === 'started') {
      toggleModalExitShowed();
    } else {
      handleClose();
    }
  };

  const initState = useMemo(
    () => (isAlternative ? initialState : formState[activeUnitId] || {}),
    [activeUnitId, formState, initialState, isAlternative],
  );

  return (
    <ModalOverlay>
      {requestUnits.length ? (
        <Container>
          <StyledWrapper>
            {formStatus !== 'finished' && (
              <Header>
                <NW2Button
                  buttonType='secondary'
                  minimized
                  size='small'
                  onClick={handleCloseOfferCreation}
                  icon={<Icon transparent icon='NW2_MOVE_BACK_THIN' />}
                />
              </Header>
            )}

            <Main id={OFFER_LEFT_CONTAINER}>
              {formStatus === 'started' ? (
                <FormWizard
                  page={page}
                  handleSetPage={handleSetPage}
                  initialValues={initState}
                  isSubmitting={isSaveOfferDisabled}
                  btnSubmitText='submit offer'
                  onSubmit={onSubmit}
                  handleSetFormState={handleSetFormState}
                  handleActiveStep={setActiveStep}
                >
                  {stepPages.map(({content, ...rest}) => (
                    <FormWizardPage key={rest.title} {...rest}>
                      {content}
                    </FormWizardPage>
                  ))}
                </FormWizard>
              ) : formStatus === 'finished' ? (
                <FinishPage handleClose={handleFinish} venueZone={venueZone} />
              ) : (
                <StartPage
                  handleStart={handleStart}
                  isAlternative={isAlternative}
                />
              )}
            </Main>
          </StyledWrapper>

          <FormPreview
            formState={formState}
            previewState={previewState}
            requestDays={requestDays}
            preArrivals={preArrivals}
            postEvents={postEvents}
            hasEquipment={hasEquipment}
            hasCatering={hasCatering}
            roomAmenities={roomAmenities}
            currency={currency}
            firstMeetingRoomId={firstMeetingRoomId}
            {...unitsExtras}
            {...prices}
          />

          <NW2ModalDrawer
            header='Attention!'
            body={
              <>
                <ModalExitBody>
                  <ModalExitIcon>
                    <Icon icon='TRIANGLE_WARN' />
                  </ModalExitIcon>
                  <span>
                    Please note that exiting at this point will result in your
                    input data being lost.
                  </span>
                </ModalExitBody>
                <ModalExitActions>
                  <NW2Button onClick={toggleModalExitShowed} size='small'>
                    cancel
                  </NW2Button>
                  <NW2Button
                    onClick={handleClose}
                    buttonType='primary'
                    size='small'
                  >
                    exit
                  </NW2Button>
                </ModalExitActions>
              </>
            }
            isShowed={isModalExitShowed}
            isMobile={false}
            onClose={toggleModalExitShowed}
            modalWidth={400}
          />
        </Container>
      ) : (
        <LoaderContainer>
          <NW2Loader />
        </LoaderContainer>
      )}
    </ModalOverlay>
  );
}
