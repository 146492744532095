import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';
import {useAppDispatch, useAppSelector} from 'store/hooks';

import NW2ModalDrawer from 'view/components/NW2ModalDrawer';
import NW2Button from 'view/components/NW2Button';
import TextValue from 'view/components/TextValue';

import InputRadioButton from 'view/components/NW2FormItem/components/Input/InputRadioButton';
import {CardNameContainer} from 'view/components/AdyenPayment/components/CardNameContainer';
import {TStoredCard} from 'store/payment/types';

import {
  ActionSection,
  ExtraParagraph,
  ExtraRow,
  ExtraText,
  StyledIcon,
  StyledSpan,
  StyledCardNameContainer,
  CardSection,
  InfoWrapper,
} from 'view/components/PaymentMethodsView/NW2PaymentMethodsView.styles';
import {modalSmallWidth} from 'constants/styleVars';
import {ButtonsContainer} from 'view/components/AdyenPayment/AdeynPayment.styles';
import {
  deletePaymentMethod,
  setDefaultCreditCard,
} from 'store/payment/apiActions';
import {setIsPaymentMethodRemoved} from 'store/payment/paymentSlice';

type TProps = TStoredCard & {
  isDesktop: boolean;
  defaultCardDetails: TStoredCard;
};

export function PaymentMethodsListItem({
  uuid,
  isDesktop,
  holderName,
  expiryMonth,
  expiryYear,
  name,
  brand,
  lastFour,
  defaultCardDetails,
}: TProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useAppDispatch();
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  const customerId = useAppSelector(({app}) => app.user.id);
  const isPaymentMethodRemoved = useSelector((state) =>
    _get(state, 'payment.isPaymentMethodRemoved'),
  );
  const isPaymentInProcess = useSelector((state) =>
    _get(state, 'payment.isPaymentInProcess'),
  );

  const onToggleDeleteModal = () =>
    setIsModalVisible((prevState) => !prevState);

  const onDeleteCardHandler = () => {
    dispatch(
      deletePaymentMethod({customerId: String(customerId), creditCardId: uuid}),
    );
  };

  useEffect(() => {
    if (isModalVisible) dispatch(setIsPaymentMethodRemoved(false));
  }, [isModalVisible, dispatch]);

  useEffect(() => {
    if (isPaymentMethodRemoved) setIsModalVisible(false);
  }, [isPaymentMethodRemoved]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setDefaultCreditCard(event.target.value));
  };

  const isChecked = uuid === defaultCardDetails.uuid;

  return (
    <>
      <CardSection>
        <InputRadioButton
          name='radio'
          onChange={onChange}
          value={uuid}
          checked={isChecked}
          disabled={isPaymentInProcess}
        />
        <InfoWrapper>
          <ExtraRow>
            <CardNameContainer brand={brand} name={name} lastFour={lastFour} />
          </ExtraRow>
          {!isDesktop && (
            <>
              {holderName && <ExtraRow>{holderName}</ExtraRow>}
              <ExtraRow>
                <ExtraText>Expires on:</ExtraText> {expiryMonth}/{expiryYear}
              </ExtraRow>
            </>
          )}
          {isDesktop && (
            <>
              <ExtraParagraph>{holderName}</ExtraParagraph>
              <ExtraParagraph>
                {expiryMonth}/{expiryYear}
              </ExtraParagraph>
            </>
          )}
        </InfoWrapper>

        <ActionSection>
          <StyledIcon icon='NW2_CANCEL_BIN' onClick={onToggleDeleteModal} />
        </ActionSection>
      </CardSection>

      <NW2ModalDrawer
        header='Delete card details'
        isShowed={isModalVisible}
        onClose={onToggleDeleteModal}
        isMobile={isMobile}
        modalWidth={modalSmallWidth}
        drawerHeight='auto'
      >
        <TextValue>
          You are about to delete the selected payment method.&nbsp;
          <StyledSpan>Do you want to proceed?</StyledSpan>
        </TextValue>
        <StyledCardNameContainer brand={name} name={name} lastFour={lastFour} />
        <ButtonsContainer>
          <NW2Button
            inline
            buttonType='secondary'
            onClick={onToggleDeleteModal}
          >
            Cancel
          </NW2Button>
          <NW2Button
            inline
            buttonType='primary'
            onClick={onDeleteCardHandler}
            loading={isPaymentInProcess}
          >
            {isPaymentInProcess ? 'Loading...' : 'Delete card'}
          </NW2Button>
        </ButtonsContainer>
      </NW2ModalDrawer>
    </>
  );
}
