import {ApiType, IApi} from '../common/http.service';
import {IMultiDayVenueDetailsRequestData} from 'store/venues/actions';
import {
  IGetVenueUnitBookingStatisticsByDatesRequestData,
  ISetVenueUnitBookingStatusPayload,
  IVenueUnitBookingStatisticsItem,
} from 'types/dto/IVenueUnitBookingStatisticsByDate.type';
import {
  IBookingOrderPayload,
  IBookingOrderResponse,
  IEditBookingOrderPayload,
  IRecalculationRequest,
  IUnitBooking,
  TAttendee,
} from 'types/dto/IBooking.types';
import {INITIAL_RADIUS, VENUE_LIST_LIMIT} from 'constants/app';
import {
  ICalculatedVenue,
  IMergedVenue,
  IVenuesListResponse,
  TSearchVenuesPayload,
} from 'types/search';
import {IUnitWithDetailedExtras} from 'types/dto/IUnit.types';
import {IVenue} from 'types/venue';
import {IMultiDayPublicVenue} from '../../types/dto/IPublicVenue';
import {IDay} from 'types/dto/IDay.type';

// SUPPLIER REPOSITORY WITH HTTP REQUESTS
const venuesRepository = (api: IApi) => ({
  searchAndCalculate: async ({
    latitude,
    longitude,
    limit = VENUE_LIST_LIMIT,
    radius = INITIAL_RADIUS,
    filterDays,
    corporateAccountId,
  }: TSearchVenuesPayload) => {
    const {pageItems, size, totalFilteredRecords}: IVenuesListResponse =
      await api.add(ApiType.Search, `venues`, {
        latitude,
        longitude,
        limit,
        radius,
        page: 0,
        corporateAccountId,
        filterDays,
      });

    if (!size) {
      return {
        size,
        totalFilteredRecords,
        pageItems,
        filterDays,
      };
    }

    const mergedVenues = await (async () => {
      const accommodationIds = pageItems
        // .filter(({hasPrice}) => hasPrice)
        .map(({accommodationId}) => accommodationId);

      if (accommodationIds.length) {
        const calculatedList: ICalculatedVenue[] = await api.add(
          ApiType.Inventory,
          `v2/calculation`,
          {
            accommodationIds,
            filterData: filterDays,
          },
        );

        return pageItems.reduce((acc: IMergedVenue[], item) => {
          const prices = calculatedList.find(
            ({accommodationId}) => accommodationId === item.accommodationId,
          ) as ICalculatedVenue;

          acc.push({
            ...prices,
            ...item,
          });
          return acc;
        }, []);
      }

      return pageItems;
    })();

    return {
      size,
      totalFilteredRecords,
      pageItems: mergedVenues,
      filterDays,
    };
  },

  getMultiDayVenueDetails: async (
    data: IMultiDayVenueDetailsRequestData,
  ): Promise<IMultiDayPublicVenue> =>
    await api.add(
      ApiType.Inventory,
      `v2/accommodations/multibook-details`,
      data,
    ),

  getMultiDayMultiVenueDetails: async (
    data: IMultiDayVenueDetailsRequestData[],
  ) => {
    return data.map(async (item) => {
      return await api.add(
        ApiType.Inventory,
        `v2/accommodations/multibook-details`,
        item,
      );
    });
  },

  getMultiSearchVenueDetails: async (data: {
    accommodationId: string | number;
    units: IUnitWithDetailedExtras[];
  }): Promise<any> =>
    await api.add(
      ApiType.Inventory,
      `accommodations/${data.accommodationId}/multibook-details`,
      data,
    ),

  getOperationalTimes: async (
    accommodationId: string | number,
  ): Promise<IDay[]> =>
    await api.get(ApiType.Availability, `operational-time/${accommodationId}`),

  setOperationalTimes: async (data: {
    accommodationId: string | number;
    updatedOperationalTimes: IDay[];
  }): Promise<IDay[]> =>
    await api.update(
      ApiType.Availability,
      `operational-time/${data.accommodationId}`,
      data.updatedOperationalTimes,
    ),

  confirmBooking: async (
    data: IBookingOrderPayload,
  ): Promise<IBookingOrderResponse> =>
    await api.add(ApiType.Inventory, `orders/v2`, data),

  postEditCustomerOrder: async (
    data: IEditBookingOrderPayload,
  ): Promise<IBookingOrderResponse> =>
    await api.add(
      ApiType.Inventory,
      `orders/v2/edit/${data.orderNumber}`,
      data,
    ),

  cancelBooking: async (id: number): Promise<number> =>
    await api.update(ApiType.Inventory, `orders/cancel/${id}`, {}),

  cancelRTCBooking: async (orderId: number): Promise<number> =>
    await api.update(ApiType.Inventory, `orders/v2/cancel/${orderId}`, {}),

  cancelRTCBookingAll: async (orderNumber: string): Promise<number> =>
    await api.update(
      ApiType.Inventory,
      `orders/v2/cancel/all/${orderNumber}`,
      {},
    ),

  getVenueUnitBookingStatisticsByDates: async (
    data: IGetVenueUnitBookingStatisticsByDatesRequestData,
  ): Promise<IVenueUnitBookingStatisticsItem[]> =>
    await api.get(
      ApiType.Inventory,
      `bookings/statistics/${data.venueId}?from=${data.from}&to=${data.to}`,
    ),

  setVenueUnitBookingStatus: async (
    data: ISetVenueUnitBookingStatusPayload,
  ): Promise<any> =>
    await api.add(
      ApiType.Inventory,
      `bookings/statistics/${data.venueId}`,
      data.payload,
    ),

  getBooking: async (orderNumber: string): Promise<any> =>
    await api.get(ApiType.Inventory, `orders/v2/${orderNumber}`),

  getRecalculatedUnits: async ({
    orderNumber,
    accommodationId,
    unitsToRecalculate,
  }: {
    orderNumber: string;
    accommodationId: number;
    unitsToRecalculate: IRecalculationRequest[];
  }): Promise<IUnitBooking[]> =>
    await api.add(
      ApiType.Inventory,
      `orders/v2/price/${accommodationId}/${orderNumber}`,
      unitsToRecalculate,
    ),

  updateBookingAttendees: async ({
    bookingId,
    attendees,
  }: {
    bookingId: string;
    attendees: TAttendee[];
  }): Promise<TAttendee[]> =>
    await api.update(ApiType.Inventory, `booking/${bookingId}/attendees`, {
      data: attendees,
    }),

  // setEventName: async ({
  //   name,
  //   bookingId,
  // }: {
  //   bookingId: string;
  //   name: string;
  // }): Promise<any> =>
  //   await api.patch(
  //     ApiType.Inventory,
  //     `booking/${bookingId}/eventName?eventName=${name}`,
  //     {},
  //   ),

  getVenuesByIds: async (ids: number[]): Promise<IVenue[]> =>
    await api.get(ApiType.Inventory, `venues/all/${ids.join()}`),
});

export default venuesRepository;
