import {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _get from 'lodash/get';

import {EEventType, IVenue} from 'types/venue';
import {setBookingPreview} from 'store/venues/actions';
import {IExtrasOption} from 'types/dto/IExtras.type';
import {TGroupedUnitsByDay} from 'types/dto/IBooking.types';
import {ICustomerBillingAddress, IUser} from 'types/dto/IUser.types';
import {makeOfferPreviewUnitsData} from 'view/venue/Offer/helpers';
import {useAppSelector} from 'store/hooks';
import {clearOfferDetails} from 'store/offers/offersSlice';
import {getFilteredUnitsByEventType} from 'utils/venueUtils';
import {setRedirectLink} from 'store/app/appSlice';
import {setVenue} from 'store/venue/actions';

export function useOfferBookingData(venue: IVenue) {
  const dispatch = useDispatch();

  const [dataReady, setDataReady] = useState(false);

  const {billingAddress, firstName, lastName, email, phone}: IUser =
    useSelector((state) => _get(state, 'app.user'));
  const previewUnits: TGroupedUnitsByDay[] = useSelector((state) =>
    _get(state, `venuesReducer.bookingPreview.bookingMaskPreviewUnits`),
  );
  const extrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );

  const offerDetails = useAppSelector(({offers}) => offers.offerDetails);

  const {checkIn, checkOut, days = []} = offerDetails;

  const {preArrivals, postEvents} = useMemo(() => {
    return {
      preArrivals: getFilteredUnitsByEventType(days, EEventType.PRE_ARRIVAL),
      postEvents: getFilteredUnitsByEventType(days, EEventType.POST_EVENT),
    };
  }, [days]);

  // clear store data on page leave
  useEffect(() => {
    return () => {
      dispatch(
        setBookingPreview({
          bookingMaskPreviewUnits: [],
          attendees: [],
        }),
      );

      dispatch(setRedirectLink(''));
      dispatch(clearOfferDetails());
      dispatch(setVenue(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (previewUnits.length) {
      setDataReady(true);
    }
  }, [previewUnits]);

  useEffect(() => {
    if (!venue?.id || !checkIn || previewUnits.length) return;

    const initPreviewUnitsData = makeOfferPreviewUnitsData({
      searchStartDate: checkIn,
      searchEndDate: checkOut,
      days,
      extrasOption,
    });

    dispatch(
      setBookingPreview({
        bookingMaskPreviewUnits: initPreviewUnitsData,
      }),
    );
  }, [
    checkIn,
    checkOut,
    days,
    dispatch,
    extrasOption,
    previewUnits.length,
    venue?.id,
  ]);

  const formInitialValues = useMemo(() => {
    const defaultBillingAddress = billingAddress?.find(
      (address: ICustomerBillingAddress) => address.isDefault,
    );

    return {
      firstName,
      lastName,
      email,
      phone,
      ...(defaultBillingAddress || {}),
      orderDays: previewUnits,
    };
  }, [billingAddress, email, firstName, lastName, phone, previewUnits]);

  if (!dataReady) return null;

  return {
    formInitialValues,
    preArrivals,
    postEvents,
  };
}
