import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from 'store/hooks';

import NW2ModalDrawer from 'view/components/NW2ModalDrawer';

import {NW2SummaryDesktopBlock} from 'view/components/NW2SummaryBlock';
import {
  useBookingSummaryContent,
  useBookingSummaryModal,
} from 'view/venue/components/NW2BookingSummary/hooks';

import {EBookingSummaryTheme} from 'view/components/NW2SummaryBlock/types';
import {TImage} from 'types/app';
import {EAccommodationType, IPublicVenue} from 'types/dto/IPublicVenue';
import {getCustomerPreviousBookingOrder} from 'store/customer/apiActions';
import {EBookingStatus} from 'types/booking';
import {IOfferRequestDay} from 'types/offer';
import {getFilteredUnitsByEventType} from 'utils/venueUtils';

type TProps = {
  venueAddress: string;
  venueDetails: IPublicVenue;
  venueCoverImage: TImage;
  linkTermsText: string;
  preArrivals?: IOfferRequestDay[];
  postEvents?: IOfferRequestDay[];
};

const NW2BookingConfirmationSummary = ({
  venueAddress,
  venueDetails,
  venueCoverImage,
  linkTermsText,
  preArrivals,
  postEvents,
}: TProps) => {
  const dispatch = useAppDispatch();

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const bookingOrder = useAppSelector(
    ({customer}) => customer.customerBookingOrder,
  );
  const previousBookingOrder = useAppSelector(
    ({customer}) => customer.customerPreviousBookingOrder,
  );
  const customerBookedUnits = useAppSelector(
    ({customer}) => customer.customerBookedUnits,
  );

  const {accommodationType, currency, name} = venueDetails;

  const isTotalPriceVisible =
    accommodationType !== EAccommodationType.CORPORATE_OFFICE;

  // modals
  const {isModalShowed, modalTitle, modalBody, onModalShow, onModalClose} =
    useBookingSummaryModal();

  const isEdited = bookingOrder?.bookingStatus === EBookingStatus.RTC_PENDING;

  const meetingCustomerBookedUnits = customerBookedUnits
    ? getFilteredUnitsByEventType(customerBookedUnits)
    : [];

  const {
    headerSection,
    totalPriceSection,
    unitsSection,
    totalDiffPriceSection,
  } = useBookingSummaryContent({
    currency,
    unitBookings: meetingCustomerBookedUnits,
    isRfp: bookingOrder?.isRfp,
    theme: EBookingSummaryTheme.LIGHT,
    isTotalPriceVisible,
    totalPrice: bookingOrder?.totalPrice || 0,
    prevTotalPrice: previousBookingOrder?.totalPrice,
    venueAddress,
    venueName: name,
    venueCoverImage,
    preArrivals,
    postEvents,
    onModalShow,
    editMode: isEdited,
  });

  useEffect(() => {
    if (isEdited && !previousBookingOrder) {
      dispatch(getCustomerPreviousBookingOrder(bookingOrder.orderNumber));
    }
  }, [dispatch, isEdited, previousBookingOrder, bookingOrder]);

  if (!meetingCustomerBookedUnits) return null;

  return (
    <>
      <NW2SummaryDesktopBlock
        isStickyDisabled
        theme={EBookingSummaryTheme.LIGHT}
        isSticky={false}
        headerSection={headerSection}
        totalPriceSection={totalPriceSection}
        totalDiffPriceSection={totalDiffPriceSection}
        unitsSection={unitsSection}
        linkTermsText={linkTermsText}
      />

      <NW2ModalDrawer
        isMobile={isMobile}
        isShowed={isModalShowed}
        header={modalTitle}
        body={modalBody}
        onClose={onModalClose}
        drawerHeight='auto'
      />
    </>
  );
};

export default NW2BookingConfirmationSummary;
