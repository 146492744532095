import React from 'react';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import _get from 'lodash/get';

import Icon from 'view/components/Icon';
import TopNotificationContainer from './TopNotificationContainer';

import {EPaymentAccountStatus} from 'types/venue';
import {Link} from 'view/components/Typography';
import {NW2Error50Color} from 'styles/configs/colors';
import {Routes} from 'constants/routes';
import {
  EVenueProfilePages,
  VenueProfileHash,
} from 'view/venue/NW2VenueProfile/types';

const VenuePayoutStatusNotification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const venueId = useSelector((state) => _get(state, 'venue.venue.id'));
  const payAccStatus = useSelector((state) =>
    _get(state, 'venue.venue.payAccStatus'),
  );

  const isNotificationHidden =
    payAccStatus === EPaymentAccountStatus.ACTIVE ||
    payAccStatus === EPaymentAccountStatus.CHECK_PENDING ||
    payAccStatus === EPaymentAccountStatus.ACCOUNT_DISABLED ||
    (location.hash === VenueProfileHash[EVenueProfilePages.PAYMENT] &&
      location.pathname === Routes.publicVenue) ||
    !venueId;

  const navigateToPayment = () => {
    navigate(
      `${Routes.publicVenue}${VenueProfileHash[EVenueProfilePages.PAYMENT]}`,
    );
  };

  if (isNotificationHidden) return null;

  return (
    <TopNotificationContainer background={NW2Error50Color}>
      <Icon error icon='WARN' />
      &nbsp;Your venue payment information is incomplete.&nbsp;
      <Link onClick={navigateToPayment}>Check status now</Link>
    </TopNotificationContainer>
  );
};

export default VenuePayoutStatusNotification;
