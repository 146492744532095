import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';

import Icon from 'view/components/Icon';
import NW2Button from 'view/components/NW2Button';
import ExtrasList from './ExtrasList/ExtrasList';
import SpaceItem from './SpaceItem/SpaceItem';
import ActionButtons from './ActionButtons';

import {
  filterByAvailability,
  filterPreviewExtrasByExtraType,
} from 'utils/venueUtils';
import {
  getFoodAndBeverageState,
  getUpdatedExtras,
  makeRequestCalculationDataForExtra,
} from '../helpers';
import {EResourcesType, IExtrasResponse} from 'types/dto/IExtras.type';
import {
  IExtraState,
  ISummaryExtra,
  ISummaryUnit,
} from 'types/dto/IBooking.types';
import {IUnit} from 'types/dto/IUnit.types';
import {getRecalculatedUnits} from 'store/customer/apiActions';

import {
  CardWrapper,
  ExtrasListWrapper,
  FoodBeveragePreviewList,
  UnitTitle,
} from './Space.styles';
import {IOfferUnitExtra} from 'types/offer';
import {EEventType, IBookedExtra} from 'types/venue';
import {BEDROOMS_NAMES_FROM_BE} from 'constants/bedrooms';
import {IExtraResponse} from 'types/dto/IPublicVenue';

type TProps = {
  dayIndex?: number;
  isMultiDay?: boolean;
  venueUnits: IUnit[];
  bookedUnits: ISummaryUnit[];
  bookedFoodAndBeverage: (ISummaryExtra | IOfferUnitExtra)[];
  isPresetEditModeOn: boolean;
  setIsPresetEditModeOn: (value: boolean) => void;
  bookedBedrooms?: (IOfferUnitExtra | IBookedExtra | IExtraResponse)[];
  eventType?: EEventType;
};

const Space = ({
  bookedUnits,
  venueUnits,
  isMultiDay = false,
  dayIndex = 0,
  bookedFoodAndBeverage,
  isPresetEditModeOn,
  setIsPresetEditModeOn,
  bookedBedrooms,
  eventType,
}: TProps) => {
  const dispatch = useAppDispatch();
  const initMaxToShowFoodBeverage = 3;

  const roomType = useAppSelector(({search}) => search.searchCriteria.roomType);

  const orderNumber = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.orderNumber,
  );

  const accommodationId = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.accommodationId,
  );

  const customerBookedUnitsToReCalculate = useAppSelector(
    ({customer}) => customer.customerBookedUnitsToReCalculate,
  );

  /**
   * todo can't change to useVenueDetailsData because IMultiDayPublicVenue hasn't extras... clarify what to do
   * looks like we need to have useVenueDetailsExtras hook
   */
  const availableExtras: IExtrasResponse[] = useSelector((state) =>
    _get(state, 'venuesReducer.venueDetails.extras'),
  );

  const availableExtrasByBookable = filterByAvailability<IExtrasResponse>(
    availableExtras,
    roomType,
  );

  const initFoodAndBeverageState = getFoodAndBeverageState(
    dayIndex,
    customerBookedUnitsToReCalculate,
    availableExtrasByBookable,
  );
  const initBedroomsState = useMemo(() => {
    return (
      (bookedBedrooms as IBookedExtra[])?.map(
        ({accommodationExtraId, bookedQuantity}) => ({
          accommodationExtraId,
          bookedQuantity,
        }),
      ) || []
    );
  }, [bookedBedrooms]);

  const [foodAndBeverageState, setFoodAndBeverageState] = useState<
    IExtraState[]
  >(initFoodAndBeverageState);
  const [bedroomsState, setBedroomsState] =
    useState<IExtraState[]>(initBedroomsState);

  const [isFoodAndBeverageEditMode, setIsFoodAndBeverageEditMode] =
    useState(false);
  const [isBedroomsEditMode, setIsBedroomsEditMode] = useState(false);

  const [isPresetChanged, setIsPresetChanged] = useState(false);
  const [isBedroomsPresetChanged, setIsBedroomsPresetChanged] = useState(false);

  const equipmentList = filterPreviewExtrasByExtraType<IExtrasResponse>(
    availableExtrasByBookable,
    EResourcesType.EQUIPMENT,
  );

  const foodBeverageList = filterPreviewExtrasByExtraType<IExtrasResponse>(
    availableExtrasByBookable,
    EResourcesType.FOOD_AND_BEVERAGE,
  );

  const bedroomsList = filterPreviewExtrasByExtraType(
    availableExtras,
    EResourcesType.BEDROOM,
  );

  const bedroomsToShow = bedroomsList.filter((room) => {
    return (bookedBedrooms as IBookedExtra[])?.some(
      (orderedRoom) => orderedRoom.accommodationExtraId === room.id,
    );
  });

  const showFoodBeverageList =
    !!foodBeverageList.length &&
    eventType !== EEventType.POST_EVENT &&
    eventType !== EEventType.PRE_ARRIVAL;

  const foodAndBeverageOpenEditButtonHandler = () => {
    setIsFoodAndBeverageEditMode(true);
    setIsPresetEditModeOn(true);
  };

  const foodAndBeverageCancelEditButtonHandler = () => {
    setIsFoodAndBeverageEditMode(false);
    setFoodAndBeverageState(initFoodAndBeverageState);
    setIsPresetEditModeOn(false);
  };

  const foodAndBeverageSaveChangesButtonHandler = () => {
    const updatedData = makeRequestCalculationDataForExtra(
      customerBookedUnitsToReCalculate,
      dayIndex,
      foodAndBeverageState,
      'foodAndBeverage',
    );
    const onSuccessHandler = () => {
      setIsFoodAndBeverageEditMode(false);
      setIsPresetEditModeOn(false);
    };

    dispatch(
      getRecalculatedUnits(
        orderNumber as string,
        accommodationId as number,
        updatedData,
        onSuccessHandler,
      ),
    );
  };
  const bedroomsSaveChangesButtonHandler = () => {
    const bedroomsUpdatedData = makeRequestCalculationDataForExtra(
      customerBookedUnitsToReCalculate,
      dayIndex,
      bedroomsState,
      'bedrooms',
    );
    const onSuccessHandler = () => {
      setIsBedroomsEditMode(false);
      setIsPresetEditModeOn(false);
    };

    dispatch(
      getRecalculatedUnits(
        orderNumber as string,
        accommodationId as number,
        bedroomsUpdatedData,
        onSuccessHandler,
      ),
    );
  };

  const checkIsFoodAndBeverageStateDataChanged = useCallback(() => {
    return _isEqual(initFoodAndBeverageState, foodAndBeverageState);
  }, [initFoodAndBeverageState, foodAndBeverageState]);
  const checkIsBedroomsStateDataChanged = useCallback(() => {
    return _isEqual(initBedroomsState, bedroomsState);
  }, [initBedroomsState, bedroomsState]);

  const onFoodAndBeverageQuantity = (
    extraId: number,
    quantityValue: number,
  ) => {
    const updatedFoodAndBeverage = getUpdatedExtras(
      foodAndBeverageState,
      extraId,
      quantityValue,
    );
    setFoodAndBeverageState(updatedFoodAndBeverage);
  };

  const onBedroomsQuantity = (extraId: number, quantityValue: number) => {
    const updatedBedrooms = getUpdatedExtras(
      bedroomsState,
      extraId,
      quantityValue,
    );
    setBedroomsState(updatedBedrooms);
  };

  const isCustomerBookedUnits = bookedUnits.some((item) => item.chosenPackage);

  useEffect(() => {
    const isChanged = checkIsFoodAndBeverageStateDataChanged();
    setIsPresetChanged(!isChanged);
  }, [checkIsFoodAndBeverageStateDataChanged]);
  useEffect(() => {
    const isBedroomsChanged = checkIsBedroomsStateDataChanged();
    setIsBedroomsPresetChanged(!isBedroomsChanged);
  }, [checkIsBedroomsStateDataChanged]);

  function bedroomsOpenEditButtonHandler() {
    setIsBedroomsEditMode(true);
    setIsPresetEditModeOn(true);
  }

  const bedroomsCancelEditButtonHandler = () => {
    setIsBedroomsEditMode(false);
    setBedroomsState(initBedroomsState);
    setIsPresetEditModeOn(false);
  };

  return (
    <CardWrapper>
      {bookedUnits.map((unit, index) => {
        const availableSeatPlans = venueUnits.find(
          (item) => item.id === unit.unitId,
        )?.unitCapacities;

        return (
          <SpaceItem
            key={unit.unitId}
            unit={unit}
            dayIndex={dayIndex}
            roomCounter={isMultiDay ? (index + 1).toString() : ''}
            equipmentList={equipmentList}
            isMultiDay={isMultiDay}
            availableSeatPlans={availableSeatPlans}
            setIsPresetEditModeOn={setIsPresetEditModeOn}
            isPresetEditButtonDisabled={isPresetEditModeOn}
          />
        );
      })}
      {showFoodBeverageList && (
        <>
          <UnitTitle marginBottom={0}>
            <span>
              {isCustomerBookedUnits
                ? 'Additional Food & Beverage'
                : 'Food & Beverage'}
            </span>
            {!isFoodAndBeverageEditMode && (
              <NW2Button
                minimized
                size='small'
                buttonType='tertiary'
                icon={<Icon transparent icon='NW2_EDIT' />}
                onClick={foodAndBeverageOpenEditButtonHandler}
                disabled={isPresetEditModeOn}
              />
            )}
          </UnitTitle>
          {isFoodAndBeverageEditMode ? (
            <ExtrasListWrapper>
              <ExtrasList
                extrasState={foodAndBeverageState}
                chosenExtras={bookedFoodAndBeverage as ISummaryExtra[]}
                availableExtras={foodBeverageList}
                initMaxToShow={initMaxToShowFoodBeverage}
                onExtraChangeQuantity={onFoodAndBeverageQuantity}
                //check is necessary this flag
                isFoodAndBeverage
              />
              <ActionButtons
                isSaveButtonDisabled={!isPresetChanged}
                onSaveHandler={foodAndBeverageSaveChangesButtonHandler}
                onCancelHandler={foodAndBeverageCancelEditButtonHandler}
              />
            </ExtrasListWrapper>
          ) : (
            !!bookedFoodAndBeverage?.length && (
              <FoodBeveragePreviewList>
                {(bookedFoodAndBeverage as ISummaryExtra[])
                  ?.filter(({bookedQuantity}) => bookedQuantity)
                  ?.map(({accommodationExtraId, extraName, bookedQuantity}) => {
                    return (
                      <li
                        key={accommodationExtraId}
                      >{`${bookedQuantity} x ${extraName}`}</li>
                    );
                  })}
              </FoodBeveragePreviewList>
            )
          )}
        </>
      )}

      {!!bookedBedrooms?.length && (
        <>
          <UnitTitle marginTop={showFoodBeverageList ? 24 : 0}>
            <span>Accommodation</span>
            {!isBedroomsEditMode && (
              <NW2Button
                minimized
                size='small'
                buttonType='tertiary'
                icon={<Icon transparent icon='NW2_EDIT' />}
                onClick={bedroomsOpenEditButtonHandler}
                disabled={isPresetEditModeOn}
              />
            )}
          </UnitTitle>
          {isBedroomsEditMode ? (
            <ExtrasListWrapper>
              <ExtrasList
                extrasState={bedroomsState}
                chosenExtras={bookedBedrooms as ISummaryExtra[]}
                onExtraChangeQuantity={onBedroomsQuantity}
                initMaxToShow={2}
                availableExtras={bedroomsToShow}
                isBedrooms
              />
              <ActionButtons
                isSaveButtonDisabled={!isBedroomsPresetChanged}
                onSaveHandler={bedroomsSaveChangesButtonHandler}
                onCancelHandler={bedroomsCancelEditButtonHandler}
              />
            </ExtrasListWrapper>
          ) : (
            !!bookedBedrooms?.length && (
              <FoodBeveragePreviewList>
                {(bookedBedrooms as ISummaryExtra[])?.map(
                  ({accommodationExtraId, extraName, bookedQuantity}) => {
                    const bedroomName =
                      extraName &&
                      BEDROOMS_NAMES_FROM_BE[
                        extraName as keyof typeof BEDROOMS_NAMES_FROM_BE
                      ];
                    return (
                      <li
                        key={accommodationExtraId}
                      >{`${bookedQuantity} x ${bedroomName}`}</li>
                    );
                  },
                )}
              </FoodBeveragePreviewList>
            )
          )}
        </>
      )}
    </CardWrapper>
  );
};

export default Space;
