import {useAppSelector} from 'store/hooks';
import {getDateTime} from 'utils/dateUtils';
import {useMemo} from 'react';

/**
 * getting difference in days for enabling RFP flow
 * should be not less than 4 days from current day
 */
export function useGetSearchDateDifference(): [number] {
  const searchStartDate = useAppSelector(
    ({search}) => search.multiSearchPayload[0]?.checkIn,
  );

  const diffInDays = useMemo(() => {
    const start = getDateTime(searchStartDate);
    const end = getDateTime(new Date());

    return Math.floor(end.diff(start, ['days']).days);
  }, [searchStartDate]);

  return [diffInDays];
}
