import React, {ReactNode} from 'react';
import {
  useTable,
  TableOptions,
  Row,
  useRowSelect,
  HeaderGroup,
  ColumnInstance,
} from 'react-table';

import NW2Loader from 'view/components/NW2Loader/NW2Loader';

import {
  NoData,
  Table,
  TableWrapper,
  Td,
  Th,
  THead,
  Tr,
  TrHead,
} from './TableLayout.styles';

interface IProps<P extends object = Record<string, never>> {
  emptyText?: ReactNode;
  isLoading?: boolean;
  options: TableOptions<P>;
  children?: ReactNode;
  onRowClick?: (row: Row<Record<string, never>>) => () => void;
  isTablet?: boolean;
  isCustomer?: boolean;
  priceStrikeThroughStatus?: string[];
}

interface ICustomHeaderGroup extends HeaderGroup<Record<string, never>> {
  textAlign?: string;
}

interface ICustomColumn extends ColumnInstance<Record<string, never>> {
  textAlign?: string;
}

function TableLayout({
  options,
  emptyText,
  isLoading,
  children,
  onRowClick,
  isTablet,
  isCustomer,
  priceStrikeThroughStatus,
}: IProps) {
  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable(options, useRowSelect);

  return (
    <TableWrapper>
      <Table {...getTableProps()}>
        {children}

        <THead>
          {headerGroups.map((headerGroup) => {
            const {key, ...restHeaderGroupProps} =
              headerGroup.getHeaderGroupProps();

            return (
              <TrHead key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const {key, ...restColumn} = column.getHeaderProps();

                  return (
                    <Th
                      key={key}
                      isTablet={isTablet}
                      textAlign={(column as ICustomHeaderGroup).textAlign}
                      {...restColumn}
                    >
                      {column.render('Header')}
                    </Th>
                  );
                })}
              </TrHead>
            );
          })}
        </THead>
        <tbody {...getTableBodyProps()}>
          {rows.length > 0 ? (
            rows.map((row) => {
              prepareRow(row);
              const {key, ...restRowProps} = row.getRowProps();

              return (
                <Tr
                  key={key}
                  {...restRowProps}
                  onClick={onRowClick && onRowClick(row)}
                  hasHover={!!onRowClick}
                  isSelected={(row as any).isSelected}
                >
                  {row.cells.map((cell) => {
                    const {key, ...restCellProps} = cell.getCellProps();

                    return (
                      <Td
                        key={key}
                        {...restCellProps}
                        isTablet={isTablet}
                        isCustomer={isCustomer}
                        isStrikeThroughPrice={priceStrikeThroughStatus?.includes(
                          row.original.status,
                        )}
                        textAlign={(cell.column as ICustomColumn).textAlign}
                      >
                        {cell.render('Cell')}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })
          ) : (
            <Tr>
              <Td style={{height: '60px'}} isTablet={isTablet}>
                <NoData>{isLoading ? <NW2Loader /> : emptyText}</NoData>
              </Td>
            </Tr>
          )}
        </tbody>
      </Table>
    </TableWrapper>
  );
}

export default TableLayout;
