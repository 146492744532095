import React from 'react';

import NW2Switch from 'view/components/NW2Switch';
import NW2Badge from 'view/components/NW2Badge';
import Select from './Select';

import {EDays} from 'constants/days';
import {BadgesContainer, HoursContainer, RowItem} from './OpenHoursForm.styles';
import {IDay, IDayOfWeek} from 'types/dto/IDay.type';
import {EFieldNames} from '../NW2OpeningHours.types';
import {capitalizeText} from 'utils/stringUtils';
import {
  createHoursOptions,
  filterMinOptions,
  filterMaxOptions,
} from 'utils/operationalHoursUtils';

interface IProps {
  wholeDayAccess: boolean;
  openFrom: string;
  openTo: string;
  isLoading?: boolean;
  getValueFromState: (
    day: IDayOfWeek,
    fieldName: keyof IDay,
  ) => string | number | boolean;
  updateField: (day: string, fieldName: string, value: any) => void;
  handle24HourClick: () => void;
  handleOpenFromChange: (value: string) => void;
  handleOpenToChange: (value: string) => void;
}

const SameEverydayForm = ({
  wholeDayAccess,
  openFrom,
  openTo,
  isLoading,
  getValueFromState,
  updateField,
  handle24HourClick,
  handleOpenFromChange,
  handleOpenToChange,
}: IProps) => {
  return (
    <>
      <BadgesContainer>
        {Object.values(EDays).map((day) => (
          <RowItem key={day}>
            <NW2Badge
              label={capitalizeText(day)}
              isActive={getValueFromState(day, 'active') as boolean}
              fullWidth
              size='small'
              disabled={isLoading}
              onClick={() =>
                updateField(day, 'active', !getValueFromState(day, 'active'))
              }
            />
          </RowItem>
        ))}
      </BadgesContainer>

      <HoursContainer>
        {!wholeDayAccess && (
          <>
            <RowItem>
              <Select
                name={EFieldNames.OPEN_HOURS_FROM}
                label='From'
                readonly
                options={filterMinOptions(
                  openTo.split(':')[0],
                  createHoursOptions(),
                )}
                onItemChange={handleOpenFromChange}
                inputProps={{
                  value: openFrom,
                  disabled: isLoading,
                }}
                data-testid={`operational hours select-from`}
              />
            </RowItem>

            <RowItem>
              <Select
                name={EFieldNames.OPEN_HOURS_TO}
                label='To'
                readonly
                options={filterMaxOptions(
                  openFrom.split(':')[0],
                  createHoursOptions(),
                )}
                onItemChange={handleOpenToChange}
                inputProps={{
                  value: openTo,
                  disabled: isLoading,
                }}
                data-testid={`operational hours select-to`}
              />
            </RowItem>
          </>
        )}

        <NW2Switch
          label='24 hour access'
          isActive={wholeDayAccess}
          disabled={isLoading}
          onClick={handle24HourClick}
        />
      </HoursContainer>
    </>
  );
};

export default SameEverydayForm;
