import React, {useCallback, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';
import {useAppDispatch, useAppSelector} from 'store/hooks';

import Icon from 'view/components/Icon';
import NW2ModalDrawer from 'view/components/NW2ModalDrawer';
import NW2Button from 'view/components/NW2Button';
import NW2Loader from 'view/components/NW2Loader/NW2Loader';
import OfferRequestSummary from './components/OfferRequestSummary';
import NW2BookingConfirmationActionButtons from 'view/venue/NW2BookingConfirmation/components/NW2BookingConfirmationActionButtons/NW2BookingConfirmationActionButtons';
import BookingConfirmationBlockList from 'view/venue/NW2BookingConfirmation/components/BookingConfirmationBlockList/BookingConfirmationBlockList';

import {OfferSteps} from '../OfferSteps/OfferSteps';
import {OfferRequestStatus} from '../OfferRequestStatus/OfferRequestStatus';
import {useActions} from 'hooks/useActions';
import {useRequestBedrooms} from 'view/venue/Offer/hooks/useRequestBedrooms';
import {EGroupStatus, ERequestStatus, IRequestDayItem} from 'types/offer';
import {useLoggedInUser} from 'hooks/useLoggedInUser';
import {getAddressStringFromLocation} from 'utils/stringUtils';
import {findCoverImage} from 'utils/helpers';
import useUpdateCancellationPolicyText from 'view/venue/hooks/useUpdateCancellationPolicyText';
import {
  cleanCustomerBookedUnits,
  cleanCustomerPreviousBookingOrder,
} from 'store/customer/customerSlice';
import {clearVenueDetails} from 'store/venues/actions';
import {Routes} from 'constants/routes';
import {useRequestCancelModal} from 'view/venue/NW2BookingConfirmation/hooks/useRequestCancelModal';
import {signOutUser} from 'store/app/apiActions';
import {
  Divider,
  GoBackBlock,
  LoaderWrapper,
  Main,
  StyledContainer,
  Title,
  Wrapper,
} from 'view/venue/NW2BookingConfirmation/NW2BookingConfirmation.styles';
import {
  OFFER_SENT_LINK_TERMS_TEXT,
  REQUEST_OFFER_SENT_LINK_TERMS_TEXT,
} from '../../constants';
import {EAccommodationType, IPublicVenue} from 'types/dto/IPublicVenue';
import {useOfferRequestDetails} from '../../hooks/useOfferRequestDetails';
import {useCommonExtras} from 'view/venue/hooks/useCommonExtras';
import {RootState} from 'store/types';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';

interface IProps {
  id: string;
  groupStatus: EGroupStatus;
  isOffer?: boolean;
  isRequest?: boolean;
  isGroupRequestPage?: boolean;
}

export function OfferRequestSecondStep({
  isRequest,
  isOffer,
  groupStatus,
  id,
  isGroupRequestPage,
}: IProps) {
  const dispatch = useAppDispatch();

  const {setOfferRequestedDetails, setOfferRequestedUnits, setVenueDetails} =
    useActions();

  const navigate = useNavigate();

  const isAgentRole =
    useSelector((state: RootState) => _get(state, 'app.user.role')) ===
    EUserRoleCognito.ROLE_AGENT;

  const billingAddress = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.billingAddressDto,
  );
  const isRfp = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.isRfp,
  );
  const customerDeleted = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.customerDeleted,
  );
  const searchCriteria = useAppSelector(({search}) => search.searchCriteria);
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const isCustomerLoading = useAppSelector(({customer}) => customer.isLoading);

  const {preArrivals, postEvents} = useRequestBedrooms(true);

  const {isLoggedInUser} = useLoggedInUser();

  const {offerDetails} = useOfferRequestDetails(!isGroupRequestPage);

  const venueId = offerDetails?.accommodationId;

  const requestedUnits = offerDetails.days.flatMap(({items}) => items);

  // get common extras
  useCommonExtras();

  useUpdateCancellationPolicyText({
    unitData: requestedUnits as IRequestDayItem[],
    isRfp: !!isRfp,
  });

  useEffect(() => {
    return () => {
      dispatch(cleanCustomerBookedUnits());
      setOfferRequestedDetails({});
      setOfferRequestedUnits([]);
    };
  }, [dispatch, setOfferRequestedDetails, setOfferRequestedUnits]);

  const goToCustomerBookings = () => {
    if (isAgentRole) {
      navigate({
        pathname: Routes.agentView,
      });
      return;
    }

    navigate({
      pathname: Routes.customerBookings,
    });
  };

  const {
    isModalShowed,
    onModalShow,
    onModalClose,
    modalTitle,
    modalBody,
    modalFooter,
  } = useRequestCancelModal({isGroupRequest: isGroupRequestPage, id});

  const handleModalShow = useCallback(
    () => () => {
      onModalShow();
    },
    [onModalShow],
  );

  useEffect(() => {
    return () => {
      dispatch(cleanCustomerPreviousBookingOrder());
      dispatch(clearVenueDetails()); // WD's
      setVenueDetails(null); // MR's
    };
  }, [dispatch, setVenueDetails]);

  useEffect(() => {
    if (!isLoggedInUser) {
      dispatch(signOutUser());
      navigate(Routes.login);
    }
  }, [dispatch, isLoggedInUser, navigate]);

  if (isCustomerLoading || !offerDetails?.id || !venueId)
    return (
      <LoaderWrapper isMobile={isMobile}>
        <NW2Loader label='Loading' />
      </LoaderWrapper>
    );

  const {documents, location: venueLocation} = offerDetails.venue;

  const latitude = String(venueLocation?.latitude);
  const longitude = String(venueLocation?.longitude);
  const searchString = `${venueLocation?.city}, ${venueLocation?.country}`;

  const meetingName = offerDetails?.eventName;

  const venueAddress = getAddressStringFromLocation(venueLocation);
  const venueCoverImage = findCoverImage(documents);

  const summaryBlock = (
    <OfferRequestSummary
      venueAddress={venueAddress}
      venueDetails={offerDetails.venue as unknown as IPublicVenue}
      venueCoverImage={venueCoverImage}
      isOffer={isOffer}
      postEvents={postEvents}
      preArrivals={preArrivals}
      isRequest={isRequest}
      isGroupRequest={isGroupRequestPage}
      status={offerDetails?.status}
      linkTermsText={
        isRequest
          ? REQUEST_OFFER_SENT_LINK_TERMS_TEXT
          : isOffer
          ? OFFER_SENT_LINK_TERMS_TEXT
          : 'Cancellation policy becomes binding once request is confirmed by the venue. You have already accepted the HRS '
      }
    />
  );

  return (
    <Wrapper>
      <StyledContainer>
        {isLoggedInUser && (
          <GoBackBlock>
            <NW2Button
              buttonType='secondary'
              minimized
              size='small'
              icon={<Icon transparent icon='NW2_MOVE_BACK' />}
              onClick={goToCustomerBookings}
            />
            <Title>bookings overview</Title>
          </GoBackBlock>
        )}

        {offerDetails?.status === ERequestStatus.REQUEST_PENDING && (
          <OfferSteps activeStep={2} />
        )}

        <Main>
          <div>
            <OfferRequestStatus
              offerDetails={offerDetails}
              searchString={searchString}
              latitude={latitude}
              longitude={longitude}
              searchCriteria={searchCriteria}
              venueZone={venueLocation.timeZone}
              groupStatus={groupStatus}
              isOffer={isOffer}
              isGroupRequestPage={isGroupRequestPage}
            />

            {!isAgentRole && (
              <NW2BookingConfirmationActionButtons
                onModalShow={handleModalShow}
                status={offerDetails?.status}
                isGroupRequest={isGroupRequestPage}
                isOfferRequest
              />
            )}

            {isMobile && summaryBlock}

            <Divider />

            <BookingConfirmationBlockList
              orderNumber={offerDetails?.id}
              bookingStatus={offerDetails?.status}
              accommodationType={EAccommodationType.VENUE}
              billingAddress={billingAddress}
              customerDeleted={customerDeleted}
              meetingName={meetingName}
              isOffer={isOffer}
              preArrivals={preArrivals}
              postEvents={postEvents}
              isGroupRequestPage={isGroupRequestPage}
              groupStatus={groupStatus}
              isOfferOrRequest
            />
          </div>

          {!isMobile && summaryBlock}
        </Main>
      </StyledContainer>

      <NW2ModalDrawer
        isMobile={isMobile}
        isShowed={isModalShowed}
        header={modalTitle}
        modalWidth={400}
        drawerHeight='auto'
        body={modalBody}
        footer={modalFooter}
        onClose={onModalClose}
      />

      {/*{!!spaceDetailsUnit && (*/}
      {/*  <NW2SpaceDetails altUnit={spaceDetailsUnit} isFooterHidden />*/}
      {/*)}*/}
    </Wrapper>
  );
}
