import {
  checkHistoryOffersByStatus,
  checkUpcomingOffersByStatus,
} from '../Bookings/BookingDetails/helpers';

import {ISearchRequestOfferDto} from 'types/dto/ISearchRequestOfferDto.type';
import {ERequestStatus} from 'types/offer';
import {ISearchOrder} from 'types/bookingOrders';

interface ITableData extends ISearchOrder {
  offerId: string;
}

export function useTableData(
  requests: ISearchRequestOfferDto[],
  offers: ISearchRequestOfferDto[],
) {
  const getDataForTableStructure = (
    data: ISearchRequestOfferDto[],
    isRequest: boolean,
    isOffer: boolean,
  ) =>
    data.map((item) => {
      return {
        orderId: item.id,
        offerId: item.offerId,
        orderNumber: item.number,
        checkInDate: item.checkIn,
        checkOutDate: item.checkOut,
        isMultiDay: item.isMultiDay,
        company: item.company || item.customer.companyName,
        totalPrice: item?.totalRequestPrice?.value || item.price?.value || 0,
        participants: item.participants,
        hasEquipment: item.hasEquipment,
        hasCatering: item.hasCatering,
        hasAccommodation: item.hasAccommodation,
        status: item.status,
        firstName: item.customer.firstName,
        lastName: item.customer.lastName,
        isRequest,
        isOffer,
      } as unknown as ITableData;
    });

  const updatedNotPendingRequests = getDataForTableStructure(
    requests.filter((item) => item.status !== ERequestStatus.REQUEST_PENDING),
    true,
    false,
  );

  const updatedHistoryOffers = getDataForTableStructure(
    offers.filter((item) => checkHistoryOffersByStatus(item.status)),
    false,
    true,
  );

  const updatedUpcomingOffers = getDataForTableStructure(
    offers.filter((item) => checkUpcomingOffersByStatus(item.status)),
    false,
    true,
  );

  return {
    updatedNotPendingRequests,
    updatedHistoryOffers,
    updatedUpcomingOffers,
  };
}
