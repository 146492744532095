import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import NWTable from 'view/components/Table/NWTable';
import BookingTodayOverview from 'view/supplier/Bookings/BookingsList/components/BookingTodayOverview';

import {
  EBookingFilterType,
  EInstantBookingFilterType,
  IBookingType,
} from 'types/dto/IBookingType.type';
import {columns} from './BookingTableColumns';
import {useActions} from 'hooks/useActions';
import {TablePaginationConfig} from 'antd/lib/table';
import {FilterValue, SorterResult} from 'antd/es/table/interface';
import {setIsPagination} from 'view/supplier/Bookings/BookingsList/helpers';
import {EOrderShowStatus} from 'types/bookingOrders';

function BookingMainContent() {
  const {getBookingsAction} = useActions();

  const bookingFilterType = EBookingFilterType.WORK_SPACE;

  const bookingsType: IBookingType = useSelector((state) =>
    _get(state, 'bookingReducer.bookingsType'),
  );

  const {isLoading, data, pagination} = useSelector((state) =>
    _get(state, `bookingReducer[${bookingsType}]`),
  );

  const isPagination = setIsPagination(bookingsType, bookingFilterType);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Record<string, string | any[]>>,
  ) => {
    getBookingsAction({
      bookingsType: bookingsType,
      pagination,
      filters,
      sortField: sorter.field,
      sortOrder: sorter.order,
    });
  };

  const isFilterTypeInstantBookings =
    bookingFilterType in EInstantBookingFilterType;
  const isTodaysOverview = bookingsType === IBookingType.ARRIVING_TODAY;
  const columnsType = isFilterTypeInstantBookings
    ? bookingsType
    : bookingFilterType;
  const tableColumns = columns[columnsType];

  const selectedKeys = useMemo(() => {
    return data[bookingFilterType].length
      ? data[bookingFilterType]
          .filter((item: any) => item.showStatus === EOrderShowStatus.UNREAD)
          .map((item: any) => item.orderId)
      : [];
  }, [data, bookingFilterType]);

  const rowSelectionProps = {
    selectedRowKeys: selectedKeys,
    hideSelectAll: true,
  };

  return isTodaysOverview ? (
    <BookingTodayOverview
      columns={tableColumns}
      rowSelection={rowSelectionProps}
    />
  ) : (
    <NWTable
      columns={tableColumns}
      tableData={data[bookingFilterType]}
      pagination={pagination}
      isLoading={isLoading}
      isPagination={isPagination}
      handleTableChange={handleTableChange}
      rowKey='orderId'
      tableEntityText='requests'
      rowSelection={rowSelectionProps}
    />
  );
}

export default BookingMainContent;
