import React, {ChangeEvent, FC, useEffect, useState} from 'react';

import PackagesCard from './PackagesCard';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {
  setCustomerSelectedPackages,
  updateCustomerSelectedPackages,
} from 'store/customer/customerSlice';
import getSymbolFromCurrency from 'currency-symbol-map';
import {
  findPackageByType,
  getPackageExtrasDescriptions,
} from 'view/venue/NW2BookingPreview/helpers';
import {addPackageBookingPreview} from 'store/venues/actions';
import {formatFloatWithOneNumber} from 'utils/venueUtils';

import {
  EPackageText,
  EPackageType,
  IPackageResponse,
  TSelectedPackageItem,
} from 'types/dto/IPackages.type';
import {infoColor} from 'constants/styleVars';
import {EMPTY_OBJECT} from 'constants/app';
import {
  RadioBtnGroup,
  RadioBtnLabel,
  StyledRadioBtnIcon,
  StyledRadioBtnPrice,
  StyledRadioBtnText,
} from '../Space.styles';

enum EPackageIcons {
  HALF_DAY = 'HALF_DAY_PACKAGE',
  FULL_DAY = 'FULL_DAY_PACKAGE',
  FULL_DAY_WITH_DINNER = 'FULL_DAY_PACKAGE_PLUS',
}

type TPackageData = Partial<Record<EPackageType, any>>;
type TProps = {
  packages: IPackageResponse[];
  customerBookedPackage?: IPackageResponse;
  isSpaceEditMode?: boolean;
  dayIndex: number;
  unitId: number;
  defaultPackageType?: EPackageType;
};
const PackagesOptions: FC<TProps> = ({
  packages,
  customerBookedPackage,
  dayIndex,
  unitId,
  defaultPackageType,
}) => {
  const customerBookedUnits = useAppSelector(
    ({customer}) => customer.customerBookedUnitsToReCalculate,
  );

  const dispatch = useAppDispatch();

  const initialValue = customerBookedPackage?.type || defaultPackageType;
  const [selectedPackageType, setSelectedPackageType] = useState(initialValue);

  const selectedPackageData = findPackageByType(
    selectedPackageType,
    packages,
  ) as IPackageResponse | null;

  useEffect(() => {
    if (customerBookedUnits?.length) {
      const defaultMultiDayCustomerPackages = customerBookedUnits.map((day) =>
        day.unitBookings.map(({unitId}) => [unitId, null]),
      );
      dispatch(
        setCustomerSelectedPackages(
          defaultMultiDayCustomerPackages as TSelectedPackageItem[][],
        ),
      );
    }
  }, [dispatch, customerBookedUnits]);

  useEffect(() => {
    if (selectedPackageData) {
      dispatch(
        updateCustomerSelectedPackages({
          newPackage: selectedPackageData,
          dayIndex,
          unitId,
        }),
      );
      dispatch(addPackageBookingPreview());
    }
  }, [unitId, dayIndex, selectedPackageType, selectedPackageData, dispatch]);

  const packageData: TPackageData = {
    [EPackageType.HALF_DAY]: findPackageByType(EPackageType.HALF_DAY, packages),
    [EPackageType.FULL_DAY]: findPackageByType(EPackageType.FULL_DAY, packages),
    [EPackageType.FULL_DAY_WITH_DINNER]: findPackageByType(
      EPackageType.FULL_DAY_WITH_DINNER,
      packages,
    ),
    ...(customerBookedPackage
      ? {[customerBookedPackage.type]: customerBookedPackage}
      : EMPTY_OBJECT),
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSelectedPackageType(value as EPackageType);
  };

  const currencySymbol = getSymbolFromCurrency(
    packageData[selectedPackageType as EPackageType].currency,
  );

  const foodAndBeverage = getPackageExtrasDescriptions(
    packageData[selectedPackageType as EPackageType],
    'catering',
  );

  const equipment = getPackageExtrasDescriptions(
    packageData[selectedPackageType as EPackageType],
    'equipment',
  );

  const selectedPackagePrice =
    packageData[selectedPackageType as EPackageType]?.price;

  return (
    <>
      <RadioBtnGroup>
        {Object.values(packageData).map(({type, price}, index) =>
          type ? (
            <RadioBtnLabel key={index} checked={type === selectedPackageType}>
              <input
                type='radio'
                name='package'
                id={type}
                value={type}
                onChange={onChangeHandler}
              />
              <label htmlFor={type}>
                <StyledRadioBtnIcon
                  icon={EPackageIcons[type as EPackageType]}
                  size={20}
                  clickable
                  transparent
                />
              </label>
              <StyledRadioBtnText>
                {EPackageText[type as EPackageType]}
              </StyledRadioBtnText>
              <StyledRadioBtnPrice>{`${formatFloatWithOneNumber(
                price,
              )} ${currencySymbol} pp`}</StyledRadioBtnPrice>
            </RadioBtnLabel>
          ) : null,
        )}
      </RadioBtnGroup>

      {!!selectedPackageType && (
        <PackagesCard
          price={selectedPackagePrice}
          foodAndBeverage={foodAndBeverage}
          equipment={equipment}
          titleTextColor={infoColor}
          currencySymbol={currencySymbol}
        />
      )}
    </>
  );
};

export default PackagesOptions;
