import React from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';
import styled, {css} from 'styled-components';

import LeftSideItem from 'view/venue/components/LeftSideContainer/LeftSideItem';
import PreviewExtrasList from 'view/venue/components/PreviewExtrasList';

import {EEventType} from 'types/venue';
import {IOfferRequestDay} from 'types/offer';
import DateUtils, {DateFormats} from 'utils/dateUtils';
import {IExtrasOption} from 'types/dto/IExtras.type';
import {
  borderRadiusXLg,
  fontSizeLg,
  fontWeightBold,
  lineHeightXL,
  offsetXLg,
  NW2Gray200Color,
  offsetXXLg,
  offsetXXXXLg,
  defaultTextColor,
  offsetDef,
} from 'constants/styleVars';
import OfferExtrasList from 'view/venue/components/OfferExtrasList';
import {StyledAccordion} from '../Space/Space.styles';
import {TSearchVenuesDay} from 'types/search';

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${offsetDef};
  padding: ${offsetXLg};
  border: 1px solid ${NW2Gray200Color};
  border-radius: ${borderRadiusXLg};
`;

const Title = styled.div`
  font-size: ${fontSizeLg};
  font-weight: ${fontWeightBold};
  line-height: ${lineHeightXL};
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledLeftSideItem = styled(LeftSideItem)<{
  paddingTop?: string;
  paddingBottom?: string;
  isNoBorderTop?: boolean;
  isDesktop?: boolean;
  isDefaultItem?: boolean;
}>`
  padding-bottom: ${({paddingBottom}) => paddingBottom || offsetXXLg};
  padding-top: ${({paddingTop}) => paddingTop};

  ${({isNoBorderTop}) =>
    isNoBorderTop &&
    css`
      &:before {
        display: none;
      }
    `};

  & + & {
    padding-top: ${offsetXXLg};

    &:before {
      display: none;
    }
  }

  ${({isDefaultItem, isDesktop}) =>
    isDesktop &&
    isDefaultItem &&
    css`
      display: grid;
      grid-template-columns: 1fr 4fr;
    `}
`;

const accordionBorderRadius = 16;

interface IProps {
  title: 'PRE-ARRIVAL' | 'POST-EVENT';
  rooms: TSearchVenuesDay[] | IOfferRequestDay[];
  hasDates?: boolean;
  isOfferPreview?: boolean;
  hasDots?: boolean;
  isDefaultItem?: boolean;
  isDesktop?: boolean;
}
export function Bedrooms({
  rooms,
  title,
  hasDates,
  isOfferPreview,
  hasDots,
  isDefaultItem,
  isDesktop,
}: IProps) {
  const allExtrasOptions: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );

  return (
    <>
      {rooms.map((item, index) => {
        const bedroomDateRange = DateUtils.getBedroomDateRange(item.checkIn);

        const accommodationData = {
          title: (
            <div>
              <Title>Accommodation</Title>
              {hasDates && <div>{bedroomDateRange}</div>}
            </div>
          ),
          content: (
            <>
              {isOfferPreview ? (
                <OfferExtrasList bedrooms={item.bedrooms} />
              ) : (
                <PreviewExtrasList
                  extras={item.bedrooms}
                  extrasOptions={allExtrasOptions}
                  hasDots={hasDots}
                />
              )}
            </>
          ),
        };

        return (
          <StyledLeftSideItem
            key={EEventType.PRE_ARRIVAL + item.checkIn}
            paddingBottom={
              title === 'PRE-ARRIVAL'
                ? '0 !important'
                : index === rooms.length - 1
                ? offsetXXXXLg
                : undefined
            }
            paddingTop={title === 'POST-EVENT' ? '0 !important' : undefined}
            subtitleColor={defaultTextColor}
            isNoBorderTop={title === 'POST-EVENT'}
            item={{
              title,
              subtitle: DateUtils.getDateWithoutHours(
                item.checkIn,
                DateFormats['1 Jan 2023'],
              ),
              children: isOfferPreview ? (
                <StyledAccordion
                  items={[accommodationData]}
                  isDivider={false}
                  borderRadius={accordionBorderRadius}
                />
              ) : (
                <Item>
                  {accommodationData.title}
                  {accommodationData.content}
                </Item>
              ),
            }}
            isDefaultItem={isDefaultItem}
            isDesktop={isDesktop}
          />
        );
      })}
    </>
  );
}
