import styled, {css} from 'styled-components';
import {TextValue} from 'view/components/TextValue';
import {
  INPUT_BORDER_COLOR,
  INPUT_COLOR,
  INPUT_DISABLED_COLOR,
  INPUT_ERROR_COLOR,
  INPUT_FOCUS_BORDER_COLOR,
  INPUT_FONT_SIZE,
  INPUT_HEIGHT,
  INPUT_PADDING,
  INPUT_PLACEHOLDER_COLOR,
  InputStyles,
  LABEL_COLOR,
  LabelContainerStyles,
  LabelContainerStylesFocused,
} from 'view/components/NW2FormItem/NW2FormItem.styles';
import {
  fontSizeXSm,
  fontWeightBold,
  lighterBlueColor,
  lineHeightDefault,
  mdBp,
  lineHeightSm,
  offsetDef,
  offsetSm,
  offsetXLg,
  pagePadding,
  whiteColor,
} from 'constants/styleVars';
import {Group} from 'styles/basicStyledComponents/group';

const FocusedStyles = css`
  ${LabelContainerStylesFocused};
  color: ${LABEL_COLOR};

  &:before {
    z-index: -1;
  }

  &--error {
    color: ${INPUT_ERROR_COLOR};
  }
`;

const PaymentContainer = styled(Group)`
  line-height: 1;
  width: 100%;

  * + & {
    margin-top: ${offsetDef};
  }

  .adyen-checkout__payment-method {
    &--selected {
      background: ${lighterBlueColor};
    }
    &__header {
      cursor: pointer;
    }
    &__header__title {
      pointer-events: none;
    }
  }

  .adyen-checkout__input {
    &::placeholder {
      color: ${INPUT_COLOR};
      font-weight: 400;
    }
  }

  .adyen-checkout__input,
  .adyen-checkout__dropdown__button {
    ${InputStyles};
    height: ${INPUT_HEIGHT};
    padding: 0 ${INPUT_PADDING};
    border-color: ${INPUT_BORDER_COLOR};

    &:hover,
    &:focus,
    &--focus,
    &--active {
      box-shadow: none;
      border-color: ${INPUT_FOCUS_BORDER_COLOR};
      outline: 1px solid ${INPUT_FOCUS_BORDER_COLOR};
    }

    &--invalid,
    &--error {
      border-color: ${INPUT_ERROR_COLOR};
    }

    &--disabled {
      display: flex;
      align-items: center;
      background: ${whiteColor};
      border-color: ${INPUT_DISABLED_COLOR};
      color: ${INPUT_DISABLED_COLOR};
    }
  }

  .adyen-checkout__field {
    position: relative;
    margin-bottom: ${offsetXLg};

    &--valid,
    &--invalid {
      .adyen-checkout__label__text {
        ${FocusedStyles};
      }
    }

    &--valid {
      .adyen-checkout__label {
        ${FocusedStyles};
      }
    }
  }

  .adyen-checkout__card__holderName {
    margin-top: ${offsetXLg};
  }

  .adyen-checkout__field-wrapper > .adyen-checkout__field {
    &:first-child {
      margin-right: ${offsetSm};
    }
    &:nth-child(2) {
      margin-left: ${offsetSm};
    }
  }

  .adyen-checkout__field--storedCard {
    .adyen-checkout__label--disabled .adyen-checkout__label__text {
      ${FocusedStyles};
      color: ${INPUT_DISABLED_COLOR};
    }
  }

  .adyen-checkout__label {
    ${LabelContainerStyles};
    top: 23px;
    line-height: ${lineHeightDefault};
    font-size: ${INPUT_FONT_SIZE};
    color: ${INPUT_PLACEHOLDER_COLOR};
    height: auto;
    pointer-events: none;

    &--focused,
    &--filled {
      ${FocusedStyles};
    }

    &--disabled {
      color: ${INPUT_DISABLED_COLOR};
    }

    &__text {
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      padding: 0;

      &--error {
        color: ${INPUT_ERROR_COLOR};
      }
    }
  }

  .adyen-checkout__field--storedCard {
    margin-left: 0 !important;

    &:not(.adyen-checkout__field--securityCode) {
      display: none;
    }
  }

  .adyen-checkout__error-text {
    font-size: ${fontSizeXSm};
    line-height: ${lineHeightSm};
    font-weight: ${fontWeightBold};
    margin-top: 0;
  }

  > button {
    * + & {
      margin-top: ${pagePadding};
    }
  }
`;

const StyledTextValue = styled(TextValue)`
  * + & {
    margin-top: calc(${offsetDef} * 2);
  }
`;

const PaymentCardContainer = styled.div<{isVisible: boolean}>`
  display: ${({isVisible}) => (isVisible ? 'block' : 'none')};
  width: 100%;

  * + & {
    margin-top: ${offsetDef};
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: ${offsetSm};
  }
`;

const ButtonsContainer = styled(ButtonGroup)`
  margin-top: ${offsetXLg};
  justify-content: center;

  @media (min-width: ${mdBp}px) {
    justify-content: flex-end;
  }
`;

export {
  StyledTextValue,
  PaymentCardContainer,
  PaymentContainer,
  ButtonsContainer,
  ButtonGroup,
};
