import styled, {css} from 'styled-components';

import NW2Button from 'view/components/NW2Button/NW2Button';

import {datePickerDropdownWidth} from 'view/components/NW2Datepicker/NW2DatepickerCommon.styles';
import {
  borderRadiusLg,
  datepickerZIndex,
  fontSizeSm,
  mdBp,
  NW2Gray200Color,
  NW2Gray50Color,
  NW2Gray600Color,
  offsetDef,
  offsetXSm,
  whiteColor,
} from 'constants/styleVars';

const bpExtraSmall = '359px';
export const calendarWidth = '288px';

export const StyledColumnCalendar = styled.div<{isMobile?: boolean}>(
  ({isMobile}) =>
    css`
      display: flex;
      justify-content: right;
      height: 100%;
      padding-left: ${isMobile ? 0 : offsetXSm};
      border-top-left-radius: ${borderRadiusLg};
      border-bottom-left-radius: ${borderRadiusLg};
      background: ${whiteColor};
    `,
);

export const StyledTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${offsetDef} ${offsetDef} ${offsetDef} ${offsetDef};
  border-left: 1px solid ${NW2Gray200Color};
`;

export const StyledColumnTime = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow-y: auto;
  flex: 1;
  padding-right: ${offsetXSm};
`;

export const StyledDropdown = styled.div`
  display: flex;
  width: 596px;
  height: 350px;
  background-color: ${NW2Gray50Color};
  border-radius: ${borderRadiusLg};
  position: absolute;
  top: calc(100% + ${offsetXSm});
  left: 0;
  z-index: ${datepickerZIndex};
  overflow: hidden;
`;

export const StyledTimeRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 3fr 3fr;
  align-items: center;
  grid-column-gap: ${offsetDef};
  margin-bottom: ${offsetDef};

  @media (min-width: ${mdBp}px) {
    grid-template-columns: 1fr ${datePickerDropdownWidth} ${datePickerDropdownWidth};
  }
`;

export const StyledTimeRowDate = styled.div`
  font-size: ${fontSizeSm};
  white-space: nowrap;
  color: ${NW2Gray600Color};

  @media (max-width: ${bpExtraSmall}) {
    font-size: ${fontSizeSm};
  }
`;

export const StyledDatepickerActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${offsetDef};
  padding: ${offsetXSm} ${offsetDef} 0 0;
  background-color: ${NW2Gray50Color};
  position: sticky;
  bottom: 0;
`;

export const StyledTimeApplyBtn = styled(NW2Button)`
  min-width: 138px;
`;

export const StyledTimeCancelBtn = styled.button`
  border: 0;
  outline: 0;
  background: none;
  text-decoration: underline;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export const StyledDateInput = styled.input`
  letter-spacing: -0.9px;
`;
