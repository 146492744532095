import {IDay, IDayOfWeek, TDayTime} from 'types/dto/IDay.type';
import {addLeadingZeroToNumber} from './stringUtils';
import {Days} from 'constants/days';
import {EMPTY_ARRAY} from 'constants/app';
import {IDropdownListOption} from 'view/components/NW2FormItem/types';
import {isNull} from './helpers';

const getDayStr = (day: IDayOfWeek): IDayOfWeek => Days[day];

export const isDayActive = (workDays: IDay[], day: string): boolean => {
  const dayOfWeek = workDays.find((d) => d.dayOfWeek === day) || ({} as IDay);

  const {timeEndHour, timeEndMinutes, timeStartHour, timeStartMinutes} =
    dayOfWeek;

  const isAllTimeValid =
    !isNull(timeEndHour) &&
    !isNull(timeEndMinutes) &&
    !isNull(timeStartHour) &&
    !isNull(timeStartMinutes);

  return (dayOfWeek.active && isAllTimeValid) || false;
};

export const isFullDayAccess = (workDays: IDay[], day: string): boolean => {
  const dayOfWeek = workDays.find((d) => d.dayOfWeek === day);

  if (!dayOfWeek) return false;

  return (
    Number(dayOfWeek.timeEndHour) === 23 &&
    Number(dayOfWeek.timeEndMinutes) === 59
  );
};

const getStartTime = (d?: IDay): string => {
  if (!d) return '';
  return `${addLeadingZeroToNumber(d?.timeStartHour)}:${addLeadingZeroToNumber(
    d?.timeStartMinutes,
  )}`;
};

const getEndTime = (d?: IDay): string => {
  if (!d) return '';
  return `${addLeadingZeroToNumber(d?.timeEndHour)}:${addLeadingZeroToNumber(
    d?.timeEndMinutes,
  )}`;
};

const getStartEndTime = (day: IDayOfWeek, workDays: IDay[] = []): string => {
  const d = workDays.find((d) => d.dayOfWeek === getDayStr(day));
  if (!d) return '-';
  return `${getStartTime(d)} - ${getEndTime(d)}`;
};

export const getFromTimeDisabledHours =
  <T extends string>(day: T, workDays: IDay[] = EMPTY_ARRAY) =>
  () => {
    const d = workDays.find(
      (d) => d.dayOfWeek === getDayStr(day as IDayOfWeek),
    );
    const tillHour = (d && +(d as any).timeEndHour) || 25; // if there is no timeEndHour set - enable all time options
    return Array.from({length: 24})
      .map((val: any, index: number) => index)
      .filter((val: number) => val >= tillHour);
  };

export const getTillTimeDisabledHours =
  <T extends string>(day: T, workDays: IDay[] = EMPTY_ARRAY) =>
  () => {
    const d = workDays.find(
      (d) => d.dayOfWeek === getDayStr(day as IDayOfWeek),
    );
    const fromHour = (d && +(d as any).timeStartHour) || -1; // if there is no timeStartHour set - enable all time options
    return Array.from({length: 24})
      .map((val: any, index: number) => index)
      .filter((val: number) => val <= fromHour);
  };

export const getTimePickerValue = (
  day: IDayOfWeek,
  time: string,
  workDays: IDay[] = EMPTY_ARRAY,
) => {
  const d = workDays.find((d) => d.dayOfWeek === getDayStr(day));
  if (!d) return '00:00';
  if (time === 'timeStart')
    return `${addLeadingZeroToNumber(
      d?.timeStartHour,
    )}:${addLeadingZeroToNumber(d?.timeStartMinutes)}`;
  if (time === 'timeEnd')
    return `${addLeadingZeroToNumber(d?.timeEndHour)}:${addLeadingZeroToNumber(
      d?.timeEndMinutes,
    )}`;
};

export const createHoursOptions = (min = 0, max = 24, interval = 1) => {
  const options = [];

  for (let i = min; i < max; i += interval) {
    options.push({
      key: `${i}:00`,
      value: `${i}:00`,
      text: `${i}:00`,
    });
  }

  return options;
};

export const filterMinOptions = (
  value: TDayTime,
  options: IDropdownListOption[],
) =>
  options.filter((option) => {
    const hour = option.value.split(':')[0];

    return Number(hour) < Number(value);
  });

export const filterMaxOptions = (
  value: TDayTime,
  options: IDropdownListOption[],
) =>
  options.filter((option) => {
    const hour = option.value.split(':')[0];

    return Number(hour) > Number(value);
  });

export const getDayWorkingHours = ({
  day,
  active,
  fullDayAccess,
  workDays,
}: {
  day: IDayOfWeek;
  active?: boolean;
  fullDayAccess?: boolean;
  workDays: IDay[];
}) => {
  if (active) {
    if (fullDayAccess) {
      return '24 hour access';
    }

    return getStartEndTime(day, workDays);
  }

  return 'Closed';
};
