import React from 'react';

import DateUtils from 'utils/dateUtils';

import {useAppSelector} from 'store/hooks';
import {UnitWrapper, PlainText} from '../../Offer.styles';
import {useOfferRequestDetails} from '../../hooks/useOfferRequestDetails';

const OfferOptionDate = () => {
  const isDesktop = useAppSelector(({app}) => app.deviceType.isDesktop);

  const {offerDetails} = useOfferRequestDetails();

  const optionDate = offerDetails.optionDate;
  const venueZone = offerDetails.venue.location.timeZone;

  // Get an optionDate from OfferRequest.tsx already setted on 18 hours
  const optionDateText = DateUtils.getFormattedDayMonthTime({
    date: optionDate || '',
    venueZone,
  });

  return (
    <UnitWrapper>
      <PlainText>
        Once the venues will provide their offers you will have until{' '}
        {isDesktop && <br />}
        <b>{optionDateText}</b> to finalise your decision.
      </PlainText>
    </UnitWrapper>
  );
};

export default OfferOptionDate;
