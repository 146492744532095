import {useCallback, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import axios from 'axios';
import {FormInstance} from 'antd';
import {UploadFile} from 'antd/es/upload/interface';
import {notification} from 'antd';

import {
  setVenueFields,
  setPhotoToList,
  setCoverPhoto,
  setVenueAlreadyExists,
  clearVenueData,
} from 'store/venue/actions';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {EIgnoreInterceptorCodes} from 'utils/axiosInterceptors/types';
import useCountryList from 'hooks/useCountriesList';
import {getOfferRequestByToken} from 'store/offers/apiActions';
import {setSupplierOfferRequest} from 'store/offers/offersSlice';
import {checkOfferRequestRedirectStatus} from '../Offer/helpers';
import {useHMDRegistrationLink} from '../hooks/useHMDRegistrationLink';
import {useLoggedInUser} from 'hooks/useLoggedInUser';
import {useQuery} from 'hooks/useQuery';
import {ApiType, createBaseUrl} from 'infra/common/http.service';
import {Routes} from 'constants/routes';

interface IProps {
  form: FormInstance<any>;
  venueId: number;
}
export function useHmdMigration({form, venueId}: IProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const request = useAppSelector(({offers}) => offers.supplierOfferRequest);
  const hmdToken: string | null = new URLSearchParams(location.search).get(
    'token',
  );

  const {accommodationId, checkIn, checkOut, id, status} = request;

  const [isHmdLoading, setHmdLoading] = useState(!!hmdToken);
  const [isHmdChecked, setHmdChecked] = useState(false);
  const [hasHmdError, setHmdError] = useState(false);

  const {isOfferRequest} = useQuery(); //Used to identify that user came from offer email
  const {state} = useLocation();
  const {isLoggedInUser} = useLoggedInUser();

  const isRequestDeclineSuccess = state?.requestDeclineSuccess;

  const {getHMDLink, isHmdLinkChecked} = useHMDRegistrationLink({
    token: hmdToken as string,
    accommodationId,
    checkIn,
    checkOut,
    requestId: id,
  });

  const {countriesObject} = useCountryList();

  const processHmdData = useCallback(
    (data: any) => {
      if (!data) return;

      Object.entries(data).map(([key, value]: any) => {
        if (key && value) {
          if (key === 'documents') {
            // images
            if (value.length) {
              value.forEach((photo: UploadFile) => {
                dispatch(setPhotoToList(photo));
              });

              const coverPhoto = value.find(({isCover}: any) => isCover);
              const coverPhotoId = coverPhoto?.uid || value[0].uid;

              dispatch(setCoverPhoto(coverPhotoId as string));

              form.setFieldsValue({venuePhotos: value});
            }

            return;
          }

          dispatch(
            setVenueFields({
              path: key,
              value,
            }),
          );

          if (key === 'contact') {
            dispatch(
              setVenueFields({
                path: 'supplier.email',
                value: value.email,
              }),
            );
            dispatch(
              setVenueFields({
                path: 'supplier.phone',
                value: value.phone,
              }),
            );
          }

          if (key === 'legalContact' && value.countryCode) {
            const countryValue: string = countriesObject[value.countryCode];

            dispatch(
              setVenueFields({
                path: 'legalContact.country',
                value: countryValue,
              }),
            );
            dispatch(
              setVenueFields({
                path: 'legalContact.countryCode',
                value: value.countryCode,
              }),
            );
          }
        }
      });
    },
    [countriesObject, dispatch, form],
  );

  useEffect(() => {
    if (hmdToken && !isHmdChecked) {
      (async () => {
        try {
          const unInterceptedRequestInstance = axios.create();
          const {data} = await unInterceptedRequestInstance.get(
            createBaseUrl(
              ApiType.Inventory,
              `venues/by-token?token=${encodeURIComponent(hmdToken)}`,
            ),
          );

          processHmdData(data);

          if (isOfferRequest) {
            // getting request details if we are going from email link
            dispatch(getOfferRequestByToken(hmdToken));
          }
        } catch (error: any) {
          if (
            error?.response?.data?.reasonCode ===
            EIgnoreInterceptorCodes.BAD_REQUEST_INVALID_TOKEN
          ) {
            setHmdError(true);

            const notificationOptions = {
              message:
                'There seems to be a problem with the used registration link. Please contact HRS to get a new one.',
            };
            notification.error(notificationOptions);
          }

          if (
            error?.response?.data?.reasonCode ===
            EIgnoreInterceptorCodes.ACCOUNT_ALREADY_REGISTERED
          ) {
            // set venue already exists for handling it on the Login page
            const appendRequest = isOfferRequest
              ? `?token=${hmdToken}&isOfferRequest=true`
              : '';
            dispatch(setVenueAlreadyExists(true));

            if (isLoggedInUser) {
              navigate(`${Routes.bookingsListRooms}${appendRequest}`);
            } else {
              navigate(`${Routes.login}${appendRequest}`);
            }
          }
        } finally {
          setHmdLoading(false);
        }
      })();
      setHmdChecked(true);
    }
  }, [
    dispatch,
    hmdToken,
    isHmdChecked,
    isLoggedInUser,
    isOfferRequest,
    navigate,
    processHmdData,
    venueId,
  ]);

  useEffect(() => {
    if (!hmdToken && venueId) {
      // clear venue prefilled data if we enter to default venue create after hmdVenue create
      dispatch(clearVenueData());
      dispatch(setSupplierOfferRequest({} as any));
    }
  }, [dispatch, hmdToken, venueId]);

  const redirectStatus = checkOfferRequestRedirectStatus(status);

  useEffect(() => {
    //redirect to request RequestDeclineSuccessPage when request already declined/expired/cancelled
    if (hmdToken && redirectStatus && !isRequestDeclineSuccess) {
      getHMDLink();
      navigate(
        `${Routes.requestDeclineSuccess}?isOfferRequest=true&hmdToken=${hmdToken}`,
      );
    }
  }, [getHMDLink, hmdToken, navigate, redirectStatus, isRequestDeclineSuccess]);

  return {
    hmdToken,
    isHmdLoading:
      !isRequestDeclineSuccess &&
      hmdToken &&
      ((isOfferRequest && !id) || // checking only for requests from email
        !isHmdChecked ||
        isHmdLoading ||
        (redirectStatus && !isHmdLinkChecked)),
    hasHmdError,
  };
}
