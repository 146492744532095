import React from 'react';

import {pluralize} from 'utils/helpers';
import {formatFloatWithOneNumber} from 'utils/venueUtils';

import {
  ContentWrapper,
  ContentSubtitle,
  Text,
} from '../../NMMSpacesDetails.styles';

interface IProps {
  priceHour: number;
  priceHalfDay: number;
  priceDay: number;
  minimumPaidDuration: number;
  currencySymbol: string | undefined;
}

const PriceViewMode = ({
  priceHour,
  priceHalfDay,
  priceDay,
  minimumPaidDuration,
  currencySymbol,
}: IProps) => {
  return (
    <>
      <ContentSubtitle>price range</ContentSubtitle>
      <ContentWrapper>
        <Text>
          {formatFloatWithOneNumber(priceHour)} {currencySymbol} p/h
        </Text>
        {priceHalfDay && (
          <Text>
            {formatFloatWithOneNumber(priceHalfDay)} {currencySymbol} p/hd
          </Text>
        )}
        <Text>
          {formatFloatWithOneNumber(priceDay)} {currencySymbol} p/d
        </Text>
      </ContentWrapper>

      {minimumPaidDuration && (
        <>
          <ContentSubtitle>minimum spend</ContentSubtitle>
          <Text>{pluralize('hour', minimumPaidDuration)}</Text>
        </>
      )}
    </>
  );
};

export default PriceViewMode;
