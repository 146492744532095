import React from 'react';

import Icon from 'view/components/Icon';

import {IDropdownListOption} from 'view/components/NW2FormItem/types';
import {Dropdown, DropdownItem} from './DropdownList.styles';

type TProps = {
  focused: boolean;
  inputValue: string | string[];
  optionsList: IDropdownListOption[];
  onBlurHandler: () => void;
  onItemChange: (value: string) => void;
};

export function DropdownList({
  focused,
  inputValue,
  optionsList,
  onBlurHandler,
  onItemChange,
}: TProps) {
  const onDropdownClick = (value: string) => () => {
    onItemChange(value);
    onBlurHandler();
  };

  if (!focused || !optionsList?.length) return null;

  return (
    <Dropdown>
      {optionsList.map(({key, text, value}: IDropdownListOption) => {
        const isActive = inputValue.includes(value);
        return (
          <DropdownItem
            key={key}
            active={isActive}
            onClick={onDropdownClick(value)}
          >
            {isActive && <Icon icon='NW2_TICK' transparent size={14} />}
            {text}
          </DropdownItem>
        );
      })}
    </Dropdown>
  );
}
