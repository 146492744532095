import React, {useCallback, useState} from 'react';
import {Dropdown, MenuProps} from 'antd';

import Icon from 'view/components/Icon';
import NW2ModalDrawer from 'view/components/NW2ModalDrawer';

import {IBooking, IBookingOrderResponse} from 'types/dto/IBooking.types';
import {modalMediumWidth} from 'constants/styleVars';
import {CustomerDetailsContent} from 'view/supplier/Bookings/BookingsList/components/RespondModalsContent';
import {venuesContainer} from 'app';
import {patchOrderShowStatus} from 'store/bookingsSupplier/actions';
import {useAppDispatch} from 'store/hooks';
import {EOrderShowStatus} from 'types/bookingOrders';
import {setWorkdeskOrderReadStatus} from 'store/bookings/actions';

interface IProps {
  text: string;
  record: IBooking;
  index: number;
}

const RowMenuColumn = ({record, index}: IProps) => {
  const dispatch = useAppDispatch();
  const [isContactModalVisible, setIsContactModalVisible] = useState(false);

  const {orderNumber, orderId, showStatus} = record;

  const [orderDetails, setOrderDetails] = useState<IBookingOrderResponse>(
    {} as IBookingOrderResponse,
  );

  const showContactDetails = useCallback(() => {
    venuesContainer.getBooking({
      payload: orderNumber,
      onSuccess: (data: IBookingOrderResponse) => {
        setOrderDetails(data);
        setIsContactModalVisible(true);
        if (showStatus === EOrderShowStatus.UNREAD)
          dispatch(setWorkdeskOrderReadStatus(orderId));
      },
    });
    if (showStatus === EOrderShowStatus.UNREAD)
      dispatch(patchOrderShowStatus(orderId));
  }, [orderNumber, orderId, dispatch, showStatus]);

  const hideContactDetails = () => setIsContactModalVisible(false);

  const onClick: MenuProps['onClick'] = ({key}) => {
    if (key === '1') {
      showContactDetails();
    }
  };
  const items = [{label: 'Booking details', key: '1'}];

  return (
    <>
      <Dropdown key={index} menu={{items, onClick}} trigger={['click']}>
        <Icon icon='MORE_VERTICAL' primary />
      </Dropdown>
      <NW2ModalDrawer
        isMobile={false}
        isShowed={isContactModalVisible}
        modalWidth={modalMediumWidth}
        onClose={hideContactDetails}
        header={<>Booking details</>}
        body={<CustomerDetailsContent record={orderDetails} />}
        headerPaddingBottom='0'
      />
    </>
  );
};

export default RowMenuColumn;
