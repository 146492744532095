import React from 'react';
import styled from 'styled-components';

import MeetingRequestSectionTitle from '../MeetingRequestSectionTitle';
import NW2AccordionItem from 'view/components/NW2Accordion/components/NW2AccordionItem';

import {AccommodationContent} from './components/AccommodationContent';

import {TMeetingRoomItem, TSelectedOptions} from 'types/search';
import {TBedroomsCatering, THandleSetBedroomsCatering} from './types';

const StyledNW2AccordionItem = styled(NW2AccordionItem)`
  padding: 0;
`;

type TProps = {
  accommodationData: TSelectedOptions | null;
  meetingRooms: TMeetingRoomItem[];
  addAccommodation: (formData?: Record<string, any>) => void;
  removeAccommodation: () => void;
  bedroomsCatering: TBedroomsCatering;
  handleSetBedroomsCatering: THandleSetBedroomsCatering;
  removeBedroomsCatering: (date: string) => void;
  date: string;
  setEditedAccommodationDaysIndex?: React.Dispatch<
    React.SetStateAction<(number | undefined)[]>
  >;
  dayIndex: number;
};

export function AddAccommodationRequest({
  accommodationData,
  meetingRooms,
  addAccommodation,
  removeAccommodation,
  bedroomsCatering,
  handleSetBedroomsCatering,
  removeBedroomsCatering,
  date,
  setEditedAccommodationDaysIndex,
  dayIndex,
}: TProps) {
  const isRooms = !!meetingRooms.length;
  const isAccommodationEnabled = !!accommodationData && isRooms;

  const toggleAccommodation = () => {
    if (isAccommodationEnabled) {
      removeAccommodation();
      removeBedroomsCatering(date);
    } else {
      addAccommodation();
    }
  };

  return (
    <StyledNW2AccordionItem
      expandable={isAccommodationEnabled}
      initiallyExpanded
      isDivider={false}
      title={
        <MeetingRequestSectionTitle
          label='Accommodation'
          isSwitchDisabled={!isRooms}
          isSwitchActive={isAccommodationEnabled}
          onClickSectionHandler={toggleAccommodation}
        />
      }
      content={
        isAccommodationEnabled ? (
          <AccommodationContent
            accommodationData={accommodationData}
            meetingRooms={meetingRooms}
            addAccommodation={addAccommodation}
            bedroomsCatering={bedroomsCatering}
            handleSetBedroomsCatering={handleSetBedroomsCatering}
            date={date}
            setEditedAccommodationDaysIndex={setEditedAccommodationDaysIndex}
            dayIndex={dayIndex}
          />
        ) : null
      }
    />
  );
}
