import React, {ChangeEvent, useEffect} from 'react';
import {Form, Input, FormInstance} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {createGlobalStyle} from 'styled-components';
import _get from 'lodash/get';

import TextValue from 'view/components/TextValue';
import BusinessAddress from './components/BusinessAddress';
import BusinessDetails from './components/BusinessDetails';

import {SubPageTitle} from 'view/components/Typography';
import {defaultAntdFormLayout, EMPTY_OBJECT} from 'constants/app';
import {
  PATH_TO_REDUCER_VENUE_DATA,
  phoneNumberPlaceholderForCreateVenue,
} from 'constants/venue';
import {
  emailFieldRules,
  emailFieldRulesWithUniqEmailValidator,
  legalPhoneFieldRules,
  nameFieldRules,
} from 'utils/antdFormFieldRules';
import {trimPhoneNumber} from 'utils/stringUtils';

import {setVenueFields} from 'store/venue/actions';
import {
  EPersonOfInterestType,
  ISupplier,
  IVenueLegalContact,
} from 'types/venue';
import {getLegalPhoneNumber} from 'view/venue/CreateVenue/pages/ContactDetails/helpers';
import {
  StyledLabelStar,
  CustomInputNumber,
  StyledItem,
} from './ContactDetailsForm.styles';
import {StyledNameWrapper} from './components/EntityRoleFields';

const {Item} = Form;

type TProps = {
  form: FormInstance;
  disableEmail?: boolean;
};

export type TFullNameFieldMappings = {
  [key: string]: string;
};
const GlobalContactDetailsFormStyle = createGlobalStyle`
  .monthPicker,
  .datePicker {
    .ant-picker {
      &-header,
      &-footer,
      &-body > table > thead {
        display: none;
      }
    }
  }

  .ant-form-item .ant-select,
  .ant-form-item .ant-input-affix-wrapper {
    width: 92%;
  }
`;

// const ENTITY_LIST = ['Business', 'Individual'];

const ContactDetailsForm = ({form, disableEmail}: TProps) => {
  const dispatch = useDispatch();

  const supplier: ISupplier =
    useSelector((state) =>
      _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.supplier`),
    ) || EMPTY_OBJECT;

  const legalContact: IVenueLegalContact =
    useSelector((state) =>
      _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.legalContact`),
    ) || EMPTY_OBJECT;

  const legalPhoneValue = getLegalPhoneNumber(legalContact.legalPhone);

  useEffect(() => {
    const isShouldShow = !!supplier && !!legalContact;

    if (!isShouldShow) return;

    const initialValues = {
      firstName: supplier.firstName,
      lastName: supplier.lastName,
      contactPhone: supplier.phone,
      email: supplier.email,
      companyName: legalContact.companyName,
      legalEmail: legalContact.email,
      legalPhone: legalPhoneValue,
      taxNumber: legalContact.taxNumber,
      individualTaxNumber: legalContact.taxNumber,
      regNumber: legalContact.registrationNumber,
      country: legalContact.country,
      region: legalContact.region,
      countryCode: legalContact.countryCode,
      legalCity: legalContact.city,
      legalStreetName: legalContact.streetName,
      legalBuildingNumber: legalContact.buildingNumber,
      legalPostalCode: legalContact.postalCode,
      floorNumber: legalContact.floorNumber,
      signatoryFirstName: legalContact.signatory?.firstName || '',
      signatoryLastName: legalContact.signatory?.lastName || '',
      controllerFirstName: legalContact.controller?.firstName || '',
      controllerLastName: legalContact.controller?.lastName || '',
      ownerFirstName: legalContact.owner?.firstName || '',
      ownerLastName: legalContact.owner?.lastName || '',
    };

    form.setFieldsValue(initialValues);
  });

  const handleFormFields =
    (path: string, role: string | null = null) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      event.persist();

      try {
        dispatch(setVenueFields({path, value: event.target.value}));

        const fullNameFieldMappings: TFullNameFieldMappings = {
          [`legalContact.${role}.firstName`]: `legalContact.${role}FirstName`,
          [`legalContact.${role}.lastName`]: `legalContact.${role}LastName`,
        };

        const fullNameFieldPath = fullNameFieldMappings[path];
        if (fullNameFieldPath && role !== 'signatory') {
          dispatch(
            setVenueFields({
              path: fullNameFieldPath,
              value: event.target.value,
            }),
          );
        }
        if (role) {
          dispatch(
            setVenueFields({
              path: `legalContact.${role}.type`,
              value:
                EPersonOfInterestType[
                  role.toUpperCase() as keyof typeof EPersonOfInterestType
                ],
            }),
          );
        }
      } catch (error) {}
    };

  // this function is meant to keep leading zeros and +

  const handlePhoneFields =
    (path: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const normalizedValue = trimPhoneNumber(event.target.value);
      dispatch(setVenueFields({path, value: normalizedValue}));
    };

  // this function is meant to keep leading zeros
  const handleCustomNumericFormFields =
    (path: string) => (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(setVenueFields({path, value: event.target.value}));
    };

  const labelWithStar = (labelName: string) => {
    return (
      <span>
        <StyledLabelStar>*</StyledLabelStar>
        {labelName}
      </span>
    );
  };

  // TODO: do not remove in case we will need adyen in future
  // const legalEntityType: ELegalEntityType = useSelector((state) =>
  //   _get(state, 'venue.legalEntityType'),
  // );

  // const resetForm = () => {
  //   dispatch(setVenueFields({path: 'legalContact', value: null}));
  //   dispatch(setVenueFields({path: 'supplier', value: null}));
  //   form.resetFields();
  // };

  // const handleEntityType = (value: string) => {
  //   dispatch(setVenueEntityType(value.toUpperCase()));
  //   resetForm();
  // };

  return (
    <>
      {/*<SubPageTitle>Legal entity type</SubPageTitle>*/}

      <Form {...defaultAntdFormLayout} form={form}>
        {/*<Item label={labelWithStar('Legal entity')}>*/}
        {/*  <StyledWrapper>*/}
        {/*    <Select*/}
        {/*      defaultValue={LegalEntityTypeCaption[legalEntityType]}*/}
        {/*      placeholder='Select entity'*/}
        {/*      onChange={handleEntityType}*/}
        {/*    >*/}
        {/*      {ENTITY_LIST.map((entity) => {*/}
        {/*        return (*/}
        {/*          <Select.Option key={entity} value={entity}>*/}
        {/*            {entity}*/}
        {/*          </Select.Option>*/}
        {/*        );*/}
        {/*      })}*/}
        {/*    </Select>*/}
        {/*    <TextPopover textIndex={0} />*/}
        {/*  </StyledWrapper>*/}
        {/*</Item>*/}

        {/*<CompanyDetailsInformCard />*/}
        {/*<Divider />*/}
        <SubPageTitle>Platform log-in</SubPageTitle>
        <Item
          name='email'
          label='Email address'
          rules={
            !disableEmail
              ? emailFieldRulesWithUniqEmailValidator
              : emailFieldRules
          }
        >
          <>
            <Input
              allowClear
              name='email'
              placeholder='Email address'
              onChange={handleFormFields('supplier.email')}
              disabled={disableEmail}
              value={supplier.email}
            />
            <TextValue secondary>
              This will be your login email address for NewWork account
            </TextValue>
          </>
        </Item>
        <Item label={labelWithStar('Your name')} style={{marginBottom: '0'}}>
          <StyledNameWrapper>
            <StyledItem name='firstName' rules={nameFieldRules}>
              <Input
                name='firstName'
                placeholder='First name'
                onChange={handleFormFields('supplier.firstName')}
                value={supplier.firstName}
              />
            </StyledItem>

            <StyledItem name='lastName' rules={nameFieldRules}>
              <Input
                name='lastName'
                placeholder='Last name'
                onChange={handleFormFields('supplier.lastName')}
                value={supplier.lastName}
              />
            </StyledItem>
          </StyledNameWrapper>
        </Item>
        <Item
          name='contactPhone'
          label='Phone number'
          rules={legalPhoneFieldRules}
        >
          <CustomInputNumber
            name='contactPhone'
            placeholder={phoneNumberPlaceholderForCreateVenue}
            onChange={handlePhoneFields('supplier.phone')}
            value={supplier.phone}
            allowClear
          />
        </Item>

        <BusinessDetails
          handleCustomNumericFormFields={handleCustomNumericFormFields}
          legalContact={legalContact}
        />

        <SubPageTitle>Business address</SubPageTitle>
        <BusinessAddress
          handleFormFields={handleFormFields}
          handleCustomNumericFormFields={handleCustomNumericFormFields}
          legalPhoneValue={legalPhoneValue}
        />

        {/*<SubPageTitle>Operating executives</SubPageTitle>*/}
        {/*{*/}
        {/*  <OperatingExecutives*/}
        {/*    isBusinessEntityType={isBusinessEntityType}*/}
        {/*    labelWithStar={labelWithStar}*/}
        {/*    handleFormFields={handleFormFields}*/}
        {/*    handleCustomNumericFormFields={handleCustomNumericFormFields}*/}
        {/*    form={form}*/}
        {/*  />*/}
        {/*}*/}
        <GlobalContactDetailsFormStyle />
      </Form>
    </>
  );
};

export default ContactDetailsForm;
