import React from 'react';
import {useAppSelector} from 'store/hooks';
import {useLocation} from 'react-router-dom';

import PendingRequestNotification from './components/PendingRequestNotification';
import PopupNotification from './components/PopupNotification';
import VenuePayoutStatusNotification from './components/VenuePayoutStatusNotification';

import {ETopNotificationId, ITopNotification} from 'types/app';
import {EMPTY_OBJECT} from 'constants/app';
import {Routes} from 'constants/routes';

const NOTIFICATION_COMPONENTS = {
  [ETopNotificationId.PENDING_REQUEST]: PendingRequestNotification,
  [ETopNotificationId.POPUP_NOTIFICATION]: PopupNotification,
  [ETopNotificationId.VENUE_PAYOUT_STATUS]: VenuePayoutStatusNotification,
};

const TopNotification = () => {
  const topNotifications = useAppSelector(({app}) => app.topNotifications);

  const location = useLocation();
  const isBookingList = location.pathname === Routes.bookingsListRooms;
  const isAgentsRoutes = location.pathname.startsWith('/agent/');

  if (!topNotifications.length || isBookingList || isAgentsRoutes) return null;

  return (
    <>
      {topNotifications.map((notification: ITopNotification, index: number) => {
        const {id, props = EMPTY_OBJECT} = notification;

        const Component = NOTIFICATION_COMPONENTS[id];
        return Component ? (
          <Component key={`${id}-${index}`} {...props} />
        ) : null;
      })}
    </>
  );
};

export default TopNotification;
