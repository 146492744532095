import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';

import Icon from 'view/components/Icon';
import NW2Button from 'view/components/NW2Button';
import StyledAntdDrawer from 'view/common/StyledAntdDrawer';
import {useClickOutside} from 'hooks/useClickOutside';

import {useAppSelector} from 'store/hooks';
import {ERoomSchema, ERoomSchemaNames, iconsObject} from 'types/venue';
import {
  INITIAL_SEATING_STYLE,
  setSearchCriteriaSeatingStyle,
} from 'store/search/searchSlice';

import {
  borderRadiusLg,
  mdBp,
  NW2Gray500Color,
  NW2Gray800Color,
  NW2Primary,
  offsetDef,
  offsetXLg,
  whiteColor,
} from 'styles/configs';

import {
  StyledActions,
  StyledDrawerContentHeader,
  StyledDrawerContentFooter,
  StyledInputContainer,
} from '../../../../../NW2Datepicker/NW2DatePicker/NW2MultiDatePickerMobile.styles';

const Wrapper = styled.div`
  position: relative;
`;

const List = styled.div`
  display: flex;
  background-color: ${whiteColor};
  padding: ${offsetDef};
  border-radius: ${borderRadiusLg};
  overflow: hidden;
  top: calc(100% + ${offsetXLg});
  right: -${offsetXLg};
  position: absolute;
  z-index: 100;
`;

const DrawerContent = styled.div`
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(4, minmax(74px, 1fr));
  grid-gap: ${offsetDef};
  padding: ${offsetXLg} ${offsetDef};
`;

const Item = styled.div<{checked: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 3px;
  border: 1px solid
    ${({checked}) => (checked ? NW2Gray800Color : NW2Gray500Color)};
  color: ${({checked}) => (checked ? NW2Gray800Color : NW2Gray500Color)};
  background-color: ${({checked}) => (checked ? NW2Primary : 'none')};

  @media (min-width: ${mdBp}px) {
    &:not(&:last-child) {
      margin-right: ${offsetDef};
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

const StyledButton = styled(NW2Button)`
  position: absolute;
  top: 50%;
  right: ${offsetXLg};
  transform: translateY(-50%);

  @media (min-width: ${mdBp}px) {
    right: auto;
    left: ${offsetXLg};
  }
`;

type TProps = {
  showButton: boolean;
  onClickButton: () => void;
  onClickInput: () => void;
};

const SeatingStyleInput = ({
  showButton,
  onClickButton,
  onClickInput,
}: TProps) => {
  const [showSeatingStylePopUp, setShowSeatingStylePopUp] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [seatingStyleLocal, setSeatingStyleLocal] = useState(
    INITIAL_SEATING_STYLE,
  );

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  const seatingStyleFromStore = useAppSelector(
    ({search}) => search.searchCriteria.seatingStyle,
  ) as ERoomSchemaNames;

  const dispatch = useDispatch();
  const wrapperRef = useRef(null);

  const onClickInputHandler = () => {
    if (isMobile) {
      setShowDrawer(!showDrawer);
    } else {
      onClickInput();
      setShowSeatingStylePopUp(!showSeatingStylePopUp);
    }
  };

  useClickOutside(wrapperRef, showSeatingStylePopUp, () => {
    setShowSeatingStylePopUp(false);
    dispatch(setSearchCriteriaSeatingStyle(seatingStyleLocal));
  });

  const nextHandler = () => {
    dispatch(setSearchCriteriaSeatingStyle(seatingStyleLocal));
    setShowDrawer(!showDrawer);
  };

  const clearHandler = () => {
    if (seatingStyleFromStore !== seatingStyleLocal) {
      setSeatingStyleLocal(seatingStyleFromStore);
    }
  };

  const onClose = () => {
    clearHandler();
    setShowDrawer(!showDrawer);
  };

  useEffect(() => {
    setSeatingStyleLocal(seatingStyleFromStore);
  }, [seatingStyleFromStore]);

  const items = Object.values(ERoomSchemaNames).map((roomSchema) => (
    <Item
      key={roomSchema}
      checked={seatingStyleLocal === roomSchema}
      onClick={() => setSeatingStyleLocal(roomSchema)}
    >
      <Icon size={34} clickable icon={iconsObject[roomSchema]} />
    </Item>
  ));

  const bodyStyle = {
    padding: 0,
  };

  if (showButton) {
    return (
      <StyledButton
        minimized
        size='small'
        buttonType='tertiary'
        icon={<Icon size={18} clickable icon='NW2_EDIT' />}
        onClick={onClickButton}
      />
    );
  }

  return (
    <>
      <Wrapper ref={wrapperRef}>
        <input
          type='text'
          id='search_seating_style'
          onClick={onClickInputHandler}
          value={ERoomSchema[seatingStyleLocal]}
          readOnly
        />
        {showSeatingStylePopUp && <List>{items}</List>}
      </Wrapper>
      {isMobile && (
        <StyledAntdDrawer
          onClose={onClose}
          open={showDrawer}
          title={null}
          height='auto'
          bodyStyle={bodyStyle}
        >
          <StyledDrawerContentHeader showDivider={true}>
            <StyledInputContainer highlighted>
              <input
                type='text'
                id='search_seating_style_mobile'
                value={ERoomSchema[seatingStyleLocal]}
                readOnly
              />
              <label>
                <span>seating style</span>
              </label>
            </StyledInputContainer>
          </StyledDrawerContentHeader>

          <DrawerContent>{items}</DrawerContent>

          <StyledDrawerContentFooter showDivider={true}>
            <StyledActions>
              <NW2Button
                type='button'
                size='medium'
                fullWidth={true}
                onClick={clearHandler}
              >
                clear
              </NW2Button>

              <NW2Button
                buttonType='primary'
                size='medium'
                fullWidth={true}
                onClick={nextHandler}
              >
                next
              </NW2Button>
            </StyledActions>
          </StyledDrawerContentFooter>
        </StyledAntdDrawer>
      )}
    </>
  );
};

export default SeatingStyleInput;
