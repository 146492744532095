import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useAppSelector} from 'store/hooks';

import NW2Button from 'view/components/NW2Button';
import NW2DateSection from './NW2DateSection';
import NW2TimeSection from './NW2TimeSection';

import {
  useMultiDatePicker,
  NW2MultiDatePickerMobile,
  formatDateForPicker,
} from 'view/components/NW2Datepicker';

import {EMPTY_FUNC} from 'constants/app';
import {useClickOutside} from 'hooks/useClickOutside';

import {
  StyledColumnTime,
  StyledDropdown,
  StyledDatepickerActions,
  StyledTimeWrapper,
  StyledDateInput,
} from './NW2SearchDatePicker.styles';
import {StyledTimeTitle} from 'view/components/NW2Datepicker/NW2TimePicker/NW2TimePicker.styles';

interface IProps {
  inputId: string;
  onClickHandler?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  roomTypeString?: string;
  hasTimeColumn?: boolean;
  monthsShown?: number;
}

export const NW2SearchDatePicker = ({
  inputId,
  onClickHandler = EMPTY_FUNC,
  onFocus = EMPTY_FUNC,
  onBlur = EMPTY_FUNC,
  roomTypeString,
}: IProps) => {
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const searchFocusedInput = useAppSelector(({app}) => app.searchFocusedInput);

  const [isOpened, setOpened] = useState(false);

  const onToggleDatePicker = useCallback(() => {
    if (!isOpened) {
      onFocus();
    } else {
      onBlur();
    }

    onClickHandler();
    setOpened(!isOpened);
  }, [isOpened, onFocus, onBlur, onClickHandler]);

  const {
    startDate,
    endDate,
    timeData,
    onDateChange,
    onTimeStartChange,
    onTimeEndChange,
    onChangeTimeComplete,
    onReset,
  } = useMultiDatePicker();

  const nextHandler = useCallback(() => {
    onChangeTimeComplete(onToggleDatePicker);
  }, [onChangeTimeComplete, onToggleDatePicker]);

  const containerRef = useRef(null);
  useClickOutside(containerRef, isOpened, () => {
    if (!isMobile) {
      onToggleDatePicker();
    }
  });

  const inputValue = useMemo(() => {
    const start = timeData[0]?.timeStart;
    const end = timeData[timeData.length - 1]?.timeEnd;

    return formatDateForPicker(start, end);
  }, [timeData]);

  useEffect(() => {
    if (searchFocusedInput === inputId && !isOpened) onToggleDatePicker();
  }, [searchFocusedInput, inputId, onToggleDatePicker, isOpened]);

  return (
    <div ref={containerRef}>
      <StyledDateInput
        type='text'
        value={inputValue}
        id={inputId}
        onClick={onToggleDatePicker}
        readOnly
        data-testid='datepicker open picker'
      />

      {isMobile ? (
        <NW2MultiDatePickerMobile
          startDate={startDate}
          endDate={endDate}
          timeData={timeData}
          isOpened={isOpened}
          onDateChange={onDateChange}
          onTimeStartChange={onTimeStartChange}
          onTimeEndChange={onTimeEndChange}
          onToggleDatePicker={onToggleDatePicker}
          onNextClick={nextHandler}
          onClearClick={onReset}
          roomTypeString={roomTypeString}
        />
      ) : isOpened ? (
        <StyledDropdown>
          <NW2DateSection
            startDate={startDate}
            endDate={endDate}
            onChange={onDateChange}
          />

          {/*{isColTimeShowed && (*/}
          <StyledTimeWrapper>
            <StyledTimeTitle>{`When do you plan to ${roomTypeString}?`}</StyledTimeTitle>

            <StyledColumnTime>
              <div>
                <NW2TimeSection
                  timeData={timeData}
                  onTimeStartChange={onTimeStartChange}
                  onTimeEndChange={onTimeEndChange}
                />
              </div>

              <StyledDatepickerActions>
                <NW2Button
                  type='button'
                  onClick={onReset}
                  data-testid='datepicker clear'
                >
                  clear
                </NW2Button>

                <NW2Button
                  buttonType='primary'
                  fullWidth={false}
                  onClick={nextHandler}
                  data-testid='datepicker next'
                >
                  apply
                </NW2Button>
              </StyledDatepickerActions>
            </StyledColumnTime>
          </StyledTimeWrapper>
          {/*)}*/}
        </StyledDropdown>
      ) : null}
    </div>
  );
};
