import styled, {css} from 'styled-components';
import {Layout, Menu} from 'antd';

import NW2Button from 'view/components/NW2Button';
import Icon from 'view/components/Icon';
import HeaderUserMenu from 'view/common/Header/components/HeaderUserMenu';

import {
  primaryColor,
  textColor,
  secondaryColor,
  offsetXLg,
  borderColor,
  cardBoxShadow,
  headerHeight,
  headerZIndex,
  fontWeightBold,
  fontSizeLg,
  offsetLg,
  headerHeightMd,
  mdBp,
  headerHeightSm,
  offsetNone,
  whiteColor,
  offsetXSm,
} from 'constants/styleVars';
import {NW2Container} from 'view/mainLanding/MainLanding.styles';

const DIVIDER_HEIGHT = '32px';
const BORDER_RADIUS = '99px';

const {Header} = Layout;

const MENU_ITEM_MARGIN = offsetXLg;

export const StyledHeader = styled(Header)<{datatype: string}>`
  display: flex;
  align-items: center;
  height: ${headerHeight};
  width: 100%;
  padding: 0;
  background: ${({theme}) => theme.navigation.background};
  box-shadow: ${cardBoxShadow};
  position: relative; // box-shadow is not seen without this!
  z-index: ${headerZIndex}; // box-shadow is not seen without this!

  ${({datatype}) =>
    (datatype === 'GuestLayout' || datatype === 'CustomerLayout') &&
    `
      @media (min-width: ${mdBp}px) {
        height: ${headerHeightMd}
      }
    `}

  ${({datatype}) =>
    [
      'NW2SupplierLayout',
      'NW2PropertyManagerLayout',
      'AdminLayout',
      'Logo',
    ].includes(datatype) &&
    css`
      height: ${headerHeightSm};

      &.ant-layout-header {
        line-height: 0;
      }
    `}
`;

export const NW2HeaderContainer = styled(NW2Container)<{datatype?: string}>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .sub-logo {
    display: none;
  }

  ${({datatype}) =>
    datatype === 'centered' &&
    `& > *:nth-child(1) {text-align: left;}
     & > * {width: 100%}
    `}

  ${({datatype}) =>
    datatype === 'default' &&
    `justify-content: center; & ul[role='menu'] {width: 100%}`}

  ${({datatype}) =>
    datatype === 'GuestLayout' &&
    `
    justify-content: space-between; 
    & ul[role='menu'] {
      width: 100%;
    }
    `}

  ${({datatype}) =>
    datatype === 'CustomerLayout' &&
    `
    justify-content: space-between; 
    & ul[role='menu'] {
      flex-grow: 1;
    }
    `}

  ${({datatype, theme}) =>
    (datatype === 'GuestLayout' || datatype === 'CustomerLayout') &&
    `
    .sub-logo {
      display: flex;
      align-items: center;
      font-size: ${fontSizeLg};
      line-height: 1;
      font-weight: ${fontWeightBold};
      color: ${theme.navigation.color};
      
      &:before {
        content: '|';
        display: inline-block;
        margin: 0 ${offsetLg};
      }
    }
    `}

    ${({datatype}) =>
    datatype === 'NW2SupplierLayout' &&
    css`
      height: ${headerHeightSm};
    `}

    ${({datatype}) =>
    datatype === 'Logo' &&
    css`
      justify-content: center;
    `}
`;

export const HeaderLogoIcon = styled(Icon)`
  max-width: 85px;

  & .logo-img-1 {
    fill: ${({theme}) => theme.logo.Img1};
  }
  & .logo-img-2 {
    fill: ${({theme}) => theme.logo.Img2};
  }
  & .logo-img-3 {
    fill: ${({theme}) => theme.logo.Img3};
  }

  & .logo-text {
    fill: ${({theme}) => theme.logo.Text};
  }
`;

export const StyledMenu = styled(Menu)<{hasBorder?: boolean}>`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  border-bottom: none;
  background: ${({theme}) => theme.navigation.background};
  color: ${({theme}) => theme.navigation.color};
  ${({hasBorder}) => hasBorder && `border-right: 1px solid ${borderColor}`};
`;

export const MenuItem = styled(Menu.Item)<{
  selected?: boolean;
  secondary?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.3;
  color: ${({selected, secondary}) =>
    selected ? '#fff' : secondary ? secondaryColor : textColor};
  padding: 0 6px;
  text-align: center;
  text-decoration: none;

  & a.active {
    color: ${primaryColor};
  }

  @media all and (min-width: 480px) {
    padding: 0 16px;
  }
`;

export const AvatarContainer = styled.div`
  text-align: right;
`;

export const LocationSelectContainer = styled.div`
  padding: ${offsetNone} ${offsetXLg};
  flex-grow: 1;
  display: inline-flex;
  white-space: nowrap;
`;

export const MenuIcon = styled(Icon)`
  margin-right: ${offsetXSm};
  color: ${({theme}) => theme.navigation.color || whiteColor};

  & svg {
    & path.styled-stroke,
    & circle.styled-stroke {
      stroke: ${({theme}) => theme.navigation.color || whiteColor};
    }

    & path.styled-fill,
    & circle.styled-fill {
      fill: ${({theme}) => theme.navigation.color || whiteColor};
    }
  }
`;

export const Divider = styled.div`
  width: 1px;
  height: ${DIVIDER_HEIGHT};
  margin: ${offsetNone} ${offsetLg};
  background: ${({theme}) => theme.navigation.color};
  border-radius: ${BORDER_RADIUS};
`;

/* HeaderLoginRegisterBlock */
export const LoginRegisterBlockWrapper = styled.div`
  display: none;

  @media (min-width: ${mdBp}px) {
    display: flex;
    align-items: center;
  }
`;

export const LoginRegisterBlockButton = styled(NW2Button)`
  margin-left: ${MENU_ITEM_MARGIN};
`;

export const LoginRegisterBlockUserMenu = styled(HeaderUserMenu)`
  margin-left: ${MENU_ITEM_MARGIN};
`;
/* End of HeaderLoginRegisterBlock */

/* Guest and Customer menu */
export const GeneralMenu = styled.div`
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  vertical-align: middle;
  cursor: pointer;
  border-right: 2px solid ${whiteColor};

  display: none;

  @media (min-width: ${mdBp}px) {
    display: flex;
  }
`;

export const GeneralMenuItem = styled.div`
  display: inline-flex;
  align-items: center;
  margin: 0 ${MENU_ITEM_MARGIN};
  vertical-align: middle;
  cursor: pointer;
`;
/* End of Guest and Customer menu */
