import styled from 'styled-components';

import Icon from 'view/components/Icon/Icon';
import NW2Button from 'view/components/NW2Button/NW2Button';

import {SubBlockTitle} from 'view/components/Typography';
import {CardNameContainer} from 'view/components/AdyenPayment/components/CardNameContainer';

import {
  mdBp,
  xLgBp,
  offsetXSm,
  offsetSm,
  offsetDef,
  offsetXLg,
  offsetXXXLg,
  fontSizeXXSm,
  fontSizeMd,
  fontWeightExtraBold,
  NW2Gray200Color,
  NW2Gray600Color,
  offsetXXLg,
  offset80,
} from 'constants/styleVars';

export const Title = styled(SubBlockTitle)`
  font-weight: ${fontWeightExtraBold};
  text-transform: uppercase;
  margin-bottom: ${offsetDef};
`;

export const Text = styled.p`
  font-size: ${fontSizeMd};
  margin-bottom: ${offsetXXXLg};
`;

export const Wrapper = styled.div`
  width: 100%;
  text-align: end;

  @media (min-width: ${xLgBp}px) {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr;
    grid-gap: 20px;
    text-align: start;
    justify-items: end;
  }
`;

export const Container = styled.div`
  padding: ${offsetXLg};
  border: 1px solid ${NW2Gray200Color};
  margin-bottom: ${offsetXXXLg};
`;

export const StyledParagraph = styled.p`
  display: inline-block;
  color: ${NW2Gray600Color};
  text-transform: uppercase;
  text-align: start;
  font-size: ${fontSizeXXSm};
  font-weight: bold;
`;

export const StyledRadioGroupWrapper = styled.div`
  width: 100%;
  border-top: 1px solid ${NW2Gray200Color};
  padding-top: ${offsetXLg};
`;

export const Section = styled.div`
  margin-bottom: ${offsetXLg};

  @media (min-width: ${mdBp}px) {
    display: flex;
  }

  @media (min-width: ${xLgBp}px) {
    display: grid;
    align-items: center;
    grid-template-columns: 4fr 1fr 1fr 1fr;
    grid-gap: 20px;
  }
`;
export const CardSection = styled.div`
  margin-bottom: ${offsetXLg};
  display: flex;
  justify-content: space-between;

  @media (min-width: ${xLgBp}px) {
    display: flex;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  @media (min-width: ${xLgBp}px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const ExtraRow = styled.div`
  margin-left: ${offsetXSm};
  width: 66vw;
  @media (min-width: ${xLgBp}px) {
    width: 33vw;
  }
`;

export const ExtraText = styled.span`
  color: ${NW2Gray600Color};
  font-size: ${fontSizeMd};
  font-weight: bold;
`;

export const ExtraParagraph = styled.p`
  font-size: ${fontSizeMd};
  margin-bottom: 0;
  width: 12vw;
`;

export const ActionSection = styled.div`
  text-align: end;
  margin-top: ${offset80};

  @media (min-width: ${mdBp}px) and (max-width: ${xLgBp}px) {
    display: flex;
    margin-top: ${offsetSm};
    text-align: center;
  }

  @media (min-width: ${xLgBp}px) {
    text-align: start;
    margin-top: 0;
  }
`;

export const StyledIcon = styled(Icon)`
  margin-right: ${offsetXLg};
`;

export const StyledSpan = styled.span`
  font-weight: bold;
`;

export const StyledNW2Button = styled(NW2Button)`
  margin-right: ${offsetSm};
`;

export const StyledCardNameContainer = styled(CardNameContainer)`
  margin-top: ${offsetXXLg};
`;
