import React from 'react';
import styled from 'styled-components';
import DateUtils, {DateFormats} from 'utils/dateUtils';

import LeftSideItem from 'view/venue/components/LeftSideContainer/LeftSideItem';
import Space from './Space/Space';

import {ICustomerBookedUnits} from 'types/dto/IBooking.types';
import {IUnit} from 'types/dto/IUnit.types';

import {offsetSm, offsetXXLg, offsetXXSm} from 'styles/configs/offset';
import {fontSizeDefault, fontWeightNormal} from 'styles/configs/typography';

const SubtitleWrapper = styled.div`
  margin-top: ${offsetSm};
  font-weight: ${fontWeightNormal};
  font-size: ${fontSizeDefault};
  text-transform: initial;

  & div:not(& div:last-of-type) {
    margin-bottom: ${offsetXXSm};
  }
`;

const StyledLeftSideItem = styled(LeftSideItem)`
  padding-bottom: ${offsetXXLg};

  & + & {
    padding-top: 0;

    &:before {
      display: none;
    }
  }
`;

type TProps = {
  bookedUnits: ICustomerBookedUnits[];
  venueUnits: IUnit[];
  isHidden?: boolean;
  isPresetEditModeOn: boolean;
  setIsPresetEditModeOn: (value: boolean) => void;
};

const {getHoursAndMinutes, getDateWithoutHours} = DateUtils;

const SpaceList = ({
  isHidden,
  bookedUnits,
  venueUnits,
  isPresetEditModeOn,
  setIsPresetEditModeOn,
}: TProps) => {
  if (isHidden) return null;

  return (
    <>
      {bookedUnits.map(
        (
          {
            checkInDate,
            checkOutDate,
            unitBookings,
            foodAndBeverage,
            bedrooms,
            eventType,
          },
          index,
        ) => {
          const dateText = getDateWithoutHours(
            checkInDate,
            DateFormats['1 Jan 2023'],
          );

          const timeRangeText = `(${getHoursAndMinutes(
            checkInDate,
          )} - ${getHoursAndMinutes(checkOutDate)})`;

          return (
            <StyledLeftSideItem
              key={index}
              item={{
                title: (
                  <>
                    <span>{`DAY ${index + 1}`}</span>
                    <SubtitleWrapper>
                      <div>{dateText}</div>
                      <div>{timeRangeText}</div>
                    </SubtitleWrapper>
                  </>
                ),
                children: (
                  <Space
                    isMultiDay
                    dayIndex={index}
                    venueUnits={venueUnits}
                    bookedUnits={unitBookings}
                    bookedFoodAndBeverage={foodAndBeverage}
                    setIsPresetEditModeOn={setIsPresetEditModeOn}
                    isPresetEditModeOn={isPresetEditModeOn}
                    bookedBedrooms={bedrooms}
                    eventType={eventType}
                  />
                ),
              }}
            />
          );
        },
      )}
    </>
  );
};

export default SpaceList;
