import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import useAccommodationType from 'hooks/useAccommodationType';

import {EPaymentAccountStatus} from 'types/venue';
import {EAccommodationType} from 'types/dto/IPublicVenue';

const useNumberOfLiveSpaces = (numberOfSpaces: number) => {
  const payAccStatus = useSelector((state) =>
    _get(state, 'venue.venue.payAccStatus'),
  );
  const accommodationType = useAccommodationType();

  const isExternalVenue = accommodationType === EAccommodationType.VENUE;

  if (isExternalVenue) {
    if (payAccStatus === EPaymentAccountStatus.ACTIVE) {
      return numberOfSpaces;
    }

    return 0;
  }

  return numberOfSpaces;
};

export default useNumberOfLiveSpaces;
