import styled from 'styled-components';

import Icon from '../../Icon/Icon';

import {
  fontWeightBold,
  mdBp,
  NW2Gray600Color,
  NW2Primary,
  offsetDef,
  offsetXXLg,
  whiteColor,
} from 'constants/styleVars';

export const Container = styled.div`
  display: flex;
  border-bottom: 2px solid ${NW2Gray600Color};
  margin-bottom: ${offsetDef};
`;

export const Tab = styled.div`
  display: flex;
  margin-right: ${offsetXXLg};

  &:last-of-type {
    margin-right: 0;
  }

  input {
    display: none;
  }

  label {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${whiteColor};
    text-transform: uppercase;
    font-weight: ${fontWeightBold};
    padding-bottom: ${offsetDef};
    transition: color 0.15s, border-bottom 0.15s;
  }

  label:after {
    content: '';
    height: 5px;
    width: 100%;
    position: absolute;
    bottom: -2px;
    left: 0;
    background-color: transparent;
    transition: background-color 0.15s;
  }

  input:checked + label {
    color: ${NW2Primary};
    transition: color 0.15s, border-bottom 0.15s;
  }

  input:checked + label:after {
    background-color: ${NW2Primary};
    transition: background-color 0.15s;
  }

  input + label svg path.styled-stroke,
  input + label svg circle.styled-stroke {
    transition: stroke 0.15s;
    stroke: ${whiteColor};
  }

  input:checked + label svg path.styled-stroke,
  input:checked + label svg circle.styled-stroke {
    transition: stroke 0.15s;
    stroke: ${NW2Primary};
  }
`;

export const StyledIcon = styled(Icon)`
  display: none;
  margin-right: ${offsetDef};

  @media (min-width: ${mdBp}px) {
    display: block;
  }
`;
