import styled, {css} from 'styled-components';

import {
  borderRadiusLg,
  fontSizeXXXLg,
  fontWeightBold,
  fontWeightExtraBold,
  lineHeightLg,
  lineHeightXXLg,
  NW2Gray200Color,
  NW2Gray50Color,
  NW2Gray600Color,
  NW2Gray900Color,
  offsetDef,
  offsetXLg,
  offsetXSm,
  offsetXXSm,
  whiteColor,
  fontSizeMd,
  fontSizeLg,
  lineHeightXL,
  fontSizeSm,
  fontSizeXSm,
  fontSizeXLg,
  offsetXXLg,
  mdBp,
  xLgBp,
  xxLgBp,
  NW2Blue50Color,
  offsetSm,
} from 'constants/styleVars';

const REQUESTS_BLOCK_HEIGHT = '360px';

export const Container = styled.div<{
  isTablet?: boolean;
  customerSide?: boolean;
}>`
  display: grid;
  gap: ${offsetXLg};
  padding: ${offsetXLg};
  background: ${NW2Gray50Color};
  border: 1px solid ${NW2Gray200Color};
  border-radius: ${borderRadiusLg};
  margin-bottom: ${offsetXLg};

  ${({isTablet, customerSide}) =>
    isTablet &&
    css`
      @media (min-width: ${mdBp}px) {
        grid-template-columns: ${customerSide ? '120px' : '160px'} 1fr;
      }
    `}

  @media (min-width: ${xLgBp}px) {
    grid-template-columns: 160px 1fr;
  }

  ${({customerSide}) =>
    !customerSide &&
    css`
      @media (min-width: ${xxLgBp}px) {
        grid-template-columns: 314px 1fr;
      }
    `}
`;

export const Title = styled.div`
  font-weight: ${fontWeightExtraBold};
  font-size: ${fontSizeXXXLg};
  line-height: ${lineHeightXXLg};
  text-transform: uppercase;
  letter-spacing: 0.02em;
`;

export const RequestWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${offsetXLg};
  max-height: ${REQUESTS_BLOCK_HEIGHT};
  overflow-y: auto;
  overflow-x: hidden;
`;

export const BlockWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${whiteColor};
  border: 1px solid ${NW2Gray200Color};
  border-radius: ${borderRadiusLg};
  padding: ${offsetDef};
  transition: background-color 0.15s;

  &:hover {
    background-color: ${NW2Blue50Color};
    cursor: pointer;
  }
`;

export const CellContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${offsetXXSm};
`;

export const CellTitle = styled.div<{noWrap?: boolean}>`
  font-weight: ${fontWeightBold};
  color: ${NW2Gray600Color};
  ${({noWrap}) =>
    noWrap &&
    css`
      white-space: nowrap;
    `}
`;

export const CellData = styled.div<{
  noWrap?: boolean;
  smallFont?: boolean;
  isLongData?: boolean;
}>`
  display: flex;
  gap: ${offsetXSm};
  align-items: center;
  font-size: ${({smallFont}) =>
    smallFont ? `${fontSizeSm}` : `${fontSizeMd}`};
  line-height: ${lineHeightLg};
  color: ${NW2Gray900Color};

  ${({isLongData}) =>
    isLongData &&
    css`
      overflow-wrap: anywhere;
    `}

  ${({noWrap}) =>
    noWrap &&
    css`
      white-space: nowrap;
    `}
`;

export const CellsWrapper = styled.div<{columns?: string}>`
  display: grid;
  flex-grow: 1;
  grid-template-columns: ${({columns}) => columns || '124px 230px 155px 1fr'};
  pointer-events: none;

  > div {
    padding-right: ${offsetXLg};

    &:not(&:first-of-type) {
      padding-left: ${offsetXLg};
    }

    &:not(&:last-of-type) {
      border-right: 1px solid ${NW2Gray200Color};
    }
  }
`;

export const ModalConfirmWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${offsetXLg};
`;

export const ModalTitle = styled.div`
  font-size: ${fontSizeLg};
  line-height: ${lineHeightXL};
  font-weight: ${fontWeightBold};
`;

export const ModalText = styled.div`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  margin-bottom: ${offsetXXLg};
`;

export const InputBox = styled.div<{isMobile?: boolean}>`
  ${({isMobile}) =>
    !isMobile &&
    css`
      display: grid;
      max-width: 268px;
    `}
  grid-template-columns: 268px 1fr;
  gap: ${offsetXSm};

  input {
    font-size: ${fontSizeSm};
  }
`;

export const NameInputBox = styled(InputBox)`
  margin-top: ${offsetDef};
`;

export const TextAreaBox = styled.div<{isMobile?: boolean}>`
  padding-top: ${offsetDef};
  ${({isMobile}) =>
    !isMobile &&
    css`
      margin-bottom: ${offsetXXLg};
    `}

  > div:first-of-type {
    margin-bottom: ${offsetDef};
  }

  textarea {
    font-size: ${fontSizeSm};
  }
`;

export const Required = styled.span<{marginTop?: number}>`
  font-size: ${fontSizeXSm};
  line-height: ${fontSizeXLg};
  color: ${NW2Gray600Color};
  margin-top: ${({marginTop}) => `${marginTop}px`};
`;

export const ActionCardBox = styled.div`
  margin-bottom: ${offsetXXLg};
`;

export const ButtonContainer = styled.div`
  padding: ${offsetSm} 0;
`;
