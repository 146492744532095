import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';

import TruncateText from 'view/components/TruncateText';
import PreviewExtrasList from 'view/venue/components/PreviewExtrasList';

import {SpaceImage} from './components/SpaceImage';

import {setSpaceDetailsId} from 'store/venues/actions';
import {EResourcesCode, IExtrasOption} from 'types/dto/IExtras.type';
import {pluralize} from 'utils/helpers';
import DateUtils from 'utils/dateUtils';
import {ERoomSchemaNames, ERoomSchema} from 'types/venue';
import {
  EAccommodationType,
  IExtraResponse,
  IUnitResponse,
} from 'types/dto/IPublicVenue';
import {useAppSelector} from 'store/hooks';
import {
  UnitTitle,
  UnitBox,
  UnitInfo,
  UnitNameLink,
  UnitName,
  ExtrasTitle,
  Container,
  Wrapper,
  Header,
  TitleContainer,
  Title,
} from './Space.styles';
import {capitalizeText} from 'utils/stringUtils';
import {useGroupRequest} from 'view/venue/hooks/useGroupRequest';
import {IPreviewExtra} from 'store/venues/types';
import {moveToEndWhere} from 'view/venue/helpers/spaces';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';
import {CODE_TO_EXTRA_NAME} from 'constants/extras';

type TProps = {
  units: IUnitResponse[];
  foodAndBeverage: IExtraResponse[];
  bedrooms: IExtraResponse[];
  isAlternative?: boolean;
  hasDots?: boolean;
};

export const OfferRequestSpace = ({
  units,
  foodAndBeverage,
  bedrooms,
  isAlternative,
  hasDots,
}: TProps) => {
  const dispatch = useDispatch();
  const {isMultiRequest, isSingleRegisteredVenue} = useGroupRequest();

  const participants = useAppSelector(
    ({search}) => search.searchCriteria.meetingRoomCapacity,
  );
  const extrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );

  const {venueDetails} = useVenueDetailsData();

  const accommodationType = venueDetails.accommodationType;
  const isOfferDetails = venueDetails.isOffer;

  const {getHoursAndMinutes} = DateUtils;

  const isPublicVenue = accommodationType === EAccommodationType.VENUE;

  const baseAccordionData = units.map((unit, index, array) => {
    const {
      name,
      documents,
      unitId = 0,
      checkIn,
      checkOut,
      unitCapacities,
      requestedCapacity,
      requestedSetupStyle,
      extras,
    } = unit;

    const equipmentExtras = moveToEndWhere(
      // move wi-fi at the end
      [...extras],
      'code',
      EResourcesCode.WIFI,
    );

    const timeText = `${getHoursAndMinutes(checkIn)} - ${getHoursAndMinutes(
      checkOut,
    )}`;

    const seatingPlan =
      requestedSetupStyle || (unit as IUnitResponse).requestedSetupStyle; //|| venuesChosenSeatingPlan;

    const currentSeatingPlanItem = unitCapacities?.find(
      (plan) => plan.setupStyle === ERoomSchemaNames[seatingPlan],
    );

    const roomSetupText = seatingPlan ? ERoomSchema[seatingPlan] : '';

    const maxCapacityText = currentSeatingPlanItem
      ? `(${currentSeatingPlanItem.capacity} max)`
      : '';

    const participantsText = `${pluralize(
      'people',

      requestedCapacity || participants,
    )} ${maxCapacityText && !isMultiRequest ? maxCapacityText : ''}`;

    const openSpaceDetails = () => {
      if (isOfferDetails) return;
      dispatch(setSpaceDetailsId(unitId));
    };

    const roomCounter = array.length > 1 ? (index + 1).toString() : '';

    const unitName = isAlternative ? (
      `${ERoomSchema[seatingPlan]} Meeting Room`
    ) : isSingleRegisteredVenue ? (
      <TruncateText text={name} hideToggler />
    ) : (
      'Meeting Room'
    );

    const unitTitle = `Meeting room ${roomCounter}`;

    return {
      title: (
        <div key={`head_${unitId}`}>
          <UnitTitle>{capitalizeText(unitTitle)}</UnitTitle>
        </div>
      ),
      content: (
        <>
          <UnitBox>
            <SpaceImage
              images={documents}
              isPlaceholder={!isSingleRegisteredVenue}
            />

            <div>
              {isOfferDetails || isMultiRequest ? (
                <UnitName>{unitName}</UnitName>
              ) : (
                <UnitNameLink onClick={openSpaceDetails} hasMargin>
                  {unitName}
                </UnitNameLink>
              )}

              <UnitInfo>
                <span>Time:&nbsp;</span>
                {timeText}
              </UnitInfo>

              {!!roomSetupText && (
                <UnitInfo>
                  <span>Room setup:&nbsp;</span>
                  {roomSetupText}
                </UnitInfo>
              )}
              <UnitInfo>
                <span>Participants:&nbsp;</span>
                {participantsText}
              </UnitInfo>
            </div>
          </UnitBox>

          {!!equipmentExtras.length && (
            <div>
              {equipmentExtras.map(
                ({name, code, accommodationExtraId}, idx) => (
                  <span key={accommodationExtraId}>
                    {!!idx && <> · </>}
                    {name || CODE_TO_EXTRA_NAME[code]}
                  </span>
                ),
              )}
            </div>
          )}
        </>
      ),
      expandable: isPublicVenue && !!equipmentExtras.length,
    };
  });

  const foodBeverageData = {
    title: <ExtrasTitle>Food & Beverage</ExtrasTitle>,
    content: (
      <PreviewExtrasList
        extras={foodAndBeverage}
        extrasOptions={extrasOption}
        hasDots={hasDots}
      />
    ),
  };

  const sortedBedrooms = _orderBy(bedrooms, ['code'], ['desc']);

  const accommodationData = {
    title: <ExtrasTitle>Accommodation</ExtrasTitle>,
    content: (
      <PreviewExtrasList
        extras={sortedBedrooms as unknown as IPreviewExtra[]}
        extrasOptions={extrasOption}
        hasDots={hasDots}
      />
    ),
  };

  const items = foodAndBeverage.length
    ? [...baseAccordionData, foodBeverageData]
    : baseAccordionData;

  const itemsToRender = sortedBedrooms.length
    ? [...items, accommodationData]
    : items;

  return (
    <Wrapper>
      {itemsToRender.map(({title, content}, idx) => (
        <Container key={'space-preview-item' + idx}>
          <Header>
            <TitleContainer>
              <Title>{title}</Title>
            </TitleContainer>
          </Header>

          {content}
        </Container>
      ))}
    </Wrapper>
  );
};
