import React from 'react';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import axios from 'axios';
import {message} from 'antd';

import Icon from 'view/components/Icon';

import {IconContainer} from '../NW2GoogleMapSearchInput.styles';
import {NW2Gray900Color, offsetDef, offsetLg} from 'constants/styleVars';
import {TNW2GoogleMapSearchInputData} from '../types';
import {searchPredictionLoading} from 'store/search/searchSlice';
import {useAppSelector} from 'store/hooks';
import {NW2SearchLoader} from '../../NW2SearchLoader/NW2SearchLoader';

type TProps = {
  onSearch: (data: TNW2GoogleMapSearchInputData) => void;
};

const Container = styled.div`
  height: 46px;
  display: flex;
  align-items: center;
  padding: ${offsetLg} 0;

  &:hover,
  &:hover * {
    cursor: pointer;
  }
`;

const Text = styled.div`
  white-space: nowrap;
  font-size: 13px;
  line-height: 22px;
  color: ${NW2Gray900Color};
  margin-left: ${offsetDef};
`;

const CURRENT_LOCATION_ID = 'CURRENT_LOCATION_ID';

const CurrentLocation = ({onSearch}: TProps) => {
  const dispatch = useDispatch();

  const searchPrediction = useAppSelector(
    ({search}) => search.searchPredictionLoading,
  );

  const successHandler = async (position: GeolocationPosition) => {
    try {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&sensor=false&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`;
      const response = await axios.get(url);
      const address = response?.data?.results[0].formatted_address;

      dispatch(searchPredictionLoading(''));

      return onSearch({
        latitude: `${latitude}`,
        longitude: `${longitude}`,
        address: address ?? '',
      });
    } catch (error) {
      message.error({
        type: 'error',
        content: 'Failed to fetch current location',
        key: 'errorMessage',
      });
    }
  };

  const errorHandler = () => {
    message.error({
      type: 'error',
      content: 'Check if geolocation permissions are enabled on this device',
      key: 'errorMessage',
    });

    dispatch(searchPredictionLoading(''));
  };

  const useCurrentLocation = () => {
    const location = window?.navigator?.geolocation;
    if (location) {
      dispatch(searchPredictionLoading(CURRENT_LOCATION_ID));

      location.getCurrentPosition(successHandler, errorHandler);
    }
  };

  return (
    <Container onClick={useCurrentLocation}>
      <IconContainer>
        <Icon icon='LOCATION' />
      </IconContainer>
      <Text>Use current location</Text>
      {searchPrediction === CURRENT_LOCATION_ID && <NW2SearchLoader />}
    </Container>
  );
};

export default CurrentLocation;
