import React, {memo} from 'react';
import styled from 'styled-components';

import {UnitCardRow} from './UnitCardRow';
import {IPreviewExtraOption, TExtraPriceField} from '../../../types';
import {FREE} from 'constants/venue';
import {EResourcesCode} from 'types/dto/IExtras.type';
import {formatFloatWithOneNumber} from 'utils/venueUtils';
import {offsetXXLg, offsetXXSm} from 'constants/styleVars';
import {UnitCardExtraPrice} from './UnitCard.styles';
import {CODE_TO_EXTRA_NAME} from 'constants/extras';

const Wrapper = styled.div`
  margin-bottom: ${offsetXXLg};
`;

const UnitCardExtra = styled.div`
  display: grid;
  grid-template-columns: minmax(20px, min-content) 12px 1fr 120px;
  grid-column-gap: ${offsetXXSm};
  align-items: start;
  margin-bottom: ${offsetXXSm};
`;

interface IProps {
  currency: string;
  extrasList: IPreviewExtraOption[];
  extrasPrices?: TExtraPriceField;
  isBedrooms?: boolean;
  isInitialised?: boolean;
  prices?: TExtraPriceField;
}
export const UnitCardExtrasList = memo(
  ({
    currency,
    extrasList,
    extrasPrices,
    isBedrooms,
    isInitialised,
    prices,
  }: IProps) => (
    <Wrapper>
      {extrasList.map(({id, name, quantity, code}) => {
        const price = prices
          ? prices[code]
          : extrasPrices?.[isBedrooms ? code : name];
        const isPriceShowed = isInitialised && !!price;
        const isWiFi = code === EResourcesCode.WIFI;

        return (
          <UnitCardExtra key={`preview-unit-extra-${id}`}>
            <span>{isWiFi ? '' : quantity}</span>
            <span>{isWiFi ? ' ' : 'x'}</span>

            <span>{isBedrooms ? CODE_TO_EXTRA_NAME[code] : name}</span>
            <UnitCardRow
              data={
                isPriceShowed && (
                  <UnitCardExtraPrice>
                    {price === FREE
                      ? FREE
                      : `${formatFloatWithOneNumber(
                          Number(price) * quantity,
                        )} ${currency}`}{' '}
                  </UnitCardExtraPrice>
                )
              }
            />
          </UnitCardExtra>
        );
      })}
    </Wrapper>
  ),
);
