import React from 'react';

import {EDeskSpaceName, EPaymentAccountStatus} from 'types/venue';
import {
  blackColor,
  NW2ErrorLight500Color,
  NW2Primary,
  whiteColor,
} from 'constants/styleVars';
import {ERoomType} from 'types/dto/ERoomType.type';
import {ItemWithIcon, PrefixIcon} from './NMMSpacesList.styles';
import {TStatusContent} from './types';
import {
  EVenueProfilePages,
  VenueProfileHash,
} from 'view/venue/NW2VenueProfile/types';
import {Routes} from 'constants/routes';

export const getStatusConfig = (
  status: EPaymentAccountStatus | null,
): TStatusContent => {
  switch (status) {
    case EPaymentAccountStatus.ACTIVE:
      return {
        bgColor: NW2Primary,
        color: blackColor,
        size: 'small',
        icon: 'NW2_INFO',
        statusText: 'live',
        text: `This space is live, which means it will appear in bookers search results and can be booked. `,
      };
    default:
      return {
        bgColor: NW2ErrorLight500Color,
        color: whiteColor,
        size: 'small',
        icon: 'TRIANGLE_WARN',
        statusText: 'inactive',
        text: (
          <>
            <p>
              This space is inactive due to your venue’s payment method not
              being completed.
            </p>
            <p>
              Please click the Register link below, in order to be forwarded to
              Adyen to complete the payment process.
            </p>
          </>
        ),
        buttonText: 'register',
        buttonHandler: () =>
          window.location.replace(
            Routes.publicVenue + VenueProfileHash[EVenueProfilePages.PAYMENT],
          ),
      };
  }
};

export const getTypeConfig = (
  roomType: ERoomType,
  type: ERoomType,
  isExternalVenue: boolean,
  isRfp?: boolean,
) => {
  switch (roomType) {
    case ERoomType.WORK_SPACE:
      return (
        <ItemWithIcon data-testid='space-card-rfp'>
          {type === ERoomType.SHARED_SPACE && (
            <>
              <PrefixIcon icon='NMM_CO_WORKING' />
              {EDeskSpaceName.SHARED_SPACE}
            </>
          )}
          {type === ERoomType.OPEN_SPACE && (
            <>
              <PrefixIcon icon='NMM_CO_WORKING' />
              {EDeskSpaceName.OPEN_SPACE}
            </>
          )}
        </ItemWithIcon>
      );
    default:
      return (
        isExternalVenue && (
          <ItemWithIcon data-testid='space-card-rfp'>
            {isRfp ? (
              <>
                <PrefixIcon icon='CLOCK' />
                Request-to-book
              </>
            ) : (
              <>
                <PrefixIcon icon='LIGHTENING' />
                Instant book
              </>
            )}
          </ItemWithIcon>
        )
      );
  }
};
