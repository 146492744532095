import React, {ReactNode, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import NW2InfoCard from 'view/common/NW2InfoCard';

import {EInfoCardType} from 'view/common/NW2InfoCard/NW2InfoCard';
import {EPaymentAccountStatus} from 'types/venue';
import {Routes} from 'constants/routes';
import {setSpaceDetailsId} from 'store/venues/actions';
import {
  InfoCardText,
  InfoCardWrapper,
  RegisterLink,
} from '../NMMSpacesDetails.styles';
import {ESpaceDetailsContentSections} from '../types';
import {
  EVenueProfilePages,
  VenueProfileHash,
} from 'view/venue/NW2VenueProfile/types';

enum EInfoCardStatuses {
  LIVE = 'Live',
  INACTIVE = 'Inactive',
  READY_TO_GO_LIVE = 'Ready to go live',
  WORK_IN_PROGRESS = 'Work in progress',
}

interface IInfoCardsContent {
  title: string;
  type: EInfoCardType;
  icon: string;
  text: string | ReactNode;
}

type TProps = {
  payAccStatus: EPaymentAccountStatus;
  isWip?: boolean;
  errorSections: Set<ESpaceDetailsContentSections>;
};

const StatusAndPreviewContent = ({
  payAccStatus,
  isWip,
  errorSections,
}: TProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showStatus = useMemo(() => {
    if (isWip) {
      if (errorSections.size) {
        return EInfoCardStatuses.WORK_IN_PROGRESS;
      } else {
        return EInfoCardStatuses.READY_TO_GO_LIVE;
      }
    }

    switch (payAccStatus) {
      case EPaymentAccountStatus.ACTIVE:
        return EInfoCardStatuses.LIVE;

      default:
        return EInfoCardStatuses.INACTIVE;
    }
  }, [errorSections.size, isWip, payAccStatus]);

  const infoCards: {
    [key in EInfoCardStatuses]: IInfoCardsContent;
  } = {
    [EInfoCardStatuses.LIVE]: {
      title: EInfoCardStatuses.LIVE,
      type: EInfoCardType.live,
      icon: 'NW2_TICK',
      text: 'This space is now live and will appear in the organisers’ search.',
    },
    [EInfoCardStatuses.INACTIVE]: {
      title: EInfoCardStatuses.INACTIVE,
      type: EInfoCardType.error,
      icon: 'TRIANGLE_WARN',
      text: (
        <div>
          <InfoCardText>
            This space is inactive due to your venue’s payment method not being
            completed.
          </InfoCardText>
          <InfoCardText>
            Please click the Register link below, in order to be forwarded to
            Adyen to complete the payment process.
          </InfoCardText>
          <RegisterLink
            onClick={() => {
              dispatch(setSpaceDetailsId(null));
              navigate(
                Routes.publicVenue +
                  VenueProfileHash[EVenueProfilePages.PAYMENT],
              );
            }}
          >
            Register
          </RegisterLink>
        </div>
      ),
    },
    [EInfoCardStatuses.READY_TO_GO_LIVE]: {
      title: EInfoCardStatuses.READY_TO_GO_LIVE,
      type: EInfoCardType.live,
      icon: 'NW2_TICK',
      text: 'You can now save your changes. All of the mandatory input fields had been provided.',
    },
    [EInfoCardStatuses.WORK_IN_PROGRESS]: {
      title: 'Work in progress',
      type: EInfoCardType.warn,
      icon: 'TRIANGLE_WARN',
      text: `There is still some important information missing which is required before this space can go live.`,
    },
  };

  const allContent = infoCards[showStatus];
  const {title, type, icon, text} = allContent;

  return (
    <InfoCardWrapper>
      <NW2InfoCard title={title} type={type} icon={icon}>
        {text}
      </NW2InfoCard>
    </InfoCardWrapper>
  );
};

export default StatusAndPreviewContent;
