import React, {ChangeEvent, FocusEvent, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useForm} from 'react-final-form';
import _get from 'lodash/get';

import {NW2FormItemInput} from 'view/components/NW2FormItem/NW2FormItem';

import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {IAmenity} from 'types/dto/IAmenity';
import {requiredPriceRules} from 'utils/finalFormFieldRules';
import {inputTextToNumber} from 'utils/helpers';
import {EAddDeskFormFields} from '../types';
import {PageFormSection} from '../styles';

export function AddDeskPrice() {
  const {mutators} = useForm();

  const currency: IAmenity[] = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.currency`),
  );

  const onChangePrice = useCallback(
    (type: EAddDeskFormFields) => (e: ChangeEvent<HTMLInputElement>) => {
      const onChange = (v: string) => {
        mutators.setValue(type, v);
      };

      inputTextToNumber(e.target.value, onChange);
    },
    [mutators],
  );

  const onBlurPrice = useCallback(
    (type: EAddDeskFormFields) => (e: FocusEvent<HTMLInputElement>) => {
      const value = e.target.value;

      if (value) {
        mutators.setValue(type, Number(value).toFixed(2));
      }
    },
    [mutators],
  );

  return (
    <PageFormSection>
      <NW2FormItemInput
        type='text'
        label={`Per hour in ${currency}`}
        placeholder={`Per hour in ${currency}`}
        name={EAddDeskFormFields.PRICE_HOUR}
        rules={requiredPriceRules(
          EAddDeskFormFields.PRICE_HOUR,
          'Please provide per hour price',
        )}
        onChange={onChangePrice(EAddDeskFormFields.PRICE_HOUR)}
        onBlur={onBlurPrice(EAddDeskFormFields.PRICE_HOUR)}
      />

      <NW2FormItemInput
        type='text'
        label={`Per day in ${currency}`}
        placeholder={`Per day in ${currency}`}
        name={EAddDeskFormFields.PRICE_DAY}
        rules={requiredPriceRules(
          EAddDeskFormFields.PRICE_DAY,
          'Please provide per day price',
        )}
        onChange={onChangePrice(EAddDeskFormFields.PRICE_DAY)}
        onBlur={onBlurPrice(EAddDeskFormFields.PRICE_DAY)}
      />
    </PageFormSection>
  );
}
