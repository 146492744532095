import styled from 'styled-components';
import {
  contentMaxWidthLg,
  lgBp,
  mdBp,
  offset120,
  offset80,
  offsetDef,
  offsetXLg,
  offsetXXLg,
  offsetXXXLg,
  sectionInsetLg,
  sectionInsetMd,
  sectionInsetXs,
  smBp,
} from 'constants/styleVars';

const NW2MainLandingContainer = styled.div`
  overflow: hidden;
`;

const NW2Container = styled.div<{offset?: string}>`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  ${({offset}) => (offset ? `margin-top: ${offset}` : '')}
  padding: 0 ${offsetDef};

  @media (min-width: ${mdBp}px) {
    padding: 0 ${offsetXXLg};
  }

  @media (min-width: ${lgBp}px) {
    max-width: calc(${contentMaxWidthLg} + 2 * ${offsetXXLg});
  }
`;

const StyledContainer = styled(NW2Container)`
  margin-top: ${offsetXLg};
`;

const NW2ContainerWithOffset = styled(NW2Container)`
  & + & {
    margin-top: ${offsetXXXLg};
  }

  @media (min-width: ${mdBp}px) {
    & + & {
      margin-top: ${offset80};
    }
  }

  @media (min-width: ${lgBp}px) {
    & + & {
      margin-top: ${offset120};
    }
  }
`;

const NW2ContainerRow = styled.div<{offset?: string}>`
  display: grid;
  grid-gap: ${offsetXLg};
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  ${({offset}) => (offset ? `margin-top: ${offset}` : '')};

  @media (min-width: ${smBp}px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;

const NW2Section = styled.section<{background?: string}>`
  padding: ${sectionInsetXs} 0;
  background: ${({background}) => background || 'transparent'};

  @media (min-width: ${mdBp}px) {
    padding: ${sectionInsetMd} 0;
  }

  @media (min-width: ${lgBp}px) {
    padding: ${sectionInsetLg} 0;
  }
`;

export {
  NW2MainLandingContainer,
  NW2Section,
  NW2Container,
  StyledContainer,
  NW2ContainerWithOffset,
  NW2ContainerRow,
};
