import {ApiType, IApi} from '../common/http.service';
import {TDeletePaymentMethodPayload} from 'store/payment/types';

// TODO: do not remove in case we will need adyen in future
const paymentRepository = (api: IApi) => ({
  postPaymentDetails: async (paymentDetails: any): Promise<any> => {
    return await api.add(
      ApiType.Inventory,
      `payments/paymentDetails`,
      paymentDetails,
    );
  },
  postDefaultPaymentMethod: async ({
    creditCardId,
    customerId,
  }: TDeletePaymentMethodPayload): Promise<any> => {
    return await api.add(
      ApiType.Inventory,
      `payment-method/default?customerId=${customerId}`,
      {uuid: creditCardId},
    );
  },
  postNewPaymentMethod: async ({
    paymentMethodDetails,
    customerId,
  }: Record<string, any>): Promise<any> => {
    return await api.add(
      ApiType.Inventory,
      `payment-method?customerId=${customerId}`,
      paymentMethodDetails,
    );
  },
  deletePaymentMethod: async ({
    creditCardId,
    customerId,
  }: TDeletePaymentMethodPayload): Promise<any> => {
    return await api.delete(
      ApiType.Inventory,
      `payment-method/${creditCardId}?customerId=${customerId}`,
    );
  },

  getPaymentMethods: async (customerId: string | number) => {
    return await api.get(
      ApiType.Inventory,
      `payment-method?customerId=${customerId}`,
    );
  },
  getUnregisteredPaymentMethods: async () => {
    return await api.get(ApiType.Inventory, `payment-method/guest`);
  },
});

export default paymentRepository;
