import React, {useCallback} from 'react';
import styled from 'styled-components';
import {Field, useForm, useFormState} from 'react-final-form';

import NW2Button from 'view/components/NW2Button';

import {ErrorMessage, Input} from 'view/components/NW2FormItem/components';

import useToggle from 'hooks/useToggle';
import {requiredPriceRules} from 'utils/finalFormFieldRules';
import {fontWeightBold, offsetDef, offsetXLg} from 'constants/styleVars';
import {useInputPriceChange} from 'view/venue/hooks/useInputPriceChange';
import {FREE} from 'constants/venue';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 50px;
  grid-column-gap: ${offsetDef};
  align-items: start;
  margin-bottom: ${offsetXLg};
`;

const Button = styled(NW2Button)`
  margin-top: 6px;
  font-weight: ${fontWeightBold};
`;

interface IProps {
  name: string;
  fieldName: string;
}

export function FormInputWithButton({name, fieldName}: IProps) {
  const {mutators} = useForm();
  const {values} = useFormState();

  const inputValue = values[fieldName]?.[name];

  const [isFree, toggleFree] = useToggle(inputValue === FREE);

  const rules = requiredPriceRules(fieldName);

  const inputFieldName = `${fieldName}.${name}`;

  const handleFree = useCallback(() => {
    mutators.setValue(inputFieldName, isFree ? '' : FREE);
    toggleFree();
  }, [inputFieldName, isFree, mutators, toggleFree]);

  const {onChangePrice, onBlurPrice} = useInputPriceChange();

  return (
    <Wrapper>
      <Field name={inputFieldName} validate={rules}>
        {({input, meta}) => {
          const hasError = isFree ? false : meta.error && meta.touched;

          return (
            <>
              <div title={name}>
                <Input
                  name={inputFieldName}
                  type='text'
                  label={name}
                  placeholder={name}
                  hasError={hasError}
                  value={isFree ? FREE : input.value}
                  onChange={onChangePrice(inputFieldName)}
                  onBlur={onBlurPrice(inputFieldName)}
                  inputProps={{...input, disabled: isFree}}
                  disabled={isFree}
                  isLabelActive={isFree}
                />
                <ErrorMessage
                  hasError={hasError}
                  errorData={meta.error}
                  showAllValidationErrors
                />
              </div>
              <Button
                buttonType='chip'
                size='small'
                active={isFree}
                onClick={handleFree}
              >
                Free
              </Button>
            </>
          );
        }}
      </Field>
    </Wrapper>
  );
}
