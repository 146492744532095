import React, {memo, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _get from 'lodash/get';
import getSymbolFromCurrency from 'currency-symbol-map';
import {Tooltip} from 'antd';

import Image from 'view/components/Image';
import TruncateText from 'view/components/TruncateText';
import NW2Button from 'view/components/NW2Button';
import imagePlaceholder from 'img/no_image_spaces.jpg';
import NMMSpaceStatus from '../NMMSpaceStatus/NMMSpaceStatus';

import {setSpaceDetailsId} from 'store/venues/actions';
import {getCapacityRange, getCoverImage} from 'view/venue/helpers/spaces';
import {formatFloatWithOneNumber} from 'utils/venueUtils';
import {getTypeConfig} from './helpers';
import {useSpaceStatus} from '../hooks/useSpaceStatus';
import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {
  blackColor,
  borderRadiusDef,
  fontSizeXSm,
  lineHeightSm,
} from 'constants/styleVars';
import {ISpacesDataItem} from 'types/venue';
import {ERoomType} from 'types/dto/ERoomType.type';
import {
  Card,
  CardImageWrapper,
  CardContent,
  CardTitle,
  CardInfo,
  ItemWithIcon,
  PrefixIcon,
  CardContainer,
  Text,
  DropdownWrapper,
  StyledDropdown,
  StyledLink,
  ActionsDiv,
  PriceText,
} from './NMMSpacesList.styles';
import {RootState} from '../../../../../../../store/types';
import {EUserRoleCognito} from '../../../../../../../types/dto/EUserRoleCognito';

const SPACE_IMAGE_HEIGHT = '125px';
const TRIGGER_ITEM_SIZE = '36px';

type SpaceItemData = {
  itemData: ISpacesDataItem;
  roomType: ERoomType;
  isExternalVenue: boolean;
  isWIP?: boolean;
};

const PriceViewComponent = memo(
  ({
    price,
    type,
    currencySymbol,
  }: {
    currencySymbol?: string;
    price: number;
    type: 'p/h' | 'p/hd' | 'p/d';
  }) => {
    const toolTipProps = {
      color: blackColor,
      overlayInnerStyle: {
        fontSize: fontSizeXSm,
        lineHeight: lineHeightSm,
        borderRadius: borderRadiusDef,
      },
    };

    const text = useMemo(() => {
      switch (type) {
        case 'p/h':
          return {title: 'Price per hour', id: 'priceHour'};
        case 'p/hd':
          return {title: 'Price per half-day', id: 'priceHalfDay'};
        case 'p/d':
          return {title: 'Price per day', id: 'priceDay'};
        default:
          return {title: '', id: ''};
      }
    }, [type]);

    return (
      <Tooltip title={text.title} {...toolTipProps}>
        <PriceText data-testid={`space-card-${text.id}`}>
          {`${formatFloatWithOneNumber(price)} ${currencySymbol} ${type} `}
          &nbsp;
        </PriceText>
      </Tooltip>
    );
  },
);

const EMPTY_SYMBOL = '-';

const NMMSpacesListItem = ({
  itemData: {
    id,
    name,
    description,
    capacity,
    priceHour,
    priceHalfDay,
    priceDay,
    documents,
    isRfp,
    unitCapacities,
    type,
  },
  roomType,
  isExternalVenue,
  isWIP,
}: SpaceItemData) => {
  const dispatch = useDispatch();

  // TODO: Find better solution
  const isAgent =
    useSelector((state: RootState) => _get(state, 'app.user.role')) ===
    EUserRoleCognito.ROLE_AGENT;

  // Status should be always LIVE for corporate venues
  const [paymentStatus] = useSpaceStatus({isExternalVenue});
  const coverImage = getCoverImage(documents);
  const image = coverImage?.url || imagePlaceholder;

  const currency: string = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.currency`),
  );
  const currencySymbol = useMemo(
    () => getSymbolFromCurrency(currency),
    [currency],
  );

  // Dropdown
  const [isDropdownShowed, setDropdownShowed] = useState(false);
  const onShow = () => setDropdownShowed(true);
  const onHide = () => setDropdownShowed(false);

  const openSpaceDetails = () => {
    dispatch(setSpaceDetailsId(id));
  };

  return (
    <Card onMouseEnter={onShow} onMouseLeave={onHide}>
      <CardImageWrapper data-testid='space-card-image-wrapper'>
        <Image
          alt='Space'
          src={image}
          height={SPACE_IMAGE_HEIGHT}
          borderRadius={borderRadiusDef}
          data-testid='space-card-image'
        />
      </CardImageWrapper>
      <CardContent>
        <CardTitle data-testid='space-card-title'>
          {name || EMPTY_SYMBOL}
        </CardTitle>

        <CardInfo>
          {getTypeConfig(roomType, type, isExternalVenue, isRfp)}
          <ItemWithIcon data-testid='space-card-capacity'>
            <PrefixIcon icon='NW2_THREE_PEOPLE' />
            {capacity
              ? getCapacityRange(unitCapacities, capacity, roomType)
              : EMPTY_SYMBOL}
          </ItemWithIcon>
          {isExternalVenue && (
            <ItemWithIcon>
              <PrefixIcon icon='NW2_CREDIT_CARD' />
              {priceHour || priceHalfDay || priceDay ? (
                <>
                  {priceHour && (
                    <PriceViewComponent
                      price={priceHour}
                      type='p/h'
                      currencySymbol={currencySymbol}
                    />
                  )}

                  {priceHalfDay && (
                    <PriceViewComponent
                      price={priceHalfDay}
                      type='p/hd'
                      currencySymbol={currencySymbol}
                    />
                  )}

                  {priceDay && (
                    <PriceViewComponent
                      price={priceDay}
                      type='p/d'
                      currencySymbol={currencySymbol}
                    />
                  )}
                </>
              ) : (
                EMPTY_SYMBOL
              )}
            </ItemWithIcon>
          )}
        </CardInfo>

        <CardContainer>
          <Text data-testid='space-card-description'>
            {description ? (
              <TruncateText
                text={description}
                numberOfLines={3}
                tag='pre'
                hideToggler
              />
            ) : (
              EMPTY_SYMBOL
            )}
          </Text>
          <ActionsDiv>
            {isAgent ? null : isWIP ? (
              <NW2Button
                buttonType='secondary'
                onClick={openSpaceDetails}
                data-testid='continue-editing'
                size='small'
              >
                Continue editing
              </NW2Button>
            ) : (
              <NMMSpaceStatus status={paymentStatus} />
            )}
          </ActionsDiv>
        </CardContainer>

        {!isWIP && !isAgent && (
          <DropdownWrapper isShowed={isDropdownShowed}>
            <StyledDropdown
              trigger={['hover']}
              placement='bottomRight'
              triggerItemSize={TRIGGER_ITEM_SIZE}
            >
              <StyledLink
                onClick={openSpaceDetails}
                data-testid={'dropdown-list-item-edit-space'}
              >
                Edit space
              </StyledLink>
            </StyledDropdown>
          </DropdownWrapper>
        )}
      </CardContent>
    </Card>
  );
};

export default NMMSpacesListItem;
