import React, {useCallback, useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import _get from 'lodash/get';
import queryString from 'query-string';

import NW2Loader from 'view/components/NW2Loader/NW2Loader';

import useSearchData from '../hooks/useSearchData';

import {Routes} from 'constants/routes';
import {useQuery} from 'hooks/useQuery';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {useIsSearchWithBedrooms} from '../hooks/useIsSearchWithBedrooms';
import {useGetSearchDateDifference} from '../hooks/useGetSearchDateDifference';
import {
  AnchorContentBlock,
  AnchorNavigationBlock,
  GoBackLink,
  NW2SpaceDetails,
  VenueImages,
  VenueOffers,
} from './components';
import {
  getVenueDetailsFailure,
  searchVenuesAction,
  setIsVenueDetailsMapVisible,
} from 'store/venues/actions';
import {getAnchorContentSections} from './components/AnchorBlock/helpers';
import {IMergedVenue} from 'types/search';
import {NW2Container} from 'view/mainLanding/MainLanding.styles';
import {
  HeroHeaderContainer,
  SectionDark,
  SectionLight,
} from './NW2VenueDetails.styles';
import {EFrontendErrors} from 'types/errors';
import {EIgnoreInterceptorCodes} from 'utils/axiosInterceptors/types';
import {ShortList} from '../Offer/customer/ShortList';
import {useStoredSearchCriteria} from '../hooks/useStoredSearchCriteria';
import {useMultiDayVenueDetailsData} from './useMultiDayVenueDetails';
import {useVenueDetailsId} from '../hooks/useVenueDetailsId';
import {useVenueDetailsData} from '../hooks/useVenueDetailsData';
import {EVenueType} from 'types/dto/IPublicVenue';
import {ERoomType} from 'types/dto/ERoomType.type';
import useParamsVenueId from '../hooks/useParamsVenueId';
import {setSearchCriteriaRoomType} from 'store/search/searchSlice';
import {ALLOWED_DAYS_DIFFERENCE_FOR_RFP} from 'constants/days';
import {makeMultiSearchPayload} from '../../components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/utils';
import {useCommonExtras} from '../hooks/useCommonExtras';
import {EResourcesType} from 'types/dto/IExtras.type';

function NW2VenueDetails() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {showWhatsAround, roomType: roomTypeFromQuery} = useQuery();
  const {paramsVenueId} = useParamsVenueId();

  const venueGlobalList: IMergedVenue[] = useSelector((state) =>
    _get(state, 'venuesReducer.venueGlobalList'),
  );
  const error: unknown = useSelector((state) =>
    _get(state, 'venuesReducer.error'),
  );
  const searchCriteria = useAppSelector(({search}) => search.searchCriteria);
  const isLoading = useSelector((state) =>
    _get(state, 'venuesReducer.loading'),
  );
  // refetch venueGlobalList when refresh page
  const {initialSearchData, bedroomsCatering} = useSearchData();
  const [extrasOption] = useCommonExtras();
  const filteredBedroomExtras = useMemo(
    () => extrasOption.filter((item) => item.type === EResourcesType.BEDROOM),
    [extrasOption],
  );
  const getVenueGlobalList = useCallback(() => {
    if (roomTypeFromQuery !== ERoomType.WORK_SPACE) {
      const {multiSearchPayload} = makeMultiSearchPayload({
        meetingRequestData: initialSearchData.meetingRequestData,
        bedroomsCatering,
        filteredBedroomExtras,
      });

      const requestData = {
        ...initialSearchData,
        filterDays: multiSearchPayload,
        searchString: '', // backend needs it to be an empty string!
      };

      dispatch(searchVenuesAction(requestData));
    }
  }, [
    roomTypeFromQuery,
    initialSearchData,
    bedroomsCatering,
    filteredBedroomExtras,
    dispatch,
  ]);

  useEffect(() => {
    if (venueGlobalList.length < 1 && !isLoading) {
      getVenueGlobalList();
    }
  }, [venueGlobalList.length, isLoading, getVenueGlobalList]);

  useMultiDayVenueDetailsData(paramsVenueId);

  const {venueDetails, isVenueDetailsLoading} = useVenueDetailsData();
  const [venueDetailsId] = useVenueDetailsId();

  const amenities = venueDetails.amenities;

  // Old hook for fetching venue details for rooms and desks
  // useVenueDetailsData({
  //   venueId: paramsVenueId,
  //   isAllSpaces: false,
  //   isVenueLists: !!venueGlobalList.length || !!venueOffersList.length,
  // });

  // New hook for fetching venue details for rooms only

  const {querySearchData} = useSearchData();
  const {venueID: paramsId = ''} = useParams<{venueID: string}>();

  useStoredSearchCriteria({querySearchData});

  // Accommodation existing on perfect matches
  const {isSearchWithBedrooms} = useIsSearchWithBedrooms();

  const [diffInDays] = useGetSearchDateDifference();
  let alternativeList = venueGlobalList.filter(
    (venue) => venue.accommodationId != +paramsId,
  );

  if (diffInDays > ALLOWED_DAYS_DIFFERENCE_FOR_RFP) {
    alternativeList = alternativeList.filter(
      (venue) =>
        venue.type !== EVenueType.RFP && venue.type !== EVenueType.RFP_WITH_WIP,
    );
  }

  const anchorContentSections = getAnchorContentSections({
    isAlternativeSectionVisible:
      searchCriteria.roomType === ERoomType.MEETING_ROOM &&
      alternativeList.length > 0,
    amenities,
    isDetailsSectionVisible: !!venueDetailsId,
    alternativeList,
  });

  // useEffect(() => {
  //   // clear showed case after book again flow
  //   return () => {
  //     dispatch(setDatepickerDetailsShowed(false));
  //     // clear AdyenCardAction from localStorage just in case if user will go back to venue details page
  //     LocalStorageService.removeItemByKey('AdyenCardAction');
  //   };
  // }, [dispatch]);

  // Open what's around map
  useEffect(() => {
    if (showWhatsAround) {
      dispatch(setIsVenueDetailsMapVisible(true));
    }
  }, [dispatch, showWhatsAround]);

  // Navigate to venues list page if user has no access to this venue
  useEffect(() => {
    if (
      error ===
        EIgnoreInterceptorCodes.FORBIDDEN_ACCESS_TO_VENUE_DETAILS_ERROR ||
      error === EIgnoreInterceptorCodes.UNAUTHORIZED_NO_ACCESS_DATA_ERROR
    ) {
      // Set ACCESS_TO_VENUE_DENIED_ERROR error to show no access block in venues list page
      dispatch(
        getVenueDetailsFailure(EFrontendErrors.ACCESS_TO_VENUE_DENIED_ERROR),
      );

      navigate({
        pathname: Routes.venuesList,
        search: queryString.stringify(querySearchData),
      });
    }
  }, [error, dispatch, navigate, querySearchData]);

  useEffect(() => {
    if (roomTypeFromQuery && roomTypeFromQuery !== searchCriteria.roomType) {
      dispatch(setSearchCriteriaRoomType(roomTypeFromQuery as ERoomType));
    }
  }, [dispatch, roomTypeFromQuery, searchCriteria.roomType]);

  if (!venueDetailsId || isVenueDetailsLoading)
    return <NW2Loader height='100%' />;

  return (
    <>
      <SectionDark>
        <HeroHeaderContainer>
          <GoBackLink />
          <VenueImages />
        </HeroHeaderContainer>

        <NW2Container>
          <VenueOffers hasBedroom={isSearchWithBedrooms} />
        </NW2Container>
      </SectionDark>

      <AnchorNavigationBlock
        anchorContentSections={anchorContentSections}
        roomType={searchCriteria.roomType}
        hasBedroom={isSearchWithBedrooms}
      />

      <SectionLight>
        <AnchorContentBlock anchorContentSections={anchorContentSections} />
      </SectionLight>

      {venueDetails.type !== EVenueType.RFP_WITH_WIP && (
        <NW2SpaceDetails hasBedroom={isSearchWithBedrooms} isFooterHidden />
      )}

      <ShortList hasBackNav querySearchData={querySearchData} />
    </>
  );
}

export default NW2VenueDetails;
