import {combineReducers} from 'redux';

import appSlice from './app/appSlice';
import bookingReducer from './bookings/reducer';
import bookingsSupplier from './bookingsSupplier/reducer';
import venuesReducer from './venues/reducer';
import venueReducer from './venue/reducer';
import paymentSlice from './payment/paymentSlice';
import customerSlice from 'store/customer/customerSlice';
import searchSlice from './search/searchSlice';
import offersSlice from 'store/offers/offersSlice';
import corporateOfficeSlice from 'store/corporateOffice/corporateOfficeSlice';
import adminSlice from './admin/adminSlice';
import venueDetailsReducer from './venueDetails/venueDetailsSlice';
import agentSlice from './agent/agentSlice';
import packagesSlice from './packages/packagesSlice';
import workDesksSlice from './workDesks/workDesksSlice';

// MAIN REDUCER WHICH BRINGS OTHERS TOGETHER
const rootReducer = combineReducers({
  app: appSlice,
  bookingReducer,
  bookingsSupplier,
  venuesReducer,
  venue: venueReducer,
  venueDetails: venueDetailsReducer,
  workDesks: workDesksSlice,
  payment: paymentSlice,
  customer: customerSlice,
  search: searchSlice,
  offers: offersSlice,
  admin: adminSlice,
  agent: agentSlice,
  corporate: corporateOfficeSlice,
  packages: packagesSlice,
});

export default rootReducer;
