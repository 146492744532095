import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';

import TruncateText from 'view/components/TruncateText';
import Packages from './components/Packages';
import ExtrasList from './components/ExtrasList';
import PreviewExtrasList from 'view/venue/components/PreviewExtrasList';

import {SpaceImage} from './components/SpaceImage';
import {EDeskSpaceName, TBedrooms, TSpaceType} from 'types/venue';
import {ERoomType} from 'types/dto/ERoomType.type';
import {setSpaceDetailsId} from 'store/venues/actions';
import {
  EResourcesCode,
  EResourcesType,
  IExtrasOption,
  IExtrasResponse,
} from 'types/dto/IExtras.type';
import {
  filterByAvailability,
  filterPreviewExtrasByExtraType,
} from 'utils/venueUtils';
import {pluralize} from 'utils/helpers';
import DateUtils from 'utils/dateUtils';
import {ERoomSchemaNames, ERoomSchema} from 'types/venue';
import {
  EAccommodationType,
  IExtraResponse,
  IUnitResponse,
} from 'types/dto/IPublicVenue';
import {
  IPreviewUnit,
  IPreviewUnitResponse,
  ISummaryExtra,
} from 'types/dto/IBooking.types';
import {useAppSelector} from 'store/hooks';
import {
  UnitTitle,
  UnitBox,
  UnitInfo,
  UnitNameLink,
  StyledAccordion,
  UnitName,
  ExtrasTitle,
} from './Space.styles';
import {convertOfferExtrasToVenueExtras} from 'view/venue/Offer/helpers';
import {capitalizeText} from 'utils/stringUtils';
import {IOfferUnitExtra} from 'types/offer';
import {useGroupRequest} from 'view/venue/hooks/useGroupRequest';
import {IPreviewExtra} from 'store/venues/types';
import {moveToEndWhere} from 'view/venue/helpers/spaces';
import LocalStorageService from 'infra/common/localStorage.service';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';
import {TSelectedPackageItem} from 'types/dto/IPackages.type';

const accordionBorderRadius = 16;
const initMaxToShowEquipment = 5;
const initMaxToShowFoodBeverage = 3;

type TProps = {
  units: IPreviewUnit[] | IPreviewUnitResponse[];
  isMultiDay?: boolean;
  dayIndex?: number;
  previewFoodAndBeverage?: (ISummaryExtra | IOfferUnitExtra)[];
  bedrooms?: TBedrooms;
  duration?: number;
  packagesPreview?: TSelectedPackageItem[];
};
const Space = ({
  units = [],
  isMultiDay,
  dayIndex = 0,
  previewFoodAndBeverage = [],
  bedrooms,
  duration,
  packagesPreview,
}: TProps) => {
  const dispatch = useDispatch();
  const {isMultiRequest, isSingleRegisteredVenue} = useGroupRequest();

  const modernAvailableExtras: IExtrasResponse[] = useSelector((state) =>
    _get(state, 'venueDetails.details.extras'),
  );

  const workDesksAvailableExtras = useSelector((state) =>
    _get(state, 'workDesks.workDesksDetails.extras'),
  );

  const roomType = useAppSelector(({search}) => search.searchCriteria.roomType);
  // NOW USED FOR WORK_SPACES
  const alternativeAvailableExtras: IExtrasResponse[] = useSelector((state) =>
    _get(state, 'venuesReducer.venueDetails.extras'),
  );

  const isWorkDesks = roomType === ERoomType.WORK_SPACE;

  const bookingAvailableExtras: IExtrasResponse[] =
    modernAvailableExtras?.length || workDesksAvailableExtras?.length
      ? modernAvailableExtras || workDesksAvailableExtras
      : !isWorkDesks
      ? alternativeAvailableExtras
      : [];

  const participants = useAppSelector(
    ({search}) => search.searchCriteria.meetingRoomCapacity,
  );

  const extrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );

  const {venueDetails} = useVenueDetailsData();

  const accommodationType = venueDetails.accommodationType;
  const isOfferDetails = venueDetails.isOffer;

  const {getHoursAndMinutes} = DateUtils;

  const isPublicVenue = accommodationType === EAccommodationType.VENUE;

  const extrasOptionMap = useMemo(() => {
    /**
     * we need to convert all common extras to IExtrasResponse for using them by ExtrasList
     * in the offer request flow
     */
    return convertOfferExtrasToVenueExtras(extrasOption);
  }, [extrasOption]);

  const getAvailableExtras = () => {
    switch (true) {
      case isSingleRegisteredVenue && !isMultiDay:
        return filterByAvailability<IExtrasResponse>(
          bookingAvailableExtras,
          roomType,
        );

      case isSingleRegisteredVenue && isMultiDay:
        // TODO: Can produce bugs in future which can be resolved by updating of this return statement
        return bookingAvailableExtras;
      default:
        return extrasOptionMap;
    }
  };

  const availableExtras = getAvailableExtras();

  const equipmentList = filterPreviewExtrasByExtraType<IExtrasResponse>(
    availableExtras,
    EResourcesType.EQUIPMENT,
  );

  const foodBeverageList = filterPreviewExtrasByExtraType<IExtrasResponse>(
    availableExtras,
    EResourcesType.FOOD_AND_BEVERAGE,
  );

  const bedroomsList = filterPreviewExtrasByExtraType<IExtrasResponse>(
    availableExtras,
    EResourcesType.BEDROOM,
  );

  const baseAccordionData = units.map((unit, index, array) => {
    const {
      name,
      type = ERoomType.MEETING_ROOM,
      roomType,
      documents,
      unitId = 0,
      checkInDate,
      checkOutDate,
      unitCapacities,
      chosenSetupStyle,
      chosenExtras,
    } = unit;

    const customerSelectedPackage =
      packagesPreview?.find(([uId]) => uId === unitId)?.[1] || undefined;

    const chosenEquipmentExtras = moveToEndWhere(
      // move wi-fi at the end
      (chosenExtras as IExtraResponse[]).filter(
        ({extraType}) => !extraType || extraType === EResourcesType.EQUIPMENT,
      ),
      'code',
      EResourcesCode.WIFI,
    );

    const unitTypeText = EDeskSpaceName[type as TSpaceType];

    const isMeetingRoom = roomType !== ERoomType.WORK_SPACE;

    const timeText = `${getHoursAndMinutes(checkInDate)} - ${getHoursAndMinutes(
      checkOutDate,
    )}`;

    const meetingDuration = DateUtils.getTotalHours(checkInDate, checkOutDate);

    const seatingPlan =
      chosenSetupStyle || (unit as IUnitResponse).requestedSetupStyle; //|| venuesChosenSeatingPlan;

    const currentSeatingPlanItem = unitCapacities?.find(
      (plan) => plan.setupStyle === ERoomSchemaNames[seatingPlan],
    );

    const roomSetupText = seatingPlan ? ERoomSchema[seatingPlan] : '';

    const maxCapacityText = currentSeatingPlanItem
      ? `(${currentSeatingPlanItem.capacity} max)`
      : '';

    const participantsText = `${pluralize(
      'people',
      (unit as IPreviewUnit).unitFilter?.capacity ||
        (unit as IPreviewUnitResponse)?.requestedCapacity ||
        participants,
    )} ${maxCapacityText && !isMultiRequest ? maxCapacityText : ''}`;

    const guestBookingPreviewData =
      LocalStorageService.getByKey('bookingPreviewData');
    const guestPreviewUnits = guestBookingPreviewData
      ? JSON.parse(guestBookingPreviewData).previewUnits
      : null;
    const guestSelectedPackage =
      guestPreviewUnits?.[dayIndex]?.selectedPackage?.[0]?.[1];
    const isGuestBookingPreviewMode = !!guestBookingPreviewData;

    const openSpaceDetails = () => {
      if (isOfferDetails) return;
      dispatch(setSpaceDetailsId(unitId));
    };

    const roomCounter = array.length > 1 ? (index + 1).toString() : '';

    const unitName = isSingleRegisteredVenue ? (
      <TruncateText text={name} hideToggler />
    ) : (
      'Meeting Room'
    );

    const unitTitle = isMeetingRoom
      ? `Meeting room ${roomCounter}`
      : 'Work desk';

    return {
      title: (
        <div key={`head_${unitId}`}>
          <UnitTitle>{capitalizeText(unitTitle)}</UnitTitle>
        </div>
      ),
      content: (
        <>
          <UnitBox>
            <SpaceImage
              images={documents}
              isPlaceholder={!isSingleRegisteredVenue}
            />

            <div>
              {isOfferDetails || isMultiRequest ? (
                <UnitName>{unitName}</UnitName>
              ) : (
                <UnitNameLink onClick={openSpaceDetails} hasMargin={false}>
                  {unitName}
                </UnitNameLink>
              )}

              <UnitInfo>
                <span>Time:&nbsp;</span>
                {timeText}
              </UnitInfo>
              {isMeetingRoom ? (
                <>
                  {!!roomSetupText && (
                    <UnitInfo>
                      <span>Room setup:&nbsp;</span>
                      {roomSetupText}
                    </UnitInfo>
                  )}
                  <UnitInfo>
                    <span>Participants:&nbsp;</span>
                    {participantsText}
                  </UnitInfo>
                </>
              ) : (
                <UnitInfo>
                  <span>Space type:&nbsp;</span>
                  {unitTypeText}
                </UnitInfo>
              )}
            </div>
          </UnitBox>
          {/*show packages only in first room of the day*/}
          {isMeetingRoom && !index && (
            <Packages
              participants={participants}
              dayIndex={dayIndex}
              unitId={unitId}
              meetingDuration={meetingDuration}
              guestSelectedPackage={guestSelectedPackage}
              isGuestBookingPreviewMode={isGuestBookingPreviewMode}
              customerBookedPackage={customerSelectedPackage}
            />
          )}

          {!!equipmentList.length && (
            <ExtrasList
              chosenExtras={chosenEquipmentExtras}
              extras={equipmentList}
              initMaxToShow={initMaxToShowEquipment}
              dayId={dayIndex}
              unitId={unitId}
              isPriceHidden={false}
              checkInDate={checkInDate}
              checkOutDate={checkOutDate}
            />
          )}
        </>
      ),
      expandable:
        isPublicVenue &&
        !!equipmentList.length &&
        !!chosenEquipmentExtras.length,
    };
  });

  const meetingRoomParticipants = isMultiDay
    ? //TODO: Fix any
      (units as any[]).reduce(
        (prev, curr) =>
          Math.max(
            prev,
            (curr as IPreviewUnit).unitFilter?.capacity ||
              (curr as IPreviewUnitResponse)?.requestedCapacity ||
              0,
          ),
        0,
      )
    : participants;

  const foodBeverageData = {
    title: <ExtrasTitle>Food & Beverage</ExtrasTitle>,
    content: (
      <ExtrasList
        chosenExtras={previewFoodAndBeverage}
        extras={foodBeverageList}
        initMaxToShow={initMaxToShowFoodBeverage}
        dayId={dayIndex}
        participants={meetingRoomParticipants}
        duration={duration}
        isPriceHidden={false}
        isFoodAndBeverage
      />
    ),
  };

  const unitBedrooms = (
    (units[0]?.chosenExtras || []) as IPreviewExtra[]
  ).filter(({extraType}) => extraType === EResourcesType.BEDROOM);

  const sortedBedrooms = _orderBy(bedrooms, ['code'], ['desc']) as TBedrooms;

  const accommodationData = {
    title: <ExtrasTitle>Accommodation</ExtrasTitle>,
    content: (
      <PreviewExtrasList extras={sortedBedrooms} extrasOptions={bedroomsList} />
    ),
  };

  const hasFoodAndBeverage = foodBeverageList.length;

  const items = hasFoodAndBeverage
    ? [...baseAccordionData, foodBeverageData]
    : baseAccordionData;

  const itemsToRender = (sortedBedrooms || unitBedrooms)?.length
    ? [...items, accommodationData]
    : items;

  return (
    <StyledAccordion
      items={itemsToRender}
      isDivider={false}
      borderRadius={accordionBorderRadius}
    />
  );
};

export default Space;
