import {Dispatch, SetStateAction} from 'react';

import {TFileWithPreview} from 'view/venue/NW2VenueProfile/components/NW2Images/types';
import {TRoomSchemaOption} from 'view/venue/NW2VenueProfile/components/AddSpace/components/AddRoomSchema/types';
import {EEventType, ERoomSchemaNames, ISpacesDataItemImage} from 'types/venue';
import {TUnitCapacityStyle} from 'types/dto/IUnit.types';
import {EDimensionsFormFields} from 'view/venue/NW2VenueProfile/components/NMMSpaces/common/NMMSpacesDetails/components/Dimensions/DimensionsForm.types';
import {IExtrasOption} from 'types/dto/IExtras.type';
import {
  IOfferDay,
  IOfferRequestDay,
  IOfferUnitExtra,
  IRequestDay,
  IRequestDayItem,
} from 'types/offer';

export enum ECreateOfferFormFields {
  TYPE = 'type',
  NAME = 'name',
  IMAGES = 'images',
  COVER_IMAGE = 'coverImage',
  CAPACITY = 'capacity',
  UPDATED_CAPACITIES = 'updatedCapacities',
  FLOOR = 'floor',
  DIMENSIONS = 'dimensions',
  AMENITIES = 'amenities',
  DESCRIPTION = 'description',
  PRICES_EQUIPMENT = 'pricesEquipment',
  PRICES_FOOD_AND_BEVERAGE = 'pricesFoodAndBeverage',
  PRICES_BEDROOMS = 'pricesBedrooms',
  SUPPLIER = 'supplier',
  PRICE_HOUR = 'priceHour',
  PRICE_HALF_DAY = 'priceHalfDay',
  PRICE_DAY = 'priceDay',
  PAID_DURATION = 'minimumPaidDuration',
}

export interface IPreviewState {
  assignRoomType: TAssignRoomType;
  isAmenitiesHidden: boolean;
  isAmenitiesFilled: boolean;
  isCapacityInitialised: boolean;
  isDimensionsInitialised: boolean;
  isFloorsInitialised: boolean;
  isEquipmentInitialised: boolean;
  isCateringInitialised: boolean;
  isBedroomsInitialised: boolean;
}

export type TFormState = Record<string, TCreateOfferFormValues>;
export type TPreviewState = Record<
  string,
  Partial<IPreviewState> | Record<string, never>
>;

export type TExtraPriceField = Record<string, string | number>;

export type TInitialExtras = {
  [ECreateOfferFormFields.PRICES_EQUIPMENT]: TExtraPriceField;
  [ECreateOfferFormFields.PRICES_FOOD_AND_BEVERAGE]: TExtraPriceField;
};

export type TAssignRoomType = 'wip' | 'new' | 'existed' | '';

export type TBedroomsType = TExtraPriceField | Record<string, never>;

export type TCapacityType = {
  [key in ERoomSchemaNames]?: TRoomSchemaOption;
};

export type TCreateOfferFormValues =
  | {
      [ECreateOfferFormFields.NAME]: string;
      [ECreateOfferFormFields.IMAGES]:
        | TFileWithPreview[]
        | ISpacesDataItemImage[];
      [ECreateOfferFormFields.DESCRIPTION]: string;
      [ECreateOfferFormFields.FLOOR]: string;
      [ECreateOfferFormFields.CAPACITY]: TCapacityType;
      [ECreateOfferFormFields.UPDATED_CAPACITIES]?: TUnitCapacityStyle[];
      [ECreateOfferFormFields.AMENITIES]: number[];
      [ECreateOfferFormFields.DIMENSIONS]: {
        [EDimensionsFormFields.AREA]: string;
        [EDimensionsFormFields.AREA_FEET]: string;
        [EDimensionsFormFields.CEILING_HEIGHT]: string;
        [EDimensionsFormFields.CEILING_HEIGHT_FEET]: string;
      };
      [ECreateOfferFormFields.COVER_IMAGE]: string;
      [ECreateOfferFormFields.PRICES_EQUIPMENT]: TExtraPriceField;
      [ECreateOfferFormFields.PRICES_FOOD_AND_BEVERAGE]: TExtraPriceField;
      [ECreateOfferFormFields.PRICES_BEDROOMS]?: TBedroomsType;
      [ECreateOfferFormFields.PRICE_HOUR]: string;
      [ECreateOfferFormFields.PRICE_HALF_DAY]: string;
      [ECreateOfferFormFields.PRICE_DAY]: string;
      [ECreateOfferFormFields.PAID_DURATION]: string;
      [ECreateOfferFormFields.SUPPLIER]?: string;
    }
  | Record<string, never>;

export type TGetBedroomSubtotalPrice = {
  requestedUnitId: string;
  unitExtras: IOfferUnitExtra[];
  prices?: TExtraPriceField;
};

export interface IPreviewPrices {
  roomRentalPrices: Record<string, number>;
  unitsSubtotal: Record<string, number>;
  totalPrice: number;
  getBedroomSubtotalPrice: (props: TGetBedroomSubtotalPrice) => number;
  removeUnitSubtotalPrice: (unitId: string) => void;
  setRoomPrice: (id: string, price: number) => void;
}

export interface IPreviewExtraOption extends IExtrasOption {
  quantity: number;
}

export type TCreateOfferExtraItem = {
  extraId: number;
  price: {
    value: number;
  };
};

export type TCreateOfferItem = {
  requestItemId: string;
  unitId?: number;
  extras?: TCreateOfferExtraItem[];
  bedrooms?: TCreateOfferExtraItem[];
  eventType?: EEventType;
};

export interface IRequestMeeting extends IRequestDayItem {
  foodAndBeverage?: IOfferUnitExtra[];
  bedrooms?: IOfferUnitExtra[];
}

export interface ICommonProps {
  activeUnitId: string;
  activeForm: TCreateOfferFormValues;
  page: number;
  handleSetFiles: (files: TFileWithPreview[]) => void;
  handleScrollToUnit: (unitId: string) => void;
  handleClose: () => void;
  handleSetItemsToOffer: ({
    unitId,
    extras,
    bedrooms,
    eventType,
  }: {
    unitId?: number | undefined;
    extras?: TCreateOfferExtraItem[] | undefined;
    bedrooms?: TCreateOfferExtraItem[] | undefined;
    eventType?: EEventType | undefined;
  }) => void;
  formState: TFormState;
  formStatus: 'started' | 'finished' | '';
  setActiveUnitId: Dispatch<SetStateAction<string>>;
  setPage: Dispatch<SetStateAction<number>>;
  setFormState: Dispatch<SetStateAction<TFormState>>;
  itemsToOffer: TCreateOfferItem[];
  setItemsToOffer: Dispatch<SetStateAction<TCreateOfferItem[]>>;
  setFormStatus: Dispatch<SetStateAction<'started' | 'finished' | ''>>;

  postEvents: IOfferRequestDay[];
  preArrivals: IOfferRequestDay[];
  requestMeetings: IRequestMeeting[];
  requestRooms: (IRequestDay | IOfferDay)[];
  requestDays: (IRequestDay | IOfferDay)[];
  requestUnits: IRequestMeeting[];

  prices: IPreviewPrices;
  hasEquipment: boolean;
  hasCatering: boolean;
}
