import React, {ReactNode, useCallback} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import {useNmmRegisterClick} from 'view/venue/NW2VenueProfile/components/NMMPayout/hooks/useNmmRegisterClick';
import {MissingInfoStatusData} from './components/MissingInfoStatusData';

import {ELegalEntityType, EPaymentAccountStatus} from 'types/venue';
import {PATH_TO_REDUCER_GLOBAL_VENUE_DATA} from 'constants/venue';

import {
  Container,
  Title,
  SubTitle,
  Description,
  TextWrapper,
  Content,
  StyledButton,
} from './NMMPayoutStatusBlock.styles';

interface IProps {
  status: EPaymentAccountStatus | null;
  customContent?: ReactNode | string;
  legalEntityType?: ELegalEntityType;
}

const defaultInfo = (
  <>
    <SubTitle>Some helpful information</SubTitle>
    <ul>
      <li>
        Revise the information on your business and operating executives and
        correct it, if necessary.
      </li>
      <li>
        In some cases a copy of the signatory persons ID card is required.
      </li>
      <li>Have your bank details ready:</li>
      <ul>
        <li>IBAN Number</li>
        <li>
          Bank document stating the IBAN and the company the bank account
          belongs to
        </li>
      </ul>
    </ul>
  </>
);

const statusMap = {
  [EPaymentAccountStatus.AWAITING_DATA]: {
    title: 'Some information is missing',
    description: (
      <>
        <TextWrapper>
          To get paid by your customers, you need an active profile with our
          contracted payment provider Adyen. Our payment provider Adyen
          requested some more information of you.
        </TextWrapper>
        <TextWrapper>
          Please check your Adyen account and add the missing data.
        </TextWrapper>
      </>
    ),
    linkText: 'Add missing data',
    content: defaultInfo,
  },
  [EPaymentAccountStatus.ACCOUNT_DISABLED]: {
    title: 'Your account was disabled',
    description: (
      <>
        <TextWrapper>
          To get paid by your customers, you need an active profile with our
          contracted payment provider Adyen. Our payment provider Adyen notified
          us, that the the account has been disabled.
        </TextWrapper>
        <TextWrapper>
          To solve this, visit Adyen’s website and get in touch.
        </TextWrapper>
      </>
    ),
    linkText: 'Visit adyen',
    content: defaultInfo,
  },
  [EPaymentAccountStatus.FAILURE]: {
    title: 'Payment setup failed',
    description: (
      <>
        <TextWrapper>
          To get paid by your customers, you need an active profile with our
          contracted payment provider Adyen. Our payment provider Adyen notified
          us, that the payment setup failed.
        </TextWrapper>
        <TextWrapper>
          Please visit your Adyen account and update your data.
        </TextWrapper>
      </>
    ),
    linkText: 'Update account',
    content: defaultInfo,
  },
  [EPaymentAccountStatus.ACTIVE]: null,
  [EPaymentAccountStatus.CHECK_PENDING]: null,
};

const notRegisterStatus = {
  title: 'Revise and register',
  description: (
    <TextWrapper>
      To get paid by your customers, you have to register with our contracted
      payment provider Adyen. Please revise and correct your data as it is
      important that the data you provided to us is correct. Don’t worry, the
      data will be forwarded which will save you time and energy.
    </TextWrapper>
  ),
  linkText: 'Register with payment provider',
  content: defaultInfo,
};

const incorrectInfo = {
  title: 'Incorrect information',
  description: (
    <>
      <TextWrapper>
        To get paid by your customers, you have to register with our contracted
        payment provider Adyen.
      </TextWrapper>
      <TextWrapper>
        We were informed that some of the information you provided were
        incorrect. Please correct them and try again.
      </TextWrapper>
    </>
  ),
  linkText: 'Register with payment provider',
  content: (
    <>
      <SubTitle>Some helpful information</SubTitle>
      <ul>
        <li>
          Ensure the information provided about your business and operating
          executives is accurate.
        </li>
        <li>
          In some cases a copy of the signatory persons ID card is required.
        </li>
        <li>Have your bank details ready:</li>
        <ul>
          <li>IBAN Number</li>
          <li>
            Bank document stating the IBAN and the company the bank account
            belongs to
          </li>
        </ul>
      </ul>
    </>
  ),
};

const NMMPayoutStatusBlock = ({status, customContent}: IProps) => {
  const {hasMissingFields: isBusinessInfoMissing, missingFields} = useSelector(
    (state) =>
      _get(state, `${PATH_TO_REDUCER_GLOBAL_VENUE_DATA}.missingBusinessInfo`),
  );

  const adyenOnBoardingErrorList = useSelector((state) =>
    _get(
      state,
      `${PATH_TO_REDUCER_GLOBAL_VENUE_DATA}.adyenOnBoardingErrorList`,
    ),
  );

  const {onRegisterClick, isGeneratingOnBoardingLink, isClicked} =
    useNmmRegisterClick();

  const onBtnClick = useCallback(() => {
    if (status === EPaymentAccountStatus.ACCOUNT_DISABLED) {
      window?.open('https://www.adyen.com/', '_blank', 'noopener,noreferrer');
    } else {
      onRegisterClick();
    }
  }, [onRegisterClick, status]);

  const hasAdyenOnBoardingErrors = adyenOnBoardingErrorList.length > 0;

  const statusData = status
    ? statusMap[status]
    : isBusinessInfoMissing
    ? MissingInfoStatusData({missingFields})
    : hasAdyenOnBoardingErrors
    ? incorrectInfo
    : notRegisterStatus;

  if (!statusData) return null;

  return (
    <Container>
      <Title>{statusData.title}</Title>
      <Description>{statusData.description}</Description>
      {!!statusData.linkText && (
        <StyledButton
          disabled={
            (isGeneratingOnBoardingLink && isClicked) ||
            hasAdyenOnBoardingErrors
          }
          onClick={onBtnClick}
          buttonType='badge'
          inverted
        >
          {statusData.linkText}
        </StyledButton>
      )}
      <Content>{customContent ? customContent : statusData.content}</Content>
    </Container>
  );
};

export default NMMPayoutStatusBlock;
