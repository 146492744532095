import {useCallback, useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import _get from 'lodash/get';
import queryString from 'query-string';

import {ERoomSchemaNames} from 'types/venue';
import {setBookingPreview} from 'store/venues/actions';
import {
  cleanCustomerSelectedPackages,
  setCustomerSelectedPackages,
} from 'store/customer/customerSlice';
import {IUnit} from 'types/dto/IUnit.types';
import {IExtrasOption, IExtrasResponse} from 'types/dto/IExtras.type';
import {ERoomType} from 'types/dto/ERoomType.type';
import {TGroupedDeskUnitsByDay} from 'types/dto/IBooking.types';
import {useQuery} from 'hooks/useQuery';
import {makeInitPreviewDeskUnitsData} from '../helpers';
import {useRedirectResult} from 'view/components/AdyenPayment/hooks/useRedirectResult';
import {convertOfferExtrasToVenueExtras} from 'view/venue/Offer/helpers';
import {useAppSelector} from 'store/hooks';
import {useShortList} from 'view/venue/Offer/hooks/useShortList';
import {useGroupRequest} from 'view/venue/hooks/useGroupRequest';
import LocalStorageService from 'infra/common/localStorage.service';
import {setIsRegistrationAction} from 'store/app/appSlice';
import {useMultiDayVenueDetailsData} from '../../NW2VenueDetails/useMultiDayVenueDetails';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';
import {setVenueDetails} from '../../../../store/venueDetails/venueDetailsSlice';
import useParamsVenueId from 'view/venue/hooks/useParamsVenueId';

interface IProps {
  id: number | string;
  isOffer?: boolean; //TODO: maybe cut out
  isMeetingRoom?: boolean; //TODO: maybe cut out
}

export function useDeskBookingInitialData({
  id,
  isOffer,
  isMeetingRoom,
}: IProps) {
  const navigate = useNavigate();
  const {shortList} = useShortList();
  const dispatch = useDispatch();
  const {isSingleRegisteredVenue} = useGroupRequest();
  const {paramsVenueId} = useParamsVenueId();

  const customerSelectedPackages = useAppSelector(
    ({customer}) => customer.customerSelectedPackages,
  );

  const searchCriteria = useAppSelector(({search}) => search.searchCriteria);

  /**
   * todo can't change to useVenueDetailsData because IMultiDayPublicVenue hasn't extras... clarify what to do
   * looks like we need to have useVenueDetailsExtras hook
   */
  const multiDayBookingAvailableExtras: IExtrasResponse[] = useSelector(
    (state) => _get(state, 'venuesReducer.venueDetails.accommodationExtras'),
  );
  const simpleBookingAvailableExtras: IExtrasResponse[] = useSelector((state) =>
    _get(state, 'venuesReducer.venueDetails.extras'),
  );

  const previewDeskUnits: TGroupedDeskUnitsByDay[] = useSelector((state) =>
    _get(state, `venuesReducer.bookingPreview.deskPreviewUnits`, []),
  );

  const extrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );

  const {venueUnits: unitsToBook} = useVenueDetailsData();

  const {
    multiRooms: isMultiDay,
    meetingRoomCapacity: participants,
    startDate: searchStartDate,
    endDate: searchEndDate,
  } = searchCriteria;

  const {
    seatingPlan = '',
    roomType = '',
    unitIds = '',
    isMultiRequest: isQueryMultiRequest = false,
  } = useQuery();

  useMultiDayVenueDetailsData(paramsVenueId);

  const stringSearchData = queryString.stringify({...searchCriteria});
  const onGoToVenueDetails = useCallback(() => {
    if (shortList.items.length && typeof isQueryMultiRequest === 'boolean') {
      dispatch(setVenueDetails(null));
      navigate(-1);
      return;
    }

    navigate({
      pathname: `/venue/${id}/details`,
      search: stringSearchData,
    });
  }, [
    dispatch,
    id,
    isQueryMultiRequest,
    navigate,
    shortList.items.length,
    stringSearchData,
  ]);

  const availableExtras = isMultiDay
    ? multiDayBookingAvailableExtras
    : simpleBookingAvailableExtras;

  const venueUnits = useMemo(() => {
    return unitsToBook?.filter((unit) => {
      return unitIds
        ? typeof unitIds === 'string' && unitIds?.includes(String(unit.id))
        : unit;
    });
  }, [unitIds, unitsToBook]);

  const isRfp = venueUnits?.some(({isRfp}) => isRfp);

  // clear store data on page leave
  useEffect(() => {
    return () => {
      dispatch(
        setBookingPreview({
          deskPreviewUnits: [],
          attendees: [],
        }),
      );

      dispatch(cleanCustomerSelectedPackages());
      dispatch(setIsRegistrationAction(false));
    };
  }, [dispatch]);

  const {isRedirectResult} = useRedirectResult();
  const isInitPreviewUnitsExist = !!previewDeskUnits.length;

  const guestBookingPreviewData =
    LocalStorageService.getByKey('bookingPreviewData');

  const parsedPreviewUnits = JSON.parse(guestBookingPreviewData as string);

  const guestPreviewUnits = parsedPreviewUnits
    ? parsedPreviewUnits?.previewUnits
    : null;
  // Setting array of default package values, initially customer has all packages turned off,
  // it will be [day:[[unitId: null]...]...] for multiDay
  useEffect(() => {
    if (!customerSelectedPackages && previewDeskUnits.length) {
      const defaultMultiDayCustomerPackages = previewDeskUnits.reduce(
        (prev, curr) => {
          const unitsMapByDay = curr.units.map(({unitId}) => [unitId, null]);
          return [...prev, unitsMapByDay];
        },
        [] as any,
      );

      dispatch(setCustomerSelectedPackages(defaultMultiDayCustomerPackages));
    }
  }, [dispatch, previewDeskUnits, customerSelectedPackages]);

  useEffect(() => {
    if (
      isRedirectResult ||
      isInitPreviewUnitsExist ||
      !venueUnits?.length ||
      isMeetingRoom
    )
      return;

    const initPreviewDeskUnitsData = makeInitPreviewDeskUnitsData({
      searchStartDate,
      searchEndDate,
      venueUnits: venueUnits as IUnit[],
      availableExtras:
        isOffer && !isSingleRegisteredVenue
          ? convertOfferExtrasToVenueExtras(extrasOption)
          : availableExtras,
      seatingPlan: seatingPlan as ERoomSchemaNames,
      roomType: roomType as ERoomType,
      participants,
    });

    dispatch(
      setBookingPreview({
        deskPreviewUnits: guestPreviewUnits
          ? guestPreviewUnits
          : initPreviewDeskUnitsData,
      }),
    );
  }, [
    availableExtras,
    dispatch,
    extrasOption,
    guestPreviewUnits,
    isInitPreviewUnitsExist,
    isMeetingRoom,
    isOffer,
    isRedirectResult,
    isSingleRegisteredVenue,
    participants,
    roomType,
    searchEndDate,
    searchStartDate,
    seatingPlan,
    venueUnits,
  ]);

  const formInitialValues = {
    participants,
    ...(isOffer ? {optionDate: ''} : {}),
  };

  return {
    isRfp,
    formInitialValues,
    onGoToVenueDetails,
  };
}
