import React from 'react';
import styled from 'styled-components';

import TextValue from 'view/components/TextValue';
import Icon from 'view/components/Icon';

import {offsetDef, offsetSm} from 'constants/styleVars';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${offsetDef} 0;

  > * + * {
    margin-left: ${offsetSm};
  }
`;

export function ProcessingPaymentBlock() {
  const isPaymentInProcess = useSelector((state) =>
    _get(state, 'payment.isPaymentInProcess'),
  );

  if (!isPaymentInProcess) return null;
  return (
    <Container>
      <Icon icon='NW2_SYNCHRONIZE_ARROWS' />
      <TextValue>Processing</TextValue>
    </Container>
  );
}
