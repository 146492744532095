import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {CoreOptions} from '@adyen/adyen-web/dist/types/core/types';
import _get from 'lodash/get';
import {useAppSelector} from 'store/hooks';

import NW2Button from 'view/components/NW2Button';

import {useAdyenCheckout} from 'view/components/AdyenPayment/hooks/useAdyenCheckout';
import {setAdyenComponent} from 'store/payment/paymentSlice';
import {TStoredCard} from 'store/payment/types';

import {
  ButtonGroup,
  PaymentCardContainer,
  PaymentContainer,
} from 'view/components/AdyenPayment/AdeynPayment.styles';
import '@adyen/adyen-web/dist/adyen.css';
import {pagePadding} from 'styles/configs';

type TProps = {
  adyenCoreOptions: CoreOptions;
  isUserLoggedIn?: boolean;
  isButtonGroup?: boolean;
  defaultCardDetails?: TStoredCard | null;
};

function AdyenCard({
  adyenCoreOptions,
  isUserLoggedIn,
  defaultCardDetails,
  isButtonGroup,
}: TProps) {
  const [isButtonsVisible, setIsButtonVisible] = useState(false);
  const paymentContainer = useRef(null);
  const dispatch = useDispatch();
  const {paymentMethodsResponse} = adyenCoreOptions;

  const adyenProps = {
    ...adyenCoreOptions,
    onChange(state: any, component: any) {
      if (state.data.riskData && isButtonGroup) {
        setIsButtonVisible(true);
      }
      dispatch(
        setAdyenComponent({
          submit: component.submit,
          errors: state.errors,
        }),
      );
    },
  };

  const response = useSelector((state) => _get(state, 'payment.action'));
  const isPaymentInProcess = useSelector((state) =>
    _get(state, 'payment.isPaymentInProcess'),
  );
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  const checkout = useAdyenCheckout(adyenProps);

  useEffect(() => {
    if (!paymentContainer.current || !checkout || !paymentMethodsResponse)
      return;

    const storedPaymentMethod = defaultCardDetails && {
      ...defaultCardDetails,
      storedPaymentMethodId: defaultCardDetails.id,
    };

    checkout
      .create('card', storedPaymentMethod)
      .mount(paymentContainer.current as unknown as HTMLElement);
  }, [checkout, paymentMethodsResponse, defaultCardDetails]);

  useEffect(() => {
    if (!checkout) return;
    checkout.update(adyenProps);

    // we dont need whole adyenProps object as a dependency
    // eslint-disable-next-line
  }, [isUserLoggedIn, paymentMethodsResponse, checkout, defaultCardDetails]);

  useEffect(() => {
    if (!response || !checkout || !response.action) return;

    checkout
      .createFromAction(response.action)
      .mount(paymentContainer.current as unknown as HTMLElement); // move to other adyen page 3D secure
  }, [response, checkout]);

  const onCancel = () => {
    if (checkout) checkout.update(adyenProps);
    setIsButtonVisible(false);
  };

  const saveButtonText = isMobile ? 'Save' : 'Save Changes';
  const onClick = () => {
    if (checkout) checkout.components[checkout.components.length - 1].submit();
  };

  return (
    <PaymentContainer className='payment-container' gap={pagePadding}>
      <PaymentCardContainer
        isVisible
        ref={paymentContainer}
        className='payment'
      />
      {isButtonGroup && isButtonsVisible && (
        <ButtonGroup>
          <NW2Button
            type='button'
            inline
            buttonType='secondary'
            onClick={onCancel}
            size='responsiveAdaptive'
            disabled={isPaymentInProcess}
          >
            Cancel
          </NW2Button>

          <NW2Button
            inline
            buttonType='primary'
            size='responsiveAdaptive'
            loading={isPaymentInProcess}
            onClick={onClick}
          >
            {isPaymentInProcess ? 'Loading...' : saveButtonText}
          </NW2Button>
        </ButtonGroup>
      )}
    </PaymentContainer>
  );
}

export default React.memo(AdyenCard);
