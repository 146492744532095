import React from 'react';
import styled from 'styled-components';

import Icon from 'view/components/Icon';
import {NW2SearchLoader} from '../../NW2SearchLoader/NW2SearchLoader';

import {TPrediction} from '../types';
import {useAppSelector} from 'store/hooks';

import {
  blackColor,
  borderColor,
  NW2Gray600Color,
  offsetXSm,
  offsetXXSm,
} from 'constants/styleVars';
import {IconContainer} from '../NW2GoogleMapSearchInput.styles';

type PropsType = {
  readonly prediction: TPrediction;
  readonly onClick: (prediction: any) => void;
};

const Container = styled.div`
  white-space: nowrap;
  overflow: hidden;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${borderColor};
  padding: ${offsetXSm} 0;

  &:hover,
  &:hover * {
    cursor: pointer;
  }
`;

const ContainerText = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const MainDescription = styled.span`
  color: ${blackColor};
  font-size: 13px;
  line-height: 22px;
`;

const SecondaryDescription = styled.span`
  margin-left: ${offsetXXSm};
  color: ${NW2Gray600Color};
  font-size: 11px;
  line-height: 21px;
`;

const Prediction = ({prediction, onClick}: PropsType) => {
  const {description} = prediction;

  const [mainText, ...rest] = description.split(',');
  const secondaryText = rest.join(',');

  const searchPredictionLoading = useAppSelector(
    ({search}) => search.searchPredictionLoading,
  );

  return (
    <Container onClick={onClick}>
      <ContainerText>
        <IconContainer>
          <Icon icon='MAP_PIN_STYLED' disabled size={13} />
        </IconContainer>
        <div>
          <MainDescription>{mainText}</MainDescription>
          <SecondaryDescription>{secondaryText}</SecondaryDescription>
        </div>
      </ContainerText>

      {searchPredictionLoading === prediction.place_id && <NW2SearchLoader />}
    </Container>
  );
};

export default Prediction;
