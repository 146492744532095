import React from 'react';
import {useSelector} from 'react-redux';
import {Form} from 'react-final-form';
import _get from 'lodash/get';

import NW2ModalDrawer from 'view/components/NW2ModalDrawer';
import AddModalForm from './AddModalForm';

import {modalDefaultWidth} from 'constants/styleVars';

type TProps = {
  isMobile: boolean;
  isShowed: boolean;
  onClose: () => void;
};

const initialState: Record<string, boolean> = {
  defaultMethod: true,
};

export function NW2AddPaymentMethodModalDrawer({
  isMobile,
  isShowed,
  onClose,
}: TProps) {
  const adyenComponent = useSelector((state) =>
    _get(state, 'payment.adyenComponent'),
  );

  const onSubmit = () => {
    if (adyenComponent && adyenComponent.submit) adyenComponent.submit();
  };

  const modalProps = {
    isMobile,
    isShowed,
    onClose,
    header: 'Add New Card',
  };

  return (
    <div>
      <NW2ModalDrawer
        {...modalProps}
        modalWidth={modalDefaultWidth} // per design
      >
        <Form onSubmit={onSubmit} initialValues={initialState}>
          {({handleSubmit}) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <AddModalForm onClose={onClose} />
              </form>
            );
          }}
        </Form>
      </NW2ModalDrawer>
    </div>
  );
}
