import React, {useMemo, useEffect} from 'react';

import AnchorMenu from 'view/components/SupplierLayout/components/AnchorMenu';
import DateUtils, {DateFormats} from 'utils/dateUtils';
import PrePostAccommodationBlock from 'view/venue/Offer/components/OfferRequestAccommodationBlock/PrePostAccommodationBlock';

import {Status} from './Status/Status';
import {getCancellationPolicyByInfo} from 'store/bookingsSupplier/actions';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {
  formatFloatWithOneNumber,
  getFilteredUnitsByEventType,
} from 'utils/venueUtils';
import {
  getArrayOfUnitsFromOrderDays,
  getBookingDetailsType,
  hiddenBillingAddressReasons,
} from '../helpers';
import {
  formatDate,
  getAddressStringFromBillingAddress,
  getCancellationPolicyStrings,
} from 'utils/stringUtils';
import {useBookingSections} from '../hooks/useBookingSections';
import {BookingDetailsUnit} from './BookingDetailsUnit';

import {
  Text,
  Container,
  MainTitle,
  Wrapper,
  DaysBlock,
  DayTitle,
  DayContent,
  UnitBlock,
  UnitDayPrice,
  DaySubTotal,
  DaySubTotalPrice,
  TotalSection,
  TotalTitle,
  TotalPrice,
  FeesText,
  // After units
  Section,
  SubTitle,
  StyledSubTitle,
  StyledText,
  StyledTextes,
  BoldSpan,
  MainSubtitle,
} from '../BookingDetails.styles';
import {MODAL_TWO_COLS_RIGHT_COL_ID} from 'constants/app';
import {
  EBookingDetailsSections,
  IBookingChanges,
  IUnitBookingDetails,
} from 'types/bookingOrders';
import {ICustomerBillingAddress} from 'types/dto/IUser.types';
import {EBookingStatus} from 'types/booking';
import {IOrderDay} from 'types/dto/IBooking.types';
import {EEventType} from 'types/venue';
import {ERequestDetailsSections} from 'types/offer';
import {offsetDef} from 'constants/styleVars';

type TProps = {
  billingAddressDto: ICustomerBillingAddress;
  unitBookingDtos: IUnitBookingDetails[];
  isRfp: boolean;
  resolvedAt: string;
  createdAt: string;
  currencySymbol?: string;
  totalPriceAndCurrency: string;
  bookingStatus: EBookingStatus;
  prevBookingStatus?: EBookingStatus;
  declinedReason: string | null;
  holdUp: string;
  refundAmount: number | null;
  declinedMessage: string | null;
  venueTimeZone: string;
  isRequestStatus: boolean;
  isMultiDay: boolean;
  isEdited: boolean;
  handleBookingChangeSidebarVisibility: () => void;
  isRTCPendingStatus: boolean;
  updatedAt: string;
  bookingChangesObj: Record<string, IBookingChanges>;
  orderDays: IOrderDay[];
};

const BookingDetailsInfo = ({
  billingAddressDto,
  unitBookingDtos,
  isRfp,
  resolvedAt,
  createdAt,
  currencySymbol,
  totalPriceAndCurrency,
  bookingStatus,
  declinedReason,
  holdUp,
  refundAmount,
  declinedMessage,
  venueTimeZone,
  isRequestStatus,
  isMultiDay,
  isEdited,
  handleBookingChangeSidebarVisibility,
  isRTCPendingStatus,
  prevBookingStatus,
  updatedAt,
  bookingChangesObj,
  orderDays,
}: TProps) => {
  const dispatch = useAppDispatch();
  const cancellationPolicyData = useAppSelector(
    (state) => state.bookingsSupplier.cancellationPolicy,
  );
  const cancellationPolicy = getCancellationPolicyStrings(
    cancellationPolicyData,
  );

  const filteredOrderDays = getFilteredUnitsByEventType(orderDays);
  const preArrivalData = getFilteredUnitsByEventType(
    orderDays,
    EEventType.PRE_ARRIVAL,
  );
  const postEventData = getFilteredUnitsByEventType(
    orderDays,
    EEventType.POST_EVENT,
  );

  const units = getArrayOfUnitsFromOrderDays(filteredOrderDays);

  const isRfpUnconfirmed = isRfp && bookingStatus !== EBookingStatus.CONFIRMED;
  const isSingle = Object.keys(units).length === 1;
  const maxParticipants = useMemo(
    () => Math.max(...unitBookingDtos.map((item) => item.maxParticipants)),
    [unitBookingDtos],
  );
  const isPreArrival = !!preArrivalData.length;
  const isPostEvent = !!postEventData.length;

  useEffect(() => {
    dispatch(
      getCancellationPolicyByInfo({
        maxParticipants,
        isRfp: isRequestStatus,
        isMultiDay,
      }),
    );
  }, [dispatch, isMultiDay, isRequestStatus, maxParticipants]);

  const bookingDetailsType = getBookingDetailsType(
    isSingle,
    isRfpUnconfirmed,
    bookingStatus,
  );

  const sections = useBookingSections({
    bookingDetailsType,
    units,
    preArrivalData,
    postEventData,
  }).filter(Boolean);

  const title = isRfpUnconfirmed
    ? EBookingDetailsSections.REQUEST_DETAILS
    : EBookingDetailsSections.BOOKING_DETAILS;

  const commonPropsForPrePostBlock = {
    isOffer: true,
    currencySymbol,
    titleMargin: `0 0 ${offsetDef}`,
  };

  return (
    <Container>
      <div>
        {bookingStatus !== EBookingStatus.RFP_PENDING && (
          <Section id={EBookingDetailsSections.STATUS}>
            <Status
              bookingStatus={bookingStatus}
              prevBookingStatus={prevBookingStatus}
              declinedReason={declinedReason}
              resolvedAt={resolvedAt}
              holdUp={holdUp}
              currencySymbol={currencySymbol}
              refundAmount={refundAmount}
              venueTimeZone={venueTimeZone}
              totalPriceAndCurrency={totalPriceAndCurrency}
              declinedMessage={declinedMessage}
              isEdited={isEdited}
              updatedAt={updatedAt}
              handleBookingChangeSidebarVisibility={
                handleBookingChangeSidebarVisibility
              }
              bookingChangesObj={bookingChangesObj}
            />
          </Section>
        )}

        <MainTitle>{title}</MainTitle>
        {isRTCPendingStatus && (
          <MainSubtitle>
            Confirmed on{' '}
            {DateUtils.getDateWithoutHours(
              createdAt,
              DateFormats['1 Jan, 2023'],
            )}
          </MainSubtitle>
        )}

        {isPreArrival && (
          <section
            key={ERequestDetailsSections.PRE_ARRIVAL}
            id={ERequestDetailsSections.PRE_ARRIVAL}
          >
            <PrePostAccommodationBlock
              bookingData={preArrivalData}
              {...commonPropsForPrePostBlock}
            />
          </section>
        )}

        {Object.keys(units).map((day, index) => {
          const date = formatDate(day, 'd MMM, YYYY', 'en-US');
          const dayId = `Day ${index + 1}`;
          const unitsByDay = units[day].units;
          const accommodationsPerDay = units[day].accommodations;
          const foodAndBeverageExtras = units[day].foodAndBeverage;
          const unitsLen = unitsByDay.length;
          const isUnitSingle = unitsLen === 1;

          const updatedUnitsByDay = unitsByDay.map((unit) => {
            return {
              ...unit,
              foodAndBeverage: foodAndBeverageExtras,
              accommodations: accommodationsPerDay,
            };
          });

          return (
            <section key={`${day} ${index}`} id={isSingle ? title : ''}>
              <Wrapper>
                <DaysBlock id={dayId}>
                  <DayTitle>
                    {isPreArrival || isPostEvent || !isSingle
                      ? dayId
                      : 'Details'}
                  </DayTitle>
                  <DayContent>{date}</DayContent>
                </DaysBlock>

                <UnitBlock>
                  {updatedUnitsByDay.map((unit, idx: number) => (
                    <BookingDetailsUnit
                      key={`${unit.unitId} ${idx}`}
                      unit={unit as IUnitBookingDetails}
                      index={idx}
                      currencySymbol={currencySymbol}
                      isUnitSingle={isUnitSingle}
                      isUnitLast={idx === unitsLen - 1}
                    />
                  ))}

                  <UnitDayPrice>
                    <DaySubTotal>Day sub-total:</DaySubTotal>
                    <DaySubTotalPrice>
                      {formatFloatWithOneNumber(units[day].daySubTotal)}
                      &nbsp; {currencySymbol}
                    </DaySubTotalPrice>
                  </UnitDayPrice>
                </UnitBlock>
              </Wrapper>
            </section>
          );
        })}

        {isPostEvent && (
          <section
            key={ERequestDetailsSections.POST_EVENT}
            id={ERequestDetailsSections.POST_EVENT}
          >
            <PrePostAccommodationBlock
              bookingData={postEventData}
              {...commonPropsForPrePostBlock}
            />
          </section>
        )}

        <TotalSection>
          <TotalTitle>
            {isRfpUnconfirmed ? 'Request total' : 'Booking total'}
          </TotalTitle>
          <div>
            <TotalPrice>{totalPriceAndCurrency}</TotalPrice>
            <FeesText>Includes taxes and fees</FeesText>
          </div>
        </TotalSection>
        {(!isRfp || bookingStatus === EBookingStatus.CONFIRMED) && (
          <Section id={EBookingDetailsSections.CONFIRMATION_DETAILS}>
            <MainTitle>Confirmation details</MainTitle>
            <StyledText>
              <BoldSpan>Booking confirmed on:</BoldSpan>{' '}
              {/* resolvedAt is null for non rfp */}
              {DateUtils.getFormattedDateTime(
                resolvedAt || createdAt,
                venueTimeZone,
                DateFormats["22/05/1997 'at' 22:22 (+02:00)"],
              )}
            </StyledText>
            <Text>
              All instant bookings get confirmed immediately on behalf of the
              venue.
            </Text>
          </Section>
        )}
        <Section id={EBookingDetailsSections.PAYMENT_INFORMATION}>
          <MainTitle>Payment information</MainTitle>
          <SubTitle>Arrange payment directly with the booker</SubTitle>
          <Text>
            We defer to the venue to arrange a payment method directly with the
            booker. Once this process is finalised, you may utilise the provided
            billing address to generate an invoice.
          </Text>
        </Section>
        <Section id={EBookingDetailsSections.BILLING_ADDRESS}>
          <MainTitle>Billing address</MainTitle>
          {isRfp && bookingStatus !== EBookingStatus.CONFIRMED ? (
            <>
              <SubTitle>Why can’t I see billing address?</SubTitle>
              <Text>{hiddenBillingAddressReasons[bookingStatus]}</Text>
            </>
          ) : (
            <>
              <Text>
                As bookings are concluded between the booker and the venue,
                please provide an invoice for all booked services - as part of
                this confirmation plus all additional services booked on-site -
                to the booker.
              </Text>
              <StyledSubTitle>
                Use the following address to generate an invoice:
              </StyledSubTitle>
              <StyledText>
                {getAddressStringFromBillingAddress(billingAddressDto)}
              </StyledText>
              <StyledText>
                Cost center: {billingAddressDto?.costCenter || '-'}
              </StyledText>
              <StyledText>
                Additional note: {billingAddressDto?.additionalReference || '-'}
              </StyledText>
            </>
          )}
        </Section>
        <Section id={EBookingDetailsSections.TERMS_AND_CONDITIONS}>
          <MainTitle>Terms & Conditions</MainTitle>
          <SubTitle>How is booker charged?</SubTitle>
          <StyledTextes>
            We defer to the venue to arrange a payment method directly with the
            booker. Once this process is finalised, you may utilise the provided
            billing address to generate an invoice.
          </StyledTextes>
          <SubTitle>What happens if the booker cancels?</SubTitle>
          <StyledText>
            If the booker cancels within their free-of-charge booking period
            they will not be charged.
          </StyledText>
          <StyledTextes>
            If the booker cancels outside of their free-of-charge booking
            period, you may utilise the provided billing address to generate an
            invoice.
          </StyledTextes>
          <SubTitle>What is the cancellation policy?</SubTitle>
          <StyledTextes>
            {cancellationPolicy.mainText}
            <b> {cancellationPolicy.extraText}</b>
          </StyledTextes>
          <SubTitle>What if there are additional charges?</SubTitle>
          <StyledTextes>
            Only extras which are not part of this booking can, and should be,
            charged on-site.
          </StyledTextes>
        </Section>
      </div>

      <AnchorMenu
        rootId={MODAL_TWO_COLS_RIGHT_COL_ID}
        sections={sections as string[]}
      />
    </Container>
  );
};

export default BookingDetailsInfo;
