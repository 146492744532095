import React from 'react';
import {useNavigate} from 'react-router-dom';

import NW2Button from 'view/components/NW2Button';

import {customerMenu} from '../menuConfigs';
import {
  GeneralMenuItem,
  GeneralMenu,
  MenuIcon,
} from 'view/common/Header/Header.styles';
import {Routes} from 'constants/routes';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import {NavItem} from '../types';

type TProps = {
  currentPath: string;
  role: EUserRoleCognito;
};

function CustomerNavMenuList({currentPath, role}: TProps) {
  const navigate = useNavigate();

  const isCustomer = role === EUserRoleCognito.ROLE_CUSTOMER;

  const onClick = (url: string) => () => {
    !!url && navigate(url);
  };

  const isItemVisible = (url: string) => {
    return url === Routes.customerBookings && isCustomer;
  };

  const visibleItems = customerMenu.navList?.filter((item: NavItem) => {
    return isItemVisible(item.url);
  });

  return (
    <GeneralMenu>
      {visibleItems?.map(({icon, url, text}) => (
        <GeneralMenuItem key={url} onClick={onClick(url)}>
          {!!icon && <MenuIcon icon={icon} size={24} />}
          <NW2Button buttonType='link' active={currentPath === url}>
            {text}
          </NW2Button>
        </GeneralMenuItem>
      ))}
    </GeneralMenu>
  );
}

export default CustomerNavMenuList;
