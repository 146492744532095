import React, {useMemo} from 'react';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';

import DateUtils, {DateFormats} from 'utils/dateUtils';
import TruncateText from 'view/components/TruncateText';
import PreviewExtrasList from 'view/venue/components/PreviewExtrasList';

import {SpaceImage} from '../../../NW2BookingPreview/components/Space/components/SpaceImage';

import {pluralize} from 'utils/helpers';
import {useAppSelector} from 'store/hooks';
import {TextValue} from 'view/components/TextValue';
import {
  fontSizeMd,
  offsetNone,
  offsetXSm,
  offsetXXLg,
  offsetXXSm,
} from 'constants/styleVars';
import {Group} from 'styles/basicStyledComponents/group';
import {
  ExtrasWrapper,
  MainTitle,
  PreviewExtraList,
  TimeBlock,
  UnitContainer,
  UnitContainerTitle,
  UnitText,
  UnitTitle,
} from './OfferRequestReviewUnits.styles';
import {ERoomSchema} from 'types/venue';
import {UnitWrapper} from '../../Offer.styles';
import {convertOfferExtrasToVenueExtras} from '../../helpers';
import {useIsAlternativeOffer} from 'view/venue/hooks/useIsAlternativeOffer';
import {IOfferDayItem} from 'types/offer';
import {getFilteredUnitsByEventType} from 'utils/venueUtils';
import {CODE_TO_EXTRA_NAME} from 'constants/extras';
import {useGroupRequest} from 'view/venue/hooks/useGroupRequest';
import {StyledLeftSideItem} from 'view/venue/NW2BookingPreview/components/Space/Space.styles';
import {useOfferRequestDetails} from '../../hooks/useOfferRequestDetails';
import {capitalizeText} from 'utils/stringUtils';
import {EResourcesCode} from 'types/dto/IExtras.type';

interface IProps {
  isOffer?: boolean;
  isGroupRequestPage?: boolean;
  hasPreArrivals?: boolean;
  hasPostEvents?: boolean;
}

export function OfferRequestReviewUnits({
  isOffer,
  isGroupRequestPage,
  hasPreArrivals,
  hasPostEvents,
}: IProps) {
  const {isAlternative} = useIsAlternativeOffer();
  const {isSingleRegisteredVenue} = useGroupRequest();

  const isDesktop = useAppSelector(({app}) => app.deviceType.isDesktop);
  const extrasOption = useAppSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );

  const {offerDetails} = useOfferRequestDetails(!isGroupRequestPage);

  const meetingDays = useMemo(
    () => getFilteredUnitsByEventType(offerDetails.days),
    [offerDetails.days],
  );

  const venueUnits = useMemo(
    () => meetingDays.flatMap(({items}) => items),
    [meetingDays],
  );

  const extrasOptionMap = useMemo(() => {
    /**
     * we need to convert all common extras to IExtrasResponse for using them by ExtrasList
     * in the offer request flow
     */
    return convertOfferExtrasToVenueExtras(extrasOption);
  }, [extrasOption]);

  const isMultiDay = meetingDays.length > 1;

  // const openSpaceDetails = (unitId?: number) => {
  //   if (!unitId) return;
  //   dispatch(setSpaceDetailsId(unitId));
  // };

  return (
    <>
      {meetingDays.map(
        ({checkIn, checkOut, bedrooms, items, foodAndBeverage}, index) => {
          const sortedBedrooms = _orderBy(bedrooms, ['code'], 'desc');
          const multiBookingTitle = (
            <>
              <span>Day {index + 1}</span>
              <TimeBlock>
                <TextValue style={{textTransform: 'none'}}>
                  {DateUtils.getDateWithoutHours(
                    checkIn,
                    DateFormats['1 Jan 2023'],
                  )}
                </TextValue>
                <TextValue style={{textTransform: 'none'}}>
                  ({DateUtils.getHoursAndMinutes(checkIn)} -{' '}
                  {DateUtils.getHoursAndMinutes(checkOut)})
                </TextValue>
              </TimeBlock>
            </>
          );

          return (
            <StyledLeftSideItem
              key={String(checkIn) + index}
              paddingTop={
                hasPreArrivals && index === 0 ? '32px !important' : undefined
              }
              paddingBottom={
                !hasPostEvents && index === meetingDays.length - 1
                  ? undefined
                  : `${offsetXXLg} !important`
              }
              isNoBorderTop={hasPreArrivals}
              item={{
                title: isMultiDay ? multiBookingTitle : 'Your room',
                children: (
                  <UnitWrapper>
                    {items?.map((unit, idx, array) => {
                      const {unitInfo, unitId, extras} = unit;

                      const roomCounter =
                        array.length > 1 ? (idx + 1).toString() : '';

                      const name = venueUnits?.find(
                        (item) => Number(item?.id) === unitId,
                      )?.unitInfo?.unitName;

                      const unitTitle = `Meeting room ${roomCounter}`;

                      return (
                        <ExtrasWrapper
                          key={`unit-${idx}`}
                          isDesktop={isDesktop}
                        >
                          <UnitContainerTitle>
                            {capitalizeText(unitTitle)}
                          </UnitContainerTitle>
                          <UnitContainer>
                            <SpaceImage
                              images={unitInfo?.documents}
                              isPlaceholder={
                                isGroupRequestPage
                                  ? !isSingleRegisteredVenue
                                  : false
                              }
                            />

                            <div>
                              {isOffer ||
                              (!isAlternative &&
                                (isSingleRegisteredVenue ||
                                  !isGroupRequestPage)) ? (
                                <TextValue
                                  // onClick={() => openSpaceDetails(unitId)}
                                  fontSize={fontSizeMd}
                                  bold
                                  marginTop={offsetNone}
                                  marginBottom={offsetXSm}
                                >
                                  {/*<Link>*/}
                                  <TruncateText
                                    text={
                                      unitInfo
                                        ? unitInfo.unitName
                                        : name || 'Meeting room ' + roomCounter
                                    }
                                    hideToggler
                                  />
                                  {/*</Link>*/}
                                </TextValue>
                              ) : (
                                <UnitTitle>{`${
                                  ERoomSchema[unit.setupStyle]
                                } Meeting room`}</UnitTitle>
                              )}

                              <Group direction='column' gap={offsetXXSm}>
                                <UnitText>
                                  Time:{' '}
                                  <b>
                                    {DateUtils.getHoursAndMinutes(unit.checkIn)}{' '}
                                    -
                                    {DateUtils.getHoursAndMinutes(
                                      unit.checkOut,
                                    )}
                                  </b>
                                </UnitText>
                                <UnitText>
                                  {isOffer ? 'Room setup:' : 'Setup style:'}{' '}
                                  <b>{ERoomSchema[unit.setupStyle]}</b>
                                </UnitText>
                                <UnitText>
                                  Participants:{' '}
                                  <b>
                                    {pluralize(
                                      'people',
                                      unit.participants || 0,
                                    )}
                                    {isOffer &&
                                    (unit as IOfferDayItem).maxParticipants
                                      ? ` (${
                                          (unit as IOfferDayItem)
                                            .maxParticipants
                                        } max)`
                                      : ''}
                                  </b>
                                </UnitText>
                              </Group>
                            </div>
                          </UnitContainer>

                          {!!extras?.length && (
                            <PreviewExtraList>
                              {extras.map(({code}, idx) => (
                                <span key={code}>
                                  {!!idx && <> · </>}
                                  {CODE_TO_EXTRA_NAME[code as EResourcesCode]}
                                </span>
                              ))}
                            </PreviewExtraList>
                          )}
                        </ExtrasWrapper>
                      );
                    })}

                    {!!foodAndBeverage?.length && (
                      <>
                        <MainTitle>Food & Beverage</MainTitle>
                        <PreviewExtrasList
                          extras={foodAndBeverage}
                          extrasOptions={extrasOption}
                        />
                      </>
                    )}

                    {!!sortedBedrooms.length && (
                      <>
                        <MainTitle>Accommodation</MainTitle>
                        <PreviewExtrasList
                          extras={sortedBedrooms}
                          extrasOptions={extrasOptionMap}
                        />
                      </>
                    )}
                  </UnitWrapper>
                ),
              }}
            />
          );
        },
      )}
    </>
  );
}
