import styled from 'styled-components';
import {offsetSm, pagePadding} from 'styles/configs/offset';

const FormInnerContainer = styled.div<{isGrid?: boolean}>`
  ${({isGrid}) =>
    isGrid &&
    `
    display: grid;
    grid-template-columns: 1fr ${pagePadding};
    grid-gap: ${offsetSm};
  `}

  > div {
    margin: 0;
  }
`;

FormInnerContainer.defaultProps = {
  isGrid: true,
};

export {FormInnerContainer};
