import {useCallback, useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import {useAppDispatch, useAppSelector} from 'store/hooks';
import {getMultiVenueDetailsMultiRequest} from 'store/venues/actions';
import {useActions} from 'hooks/useActions';
import {ERoomType} from 'types/dto/ERoomType.type';
import useSearchData from '../hooks/useSearchData';
import {makeMultiSearchPayload} from '../../components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/utils';
import {EResourcesType, IExtrasOption} from 'types/dto/IExtras.type';
import {
  setMeetingRequestData,
  setMultiSearchPayload,
  setSearchCriteria,
} from 'store/search/searchSlice';
import {useCommonExtras} from '../hooks/useCommonExtras';
import {TDay, TSearchVenuesDay} from 'types/search';
import {ITimeData} from 'types/dto/ISearch.types';
import LocalStorageService from 'infra/common/localStorage.service';
import {useSelectedRoomId} from '../hooks/useSelectedRoomId';
import {ERoomSchemaNames} from 'types/venue';
import {useQuery} from 'hooks/useQuery';
import {getVenueDetailsWorkDesksAction} from 'store/workDesks/apiActions';

export function useMultiDayVenueDetailsData(
  venueId: number | string,
  withoutPackages?: boolean,
) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {getVenueDetails, setVenueDetails} = useActions();

  const venueDetails = useAppSelector(({venueDetails}) => venueDetails.details);
  const isLoading = useAppSelector(({venueDetails}) => venueDetails.isLoading);
  const isWorkSpaceSearchLoading: boolean = useSelector((state) =>
    _get(state, 'workDesks.workDesksDetails.isLoading'),
  );
  const venueDetailsWorkDeskId: number = useSelector((state) =>
    _get(state, 'workDesks.workDesksDetails.id', 0),
  );
  const error = useAppSelector(({venueDetails}) => venueDetails.error);
  const filterData = useAppSelector(({search}) => search.multiSearchPayload);
  const searchCriteria = useAppSelector(({search}) => search.searchCriteria);
  const meetingRequestData = useAppSelector(
    ({search}) => search.meetingRequestData,
  );
  const roomType = useAppSelector(({search}) => search.searchCriteria.roomType);

  const {
    initialSearchData,
    querySearchData,
    bedroomsCatering,
    searchData,
    initialTimeData,
  } = useSearchData();

  const multiSearchData = LocalStorageService.getByKey('multiSearchData');
  const parsedMultiSearchData = useMemo(
    (): {meetingRequestData: TDay[]; timeData: ITimeData[]} =>
      multiSearchData ? JSON.parse(multiSearchData) : {},
    [multiSearchData],
  );

  const parsedMeetingRequestData = parsedMultiSearchData.meetingRequestData;

  useEffect(() => {
    if (!searchCriteria.searchString && searchData.searchString) {
      dispatch(setSearchCriteria(searchData));
    }
  }, [dispatch, searchCriteria.searchString, searchData]);

  useEffect(() => {
    if (!meetingRequestData?.length) {
      dispatch(setMeetingRequestData(parsedMeetingRequestData));
    }
  }, [dispatch, meetingRequestData?.length, parsedMeetingRequestData]);

  const fetchVenueDetailsWorkDesks = useCallback(() => {
    // use search params either from query, or from redux store
    const id = Number(venueId);

    const data = {
      id,
      ...querySearchData,
    };

    dispatch(getVenueDetailsWorkDesksAction(data));
  }, [dispatch, querySearchData, venueId]);

  const isWorkSpace = roomType === ERoomType.WORK_SPACE;

  useEffect(() => {
    if (isWorkSpace && !isWorkSpaceSearchLoading && !venueDetailsWorkDeskId) {
      fetchVenueDetailsWorkDesks();
    }
  }, [
    fetchVenueDetailsWorkDesks,
    isWorkSpace,
    isWorkSpaceSearchLoading,
    venueDetailsWorkDeskId,
    venueId,
  ]);
  // WORD DESK STUFF END

  const extrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );

  const filteredBedroomExtras = useMemo(
    () => extrasOption.filter((item) => item.type === EResourcesType.BEDROOM),
    [extrasOption],
  );

  useCommonExtras();

  const [getSelectedVenueUnitId] = useSelectedRoomId();

  const {meetingRoomCapacity, seatingPlan} = useQuery();

  useEffect(() => {
    if (isWorkSpace || isLoading || error || !venueId) return;

    const accommodationIds =
      typeof venueId === 'string' && venueId.includes(',')
        ? venueId.split(',').map((id) => Number(id))
        : [Number(venueId)];

    if (
      extrasOption.length &&
      (!venueDetails || accommodationIds[0] !== venueDetails?.accommodationId)
    ) {
      let multiSearchPayload: TSearchVenuesDay[] = [];

      if (!filterData.length) {
        const initialMeetingRequestData = initialTimeData.map(
          ({timeStart, timeEnd}) => ({
            startDate: timeStart,
            endDate: timeEnd,
            rooms: [
              {
                timeStart,
                timeEnd,
                participants: meetingRoomCapacity || 1,
                equipmentData: {},
                seatsSetup: seatingPlan || ERoomSchemaNames.BLOCK,
              },
            ],
            foodBeverageData: null,
            accommodationData: null,
          }),
        ) as TDay[];

        multiSearchPayload = makeMultiSearchPayload({
          meetingRequestData: initialSearchData.meetingRequestData.length
            ? initialSearchData.meetingRequestData
            : initialMeetingRequestData,
          bedroomsCatering,
          filteredBedroomExtras,
        }).multiSearchPayload as TSearchVenuesDay[];

        dispatch(setMultiSearchPayload(multiSearchPayload));
      }

      const parsedFilterData = filterData.length
        ? filterData
        : multiSearchPayload;

      if (accommodationIds.length === 1) {
        getVenueDetails({
          payload: {
            accommodationIds: [Number(venueId)],
            filterData: parsedFilterData.map((item) => ({
              ...item,
              roomFilters: item.roomFilters?.map((room) => {
                const selectedUnitId = getSelectedVenueUnitId({
                  venueId: Number(venueId),
                  checkIn: room.checkIn,
                });

                return {
                  ...room,
                  ...(selectedUnitId ? {unitId: selectedUnitId} : {}),
                };
              }),
            })),
            withoutPackages,
          },
          onErrorCallback() {
            navigate({
              pathname: '/',
            });
          },
        });
      } else {
        if (!filterData.length) return;
        const multiData = accommodationIds.map((id) => ({
          accommodationIds: [id],
          filterData: parsedFilterData,
          withoutPackages,
        }));

        dispatch(getMultiVenueDetailsMultiRequest(multiData));
      }
    }
  }, [
    bedroomsCatering,
    dispatch,
    error,
    extrasOption.length,
    filterData,
    filteredBedroomExtras,
    getSelectedVenueUnitId,
    getVenueDetails,
    initialSearchData.meetingRequestData,
    initialTimeData,
    isLoading,
    isWorkSpace,
    meetingRoomCapacity,
    navigate,
    seatingPlan,
    venueDetails,
    venueId,
    withoutPackages,
  ]);

  useEffect(() => {
    return () => {
      setVenueDetails(null);
    };
  }, [setVenueDetails]);
}
