import {notification} from 'antd';

import {
  clearPayment,
  removePaymentMethod,
  setDefaultCreditCardId,
  setIsPaymentInProcess,
  setIsPaymentMethodAdded,
  setPaymentAction,
  setPaymentsMethod,
} from './paymentSlice';
import {TContainer} from 'app';
import LocalStorageService from 'infra/common/localStorage.service';
import {findDefaultCreditCard} from 'view/components/AdyenPayment/helpers';
import {AppDispatch, RootState} from 'store/types';
import {EAdyenCardAction, TDeletePaymentMethodPayload} from './types';

export const getPaymentsMethod =
  (userId: string | number) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {paymentContainer}: TContainer,
  ) => {
    if (userId && userId !== '0') {
      await paymentContainer.getPaymentMethods({
        payload: userId,
        onSuccess: (data: any) => {
          dispatch(setPaymentsMethod(data));
          const defaultCard = findDefaultCreditCard(
            data.storedPaymentMethods,
            '',
            String(userId),
          );
          if (defaultCard) dispatch(setDefaultCreditCardId(defaultCard.uuid));
        },
        onError: () => {},
      });
    } else {
      await paymentContainer.getUnregisteredPaymentMethods({
        onSuccess: (data: any) => {
          dispatch(setPaymentsMethod(data));
        },
        onError: () => {},
      });
    }
  };

export const setDefaultCreditCard =
  (creditCardId: string) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {paymentContainer}: TContainer,
  ) => {
    const msg = 'Your default payment method was successfully changed.';
    const customerId = getState().app.user.id;
    dispatch(setIsPaymentInProcess(true));
    await paymentContainer.postDefaultPaymentMethod({
      payload: {creditCardId, customerId},
      onSuccess: () => {
        dispatch(setDefaultCreditCardId(creditCardId));
        notification.success({
          key: msg,
          message: msg,
        });
        dispatch(setIsPaymentInProcess(false));
      },
      onError: () => {
        dispatch(setIsPaymentInProcess(false));
      },
    });
  };

export const deletePaymentMethod =
  (payload: TDeletePaymentMethodPayload) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {paymentContainer}: TContainer,
  ) => {
    const msg = 'Your payment method was successfully deleted.';
    dispatch(setIsPaymentInProcess(true));
    await paymentContainer.deletePaymentMethod({
      payload,
      onSuccess: () => {
        dispatch(removePaymentMethod(payload.creditCardId));
        notification.success({
          key: msg,
          message: msg,
        });
      },
      onError: () => {
        dispatch(setIsPaymentInProcess(false));
      },
    });
  };

export const postNewPaymentMethod =
  (payload: Record<string, any>) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {paymentContainer}: TContainer,
  ) => {
    const msg = 'Your payment method was successfully added.';
    dispatch(setIsPaymentInProcess(true));
    await paymentContainer.postNewPaymentMethod({
      payload,
      onSuccess: (response: any) => {
        if (response && response.action) {
          LocalStorageService.setByKey('AdyenCardAction', EAdyenCardAction.ADD);
          dispatch(setPaymentAction(response));
        } else {
          dispatch(getPaymentsMethod(payload.customerId));
          dispatch(setIsPaymentMethodAdded(true));
          notification.success({key: msg, message: msg});
        }
      },
      onError: (error: any) => {
        dispatch(setIsPaymentInProcess(false));

        if (error.data.remoteResponse)
          dispatch(setPaymentAction(error.data.remoteResponse));

        payload.component.setStatus('ready');
        dispatch(clearPayment());
      },
    });
  };
