import React from 'react';

import {ERoomType} from 'types/dto/ERoomType.type';
import {Container, StyledIcon, Tab} from './Tabs.styles';

type TProps = {
  minimized: boolean;
  onRoomTypeChange: (data: ERoomType) => void;
  roomTypeValue: ERoomType;
};

function Tabs({minimized, onRoomTypeChange, roomTypeValue}: TProps) {
  const tabsData = [
    {
      icon: <StyledIcon icon='SEARCH_MEETING_ROOM' />,
      label: 'Meeting rooms',
      value: ERoomType.MEETING_ROOM,
    },
    {
      icon: <StyledIcon icon='SEARCH_WORK_DESK' />,
      label: 'Work desks',
      value: ERoomType.WORK_SPACE,
    },
  ];

  const onSelectTabHandler = (e: any) => {
    const value = e.target.value;
    if (value !== roomTypeValue) {
      onRoomTypeChange(value);
    }
  };

  if (minimized) return null;

  return (
    <Container>
      {tabsData.map(({icon, label, value}) => {
        return (
          <Tab key={value}>
            <input
              type='radio'
              name='mode'
              id={value}
              value={value}
              onChange={onSelectTabHandler}
              checked={roomTypeValue === value}
            />
            <label htmlFor={value}>
              {icon}
              <span>{label}</span>
            </label>
          </Tab>
        );
      })}
    </Container>
  );
}

export default Tabs;
