import React, {useState, useEffect} from 'react';
import _isEqual from 'lodash/isEqual';

import NW2Switch from 'view/components/NW2Switch';
import NMMSubmitSection from 'view/components/NMMSubmitSection';
import OpenHoursRow from './OpenHoursRow';
import SameEverydayForm from './SameEverydayForm';

import useHoursState from '../hooks/useHoursState';
import useInitialState from '../hooks/useInitialState';
import {SwitchContainer} from './OpenHoursForm.styles';
import {EDays} from 'constants/days';
import {
  DEFAULT_OPEN_FROM,
  DEFAULT_OPEN_TO,
  DEFAULT_OPEN_FROM_HOUR,
  DEFAULT_OPEN_FROM_MINUTE,
  DEFAULT_OPEN_TO_HOUR,
  DEFAULT_OPEN_TO_MINUTE,
  WHOLE_DAY_OPEN_FROM_HOUR,
  WHOLE_DAY_OPEN_FROM_MINUTE,
  WHOLE_DAY_OPEN_TO_HOUR,
  WHOLE_DAY_OPEN_TO_MINUTE,
} from 'constants/NW2OpenHours';
import {IDay} from 'types/dto/IDay.type';
import {IVenue} from 'types/venue';
import {defaultTimeSet} from 'constants/defaultOperationalTimes';
import {setOperationalTimes} from 'store/venueDetails/apiActions';
import {useAppDispatch, useAppSelector} from 'store/hooks';

interface Props {
  venue: IVenue;
  workDays: IDay[];
  onEnd: () => void;
}

const OperationalHoursForm = ({workDays, venue, onEnd}: Props) => {
  const [isFormDirty, setFormDirty] = useState(true);

  const {operationalTimesLoading: loading} = useAppSelector(
    ({venueDetails}) => venueDetails,
  );

  const dispatch = useAppDispatch();

  const {
    operationalTimes,
    setLocalOperationalTimes,
    getDayFromState,
    getValueFromState,
    updateField,
    updateMultipleFields,
  } = useHoursState(workDays);

  const {
    initiallySameEveryday,
    initial24HoursAccess,
    initialOpenFrom,
    initialOpenTo,
  } = useInitialState(operationalTimes);

  const [sameEveryday, setSameEveryday] = useState(initiallySameEveryday);
  const [wholeDayAccess, setWholeDayAccess] = useState(initial24HoursAccess);
  const [openFrom, setOpenFrom] = useState(initialOpenFrom);
  const [openTo, setOpenTo] = useState(initialOpenTo);

  const handleSameEverydayClick = () => {
    const openFromValues = openFrom.split(':');
    const openToValues = openTo.split(':');

    if (!sameEveryday) {
      const updatedState = operationalTimes.map((day) => {
        if (day.active) {
          return {
            ...day,
            timeStartHour: openFromValues[0],
            timeStartMinutes: openFromValues[1],
            timeEndHour: openToValues[0],
            timeEndMinutes: openToValues[1],
          };
        }

        return day;
      });

      setLocalOperationalTimes(updatedState);
    }

    setSameEveryday(!sameEveryday);
  };

  const updateActiveDaysOpeningTime = (
    value: string,
    status: 'open' | 'close',
  ) => {
    const times = value.split(':');

    const updatedState = operationalTimes.map((day) => {
      if (status === 'open') {
        return {
          ...day,
          timeStartHour: times[0],
          timeStartMinutes: times[1],
        };
      }

      if (status === 'close') {
        return {
          ...day,
          timeEndHour: times[0],
          timeEndMinutes: times[1],
        };
      }

      return day;
    });

    setLocalOperationalTimes(updatedState);
  };

  const handle24HourClick = () => {
    const updatedState = operationalTimes.map((day) => {
      if (day.active) {
        return {
          ...day,
          timeStartHour: wholeDayAccess
            ? DEFAULT_OPEN_FROM_HOUR
            : WHOLE_DAY_OPEN_FROM_HOUR,
          timeStartMinutes: wholeDayAccess
            ? DEFAULT_OPEN_FROM_MINUTE
            : WHOLE_DAY_OPEN_FROM_MINUTE,
          timeEndHour: wholeDayAccess
            ? DEFAULT_OPEN_TO_HOUR
            : WHOLE_DAY_OPEN_TO_HOUR,
          timeEndMinutes: wholeDayAccess
            ? DEFAULT_OPEN_TO_MINUTE
            : WHOLE_DAY_OPEN_TO_MINUTE,
        };
      }

      return day;
    });

    if (wholeDayAccess) {
      setOpenFrom(DEFAULT_OPEN_FROM);
      setOpenTo(DEFAULT_OPEN_TO);
    }

    setLocalOperationalTimes(updatedState);
    setWholeDayAccess(!wholeDayAccess);
  };

  const handleOpenFromChange = (value: string) => {
    setOpenFrom(value);
    updateActiveDaysOpeningTime(value, 'open');
  };

  const handleOpenToChange = (value: string) => {
    setOpenTo(value);
    updateActiveDaysOpeningTime(value, 'close');
  };

  const onSubmit = () => {
    const isAllDaysDisabled = operationalTimes.every(({active}) => !active);

    const updatedOperationalTimes =
      initiallySameEveryday && isAllDaysDisabled
        ? operationalTimes.map((item) => ({
            ...item,
            ...defaultTimeSet,
          }))
        : operationalTimes;

    dispatch(setOperationalTimes(venue.id, updatedOperationalTimes, onEnd));
  };

  useEffect(() => {
    const isEqual = _isEqual(workDays, operationalTimes);
    setFormDirty(isEqual);
  }, [workDays, operationalTimes]);

  return (
    <>
      <SwitchContainer>
        <NW2Switch
          label='Same everyday'
          isActive={sameEveryday}
          disabled={loading}
          onClick={handleSameEverydayClick}
        />
      </SwitchContainer>

      {sameEveryday ? (
        <SameEverydayForm
          wholeDayAccess={wholeDayAccess}
          openFrom={openFrom}
          openTo={openTo}
          isLoading={loading}
          getValueFromState={getValueFromState}
          updateField={updateField}
          handle24HourClick={handle24HourClick}
          handleOpenFromChange={handleOpenFromChange}
          handleOpenToChange={handleOpenToChange}
        />
      ) : (
        <div>
          {Object.values(EDays).map((day) => (
            <OpenHoursRow
              key={day}
              operationalTime={getDayFromState(day)}
              name={day}
              isLoading={loading}
              onBadgeClick={() =>
                updateField(day, 'active', !getValueFromState(day, 'active'))
              }
              updateFields={updateMultipleFields}
            />
          ))}
        </div>
      )}

      <NMMSubmitSection
        isLoading={loading}
        disabled={isFormDirty}
        size='small'
        submitButtonType='button'
        handleCancel={onEnd}
        handleSubmit={onSubmit}
      />
    </>
  );
};

export default OperationalHoursForm;
