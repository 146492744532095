import React, {FC} from 'react';
import styled from 'styled-components';
import {Form, Input, Button, FormInstance, InputNumber} from 'antd';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';

import {
  COMPANY_ID_FIELD_NAME,
  COMPANY_NAME_FIELD_NAME,
  EMAIL_DOMAINS_FIELD_NAME,
} from 'constants/admin';
import {validateDomain} from 'utils/formFieldValidators';

const layout = {
  labelCol: {span: 8},
  wrapperCol: {span: 16},
};

const buttonWrapperCol = {span: 24};

type Props = {
  form: FormInstance;
  isEdit?: boolean;
};

const initialValue = ['']; // initially show one email_domain field

const FullWidthInputNumber = styled(InputNumber)`
  width: 100%;
`;

const AdminAddCompany: FC<Props> = ({form, isEdit}) => {
  return (
    <Form autoComplete='off' form={form} preserve={false} {...layout}>
      <Form.Item
        label='Company ID'
        name={COMPANY_ID_FIELD_NAME}
        rules={[
          {required: true, message: 'Please input company ID!'},
          {
            type: 'number',
            min: 1,
            max: 2147483647,
          },
        ]}
      >
        <FullWidthInputNumber
          placeholder='Company ID'
          type='number'
          precision={0}
          min={1}
          disabled={isEdit}
        />
      </Form.Item>

      <Form.Item
        label='Company name'
        name={COMPANY_NAME_FIELD_NAME}
        rules={[{required: true, message: 'Please input company full name!'}]}
      >
        <Input
          placeholder='Full name of the company'
          allowClear
          disabled={isEdit}
        />
      </Form.Item>

      <Form.List name={EMAIL_DOMAINS_FIELD_NAME} initialValue={initialValue}>
        {(fields, {add, remove}) => (
          <>
            {fields.map(({key, name, ...restField}, index) => {
              const addonAfter =
                fields.length > 1 ? (
                  // @ts-ignore
                  <MinusCircleOutlined onClick={() => remove(name)} />
                ) : null;
              return (
                <Form.Item
                  {...restField}
                  name={name}
                  label={`Email domain ${index + 1}`}
                  rules={[
                    {required: true, message: 'Please input email domain!'},
                    {validator: validateDomain(form)},
                  ]}
                  key={key}
                  initialValue=''
                >
                  <Input
                    allowClear
                    placeholder='domain.com'
                    addonAfter={addonAfter}
                  />
                </Form.Item>
              );
            })}

            <Form.Item wrapperCol={buttonWrapperCol}>
              <Button
                type='dashed'
                onClick={() => add()} // 'add' should be called from inline function! Otherwise it won't work! This is ant design.
                block
                // @ts-ignore
                icon={<PlusOutlined />}
              >
                Add email domain
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};

export default React.memo(AdminAddCompany);
