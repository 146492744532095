import React from 'react';
import DateUtils, {DateFormats} from 'utils/dateUtils';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import {OfferRequestSpace} from 'view/venue/NW2BookingPreview/components/Space/OfferRequestSpace';

import {TGroupedUnitsByDay} from 'types/dto/IBooking.types';
import {offsetXXLg} from 'constants/styleVars';
import {
  StyledLeftSideItem,
  SubtitleWrapper,
} from 'view/venue/NW2BookingPreview/components/Space/Space.styles';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';
import {IDayResponse} from 'types/dto/IPublicVenue';
import {getFilteredUnitsByEventType} from 'utils/venueUtils';
import {useSelectedRoomId} from 'view/venue/hooks/useSelectedRoomId';

type TProps = {
  hasPreArrivals?: boolean;
  hasPostEvents?: boolean;
  isAlternative?: boolean;
  hasDots?: boolean;
};

const {getHoursAndMinutes, getDateWithoutHours} = DateUtils;

export const OfferRequestLeftSideSpaces = ({
  hasPreArrivals,
  hasPostEvents,
  isAlternative,
  hasDots,
}: TProps) => {
  const previewUnits: TGroupedUnitsByDay[] = useSelector((state) =>
    _get(state, `venuesReducer.bookingPreview.bookingMaskPreviewUnits`),
  );

  const {venueDetails} = useVenueDetailsData();

  const venueDays: IDayResponse[] = getFilteredUnitsByEventType(
    venueDetails.days,
  );

  const isMultiDay = previewUnits.length > 1 || venueDays.length > 1;

  const [getSelectedVenueUnitId] = useSelectedRoomId();

  return (
    <>
      {venueDays.map((day, dayIndex) => {
        const {checkIn, checkOut, foodAndBeverage, bedrooms} = day;

        const dateText = getDateWithoutHours(
          checkIn,
          DateFormats['1 Jan 2023'],
        );
        const timeRangeText = `(${getHoursAndMinutes(
          checkIn,
        )} - ${getHoursAndMinutes(checkOut)})`;

        const title = (
          <>
            <span>{isMultiDay ? `DAY ${dayIndex + 1}` : 'Your room'}</span>
            <SubtitleWrapper>
              <div>{dateText}</div>
              <div>{timeRangeText}</div>
            </SubtitleWrapper>
          </>
        );

        const selectedVenueUnitId = getSelectedVenueUnitId({
          venueId: venueDetails.accommodationId,
          checkIn: day.checkIn,
        });

        const units = (day as IDayResponse).rooms
          .flatMap(({units}) => units)
          .filter(({isOptimalPrice, unitId}) =>
            selectedVenueUnitId
              ? unitId === selectedVenueUnitId
              : isOptimalPrice,
          );

        const children = (
          <OfferRequestSpace
            units={units}
            foodAndBeverage={foodAndBeverage}
            bedrooms={bedrooms}
            isAlternative={isAlternative}
            hasDots={hasDots}
          />
        );

        return (
          <StyledLeftSideItem
            key={dayIndex}
            item={{
              title,
              children,
            }}
            isHidden={false}
            paddingTop={
              hasPreArrivals && dayIndex === 0
                ? `${offsetXXLg} !important`
                : undefined
            }
            paddingBottom={
              !hasPostEvents && dayIndex === previewUnits.length - 1
                ? undefined
                : `${offsetXXLg} !important`
            }
            isNoBorderTop={hasPreArrivals}
          />
        );
      })}
    </>
  );
};
