import React from 'react';

import {IBooking} from 'types/dto/IBooking.types';
import {getCurrencySymbol} from 'utils/stringUtils';
import {formatFloatWithOneNumber} from 'utils/venueUtils';

export function TotalColumn(totalPrice: number, row: IBooking, index: number) {
  const currencySymbol = getCurrencySymbol(row.currency);

  return (
    <div key={index}>
      <b>
        {formatFloatWithOneNumber(totalPrice)} {currencySymbol}
      </b>
    </div>
  );
}
