import React, {FC, useEffect, useState} from 'react';
import {Form} from 'react-final-form';
import {useDispatch} from 'react-redux';

import useForgotPassword from 'view/auth/utils/useForgotPassword';
import NW2Button from 'view/components/NW2Button/NW2Button';
import TextValue from 'view/components/TextValue';
import {NW2FormItemInput} from 'view/components/NW2FormItem/NW2FormItem';
import NW2PasswordFieldWithCheckList from '../NW2PasswordFieldWithCheckList/NW2PasswordFieldWithCheckList';
import {useQuery} from 'hooks/useQuery';
import {useAppSelector} from 'store/hooks';

import {EAuthActions} from './Login';
import {setOpenLoginPopup} from 'store/app/appSlice';
import {LoginRegisterContainer} from 'view/auth/LoginRegisterContainer';
import {emailFieldRules} from 'utils/finalFormFieldRules';
import {
  FormGroup,
  StepCountTitle,
  MainAuthTitle,
  TitleContainer,
  RegisterModalFooter,
  ForgotPasswordContainer,
  StyledTextValue,
} from 'view/auth/utils/auth.styles';
import {Link} from '../../components/Typography';
import {useNavigate} from 'react-router-dom';

enum EForgotPasswordSteps {
  STEP_1 = 'STEP_1',
  STEP_2 = 'STEP_2',
  STEP_3 = 'STEP_3',
  STEP_4 = 'STEP_4',
}

const ForgotResetPasswordModal: FC<{
  isForgotPopupVisible: boolean;
  setIsForgotPopupVisible: (openForgotPasswordPopup: boolean) => void;
}> = ({isForgotPopupVisible, setIsForgotPopupVisible}) => {
  const [currentStep, setCurrentStep] = useState(EForgotPasswordSteps.STEP_1);
  const [localEmail, setLocalEmail] = useState('');
  const [localCode, setLocalCode] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {submitForgotPassword, submitConfirmationForgotPassword, isLoading} =
    useForgotPassword();
  const appEnvironment = useAppSelector(({app}) => app.environment);

  const {code, email, action} = useQuery();

  const isForgotPasswordAction =
    action === EAuthActions.CONFIRM_FORGOT_PASSWORD;

  const closeModal = () => {
    setIsForgotPopupVisible(false);
    dispatch(setOpenLoginPopup({openLoginPopup: false}));
    setCurrentStep(EForgotPasswordSteps.STEP_1);
  };

  const returnToLogIn = () => {
    setIsForgotPopupVisible(false);
    setCurrentStep(EForgotPasswordSteps.STEP_1);
    dispatch(setOpenLoginPopup({openLoginPopup: true}));
  };

  const handleForgotPasswordApi = (email: string) => async () => {
    submitForgotPassword(email, () =>
      setCurrentStep(EForgotPasswordSteps.STEP_2),
    );
  };

  const requestForgotPassword = async (formData: unknown) => {
    const {email} = formData as {email: string};
    setLocalEmail(email);
    await handleForgotPasswordApi(email)();
  };

  const handleConfirmForgotPasswordApi =
    (email: string, newPassword: string) => async () => {
      submitConfirmationForgotPassword({
        email,
        code: localCode,
        newPassword,
        callback: () => setCurrentStep(EForgotPasswordSteps.STEP_4),
      });
    };

  const confirmForgotPassword = async (formData: unknown) => {
    const {disabledEmail, newPassword} = formData as {
      disabledEmail: string;
      newPassword: string;
    };
    await handleConfirmForgotPasswordApi(disabledEmail, newPassword)();
  };

  useEffect(() => {
    if (appEnvironment && isForgotPasswordAction) {
      setIsForgotPopupVisible(true);
      setLocalCode(code as string);
      setLocalEmail(email as string);
      setCurrentStep(EForgotPasswordSteps.STEP_3);
      navigate({search: ''});
    }
  }, [
    setLocalCode,
    setLocalEmail,
    setCurrentStep,
    appEnvironment,
    isForgotPasswordAction,
    setIsForgotPopupVisible,
    navigate,
    code,
    email,
  ]);

  const modalContentBySteps = {
    [EForgotPasswordSteps.STEP_1]: {
      stepCountTitle: '1 / 2 Step',
      title: 'Forgot password',
      body: (
        <>
          <Form onSubmit={requestForgotPassword} initialValues={{email: ''}}>
            {({handleSubmit}) => (
              <form onSubmit={handleSubmit} noValidate>
                <FormGroup columnNumber={1} gap={24}>
                  <NW2FormItemInput
                    name='email'
                    type='email'
                    label='Email address'
                    placeholder='Email address'
                    rules={emailFieldRules}
                    showAllValidationErrors
                  />
                  <FormGroup columnNumber={1} gap={16}>
                    <NW2Button
                      fullWidth
                      type='submit'
                      buttonType='primary'
                      loading={isLoading}
                    >
                      {isLoading ? 'Loading...' : 'send reset instructions'}
                    </NW2Button>
                    <NW2Button buttonType='secondary' onClick={returnToLogIn}>
                      Back to log in
                    </NW2Button>
                  </FormGroup>
                </FormGroup>
              </form>
            )}
          </Form>
        </>
      ),
    },
    [EForgotPasswordSteps.STEP_2]: {
      stepCountTitle: '2 / 2 Step',
      title: 'Instructions sent',
      body: (
        <>
          <ForgotPasswordContainer>
            <div>
              If an account with this email address exists you will receive
              password reset instructions shortly.
            </div>
            <NW2Button buttonType='primary' onClick={returnToLogIn}>
              return to log in
            </NW2Button>
          </ForgotPasswordContainer>
          <RegisterModalFooter>
            <TextValue>
              Email not found? Check your spam folder or
              <br /> resend email.
            </TextValue>
            <Link onClick={handleForgotPasswordApi(localEmail)}>
              Re-send email
            </Link>
          </RegisterModalFooter>
        </>
      ),
    },
    [EForgotPasswordSteps.STEP_3]: {
      stepCountTitle: '1 / 2 Step',
      title: 'Create a new password',
      body: (
        <>
          <Form
            onSubmit={confirmForgotPassword}
            initialValues={{disabledEmail: localEmail, newPassword: ''}}
          >
            {({handleSubmit, form}) => (
              <form onSubmit={handleSubmit} noValidate>
                <FormGroup columnNumber={1} gap={24}>
                  <NW2FormItemInput
                    name='disabledEmail'
                    type='email'
                    label='Email address'
                    placeholder='Email address'
                    disabled={true}
                  />
                  <NW2PasswordFieldWithCheckList
                    name='newPassword'
                    label='New password'
                    placeholder='New password'
                    isSubmitFailed={false}
                  />
                  <FormGroup columnNumber={1} gap={16}>
                    <NW2Button
                      fullWidth
                      buttonType='primary'
                      loading={isLoading}
                      // need this handler to avoid block onClick by onBlur event
                      onMouseDown={() => form.submit()}
                    >
                      {isLoading ? 'Loading...' : 'change password'}
                    </NW2Button>
                  </FormGroup>
                </FormGroup>
              </form>
            )}
          </Form>
        </>
      ),
    },
    [EForgotPasswordSteps.STEP_4]: {
      stepCountTitle: '2 / 2 Step',
      title: 'New password created!',
      body: (
        <>
          <StyledTextValue>
            You can now go back to the log in to book your next venue
          </StyledTextValue>
          <NW2Button fullWidth buttonType='primary' onClick={returnToLogIn}>
            return to log in
          </NW2Button>
        </>
      ),
    },
  };

  return (
    <LoginRegisterContainer onClose={closeModal} open={isForgotPopupVisible}>
      <>
        <TitleContainer>
          <StepCountTitle>
            {modalContentBySteps[currentStep].stepCountTitle}
          </StepCountTitle>
          <MainAuthTitle>
            {modalContentBySteps[currentStep].title}
          </MainAuthTitle>
        </TitleContainer>
        {modalContentBySteps[currentStep].body}
      </>
    </LoginRegisterContainer>
  );
};

export default ForgotResetPasswordModal;
