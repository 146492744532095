import {DateTime} from 'luxon';
import _uniqBy from 'lodash/uniqBy';

import DateUtils from 'utils/dateUtils';

import {IPreviewExtra, TAttendeeFormField} from 'store/venues/types';
import {ATTENDEE_FIELD_KEY} from 'constants/venue';
import {EMPTY_ARRAY, EMPTY_OBJECT} from 'constants/app';
import {IUnit} from 'types/dto/IUnit.types';
import {
  EPriceType,
  EResourcesType,
  IExtrasResponse,
} from 'types/dto/IExtras.type';
import {
  IPreviewUnit,
  IPreviewUnitResponse,
  TGroupedDeskUnitsByDay,
  TGroupedUnitsByDay,
} from 'types/dto/IBooking.types';
import {
  filterByAvailability,
  filterPreviewExtrasByExtraType,
} from 'utils/venueUtils';
import {EEventType, ERoomSchemaNames} from 'types/venue';
import {ERoomType} from 'types/dto/ERoomType.type';
import {getFoodAndBeverageQuantity} from 'view/components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/components/AddFoodBeverageRequest/helpers';
import {EPackageType, IPackageResponse} from 'types/dto/IPackages.type';
import {
  IDayResponse,
  IExtraResponse,
  IUnitResponse,
} from 'types/dto/IPublicVenue';

export const setAttendees = (attendees: TAttendeeFormField[]) => {
  const attendeesFields = attendees || EMPTY_ARRAY;

  const attendeesEmails = attendeesFields
    .map((item) => item[ATTENDEE_FIELD_KEY])
    .filter((item: string) => item && !!item.trim());

  return [...new Set(attendeesEmails)].map((item: string) => ({
    email: item.toLowerCase(),
  }));
};

export const getChosenFoodFromUnits = (units: IPreviewUnit[]) => {
  let mySet1: IPreviewExtra[] = [];
  units.forEach((unit) => {
    mySet1 = [...mySet1, ...unit.chosenExtras];
  });
  return _uniqBy(mySet1, 'id');
};

interface IMakePreviewUnitsData {
  searchStartDate: string;
  searchEndDate: string;
  participants: number;
  seatingPlan: ERoomSchemaNames;
  days?: IDayResponse[];
}

interface IMakeInitPreviewUnits extends IMakePreviewUnitsData {
  venueUnits: IUnitResponse[];
  roomType: ERoomType;
  extras?: IPreviewExtra[];
}

const getFreeExtrasByType = (extras: IPreviewExtra[], type: EResourcesType) => {
  return extras.filter(
    (extra) => extra.extraType === type && extra.priceType === EPriceType.FREE,
  );
};

export const makeInitPreviewUnitsData = ({
  venueUnits,
  days,
  seatingPlan,
  extras,
}: IMakeInitPreviewUnits) => {
  const freeFoodAndBeverage = getFreeExtrasByType(
    extras || [],
    EResourcesType.FOOD_AND_BEVERAGE,
  );
  const freeEquipmentExtras = getFreeExtrasByType(
    extras || [],
    EResourcesType.EQUIPMENT,
  );

  //@ts-ignore //TODO: fix
  const groupedUnitsByDay: TGroupedUnitsByDay[] = venueUnits?.reduce<
    TGroupedUnitsByDay[]
    //@ts-ignore
  >((prev, curr) => {
    const isSameDay = prev.some(({checkInDate}) =>
      DateUtils.isSameDay(checkInDate, curr.checkIn),
    );

    const currentDay: any = days?.find(({checkIn}) => checkIn === curr.checkIn);

    const foodAndBeverageExtras = [...freeFoodAndBeverage];

    if (currentDay?.foodAndBeverage) {
      foodAndBeverageExtras.push(
        // @ts-ignore
        ...(currentDay?.foodAndBeverage as IExtraResponse[]).filter(
          (extra) =>
            !freeFoodAndBeverage.find(
              (freeExtra) => freeExtra.code === extra.code,
            ),
        ),
      );
    }

    const equipmentExtras = [...freeEquipmentExtras];

    if (curr?.extras) {
      equipmentExtras.push(
        // @ts-ignore
        ...(curr.extras as IExtraResponse[]).filter(
          (extra) =>
            !freeEquipmentExtras.find(
              (freeExtra) => freeExtra.code === extra.code,
            ),
        ),
      );
    }

    const updatedUnit: IPreviewUnitResponse = {
      ...curr,
      unitId: curr.unitId,
      checkInDate: curr.checkIn,
      checkOutDate: curr.checkOut,
      chosenSetupStyle: seatingPlan,
      unitCapacities: curr.unitCapacities || [],
      chosenExtras: equipmentExtras as unknown as IExtraResponse[],
    };

    if (!prev.length || !isSameDay) {
      return [
        ...prev,
        {
          checkInDate: curr.checkIn,
          checkOutDate: curr.checkOut,
          units: [updatedUnit],
          eventType: EEventType.DAY,
          unitBookings: [updatedUnit],
          bedrooms: currentDay?.bedrooms,
          foodAndBeverage: foodAndBeverageExtras,
        },
      ];
    }

    return prev.map((el) => {
      const isSameDay = DateUtils.isSameDay(el.checkInDate, curr.checkIn);
      const isBefore =
        isSameDay && DateUtils.isBefore(curr.checkIn, el.checkInDate);
      const isAfter =
        isSameDay && DateUtils.isAfter(curr.checkOut, el.checkOutDate);

      const units = isSameDay ? [...el.units, updatedUnit] : [...el.units];
      const unitBookings = isSameDay
        ? [...el.unitBookings, updatedUnit]
        : [...el.unitBookings];

      const chosenFoodAndBeverage = isSameDay
        ? _uniqBy([...el.foodAndBeverage, ...foodAndBeverageExtras], 'extraId')
        : [...el.foodAndBeverage];

      return {
        ...el,
        checkInDate: isBefore ? curr.checkIn : el.checkInDate,
        checkOutDate: isAfter ? curr.checkOut : el.checkOutDate,
        units,
        eventType: EEventType.DAY,
        unitBookings,
        foodAndBeverage: chosenFoodAndBeverage,
      };
    });
  }, []);

  return groupedUnitsByDay.sort(
    (a, b) =>
      DateTime.fromISO(a.checkInDate).diff(
        DateTime.fromISO(b.checkInDate),
        'days',
      ).days,
  );
};

interface IMakePreviewDeskUnitsData {
  availableExtras: IExtrasResponse[];
  searchStartDate: string;
  searchEndDate: string;
  participants: number;
  seatingPlan: ERoomSchemaNames;
}

interface IMakeInitPreviewDeskUnits extends IMakePreviewDeskUnitsData {
  venueUnits: IUnit[];
  roomType: ERoomType;
}

export const makeInitPreviewDeskUnitsData = ({
  searchStartDate,
  searchEndDate,
  venueUnits,
  availableExtras,
  seatingPlan,
  roomType,
  participants,
}: IMakeInitPreviewDeskUnits) => {
  const filteredExtrasByAvailability =
    filterByAvailability<IExtrasResponse>(availableExtras, roomType) || [];

  const isSingleDay = venueUnits?.length === 1;

  //@ts-ignore
  const groupedUnitsByDay = venueUnits?.reduce((prev, curr) => {
    const isSameDay = prev.some((el) =>
      DateUtils.isSameDay(el.checkInDate, curr.unitFilter?.startDate),
    );

    const foodAndBeverageExtras = filteredExtrasByAvailability.filter(
      (item) =>
        item.extraType === EResourcesType.FOOD_AND_BEVERAGE &&
        curr.unitFilter?.extras
          .map(({accommodationExtraId}) => accommodationExtraId)
          .includes(item.accommodationExtraId),
    );

    const chosenExtras = filteredExtrasByAvailability
      .filter(
        ({accommodationExtraId, price}) =>
          curr.unitFilter?.extras.some(
            (extra) => extra.accommodationExtraId === accommodationExtraId,
          ) || price === 0,
      )
      .filter(({accommodationExtraId}) => {
        //TODO: temporary solution with 'isSingleDay' till single day will have unitFilter
        if (isSingleDay) return true;
        //TODO: maybe better use curr.unitFilter?.extras.some((extra) =>
        // extra.accommodationExtraId === accommodationExtraId)
        // instead of .map(({accommodationExtraId}) => accommodationExtraId).includes(accommodationExtraId)
        return curr.unitFilter?.extras
          .map(({accommodationExtraId}) => accommodationExtraId)
          .includes(accommodationExtraId);
      })
      .map((extra) => {
        const isFoodAndBeverage =
          extra.extraType === EResourcesType.FOOD_AND_BEVERAGE;
        let foodAndBeverageQuantity = 1;
        if (isFoodAndBeverage) {
          const duration = DateUtils.getTotalHours(
            curr.unitFilter?.startDate || '',
            curr.unitFilter?.endDate || '',
          );
          foodAndBeverageQuantity = getFoodAndBeverageQuantity({
            extra,
            duration,
            participants: curr.unitFilter?.capacity || participants,
          });
        }
        return {
          ...extra,
          chosenQuantity:
            curr.unitFilter?.extras.find(
              (el) => el.accommodationExtraId === extra.accommodationExtraId,
            )?.quantity ||
            (isFoodAndBeverage && foodAndBeverageQuantity) ||
            1,
        };
      });

    const checkInDate = DateUtils.normalizeDateToBackendFormat(searchStartDate);
    const checkOutDate = DateUtils.normalizeDateToBackendFormat(searchEndDate);

    const updatedUnit: IPreviewUnit = {
      ...curr,
      unitId: curr.unitId || curr.id,
      checkInDate: curr.unitFilter?.startDate || checkInDate,
      checkOutDate: curr.unitFilter?.endDate || checkOutDate,
      chosenSetupStyle: curr.unitFilter?.setupStyle || seatingPlan,
      unitCapacities: curr.unitCapacities || [],
      chosenExtras:
        filterPreviewExtrasByExtraType(
          chosenExtras,
          EResourcesType.EQUIPMENT,
        ) || [],
    };

    if (!prev.length || !isSameDay) {
      return [
        ...prev,
        {
          checkInDate: curr.unitFilter?.startDate || checkInDate,
          checkOutDate: curr.unitFilter?.endDate || checkOutDate,
          units: [updatedUnit],
          eventType: EEventType.DAY,
          unitBookings: [updatedUnit],
          bedrooms:
            filterPreviewExtrasByExtraType(
              chosenExtras,
              EResourcesType.BEDROOM,
            ) || [],
          foodAndBeverage:
            filterPreviewExtrasByExtraType(
              chosenExtras,
              EResourcesType.FOOD_AND_BEVERAGE,
            ) || [],
        },
      ];
    }

    return prev.map((el) => {
      if (!curr.unitFilter) return el;
      const isSameDay = DateUtils.isSameDay(
        el.checkInDate,
        curr.unitFilter?.startDate,
      );
      const isBefore =
        isSameDay &&
        DateUtils.isBefore(curr.unitFilter.startDate, el.checkInDate);
      const isAfter =
        isSameDay &&
        DateUtils.isAfter(curr.unitFilter.endDate, el.checkOutDate);

      const units = isSameDay ? [...el.units, updatedUnit] : [...el.units];
      const unitBookings = isSameDay
        ? [...el.unitBookings, updatedUnit]
        : [...el.unitBookings];

      const chosenFoodAndBeverage = isSameDay
        ? [...el.foodAndBeverage, foodAndBeverageExtras]
        : [...el.foodAndBeverage];

      return {
        ...el,
        checkInDate: isBefore ? curr.unitFilter.startDate : el.checkInDate,
        checkOutDate: isAfter ? curr.unitFilter.endDate : el.checkOutDate,
        units,
        eventType: EEventType.DAY,
        unitBookings,
        foodAndBeverage:
          filterPreviewExtrasByExtraType(
            chosenFoodAndBeverage,
            EResourcesType.FOOD_AND_BEVERAGE,
          ) || [],
      };
    });
  }, [] as TGroupedDeskUnitsByDay[]);

  return (groupedUnitsByDay as unknown as TGroupedDeskUnitsByDay[])?.sort(
    (a, b) =>
      DateTime.fromISO(a.checkInDate).diff(
        DateTime.fromISO(b.checkInDate),
        'days',
      ).days,
  );
};

export const findPackageByType = (
  packageType: string | undefined,
  packages: IPackageResponse[],
): IPackageResponse =>
  packages.find((item) => item.type === packageType && item.active) ||
  (EMPTY_OBJECT as IPackageResponse);

export function getPackageExtrasDescriptions(
  packageItem: IPackageResponse | undefined,
  extraType: 'catering' | 'equipment',
) {
  if (!packageItem || !packageItem[extraType]) return [];
  return packageItem[extraType]
    .filter((item) => item.active)
    .map((item) => item.description);
}

export const isPackageTypeAvailable = (
  packages: IPackageResponse[],
  packageType: EPackageType,
) => {
  return packages.some(
    (packagesItem) => packagesItem.type === packageType && packagesItem.active,
  );
};
