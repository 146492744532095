import React from 'react';

import Image from 'view/components/Image';

import {borderRadiusDef} from 'constants/styleVars';
import {EBookingSummaryTheme} from 'view/components/NW2SummaryBlock/types';
import {
  TotalHeader,
  TotalHeaderSubTitle,
  TotalHeaderText,
  TotalHeaderTitle,
} from '../../NW2BookingSummary.styles';

const IMAGE_SIZE = 60;

interface ISummaryVenueSectionProps {
  venueCoverImage: string;
  theme: EBookingSummaryTheme;
  venueName: string;
  venueAddress: string;
  isMultiRequest?: boolean;
}

export const SummaryVenueSection = ({
  venueCoverImage,
  theme,
  venueAddress,
  venueName,
  isMultiRequest,
}: ISummaryVenueSectionProps) => {
  return (
    <TotalHeader isMultiRequest={isMultiRequest}>
      <Image
        src={venueCoverImage}
        alt={venueName}
        maxWidth={IMAGE_SIZE}
        height={IMAGE_SIZE}
        borderRadius={borderRadiusDef}
      />
      <TotalHeaderText>
        <TotalHeaderTitle colorTheme={theme}>{venueName}</TotalHeaderTitle>
        <TotalHeaderSubTitle colorTheme={theme}>
          {venueAddress}
        </TotalHeaderSubTitle>
      </TotalHeaderText>
    </TotalHeader>
  );
};
