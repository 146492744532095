import React from 'react';
import {Bedrooms} from 'view/venue/NW2BookingPreview/components/Bedrooms/Bedrooms';
import {DefaultRequestLeftSideSpaces} from 'view/venue/Offer/components/OfferRequestLeftSidePreview/components/DefaultRequestLeftSideSpaces';
import {useSearchTypeBedrooms} from 'view/venue/Offer/hooks/useSearchTypeBedrooms';
import {
  Title,
  Wrapper,
  StyledNW2AccordionItem,
} from './RequestFormTripDetails.styles';

interface IProps {
  searchString: string;
  isDesktop?: boolean;
  isTablet?: boolean;
}

const RequestFormTripDetails = ({
  isDesktop,
  isTablet,
  searchString,
}: IProps) => {
  const {postEvents, preArrivals} = useSearchTypeBedrooms();
  const [city, country] = searchString.split(', ');
  const isMobile = !isDesktop && !isTablet;

  const title = `Your trip ${isMobile ? 'details for' : 'to'} ${city} `;

  const hasPreArrivals = !!preArrivals.length;
  const hasPostEvents = !!postEvents.length;

  const renderContent = (
    <>
      {hasPreArrivals && (
        <Bedrooms
          title='PRE-ARRIVAL'
          rooms={preArrivals}
          isDesktop={isDesktop}
          isDefaultItem
          hasDates
          hasDots
        />
      )}

      <DefaultRequestLeftSideSpaces
        hasPreArrivals={hasPreArrivals}
        hasPostEvents={hasPostEvents}
        isDesktop={isDesktop}
        hasDots
      />

      {hasPostEvents && (
        <Bedrooms
          title='POST-EVENT'
          rooms={postEvents}
          isDesktop={isDesktop}
          isDefaultItem
          hasDates
          hasDots
        />
      )}
    </>
  );

  return (
    <>
      {isMobile ? (
        <StyledNW2AccordionItem
          title={title}
          content={renderContent}
          initiallyExpanded
          isDivider={false}
        />
      ) : (
        <Wrapper isDesktop={isDesktop} isTablet={isTablet}>
          <Title isMobile={isMobile}>
            {title}
            {country && <span>{`(${country})`}</span>}
          </Title>
          {renderContent}
        </Wrapper>
      )}
    </>
  );
};

export default RequestFormTripDetails;
