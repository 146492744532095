import React, {useCallback, useMemo} from 'react';
import {Form} from 'react-final-form';

import NMMSubmitSection from 'view/components/NMMSubmitSection';
import ActionCardClosable from '../ActionCardClosable';

import {
  NW2FormItemInput,
  NW2FormItemSelect,
} from 'view/components/NW2FormItem/NW2FormItem';
import {
  NW2FormItemCheckbox,
  Textarea,
} from 'view/components/NW2FormItem/components';

import {
  ModalTitle,
  TextAreaBox,
  InputBox,
  NameInputBox,
  Required,
  ActionCardBox,
  ModalText,
} from '../../supplier/Bookings/BookingListRooms/Requests/styles';
import {NW2Gray600Color, offsetDef} from 'constants/styleVars';
import {
  declineRfpOrderMessage,
  declineRfpOrderMessageRequired,
  requiredFieldRules,
} from 'utils/finalFormFieldRules';
import {ECheckboxSizes} from 'view/components/NW2FormItem/components/NW2FormItemCheckbox/types';
import {getKeyByValue} from 'constants/floorStorey';
import {EDeclineItemType} from 'types/offer';

//TODO: replace EDeclineReasons with ERequestDeclineReasons after NMM-615 backend merged to master
export enum EDeclineReasons {
  ROOM_IS_NOT_AVAILABLE = 'Meeting room(s) not available',
  EQUIPMENT_IS_NOT_AVAILABLE = 'Equipment is not available',
  CATERING_IS_NOT_AVAILABLE = 'Catering is not available',
  VENUE_FULLY_BOOKED = 'Venue fully booked',
  COULD_NOT_GET_BACK_CREDENTIALS_VERIFIED_IN_TIME = `Could not get back credentials verified in time`,
  OTHER = 'Other',
}

enum ECustomerDeclineReasons {
  OTHER_VENUE_SELECTED = 'OTHER_VENUE_SELECTED',
  REQUIREMENTS_HAVE_CHANGED = 'REQUIREMENTS_HAVE_CHANGED',
  EVENT_WILL_NOT_TAKE_PLACE = 'EVENT_WILL_NOT_TAKE_PLACE',
  PRICE_NOT_FITTING_TO_BUDGET = 'PRICE_NOT_FITTING_TO_BUDGET',
  OTHER_REASON = 'OTHER_REASON',
}

enum ERequestDeclineReasons {
  ROOM_IS_NOT_AVAILABLE = 'ROOM_IS_NOT_AVAILABLE',
  EQUIPMENT_IS_NOT_AVAILABLE = 'EQUIPMENT_IS_NOT_AVAILABLE',
  CATERING_IS_NOT_AVAILABLE = 'CATERING_IS_NOT_AVAILABLE',
  VENUE_FULLY_BOOKED = 'VENUE_FULLY_BOOKED',
  COULD_NOT_GET_BACK_CREDENTIALS_VERIFIED_IN_TIME = `COULD_NOT_GET_BACK_CREDENTIALS_VERIFIED_IN_TIME`,
  OTHER = 'OTHER',
}

const declineReasons = {
  [ERequestDeclineReasons.ROOM_IS_NOT_AVAILABLE]:
    'Meeting room(s) not available',
  [ERequestDeclineReasons.EQUIPMENT_IS_NOT_AVAILABLE]:
    'Equipment is not available',
  [ERequestDeclineReasons.CATERING_IS_NOT_AVAILABLE]:
    'Catering is not available',
  [ERequestDeclineReasons.VENUE_FULLY_BOOKED]: 'Venue fully booked',
  [ERequestDeclineReasons.COULD_NOT_GET_BACK_CREDENTIALS_VERIFIED_IN_TIME]:
    'Could not get back credentials verified in time',
  [ERequestDeclineReasons.OTHER]: 'Other',
};
const customerDeclineReasons = {
  [ECustomerDeclineReasons.OTHER_VENUE_SELECTED]: 'Other venue selected',
  [ECustomerDeclineReasons.REQUIREMENTS_HAVE_CHANGED]:
    'Requirements have changed',
  [ECustomerDeclineReasons.EVENT_WILL_NOT_TAKE_PLACE]:
    'Event will not take place',
  [ECustomerDeclineReasons.PRICE_NOT_FITTING_TO_BUDGET]:
    'Price not fitting to budget',
  [ECustomerDeclineReasons.OTHER_REASON]: 'Other reason',
};

enum FormValues {
  REASON = 'REASON',
  UNAVAILABLE = 'UNAVAILABLE',
  MESSAGE = 'MESSAGE',
  RESOLVER_NAME = 'RESOLVER_NAME',
}

const CARD_TEXT =
  'You have not visited the request details page, would you like to see more details first?';

interface IProps {
  orderId: string;
  handleDecline: <TPayload>(
    payload: TPayload,
    declineItem: EDeclineItemType,
  ) => void;
  handleCancel: () => void;
  isSending: boolean;
  isRequest: boolean;
  declineItem: EDeclineItemType;
  isCustomer?: boolean;
  submitLabel?: string;
  cancelLabel?: string;
  isActionCardShowed?: boolean;
  showButtons?: boolean;
  isMobile: boolean;
  handleShowDetails?: () => void;
  isAdditionCancelText?: boolean;
}

export function RequestDeclineForm({
  orderId,
  handleDecline,
  handleCancel,
  isSending,
  submitLabel = 'decline',
  cancelLabel = 'cancel',
  isRequest,
  declineItem,
  isCustomer,
  isActionCardShowed,
  handleShowDetails,
  showButtons,
  isMobile,
  isAdditionCancelText,
}: IProps) {
  const onSubmit = useCallback(
    (values: any) => {
      const commonValues = {
        orderId,
        message: values[FormValues.MESSAGE],
      };

      const customerValues = {
        ...commonValues,
        reason: getKeyByValue(
          customerDeclineReasons,
          values[FormValues.REASON],
        ),
      };

      const offerValues = {
        ...commonValues,
        reason: getKeyByValue(declineReasons, values[FormValues.REASON]),
        resolverName: values[FormValues.RESOLVER_NAME],
      };

      const rfpValues = {
        ...commonValues,
        reason: values[FormValues.REASON],
        isUnavailable: values[FormValues.UNAVAILABLE],
      };

      const getSubmitValues = () => {
        switch (true) {
          case isCustomer:
            return customerValues;
          case isRequest:
            return offerValues;
          default:
            return rfpValues;
        }
      };

      handleDecline(getSubmitValues(), declineItem);
    },
    [orderId, isCustomer, handleDecline, isRequest, declineItem],
  );

  const initialFormValues = useMemo(() => {
    const initialOfferValues = {
      [FormValues.REASON]: isCustomer
        ? customerDeclineReasons[ECustomerDeclineReasons.OTHER_VENUE_SELECTED]
        : EDeclineReasons.ROOM_IS_NOT_AVAILABLE,
      [FormValues.MESSAGE]: '',
      [FormValues.RESOLVER_NAME]: '',
    };
    const initialRequestValues = {
      [FormValues.REASON]: EDeclineReasons.ROOM_IS_NOT_AVAILABLE,
      [FormValues.UNAVAILABLE]: false,
      [FormValues.MESSAGE]: '',
    };

    return isRequest ? initialOfferValues : initialRequestValues;
  }, [isCustomer, isRequest]);

  const reasonOptions = useMemo(() => {
    const reasons = isRequest
      ? Object.values(EDeclineReasons)
      : Object.values(EDeclineReasons).filter(
          (reason) =>
            reason !==
            EDeclineReasons.COULD_NOT_GET_BACK_CREDENTIALS_VERIFIED_IN_TIME,
        );
    const customerReasons = Object.values(customerDeclineReasons);

    return (isCustomer ? customerReasons : reasons).map((item) => ({
      key: item,
      value: item,
      text: item,
    }));
  }, [isCustomer, isRequest]);

  const combineValidators = useCallback(
    (values: Record<FormValues, string>) =>
      Object.keys(values).reduce((prev) => {
        // if checked reason OTHER the message is required
        const isOther =
          values[FormValues.REASON] === EDeclineReasons.OTHER ||
          values[FormValues.REASON] ===
            customerDeclineReasons[ECustomerDeclineReasons.OTHER_REASON];

        const msgError = isOther
          ? declineRfpOrderMessageRequired(values[FormValues.MESSAGE])
          : declineRfpOrderMessage(values[FormValues.MESSAGE]);

        if (msgError) {
          return {
            ...prev,
            [FormValues.MESSAGE]: msgError,
          };
        }

        return prev;
      }, {}),
    [],
  );

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        validate={combineValidators}
        render={({handleSubmit, values}) => {
          const isOther =
            values[FormValues.REASON] === EDeclineReasons.OTHER ||
            values[FormValues.REASON] === ECustomerDeclineReasons.OTHER_REASON;

          return (
            <form onSubmit={handleSubmit} id={`decline-form-${orderId}`}>
              {isActionCardShowed && (
                <ActionCardBox>
                  <ActionCardClosable
                    buttonText='Show me request details'
                    buttonPosition='postfix'
                    inlineButton={false}
                    description={CARD_TEXT}
                    onClick={handleShowDetails}
                  />
                </ActionCardBox>
              )}

              <div>
                {isAdditionCancelText && (
                  <ModalText>
                    You are about to cancel your request. Confirming this
                    cancellation will automatically decline/cancel any pending
                    offer/request. All of the requested venues will be notified.
                    <br />
                    <b>This action is irreversible.</b>
                  </ModalText>
                )}

                <ModalTitle>What’s the reason for declining?</ModalTitle>

                <InputBox isMobile={isMobile}>
                  <NW2FormItemSelect
                    name={FormValues.REASON}
                    options={reasonOptions}
                    placeholder='Reason'
                    label='Reason*'
                    labelColor={NW2Gray600Color}
                    data-testid='decline-rfp-reason'
                    readonly
                  />
                  {isRequest && <Required marginTop={16}>(Required)</Required>}
                </InputBox>
              </div>

              {!isRequest && (
                <NW2FormItemCheckbox
                  name={FormValues.UNAVAILABLE}
                  id={`decline-form-${FormValues.UNAVAILABLE}`}
                  size={ECheckboxSizes.SMALL}
                  label='Make this space unavailable for this time and date for other bookers'
                  data-testid='decline-rfp-unavailable'
                />
              )}

              <TextAreaBox isMobile={isMobile}>
                <ModalTitle>
                  {isCustomer
                    ? 'Why wasn’t the venues proposal suitable for you?'
                    : 'Would you like to add an additional message?'}
                </ModalTitle>

                <Textarea
                  name={FormValues.MESSAGE}
                  label={`Additional message${isOther ? '*' : ''}`}
                  placeholder='Additional message'
                  minHeight={isMobile ? 166 : 98}
                  data-testid='decline-rfp-message'
                />
                {isOther && <Required>(Required)</Required>}
              </TextAreaBox>

              {isRequest && !isCustomer && (
                <>
                  <ModalTitle>
                    Let the booker know who declined this request
                  </ModalTitle>
                  <NameInputBox>
                    <NW2FormItemInput
                      name={FormValues.RESOLVER_NAME}
                      type='text'
                      label='Your name*'
                      placeholder='Your name'
                      rules={requiredFieldRules('Your name')}
                      showAllValidationErrors
                    />
                    {isRequest && <Required>(Required)</Required>}
                  </NameInputBox>
                </>
              )}

              {showButtons && (
                <NMMSubmitSection
                  submitLabel={submitLabel}
                  cancelLabel={cancelLabel}
                  handleCancel={handleCancel}
                  isLoading={isSending}
                  gap={offsetDef}
                  justifyContent='flex-end'
                  margin='48px 0 0 0'
                />
              )}
            </form>
          );
        }}
      />
    </>
  );
}
