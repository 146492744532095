import React from 'react';
import styled from 'styled-components';

import Image from 'view/components/Image';
import CustomList from 'view/components/CustomList';
import OptionsImg from 'img/options_section.jpg';
import OnTheGoSvg from 'img/ON_THE_GO.svg';

import {
  blackColor,
  fontSizeMd,
  mdBp,
  NW2Gray800Color,
  offset80,
  offsetLg,
  offsetXLg,
  offsetXXLg,
  offsetXXXLg,
  smBp,
  whiteColor,
  xLgBp,
} from 'constants/styleVars';
import {
  NW2ContainerRow,
  NW2ContainerWithOffset,
  NW2Section,
} from 'view/mainLanding/MainLanding.styles';
import {StyledH2, StyledSubH2, StyledH3} from 'view/components/Typography';
import {CopyBlock} from 'view/components/CopyBlock';
import {StyledSpan} from 'view/components/Typography/Typography';

const employeeList = [
  {
    value: (
      <StyledSpan fontSize={fontSizeMd} white>
        Seamless and touchless check-in and check-out through QR code system
      </StyledSpan>
    ),
  },
  {
    value: (
      <StyledSpan fontSize={fontSizeMd} white>
        Ability to book work desks and meeting rooms through allocated business
        needs electronic wallet{' '}
      </StyledSpan>
    ),
  },
  {
    value: (
      <StyledSpan fontSize={fontSizeMd} white>
        Book and order additional services on sight through the platform and
        avoid invoicing concerns{' '}
      </StyledSpan>
    ),
  },
  {
    value: (
      <StyledSpan fontSize={fontSizeMd} white>
        Plan trips for yourself, your team or a group event for one off events,
        multiple or reoccurring meetings
      </StyledSpan>
    ),
  },
];

const businessList = [
  {
    value: (
      <StyledSpan fontSize={fontSizeMd} white>
        Configure NewWork Hub Manager to continuously procure best matching
        suppliers and simulate your business demand allocation
      </StyledSpan>
    ),
  },
  {
    value: (
      <StyledSpan fontSize={fontSizeMd} white>
        Enable automatic price and availability adjustments that suit your
        program long term or short term – you decide
      </StyledSpan>
    ),
  },
  {
    value: (
      <StyledSpan fontSize={fontSizeMd} white>
        Connect employees’ home address data to access historic bookings and
        configure new target settings
      </StyledSpan>
    ),
  },
  {
    value: (
      <StyledSpan fontSize={fontSizeMd} white>
        Adjust the size of electronic wallets for your employees based on
        consumption, needs and policy
      </StyledSpan>
    ),
  },
];

const OptionsImageWrapper = styled.div`
  position: relative;
  min-height: 400px;
  padding-right: ${offset80};
  margin-top: ${offsetXXXLg};
  margin-bottom: ${offsetXXXLg};

  &:after {
    content: url(${OnTheGoSvg});
    position: absolute;
    right: 8px;
    top: 0;
  }

  @media (min-width: ${smBp}px) {
    grid-column: span 2;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;

    &:after {
      content: none;
    }
  }
  @media (min-width: ${mdBp}px) {
    grid-column: span 1;
  }
`;

const Column2span = styled.div`
  @media (min-width: ${smBp}px) {
    grid-column: span 2;
  }

  @media (min-width: ${mdBp}px) {
    order: 2;
  }
`;

const DecoratedNW2Section = styled(NW2Section)`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 28%;
    left: 10%;
    right: 0;
    bottom: 40%;
    background: ${NW2Gray800Color};

    @media (min-width: ${mdBp}px) {
      top: 0;
      left: 20%;
      bottom: 39%;
    }
    @media (min-width: ${xLgBp}px) {
      top: 0;
      left: 28%;
      bottom: 42%;
    }
  }

  > * {
    position: relative;
  }
`;

const ListBox = styled.div`
  li:before {
    background-color: ${whiteColor};
  }
`;

export function OptionsSection() {
  return (
    <DecoratedNW2Section background={blackColor}>
      <NW2ContainerWithOffset>
        <NW2ContainerRow>
          <Column2span>
            <StyledH2 white>new strategy for enterprises</StyledH2>

            <StyledSubH2 white offset={offsetXLg}>
              Enable you and your employees to experience the type of the
              working environment that suits your needs through our powerful
              NewWork platform features.
            </StyledSubH2>
            <NW2ContainerRow offset={offsetXXLg}>
              <ListBox>
                <CopyBlock
                  title='FOR EMPLOYEES'
                  bodyText={
                    <CustomList
                      listStyle='unordered'
                      list={employeeList}
                      offset={offsetLg}
                    />
                  }
                  isDarkBackground
                />
              </ListBox>
              <ListBox>
                <CopyBlock
                  title='FOR BUSINESSES '
                  bodyText={
                    <CustomList
                      listStyle='unordered'
                      list={businessList}
                      offset={offsetLg}
                    />
                  }
                  isDarkBackground
                />
              </ListBox>
            </NW2ContainerRow>
          </Column2span>

          <OptionsImageWrapper>
            <Image isExpandToLeft height='100%' src={OptionsImg} />
          </OptionsImageWrapper>
        </NW2ContainerRow>
      </NW2ContainerWithOffset>

      <NW2ContainerWithOffset>
        <StyledH3 white>Options</StyledH3>
        <NW2ContainerRow offset={offsetXXXLg}>
          <div>
            <CopyBlock
              title='OFFICE EXCLUSIVE'
              subTitle={`List your office on NewWork for your employees exclusive bookings
               to monitor space utilisation.`}
              bodyText={`Not ready to give up your office locations for more flexible options just yet but need
               a way to monitor incoming employee traffic? We got you covered. NewWork allows you to list your
                venue for your employees to book exclusively both work desks and meeting rooms. Our innovative
                 solution enables your business to monitor your offices’ usage and our HRS representatives are on duty
                  to advise you with your future strategy once you have the necessary data to make a decision.`}
              isDarkBackground
            />
          </div>
          <div>
            <CopyBlock
              title='OFFICE EXCLUSIVE +'
              subTitle={`Allow your employees to book spaces not only at your office
               location but also alternative venue providers.`}
              bodyText={`NewWork helps to make sure your office locations never get overcrowded. In a post-pandemic
               world, it is important to track and trace your employees physical locations and provide alternative
                spaces if need be. Allow all or some of your employees to book workspaces with one of our external
                 providers for a specific event, season, or as part of your business strategy.`}
              isDarkBackground
            />
          </div>
          <div>
            <CopyBlock
              title='FREEDOM'
              subTitle={`Allow us to help you get rid of your brick-and-mortar office
               locations by allowing your employees to book externally.`}
              bodyText={`With sustainability in mind, we help businesses to connect your employees with more local
               workspace locations in order to avoid extensive and unnecessary travel to company offices. NewWork
                enables you to procure exclusive partnerships with our hotels and other co-working spaces where
                 your employees can work from where it makes most logistical sense. `}
              isDarkBackground
            />
          </div>
        </NW2ContainerRow>
      </NW2ContainerWithOffset>
    </DecoratedNW2Section>
  );
}
