import {EPriceType} from 'types/dto/IExtras.type';
import {replaceToComma} from 'utils/venueUtils';
import {EResourcesHashes} from './types';

export const checkHasAllData = (data: any): boolean => {
  const {bookableWith, price, priceType} = data;
  const freeItem = priceType === EPriceType.FREE;

  return (
    typeof bookableWith === 'object' &&
    !!bookableWith.length &&
    priceType !== '-' &&
    (price !== '-' || freeItem)
  );
};

export const convertExtraObj = (extra: any) => ({
  id: extra.extraId ?? extra.id,
  name: extra.name,
  extraType: extra.type ?? extra.extraType,
  bookableWith: (extra.bookableWith?.length && extra.bookableWith) || '-',
  price: extra.price ? `${extra.price}` : '-',
  priceType: extra.priceType ?? '-',
  isEnabled: extra.isEnabled ?? false,
  existingExtra: !!extra.extraId,
});

export const getPriceType = (pageType: EResourcesHashes) => {
  if (pageType === EResourcesHashes.EQUIPMENT) {
    return EPriceType.PER_UNIT;
  }

  if (pageType === EResourcesHashes.FOOD_AND_BEVERAGE) {
    return EPriceType.PER_PERSON;
  }
};

export const formatPriceTwoDigitsOrDash = (
  price: string | number,
  currencySymbol: string,
) => {
  if (price === '-') return '-';
  const priceToFixed = Number(price).toFixed(2);
  return `${replaceToComma(priceToFixed)} ${currencySymbol}`;
};
