import {useEffect} from 'react';

import {adminContainer} from 'app';
import {ICorporateAccountCompany} from 'store/admin/types';

const useGetCorporateAccountCompanyName = ({
  companyId,
  setCompanyName,
}: {
  companyId: number;
  setCompanyName: (name: string) => void;
}) => {
  useEffect(() => {
    const fetchCompanyById = async () => {
      try {
        adminContainer.getCorporateAccountCompanyById({
          payload: companyId,
          onSuccess: (company: ICorporateAccountCompany) => {
            const {name} = company;
            setCompanyName(name);
          },
          onError: () => {
            setCompanyName('');
          },
        });
      } catch (error) {}
    };

    if (companyId) {
      fetchCompanyById();
    }
  }, [companyId, setCompanyName]);
};

export default useGetCorporateAccountCompanyName;
