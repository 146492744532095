import styled from 'styled-components';

import {
  fontSizeXSm,
  fontWeightBold,
  lineHeightDefault,
  fontSizeDefault,
  offsetXXSm,
  offsetNone,
} from 'constants/styleVars';
import {getColorFromProps} from '../Typography/Typography';
import {TTextValueProps} from './types';

export const TextValue = styled.div<TTextValueProps>`
  display: ${({inline}) => (inline ? 'inline' : 'block')};
  font-weight: ${({bold}) => (bold ? fontWeightBold : 'normal')};
  font-size: ${({size, fontSize}) =>
    fontSize ? fontSize : size === 'small' ? fontSizeXSm : fontSizeDefault};
  line-height: ${({lineHeight}) =>
    lineHeight ? lineHeight : lineHeightDefault};
  word-break: break-word;
  ${({nowrap}) =>
    nowrap
      ? `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `
      : ''}

  color: ${(props) => (props.color ? props.color : getColorFromProps(props))};
  ${({cursor}) => (cursor ? `cursor: ${cursor}` : '')}
  ${({onClick}) =>
    onClick
      ? `
        &:hover {
          cursor: pointer;
        }
      `
      : ''}
  text-decoration: ${({textDecoration}) => textDecoration || 'none'};
  margin-top: ${({marginTop}) => marginTop || offsetXXSm};
  margin-bottom: ${({marginBottom}) => marginBottom || offsetNone};
`;

export default TextValue;
