import React from 'react';

import NW2Button from 'view/components/NW2Button';
import Icon from 'view/components/Icon';

import {
  ItemHead,
  ItemWrapper,
  Name,
  SpacesContainer,
  SpaceItem,
  ButtonContainer,
  StyledNavLink,
} from './FloorEditItem.styles';
import NW2PopoverDrawer, {
  ENW2PopoverDrawerPlacement,
} from 'view/components/NW2PopoverDrawer/NW2PopoverDrawer';
import {IFloorInfo} from 'types/venue';
import {Routes} from 'constants/routes';
import {useFloorUnits} from '../../hooks/useFloorUnits';
import useToggle from 'hooks/useToggle';

interface IProps {
  floorNumber: number;
  units: IFloorInfo;
  allowedDelete: boolean;
  venueId: number;
  hasAnyUnits: boolean;
  isExternalVenue: boolean;
  onDeleteFloors: (floorNumber: number) => void;
}

export const FloorEditItem = ({
  floorNumber,
  units,
  allowedDelete,
  hasAnyUnits,
  venueId,
  onDeleteFloors,
  isExternalVenue,
}: IProps) => {
  const [isPopoverShowed, setPopoverShowed] = useToggle(false);

  const {floorName, hasSpaces, meetingRoomsCount, workDesksCount} =
    useFloorUnits({units, floorNumber});

  const togglePopoverHandler = () => setPopoverShowed();

  const handleDeleteFloor = () => onDeleteFloors(floorNumber);

  const spaceRoute = `${
    isExternalVenue ? Routes.publicVenueSpaces : Routes.corporateVenueSpaces
  }?id=${venueId}#`;

  const popoverData = {
    header: <div>deletion not possible</div>,
    body: (
      <>
        <div>
          This floor contains spaces. Before you can delete the floor you need
          to delete spaces that it contains or move them to a different floor.
        </div>
        <StyledNavLink to={`${spaceRoute}desk`}>
          Manage my workdesk spaces
        </StyledNavLink>
        <StyledNavLink to={`${spaceRoute}room`}>
          Manage my meeting room spaces
        </StyledNavLink>
      </>
    ),
  };

  return (
    <ItemWrapper>
      <ItemHead hasAnyUnits={hasAnyUnits}>
        <Name>{floorName}</Name>
        <SpacesContainer hasSpaces={hasSpaces}>
          {hasSpaces && (
            <>
              {!!workDesksCount && (
                <SpaceItem>Workdesks: {workDesksCount}</SpaceItem>
              )}
              {!!meetingRoomsCount && (
                <SpaceItem>Meeting rooms: {meetingRoomsCount}</SpaceItem>
              )}
            </>
          )}
        </SpacesContainer>
        <ButtonContainer>
          <NW2Button
            buttonType='tertiary'
            size='extraSmall'
            disabled={!allowedDelete || hasSpaces}
            onClick={handleDeleteFloor}
            data-testid={`floor-${floorNumber}-delete-button`}
          >
            delete floor
          </NW2Button>
          <NW2PopoverDrawer
            open={isPopoverShowed}
            header={popoverData.header}
            body={popoverData.body}
            placement={ENW2PopoverDrawerPlacement.BOTTOM}
            isMobile={false}
            handleVisibleChange={togglePopoverHandler}
            onClose={togglePopoverHandler}
          >
            {hasSpaces && <Icon icon='NW2_INFO_CIRCLE' />}
          </NW2PopoverDrawer>
        </ButtonContainer>
      </ItemHead>
    </ItemWrapper>
  );
};
