import {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import {
  EBookableWith,
  IExtrasOption,
  IExtrasResponse,
  EResourcesCode,
  EResourcesType,
} from 'types/dto/IExtras.type';
import {moveToEndWhere} from 'view/venue/helpers/spaces';
import {
  ECreateOfferFormFields,
  IRequestMeeting,
  TInitialExtras,
} from '../types';
import {FREE, PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {IOfferUnitExtra} from 'types/offer';

export interface IReturnUnitsExtrasProps {
  allEquipmentExtras: IExtrasOption[];
  allCateringExtras: IExtrasOption[];
  initialExtras: Record<string, TInitialExtras>;
  allInitialExtras: TInitialExtras;
  filteredAvailableExtras: IExtrasResponse[];
}

interface IProps {
  requestMeetings: IRequestMeeting[];
  extrasOption: IExtrasOption[];
}
export function useUnitsExtras({
  requestMeetings,
  extrasOption,
}: IProps): IReturnUnitsExtrasProps {
  const availableExtras: IExtrasResponse[] = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.extras`),
  );

  const allUnitsExtras = useMemo(() => {
    /**
     * collect all unique extraId from all units
     * we should fill all extras prices only once for 1st day unit
     */
    const result = new Set();

    requestMeetings.forEach(({extras = [], foodAndBeverage = []}) => {
      [...extras, ...foodAndBeverage].forEach(({extraId}) =>
        result.add(extraId),
      );
    });

    return [...result];
  }, [requestMeetings]);

  const getFilteredAllExtrasByType = useCallback(
    (type: EResourcesType): IExtrasOption[] => {
      if (!allUnitsExtras.length || !extrasOption.length) return [];

      return extrasOption.filter((extra) =>
        allUnitsExtras.some((item) => extra.id === item && extra.type === type),
      );
    },
    [allUnitsExtras, extrasOption],
  );

  const {allEquipmentExtras, allCateringExtras} = useMemo(
    () => ({
      allEquipmentExtras: moveToEndWhere(
        // move wi-fi at the end
        getFilteredAllExtrasByType(EResourcesType.EQUIPMENT),
        'code',
        EResourcesCode.WIFI,
      ),

      allCateringExtras: getFilteredAllExtrasByType(
        EResourcesType.FOOD_AND_BEVERAGE,
      ),
    }),
    [getFilteredAllExtrasByType],
  );

  const filteredAvailableExtras = useMemo(
    () =>
      availableExtras.filter(
        ({isEnabled, bookableWith, extraType}) =>
          isEnabled &&
          bookableWith.includes(EBookableWith.MEETING_ROOM) &&
          extraType !== EResourcesType.BEDROOM,
      ),
    [availableExtras],
  );

  const getItems = useCallback(
    (extras: IExtrasOption[], unitExtras?: IOfferUnitExtra[]) => {
      const allExtras = unitExtras?.length
        ? extras.filter((el) => unitExtras?.some((f) => f.code === el.code))
        : extras;

      return Object.assign(
        {},
        ...allExtras.map((item) => {
          const extraItem = filteredAvailableExtras.find(
            ({code}) => code === item.code,
          );

          return {
            [item.name]: extraItem ? extraItem.price || FREE : '',
          };
        }),
      );
    },
    [filteredAvailableExtras],
  );

  const initialExtras = useMemo(() => {
    // prefill requested extras prices from available extras
    const result: Record<string, TInitialExtras> = {};

    requestMeetings.forEach((unit) => {
      result[unit.id] = {
        [ECreateOfferFormFields.PRICES_EQUIPMENT]: getItems(
          allEquipmentExtras,
          unit.extras,
        ),
        [ECreateOfferFormFields.PRICES_FOOD_AND_BEVERAGE]: getItems(
          allCateringExtras,
          unit.foodAndBeverage,
        ),
      };
    });

    return result;
  }, [allCateringExtras, allEquipmentExtras, getItems, requestMeetings]);

  const allInitialExtras = useMemo(() => {
    // prefill requested extras prices from available extras
    return {
      [ECreateOfferFormFields.PRICES_EQUIPMENT]: getItems(allEquipmentExtras),
      [ECreateOfferFormFields.PRICES_FOOD_AND_BEVERAGE]:
        getItems(allCateringExtras),
    };
  }, [allCateringExtras, allEquipmentExtras, getItems]);

  return {
    allEquipmentExtras,
    allCateringExtras,
    initialExtras,
    allInitialExtras,
    filteredAvailableExtras,
  };
}
