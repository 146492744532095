import {DateTime} from 'luxon';
import {useEffect, useMemo, useState} from 'react';

import useMeetingRoomData from 'view/components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/hooks/useMeetingRoomData';

import {ITimeData} from 'types/dto/ISearch.types';
import {checkInTime} from 'constants/defaultOperationalTimes';
import {getISOString} from 'utils/dateUtils';
import {TSearchCriteria} from 'types/search';
import {getVenuesListQuerySearchData} from 'utils/queryUtils';
import {EEventType} from 'types/venue';
import {IRequestDay} from 'types/offer';
import {useOfferRequestDetails} from './useOfferRequestDetails';

interface IProps {
  searchCriteria: TSearchCriteria;
  meetingRoomCapacity: number;
  searchString: string;
  latitude: string;
  longitude: string;
}

export const useShowAlternatives = ({
  searchCriteria,
  meetingRoomCapacity,
  searchString,
  latitude,
  longitude,
}: IProps) => {
  const {offerDetails} = useOfferRequestDetails();

  const requestedUnits = offerDetails.days;

  const [requestedTimeData, setRequestedTimeData] = useState<ITimeData[]>([]);
  const {meetingRequestData} = useMeetingRoomData(requestedTimeData);

  const startDate = DateTime.fromISO(
    getISOString(requestedUnits.at(0)?.checkIn as unknown as Date),
  );
  const endDate = DateTime.fromISO(
    getISOString(requestedUnits.at(-1)?.checkIn as unknown as Date),
  );
  const isMultiRooms = !startDate.hasSame(endDate, 'day');

  const startDayStartOf = startDate.startOf('day');

  // adjust past dates
  const initStartDate = useMemo(
    () =>
      DateTime.fromISO(checkInTime).set({
        hour: startDate.hour,
      }),
    [startDate.hour],
  );

  const isDateCurrent = startDayStartOf >= DateTime.now().startOf('day');

  const currentStartDate = useMemo(
    () => getISOString(isDateCurrent ? startDate : initStartDate),
    [initStartDate, isDateCurrent, startDate],
  );

  const currentEndDate = useMemo(() => {
    const startEndDifference = Math.round(
      endDate.endOf('day').diff(startDayStartOf, 'days').days,
    );

    return getISOString(
      isDateCurrent
        ? endDate
        : initStartDate
            .plus({days: startEndDifference - 1})
            .set({hour: endDate.hour}),
    );
  }, [endDate, initStartDate, isDateCurrent, startDayStartOf]);
  //end adjust past dates

  const querySearchData = getVenuesListQuerySearchData({
    searchCriteria: {
      ...searchCriteria,
      endDate: currentEndDate,
      startDate: currentStartDate,
      meetingRoomCapacity,
      searchString,
      latitude,
      longitude,
      multiRooms: isMultiRooms,
    },
  });

  useEffect(() => {
    if (isMultiRooms) {
      setRequestedTimeData(
        [...requestedUnits].reduce<ITimeData[]>(
          (acc: ITimeData[], day: IRequestDay, index) => {
            const timeStart = getISOString(
              DateTime.fromJSDate(day.checkIn as unknown as Date).set({
                year: DateTime.fromISO(currentStartDate).year,
                month: DateTime.fromISO(currentStartDate).month,
                day: DateTime.fromISO(currentStartDate).day + index,
              }),
            );
            const timeEnd = getISOString(
              DateTime.fromJSDate(day.checkOut as unknown as Date).set({
                year: DateTime.fromISO(currentStartDate).year,
                month: DateTime.fromISO(currentStartDate).month,
                day: DateTime.fromISO(currentStartDate).day + index,
              }),
            );

            const isMeetingDay = day.eventType === EEventType.DAY;

            if (isMeetingDay) {
              acc.push({
                timeStart,
                timeEnd,
                eventType: EEventType.DAY,
              });
            }
            return acc;
          },
          [],
        ),
      );
    } else {
      setRequestedTimeData([
        {
          timeStart: currentStartDate,
          timeEnd: currentEndDate,
          eventType: EEventType.DAY,
        },
      ]);
    }
  }, [currentEndDate, currentStartDate, isMultiRooms, requestedUnits]);

  return {
    querySearchData,
    isMultiRooms,
    requestedTimeData,
    meetingRequestData,
  };
};
