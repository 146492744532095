import React, {SyntheticEvent} from 'react';

import {EMenuItemSizes} from './MenuItem.types';
import {MenuListItem, Triangle} from './MenuItem.styles';

interface IProps {
  itemName: string;
  itemHref?: string;
  isActive: boolean;
  size?: EMenuItemSizes;
  handleClick: (e: SyntheticEvent) => void;
  isHighlighted?: boolean;
  hasErrors?: boolean;
}

export function MenuItem({
  itemName,
  itemHref,
  isActive,
  size = EMenuItemSizes.MEDIUM,
  handleClick,
  isHighlighted,
  hasErrors,
}: IProps) {
  return (
    <MenuListItem
      isActive={isActive}
      isHighlighted={isHighlighted}
      hasErrors={hasErrors}
      size={size}
      data-testid={`sidebar-link-${itemName}`}
    >
      <a
        href={itemHref ? `#${itemHref}` : undefined}
        onClick={handleClick}
        aria-label={`go to section ${itemName}`}
      >
        {itemName}
      </a>
      {hasErrors && !isHighlighted && <Triangle />}
    </MenuListItem>
  );
}
