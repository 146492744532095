import React from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import {NW2FormItemInput} from 'view/components/NW2FormItem/NW2FormItem';

import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {IAmenity} from 'types/dto/IAmenity';
import {
  requiredFieldRules,
  requiredPriceRules,
} from 'utils/finalFormFieldRules';
import {TPaidDuration} from '../types';
import {PageFormSection, StyledSelect} from '../styles';
import {useInputPriceChange} from 'view/venue/hooks/useInputPriceChange';

interface IProps {
  paidDurationOptions: TPaidDuration[];
  priceHourFieldName: string;
  priceHalfDayFieldName: string;
  priceDayFieldName: string;
  paidDurationFieldName: string;
}
export function AddRoomPrice({
  paidDurationOptions,
  priceHourFieldName,
  priceHalfDayFieldName,
  priceDayFieldName,
  paidDurationFieldName,
}: IProps) {
  const currency: IAmenity[] = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.currency`),
  );

  const {onChangePrice, onBlurPrice} = useInputPriceChange();

  return (
    <PageFormSection>
      <NW2FormItemInput
        type='text'
        label={`Per hour in ${currency}`}
        placeholder={`Per hour in ${currency}`}
        name={priceHourFieldName}
        rules={requiredPriceRules(
          priceHourFieldName,
          'Please provide per hour price',
        )}
        onChange={onChangePrice(priceHourFieldName)}
        onBlur={onBlurPrice(priceHourFieldName)}
      />

      <StyledSelect
        name={paidDurationFieldName}
        label='Minimum duration to be paid'
        placeholder='Minimum duration to be paid'
        options={paidDurationOptions}
        rules={requiredFieldRules(paidDurationFieldName)}
        maxHeight='330px'
        readonly
      />

      <NW2FormItemInput
        type='text'
        label={`Per half-day in ${currency}`}
        placeholder={`Per half-day in ${currency}`}
        name={priceHalfDayFieldName}
        rules={requiredPriceRules(
          priceHalfDayFieldName,
          'Please provide per half-day price',
        )}
        onChange={onChangePrice(priceHalfDayFieldName)}
        onBlur={onBlurPrice(priceHalfDayFieldName)}
      />

      <NW2FormItemInput
        type='text'
        label={`Per day in ${currency}`}
        placeholder={`Per day in ${currency}`}
        name={priceDayFieldName}
        rules={requiredPriceRules(
          priceDayFieldName,
          'Please provide per day price',
        )}
        onChange={onChangePrice(priceDayFieldName)}
        onBlur={onBlurPrice(priceDayFieldName)}
      />
    </PageFormSection>
  );
}
