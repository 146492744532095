import {IDayOfWeek} from '../types/dto/IDay.type';

export const OPERATIONAL_HOURS_TIME_FORMAT = 'HH:mm';

export const Days: {[key: string]: IDayOfWeek} = {
  SUNDAY: 'SUNDAY', // Sunday goes first! JS Date.getDay method support.
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
};

export enum EDays {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export const ALLOWED_DAYS_DIFFERENCE_FOR_RFP = -4;
