import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';
import {DateTime} from 'luxon';

import {getPreselectedFoodBeverage} from '../components/AddFoodBeverageRequest/helpers';
import {IExtrasOption} from 'types/dto/IExtras.type';
import {getPriorityRoom} from '../components/utils';
import {DEFAULT_END_TIME, ITimeData} from 'types/dto/ISearch.types';
import {
  getDayCopy,
  getTimeDataItem,
} from 'view/components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/utils';

import DateUtils, {getISOString} from 'utils/dateUtils';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {getPreselectedAccommodation} from '../components/AddAccommodationRequest/helpers';
import {
  TBedroomsCatering,
  THandleSetBedroomsCatering,
} from '../components/AddAccommodationRequest/types';
import {ACCOMMODATION_FORM_FIELD_NAME} from 'constants/bedrooms';
import {
  setEndDateForVenuesSearch,
  setMultiSearchTimeData,
  setStartDateForVenuesSearch,
} from 'store/search/searchSlice';
import {TDay, TMeetingRoomItem} from 'types/search';
import {useMultiSearchData} from 'view/venue/hooks/useMultiSearchData';

import {setMeetingRequestData as setMultiMeeting} from 'store/search/searchSlice';
import LocalStorageService from 'infra/common/localStorage.service';
import {getDateTime} from 'utils/dateUtils';
import {getFilteredUnitsByEventType} from 'utils/venueUtils';

interface IUpdateParticipantsArgs {
  dayIndex: number;
  participants: number;
  isFoodBeverageEnabled?: boolean;
  isCustomerChangedFoodAndBeverage?: boolean;
  isAccommodationEnabled?: boolean;
  isCustomerChangedAccommodation?: boolean;
}

export interface IUseMeetingRoomData {
  meetingRequestData: TDay[];
  preMeetDaysCount: number;
  maxParticipants: number;
  addDay: (isPreMeet: boolean) => () => void;
  setIsSectionExpanded: (
    dayIndex: number,
  ) => (isSectionExpanded: boolean) => void;
  deleteDay: (dayIndex: number, isDayToDelete: boolean) => () => void;
  deleteRoom: (dayIndex: number) => (roomIndex: number) => void;
  deleteAllRoom: (dayIndex: number) => () => void;
  deleteFoodBeverage: (dayIndex: number) => () => void;
  addRoom: (dayIndex: number) => () => void;
  setDefaultRoomList: (dayIndex: number) => () => void;
  setSelectedEquipment: (
    dayIndex: number,
  ) => (
    roomIndex: number,
    formData?: Record<string, any>,
    isAccommodationEnabled?: boolean,
    isCustomerChangedFoodAndBeverage?: boolean,
    isCustomerChangedAccommodation?: boolean,
  ) => void;
  setSelectedFoodBeverage: (
    dayIndex: number,
  ) => (formData?: Record<string, any>, dayParticipants?: number) => void;
  addAccommodation: (
    dayIndex: number,
    isPreOrPostMeet?: boolean,
    dayParticipants?: number,
  ) => (formData?: Record<string, any>) => void;
  removeAccommodation: (dayIndex: number) => () => void;
  bedroomsCatering: TBedroomsCatering;
  handleSetBedroomsCatering: THandleSetBedroomsCatering;
  removeBedroomsCatering: (date: string) => void;
  updateParticipants: (args: IUpdateParticipantsArgs) => void;
}

const useMeetingRoomData = (timeData: ITimeData[]) => {
  const dispatch = useAppDispatch();

  const [meetingRequestData, setMeetingRequestData] = useState<TDay[]>([]);

  const [bedroomsCatering, setBedroomsCatering] = useState<TBedroomsCatering>(
    {},
  );

  const participants = useAppSelector(
    ({search}) => search.searchCriteria.meetingRoomCapacity,
  );

  const allExtrasOptions: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );

  const {defaultEquipmentData, defaultSeatsSetup, equipmentFilteredOptions} =
    useMultiSearchData();

  // Constants & Functions

  const preMeetDaysCount = meetingRequestData.reduce(
    (count, {isPreMeet}) => (isPreMeet ? count + 1 : count),
    0,
  );

  const getPickerDate = (days: TDay[], targetDay: 'start' | 'end') => {
    const meetDays = days.filter(
      ({isPostMeet, isPreMeet}) => !isPostMeet && !isPreMeet,
    );
    return targetDay === 'start'
      ? meetDays[0].startDate
      : (meetDays.at(-1)?.startDate as string);
  };

  const maxParticipants = Math.max(
    ...meetingRequestData.flatMap(({rooms}) =>
      rooms.map((room) => room.participants),
    ),
  );

  const updateMultiSearchData = (
    updatedState: TDay[],
    updatedTimeData: ITimeData[],
  ) => {
    LocalStorageService.setByKey(
      'multiSearchData',
      JSON.stringify({
        meetingRequestData: updatedState,
        timeData: updatedTimeData,
      }),
    );

    dispatch(setMultiSearchTimeData(updatedTimeData));
    dispatch(setMultiMeeting(updatedState));
  };

  const getUpdatedFoodBeverageData = useCallback(
    (
      rooms: TMeetingRoomItem[],
      dayIndex: number,
      isPreselectedCase?: boolean,
    ) => {
      const {participants, timeStart, timeEnd} = getPriorityRoom(rooms);
      const duration = DateUtils.getTotalHours(timeStart, timeEnd);
      const currentFoodAndBeverageData =
        meetingRequestData[dayIndex].foodBeverageData;

      const {ids, data, options} = getPreselectedFoodBeverage(
        duration,
        participants,
        allExtrasOptions,
      );

      return {
        ids: isPreselectedCase ? ids : currentFoodAndBeverageData?.ids || [],
        options,
        data: isPreselectedCase
          ? data
          : {
              ...data,
              ...currentFoodAndBeverageData?.ids.reduce(
                (prev, curr) => ({
                  ...prev,
                  [`${curr}_`]:
                    currentFoodAndBeverageData?.data[`${curr}_`] ||
                    participants,
                }),
                {},
              ),
              foodAndBeverage: currentFoodAndBeverageData?.data.foodAndBeverage,
            },
      };
    },
    [allExtrasOptions, meetingRequestData],
  );
  // Handlers

  const addDay = (isPreMeet: boolean) => () => {
    const dayIndex = isPreMeet ? 0 : meetingRequestData.length - 1;
    const updatedTimeData = structuredClone(timeData);
    let updatedState: TDay[];

    const updatedMeetingRequestData = meetingRequestData.reduce<TDay[]>(
      (acc, item) => {
        if (item) {
          acc.push({
            ...item,
            isSectionExpanded: false,
          });
        }

        return acc;
      },
      [],
    );

    if (isPreMeet) {
      updatedState = [
        getDayCopy(updatedMeetingRequestData, dayIndex, -1),
        ...updatedMeetingRequestData,
      ];

      dispatch(
        setStartDateForVenuesSearch(getPickerDate(updatedState, 'start')),
      );
      updatedTimeData.unshift(getTimeDataItem(timeData[dayIndex], -1));
    } else {
      updatedState = [
        ...updatedMeetingRequestData,
        getDayCopy(updatedMeetingRequestData, dayIndex, 1),
      ];

      const endDate = getISOString(
        getDateTime(getPickerDate(updatedState, 'end')).set({
          hour: DEFAULT_END_TIME,
        }),
      );

      dispatch(setEndDateForVenuesSearch(endDate));
      updatedTimeData.push(getTimeDataItem(timeData[dayIndex], 1));
    }

    setMeetingRequestData(updatedState);
    updateMultiSearchData(updatedState, updatedTimeData);
  };

  const addRoom = (dayIndex: number) => () => {
    const lastIndexOfRoom = meetingRequestData[dayIndex].rooms.length - 1;
    const copyOfLastRoom = {
      ...meetingRequestData[dayIndex].rooms[lastIndexOfRoom],
      timeStart: timeData[dayIndex].timeStart,
      timeEnd: timeData[dayIndex].timeEnd,
    };

    const updatedState = meetingRequestData.map((day) => {
      const updatedRooms = [...day.rooms, {...copyOfLastRoom}];

      const updatedDay = {
        ...day,
        rooms: updatedRooms,
      };

      return day.startDate === meetingRequestData[dayIndex].startDate
        ? updatedDay
        : day;
    });

    setMeetingRequestData(updatedState);
  };

  const deleteRoom = (dayIndex: number) => (roomIndex: number) => {
    const updatedTimeData = structuredClone(timeData);

    const updatedState = meetingRequestData.map((day, index) => {
      const filteredRooms = day.rooms.filter((_, index) => index !== roomIndex);

      const updatedFoodBeverage =
        !filteredRooms.length || !day.foodBeverageData
          ? null
          : getUpdatedFoodBeverageData(filteredRooms, index);

      return day.startDate === meetingRequestData[dayIndex].startDate
        ? {
            ...day,
            rooms: filteredRooms,
            foodBeverageData: updatedFoodBeverage,
          }
        : day;
    });

    const isDayDelete = updatedState[dayIndex].rooms.length === 0;

    if (isDayDelete) {
      const getFilteredArray = <T,>(array: T[]) => {
        return array.filter((_, index) => dayIndex !== index);
      };

      const filteredState = getFilteredArray(updatedState);
      const filteredTimeData = getFilteredArray(updatedTimeData);

      dispatch(
        setStartDateForVenuesSearch(getPickerDate(filteredState, 'start')),
      );
      dispatch(setEndDateForVenuesSearch(getPickerDate(filteredState, 'end')));

      updateMultiSearchData(filteredState, filteredTimeData);
      setMeetingRequestData(filteredState);
      return;
    }

    updateMultiSearchData(updatedState, updatedTimeData);
    setMeetingRequestData(updatedState);
  };

  const deleteAllRoom = (dayIndex: number) => () => {
    const updatedState = meetingRequestData.map((day) =>
      day.startDate === meetingRequestData[dayIndex].startDate
        ? {...day, rooms: [], foodBeverageData: null}
        : day,
    );

    setMeetingRequestData(updatedState);
  };

  const deleteDay = (dayIndex: number, isDayToDelete: boolean) => () => {
    if (!isDayToDelete) return;

    const isPreMeet = dayIndex === 0;
    const updatedTimeData = structuredClone(timeData);

    const updatedState = meetingRequestData.filter(
      (_, index) => index !== dayIndex,
    );

    if (isPreMeet) {
      dispatch(
        setStartDateForVenuesSearch(getPickerDate(updatedState, 'start')),
      );
      updatedTimeData.shift();
    } else {
      const endDate = getISOString(
        getDateTime(getPickerDate(updatedState, 'start')).set({
          hour: DEFAULT_END_TIME,
        }),
      );
      dispatch(setEndDateForVenuesSearch(endDate));

      updatedTimeData.pop();
    }

    updateMultiSearchData(updatedState, updatedTimeData);
    setMeetingRequestData(updatedState);
  };

  const deleteFoodBeverage = (dayIndex: number) => () => {
    const updatedState = meetingRequestData.map((day) =>
      day.startDate === meetingRequestData[dayIndex].startDate
        ? {...day, foodBeverageData: null}
        : day,
    );
    setMeetingRequestData(updatedState);
  };

  const setDefaultRoomList = (dayIndex: number) => () => {
    const updatedState = meetingRequestData.map((day) =>
      day.startDate === meetingRequestData[dayIndex].startDate
        ? {
            ...day,
            rooms: [
              {
                timeStart: timeData[dayIndex].timeStart,
                timeEnd: timeData[dayIndex].timeEnd,
                participants,
                equipmentData: defaultEquipmentData,
                seatsSetup: defaultSeatsSetup,
              },
            ],
            isSectionExpanded: false,
          }
        : day,
    );

    setMeetingRequestData(updatedState);
  };

  const setSelectedFoodBeverage = useCallback(
    (dayIndex: number) =>
      (formData?: Record<string, any>, dayParticipants?: number) => {
        const {timeStart, timeEnd} = getPriorityRoom(
          meetingRequestData[dayIndex].rooms,
        );

        const participants =
          dayParticipants || meetingRequestData[dayIndex].participants;

        const duration = DateUtils.getTotalHours(timeStart, timeEnd);

        const {ids, data, options} = getPreselectedFoodBeverage(
          duration,
          participants,
          allExtrasOptions,
        );

        const updatedFoodBeverageState = !formData
          ? {options, ids, data}
          : formData.foodAndBeverage?.length
          ? {
              options,
              ids: formData.foodAndBeverage,
              data: formData,
            }
          : null;

        const updatedState = meetingRequestData.map((day) =>
          day.startDate === meetingRequestData[dayIndex].startDate
            ? {...day, participants, foodBeverageData: updatedFoodBeverageState}
            : day,
        );

        setMeetingRequestData(updatedState);
      },
    [allExtrasOptions, meetingRequestData],
  );

  const setIsSectionExpanded =
    (dayIndex: number) => (isSectionExpanded: boolean) => {
      const updatedState = meetingRequestData.map((day) =>
        day.startDate === meetingRequestData[dayIndex].startDate
          ? {
              ...day,
              isSectionExpanded,
            }
          : day,
      );
      setMeetingRequestData(updatedState);
    };

  // accommodation
  const addAccommodation = useCallback(
    (dayIndex: number, isPreOrPostMeet?: boolean, dayParticipants?: number) =>
      (formData?: Record<string, any>) => {
        const participants =
          dayParticipants || meetingRequestData[dayIndex].participants;
        const meetingParticipants = isPreOrPostMeet
          ? maxParticipants
          : participants;

        const {ids, data, options} =
          getPreselectedAccommodation(meetingParticipants);

        const accommodationData = !formData
          ? {options, ids, data}
          : formData[ACCOMMODATION_FORM_FIELD_NAME]?.length
          ? {
              options,
              ids: formData[ACCOMMODATION_FORM_FIELD_NAME],
              data: formData,
            }
          : null;

        setMeetingRequestData((prev) =>
          prev.map((day) =>
            day.startDate === meetingRequestData[dayIndex].startDate
              ? {...day, participants, accommodationData}
              : day,
          ),
        );
      },
    [maxParticipants, meetingRequestData],
  );

  const removeAccommodation = (dayIndex: number) => () => {
    setMeetingRequestData((prev) =>
      prev.map((day) =>
        day.startDate === meetingRequestData[dayIndex].startDate
          ? {...day, accommodationData: null}
          : day,
      ),
    );
  };

  const setSelectedEquipment = useCallback(
    (dayIndex: number) =>
      (
        roomIndex: number,
        formData?: Record<string, any>,
        isAccommodationEnabled?: boolean,
        isCustomerChangedFoodAndBeverage?: boolean,
        isCustomerChangedAccommodation?: boolean,
      ) => {
        const updatedEquipment =
          formData && roomIndex !== undefined && formData.equipment
            ? {
                options: equipmentFilteredOptions,
                ids: formData.equipment,
                data: formData,
              }
            : defaultEquipmentData;

        const updatedParticipants = updatedEquipment.data.participants;
        const updatedState = meetingRequestData.map((day, index) => {
          let isTimeChanged = false;
          let isParticipantsChanged = false;
          const updatedRooms = day.rooms.map((room, index) => {
            if (
              room.timeEnd !== updatedEquipment.data.timeRange.timeEnd ||
              room.timeStart !== updatedEquipment.data.timeRange.timeStart
            ) {
              isTimeChanged = true;
            }

            if (
              roomIndex === index &&
              updatedParticipants !== room.participants
            ) {
              isParticipantsChanged = true;
            }

            return roomIndex === index
              ? {
                  timeStart: getISOString(
                    DateTime.fromJSDate(
                      updatedEquipment.data.timeRange.timeStart,
                    ),
                  ),
                  timeEnd: getISOString(
                    DateTime.fromJSDate(
                      updatedEquipment.data.timeRange.timeEnd,
                    ),
                  ),
                  participants: updatedParticipants,
                  equipmentData: updatedEquipment,
                  seatsSetup: updatedEquipment.data.seatsSetup,
                }
              : room;
          });

          const dayParticipants =
            updatedRooms.length && getPriorityRoom(updatedRooms).participants;
          const updatedFoodBeverageData =
            day.foodBeverageData && (isTimeChanged || isParticipantsChanged)
              ? getUpdatedFoodBeverageData(
                  updatedRooms,
                  index,
                  !isCustomerChangedFoodAndBeverage,
                )
              : day.foodBeverageData;

          const updatedAccommodationData =
            isAccommodationEnabled && !isCustomerChangedAccommodation
              ? getPreselectedAccommodation(dayParticipants)
              : day.accommodationData;

          const updatedStartDate = DateUtils.findMinMaxTime(
            updatedRooms,
            'timeStart',
            false,
          );
          const updatedEndDate = DateUtils.findMinMaxTime(
            updatedRooms,
            'timeEnd',
            true,
          );

          return day.startDate === meetingRequestData[dayIndex].startDate
            ? {
                ...day,
                participants: dayParticipants,
                accommodationData: updatedAccommodationData,
                foodBeverageData: updatedFoodBeverageData,
                startDate: updatedStartDate,
                endDate: updatedEndDate,
                rooms: updatedRooms,
              }
            : day;
        });

        setMeetingRequestData(updatedState);
      },
    [
      meetingRequestData,
      setMeetingRequestData,
      defaultEquipmentData,
      equipmentFilteredOptions,
      getUpdatedFoodBeverageData,
    ],
  );

  const handleSetBedroomsCatering: THandleSetBedroomsCatering = ({
    roomType,
    name,
    preName,
    qty,
    isEnabled,
    date,
  }) => {
    setBedroomsCatering((prev) => {
      const updatedBedroomsCatering = {
        ...prev,
        [date]: {
          ...prev[date],
          [roomType]: {
            ...prev[date]?.[roomType],
            name: name || prev[date]?.[roomType]?.name,
            preName: preName || prev[date]?.[roomType]?.preName,
            qty: qty ?? prev[date]?.[roomType]?.qty,
            isEnabled: isEnabled ?? prev[date]?.[roomType]?.isEnabled,
          },
        },
      };

      LocalStorageService.setByKey(
        'bedroomsCatering',
        JSON.stringify(updatedBedroomsCatering),
      );

      return updatedBedroomsCatering;
    });
  };

  const removeBedroomsCatering = (date: string) => {
    // @ts-ignore
    setBedroomsCatering((prev) => ({
      ...prev,
      [date]: null,
    }));
  };

  const updateParticipants = useCallback(
    ({
      dayIndex,
      participants,
      isFoodBeverageEnabled,
      isCustomerChangedFoodAndBeverage,
      isAccommodationEnabled,
      isCustomerChangedAccommodation,
    }: IUpdateParticipantsArgs) => {
      const updatedState = meetingRequestData.map((day, index) =>
        index === dayIndex
          ? {
              ...day,
              participants,
            }
          : day,
      );
      setMeetingRequestData(updatedState);
      if (isFoodBeverageEnabled && !isCustomerChangedFoodAndBeverage) {
        setSelectedFoodBeverage(dayIndex)(undefined, participants);
      }
      if (isAccommodationEnabled && !isCustomerChangedAccommodation) {
        addAccommodation(dayIndex, undefined, participants)();
      }
    },
    [
      meetingRequestData,
      setMeetingRequestData,
      setSelectedFoodBeverage,
      addAccommodation,
    ],
  );

  // Effects

  //provide initial timeData for component on first render, with fetched equipmentData
  useEffect(() => {
    const meetTimeData = getFilteredUnitsByEventType(timeData);

    const initialData = meetTimeData.map(({timeStart, timeEnd}, index) => {
      return {
        startDate: timeStart,
        endDate: timeEnd,
        rooms: [
          {
            timeStart,
            timeEnd,
            participants,
            equipmentData: defaultEquipmentData,
            seatsSetup: defaultSeatsSetup,
          },
        ],
        foodBeverageData: null,
        accommodationData: null,
        isPreMeet: false,
        isPostMeet: false,
        isSectionExpanded: !index,
        participants,
      };
    });

    setMeetingRequestData(initialData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultEquipmentData]);

  return {
    addDay,
    addRoom,
    deleteDay,
    deleteRoom,
    deleteAllRoom,
    deleteFoodBeverage,
    setDefaultRoomList,
    setSelectedEquipment,
    setSelectedFoodBeverage,
    setIsSectionExpanded,
    meetingRequestData,
    addAccommodation,
    removeAccommodation,
    bedroomsCatering,
    maxParticipants,
    preMeetDaysCount,
    handleSetBedroomsCatering,
    removeBedroomsCatering,
    updateParticipants,
  };
};

export default useMeetingRoomData;
