import React from 'react';
import {useDispatch} from 'react-redux';
import _get from 'lodash/get';

import DateUtils from 'utils/dateUtils';
import TruncateText from 'view/components/TruncateText';
import PreviewExtrasList from 'view/venue/components/PreviewExtrasList';

import {findCoverImage, pluralize} from 'utils/helpers';
import {setSpaceDetailsId} from 'store/venues/actions';
import {ERoomSchemaNames, ERoomSchema} from 'types/venue';
import {EAspectRation} from 'view/components/Image/Image';
import {ISummaryUnit} from 'types/dto/IBooking.types';
import {UNIT_NAME_TYPE_MAP} from 'constants/venue';
import {ERoomType} from 'types/dto/ERoomType.type';
import {Group} from 'styles/basicStyledComponents/group';
import {fontSizeMd, fontWeightBold} from 'styles/configs/typography';
import {TextValue} from 'view/components/TextValue';
import {Link, StyledSpan} from 'view/components/Typography';
import {borderRadiusDef} from 'styles/configs/variables';
import {offsetXSm, offsetXXSm} from 'styles/configs/offset';
import {IOfferUnitExtra} from 'types/offer';
import {useAppSelector} from 'store/hooks';
import {EResourcesType, IExtrasOption} from 'types/dto/IExtras.type';
import {
  Container,
  PreviewExtraList,
  RoomTitle,
  StyledNW2Card,
  Title,
  TitleWrapper,
  Wrapper,
} from './NW2BookingConfirmationUnitsBlock.styles';
import {IExtraResponse} from 'types/dto/IPublicVenue';

type TProps = {
  units: ISummaryUnit[];
  bedrooms?: IOfferUnitExtra[] | IExtraResponse[];
  foodAndBeverage?: IOfferUnitExtra[] | IExtraResponse[];
};

function Nw2BookingUnits({units, bedrooms, foodAndBeverage}: TProps) {
  const dispatch = useDispatch();

  const extrasOption: IExtrasOption[] = useAppSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );

  return (
    <Container>
      {units.map(
        (
          {
            checkOutDate,
            checkInDate,
            participants,
            maxParticipants,
            setupStyle = ERoomSchemaNames.BLOCK,
            unitId = 0,
            unitInfo = {
              roomType: ERoomType.WORK_SPACE,
              unitType: ERoomType.OPEN_SPACE,
              unitName: '',
            },
            chosenExtras,
          },
          index,
          array,
        ) => {
          const coverImage = findCoverImage(unitInfo?.documents || []);
          const isWorkDesk = unitInfo.roomType !== ERoomType.MEETING_ROOM;
          const openSpaceDetails = () => dispatch(setSpaceDetailsId(unitId));

          const chosenEquipmentExtras = chosenExtras?.filter(
            ({extraType}) => extraType === EResourcesType.EQUIPMENT,
          );
          //TODO: uncomment to get bedrooms timeRange
          // const bedroomDateRange = DateUtils.getBedroomDateRange(checkInDate);

          const isLastRoom = index + 1 === array.length;

          return (
            <Wrapper key={unitId}>
              <RoomTitle>Meeting Room {index + 1}</RoomTitle>
              <StyledNW2Card
                image={coverImage.url}
                imageProps={{
                  ratio: EAspectRation['16x9'],
                  borderRadius: borderRadiusDef,
                }}
                bodyText={
                  <Group direction='column' gap={offsetXSm}>
                    <TextValue
                      onClick={openSpaceDetails}
                      fontSize={fontSizeMd}
                      marginTop='0'
                    >
                      <Link>
                        <TruncateText text={unitInfo.unitName} hideToggler />
                      </Link>
                    </TextValue>
                    <Group direction='column' gap={offsetXXSm}>
                      <div>
                        Time:{' '}
                        <StyledSpan fontWeight={fontWeightBold}>
                          {DateUtils.getHoursAndMinutesFromBE(checkInDate)} -
                          {DateUtils.getHoursAndMinutesFromBE(checkOutDate)}
                        </StyledSpan>
                      </div>
                      {isWorkDesk ? (
                        <div>
                          Space type:{' '}
                          <StyledSpan fontWeight={fontWeightBold}>
                            {UNIT_NAME_TYPE_MAP[unitInfo.unitType]}
                          </StyledSpan>
                        </div>
                      ) : (
                        <>
                          <div>
                            Room setup:{' '}
                            <StyledSpan fontWeight={fontWeightBold}>
                              {ERoomSchema[setupStyle]}
                            </StyledSpan>
                          </div>
                          <div>
                            Participants:{' '}
                            <StyledSpan fontWeight={fontWeightBold}>
                              {pluralize('people', participants || 0)} (
                              {maxParticipants} max)
                            </StyledSpan>
                          </div>
                        </>
                      )}
                    </Group>
                  </Group>
                }
              />

              {!!chosenEquipmentExtras?.length && (
                <PreviewExtraList>
                  {chosenEquipmentExtras?.map(({extraName}, idx) => (
                    <span key={extraName}>
                      {!!idx && <> · </>}
                      {extraName}
                    </span>
                  ))}
                </PreviewExtraList>
              )}

              {isLastRoom && !!foodAndBeverage?.length && (
                <>
                  <TitleWrapper>
                    <Title>Food & Beverage</Title>
                  </TitleWrapper>
                  <PreviewExtrasList
                    extras={foodAndBeverage}
                    extrasOptions={extrasOption}
                  />
                </>
              )}

              {isLastRoom && !!bedrooms?.length && (
                <>
                  <TitleWrapper>
                    <Title>Accommodation</Title>
                    {/* TODO: uncomment to get bedrooms timeRange */}
                    {/* <div>{bedroomDateRange}</div> */}
                  </TitleWrapper>
                  <PreviewExtrasList
                    extras={bedrooms}
                    extrasOptions={extrasOption}
                  />
                </>
              )}
            </Wrapper>
          );
        },
      )}
    </Container>
  );
}

export default Nw2BookingUnits;
