import DateUtils from 'utils/dateUtils';
import {EResourcesType, IExtrasOption} from 'types/dto/IExtras.type';
import {TMeetingRoomItem} from 'types/search';

export const getLabelsListOfExtras = (
  extraIds: number[],
  extrasOptions: IExtrasOption[],
) =>
  extrasOptions.reduce((prev, curr) => {
    return extraIds.includes(curr.id) ? [...prev, curr.name] : prev;
  }, [] as string[]);

export const checkIsExtraByTypePresent: (
  extras: IExtrasOption[],
  type: EResourcesType,
) => boolean = (extras, type) =>
  extras.some((extraItem) => extraItem.type === type);

export const getPriorityRoom = (rooms: TMeetingRoomItem[]) => {
  if (!rooms.length)
    return {
      timeStart: '',
      timeEnd: '',
      participants: 1,
      equipmentData: {},
      seatsSetup: '',
    };
  const participantsArr = rooms.map(({participants}) => participants);
  const isAllEqual = new Set(participantsArr).size === 1;

  const biggestParticipant = rooms.reduce((prev, current) => {
    return prev.participants > current.participants ? prev : current;
  }, {} as TMeetingRoomItem);

  const biggestTimeRange = rooms.reduce((prev, current) => {
    const prevDuration = DateUtils.getTotalHours(prev.timeStart, prev.timeEnd);
    const currentDuration = DateUtils.getTotalHours(
      current.timeStart,
      current.timeEnd,
    );
    return prevDuration > currentDuration ? prev : current;
  }, {} as TMeetingRoomItem);

  return isAllEqual ? biggestTimeRange : biggestParticipant;
};

export const getDayIndexesWithZeroRooms = (
  dayIndex: number,
  dayIndexesWithNoZeroRooms: number[],
  aroundIndexes?: boolean,
) => {
  const indexArr = aroundIndexes
    ? [
        dayIndexesWithNoZeroRooms[0] - 1,
        dayIndexesWithNoZeroRooms[0],
        dayIndexesWithNoZeroRooms[dayIndexesWithNoZeroRooms.length - 1],
        dayIndexesWithNoZeroRooms[dayIndexesWithNoZeroRooms.length - 1] + 1,
      ]
    : [
        dayIndexesWithNoZeroRooms[0] - 1,
        dayIndexesWithNoZeroRooms[dayIndexesWithNoZeroRooms.length - 1] + 1,
      ];

  return indexArr.some((i) => i === dayIndex);
};
