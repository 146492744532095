import {IPreviewExtra} from 'store/venues/types';
import {TGroupedUnitsByDay} from 'types/dto/IBooking.types';

export const getPreviewTotalPrice = (
  previewDays: TGroupedUnitsByDay[] | [],
  participants: number,
) => {
  const totalPrice: number[] = [];

  for (let dayIndex = 0; dayIndex < previewDays.length; ++dayIndex) {
    const previewDay = previewDays[dayIndex];

    previewDay.foodAndBeverage.forEach((extra) => {
      const formattedExtraPrice = extra?.price ?? 0;
      const price =
        typeof formattedExtraPrice === 'number'
          ? formattedExtraPrice
          : //@ts-ignore //TODO: GIVE  correct price
            formattedExtraPrice.value;

      totalPrice.push(
        price *
          ((extra as unknown as IPreviewExtra).chosenQuantity ??
            extra.quantity),
      );
    });

    for (let unitIndex = 0; unitIndex < previewDay.units.length; ++unitIndex) {
      const unit = previewDay.units[unitIndex];
      // get package data from array [unitId, packageData]
      const unitPackage = previewDay.selectedPackage
        ? previewDay.selectedPackage[unitIndex][1]
        : null;

      if (unitPackage) {
        const packagePrice = (unitPackage.price ?? 0) * participants;
        totalPrice.push(packagePrice);
      } else {
        //@ts-ignore //TODO: put correct price
        totalPrice.push(unit?.totalSearchPrice || unit.unitPrice);
      }

      for (
        let extraIndex = 0;
        extraIndex < unit.chosenExtras.length;
        ++extraIndex
      ) {
        const extra = unit.chosenExtras[extraIndex];

        if (extra.price !== 0) {
          totalPrice.push(
            extra.price * (extra.quantity || (extra as any).chosenQuantity),
          );
        }
      }
    }
  }

  return totalPrice.reduce((acc: number, item: number) => acc + item, 0);
};
