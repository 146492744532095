import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import NW2ModalDrawer from 'view/components/NW2ModalDrawer';

import {NW2SummaryDesktopBlock} from 'view/components/NW2SummaryBlock';
import {SummaryTotalPrice} from 'view/venue/components/NW2BookingSummary/components/SummaryTotalPrice/SummaryTotalPrice';

import {useAppSelector} from 'store/hooks';
import {
  formatFloatWithOneNumber,
  getFilteredUnitsByEventType,
} from 'utils/venueUtils';
import {useBookingSummaryModal} from 'view/venue/components/NW2BookingSummary/hooks';
import {EBookingSummaryTheme} from 'view/components/NW2SummaryBlock/types';
import {TImage} from 'types/app';
import {
  EAccommodationType,
  IExtraResponse,
  IPublicVenue,
} from 'types/dto/IPublicVenue';
import {
  EOfferStatus,
  ERequestStatus,
  IOfferRequestDay,
  IOfferUnitExtra,
} from 'types/offer';
import {useGroupRequest} from 'view/venue/hooks/useGroupRequest';
import {useRequestSummaryContent} from '../hooks/useRequestSummaryContent';
import {
  EResourcesCode,
  EResourcesType,
  IExtrasOption,
} from 'types/dto/IExtras.type';
import {IOfferRequestUnitExtra} from '../../OfferRequestReviewUnitCard/types';
import {moveToEndWhere} from 'view/venue/helpers/spaces';

interface IProps {
  venueAddress: string;
  venueDetails: IPublicVenue;
  venueCoverImage: TImage;
  linkTermsText: string;
  isOffer?: boolean;
  isRequest?: boolean;
  isGroupRequest?: boolean;
  status?: ERequestStatus | EOfferStatus;
  postEvents?: IOfferRequestDay[];
  preArrivals?: IOfferRequestDay[];
}
const OfferRequestSummary = ({
  venueAddress,
  venueDetails,
  venueCoverImage,
  linkTermsText,
  isOffer,
  isRequest,
  isGroupRequest,
  status,
  postEvents,
  preArrivals,
}: IProps) => {
  const {isMultiRequest, requestGroup, isTotalGroupRequest} = useGroupRequest();

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const bookingOrder = useAppSelector(
    ({customer}) => customer.customerBookingOrder,
  );
  const customerBookedUnits = useAppSelector(
    ({customer}) => customer.customerBookedUnits,
  );
  const offerDetails = useAppSelector(({offers}) => offers.offerDetails);

  const {accommodationType, currency, name} = venueDetails;

  const isTotalPriceVisible =
    accommodationType !== EAccommodationType.CORPORATE_OFFICE;

  // modals
  const {isModalShowed, modalTitle, modalBody, onModalShow, onModalClose} =
    useBookingSummaryModal();

  const meetingCustomerBookedUnits = customerBookedUnits
    ? getFilteredUnitsByEventType(customerBookedUnits)
    : [];

  const extrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );

  const getUnitExtras = useCallback(
    (
      unitExtras: (IOfferUnitExtra | IExtraResponse)[],
      type: EResourcesType,
    ) => {
      const list = (unitExtras as IOfferUnitExtra[])
        .map(({extraId, quantity, totalPrice}) => {
          const extra = extrasOption.find(({id}) => id === extraId);

          if (extra) {
            return {
              ...extra,
              quantity,
              totalPrice: totalPrice === null ? null : totalPrice?.value,
              currencySymbol: totalPrice?.currency,
            };
          }
        })
        .filter((extra) => extra?.type === type) as IOfferRequestUnitExtra[];

      return moveToEndWhere(list, 'code', EResourcesCode.WIFI);
    },
    [extrasOption],
  );

  const {headerSection, totalPriceSection, unitsRequestSection} =
    useRequestSummaryContent({
      currency,
      venueAddress,
      venueName: name,
      venueCoverImage,
      theme: EBookingSummaryTheme.LIGHT,
      requestedDays: getFilteredUnitsByEventType(offerDetails.days),
      isTotalPriceVisible:
        isRequest || isGroupRequest ? isTotalGroupRequest : isTotalPriceVisible,
      totalPrice:
        bookingOrder?.totalPrice || // todo check
        requestGroup?.[0]?.totalRequestPrice?.value ||
        0,
      status,
      preArrivals,
      postEvents,
      isOffer,
      isGroupRequest,
      isOfferRequest: isRequest || isGroupRequest,
      isAlternative: isGroupRequest && isMultiRequest,
      onModalShow,
      getUnitExtras,
    });

  // Offers and Requests
  const offerTotalPriceBlock = useMemo(() => {
    const totalSum = `${formatFloatWithOneNumber(
      offerDetails.price?.value || null,
    )} ${currency}`;

    return <SummaryTotalPrice totalSum={totalSum} onModalShow={onModalShow} />;
  }, [currency, offerDetails.price, onModalShow]);

  if (!isRequest && !isOffer && !meetingCustomerBookedUnits) return null;

  return (
    <>
      <NW2SummaryDesktopBlock
        isStickyDisabled
        theme={EBookingSummaryTheme.LIGHT}
        isSticky={false}
        headerSection={headerSection}
        totalPriceSection={isOffer ? offerTotalPriceBlock : totalPriceSection}
        unitsSection={unitsRequestSection}
        linkTermsText={linkTermsText}
      />

      <NW2ModalDrawer
        isMobile={isMobile}
        isShowed={isModalShowed}
        header={modalTitle}
        body={modalBody}
        onClose={onModalClose}
        drawerHeight='auto'
      />
    </>
  );
};

export default OfferRequestSummary;
