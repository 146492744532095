import React from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';
import {TablePaginationConfig} from 'antd/es';
import {
  ColumnsType,
  FilterValue,
  SorterResult,
  TableRowSelection,
} from 'antd/es/table/interface';

import NWTable from 'view/components/Table/NWTable';

import {IBooking} from 'types/dto/IBooking.types';
import {EBookingFilterType, IBookingType} from 'types/dto/IBookingType.type';

type TProps = {
  columns: Record<string, ColumnsType<IBooking>>;
  isLoading: boolean;
  handleTableChange: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Record<string, string | any[]>>,
    tableDataKey?: string,
  ) => void;
  tableDataKey: string;
  bookingsType: IBookingType;
  rowSelection?: TableRowSelection<IBooking>;
};

function BookingTodayOverviewTableSection({
  columns,
  tableDataKey,
  isLoading,
  bookingsType,
  handleTableChange,
  rowSelection,
}: TProps) {
  const bookingFilterType = EBookingFilterType.WORK_SPACE;

  const {data, isLoading: isTableLoading} = useSelector((state) =>
    _get(
      state,
      `bookingReducer[${bookingsType}].data[${bookingFilterType}][${tableDataKey}]`,
    ),
  );

  const onTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Record<string, string | any[]>>,
  ) => {
    handleTableChange(pagination, filters, sorter, tableDataKey);
  };

  return (
    <NWTable
      columns={columns[tableDataKey]}
      tableData={data}
      isLoading={isLoading || isTableLoading}
      isPagination={false}
      handleTableChange={onTableChange}
      rowKey='orderId'
      tableEntityText='requests'
      rowSelection={rowSelection}
    />
  );
}

export default BookingTodayOverviewTableSection;
