import React, {memo} from 'react';
import _get from 'lodash/get';

import {IOfferUnitExtra} from 'types/offer';
import {EPriceType, EResourcesCode} from 'types/dto/IExtras.type';
import {formatFloatWithOneNumber} from 'utils/venueUtils';
import {PRICE_TYPE_MAP} from 'constants/venue';
import {useSelector} from 'react-redux';
import {getCurrencySymbol} from 'utils/stringUtils';
import {
  ExtraItemPreviewQty,
  List,
  ListItem,
  Name,
  Price,
  Quantity,
} from './OfferExtrasList.styles';
import {getPriceFromExtras} from 'view/venue/helpers/spaces';
import {CODE_TO_EXTRA_NAME} from 'constants/extras';
import {TSearchCriteriaExtra} from 'types/search';
import {IExtraResponse} from 'types/dto/IPublicVenue';

interface IProps {
  bedrooms?: (IOfferUnitExtra | TSearchCriteriaExtra | IExtraResponse)[];
}

const OfferExtrasList = memo(({bedrooms}: IProps) => {
  const venueCurrency: string = useSelector((state) =>
    _get(state, 'venue.venue.currency'),
  );

  if (!bedrooms) return null;

  const currencySymbol = getCurrencySymbol(venueCurrency);

  return (
    <List>
      {bedrooms.map((bedroom, index) => {
        const formatPriceText = formatFloatWithOneNumber(
          getPriceFromExtras(bedroom as IOfferUnitExtra),
        );
        const priceText = `${formatPriceText} ${currencySymbol} / ${
          PRICE_TYPE_MAP[EPriceType.PER_ROOM]
        }`;

        return (
          <ListItem key={'extras-list-item' + index}>
            <ExtraItemPreviewQty>
              <>
                <Quantity>{bedroom.quantity}</Quantity>
                <span>x</span>
              </>
            </ExtraItemPreviewQty>
            <span>
              <Name>{CODE_TO_EXTRA_NAME[bedroom.code as EResourcesCode]}</Name>
              <Price>{priceText}</Price>
            </span>
          </ListItem>
        );
      })}
    </List>
  );
});

export default OfferExtrasList;
