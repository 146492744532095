import React from 'react';
import {useAppSelector} from 'store/hooks';

import BillingAddressView from './BillingAddressView';

import {
  StyledNW2FormItemInput,
  BillingAddressContainer,
  BillingAddressInnerBlock,
} from './BillingAddress.styles';

function BillingAddressViewWithReferenceField({
  isAgentRole,
}: {
  isAgentRole?: boolean;
}) {
  const defaultBillingAddress = useAppSelector(
    ({app}) => app.user.defaultBillingAddress,
  );

  if (!defaultBillingAddress) return null;

  return (
    <BillingAddressContainer>
      <BillingAddressInnerBlock>
        <BillingAddressView
          billingAddress={defaultBillingAddress}
          hasEditButton={!isAgentRole}
        />
        {!isAgentRole && (
          <StyledNW2FormItemInput
            name='additionalReference'
            type='text'
            label='Additional reference'
            placeholder='Additional reference'
          />
        )}
      </BillingAddressInnerBlock>
    </BillingAddressContainer>
  );
}

export default BillingAddressViewWithReferenceField;
