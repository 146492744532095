import {
  getSupplierOrdersError,
  getSupplierOrdersLoading,
  getSupplierOrdersSuccess,
  removeConfirmedDeclinedRTC,
  setBookingOrderChanges,
  setBookingOrderDetails,
  setBookingOrderShowStatus,
  setCancellationPolicy,
  setOrderStatusReadInUpcomingMeetingRoomTab,
  setSupplierAmountOfBookings,
} from './reducer';

import {AppDispatch, RootState} from '../types';
import {
  EOrderShowStatus,
  IBookingChanges,
  IBookingDetails,
  IConfirmOrderChangesPayload,
  IDeclineOrderChangesPayload,
  IGetBookingOrdersPayload,
  IGetBookingOrdersResponse,
} from 'types/bookingOrders';
import {TContainer} from '../../app';
import {
  IAmountOfBookings,
  IGetAmountOfBookingsPayload,
} from 'types/dto/IBookingType.type';
import {ICancellationPolicyPayload} from 'types/dto/ICancellationPolicy.type';
import {EBookingStatus} from 'types/booking';
import {notification} from 'antd';

export const getSupplierOrders =
  ({
    page,
    limit,
    searchTab,
    venueId,
    sortBy,
    spaceType,
  }: IGetBookingOrdersPayload) =>
  (dispatch: AppDispatch, _: any, {bookingsContainer}: TContainer) => {
    dispatch(getSupplierOrdersLoading(true));

    return bookingsContainer.getSupplierOrders({
      payload: {
        page,
        limit,
        searchTab,
        venueId,
        sortBy,
        spaceType,
      },
      onSuccess: ({
        pageItems,
        totalFilteredRecords,
      }: IGetBookingOrdersResponse) => {
        dispatch(
          getSupplierOrdersSuccess({
            pageItems,
            spaceType,
            searchTab,
            totalFilteredRecords,
          }),
        );
      },
      onError: (error: any) => {
        dispatch(getSupplierOrdersError(error));
      },
    });
  };

export const setSupplierBookingDetails =
  (payload: IBookingDetails | null) => (dispatch: AppDispatch) => {
    dispatch(setBookingOrderDetails(payload));
  };

// export const getOrderDetailsById =
//   ({orderId}: any) =>
//   (dispatch: AppDispatch, _: any, {bookingsContainer}: TContainer) => {
//     bookingsContainer.getOrderDetails({
//       payload: orderId,
//       onSuccess: (data: IBookingDetails) => {
//         dispatch(setBookingOrderDetails(data));
//       },
//       onError: (error: any) => {
//         dispatch(getSupplierOrdersError(error));
//       },
//     });
//   };

export const getSupplierOrderDetailsById =
  ({orderId, isStatusUnread, orderNumber}: any) =>
  (dispatch: AppDispatch, _: any, {bookingsContainer}: TContainer) => {
    bookingsContainer.getSupplierOrderDetails({
      payload: orderId,
      onSuccess: (data: IBookingDetails) => {
        dispatch(
          setBookingOrderDetails({
            ...data,
            showStatus: isStatusUnread
              ? EOrderShowStatus.UNREAD
              : EOrderShowStatus.READ,
          }),
        );
        const isEdited = !!(data.nextOrderVersion || data.previousOrderVersion);
        if (isEdited) dispatch(getOrderChangesById(orderNumber));
      },
      onError: (error: any) => {
        dispatch(getSupplierOrdersError(error));
      },
    });
  };

export const getOrderChangesById =
  (id: string, isRTCPendingMode?: boolean) =>
  (dispatch: AppDispatch, _: any, {bookingsContainer}: TContainer) => {
    bookingsContainer.getOrderChanges({
      payload: id,
      onSuccess: (data: IBookingChanges[]) => {
        if (isRTCPendingMode) {
          const confirmedBooking = data.find(
            (change) => change.bookingStatus === EBookingStatus.CONFIRMED,
          );

          if (confirmedBooking) {
            dispatch(setSupplierBookingDetails(confirmedBooking));
          }
        }
        dispatch(setBookingOrderChanges(data));
      },
      onError: (error: any) => {
        dispatch(getSupplierOrdersError(error));
      },
    });
  };

export const getAmountOfBookings =
  (payload: IGetAmountOfBookingsPayload) =>
  (
    dispatch: AppDispatch,
    _: () => RootState,
    {bookingsContainer}: TContainer,
  ) => {
    bookingsContainer.getAmountOfBookings({
      payload,
      onSuccess: (data: IAmountOfBookings) =>
        dispatch(setSupplierAmountOfBookings(data)),
      onError: (error: any) => {
        dispatch(getSupplierOrdersError(error));
      },
    });
  };

export const getCancellationPolicyByInfo =
  (payload: ICancellationPolicyPayload) =>
  (dispatch: AppDispatch, _: any, {bookingsContainer}: TContainer) => {
    bookingsContainer.getCancellationPolicyText({
      payload,
      onSuccess: (data: string) => {
        dispatch(setCancellationPolicy(data));
      },
      onError: (error: any) => {
        dispatch(getSupplierOrdersError(error));
      },
    });
  };

export const patchOrderShowStatus =
  (payload: string, callback?: () => void) =>
  (dispatch: AppDispatch, _: any, {bookingsContainer}: TContainer) => {
    bookingsContainer.patchOrderShowStatus({
      payload,
      onSuccess: () => {
        dispatch(setBookingOrderShowStatus(EOrderShowStatus.READ));
        if (callback) callback();
      },
      onError: () => {},
    });
  };

export const declineOrderChanges =
  (payload: IDeclineOrderChangesPayload) =>
  (dispatch: AppDispatch, _: any, {bookingsContainer}: TContainer) => {
    bookingsContainer.putDeclineOrderChanges({
      payload,
      onSuccess: () => {
        const {orderNumber, isDeclineMode} = payload;
        dispatch(getOrderChangesById(orderNumber, isDeclineMode));
        // remove confirmed/declined RTC_Pending order from new requests section
        // set pending status to read in upcoming meeting room tab
        dispatch(setOrderStatusReadInUpcomingMeetingRoomTab());
        dispatch(removeConfirmedDeclinedRTC());
      },
      onError: (error: any) => {
        dispatch(getSupplierOrdersError(error));
      },
    });
  };

export const confirmOrderChanges =
  (payload: IConfirmOrderChangesPayload) =>
  (dispatch: AppDispatch, _: any, {bookingsContainer}: TContainer) => {
    bookingsContainer.putConfirmOrderChanges({
      payload,
      onSuccess: () => {
        const {orderNumber, isConfirmMode} = payload;
        dispatch(getOrderChangesById(orderNumber, isConfirmMode));
        // remove confirmed/declined RTC_Pending order from new requests section
        // set pending status to read in upcoming meeting room tab
        dispatch(setOrderStatusReadInUpcomingMeetingRoomTab());
        dispatch(removeConfirmedDeclinedRTC());
        const notificationOptions = {
          message: 'Change request has been successfully confirmed.',
          duration: 4,
        };
        notification.success(notificationOptions);
      },
      onError: (error: any) => {
        dispatch(getSupplierOrdersError(error));
      },
    });
  };
