import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _get from 'lodash/get';
import {useFormState} from 'react-final-form';
import {useAppSelector} from 'store/hooks';

import NW2ModalDrawer from 'view/components/NW2ModalDrawer';
import NW2SummaryBlock from 'view/components/NW2SummaryBlock';

import {LinkTerms} from 'view/components/NW2SummaryBlock/components/LinkTerms';
import {
  useBookingSummaryModal,
  useBookingSummaryContent,
} from 'view/venue/components/NW2BookingSummary/hooks';
import {setTotalAmount, setTotalDrawerVisible} from 'store/venues/actions';
import {EAccommodationType, IExtraResponse} from 'types/dto/IPublicVenue';
import {ButtonConfirm} from 'view/venue/components/NW2BookingSummary/components/ButtonConfirm';
import {
  IPreviewUnitResponse,
  TGroupedUnitsByDay,
} from 'types/dto/IBooking.types';
import {getPreviewTotalPrice} from './helpers';
import {useLoggedInUser} from 'hooks/useLoggedInUser';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';

interface IProps {
  currency: string;
  isTotalSticky: boolean;
  venueAddress: string;
  venueName: string;
  venueCoverImage: {
    url: string;
    name: string;
  };
  isRfp: boolean;
}

export function NW2BookingTotal({
  currency,
  isTotalSticky,
  venueCoverImage,
  venueName,
  venueAddress,
  isRfp,
}: IProps) {
  const dispatch = useDispatch();

  const {isLoggedInUser} = useLoggedInUser();

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const deskPreviewUnits = useSelector((state) =>
    _get(state, `venuesReducer.bookingPreview.deskPreviewUnits`, []),
  );
  const bookingMaskPreviewUnits = useSelector((state) =>
    _get(state, `venuesReducer.bookingPreview.bookingMaskPreviewUnits`, []),
  );

  const previewUnits: TGroupedUnitsByDay[] = bookingMaskPreviewUnits?.length
    ? bookingMaskPreviewUnits
    : deskPreviewUnits;

  const isRtb = previewUnits.some((day) =>
    day.units.some((unit) => unit.isRtb),
  );

  const isDrawerShowed: boolean = useSelector((state) =>
    _get(state, 'venuesReducer.bookingPreview.isTotalDrawerVisible'),
  );
  const participants =
    useAppSelector(({search}) => search.searchCriteria.meetingRoomCapacity) ||
    1;

  const {venueDetails} = useVenueDetailsData();
  const accommodationType = venueDetails.accommodationType;

  const formState = useFormState();
  const {submitting} = formState;

  const getPreviewExtras = useCallback(() => {
    const extras: IExtraResponse[] = [];
    previewUnits.forEach((venueUnit: TGroupedUnitsByDay) => {
      venueUnit.units.forEach((unit: IPreviewUnitResponse) => {
        unit.chosenExtras.forEach((extra) => {
          extras.push(extra);
        });
      });
    });
    return extras;
  }, [previewUnits]);

  const getPreviewTotalPriceMemo = useCallback(
    () => getPreviewTotalPrice(previewUnits, participants),
    [previewUnits, participants],
  );
  // hide price when CORPORATE_OFFICE
  const isOffice: boolean =
    accommodationType === EAccommodationType.CORPORATE_OFFICE;
  const extras = getPreviewExtras();
  const extrasCount = extras.length;

  const totalAmount: number = getPreviewTotalPriceMemo();

  useEffect(() => {
    dispatch(setTotalAmount({value: totalAmount, currency}));
  }, [dispatch, totalAmount, currency]);

  const onToggleDrawer = useCallback(() => {
    dispatch(setTotalDrawerVisible(!isDrawerShowed));
  }, [dispatch, isDrawerShowed]);

  // modals
  const {isModalShowed, modalTitle, modalBody, onModalClose, onModalShow} =
    useBookingSummaryModal();

  const termsText = isRfp
    ? `Cancellation policy becomes binding once the request is confirmed by the venue. 
        By proceeding you accept the HRS `
    : 'By proceeding you accept HRS NewWork ';

  const {headerSection, totalPriceSection, unitsSection} =
    useBookingSummaryContent({
      currency,
      isRfp,
      unitBookings: previewUnits,
      isTotalPriceVisible: !isOffice,
      totalPrice: totalAmount,
      venueAddress,
      venueName,
      venueCoverImage,
      submitting,
      onModalShow,
    });

  const buttonConfirm = (
    <ButtonConfirm
      isRfp={isRfp || isRtb}
      isMobile={isMobile}
      submitting={submitting}
      isOffice={isOffice}
      isDrawerShowed={isDrawerShowed}
      extrasCount={extrasCount}
      isLoggedInUser={isLoggedInUser}
    />
  );

  return (
    <>
      <NW2SummaryBlock
        isSticky={isTotalSticky}
        isMobile={isMobile}
        isDrawerShowed={isDrawerShowed}
        setIsDrawerShowed={onToggleDrawer}
        headerSection={headerSection}
        totalPriceSection={
          <>
            {totalPriceSection}
            {!isOffice && isMobile && <LinkTerms text={termsText} />}
          </>
        }
        unitsSection={unitsSection}
        linkTermsText={termsText}
        buttonConfirm={buttonConfirm}
        footerMobileContent={buttonConfirm}
      />

      <NW2ModalDrawer
        isMobile={isMobile}
        isShowed={isModalShowed}
        header={modalTitle}
        body={modalBody}
        onClose={onModalClose}
        drawerHeight='auto'
      />
    </>
  );
}
