import React from 'react';

import {RequestDetailsUnit} from './RequestDetailsUnit';
import {getPrice} from '../../helpers';

import {
  ERequestDetailsSections,
  IOfferDay,
  IOfferRequestUnit,
  IOfferUnitExtra,
  IRequestDay,
} from 'types/offer';
import {EResourcesType, IExtrasOption} from 'types/dto/IExtras.type';
import {formatDate} from 'utils/stringUtils';
import {
  formatFloatWithOneNumber,
  getFilteredExtrasByType,
} from 'utils/venueUtils';
import {
  DayContent,
  DaysBlock,
  DaySubTotal,
  DaySubTotalPrice,
  DayTitle,
  UnitBlock,
  UnitDayPrice,
  Wrapper,
} from 'view/supplier/Bookings/BookingDetails/BookingDetails.styles';

interface IProps {
  isSingle: boolean;
  isOffer: boolean;
  days: (IRequestDay | IOfferDay)[];
  extrasOption: IExtrasOption[];
  currencySymbol?: string;
  isRequestWithBedroom: boolean;
  isRequestWithUnitId?: boolean;
}
export function OfferRequestDetailsUnits({
  currencySymbol,
  extrasOption,
  isSingle,
  isOffer,
  days,
  isRequestWithBedroom,
  isRequestWithUnitId,
}: IProps) {
  return (
    <>
      {days.map((day, index) => {
        const date = formatDate(day.checkIn, 'd MMM, YYYY', 'en-US');
        const dayId = `Day ${index + 1}`;
        const dayItems = day.items as IOfferRequestUnit[];
        const unitsLen = dayItems.length;
        const isUnitSingle = unitsLen === 1;

        const dayItemsPrice = getPrice(dayItems);
        const accomodationPrice = getPrice(day.bedrooms);
        const foodAndBeveragePrice = getPrice(
          day.foodAndBeverage as IOfferUnitExtra[],
        );

        const foodAndBeverage = getFilteredExtrasByType(
          day.foodAndBeverage as IOfferUnitExtra[],
          EResourcesType.FOOD_AND_BEVERAGE,
          extrasOption,
        );

        return (
          <section
            key={day.checkIn}
            id={
              isSingle
                ? isOffer
                  ? ERequestDetailsSections.OFFER_DETAILS
                  : ERequestDetailsSections.REQUEST_DETAILS
                : ''
            }
          >
            <Wrapper>
              <DaysBlock id={dayId}>
                <DayTitle>{isSingle ? 'Details' : dayId}</DayTitle>
                <DayContent>{date}</DayContent>
              </DaysBlock>

              <UnitBlock>
                {dayItems.map((unit: IOfferRequestUnit, idx) => (
                  <RequestDetailsUnit
                    key={unit.id}
                    unit={unit}
                    index={idx}
                    bedrooms={day.bedrooms}
                    foodAndBeverage={foodAndBeverage}
                    isUnitSingle={isUnitSingle}
                    isUnitLast={idx === unitsLen - 1}
                    isOffer={isOffer}
                    currencySymbol={currencySymbol}
                    isRequestWithBedroom={isRequestWithBedroom}
                    isRequestWithUnitId={isRequestWithUnitId}
                    date={day.checkIn}
                  />
                ))}

                {isOffer && (
                  <UnitDayPrice>
                    <DaySubTotal>Day sub-total:</DaySubTotal>
                    <DaySubTotalPrice>
                      {formatFloatWithOneNumber(
                        dayItemsPrice +
                          foodAndBeveragePrice +
                          accomodationPrice,
                      )}
                      &nbsp; {currencySymbol}
                    </DaySubTotalPrice>
                  </UnitDayPrice>
                )}
              </UnitBlock>
            </Wrapper>
          </section>
        );
      })}
    </>
  );
}
