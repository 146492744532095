import React from 'react';
import {useAppSelector} from 'store/hooks';
import styled from 'styled-components';

import Icon from 'view/components/Icon';
import TextValue from 'view/components/TextValue';
import CancellationPolicyTextElement from 'view/components/CancellationPolicyTextElement/CancellationPolicyTextElement';

import {EBookingStatus} from 'types/booking';
import {Group} from 'styles/basicStyledComponents/group';
import {
  fontSizeMd,
  fontWeightBold,
  NW2ErrorLight500Color,
  NW2SuccessLight500Color,
  offsetXXSm,
} from 'constants/styleVars';

const IconBlock = styled.div<{isCancellationCharge: boolean}>`
  color: ${({isCancellationCharge}) =>
    isCancellationCharge ? NW2SuccessLight500Color : NW2ErrorLight500Color};
  font-weight: ${fontWeightBold};
  display: flex;
  align-items: center;
  gap: ${offsetXXSm};
`;

function BookingConfirmCancellationPolicy() {
  const isCancellationCharge = !!useAppSelector(
    ({customer}) => customer.customerBookingOrder?.refundAmount,
  );

  const bookingStatus = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.bookingStatus,
  );

  const cancellationPolicyText = useAppSelector(
    (state) => state.customer.cancellationPolicyText,
  );

  const isIconBlockTextVisible =
    bookingStatus === EBookingStatus.CONFIRMED ||
    bookingStatus === EBookingStatus.RFP_PENDING;

  const cancellationIcon = isCancellationCharge ? 'NW2_TICK' : 'NW2_CLOSE';
  const iconBlockText = isCancellationCharge
    ? ' You can still cancel free-of-charge'
    : ' Cancellation no longer free';

  return (
    <Group direction='column'>
      <TextValue fontSize={fontSizeMd} bold>
        Cancellation policy
      </TextValue>
      <TextValue fontSize={fontSizeMd}>
        <CancellationPolicyTextElement text={cancellationPolicyText} />
      </TextValue>
      {isIconBlockTextVisible && (
        <IconBlock isCancellationCharge={isCancellationCharge}>
          <Icon icon={cancellationIcon} transparent />
          <span>{iconBlockText}</span>
        </IconBlock>
      )}
    </Group>
  );
}

export default BookingConfirmCancellationPolicy;
