import Geocode from 'react-geocode';

import {TMarkerLocation} from '../types/venue';
import {
  TAddressComponents,
  TGeocodeAddressData,
} from 'view/components/NW2SearchSection/components/SearchForm/components/NW2GoogleMapSearchInput/types';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_KEY || '');

// get address parts from google map response object
const getGoogleMapAddressPart = (addressArray: any, type: string) => {
  let value = '';
  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].types[0] && type === addressArray[i].types[0]) {
      value = addressArray[i].long_name;
      return value;
    }
  }
};

export const processGoogleMapAddressData = (
  data: any = {},
): TAddressComponents => {
  const placeId = data.place_id || '';
  const addressArray = data.address_components || [];
  const buildingNumber =
    getGoogleMapAddressPart(addressArray, 'street_number') || '';
  const streetName = getGoogleMapAddressPart(addressArray, 'route') || '';
  const city = getGoogleMapAddressPart(addressArray, 'locality') || '';
  const country = getGoogleMapAddressPart(addressArray, 'country') || '';
  const postalCode = getGoogleMapAddressPart(addressArray, 'postal_code') || '';

  return {
    placeId,
    buildingNumber,
    streetName,
    city,
    country,
    postalCode,
  };
};

export const calculateDistance = (
  mk1: TMarkerLocation,
  mk2: TMarkerLocation,
) => {
  const R = 6371.071; // Radius of the Earth in km
  const rlat1 = mk1.lat * (Math.PI / 180); // Convert degrees to radians
  const rlat2 = mk2.lat * (Math.PI / 180); // Convert degrees to radians
  const difflat = rlat2 - rlat1; // Radian difference (latitudes)
  const difflon = (mk2.lng - mk1.lng) * (Math.PI / 180); // Radian difference (longitudes)
  const d =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) *
            Math.cos(rlat2) *
            Math.sin(difflon / 2) *
            Math.sin(difflon / 2),
      ),
    );
  return d;
};

export const getVisibleRadius = ({map}: {map: google.maps.Map}) => {
  const bounds = map.getBounds();
  if (!bounds) return;
  const northEastCornerLng = bounds?.getNorthEast().lng() || 0;
  const northEastCornerLat = bounds?.getNorthEast().lat() || 0;
  const southWestCornerLng = bounds?.getSouthWest().lng() || 0;
  const southWestCornerLat = bounds?.getSouthWest().lat() || 0;
  const topRightCorner = {
    lat: northEastCornerLat,
    lng: northEastCornerLng,
  };
  const topLeftCorner = {
    lat: northEastCornerLat,
    lng: southWestCornerLng,
  };
  const bottomLeftCorner = {
    lat: southWestCornerLat,
    lng: southWestCornerLng,
  };
  const width = calculateDistance(topRightCorner, topLeftCorner);
  const height = calculateDistance(bottomLeftCorner, topLeftCorner);
  const minDiameter =
    width && height ? Math.min(width, height) : Math.max(width, height);
  const radius = minDiameter / 2;
  return +radius.toFixed(3);
};

export const getPixelPositionOffset = (
  offsetWidth: number,
  offsetHeight: number,
) => {
  return {
    x: -Math.round(offsetWidth / 2),
    y: -Math.round(offsetHeight),
  };
};

export const getActiveMarkerPixelPositionOffset = (
  offsetWidth: number,
  offsetHeight: number,
) => {
  return {
    x: -Math.round(offsetWidth / 2),
    y: -Math.round(offsetHeight + 8),
  };
};

export const getGoogleMapPinCenteredPositionOffset = (
  offsetWidth: number,
  offsetHeight: number,
) => {
  return {
    x: -Math.round(offsetWidth / 2),
    y: -Math.round(offsetHeight / 2),
  };
};

export const getGeocodeAddressData = async (
  addressString: string,
): Promise<TGeocodeAddressData> => {
  const DEFAULT_RESPONSE = {
    latitude: '',
    longitude: '',
    address: '',
    addressComponents: undefined,
  };

  if (!addressString) {
    return DEFAULT_RESPONSE;
  }

  try {
    const response = await Geocode.fromAddress(addressString);

    const results = response.results[0];
    const addressComponents = processGoogleMapAddressData(results);
    const address = results.formatted_address;
    const {lat, lng} = results.geometry.location;
    return {
      latitude: lat,
      longitude: lng,
      address,
      addressComponents,
    };
  } catch (error) {
    return DEFAULT_RESPONSE;
  }
};
