import React, {ReactNode, useCallback, useMemo, useState} from 'react';

import {useAppSelector} from 'store/hooks';
import {useOfferHandlers} from 'view/venue/Offer/hooks/useOfferHandlers';
import {EDeclineItemType} from 'types/offer';
import {RequestDeclineView} from 'view/components/RequestDecline/RequestDeclineView';
import {RequestDeclineForm} from 'view/components/RequestDecline/RequestDeclineForm';

interface IProps {
  id: string;
  isGroupRequest?: boolean;
  hasFormFields?: boolean;
}

export const useRequestCancelModal = ({
  hasFormFields,
  isGroupRequest,
  id,
}: IProps): {
  isModalShowed: boolean;
  onModalShow: () => void;
  onModalClose: () => void;
  modalTitle: string | null;
  modalBody: ReactNode | null;
  modalFooter: ReactNode | null;
} => {
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  const [showedModal, setShowedModal] = useState(false);

  const onModalShow = () => {
    setShowedModal(true);
  };

  const onModalClose = useCallback(() => {
    setShowedModal(false);
  }, []);

  const {handleDecline, isSending} = useOfferHandlers({
    onCloseOrderDetails: onModalClose,
  });

  const {modalTitle, modalBody, modalFooter} = useMemo(() => {
    return {
      modalTitle: 'Cancel request',
      modalBody: hasFormFields ? (
        <RequestDeclineForm
          orderId={id}
          handleDecline={handleDecline}
          handleCancel={onModalClose}
          isSending={isSending}
          cancelLabel='Cancel'
          submitLabel='Confirm'
          isMobile={isMobile}
          declineItem={EDeclineItemType.REQUEST_GROUP}
          isCustomer
          showButtons
          isRequest
          isAdditionCancelText
        />
      ) : (
        <RequestDeclineView
          orderId={id}
          handleDecline={handleDecline}
          handleCancel={onModalClose}
          isSending={isSending}
          cancelLabel='Cancel'
          submitLabel='Confirm'
          declineItem={
            isGroupRequest
              ? EDeclineItemType.REQUEST_GROUP
              : EDeclineItemType.SINGLE_REQUEST
          }
        />
      ),
      modalFooter: null,
    };
  }, [
    handleDecline,
    hasFormFields,
    id,
    isGroupRequest,
    isMobile,
    isSending,
    onModalClose,
  ]);

  return {
    isModalShowed: showedModal,
    onModalShow,
    onModalClose,
    modalTitle,
    modalBody,
    modalFooter,
  };
};
