import React, {useMemo, useEffect} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import AnchorMenu from 'view/components/SupplierLayout/components/AnchorMenu';
import DateUtils from 'utils/dateUtils';
import PrePostAccommodationBlock from '../OfferRequestAccommodationBlock/PrePostAccommodationBlock';

import {Status} from 'view/supplier/Bookings/BookingDetails/components/Status/Status';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {getCancellationPolicyByInfo} from 'store/bookingsSupplier/actions';
import {getCancellationPolicyStrings} from 'utils/stringUtils';
import {useRequestSections} from 'view/venue/Offer/hooks/useRequestSections';
import {
  checkExpirationForStatuses,
  getRequestDetailsType,
  getDayItems,
} from '../../helpers';
import {OfferRequestDetailsUnits} from './OfferRequestDetailsUnits';
import {useOpenTermsAndConditionsModal} from 'view/common/NW2MultiModal/hooks/useOpenInfoPageModal';

import {
  Container,
  MainTitle,
  Wrapper,
  DaysBlock,
  Section,
  SubTitle,
  StyledTextes,
  TotalSection,
  TotalTitle,
  FeesText,
  TotalPrice,
  UnitBlock,
  DayTitle,
  StyledText,
  StyledUnitItem,
  Link,
} from 'view/supplier/Bookings/BookingDetails/BookingDetails.styles';
import {
  EOfferStatus,
  ERequestDetailsSections,
  ERequestStatus,
} from 'types/offer';
import {MODAL_TWO_COLS_RIGHT_COL_ID} from 'constants/app';
import {IExtrasOption} from 'types/dto/IExtras.type';
import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {offsetDef} from 'constants/styleVars';
import {getFilteredUnitsByEventType} from 'utils/venueUtils';
import {useRequestBedrooms} from '../../hooks/useRequestBedrooms';

type TProps = {
  totalPriceAndCurrency: string;
  holdUp: string | null;
  venueTimeZone: string;
  isMultiDay: boolean;
  isOffer: boolean;
  currencySymbol?: string;
  isRequestWithBedroom: boolean;
};

const RequestDetailsInfo = ({
  currencySymbol,
  holdUp,
  venueTimeZone,
  isMultiDay,
  totalPriceAndCurrency,
  isOffer,
  isRequestWithBedroom,
}: TProps) => {
  const dispatch = useAppDispatch();

  const requestDetails = useAppSelector(({offers}) => offers.offerDetails);
  const extrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );
  const venueZone: string = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.location.timeZone`),
  );
  const cancellationPolicyData = useAppSelector(
    (state) => state.bookingsSupplier.cancellationPolicy,
  );
  const cancellationPolicy = getCancellationPolicyStrings(
    cancellationPolicyData,
  );

  const openTermsAndConditionsModal = useOpenTermsAndConditionsModal();

  const {
    resolvedAt,
    days,
    declinedReason,
    status,
    declinedMessage,
    resolverName,
    creatorName,
    sentAt,
    expirationDate,
    optionDate,
  } = requestDetails;

  const filteredMeetingDays = getFilteredUnitsByEventType(days);

  const items = getDayItems(days);

  const isSingle = days.length === 1;

  const maxParticipants = useMemo(
    () => Math.max(...items.map(({participants}) => participants)),
    [items],
  );

  useEffect(() => {
    dispatch(
      getCancellationPolicyByInfo({
        maxParticipants,
        isRfp: false,
        isMultiDay,
      }),
    );
  }, [dispatch, isMultiDay, maxParticipants]);

  const {preArrivals, postEvents} = useRequestBedrooms(true);

  const requestDetailsType = getRequestDetailsType(isSingle, status);

  const sections = useRequestSections({
    requestDetailsType,
    requestedDays: filteredMeetingDays,
    isPreArrival: !!preArrivals.length,
    isPostEvent: !!postEvents.length,
  }).filter(Boolean);

  // Need for case when there is request with all data(with unitIds)
  // and at the same time with accommodations(bedrooms), which hadn't have prices yet
  const isRequestWithUnitId = !isOffer && items.some(({unitId}) => !!unitId);

  const commonPropsForPrePostBlock = {
    extrasOption,
    isOffer,
    currencySymbol,
    isRequestWithUnitId,
    titleMargin: `0 0 ${offsetDef}`,
  };

  return (
    <Container>
      <div>
        {status !== ERequestStatus.REQUEST_PENDING && (
          <Section id={ERequestDetailsSections.STATUS}>
            <Status
              bookingStatus={status}
              currencySymbol={currencySymbol}
              refundAmount={0}
              venueTimeZone={venueTimeZone}
              totalPriceAndCurrency={totalPriceAndCurrency}
              declinedReason={declinedReason}
              declinedMessage={declinedMessage}
              declinedBy={resolverName}
              creatorName={creatorName}
              holdUp={holdUp}
              sentAt={sentAt}
              resolvedAt={resolvedAt}
              expirationDate={expirationDate}
              isRequestWithBedroom={isRequestWithBedroom}
            />
          </Section>
        )}

        <MainTitle>
          {isOffer
            ? ERequestDetailsSections.OFFER_DETAILS
            : ERequestDetailsSections.REQUEST_DETAILS}
        </MainTitle>

        {status === ERequestStatus.REQUEST_PENDING && (
          <section
            key={ERequestDetailsSections.OPTION_DATE}
            id={ERequestDetailsSections.OPTION_DATE}
          >
            <Wrapper>
              <DaysBlock>
                <DayTitle>Option date</DayTitle>
              </DaysBlock>

              <UnitBlock>
                <StyledUnitItem>
                  <StyledText>
                    The booker has requested{' '}
                    <b>
                      {DateUtils.getFormattedDayMonthTime({
                        date: optionDate,
                        venueZone,
                      })}
                    </b>{' '}
                    to be their option deadline.
                  </StyledText>
                  You will be notified as soon as the booker makes their
                  decision.
                </StyledUnitItem>
              </UnitBlock>
            </Wrapper>
          </section>
        )}

        {!isSingle && (
          <section
            key={ERequestDetailsSections.PRE_ARRIVAL}
            id={ERequestDetailsSections.PRE_ARRIVAL}
          >
            <PrePostAccommodationBlock
              data={preArrivals}
              {...commonPropsForPrePostBlock}
            />
          </section>
        )}

        <OfferRequestDetailsUnits
          currencySymbol={currencySymbol}
          extrasOption={extrasOption}
          isSingle={isSingle}
          isOffer={isOffer}
          days={filteredMeetingDays}
          isRequestWithUnitId={isRequestWithUnitId}
          isRequestWithBedroom={isRequestWithBedroom}
        />

        {!isSingle && (
          <section
            key={ERequestDetailsSections.POST_EVENT}
            id={ERequestDetailsSections.POST_EVENT}
          >
            <PrePostAccommodationBlock
              data={postEvents}
              {...commonPropsForPrePostBlock}
            />
          </section>
        )}

        {isOffer && (
          <TotalSection>
            <TotalTitle>Offer total</TotalTitle>
            <div>
              <TotalPrice>{totalPriceAndCurrency}</TotalPrice>
              <FeesText>Includes taxes and fees</FeesText>
            </div>
          </TotalSection>
        )}

        {![
          ERequestStatus.REQUEST_EXPIRED,
          EOfferStatus.OFFER_ACCEPTING_EXPIRED,
        ].includes(status) && (
          <>
            <Section id={ERequestDetailsSections.TERMS_AND_CONDITIONS}>
              <MainTitle>Terms & Conditions</MainTitle>
              {checkExpirationForStatuses(status) && (
                <>
                  <SubTitle>How long is the offer valid?</SubTitle>
                  <StyledTextes>
                    The offer is valid for the option period requested by the
                    booker defined above. Please note, that if the booker
                    confirms the offer within the stated period, it becomes
                    binding for both parties, you and the booker.
                  </StyledTextes>
                </>
              )}
              <SubTitle>What is the cancellation policy?</SubTitle>
              <StyledTextes>
                {cancellationPolicy.mainText}
                <b> {cancellationPolicy.extraText}</b>
              </StyledTextes>
              <SubTitle>How is the booker charged?</SubTitle>
              <StyledTextes>
                We defer to the venue to arrange a payment method directly with
                the booker. Once this process is finalised, you may utilise the
                provided billing address to generate an invoice.
              </StyledTextes>
              <SubTitle>What if there are additional charges?</SubTitle>
              <StyledTextes>
                Only extras which are not part of this booking can, and should
                be, charged on-site.
              </StyledTextes>
              <SubTitle>What’s HRS Work commission?</SubTitle>
              <StyledTextes>
                HRS Work applies commission on meeting and accommodation
                bookings. For full information refer to our{' '}
                <Link onClick={openTermsAndConditionsModal}>
                  Terms & Conditions
                </Link>
                .
              </StyledTextes>
              {status === ERequestStatus.REQUEST_PENDING &&
                !isRequestWithBedroom && (
                  <>
                    <SubTitle>How is potential worth calculate?</SubTitle>
                    <StyledTextes>
                      We have looked into the latest market trends within your
                      area and calculated what you should expect to earn from
                      creating an offer for this request. The real price
                      submitted with this offer, however, depends entirely on
                      you and your venue revenue requirements.
                    </StyledTextes>
                  </>
                )}
            </Section>
          </>
        )}
      </div>

      <AnchorMenu
        rootId={MODAL_TWO_COLS_RIGHT_COL_ID}
        sections={sections as string[]}
      />
    </Container>
  );
};

export default RequestDetailsInfo;
