import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import {useAppDispatch, useAppSelector} from 'store/hooks';
import {venuesContainer} from 'app';
import {clearPayment, setIsPaymentInProcess} from 'store/payment/paymentSlice';
import {
  getCustomerBookingOrder,
  handleOnSuccessPostCustomerBookingOrder,
  postCustomerBookingOrder,
} from 'store/customer/apiActions';
import {getPaymentDataPayload} from 'view/components/AdyenPayment/helpers';

import {
  IBookingOrderResponse,
  TGroupedUnitsByDay,
} from 'types/dto/IBooking.types';
import {IBookingPreviewFormData} from 'store/venues/types';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {useVenueDetailsData} from './useVenueDetailsData';
import {useVenueDetailsId} from './useVenueDetailsId';
import {
  confirmBookingFailure,
  confirmBookingRequest,
} from 'store/venues/actions';

function useOrderSubmit() {
  const dispatch = useAppDispatch();

  const userId = useAppSelector(({app}) => app.user.id);
  const previewUnits: TGroupedUnitsByDay[] = useSelector((state) =>
    _get(state, `venuesReducer.bookingPreview.bookingMaskPreviewUnits`),
  );

  const {venueDetails} = useVenueDetailsData();
  const [venueId] = useVenueDetailsId();

  const accommodationType = venueDetails.accommodationType;

  const isOffice = accommodationType === EAccommodationType.CORPORATE_OFFICE;

  const clearPaymentProcess = () => {
    dispatch(setIsPaymentInProcess(false));
    dispatch(clearPayment());
  };

  const onStatusConfirmed = (bookingResponse: IBookingOrderResponse) => {
    dispatch(handleOnSuccessPostCustomerBookingOrder(bookingResponse));
    dispatch(getCustomerBookingOrder(bookingResponse.orderNumber));
    clearPaymentProcess();
  };

  // handles confirmation booking action after adyen success result
  // don't forget that we have onFormSubmit in NW2BookingPreview file that
  // handles corporate office booking
  const confirmBooking = (formData: IBookingPreviewFormData) => {
    const data = getPaymentDataPayload({
      userId,
      venueID: Number(venueId),
      formData,
      previewUnits,
      isOffice,
    });

    dispatch(confirmBookingRequest());
    venuesContainer.confirmBooking({
      payload: data,
      onSuccess: (response: IBookingOrderResponse) => {
        onStatusConfirmed(response);
      },
      onError: (error: any) => {
        dispatch(confirmBookingFailure(error));
        clearPaymentProcess();
      },
    });
  };

  // onFormSubmit handles form validation and set form fields to useHandlePaymentResult hook
  // to be able to confirm booking after success from adyen if it's not
  // corporate office venue
  const onFormSubmit = (formData: IBookingPreviewFormData) => {
    if (userId && isOffice) {
      const data = getPaymentDataPayload({
        venueID: Number(venueId),
        userId,
        formData,
        isOffice,
      });

      dispatch(postCustomerBookingOrder(data));
    } else {
      dispatch(setIsPaymentInProcess(true));

      confirmBooking(formData);
    }
  };

  return {
    onFormSubmit,
  };
}

export default useOrderSubmit;
