import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _get from 'lodash/get';

import {setPageForVenuesSearch} from 'store/search/searchSlice';
import {VENUE_LIST_ITEMS_PER_PAGE} from 'constants/app';
import {IMergedVenue} from 'types/search';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {useAppSelector} from 'store/hooks';
import {useIsFastSearchEnabled} from 'view/venue/hooks/useIsFastSearchEnabled';
import {useGetSearchDateDifference} from 'view/venue/hooks/useGetSearchDateDifference';
import {ALLOWED_DAYS_DIFFERENCE_FOR_RFP} from 'constants/days';
import LocalStorageService from 'infra/common/localStorage.service';

type TProps = {
  isFullListShowed: boolean;
};

/**
 * need todo improve placement call
 * now it calls in 2 components NW2VenuesListComponent & NW2VenueListGoogleMap
 */

function useFilterVenuesList({isFullListShowed}: TProps) {
  const dispatch = useDispatch();

  const page = useAppSelector(({search}) => search.searchCriteria.page || 0);
  const venueGlobalList: IMergedVenue[] = useSelector((state) =>
    _get(state, 'venuesReducer.venueGlobalList'),
  );

  const [isFastSearchEnabled] = useIsFastSearchEnabled();
  const [diffInDays] = useGetSearchDateDifference();

  const [venuesList, setVenuesList] = useState<IMergedVenue[]>([]);

  const filteredGlobalList = useMemo(
    () =>
      isFastSearchEnabled || diffInDays > ALLOWED_DAYS_DIFFERENCE_FOR_RFP
        ? venueGlobalList.filter(
            ({accommodationType, totalPrice}) =>
              !!totalPrice ||
              accommodationType === EAccommodationType.CORPORATE_OFFICE,
          )
        : venueGlobalList,
    [diffInDays, isFastSearchEnabled, venueGlobalList],
  );

  const splitVenues = (pageNumber: number, venues: IMergedVenue[]) => {
    const endValue = VENUE_LIST_ITEMS_PER_PAGE * (pageNumber + 1);
    return venues.slice(0, endValue);
  };

  const getVenuesWithPagination = useCallback((): void => {
    const pageNumber = page + 1;
    const venues = splitVenues(pageNumber, filteredGlobalList);

    setVenuesList(venues);

    dispatch(setPageForVenuesSearch(pageNumber));
  }, [page, filteredGlobalList, dispatch]);

  useEffect(() => {
    if (isFullListShowed) {
      setVenuesList(filteredGlobalList);
    } else {
      const venues = splitVenues(0, filteredGlobalList);
      setVenuesList(venues);
    }

    /**
     * reset selectedVenue unit when we are getting new list
     */
    LocalStorageService.removeItemByKey('selectedVenueUnits');
  }, [isFullListShowed, filteredGlobalList]);

  return {
    getVenuesWithPagination,
    totalRecords: filteredGlobalList.length,
    venuesList,
  };
}

export default useFilterVenuesList;
