import React from 'react';
import styled from 'styled-components';
import _get from 'lodash/get';
import {useSelector} from 'react-redux';
import {useAppSelector} from 'store/hooks';

import NW2Button from 'view/components/NW2Button/NW2Button';
import useNavigateToBookingPreview from '../../../hooks/useNavigateToBookingPreview';
import DeskAvailabilityLabel from 'view/components/NW2DeskAvailabilityLabel/NW2DeskAvailabilityLabel';

import {getCurrencySymbol} from 'utils/stringUtils';
import {formatFloatWithOneNumber} from 'utils/venueUtils';

import {IUnit} from 'types/dto/IUnit.types';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {ERoomType} from 'types/dto/ERoomType.type';
import {
  blackColor,
  fontSizeLg,
  fontSizeXSm,
  fontSizeXXXLg,
  fontWeightBold,
  NW2Gray600Color,
  offsetDef,
  mdBp,
  fontSizeXLg,
  fontSizeSm,
  fontSizeXXSm,
  offsetXXLg,
  pagePadding,
  xLgBp,
} from 'constants/styleVars';
import {getButtonText} from '../../AnchorBlock/helpers';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 0 ${offsetDef};

  @media (min-width: ${mdBp}px) {
    grid-template-columns: 1fr auto auto;
  }
`;

const PriceBlock = styled.div<{isWorkDesk?: boolean}>`
  display: flex;
  flex-direction: column;
  column-gap: ${offsetDef};
  user-select: none;

  ${({isWorkDesk}) => isWorkDesk && `flex-direction: row;`};

  @media (min-width: ${mdBp}px) {
    flex-direction: row;
    align-items: center;
  }
  @media (min-width: ${xLgBp}px) {
    gap: ${pagePadding};
  }
`;

const PriceValue = styled.span`
  font-weight: ${fontWeightBold};
  font-size: ${fontSizeXLg};

  @media (min-width: ${mdBp}px) {
    font-size: ${fontSizeXXXLg};
  }
`;

const PriceTitle = styled.div`
  font-size: ${fontSizeSm};
  line-height: ${fontSizeSm};
  font-weight: ${fontWeightBold};

  @media (min-width: ${mdBp}px) {
    font-size: ${fontSizeLg};
    line-height: inherit;
  }
`;

const PriceSubTitle = styled.div<{isMobile: boolean}>`
  color: ${({isMobile}) => (isMobile ? NW2Gray600Color : blackColor)};
  font-size: ${fontSizeXXSm};

  @media (min-width: ${mdBp}px) {
    font-size: ${fontSizeXSm};
  }
`;

const StyledNW2Button = styled(NW2Button)`
  align-self: center;
  grid-column: 2 / 3;
  grid-row: 1 / 3;

  @media (max-width: ${mdBp}px) {
    padding-left: ${offsetXXLg};
    padding-right: ${offsetXXLg};
  }
`;

const StyledDeskAvailabilityLabel = styled(DeskAvailabilityLabel)<{
  isOffice: boolean;
}>`
  justify-self: flex-start;
  align-self: center;
  grid-column: 1 / 2;
  grid-row: ${({isOffice}) => (isOffice ? '1 / 3' : '2 / 3')};

  @media (min-width: ${mdBp}px) {
    grid-column: initial;
    grid-row: initial;
    justify-self: flex-end;
  }
`;

type TProps = {
  unit: IUnit;
};

export function NW2SpaceDetailsFooterPriceBlock({unit}: TProps) {
  const {isMobile, isDesktop} = useAppSelector(({app}) => app.deviceType);
  const {meetingRoomCapacity} = useAppSelector(
    ({search}) => search.searchCriteria,
  );
  const isMultiRoomsBookingNotAvailable = useSelector((state) =>
    _get(state, 'venuesReducer.isMultiRoomsBookingNotAvailable'),
  );

  const {venueDetails} = useVenueDetailsData();

  const accommodationType = venueDetails.accommodationType;
  const currency = venueDetails.currency;

  const redirectToBooking = useNavigateToBookingPreview(
    unit,
    // false
  );

  const {
    roomType,
    // unitStatus,
    // isRfp,
    availableCapacity,
    // totalSearchPrice,
    isRtb,
    unitPrice,
    // available,
  } = unit;

  const isRoomCapacityLow = availableCapacity < meetingRoomCapacity;
  const currencySymbol = getCurrencySymbol(currency);
  // const isNotAvailable = isMultiRooms
  //   ? !available
  //   : unitStatus !== EVenueUnitStatus.AVAILABLE;
  const isOffice = accommodationType === EAccommodationType.CORPORATE_OFFICE;
  const isMeetingRoom = roomType === ERoomType.MEETING_ROOM;
  const showIsRoomCapacityLow = isMeetingRoom && isRoomCapacityLow;

  const buttonText = () => {
    if (isMobile) return 'Book';

    return getButtonText({
      isMultiRoomsBookingNotAvailable,
      isMeetingRoom,
      // isMultiRoomsRFP,
      // isSelectedMeetingRoomRFP: isRfp,
      isOffice,
      isRtb,
    });
  };

  return (
    <Container>
      {!isOffice && (
        <PriceBlock isWorkDesk={!isMeetingRoom}>
          <div>
            <PriceTitle>Total Price</PriceTitle>
            <PriceSubTitle isMobile={isMobile}>
              Inc. taxes and fees
            </PriceSubTitle>
          </div>
          <PriceValue>
            {formatFloatWithOneNumber(unitPrice)}
            &nbsp;
            {currencySymbol}
          </PriceValue>
        </PriceBlock>
      )}

      {!isMeetingRoom && (
        <StyledDeskAvailabilityLabel
          isDark
          isOffice={isOffice}
          capacity={availableCapacity}
        />
      )}

      <StyledNW2Button
        onClick={redirectToBooking}
        size={isMobile ? 'large' : isDesktop ? 'medium' : 'small'}
        // disabled={isNotAvailable || isMultiRoomsBookingNotAvailable}
        buttonType='primary'
        inverted={showIsRoomCapacityLow}
        inline
      >
        {buttonText()}
      </StyledNW2Button>
    </Container>
  );
}

export default NW2SpaceDetailsFooterPriceBlock;
