import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import DateUtils from 'utils/dateUtils';
import Image from 'view/components/Image';

import {OfferUnitImage} from 'view/venue/Offer/components/OfferUnitImage/OfferUnitImage';
import {AccommodationExtras} from '../AccommodationExtras/AccommodationExtras';

import {
  formatFloatWithOneNumber,
  getFilteredExtrasByType,
} from 'utils/venueUtils';
import {RequestUnitExtras} from './RequestUnitExtras';
import {findCoverImage, pluralize} from 'utils/helpers';

import {EResourcesType} from 'types/dto/IExtras.type';
import {ERoomSchema} from 'types/venue';
import {
  ExtrasTitle,
  Paragraph,
  Span,
  UnitCard,
  UnitItem,
  UnitName,
  UnitPrice,
  UnitTitle,
  RentalText,
  StyledSpan,
} from 'view/supplier/Bookings/BookingDetails/BookingDetails.styles';
import {IOfferRequestUnit, IOfferUnitExtra} from 'types/offer';
import {
  NW2Gray800Color,
  NW2Gray100Color,
  offsetXXLg,
  lineHeightMd,
  borderRadiusDef,
} from 'constants/styleVars';
import {IExtrasOption} from 'types/dto/IExtras.type';
import {IOfferRequestReviewUnitExtra} from '../OfferRequestReviewUnitCard/types';

interface IProps {
  index: number;
  unit: IOfferRequestUnit;
  bedrooms: IOfferUnitExtra[];
  foodAndBeverage: IOfferRequestReviewUnitExtra[];
  isUnitSingle: boolean;
  date: string;
  isUnitLast?: boolean;
  isOffer: boolean;
  currencySymbol?: string;
  isRequestWithBedroom: boolean;
  isRequestWithUnitId?: boolean;
}

export function RequestDetailsUnit({
  index,
  unit,
  bedrooms,
  foodAndBeverage,
  isUnitSingle,
  isUnitLast,
  isOffer,
  currencySymbol,
  isRequestWithBedroom,
  isRequestWithUnitId,
}: IProps) {
  const extrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );
  const {setupStyle, checkIn, checkOut, participants, extras} = unit;

  const checkInTime = DateUtils.getHoursAndMinutes(checkIn);
  const checkOutTime = DateUtils.getHoursAndMinutes(checkOut);

  const extrasData = useMemo(() => {
    return extras
      ? isOffer || isRequestWithBedroom
        ? // sorted by price
          [...extras].sort((a, b) =>
            a.totalPrice && b.totalPrice
              ? b.totalPrice.value - a.totalPrice.value
              : 0,
          )
        : extras
      : [];
  }, [extras, isOffer, isRequestWithBedroom]);

  const equipmentExtras = useMemo(
    () =>
      getFilteredExtrasByType(
        extrasData,
        EResourcesType.EQUIPMENT,
        extrasOption,
      ),
    [extrasData, extrasOption],
  );

  const foodAndBeverageExtras = useMemo(
    () =>
      foodAndBeverage.length !== 0 && (
        <>
          <ExtrasTitle>Food & beverage</ExtrasTitle>
          <RequestUnitExtras
            data={foodAndBeverage}
            isOffer={isOffer}
            isRequestWithUnitId={isRequestWithUnitId}
            currencySymbol={currencySymbol}
          />
        </>
      ),
    [foodAndBeverage, isOffer, currencySymbol, isRequestWithUnitId],
  );

  const accommodationExtras = (
    <AccommodationExtras
      unitExtras={bedrooms}
      extrasOption={extrasOption}
      currencySymbol={currencySymbol}
      isOffer={isOffer}
      isRequestWithUnitId={isRequestWithUnitId}
    />
  );

  return (
    <UnitItem>
      <UnitTitle>Meeting room {!isUnitSingle ? index + 1 : ''}</UnitTitle>
      <UnitCard>
        {isOffer || isRequestWithUnitId ? (
          <Image
            src={
              unit.unitInfo && findCoverImage(unit.unitInfo.documents || []).url
            }
            width='162px'
            height='106px'
            borderRadius={borderRadiusDef}
            data-testid='booking-details-unit-card-image'
          />
        ) : (
          <OfferUnitImage
            iconColor={NW2Gray800Color}
            bgColor={NW2Gray100Color}
            iconWidth='162px'
            boxHeight='106px'
            spacing={`${lineHeightMd} ${offsetXXLg}`}
            data-testid='booking-details-unit-card-image'
          />
        )}

        <div>
          <UnitName isRequest>
            {(isOffer || isRequestWithBedroom || isRequestWithUnitId) &&
            unit.unitInfo
              ? unit.unitInfo.unitName
              : `${ERoomSchema[setupStyle]} Meeting Room`}
          </UnitName>
          <Paragraph>
            Time:{' '}
            <Span>
              {checkInTime} - {checkOutTime}
            </Span>
          </Paragraph>
          <Paragraph>
            {isOffer || isRequestWithBedroom ? 'Room setup:' : 'Setup style:'}{' '}
            <Span>{setupStyle ? ERoomSchema[setupStyle] : '-'}</Span>
          </Paragraph>
          <Paragraph>
            Participants:{' '}
            <Span>
              {pluralize('people', participants || 0)}
              {(isOffer || isRequestWithBedroom) && unit.maxParticipants
                ? ` (${unit.maxParticipants} max)`
                : ''}
            </Span>
          </Paragraph>
        </div>
      </UnitCard>
      <div>
        {(isOffer || isRequestWithBedroom || isRequestWithUnitId) && (
          <UnitPrice>
            <RentalText>Room rental</RentalText>
            <StyledSpan>
              {unit.unitPrice && (
                <>
                  {formatFloatWithOneNumber(unit.unitPrice.value)}{' '}
                  {currencySymbol}
                </>
              )}
            </StyledSpan>
          </UnitPrice>
        )}
        <RequestUnitExtras
          data={equipmentExtras}
          isOffer={isOffer}
          isRequestWithUnitId={isRequestWithUnitId}
          currencySymbol={currencySymbol}
        />
        {/* Food and beverage */}
        {isUnitLast && foodAndBeverageExtras}

        {/* Accommodation */}
        {isUnitLast && accommodationExtras}
      </div>
    </UnitItem>
  );
}
