import DateUtils, {getDateTime} from 'utils/dateUtils';

import {
  ITimeData,
  SEARCH_DEFAULT_END_TIME,
  SEARCH_DEFAULT_START_TIME,
  TDateType,
} from 'types/dto/ISearch.types';
import {
  TDay,
  TMeetingRoomItem,
  TSearchCriteriaExtra,
  TSearchVenuesDay,
  TSelectedOptions,
  TSummaryExtra,
} from 'types/search';
import {
  EDefaultExtras,
  EResourcesCode,
  EResourcesType,
  IExtrasOption,
} from 'types/dto/IExtras.type';
import {TBedroomsCatering} from './components/AddAccommodationRequest/types';
import {getAccommodationData} from './components/AddAccommodationRequest/helpers';
import {getISOString} from 'utils/dateUtils';
import {EEventType} from 'types/venue';

const {findMinMaxTime, getHoursAndMinutes, normalizeDateToBackendFormat} =
  DateUtils;

export const getDayTimeRangeText = (
  rooms: {
    timeStart: TDateType | string;
    timeEnd: TDateType | string;
  }[],
) => {
  if (!rooms.length) return '';
  const timeStart = findMinMaxTime(rooms, 'timeStart', false);
  const timeEnd = findMinMaxTime(rooms, 'timeEnd', true);

  return `${getHoursAndMinutes(timeStart)} - ${getHoursAndMinutes(timeEnd)}`;
};

export const getRoomTimeRangeText = (timeStart: string, timeEnd: string) =>
  `${getHoursAndMinutes(timeStart)} - ${getHoursAndMinutes(timeEnd)}`;

const makeSummaryExtrasData = (extrasData: TSelectedOptions | null) => {
  if (!extrasData) return null;

  const optionsObjById = extrasData.options.reduce((prev, {id, name}) => {
    return {...prev, [id]: name};
  }, {} as Record<string, string>);

  return Object.entries(extrasData.data).reduce((prev, [textId, count]) => {
    const id = textId.slice(0, -1);
    const extraName = optionsObjById[id];
    const countText = id === EDefaultExtras.wifi.toString() ? '-' : `x${count}`;
    return extraName && extrasData.ids.includes(+id)
      ? [...prev, [extraName, countText]]
      : prev;
  }, [] as any);
};

export const makeSummaryData = (meetingRequestData: TDay[]) =>
  meetingRequestData
    .map(
      ({
        startDate,
        endDate,
        rooms,
        foodBeverageData,
        isSectionExpanded,
        accommodationData,
        isPreMeet,
        isPostMeet,
        participants,
      }) => {
        const foodBeverage = makeSummaryExtrasData(foodBeverageData);
        const accommodation = makeSummaryExtrasData(accommodationData);

        const dayTimeRange = rooms.length ? getDayTimeRangeText(rooms) : '';
        const roomsData = rooms.length
          ? rooms.reduce(
              (
                prev,
                {timeStart, timeEnd, participants, seatsSetup, equipmentData},
              ) => {
                const roomTimeRange = getRoomTimeRangeText(timeStart, timeEnd);
                const equipments = makeSummaryExtrasData(equipmentData);
                return [
                  ...prev,
                  {
                    roomTimeRange,
                    participants,
                    seatsSetup,
                    equipments,
                  },
                ];
              },
              [] as TSummaryExtra[],
            )
          : [];

        return {
          startDate,
          endDate,
          dayTimeRange,
          roomsData,
          foodBeverage,
          accommodation,
          isPreMeet,
          isPostMeet,
          isSectionExpanded,
          dayParticipants: participants,
        };
      },
    )
    .filter(
      ({dayTimeRange, isPreMeet, isPostMeet}) =>
        !!dayTimeRange || isPreMeet || isPostMeet,
    ); // can be empty for removed day items due setup multi day

const setExtraPayloadData = (
  options: TSelectedOptions,
  extraType?: EResourcesType,
): TSearchCriteriaExtra[] =>
  options?.ids
    ? options.ids.map((extraId) => ({
        extraId,
        code: options.options.find(({id}) => id === extraId)
          ?.code as EResourcesCode,
        quantity: options.data[`${extraId}_`],
        ...(extraType ? {extraType} : {}),
      }))
    : [];

interface IMakeMultiSearchPayload {
  meetingRequestData: TDay[];
  timeDataFromStore?: ITimeData[];
  bedroomsCatering?: TBedroomsCatering;
  filteredBedroomExtras?: IExtrasOption[];
}
export const makeMultiSearchPayload = ({
  meetingRequestData,
  timeDataFromStore,
  bedroomsCatering,
  filteredBedroomExtras,
}: IMakeMultiSearchPayload) => {
  const timeData: ITimeData[] = [];
  const updRequestData: TDay[] = [];

  const makeData = ({
    requestData,
    rooms,
    foodBeverageData,
    accommodationData,
    startDate,
    endDate,
    maxParticipants,
    dayIdx,
  }: {
    requestData: TSearchVenuesDay[];
    rooms: TMeetingRoomItem[];
    foodBeverageData: TSelectedOptions | null;
    accommodationData: TSelectedOptions | null;
    startDate: string;
    endDate: string;
    maxParticipants: number;
    dayIdx: number;
  }): TSearchVenuesDay[] => [
    ...requestData,
    {
      foodAndBeverage: foodBeverageData?.ids
        ? setExtraPayloadData(foodBeverageData)
        : [],
      bedrooms:
        accommodationData?.ids &&
        bedroomsCatering?.[startDate] &&
        filteredBedroomExtras
          ? setExtraPayloadData(
              getAccommodationData({
                accommodationData,
                bedroomsCateringDay: bedroomsCatering[startDate],
                filteredBedroomExtras,
              }) as TSelectedOptions,
              EResourcesType.BEDROOM,
            )
          : [],
      checkIn: startDate,
      checkOut: endDate,
      maxParticipants,
      eventType: EEventType.DAY,
      roomFilters: rooms.map(
        (
          {participants, timeEnd, timeStart, equipmentData, seatsSetup},
          roomIdx,
        ) => ({
          checkIn: normalizeDateToBackendFormat(timeStart),
          checkOut: normalizeDateToBackendFormat(timeEnd),
          capacity: participants,
          setupStyle: seatsSetup,
          position:
            meetingRequestData.reduce((acc, day, calcDayIdx) => {
              if (calcDayIdx < dayIdx) {
                acc += day.rooms.length;
              }
              return acc;
            }, 0) + roomIdx,
          extras: equipmentData.ids ? setExtraPayloadData(equipmentData) : [],
        }),
      ),
    },
  ];

  const makeArrivalsData = ({
    requestData,
    accommodationData,
    date,
    eventType,
  }: {
    requestData: TSearchVenuesDay[];
    accommodationData: TSelectedOptions | null;
    date: string;
    eventType: EEventType;
  }): TSearchVenuesDay[] => [
    ...requestData,
    {
      checkIn: normalizeDateToBackendFormat(
        getISOString(getDateTime(date).set(SEARCH_DEFAULT_START_TIME)),
      ),
      checkOut: normalizeDateToBackendFormat(
        getISOString(getDateTime(date).set(SEARCH_DEFAULT_END_TIME)),
      ),
      eventType,
      bedrooms:
        accommodationData?.ids &&
        bedroomsCatering?.[date] &&
        filteredBedroomExtras
          ? setExtraPayloadData(
              getAccommodationData({
                accommodationData,
                bedroomsCateringDay: bedroomsCatering[date],
                filteredBedroomExtras,
              }) as TSelectedOptions,
              EResourcesType.BEDROOM,
            )
          : [],
    },
  ];

  const multiSearchPayload = meetingRequestData?.reduce(
    (
      requestData,
      {
        rooms,
        foodBeverageData,
        accommodationData,
        isPostMeet,
        isPreMeet,
        startDate,
        endDate,
        participants,
      },
      dayIdx,
    ) => {
      const isPreOrPostMeet = isPreMeet || isPostMeet;

      if (!rooms?.length && !isPreOrPostMeet) {
        return requestData;
      }

      if (timeDataFromStore?.[dayIdx]) timeData.push(timeDataFromStore[dayIdx]);

      updRequestData.push(meetingRequestData[dayIdx]);

      if (isPreOrPostMeet) {
        return makeArrivalsData({
          requestData,
          accommodationData,
          date: startDate,
          eventType: isPreMeet ? EEventType.PRE_ARRIVAL : EEventType.POST_EVENT,
        });
      }

      return makeData({
        requestData,
        rooms,
        foodBeverageData,
        accommodationData,
        startDate,
        endDate,
        maxParticipants: participants,
        dayIdx,
      });
    },
    [] as TSearchVenuesDay[],
  );

  return {
    timeData,
    multiSearchPayload,
    requestData: updRequestData,
  };
};

// export const parseDateValuesInMultiSearch = (
//   meetingRequestData: TDay[],
//   participants?: number,
// ): TDay[] =>
//   meetingRequestData.map(({rooms, date, ...rest}) => ({
//     ...rest,
//     date,
//     rooms: rooms.map(({timeEnd, timeStart, ...rest}) => ({
//       ...rest,
//       participants: participants || rest.participants,
//       timeStart,
//       timeEnd,
//     })),
//   }));
//
// export const isDifferInitialData = (
//   timeData: ITimeData[],
//   initialTimeData: ITimeData[],
// ) => {
//   if (timeData.length !== initialTimeData.length) return true;
//   return timeData.some((item, index) => {
//     return (
//       item.date &&
//       initialTimeData[index].date &&
//       (!isSameMomentInTime(item.timeStart, initialTimeData[index].timeStart) ||
//         !isSameMomentInTime(item.timeEnd, initialTimeData[index].timeEnd))
//     );
//   });
// };

export const convertExtraNameToEnum = (name?: string) => {
  if (!name) return '';
  const generalReg = /\s|-/g;
  const roundBracketsReg = /[(|)]/g;
  return name
    .toLowerCase()
    .replaceAll(generalReg, '_')
    .replaceAll(roundBracketsReg, '');
};

export const getPreselectedExtrasIds = (
  extraNames: string[],
  allExtra: IExtrasOption[],
) =>
  allExtra
    .filter(({name}) => extraNames.includes(convertExtraNameToEnum(name)))
    .map(({id}) => id);

export const datePlusDays = (date: string, daysOffset: number) => {
  return getISOString(getDateTime(date).plus({days: daysOffset}));
};

export const getDayCopy = (
  meetingRequestData: TDay[],
  dayIndex: number,
  daysOffset: number,
): TDay => {
  const extraDayData = meetingRequestData[dayIndex];
  const isPreMeet = daysOffset === -1;

  return {
    ...extraDayData,
    startDate: datePlusDays(extraDayData.startDate, daysOffset),
    foodBeverageData: null,
    isSectionExpanded: true,
    isPreMeet,
    accommodationData: null,
    isPostMeet: !isPreMeet,
    rooms: [],
  };
};

export const getTimeDataItem = (
  timeDataItem: ITimeData,
  daysOffset: number,
): ITimeData => ({
  timeStart: datePlusDays(timeDataItem.timeStart, daysOffset),
  timeEnd: datePlusDays(timeDataItem.timeEnd, daysOffset),
  eventType: daysOffset === 1 ? EEventType.POST_EVENT : EEventType.PRE_ARRIVAL,
});
