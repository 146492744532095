import {SyntheticEvent, useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {offersContainer} from 'app';
import {Routes} from 'constants/routes';
import useToggle from 'hooks/useToggle';
import {
  setDeclineModalId,
  setSupplierOfferRequest,
} from 'store/offers/offersSlice';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {EDeclineItemType, IRequestV2} from 'types/offer';
import {requestGroupCancel, requestOfferDecline} from 'store/offers/apiActions';

export interface IOfferHandlers {
  activeModal: string;
  handleConfirm: (orderId: string) => void;
  handleConfirmEmail: () => void;
  handleDecline: <TPayload>(
    payload: TPayload,
    declineItem: EDeclineItemType,
  ) => void;
  isConfirmShowed: boolean;
  isDeclineShowed: boolean;
  isSending: boolean;
  toggleShowConfirm: () => void;
  toggleShowDecline: () => void;
  onShowConfirmModal: (orderId: string) => (e?: SyntheticEvent) => void;
  onShowDeclineModal: (orderId: string) => (e?: SyntheticEvent) => void;
}

export function useOfferHandlers({
  forwardLink = '',
  token,
  getOffers,
  onCloseOrderDetails,
}: {
  forwardLink?: string;
  token?: string;
  offer?: IRequestV2 | Record<string, never>;
  getOffers?: () => void;
  onCloseOrderDetails?: () => void;
}): IOfferHandlers {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const declineModalId = useAppSelector(({offers}) => offers.declineModalId);

  const [activeModal, setActiveModal] = useState('');
  const [isConfirmShowed, toggleShowConfirm] = useToggle(false);
  const [isDeclineShowed, toggleShowDecline] = useToggle(false);
  const [isSending, setSending] = useState(false);

  const handleSuccess = useCallback(
    (link?: string) => {
      setSending(false);
      setActiveModal('');
      getOffers?.();
      onCloseOrderDetails?.();

      link && navigate(link);

      setTimeout(() => {
        document.body.style.overflow = '';
      }, 1000);
    },
    [getOffers, navigate, onCloseOrderDetails],
  );

  //TODO: change  confirm request according to what we do on confirm
  const handleConfirm = useCallback(() => {}, []);

  const handleConfirmEmail = useCallback(() => {
    toggleShowDecline();
    handleSuccess(forwardLink);
  }, [toggleShowDecline, handleSuccess, forwardLink]);

  const handleDecline = useCallback(
    async <TPayload>(payload: TPayload, declineItem: EDeclineItemType) => {
      setSending(true);

      const onError = () => {
        setSending(false);
      };

      const onSuccess = () => {
        handleSuccess();
        dispatch(setDeclineModalId(''));
        toggleShowDecline();
      };

      switch (declineItem) {
        case EDeclineItemType.CUSTOMER:
          offersContainer.declineOfferById({
            payload,
            onSuccess,
            onError,
          });
          break;

        case EDeclineItemType.NOT_REGISTERED_SUPPLIER:
          offersContainer.declineOfferRequestByToken({
            payload: {token, ...payload},
            onSuccess: (response: IRequestV2) => {
              dispatch(setSupplierOfferRequest(response));
              handleSuccess(token ? Routes.requestDeclineSuccess : '');
              toggleShowDecline();
            },
            onError,
          });
          break;

        case EDeclineItemType.OFFER:
          offersContainer.cancelOfferById({
            payload,
            onSuccess,
            onError,
          });
          break;

        case EDeclineItemType.RFP:
          offersContainer.declineOfferRequestById({
            payload,
            onSuccess,
            onError,
          });
          break;

        case EDeclineItemType.REQUEST_GROUP:
          dispatch(
            requestGroupCancel({
              orderId: payload as string,
              onSuccessHandler: onSuccess,
            }),
          );
          break;

        case EDeclineItemType.SINGLE_REQUEST:
          dispatch(requestOfferDecline(payload as string, onSuccess));
          break;

        default:
          break;
      }
    },

    [dispatch, handleSuccess, toggleShowDecline, token],
  );

  const onShowConfirmModal = useCallback(
    (orderId: string) => (e?: SyntheticEvent) => {
      // prevent conflict click events between clicks for row & button inside row
      e?.stopPropagation();
      setActiveModal(orderId);
      toggleShowConfirm();
    },
    [toggleShowConfirm],
  );

  const onShowDeclineModal = useCallback(
    (orderId: string) => (e?: SyntheticEvent) => {
      // prevent conflict click events between clicks for row & button inside row
      e?.stopPropagation();
      setActiveModal(orderId);
      toggleShowDecline();
    },
    [toggleShowDecline],
  );

  //to show decline modal straight after login to already registered venue from request email
  useEffect(() => {
    if (declineModalId) {
      setActiveModal(declineModalId);
      toggleShowDecline();
    }
  }, [declineModalId, toggleShowDecline]);

  return {
    activeModal,
    handleConfirm,
    handleConfirmEmail,
    handleDecline,
    isConfirmShowed,
    isDeclineShowed,
    isSending,
    toggleShowConfirm,
    toggleShowDecline,
    onShowConfirmModal,
    onShowDeclineModal,
  };
}
