import React, {memo} from 'react';
import styled, {css} from 'styled-components';

import {
  fontSizeMd,
  lineHeightLg,
  offsetXLg,
  offsetXSm,
} from 'constants/styleVars';
import {TSearchCriteriaExtra} from 'types/search';
import {IOfferUnitExtra} from 'types/offer';
import {
  EResourcesType,
  IExtrasOption,
  IExtrasResponse,
} from 'types/dto/IExtras.type';
import {ISummaryExtra} from 'types/dto/IBooking.types';
import {IPreviewExtra} from 'store/venues/types';
import {CODE_TO_EXTRA_NAME} from 'constants/extras';

const List = styled.ul<{hasDots?: boolean}>`
  margin: 0;
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  list-style: ${({hasDots}) => !hasDots && 'none'};
  padding-left: 0;

  li + li {
    margin-top: ${offsetXSm};
  }

  ${({hasDots}) =>
    hasDots &&
    css`
      margin-left: ${offsetXLg};
    `}
`;

const ListItem = styled.div`
  display: flex;
`;

const ListItemQty = styled.span`
  margin-right: ${offsetXSm};
  display: flex;
  min-width: 34px;
`;

interface IProps {
  extras: (
    | TSearchCriteriaExtra
    | IPreviewExtra
    | IOfferUnitExtra
    | ISummaryExtra
  )[];
  extrasOptions: (IExtrasOption | IExtrasResponse)[];
  hasDots?: boolean;
}

const PreviewExtrasList = memo(({extras, hasDots, extrasOptions}: IProps) => (
  <List hasDots={hasDots}>
    {extras.map((extra) => {
      const extraItem = extrasOptions.find(({code}) => code === extra.code);

      if (!extraItem) return null;

      const qty =
        (extra as IPreviewExtra).chosenQuantity ||
        (extra as TSearchCriteriaExtra).quantity ||
        (extra as ISummaryExtra).bookedQuantity;

      const name =
        (extraItem as IExtrasResponse).extraType === EResourcesType.BEDROOM ||
        (extraItem as IExtrasOption).type === EResourcesType.BEDROOM
          ? CODE_TO_EXTRA_NAME[extraItem.code]
          : extraItem.name;

      return (
        <li key={'extras-list-item' + extra.code}>
          <ListItem>
            <ListItemQty>{qty} x </ListItemQty>
            {name}
          </ListItem>
        </li>
      );
    })}
  </List>
));

export default PreviewExtrasList;
