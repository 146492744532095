import React from 'react';
import styled, {css} from 'styled-components';

import {StyledH1, StyledH3} from 'view/components/Typography';
import {
  blackColor,
  lgBp,
  mdBp,
  NW2FontSizeH1Lg,
  NW2FontSizeH1Md,
  NW2FontSizeH1Xs,
  offsetDef,
  offsetXLg,
  offsetXXLg,
  sectionInsetSm,
} from 'constants/styleVars';
import {NW2ContainerWithOffset} from 'view/mainLanding/MainLanding.styles';

const slideAnimation = css`
  animation: marquee 25s linear infinite;
  @keyframes marquee {
    0% {
      transform: translateX(-33.3%);
    }
    100% {
      transform: translateX(-66.6%);
    }
  }
`;

const AnimatedSlider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: nowrap;
  ${slideAnimation}
`;

const Container = styled.div`
  text-align: center;
  padding: ${offsetXXLg} 0;
  border-top: 2px solid ${blackColor};
  border-bottom: 2px solid ${blackColor};

  @media (min-width: ${mdBp}px) {
    padding: ${sectionInsetSm} 0;
  }
`;
const AnimatedSliderContainer = styled.div`
  position: relative;
  padding-top: calc(${NW2FontSizeH1Xs} * 0.8);
  margin-top: ${offsetDef};

  @media (min-width: ${mdBp}px) {
    margin-top: ${offsetXLg};
    padding-top: calc(${NW2FontSizeH1Md} * 0.8);
  }

  @media (min-width: ${lgBp}px) {
    padding-top: calc(${NW2FontSizeH1Lg} * 0.8);
  }

  h1 {
    white-space: nowrap;
    &:after {
      position: relative;
      top: -5px;
      content: '•';
      display: inline-block;
      margin: 0 ${offsetDef};
    }

    @media (min-width: ${mdBp}px) {
      font-size: 90px;
    }
  }
`;

type TProps = {
  subTitle: string;
  sliderList: string[];
};

export function AnimatedTextSlider({subTitle, sliderList}: TProps) {
  return (
    <NW2ContainerWithOffset>
      <Container>
        <StyledH3 NW2Gray800>{subTitle}</StyledH3>

        <AnimatedSliderContainer>
          <AnimatedSlider>
            {[...sliderList, ...sliderList, ...sliderList].map((el, index) => (
              <StyledH1 key={index} black>
                {el}
              </StyledH1>
            ))}
          </AnimatedSlider>
        </AnimatedSliderContainer>
      </Container>
    </NW2ContainerWithOffset>
  );
}
