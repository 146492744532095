import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useForm} from 'react-final-form';
import queryString from 'query-string';
import _get from 'lodash/get';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {postNewPaymentMethod} from 'store/payment/apiActions';

import AdyenCardComponent from 'view/components/AdyenPayment/AdyenCardComponent';
import NW2Button from 'view/components/NW2Button';
import {NW2FormItemCheckbox} from 'view/components/NW2FormItem/components';

import {ButtonsContainer} from 'view/components/AdyenPayment/AdeynPayment.styles';
import {Group} from 'styles/basicStyledComponents/group';

function AddModalForm({onClose}: {onClose: () => void}) {
  const dispatch = useAppDispatch();
  const form = useForm();
  const customerId = useAppSelector(({app}) => app.user.id);
  const config = useSelector((state) => _get(state, 'payment.config'));
  const isPaymentInProcess = useSelector((state) =>
    _get(state, 'payment.isPaymentInProcess'),
  );

  const adyenProps = useMemo(
    () => ({
      ...config,
      paymentMethodsResponse: {},
      paymentMethodsConfiguration: {
        card: {
          ...config.paymentMethodsConfiguration.card,
        },
      },
      async onSubmit(state: any, component: any) {
        const isDefaultMethod = form.getState().values.defaultMethod;
        const returnUrl = queryString.exclude(window.location.href, [
          'redirectResult',
        ]);
        const paymentMethodDetails = {
          ...state.data,
          defaultPaymentMethod: isDefaultMethod,
          returnUrl,
        };
        dispatch(
          postNewPaymentMethod({
            paymentMethodDetails,
            customerId,
            component,
          }),
        );
      },
    }),
    [dispatch, config, form, customerId],
  );

  return (
    <div>
      <Group>
        <AdyenCardComponent adyenCoreOptions={adyenProps} />
        <NW2FormItemCheckbox
          id='defaultMethod'
          name='defaultMethod'
          label='Save as preferred payment method'
        />
      </Group>
      <ButtonsContainer>
        <NW2Button
          type='button'
          inline
          buttonType='secondary'
          onClick={onClose}
          size='responsiveAdaptive'
          disabled={isPaymentInProcess}
        >
          Cancel
        </NW2Button>

        <NW2Button
          type='submit'
          inline
          buttonType='primary'
          size='responsiveAdaptive'
          loading={isPaymentInProcess}
        >
          {isPaymentInProcess ? 'Loading...' : 'Add new card'}
        </NW2Button>
      </ButtonsContainer>
    </div>
  );
}

export default AddModalForm;
