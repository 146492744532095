import {IAmenity} from './IAmenity';
import {IDay} from './IDay.type';
import {IUnitType} from './IUnitType.type';
import {TImage} from 'types/app';
import {IBooking} from 'types/dto/IBooking.types';
import {ERoomType} from 'types/dto/ERoomType.type';
import {EEventType, ERoomSchemaNames, IUnitDimensions} from 'types/venue';
import {IOfferUnitExtra} from 'types/offer';
import {TSearchCriteriaExtra} from 'types/search';

export enum EVenueUnitStatus {
  SOLD_OUT = 'SOLD_OUT',
  AVAILABLE = 'AVAILABLE',
  CLOSED = 'CLOSED',
  NONE = 'NONE',
}
export type TUnitFloor = null | number; // null can be for WIP units

export type TUnitFilter = {
  id: string;
  startDate: string;
  endDate: string;
  capacity: number;
  setupStyle: ERoomSchemaNames;
  unitFilter?: any;
  extras: TSearchCriteriaExtra[];
};

export interface TOfferFilter extends Omit<TUnitFilter, 'extras'> {
  extras: IOfferUnitExtra[];
}
export interface IUnitWithDetailedExtras extends Omit<IUnit, 'unitFilter'> {
  unitFilter: TOfferFilter;
}

export type TUnitCapacityStyle = {
  capacity: number;
  setupStyle: ERoomSchemaNames;
};

export interface IUnit {
  id: number;
  availableCapacity: number;
  capacity: number;
  operationalTimes: IDay[];
  unitTypeId: number;
  unitType: IUnitType;
  totalSearchPrice: number;
  floorNumber: null | number | string;
  bookingInfo?: IBooking;
  unitStatus: EVenueUnitStatus;
  isRfp?: boolean;
  roomSetup?: ERoomSchemaNames; // todo clarify about it exists & usage in MeetingRoomSpaceItem
  unitFilter: TUnitFilter; // todo adds due setup multibook filter, should be removed from dto
  available: boolean;
  eventType?: EEventType;

  // common fields
  amenities: IAmenity[];
  description: string;
  documents: TImage[];
  floor: TUnitFloor;
  name: string;
  priceDay: number;
  priceHour: number;
  priceHalfDay: number;
  roomType: ERoomType;
  type: ERoomType;
  rfp?: boolean;
  unitCapacities?: TUnitCapacityStyle[]; // doesn't exist for WD
  unitDimensions: IUnitDimensions;
  unitId?: number;

  // new fields
  checkIn?: string;
  checkOut?: string;
  unitPrice?: number;
  isRtb?: boolean;
}
