import React from 'react';

import {LegalEntityTypeCaption, IVenueLegalContact} from 'types/venue';

import {ColumnWrapper, InfoRow, TwoColumnInfoRow} from './NMMPayout.styles';
import {SectionTitle} from '../../../../components/NMMSection/NMMSection.styles';
import {fontSizeSm} from 'styles/configs/typography';
import {offsetDef} from 'styles/configs/offset';

type TProps = {
  legalContact: IVenueLegalContact;
};

const BusinessInformationView = ({legalContact}: TProps) => {
  const {
    legalEntityType,
    companyName,
    streetName,
    buildingNumber,
    country,
    city,
    postalCode,
    region,
    registrationNumber,
    taxNumber,
    legalPhone,
  } = legalContact;

  const {phone, phoneCountryCode} = legalPhone;

  return (
    <ColumnWrapper>
      <div>
        <SectionTitle titleFontSize={fontSizeSm} titleMarginBottom={offsetDef}>
          Registered Business
        </SectionTitle>
        <InfoRow>{LegalEntityTypeCaption[legalEntityType]}</InfoRow>
        <InfoRow>{companyName}</InfoRow>
        <InfoRow>
          {streetName} {buildingNumber}
        </InfoRow>
        <InfoRow>
          {postalCode} {city}
        </InfoRow>
        <InfoRow>{region}</InfoRow>
        <InfoRow>{country}</InfoRow>
        <InfoRow>{`+${phoneCountryCode}${phone}`}</InfoRow>
      </div>
      <div>
        <SectionTitle titleFontSize={fontSizeSm} titleMarginBottom={offsetDef}>
          Legal information
        </SectionTitle>
        {!!registrationNumber && (
          <TwoColumnInfoRow>
            <span>Registration number</span>
            <span>{registrationNumber}</span>
          </TwoColumnInfoRow>
        )}
        <TwoColumnInfoRow>
          <span>Company tax ID</span>
          <span>{taxNumber}</span>
        </TwoColumnInfoRow>
      </div>
    </ColumnWrapper>
  );
};

export default BusinessInformationView;
