import styled from 'styled-components';

import NW2Button from 'view/components/NW2Button';

import {
  borderRadiusMLg,
  fontWeightExtraBold,
  NW2ErrorLight50Color,
  NW2Error500Color,
  offsetXLg,
  fontSizeXXXLg,
  offsetSm,
  fontSizeLg,
  fontWeightBold,
  offsetXXSm,
  fontSizeSm,
  NW2Gray200Color,
  fontSizeMd,
  offsetDef,
  offsetXSm,
} from 'constants/styleVars';

export const Container = styled.div`
  border: 1px solid ${NW2Error500Color};
  padding: ${offsetXLg};
  background: ${NW2ErrorLight50Color};
  border-radius: ${borderRadiusMLg};
`;

export const Title = styled.div`
  text-transform: uppercase;
  font-size: ${fontSizeXXXLg};
  font-weight: ${fontWeightExtraBold};
  margin-bottom: ${offsetDef};
`;

export const SubTitle = styled.div`
  font-size: ${fontSizeLg};
  font-weight: ${fontWeightBold};
  margin-bottom: ${offsetSm};
`;

export const Description = styled.div`
  font-size: ${fontSizeSm};
  margin-bottom: ${offsetXLg};
`;

export const Content = styled.div`
  font-size: ${fontSizeMd};
  padding-top: ${offsetXLg};
  border-top: 1px solid ${NW2Gray200Color};

  ul {
    list-style-type: disc;
    padding-left: ${offsetXLg};

    li {
      margin-bottom: ${offsetXXSm};
    }
  }

  & > ul {
    margin: 0;
    padding-left: ${offsetDef};
  }
`;

export const TextWrapper = styled.div`
  margin-bottom: ${offsetXSm};
`;

export const StyledButton = styled(NW2Button)`
  margin-bottom: ${offsetXLg};
  text-transform: uppercase;
`;
