import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {Field} from 'react-final-form';
import _get from 'lodash/get';

import NW2FormTextarea from 'view/components/NW2FormItem/components/Textarea';
import ErrorMessage from 'view/components/NW2FormItem/components/ErrorMessage';

import {NW2FormItemInput} from 'view/components/NW2FormItem/NW2FormItem';
import {
  AddDimensions,
  AddFloor,
  AddImages,
  AddRoomPrice,
} from 'view/venue/NW2VenueProfile/components/AddSpace/components';
import {StepCapacities} from './components/StepCapacities';
import {StepExtrasPrices} from './components/StepExtrasPrices';
import {StepSpaceSelection} from './components/StepSpaceSelection/StepSpaceSelection';
import {StepHint} from './components/StepHint';
import {LastStep} from './components/LastStep';

import {
  PageContent,
  PageImagesUploadInputWrapper,
} from 'view/venue/NW2VenueProfile/components/AddSpace/styles';
import {AmenitiesContainer, PricesContainer} from './OfferCreate.styles';
import {
  ECreateOfferFormFields,
  ICommonProps,
  IRequestMeeting,
  TAssignRoomType,
  TCreateOfferFormValues,
  TPreviewState,
} from './types';
import {TFileWithPreview} from 'view/venue/NW2VenueProfile/components/NW2Images/types';
import {
  getFilteredExtrasByType,
  getSeatingPlanByParticipants,
} from 'utils/venueUtils';
import {
  descriptionFieldRules,
  imagesFieldRules,
  nameFieldRules,
  spaceNameFieldRules,
} from 'utils/finalFormFieldRules';
import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {roomAmenitiesSet} from 'types/amenities';
import {EEventType, ISpaceData, ISpacesDataItem} from 'types/venue';
import {ERoomType} from 'types/dto/ERoomType.type';
import {EResourcesType, IExtrasOption} from 'types/dto/IExtras.type';
import useToggle from 'hooks/useToggle';
import {useSortedUnitAmenities} from 'view/venue/NW2VenueProfile/components/NMMSpaces/common/hooks/useSortedUnitAmenities';
import useRoomAmenities from 'view/venue/NW2VenueProfile/components/NMMSpaces/common/hooks/useRoomAmenities';
import {usePaidDurationOptions} from 'view/venue/NW2VenueProfile/components/AddSpace/hooks/usePaidDurationOptions';
import {useCreateSpace} from './hooks/useCreateSpace';
import {useUpdateExtras} from './hooks/useUpdateExtras';
import {useActions} from 'hooks/useActions';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {getCancellationPolicyText} from 'store/customer/apiActions';
import DateUtils from 'utils/dateUtils';
import {getCurrencySymbol} from 'utils/stringUtils';
import {infoColor, offsetDef} from 'constants/styleVars';
import {useUnitsExtras} from './hooks/useUnitsExtras';
import {StepBedroomsPrices} from './components/StepBedroomsPrices';
import {getBedroomsFromState, getCreateOfferPayload} from './helpers';
import {useInitialData} from './hooks/useInitialData';
import {OfferCreateLayout} from './components/OfferCreateLayout';

interface IProps extends ICommonProps {
  filesState: Record<string, TFileWithPreview[]>;
  handleResetImages: () => void;
}

export function OfferCreateAlternativeVenue({
  activeUnitId,
  activeForm,
  formState,
  handleClose,
  handleScrollToUnit,
  filesState,
  formStatus,
  handleSetFiles,
  handleResetImages,
  handleSetItemsToOffer,
  page,
  itemsToOffer,
  setActiveUnitId,
  setFormState,
  setPage,
  setItemsToOffer,
  setFormStatus,
  postEvents,
  preArrivals,
  requestDays,
  requestMeetings,
  requestUnits,
  prices,
  hasEquipment,
  hasCatering,
}: IProps) {
  const dispatch = useAppDispatch();

  const {createOffer} = useActions();

  const venueId: number = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.id`),
  );
  const venueFloors: number[] = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.location.floors`),
  );
  const currencyKey = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.currency`),
  );
  const currency = getCurrencySymbol(currencyKey);
  const allExtrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );
  const extrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );
  const requestId = useAppSelector(({offers}) => offers.offerDetails.id);

  // initializers
  const [isSubmitting, setSubmitting] = useState(false);
  const [isAddSeatingPlanShowed, toggleAddSeatingPlanShowed] = useToggle(false);
  const [previewState, setPreviewState] = useState<TPreviewState>({});

  const activePreview = previewState[activeUnitId] || {};

  const isExistedRoomType = activePreview.assignRoomType === 'existed';
  const isNewRoomType = activePreview.assignRoomType === 'new';

  const handleSetAssignRoomType = useCallback(
    (roomType: TAssignRoomType) => {
      setPreviewState((prev) => ({
        ...prev,
        [activeUnitId]: {...prev[activeUnitId], assignRoomType: roomType},
      }));
    },
    [activeUnitId],
  );

  const [editedSpace, setEditedSpace] = useState<
    ISpacesDataItem | Record<string, never>
  >({});

  const isMultiDay = requestUnits.length > 1;

  const firstMeetingRoomId = requestMeetings[0]?.id;
  const isFirstUnit = activeUnitId === firstMeetingRoomId;
  const isLastUnit = activeUnitId === requestUnits[requestUnits.length - 1]?.id;

  const requestedUnitDetails = requestUnits.find(
    ({id}) => id === activeUnitId,
  ) as unknown as IRequestMeeting;

  const isPreArrival =
    requestedUnitDetails?.eventType === EEventType.PRE_ARRIVAL;
  const isPostEvent = requestedUnitDetails?.eventType === EEventType.POST_EVENT;
  const isPreOrPostEvent = isPreArrival || isPostEvent;

  const requestedCapacity = requestedUnitDetails?.participants || 0;
  const seatingPlanName = getSeatingPlanByParticipants(requestedCapacity);
  const requestedSeatingPlan =
    requestedUnitDetails?.setupStyle || seatingPlanName;

  const handleSetEditedSpace = (space: ISpacesDataItem) => {
    setEditedSpace(space);
  };

  const paidDurationOptions = usePaidDurationOptions();

  // amenities
  const isAllAmenitiesEmpty =
    !activeForm[ECreateOfferFormFields.AMENITIES]?.length;

  const {roomAmenities} = useRoomAmenities(ERoomType.MEETING_ROOM);

  const amenitiesConditionsAndAccessibility = useSortedUnitAmenities({
    spaceAmenities: roomAmenities,
    items: roomAmenitiesSet,
    fieldName: ECreateOfferFormFields.AMENITIES,
    isTitleShowed: false,
    roomType: ERoomType.MEETING_ROOM,
  });

  useEffect(() => {
    if (isAllAmenitiesEmpty) {
      if (isExistedRoomType) {
        // hide amenities block if list empty for existed space
        setPreviewState((prev) => ({
          ...prev,
          [activeUnitId]: {...prev[activeUnitId], isAmenitiesHidden: true},
        }));
      }
    } else {
      if (activePreview.isAmenitiesHidden) {
        // show amenities block if it was hidden & then selected after back
        setPreviewState((prev) => ({
          ...prev,
          [activeUnitId]: {...prev[activeUnitId], isAmenitiesHidden: false},
        }));
      }
    }
  }, [
    activePreview.isAmenitiesHidden,
    activeUnitId,
    isAllAmenitiesEmpty,
    isExistedRoomType,
  ]);

  // floors
  useEffect(() => {
    if (isExistedRoomType) {
      setPreviewState((prev) => ({
        ...prev,
        [activeUnitId]: {...prev[activeUnitId], isFloorsInitialised: true},
      }));
    }
  }, [activeUnitId, isExistedRoomType]);

  // create space
  const [onCreateSpace] = useCreateSpace({
    venueId,
    requestedSeatingPlan,
    editedSpace: editedSpace as ISpacesDataItem,
    assignRoomType: activePreview.assignRoomType || '',
    roomAmenities,
  });

  // extras
  const [onUpdateExtras] = useUpdateExtras({venueId, extrasOption});

  const unitsExtras = useUnitsExtras({
    requestMeetings,
    extrasOption,
  });

  const {allEquipmentExtras, allCateringExtras, allInitialExtras} = unitsExtras;

  // bedrooms
  const activeUnitBedroomExtras = useMemo(
    () =>
      getFilteredExtrasByType(
        requestedUnitDetails?.bedrooms || [],
        EResourcesType.BEDROOM,
        extrasOption,
      ),
    [extrasOption, requestedUnitDetails?.bedrooms],
  );

  /**
   * reset space
   * uses for back button reasons, when we are navigating from already settled space to 1st step
   */
  const handleResetSpace = useCallback(() => {
    setFormState((prev) => ({...prev, [activeUnitId]: {}}));
    setPreviewState((prev) => ({...prev, [activeUnitId]: {}}));
    setEditedSpace({});
    setItemsToOffer((prev) =>
      prev.filter(({requestItemId}) => requestItemId !== activeUnitId),
    );
    handleResetImages();
  }, [activeUnitId, handleResetImages, setFormState, setItemsToOffer]);

  /**
   * uses in case when unit already saved due form filling & we are going back for update it
   * we are change this room type for update it instead of create new one
   */
  const handleChangeSpaceCreateToUpdate = useCallback(() => {
    setPreviewState((prev) => ({
      ...prev,
      [activeUnitId]: {...prev[activeUnitId], assignRoomType: 'wip'},
    }));

    const createdSpaceId = itemsToOffer.find(
      ({requestItemId}) => requestItemId === activeUnitId,
    )?.unitId;

    if (createdSpaceId) {
      setEditedSpace(
        (prev) =>
          ({
            ...prev,
            id: createdSpaceId,
          } as ISpacesDataItem),
      );
    }
  }, [activeUnitId, itemsToOffer]);

  // handle active steps
  const [activeStep, setActiveStep] = useState('');

  // go to configure next item
  const handleGoNextRequestedItem = useCallback(() => {
    if (isLastUnit) return;

    const unitIdx = requestUnits.findIndex(({id}) => id === activeUnitId);
    const nextUnitId = requestUnits[unitIdx + 1]?.id;

    if (nextUnitId) {
      setPage(0);
      setActiveUnitId(nextUnitId);
      handleScrollToUnit(nextUnitId);
    }
  }, [
    activeUnitId,
    handleScrollToUnit,
    isLastUnit,
    requestUnits,
    setActiveUnitId,
    setPage,
  ]);

  useEffect(() => {
    /**
     * redirect to next space selection page if not all days are filled
     */
    if (activeStep === SUPPLIER_TITLE) {
      if (!activeForm || !Object.values(activeForm).length) return;

      // get next item in the requestUnits
      handleGoNextRequestedItem();
    }
  }, [activeForm, activeStep, activeUnitId, handleGoNextRequestedItem]);

  /**
   * step titles
   * we handle the active step outside the form wizard by the title of this step
   */
  const SELECT_SPACE_TITLE =
    'What room would you like to assign for this meeting?';
  const SPACE_NAME_TITLE = 'What is this meeting room called?';
  const CAPACITY_TITLE = `What is the maximum capacity for ${activeForm.name}?`;
  const IMAGES_TITLE = `What does ${activeForm.name} look like?`;
  const FLOOR_TITLE = `Which floor is ${activeForm.name} on?`;
  const PRICES_TITLE = `How much do you want to charge for ${activeForm.name}?`;
  const DIMENSIONS_TITLE = `What are the dimensions of ${activeForm.name}?`;
  const AMENITIES_STEP_TITLE = `What amenities does ${activeForm.name} have?`;
  const DESCRIPTION_TITLE = `Tell the booker more about ${activeForm.name}.`;
  const EQUIPMENT_PRICES_TITLE = 'What’s the price of the following equipment?';
  const CATERING_PRICES_TITLE = 'What’s the price of the following services?';
  const BEDROOMS_PRICES_TITLE =
    'How much do you want to charge per room for accommodation?';
  const LAST_STEP_TITLE =
    'Almost there! Just review terms & conditions and submit your offer';
  const SUPPLIER_TITLE = 'Let your customer know who created this offer';

  // NAVIGATION
  const handlePrevUnitSelection = useCallback(() => {
    if (requestUnits[0]?.id === activeUnitId) {
      // go to start page if it 1st unit selection
      setFormStatus('');
    }

    const unitIdx = requestUnits.findIndex(({id}) => id === activeUnitId);
    const prevUnit = requestUnits[unitIdx - 1];

    if (prevUnit) {
      const prevUnitId = prevUnit.id;

      if (prevUnit.eventType === EEventType.DAY) {
        setFormState((prev) => ({...prev, [prevUnitId]: {}}));
        setPreviewState((prev) => ({...prev, [prevUnitId]: {}}));
        setItemsToOffer((prev) =>
          prev.filter(({requestItemId}) => requestItemId !== prevUnitId),
        );
      }

      setFormState((prev) => ({...prev, [activeUnitId]: {}}));
      setPreviewState((prev) => ({...prev, [activeUnitId]: {}}));
      setItemsToOffer((prev) =>
        prev.filter(({requestItemId}) => requestItemId !== activeUnitId),
      );

      setActiveUnitId(prevUnitId);
      handleScrollToUnit(prevUnitId);
    }
  }, [
    activeUnitId,
    handleScrollToUnit,
    requestUnits,
    setActiveUnitId,
    setFormState,
    setFormStatus,
    setItemsToOffer,
  ]);

  const {removeUnitSubtotalPrice} = prices;

  const handleResetBedroomItem = useCallback(() => {
    removeUnitSubtotalPrice(activeUnitId);
    setPreviewState((prev) => ({...prev, [activeUnitId]: {}}));
    setFormState((prev) => ({...prev, [activeUnitId]: {}}));
    setItemsToOffer((prev) =>
      prev.filter(({requestItemId}) => requestItemId !== activeUnitId),
    );
  }, [activeUnitId, removeUnitSubtotalPrice, setFormState, setItemsToOffer]);

  const handlePrevUnitSelectionFromBedrooms = useCallback(() => {
    if (!isPreOrPostEvent) {
      if (page === 1) {
        handleResetBedroomItem();
        handleScrollToUnit(activeUnitId);
      }

      // space bedrooms reset
      // @ts-ignore
      setFormState((prev) => ({
        ...prev,
        [activeUnitId]: {...prev[activeUnitId], [activeUnitId]: {}},
      }));
      setPreviewState((prev) => ({
        ...prev,
        [activeUnitId]: {
          ...prev[activeUnitId],
          isBedroomsInitialised: false,
        },
      }));
      return;
    }

    handleResetBedroomItem();
    handlePrevUnitSelection();
  }, [
    activeUnitId,
    handlePrevUnitSelection,
    handleResetBedroomItem,
    handleScrollToUnit,
    isPreOrPostEvent,
    page,
    setFormState,
  ]);
  // NAVIGATION END

  /**
   * check existed item availability for offer setup by the time range overlapping
   * if it's already added to the current day
   */
  const checkExistedItemAvailability = useCallback(
    (unitId: number): boolean => {
      if (!isMultiDay) return false;

      // check if unit already selected for same checkIn date as activeUnit
      const alreadySelectedUnit = itemsToOffer.find(
        (item) =>
          item.unitId === unitId &&
          DateUtils.isSameDay(
            requestUnits.find(({id}) => id === item.requestItemId)?.checkIn,
            requestedUnitDetails?.checkIn,
          ),
      );

      if (alreadySelectedUnit) {
        const alreadySelectedUnitItemId = alreadySelectedUnit.requestItemId;

        const requestItem = requestUnits.find(
          ({id}) => id === alreadySelectedUnitItemId,
        );

        if (requestItem) {
          const activeUnitItem = requestUnits.find(
            ({id}) => id === activeUnitId,
          );

          if (activeUnitItem) {
            const requestedItemCheckIn = DateUtils.getDateFromISO(
              requestItem.checkIn,
            );
            const requestedItemCheckOut = DateUtils.getDateFromISO(
              requestItem.checkOut,
            );
            const activeUnitItemCheckIn = DateUtils.getDateFromISO(
              activeUnitItem.checkIn,
            );
            const activeUnitItemCheckOut = DateUtils.getDateFromISO(
              activeUnitItem.checkOut,
            );

            /**
             * check time range overlapping
             * https://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
             * ((StartA > StartB) ? StartA : StartB) < ((EndA < EndB) ? EndA : EndB)
             */
            return (
              (requestedItemCheckIn > activeUnitItemCheckIn
                ? requestedItemCheckIn
                : activeUnitItemCheckIn) <
              (requestedItemCheckOut < activeUnitItemCheckOut
                ? requestedItemCheckOut
                : activeUnitItemCheckOut)
            );
          }
        }
      }

      return false;
    },
    [
      activeUnitId,
      isMultiDay,
      itemsToOffer,
      requestUnits,
      requestedUnitDetails?.checkIn,
    ],
  );

  // init units
  const {initialState, handleSetInitialState, handleInitUnit} = useInitialData({
    handleSetFiles,
    handleSetEditedSpace,
    allInitialExtras,
    paidDurationOptions,
    requestedSeatingPlan,
  });

  const onSubmit = useCallback(
    async (values: TCreateOfferFormValues) => {
      setSubmitting(true);

      const payload = getCreateOfferPayload({
        accommodationId: venueId,
        creatorName: values[ECreateOfferFormFields.SUPPLIER] as string,
        requestId,
        itemsToOffer,
        requestDays,
      });

      // create offer
      if (requestedUnitDetails?.id) {
        createOffer(
          payload,
          () => {
            setSubmitting(false);
            setFormStatus('finished');
            setPage(0);
          },
          () => {
            setSubmitting(false);
          },
        );
      }
    },
    [
      createOffer,
      itemsToOffer,
      requestDays,
      requestId,
      requestedUnitDetails?.id,
      setFormStatus,
      setPage,
      venueId,
    ],
  );

  const stepPages = [
    // 1
    {
      title: SELECT_SPACE_TITLE,
      fieldName: ECreateOfferFormFields.TYPE,
      titleWidth: 350,
      isTitleCentered: true,
      isButtonNextHidden: true,
      buttonsSize: 'medium',
      isHidden: isPreOrPostEvent,
      onBackClick: handlePrevUnitSelection,
      content: (
        <PageContent width={324}>
          <StepSpaceSelection
            paidDurationOptions={paidDurationOptions}
            requestedCapacity={requestedCapacity}
            requestedSeatingPlan={requestedSeatingPlan}
            defaultVenueFloors={venueFloors?.[0] || 0}
            isFirstUnit={isFirstUnit}
            handleSetAssignRoomType={handleSetAssignRoomType}
            handleSetInitialState={handleSetInitialState}
            handleSetItemsToOffer={handleSetItemsToOffer}
            checkExistedItemAvailability={checkExistedItemAvailability}
            handleInitUnit={handleInitUnit}
            initialExtras={allInitialExtras}
          />
        </PageContent>
      ),
    },

    // 2
    {
      title: SPACE_NAME_TITLE,
      fieldName: ECreateOfferFormFields.NAME,
      isHidden: isExistedRoomType || isPreOrPostEvent,
      onBackClick: handleResetSpace,
      content: (
        <PageContent width={284}>
          <NW2FormItemInput
            name={ECreateOfferFormFields.NAME}
            type='text'
            label='Space name'
            placeholder='Space name'
            rules={spaceNameFieldRules}
            showAllValidationErrors
          />
        </PageContent>
      ),
    },

    // 3
    {
      title: IMAGES_TITLE,
      fieldName: ECreateOfferFormFields.IMAGES,
      isHidden: isExistedRoomType || isPreOrPostEvent,
      titleWidth: 440,
      isTitleCentered: true,
      content: (
        <PageContent width={444}>
          <StepHint
            title='Pro tip!'
            text={`High resolution images are key! Great pictures can be the
               difference between bookers choosing your venue over another.`}
          />

          <Field
            name={ECreateOfferFormFields.IMAGES}
            validate={imagesFieldRules}
          >
            {({input, meta}) => {
              const hasError =
                meta.error && (meta.touched || meta.submitFailed);

              return (
                <>
                  <AddImages
                    fieldName={ECreateOfferFormFields.IMAGES}
                    fieldCoverName={ECreateOfferFormFields.COVER_IMAGE}
                    filesState={filesState[activeUnitId] || []}
                    handleSetFiles={handleSetFiles}
                    onChange={input.onChange}
                    itemWidth='47%'
                  />

                  {hasError && (
                    <PageImagesUploadInputWrapper>
                      <ErrorMessage
                        hasError={hasError}
                        errorData={meta.error}
                        showAllValidationErrors
                      />
                    </PageImagesUploadInputWrapper>
                  )}
                </>
              );
            }}
          </Field>
        </PageContent>
      ),
    },

    // 4
    {
      title: CAPACITY_TITLE,
      fieldName: ECreateOfferFormFields.CAPACITY,
      isHidden: isExistedRoomType || isPreOrPostEvent,
      titleWidth: 338,
      isTitleCentered: true,
      content: (
        <PageContent width={324}>
          <StepCapacities
            savedCapacities={
              activeForm[ECreateOfferFormFields.UPDATED_CAPACITIES]
            }
            requestedCapacity={requestedCapacity}
            requestedSeatingPlan={requestedSeatingPlan}
            isAddSeatingPlanShowed={isAddSeatingPlanShowed}
            toggleAddSeatingPlanShowed={toggleAddSeatingPlanShowed}
          />
        </PageContent>
      ),
    },

    // 5
    {
      title: FLOOR_TITLE,
      fieldName: ECreateOfferFormFields.FLOOR,
      titleWidth: 410,
      isTitleCentered: true,
      isHidden: isExistedRoomType || isPreOrPostEvent,
      content: (
        <PageContent width={214}>
          <AddFloor
            fieldName={ECreateOfferFormFields.FLOOR}
            venueFloors={venueFloors}
            spaceFloor={activeForm[ECreateOfferFormFields.FLOOR]}
          />
        </PageContent>
      ),
    },

    // 6
    {
      title: DIMENSIONS_TITLE,
      fieldName: ECreateOfferFormFields.DIMENSIONS,
      titleWidth: 274,
      isTitleCentered: true,
      isHidden: isExistedRoomType || isPreOrPostEvent,
      content: (
        <PageContent width={312}>
          <AddDimensions fieldName={ECreateOfferFormFields.DIMENSIONS} />
        </PageContent>
      ),
    },

    // 7
    {
      title: AMENITIES_STEP_TITLE,
      fieldName: ECreateOfferFormFields.AMENITIES,
      titleWidth: 512,
      isTitleCentered: true,
      isHidden: isExistedRoomType || isPreOrPostEvent,
      onNextClick: () => {
        setPreviewState((prev) => ({
          ...prev,
          [activeUnitId]: {
            ...prev[activeUnitId],
            isAmenitiesFilled: true,
          },
        }));
      },
      content: (
        <PageContent width={442}>
          <AmenitiesContainer>
            {amenitiesConditionsAndAccessibility}
          </AmenitiesContainer>
        </PageContent>
      ),
    },

    // 8
    {
      title: DESCRIPTION_TITLE,
      fieldName: ECreateOfferFormFields.DESCRIPTION,
      titleWidth: 502,
      isTitleCentered: true,
      isHidden: isExistedRoomType || isPreOrPostEvent,
      content: (
        <PageContent width={442}>
          <StepHint
            title='Pro tip!'
            text={`Let the booker know what they should expect when they arrive.
               Don’t worry - you only have to do this once! Everything will be saved.`}
            isCentered
          />

          <NW2FormTextarea
            name={ECreateOfferFormFields.DESCRIPTION}
            label='Space description'
            placeholder='Space description'
            rules={descriptionFieldRules}
            minHeight={135}
            showAllValidationErrors
          />
        </PageContent>
      ),
    },

    // 9
    {
      title: PRICES_TITLE,
      fieldName: ECreateOfferFormFields.PAID_DURATION,
      titleWidth: 362,
      isTitleCentered: true,
      isHidden: isExistedRoomType || isPreOrPostEvent,
      onNextClick: async () => {
        let createdSpace: ISpacesDataItem | ISpaceData | undefined;

        if (!isExistedRoomType) {
          // create or update for new or wip space
          createdSpace = await onCreateSpace(activeForm);

          if (!isFirstUnit) {
            handleGoNextRequestedItem();
          }
        }

        const unitId = createdSpace?.id || editedSpace?.id;

        if (unitId) {
          handleSetItemsToOffer({unitId, eventType: EEventType.DAY});
        }
      },
      content: (
        <PageContent width={328}>
          <StepHint
            title='Why we ask this?'
            icon='QUESTION_CIRCLED'
            iconColor={infoColor}
            text={`We will save your pricing information for future requests.
               You can always adjust it later by editing the space details.`}
            isCentered
          />

          <PricesContainer>
            <AddRoomPrice
              paidDurationOptions={paidDurationOptions}
              priceHourFieldName={ECreateOfferFormFields.PRICE_HOUR}
              priceHalfDayFieldName={ECreateOfferFormFields.PRICE_HALF_DAY}
              priceDayFieldName={ECreateOfferFormFields.PRICE_DAY}
              paidDurationFieldName={ECreateOfferFormFields.PAID_DURATION}
            />
          </PricesContainer>
        </PageContent>
      ),
    },

    // 10
    {
      title: EQUIPMENT_PRICES_TITLE,
      subTitle: `(price per item/day in ${currency})`,
      fieldName: ECreateOfferFormFields.PRICES_EQUIPMENT,
      isHidden: !allEquipmentExtras.length || !isFirstUnit || isPreOrPostEvent,
      onNextClick: () => {
        onUpdateExtras(activeForm[ECreateOfferFormFields.PRICES_EQUIPMENT]);
      },
      onBackClick: () => {
        if (hasEquipment) {
          if (isExistedRoomType) {
            handleResetSpace();
          }

          if (isNewRoomType) {
            handleChangeSpaceCreateToUpdate();
          }
        }
      },
      content: (
        <PageContent width={346}>
          <StepHint
            title='Pro tip!'
            text={`If a third party is providing these services, please consider
               that HRS' 10% commission is calculated from the total price.`}
            isCentered={false}
          />

          <StepExtrasPrices
            fieldName={ECreateOfferFormFields.PRICES_EQUIPMENT}
            extras={allEquipmentExtras}
          />
        </PageContent>
      ),
    },

    // 11
    {
      title: CATERING_PRICES_TITLE,
      subTitle: `(price per item/day in ${currency})`,
      fieldName: ECreateOfferFormFields.PRICES_FOOD_AND_BEVERAGE,
      isHidden: !allCateringExtras.length || !isFirstUnit || isPreOrPostEvent,
      onNextClick: () => {
        onUpdateExtras(
          activeForm[ECreateOfferFormFields.PRICES_FOOD_AND_BEVERAGE],
        );
      },
      onBackClick: () => {
        if (!hasEquipment) {
          if (isExistedRoomType) {
            handleResetSpace();
          }

          if (isNewRoomType) {
            handleChangeSpaceCreateToUpdate();
          }
        }
      },
      content: (
        <PageContent width={368}>
          <StepHint
            title='Pro tip!'
            text={`If a third party is providing these services, please consider
               that HRS' 10% commission is calculated from the total price.`}
            isCentered={false}
          />

          <StepExtrasPrices
            fieldName={ECreateOfferFormFields.PRICES_FOOD_AND_BEVERAGE}
            extras={allCateringExtras}
          />
        </PageContent>
      ),
    },

    {
      title: BEDROOMS_PRICES_TITLE,
      subTitle: DateUtils.getFormattedDayMonthTime({
        date: requestedUnitDetails?.checkIn,
        showDateOnly: true,
      }),
      fieldName: ECreateOfferFormFields.PRICES_BEDROOMS,
      titleWidth: 422,
      isTitleCentered: true,
      isHidden: !activeUnitBedroomExtras.length,
      stepTotalCount: isPreOrPostEvent && !isLastUnit && 1,
      onNextClick: () => {
        // set bedroom items to payload
        handleSetItemsToOffer({
          bedrooms: Object.entries(
            getBedroomsFromState(formState, activeUnitId) || {},
          ).map(([code, price]) => ({
            extraId: allExtrasOption.find((item) => item.code === code)
              ?.id as number,
            price: {
              value: Number(price),
            },
          })),
        });
      },
      onBackClick: handlePrevUnitSelectionFromBedrooms,
      content: (
        <PageContent width={346}>
          <StepHint
            title='Pro tip!'
            text={`The accommodation price will be specific to this offer
             and will not be applied to any future requests.`}
            isCentered={false}
          />

          <StepBedroomsPrices
            activeUnitId={activeUnitId}
            fieldName={`${activeUnitId}.${ECreateOfferFormFields.PRICES_BEDROOMS}`}
            bedrooms={activeUnitBedroomExtras}
            isInitialized={previewState[activeUnitId]?.isBedroomsInitialised}
            formStateValue={getBedroomsFromState(formState, activeUnitId)}
          />
        </PageContent>
      ),
    },

    // 12
    {
      title: SUPPLIER_TITLE,
      fieldName: ECreateOfferFormFields.SUPPLIER,
      onBackClick: () => {
        if (isPreOrPostEvent) {
          handleResetBedroomItem();

          return;
        } else {
          if (isLastUnit) {
            setTimeout(() => {
              if (page !== 0) {
                // move to 1st step if steps counter was changed
                setPage(0);
              }
            }, 0);
          }
        }

        // extras checking needs only for 1st day
        if ((isFirstUnit && !hasEquipment && !hasCatering) || !isFirstUnit) {
          if (isExistedRoomType) {
            handleResetSpace();
          }

          if (isNewRoomType) {
            handleChangeSpaceCreateToUpdate();
          }
        }
      },
      content: (
        <PageContent width={284}>
          <NW2FormItemInput
            name={ECreateOfferFormFields.SUPPLIER}
            type='text'
            label='Your name'
            placeholder='Your name'
            rules={nameFieldRules}
            showAllValidationErrors
          />
        </PageContent>
      ),
    },

    // 13
    {
      title: LAST_STEP_TITLE,
      titleWidth: 446,
      isTitleCentered: true,
      titlePaddingTop: offsetDef,
      isHidden: isPreOrPostEvent && !isLastUnit,
      fieldName: '',
      content: <LastStep />,
    },
  ];

  const isFormInitialised = Object.keys(activeForm).length > 0;
  const amenitiesLen = editedSpace.amenities?.length;
  const maxCapacity =
    activeForm[ECreateOfferFormFields.CAPACITY]?.[requestedSeatingPlan]
      ?.capacity || requestedCapacity;

  useEffect(() => {
    const showStep = (stepName: string, val: boolean) => {
      setPreviewState((prev) => ({
        ...prev,
        [activeUnitId]: {...prev[activeUnitId], [stepName]: val},
      }));
    };

    if (activeStep === CAPACITY_TITLE) {
      showStep('isCapacityInitialised', true);
    }

    if (activeStep === DESCRIPTION_TITLE) {
      showStep('isAmenitiesHidden', isAllAmenitiesEmpty);
    }

    if (isFirstUnit) {
      if (activeStep === EQUIPMENT_PRICES_TITLE) {
        showStep('isEquipmentInitialised', true);
      }

      if (activeStep === CATERING_PRICES_TITLE) {
        showStep('isCateringInitialised', true);
      }
    }

    if (activeStep === BEDROOMS_PRICES_TITLE) {
      showStep('isBedroomsInitialised', true);
    }

    if (!isExistedRoomType) {
      if (activeStep === FLOOR_TITLE) {
        showStep('isFloorsInitialised', true);
      }

      if (activeStep === DIMENSIONS_TITLE) {
        showStep('isDimensionsInitialised', true);
      }
    }

    if (activeStep === LAST_STEP_TITLE) {
      dispatch(
        getCancellationPolicyText(Number(maxCapacity), false, isMultiDay),
      );
    }

    if (isExistedRoomType && !isFormInitialised) {
      setPreviewState((prev) => ({
        ...prev,
        [activeUnitId]: {
          ...prev[activeUnitId],
          isAmenitiesHidden: !amenitiesLen,
          isAmenitiesFilled: true,
          isCapacityInitialised: true,
          isFloorsInitialised: true,
          isDimensionsInitialised: true,
        },
      }));
    }
  }, [
    CAPACITY_TITLE,
    DESCRIPTION_TITLE,
    DIMENSIONS_TITLE,
    FLOOR_TITLE,
    activeStep,
    activeUnitId,
    amenitiesLen,
    dispatch,
    isAllAmenitiesEmpty,
    isExistedRoomType,
    isFirstUnit,
    isFormInitialised,
    isMultiDay,
    maxCapacity,
  ]);

  return (
    <OfferCreateLayout
      activeUnitId={activeUnitId}
      isSubmitting={isSubmitting}
      requestUnits={requestUnits}
      prices={prices}
      page={page}
      setPage={setPage}
      handleClose={handleClose}
      initialState={initialState}
      formState={formState}
      formStatus={formStatus}
      onSubmit={onSubmit}
      stepPages={stepPages}
      setFormState={setFormState}
      setFormStatus={setFormStatus}
      setActiveUnitId={setActiveUnitId}
      setActiveStep={setActiveStep}
      previewState={previewState}
      requestDays={requestDays}
      preArrivals={preArrivals}
      postEvents={postEvents}
      hasEquipment={hasEquipment}
      hasCatering={hasCatering}
      roomAmenities={roomAmenities}
      currency={currency}
      firstMeetingRoomId={firstMeetingRoomId}
      unitsExtras={unitsExtras}
      isAlternative
    />
  );
}
