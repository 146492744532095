import _set from 'lodash/set';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';

import {BookingActionTypes} from './types';
import {
  GET_BOOKINGS_FAILURE,
  GET_BOOKINGS_SUCCESS,
  IS_BOOKINGS_LOADING,
  SET_BOOKINGS_TYPE,
  SET_WORKDESK_ORDER_READ_STATUS,
} from './constants';
import {EBookingFilterType, IBookingType} from 'types/dto/IBookingType.type';
import {IBooking} from 'types/dto/IBooking.types';
import {EOrderShowStatus} from 'types/bookingOrders';

interface IBookingData {
  isLoading: boolean;
  pageItems: any[];
  pagination: Record<string, string | number>;
  data: {
    [EBookingFilterType.WORK_SPACE]?: IBooking[];
    [EBookingFilterType.MEETING_ROOM]?: IBooking[];
    [EBookingFilterType.RFP_PENDING]?: IBooking[];
    [EBookingFilterType.RFP_CANCELLED]?: IBooking[];
    [EBookingFilterType.RFP_EXPIRED]?: IBooking[];
    [EBookingFilterType.RFP_EXPIRED]?: IBooking[];
  };
}

interface IBookingInitialState {
  [IBookingType.UPCOMING_BOOKINGS]: IBookingData;
  [IBookingType.TOMORROW_OVERVIEW]: IBookingData;
  [IBookingType.BOOKINGS_HISTORY]: IBookingData;
  [IBookingType.ARRIVING_TODAY]: IBookingData;
  [IBookingType.REQUESTS]: IBookingData;
  bookingsType: string;
  loading: boolean;
}

const bookingInitialState = {
  isLoading: false,
  pageItems: [],
  pagination: {},
};

const bookingDataInitialState = {
  [EBookingFilterType.WORK_SPACE]: [],
  [EBookingFilterType.MEETING_ROOM]: [],
};

const initialState: IBookingInitialState = {
  [IBookingType.UPCOMING_BOOKINGS]: {
    ...bookingInitialState,
    data: {...bookingDataInitialState},
  },
  [IBookingType.TOMORROW_OVERVIEW]: {
    ...bookingInitialState,
    data: {...bookingDataInitialState},
  },
  [IBookingType.BOOKINGS_HISTORY]: {
    ...bookingInitialState,
    data: {...bookingDataInitialState},
  },
  [IBookingType.ARRIVING_TODAY]: {
    ...bookingInitialState,
    data: {...bookingDataInitialState},
  },
  [IBookingType.REQUESTS]: {
    ...bookingInitialState,
    data: {
      [EBookingFilterType.RFP_PENDING]: [],
      [EBookingFilterType.RFP_CANCELLED]: [],
      [EBookingFilterType.RFP_EXPIRED]: [],
      [EBookingFilterType.RFP_EXPIRED]: [],
    },
  },
  bookingsType: IBookingType.ARRIVING_TODAY,
  loading: false,
};

// BOOKINGS REDUCER
const bookingsReducer = (state = initialState, action: BookingActionTypes) => {
  switch (action.type) {
    case GET_BOOKINGS_FAILURE: {
      const newState = _cloneDeep(state);
      _set(
        newState,
        `[${action.payload.bookingsType}].isLoading`,
        action.payload.state,
      );
      return {...newState, error: action.payload.error};
    }

    case GET_BOOKINGS_SUCCESS: {
      const {bookingType, payload} = action;
      const newState = _cloneDeep(state);
      const currentData = `${bookingType}.data.${
        EBookingFilterType.WORK_SPACE
      }${payload.tableDataKey ? `.${payload.tableDataKey}.data` : ''}`;
      const currentTablePath = `${
        payload.tableDataKey
          ? `${bookingType}.data.${EBookingFilterType.WORK_SPACE}.${payload.tableDataKey}`
          : `${bookingType}`
      }`;
      _set(newState, `${currentTablePath}.isLoading`, false);
      _set(newState, currentData, payload.data);
      _set(newState, `${currentTablePath}.pagination`, payload.pagination);
      return newState;
    }

    case IS_BOOKINGS_LOADING: {
      const {tableDataKey, bookingsType} = action.payload;
      const newState = _cloneDeep(state);
      const currentTablePath = `${
        tableDataKey
          ? `${bookingsType}.data.${EBookingFilterType.WORK_SPACE}.${tableDataKey}`
          : `${bookingsType}`
      }`;
      _set(newState, `${currentTablePath}.isLoading`, action.payload.state);
      return newState;
    }

    case SET_BOOKINGS_TYPE: {
      return {
        ...state,
        bookingsType: action.payload,
      };
    }

    case SET_WORKDESK_ORDER_READ_STATUS: {
      const bookingsType = state.bookingsType;
      const currentTablePath = `${bookingsType}.data.${EBookingFilterType.WORK_SPACE}`;
      const currentData = _get(state, currentTablePath);
      const updatedData = currentData.map((item: any) => ({
        ...item,
        showStatus:
          item.orderId === action.payload
            ? EOrderShowStatus.READ
            : item.showStatus,
      }));
      const newState = _cloneDeep(state);
      _set(newState, `${currentTablePath}`, updatedData);
      return newState;
    }

    default: {
      return state;
    }
  }
};

export default bookingsReducer;
