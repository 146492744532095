import React from 'react';
import styled from 'styled-components';

import Image from 'view/components/Image';
import NW2Button from 'view/components/NW2Button/NW2Button';
import GetInTouchImg from 'img/get_in-touch_section.jpg';

import {
  NW2ContainerWithOffset,
  NW2ContainerRow,
} from 'view/mainLanding/MainLanding.styles';
import {
  lgBp,
  mdBp,
  NW2FontSizeH1Lg,
  NW2FontSizeH4Lg,
  offset64,
  offsetDef,
  offsetXXLg,
  offsetXXXLg,
  smBp,
} from 'constants/styleVars';
import {StyledH1, StyledH3} from 'view/components/Typography';
import {StyledSpan} from 'view/components/Typography/Typography';
import {ISSUES_NEWWORK_EMAIL} from 'constants/app';

const GetInTouchTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const WrappedStyledH1 = styled(StyledH1)`
  font-size: ${NW2FontSizeH1Lg};
  margin-top: ${offsetDef};

  @media (min-width: ${smBp}px) {
    margin-top: 0;
  }

  @media (min-width: ${smBp}px) and (max-width: ${lgBp - 1}px) {
    font-size: 66px;
  }
`;
const WrappedStyledH3 = styled(StyledH3)`
  margin-top: ${offsetXXLg};
  font-size: ${NW2FontSizeH4Lg};
  line-height: 1;

  @media (min-width: ${smBp}px) and (max-width: ${lgBp - 1}px) {
    margin-top: ${offsetXXXLg};
    font-size: 35px;
  }
  @media (min-width: ${lgBp}px) {
    margin-top: ${offset64};
  }
`;
const StyledDefaultButton = styled.div`
  margin-top: ${offset64};
  width: 100%;

  @media (min-width: ${mdBp}px) {
    margin-top: auto;
  }
`;

const Column2span = styled.div`
  @media (min-width: ${mdBp}px) {
    grid-column: span 2;
  }
`;

function GetInTouchSection() {
  const handleGetInTouchClick = () => {
    const subject = 'Get in Touch with HRS';
    window.open(
      `mailto:${ISSUES_NEWWORK_EMAIL}?subject=${encodeURIComponent(subject)}`,
      '_blank',
      'noreferrer noopener',
    );
  };

  return (
    <NW2ContainerWithOffset>
      <NW2ContainerRow>
        <Column2span>
          <Image isExpandToLeft height='400px' src={GetInTouchImg} />
        </Column2span>
        <GetInTouchTextBlock>
          <WrappedStyledH1 black>
            <StyledSpan NW2Gray600 inline>
              New
            </StyledSpan>
            Work
          </WrappedStyledH1>
          <WrappedStyledH3 NW2Gray800>LET’S TALK WORK.</WrappedStyledH3>
          <StyledDefaultButton>
            <NW2Button buttonType='primary' onClick={handleGetInTouchClick}>
              get in touch
            </NW2Button>
          </StyledDefaultButton>
        </GetInTouchTextBlock>
      </NW2ContainerRow>
    </NW2ContainerWithOffset>
  );
}

export default GetInTouchSection;
