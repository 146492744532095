import styled from 'styled-components';

import {
  offsetXSm,
  fontSizeSm,
  fontSizeXXLg,
  fontSizeXSm,
  fontWeightBold,
  NW2Gray100Color,
  NW2Gray300Color,
  NW2Gray900Color,
  NW2Secondary,
  offsetXXXSm,
} from 'constants/styleVars';

export const StyledInput = styled.input`
  width: 42px;
  border: none;
  outline: none;
  cursor: default;
  text-align: center;
  padding: 0 ${offsetXXXSm};
  font-size: ${fontSizeSm};
  font-weight: ${fontWeightBold};
  background-color: transparent;

  ::selection {
    color: inherit;
    background: transparent;
  }
`;

export const InputWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const AddButton = styled.button<{disabled?: boolean}>`
  border: none;
  width: 84px;
  height: 36px;
  cursor: pointer;
  border-radius: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${NW2Gray900Color};
  font-size: ${fontSizeXSm};
  font-weight: ${fontWeightBold};
  text-transform: uppercase;
  background-color: ${NW2Gray100Color};
  transition: color 0.2s, background-color 0.2s;

  &:hover {
    color: ${NW2Secondary};
    background-color: ${NW2Gray900Color};
  }

  & * {
    cursor: pointer;
  }

  ${({disabled}) => {
    if (disabled) {
      return `
      cursor: default;
      pointer-events: none;
      color: ${NW2Gray300Color};
      & * {
        cursor: default;
      }
      `;
    }
  }}
`;

export const Label = styled.span`
  margin-left: ${offsetXSm};

  ::selection {
    color: inherit;
    background: transparent;
  }
`;

export const ChangeButton = styled.button<{disabled?: boolean}>`
  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${NW2Gray900Color};
  font-size: ${fontSizeXXLg};
  background-color: ${NW2Gray100Color};
  transition: color 0.2s, background-color 0.2s;

  &:hover {
    color: ${NW2Secondary};
    background-color: ${NW2Gray900Color};
  }

  & * {
    cursor: pointer;
  }

  ${({disabled}) => {
    if (disabled) {
      return `
      cursor: default;
      pointer-events: none;
      color: ${NW2Gray300Color};
      & * {
        cursor: default;
      }
      `;
    }
  }}
`;
