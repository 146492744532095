import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {DEFAULT_TIME_DATA} from 'view/components/NW2Datepicker/utils';
import DateUtils from 'utils/dateUtils';
import {INITIAL_RADIUS} from 'constants/app';
import {checkInTime, checkOutTime} from 'constants/defaultOperationalTimes';
import {ERoomType} from 'types/dto/ERoomType.type';
import {TInitialState} from './types';
import {ITimeData} from 'types/dto/ISearch.types';
import {TInitialLocation} from 'types/app';
import {TDay, TSearchVenuesDay, TVenuesQuerySearchData} from 'types/search';
import {ERoomSchemaNames} from 'types/venue';
import LocalStorageService from 'infra/common/localStorage.service';

export const INITIAL_SEATING_STYLE = ERoomSchemaNames.BLOCK;

export const INITIAL_SEARCH_CRITERIA = {
  searchString: '',
  location: false,
  startDate: checkInTime,
  endDate: checkOutTime,
  page: 0,
  offerPage: 0,
  cost: 0,
  unitTypeDescription: '',
  latitude: '',
  longitude: '',
  roomType: ERoomType.MEETING_ROOM,
  meetingRoomCapacity: 1,
  radius: INITIAL_RADIUS,
  multiRooms: false,
  seatingStyle: INITIAL_SEATING_STYLE,
};

const initialState: TInitialState = {
  searchCriteria: INITIAL_SEARCH_CRITERIA,
  searchPredictionLoading: '',
  initialTimeData: DEFAULT_TIME_DATA,
  summaryData: [],
  meetingRequestData: [],
  multiSearchPayload: [],
  searchFocusedInput: '',
  initialLocation: {
    pathname: '',
    search: '',
  },
  storedSearchCriteria: {} as TVenuesQuerySearchData,
  isSearchListLoaded: false,
  isFastSearchEnabled: false,
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchCriteria(
      state,
      action: PayloadAction<Partial<TVenuesQuerySearchData>>,
    ) {
      state.searchCriteria = {...state.searchCriteria, ...action.payload};
    },
    setSearchText(state, action: PayloadAction<string>) {
      state.searchCriteria.searchString = action.payload;
    },
    setSearchCriteriaRoomType(state, action: PayloadAction<ERoomType>) {
      state.searchCriteria.roomType = action.payload;
    },
    setSearchCriteriaMeetingRoomCapacity(state, action: PayloadAction<number>) {
      state.searchCriteria.meetingRoomCapacity = action.payload;
    },
    setSearchCriteriaSeatingStyle(
      state,
      action: PayloadAction<ERoomSchemaNames>,
    ) {
      state.searchCriteria.seatingStyle = action.payload;
    },
    setLatitudeLongitude(
      state,
      action: PayloadAction<{
        latitude: string | null | undefined;
        longitude: string | null | undefined;
      }>,
    ) {
      state.searchCriteria.latitude = action.payload.latitude || '';
      state.searchCriteria.longitude = action.payload.longitude || '';
    },
    setSearchCriteriaVisibleRadius(state, action: PayloadAction<number>) {
      state.searchCriteria.radius = action.payload;
    },
    setPageForVenuesSearch(state, action: PayloadAction<number>) {
      state.searchCriteria.page = action.payload;
    },
    setStartDateForVenuesSearch(state, action: PayloadAction<string>) {
      state.searchCriteria.startDate = DateUtils.normalizeDateToBackendFormat(
        action.payload,
      );
    },
    setEndDateForVenuesSearch(state, action: PayloadAction<string>) {
      state.searchCriteria.endDate = DateUtils.normalizeDateToBackendFormat(
        action.payload,
      );
    },
    searchPredictionLoading(state, action: PayloadAction<string>) {
      state.searchPredictionLoading = action.payload;
    },
    clearSearchCriteria(state) {
      state.searchCriteria = INITIAL_SEARCH_CRITERIA;
    },
    setMeetingRequestData(state, action: PayloadAction<TDay[]>) {
      state.meetingRequestData = action.payload;
    },
    setMultiSearchPayload(state, action: PayloadAction<TSearchVenuesDay[]>) {
      state.multiSearchPayload = action.payload;
    },
    setMultiSearchTimeData(state, action: PayloadAction<ITimeData[]>) {
      state.initialTimeData = action.payload;
    },
    clearMultiSearchData(state) {
      state.meetingRequestData = [];
      state.multiSearchPayload = [];
      state.initialTimeData = DEFAULT_TIME_DATA;

      LocalStorageService.removeItemByKey('multiSearchData');
      LocalStorageService.removeItemByKey('multiVenuesPayload');
      LocalStorageService.removeItemByKey('multiVenueOfferPayload');
    },
    setInitialLocation(state, action: PayloadAction<TInitialLocation>) {
      state.initialLocation = {
        ...state.initialLocation,
        ...action.payload,
      };
    },
    setSearchFocusedInput(state, action: PayloadAction<string>) {
      state.searchFocusedInput = action.payload;
    },
    setStoredSearchCriteria(
      state,
      action: PayloadAction<TVenuesQuerySearchData>,
    ) {
      state.storedSearchCriteria = action.payload;
    },
    setSearchListLoaded(state, action: PayloadAction<boolean>) {
      state.isSearchListLoaded = action.payload; // todo remove after merge
    },
    setIsFastSearchEnabled(state, action: PayloadAction<boolean>) {
      state.isFastSearchEnabled = action.payload;
      LocalStorageService.setByKey(
        'isFastSearchEnabled',
        String(action.payload),
      );
    },
  },
});

export const {
  setSearchCriteria,
  setSearchText,
  setSearchCriteriaRoomType,
  setSearchCriteriaMeetingRoomCapacity,
  setSearchCriteriaSeatingStyle,
  setLatitudeLongitude,
  setSearchCriteriaVisibleRadius,
  setPageForVenuesSearch,
  setStartDateForVenuesSearch,
  setEndDateForVenuesSearch,
  searchPredictionLoading,
  clearSearchCriteria,
  setMeetingRequestData,
  setMultiSearchTimeData,
  setMultiSearchPayload,
  clearMultiSearchData,
  setInitialLocation,
  setSearchFocusedInput,
  setStoredSearchCriteria,
  setSearchListLoaded,
  setIsFastSearchEnabled,
} = searchSlice.actions;

export default searchSlice.reducer;
