import {useEffect} from 'react';

import {useQuery} from 'hooks/useQuery';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {IPreviewUnit, ISummaryUnit} from 'types/dto/IBooking.types';
import {
  getCapacityFromCapacityDto,
  getCapacityFromCapacityDtoMultiRooms,
} from 'view/customer/helpers';
import {getCancellationPolicyText} from 'store/customer/apiActions';
import {IRequestDayItem} from 'types/offer';
import {getCancellationPolicyTextSuccess} from 'store/customer/customerSlice';
import {ERoomType} from '../../../types/dto/ERoomType.type';

interface IProps {
  unitData: (ISummaryUnit | IRequestDayItem)[];
  isRfp: boolean;
  isNoCall?: boolean;
}

function useUpdateCancellationPolicyText({unitData, isRfp, isNoCall}: IProps) {
  const dispatch = useAppDispatch();
  const {seatingStyle} = useQuery();

  const cancellationPolicyText = useAppSelector(
    ({customer}) => customer.cancellationPolicyText,
  );

  const isCancellationPolicyTextLoading = useAppSelector(
    ({customer}) => customer.isCancellationPolicyTextLoading,
  );

  const filteredUnits = unitData.filter(Boolean);
  const firstUnit = filteredUnits[0];

  const isWorkDesk =
    (firstUnit as ISummaryUnit)?.unitInfo?.roomType === ERoomType.WORK_SPACE ||
    (firstUnit as ISummaryUnit)?.roomType === ERoomType.WORK_SPACE;

  const setupStyle =
    (firstUnit as IRequestDayItem)?.setupStyle ||
    (firstUnit as ISummaryUnit)?.unitFilter?.setupStyle;

  const isMultiRooms = filteredUnits.length > 1;
  const participants =
    (firstUnit as ISummaryUnit)?.maxParticipants ||
    (firstUnit as IRequestDayItem)?.participants;
  const hasMaxParticipantsField = participants !== undefined;

  const roomCapacity = isWorkDesk
    ? 1
    : isMultiRooms
    ? hasMaxParticipantsField
      ? Math.max(
          ...filteredUnits.map(
            (item) =>
              ('maxParticipants' in item
                ? item.maxParticipants
                : (item as IRequestDayItem).participants) || 0,
          ),
        )
      : getCapacityFromCapacityDtoMultiRooms(filteredUnits as IPreviewUnit[])
    : hasMaxParticipantsField
    ? participants || 0
    : getCapacityFromCapacityDto(
        (firstUnit as ISummaryUnit)?.unitCapacities,
        (seatingStyle as string) || (setupStyle as string),
      );

  useEffect(() => {
    if (
      isNoCall ||
      cancellationPolicyText ||
      !roomCapacity ||
      isCancellationPolicyTextLoading
    )
      return;

    dispatch(getCancellationPolicyText(roomCapacity, isRfp, isMultiRooms));
  }, [
    dispatch,
    cancellationPolicyText,
    isMultiRooms,
    isNoCall,
    isRfp,
    roomCapacity,
    isCancellationPolicyTextLoading,
  ]);

  useEffect(() => {
    return () => {
      dispatch(getCancellationPolicyTextSuccess(''));
    };
  }, [dispatch]);
}

export default useUpdateCancellationPolicyText;
