import React, {useState} from 'react';

import {StyledPasswordInput, StyledIcon} from '../../NW2FormItem.styles';

import {IPasswordProps} from '../../types';

const InputPassword = ({
  name,
  placeholder,
  hasError,
  inputProps,
  ...rest
}: IPasswordProps) => {
  const [visibleText, setVisibleText] = useState(false);
  const toggleVisibleText = () => {
    setVisibleText(!visibleText);
  };
  return (
    <>
      <StyledPasswordInput
        {...inputProps}
        name={name}
        type={visibleText ? 'text' : 'password'}
        placeholder={placeholder}
        hasError={hasError}
        onBlur={rest.onBlur}
        onFocus={rest.onFocus}
      />
      <StyledIcon
        icon={visibleText ? 'EYE' : 'EYE_CLOSED'}
        onClick={toggleVisibleText}
      />
    </>
  );
};

export default InputPassword;
