import React, {useCallback, useRef, useState, useMemo} from 'react';
import {useForm} from 'react-final-form';
import {DateTime} from 'luxon';

import NW2PopoverDrawer from 'view/components/NW2PopoverDrawer';

import {SingleDayDatePickerMobile} from 'view/components/NW2Datepicker/NW2DatePicker/SingleDayDatePickerMobile';

import useToggle from 'hooks/useToggle';
import DateUtils, {getDateTime} from 'utils/dateUtils';
import {useAppSelector} from 'store/hooks';
import {NW2MultiDatePicker, useDatePicker} from 'view/components/NW2Datepicker';
import {useClickOutside} from 'hooks/useClickOutside';
import {optionDateRules} from 'utils/finalFormFieldRules';

import {
  DATE_ID,
  OPTION_DAYS_OFFSET_FROM_TODAY,
  OPTION_DAYS_BEFORE_MEETING,
  OPTION_DATE_DEFAULT_OFFSET_BEFORE_MEETING,
} from 'constants/app';
import {
  FormGroup,
  Link,
  StyledDropdown,
  StyledNW2FormItemInput,
  StyledParagraph,
} from './OptionDate.styles';
import {TDateType} from 'types/dto/ISearch.types';

export const OptionDate = () => {
  const [isPopoverShowed, setPopoverShowed] = useToggle(false);
  const [isOpened, setOpened] = useState<boolean>(false);
  const [formattedValue, setFormattedValue] = useState('');

  const {isMobile, isDesktop} = useAppSelector(({app}) => app.deviceType);

  const searchCriteria = useAppSelector(({search}) => search.searchCriteria);
  const {startDate: startSearchDate} = searchCriteria;

  const {mutators} = useForm();

  const handleSetInputValue = useCallback(
    (value: TDateType) => {
      mutators.setValue('optionDate', value);
      setFormattedValue(
        DateUtils.getFormattedDayMonthTime({
          date: value?.toISOString(),
          showYear: true,
        }),
      );
    },
    [mutators],
  );

  // Default date calculation
  const isBookingToday = DateUtils.isSameDay(
    startSearchDate,
    DateTime.now().toISO(),
  );

  const defaultDate = useMemo(() => {
    return isBookingToday
      ? startSearchDate
      : getDateTime(startSearchDate)
          .minus({days: OPTION_DATE_DEFAULT_OFFSET_BEFORE_MEETING})
          .toISO();
  }, [isBookingToday, startSearchDate]);

  const {date, timeStartISOString, onDateChange, onReset, isTimeRangeShowed} =
    useDatePicker(handleSetInputValue, defaultDate);

  const onChange = (payload: TDateType) => {
    onDateChange(payload);
    setOpened(false);
  };

  const onToggleDatePicker = () => {
    setOpened((prev) => !prev);
  };

  const handlePopoverToggle = () => {
    setPopoverShowed();
  };

  const clearHandler = useCallback(() => {
    onReset();
  }, [onReset]);

  const containerRef = useRef(null);
  useClickOutside(containerRef, isOpened, () => {
    if (!isMobile) {
      onToggleDatePicker();
    }
  });

  const minDate = DateTime.now()
    .plus({days: OPTION_DAYS_OFFSET_FROM_TODAY})
    .toJSDate();

  const maxDate = getDateTime(startSearchDate)
    .minus({days: OPTION_DAYS_BEFORE_MEETING})
    .toJSDate();

  const commonConfig = {
    minDate,
    maxDate,
    onChange,
  };

  // Hide MonthYearDropdown if dates are within one month
  const currentDate = new Date();
  const isWithinOneMonth =
    currentDate.getFullYear() === (maxDate || currentDate).getFullYear() &&
    currentDate.getMonth() === (maxDate || currentDate).getMonth();

  return (
    <FormGroup
      columnNumber={2}
      gap={24}
      isDesktop={isDesktop}
      ref={containerRef}
    >
      <StyledNW2FormItemInput
        id={DATE_ID}
        type='text'
        name='optionDate'
        rules={optionDateRules}
        onClick={onToggleDatePicker}
        isOpened={isOpened}
        placeholder='Non selected'
        value={formattedValue}
        errorMessageMargin='0 1px'
        readOnly
      />

      {isMobile ? (
        <SingleDayDatePickerMobile
          startDate={timeStartISOString}
          isOpened={isOpened}
          onToggleDatePicker={onToggleDatePicker}
          onNextClick={onToggleDatePicker}
          onClearClick={clearHandler}
          dirty={isTimeRangeShowed}
          inputValue={formattedValue}
          {...commonConfig}
        />
      ) : (
        isOpened && (
          <StyledDropdown>
            <NW2MultiDatePicker
              startDate={date}
              isSingleDayDatePicker
              withMonthYearDropdown
              showMonthYearDropdown={!isWithinOneMonth}
              {...commonConfig}
            />
          </StyledDropdown>
        )
      )}

      <NW2PopoverDrawer
        header='What is option date?'
        body={
          <>
            <StyledParagraph>
              An "option date" is the window after receiving an offer when the
              venue holds the space for you to make your decision.
            </StyledParagraph>
            <span>
              If you make a booking before the option date, it is immediately
              binding. If you would like to book an offer after the option date,
              the supplier is required to reconfirm your request-to-book.
            </span>
          </>
        }
        placement='bottomLeft'
        open={isPopoverShowed}
        handleVisibleChange={handlePopoverToggle}
        onClose={handlePopoverToggle}
        isMobile={false}
        clickable
      >
        <Link>What is option date?</Link>
      </NW2PopoverDrawer>
    </FormGroup>
  );
};
