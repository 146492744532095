import React from 'react';
import styled, {css} from 'styled-components';

import Icon from 'view/components/Icon';

import {TextValue} from 'view/components/TextValue';
import {StyledSpan} from 'view/components/Typography';

import {
  borderColor,
  borderRadiusDef,
  mdBp,
  offsetDef,
} from 'constants/styleVars';

const flexStyles = css`
  display: flex;
  align-items: center;
  > * + * {
    margin-left: ${offsetDef};
  }
`;

const Container = styled.div`
  text-align: right;
  display: grid;
  grid-gap: ${offsetDef};

  @media (min-width: ${mdBp}px) {
    grid-template-columns: 1fr auto;
  }
`;

const StyledIcon = styled(Icon)`
  overflow: hidden;
  border-radius: ${borderRadiusDef};
  border: 1px solid ${borderColor};
`;

const FlexContainer = styled.div`
  ${flexStyles};
  text-align: left;
`;

const StyledTextValue = styled(TextValue)`
  text-transform: capitalize;
`;

type TProps = {
  brand: string | null;
  name: string | null;
  lastFour: string | null;
  className?: string;
};

export function CardNameContainer({
  brand,
  name,
  lastFour,
  className,
}: Partial<TProps>) {
  return (
    <Container className={className}>
      <FlexContainer>
        <StyledIcon icon={brand ? brand.toUpperCase() : 'NW2_CREDIT_CARD'} />
        <div>
          <StyledTextValue bold inline>
            {name || 'Credit Card'}
          </StyledTextValue>
          <StyledSpan NW2Gray600Color>&nbsp; ending in {lastFour}</StyledSpan>
        </div>
      </FlexContainer>
    </Container>
  );
}
