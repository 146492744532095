import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import {venueContainer} from 'app';
import {useAppDispatch} from 'store/hooks';
import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {
  getAdyenAccountHolderLink,
  setAdyenOnBoardingErrorList,
} from 'store/venue/actions';
import {EPaymentAccountStatus} from 'types/venue';

export function useNmmRegisterClick() {
  const dispatch = useAppDispatch();

  const adyenOnBoardingLink = useSelector((state) =>
    _get(state, 'venue.adyenOnBoardingLink'),
  );
  const venueUserId = useSelector((state) => _get(state, 'venue.venue.userId'));
  const venueId = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.id`),
  );
  const accountHolderCode = useSelector((state) =>
    _get(state, 'venue.venue.accountHolderCode'),
  );

  const isGeneratingOnBoardingLink = useSelector((state) =>
    _get(state, 'venue.isGeneratingOnBoardingLink'),
  );

  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    if (adyenOnBoardingLink) {
      window.location.replace(adyenOnBoardingLink);
    }
  }, [adyenOnBoardingLink]);

  const onRegisterClick = useCallback(() => {
    if (!venueId) return;

    setIsClicked(true);

    if (!accountHolderCode) {
      venueContainer.postCreateAdyenAccountHolder({
        payload: {
          userId: venueUserId,
          accommodationId: venueId,
        },
        onSuccess: (data: {
          accommodationId: number;
          accountCode: string;
          accountHolderCode: string;
          accountHolderStatus: EPaymentAccountStatus;
          id: number;
          userId: number;
          venueId: number;
        }) => {
          if (data?.accountHolderCode) {
            dispatch(getAdyenAccountHolderLink(venueUserId));
          }
        },
        onError: (error: any) => {
          if (error.status === 400 && error.data.reasonCode === 'INV-ACH-000') {
            dispatch(setAdyenOnBoardingErrorList(error.data.errors));
          }
          setIsClicked(false);
        },
      });
    } else {
      dispatch(getAdyenAccountHolderLink(venueUserId));
    }
  }, [accountHolderCode, dispatch, venueId, venueUserId]);

  return {
    onRegisterClick,
    isGeneratingOnBoardingLink,
    isClicked,
  };
}
