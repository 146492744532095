import React from 'react';

import {Link} from 'view/components/Typography';
import Icon from 'view/components/Icon';
import NW2PopoverDrawer from 'view/components/NW2PopoverDrawer';

import {EPaymentAccountStatus} from 'types/venue';
import {getStatusConfig} from '../NMMSpacesList/helpers';
import {
  StatusTag,
  StatusTagBtnText,
  StyledButton,
  Text,
} from './NMMSpaceStatus.styles';
import useToggle from 'hooks/useToggle';

interface IProps {
  status: EPaymentAccountStatus | null;
  isLoading?: boolean;
  isDisabled?: boolean;
  isIndicatorDisabled?: boolean;
  isClickable?: boolean;
}

const NMMSpaceStatus = ({
  status,
  isClickable,
  isLoading,
  isDisabled,
  isIndicatorDisabled,
}: IProps) => {
  const {
    statusText,
    text,
    linkText,
    linkHref,
    buttonText,
    buttonHandler,
    bgColor,
    color,
    icon,
  } = getStatusConfig(status);

  const [isPopoverShowed, setPopoverShowed] = useToggle(false);

  const handlePayoutPopoverToggle = () => {
    setPopoverShowed();
  };

  return (
    <NW2PopoverDrawer
      open={isPopoverShowed}
      handleVisibleChange={handlePayoutPopoverToggle}
      onClose={handlePayoutPopoverToggle}
      isMobile={false}
      header={statusText}
      placement='bottomRight'
      clickable={isClickable}
      body={
        <>
          <Text>{text}</Text>

          {linkText && linkHref && <Link href={linkHref}>{linkText}</Link>}

          {!!buttonText && (
            <StyledButton
              loading={isLoading}
              disabled={isDisabled}
              onClick={buttonHandler}
              buttonType='primary'
              size='small'
            >
              {buttonText}
            </StyledButton>
          )}
        </>
      }
    >
      <StatusTag bgColor={bgColor} color={color} disabled={isIndicatorDisabled}>
        <StatusTagBtnText>{statusText}</StatusTagBtnText>
        <Icon transparent icon={icon} size={16} />
      </StatusTag>
    </NW2PopoverDrawer>
  );
};

export default NMMSpaceStatus;
