import {ChangeEvent, FocusEvent, useCallback} from 'react';
import {inputTextToNumber} from '../../../utils/helpers';
import {useForm} from 'react-final-form';

export function useInputPriceChange() {
  /**
   * mutators={{ setValue: ([field, value], state, {changeValue}) => changeValue(state, field, () => value) }}
   */
  const {mutators} = useForm();

  const onChangePrice = useCallback(
    (type: string) => (e: ChangeEvent<HTMLInputElement>) => {
      const onChange = (v: string) => {
        mutators.setValue(type, v);
      };

      inputTextToNumber(e.target.value, onChange);
    },
    [mutators],
  );

  const onBlurPrice = useCallback(
    (type: string) => (e: FocusEvent<HTMLInputElement>) => {
      const value = e.target.value;

      if (value) {
        // align entered price with 2 digits after dot
        mutators.setValue(type, Number(value).toFixed(2));
      }
    },
    [mutators],
  );

  return {onChangePrice, onBlurPrice};
}
