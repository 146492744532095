import React, {ReactNode} from 'react';
import {Form, FormSpy} from 'react-final-form';

import NMMSection from 'view/components/NMMSection';
import OperatingExecutivesView from './OperatingExecutivesView';
import usePayout from 'view/venue/NW2VenueProfile/components/NMMPayout/hooks/usePayout';
import FormSectionActionButtons from './components/FormSectionActionButtons';

import {IVenue} from 'types/venue';
import {EVenueProfilePaymentSections} from '../../types';

import {
  offsetXLg,
  offsetXXLg,
  fontSizeXXXLg,
  offsetXXXXLg,
} from 'constants/styleVars';
import {
  Section,
  SectionTitle,
} from 'view/components/NMMSection/NMMSection.styles';

type TProps = {
  venue: IVenue;
  sectionName: EVenueProfilePaymentSections;
  containerTitle?: ReactNode;
};

export const SECTION_MARGIN = `0 0 ${offsetXXXXLg}`;
export const SECTION_PADDING = `${offsetXXLg} 0 0`;

const FormSection = ({venue, sectionName, containerTitle}: TProps) => {
  const {
    isEditorShowed,
    onEditorShow,
    initialValues,
    onEditorHide,
    onSubmit,
    isFormSending,
    isFormDirty,
    processFormFields,
    editForm,
    viewForm,
  } = usePayout(venue, sectionName);

  return (
    <>
      <NMMSection
        id={sectionName}
        title={sectionName}
        isEditOn={!!venue.accountHolderCode || isEditorShowed}
        onEdit={onEditorShow}
        sectionMargin={SECTION_MARGIN}
        sectionPadding={SECTION_PADDING}
        titleFontSize={fontSizeXXXLg}
        titleMarginBottom={offsetXLg}
        containerTitle={containerTitle}
      >
        {isEditorShowed ? (
          <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({handleSubmit}) => (
              <form onSubmit={handleSubmit}>
                {editForm}

                <FormSectionActionButtons
                  isFormDirty={isFormDirty}
                  isFormSending={isFormSending}
                  onEditorHide={onEditorHide}
                  initialValues={initialValues}
                />

                <FormSpy
                  subscription={{dirtyFields: true}}
                  onChange={processFormFields}
                />
              </form>
            )}
          />
        ) : (
          viewForm
        )}
      </NMMSection>

      {sectionName === EVenueProfilePaymentSections.BUSINESS_INFORMATION &&
        !isEditorShowed && (
          <Section
            id={EVenueProfilePaymentSections.OPERATING_EXECUTIVES}
            sectionMargin={SECTION_MARGIN}
            sectionPadding={SECTION_PADDING}
          >
            <SectionTitle
              titleFontSize={fontSizeXXXLg}
              titleMarginBottom={offsetXLg}
            >
              {EVenueProfilePaymentSections.OPERATING_EXECUTIVES}
            </SectionTitle>
            <OperatingExecutivesView
              peopleOfInterest={venue.peopleOfInterest}
            />
          </Section>
        )}
    </>
  );
};

export default FormSection;
