import {ApiType, IApi} from '../common/http.service';
import {
  IGetCorporateAccountCompaniesPayload,
  IAddCorporateAccountCompanyPayload,
  IGetCorporateAccountCompanyUsersPayload,
  IAddCorporateAccountPropertyManagerPayload,
  IEditCorporateAccountCompanyUserPayload,
  IUnassignCorporateAccountCompanyUserPayload,
  IReassignCorporateAccountCompanyUserPayload,
  IGetUnassignedUsersPayload,
  IUpdateCorporateAccountCompanyPayload,
  IAnnouncementRequest,
  IAnnouncementRespond,
  IEditAnnouncementRequest,
} from 'store/admin/types';

const adminRepository = (api: IApi) => ({
  getCorporateAccountCompanies: async (
    data: IGetCorporateAccountCompaniesPayload,
  ): Promise<any> => {
    const {page, size} = data;
    return await api.get(
      ApiType.Inventory,
      `corporate-accounts?page=${page}&size=${size}`,
    );
  },
  getCorporateAccountCompanyById: async (id: string): Promise<void> => {
    return await api.get(ApiType.Inventory, `corporate-accounts/${id}`);
  },
  getTotalCorporateAccountCompanies: async (): Promise<void> => {
    return await api.get(ApiType.Inventory, 'corporate-accounts/count');
  },
  addCorporateAccountCompany: async (
    data: IAddCorporateAccountCompanyPayload,
  ): Promise<void> => {
    return await api.add(ApiType.Inventory, 'corporate-accounts', data);
  },
  updateCorporateAccountCompany: async (
    data: IUpdateCorporateAccountCompanyPayload,
  ): Promise<void> => {
    return await api.update(
      ApiType.Inventory,
      'corporate-accounts/update',
      data,
    );
  },
  getCorporateAccountCompanyUsers: async (
    data: IGetCorporateAccountCompanyUsersPayload,
  ): Promise<void> => {
    const {id, page, size} = data;
    return await api.get(
      ApiType.Search,
      `corporate-accounts/${id}/users?page=${page}&size=${size}&searchstring=`,
    );
  },
  getUnassignedUsers: async (
    data: IGetUnassignedUsersPayload,
  ): Promise<void> => {
    const {page, size} = data;
    return await api.get(
      ApiType.Search,
      `corporate-accounts/unassigned?page=${page}&size=${size}`,
    );
  },
  addCorporateAccountPropertyManager: async (
    data: IAddCorporateAccountPropertyManagerPayload,
  ): Promise<void> => {
    return await api.add(
      ApiType.Inventory,
      `corporate-accounts/${data.accountId}/property-managers`,
      data,
    );
  },
  editCorporateAccountCompanyUser: async (
    data: IEditCorporateAccountCompanyUserPayload,
  ): Promise<void> => {
    const {userId} = data;
    return await api.update(ApiType.Inventory, `users/${userId}`, data);
  },
  unassignCorporateAccountCompanyUser: async (
    data: IUnassignCorporateAccountCompanyUserPayload,
  ): Promise<void> => {
    const {corporateAccountId, userId} = data;
    return await api.delete(
      ApiType.Inventory,
      `corporate-accounts/${corporateAccountId}/users/${userId}`,
    );
  },
  reassignCorporateAccountCompanyUser: async (
    data: IReassignCorporateAccountCompanyUserPayload,
  ): Promise<void> => {
    const {newAccountId, userId} = data;
    return await api.add(
      ApiType.Inventory,
      `corporate-accounts/${newAccountId}/users?user-id=${userId}`,
      {},
    );
  },
  getAnnouncements: async (): Promise<void> => {
    return await api.get(ApiType.Notification, `announcement`, {});
  },
  editAnnouncement: async (data: IEditAnnouncementRequest): Promise<void> => {
    const {id} = data;
    return await api.update(ApiType.Notification, `announcement/${id}`, data);
  },
  addAnnouncement: async (
    data: IAnnouncementRequest,
  ): Promise<IAnnouncementRespond> => {
    return await api.add(ApiType.Notification, `announcement`, data);
  },
});

export default adminRepository;
