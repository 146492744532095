import React from 'react';
import styled from 'styled-components';

import NW2Button from 'view/components/NW2Button/NW2Button';
import useShareVenue from 'hooks/useShareVenue';
import Icon from 'view/components/Icon';

import {IMultiDayPublicVenue, IPublicVenue} from 'types/dto/IPublicVenue';
import {TVenuesQuerySearchData} from 'types/search';
import {getAddressStringFromLocation} from 'utils/stringUtils';
import {findCoverImage} from 'utils/helpers';
import {
  fontSizeXSm,
  fontSizeXXSm,
  mdBp,
  lgBp,
  fontSizeSm,
} from 'constants/styleVars';

const StyledNW2Button = styled(NW2Button)`
  white-space: nowrap;
  font-size: ${fontSizeXSm};
  padding-bottom: 0;
  padding-top: 0;

  @media (min-width: ${mdBp}px) {
    font-size: ${fontSizeSm};
  }
  @media (min-width: ${lgBp}px) {
    font-size: ${fontSizeXXSm};
  }
`;

type TProps = {
  venueDetails: IPublicVenue | IMultiDayPublicVenue;
  searchData: TVenuesQuerySearchData;
};

export function ShareButton({venueDetails, searchData}: TProps) {
  const venueAddress = getAddressStringFromLocation(venueDetails.location);
  const venueCoverImage = findCoverImage(venueDetails.documents);

  const {shareVenue} = useShareVenue({
    title: "Check out this venue's offerings on HRS Work!",
    venueName: venueDetails.name,
    text: `\n I found a cool remote workplace for us to collaborate at! \n\n It's at ${venueAddress} \n\n Check it out:\n\n`,
    url: window.location.href,
    venueCoverImage: venueCoverImage ? venueCoverImage.url : '',
    roomType: searchData.roomType,
  });

  if ((venueDetails as IMultiDayPublicVenue)?.days?.length > 1) {
    return null;
  }

  return (
    <StyledNW2Button
      buttonType='tertiary'
      inverted
      size='small'
      icon={<Icon icon='SHARE' transparent size={18} />}
      onClick={shareVenue}
    >
      Share Venue
    </StyledNW2Button>
  );
}
