import React from 'react';
import styled from 'styled-components';

import CustomList from 'view/components/CustomList';

import {TMissingCompanyDetailsFields} from 'types/supplier';
import {legalContactFieldNames} from 'view/venue/NW2VenueProfile/components/NMMPayout/helpers';

import {SubTitle, TextWrapper} from '../NMMPayoutStatusBlock.styles';
import {offsetXXSm} from 'styles/configs/offset';

const Paragraph = styled.div`
  margin-bottom: ${offsetXXSm};
`;

export function MissingInfoStatusData({
  missingFields,
}: {
  missingFields: TMissingCompanyDetailsFields;
}) {
  const isBusinessInfo = !!missingFields.BI.length;
  const isOperatingExecutives = !!missingFields.OE.length;
  const list = [
    ...(isBusinessInfo
      ? [
          {
            value: (
              <>
                <Paragraph>Business information</Paragraph>
                <CustomList
                  list={missingFields.BI.map((field) => ({
                    value: legalContactFieldNames[field],
                  }))}
                  size='large'
                />
              </>
            ),
          },
        ]
      : []),
    ...(isOperatingExecutives
      ? [
          {
            value: (
              <>
                <Paragraph>Operating Executives</Paragraph>
                <CustomList
                  list={missingFields.OE.map((field) => ({
                    value: `${legalContactFieldNames[field]} (First name, last name, date of birth)`,
                  }))}
                  size='large'
                />
              </>
            ),
          },
        ]
      : []),
  ];

  return {
    title: 'Add company details',
    description: (
      <TextWrapper>
        To get paid by your customers, you have to provide us with additional
        company details. We will forward this data to our contracted payment
        provider Adyen once you are ready to register. This will save you time
        and energy.
      </TextWrapper>
    ),
    linkText: '',
    content: (
      <>
        <SubTitle>Please provide the following</SubTitle>
        <CustomList list={list} size='large' />
      </>
    ),
  };
}
