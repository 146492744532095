import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CoreOptions} from '@adyen/adyen-web/dist/types/core/types';
import PaymentMethodsResponse from '@adyen/adyen-web/dist/types/core/ProcessResponse/PaymentMethodsResponse';

import {PaymentAction} from '@adyen/adyen-web/dist/types/types';
import {IAdyenConfigVariables, RESULT_CODE} from './types';

import {INPUT_COLOR} from 'view/components/NW2FormItem/NW2FormItem.styles';

type TPaymentReducer = {
  resultCode: null | RESULT_CODE;
  paymentMethodsResponse?: PaymentMethodsResponse;
  config: CoreOptions;
  action: null | PaymentAction;
  adyenComponent: any;
  defaultCreditCardId: string;
  isPaymentInProcess: boolean;
  isPaymentMethodRemoved: boolean;
  isPaymentMethodAdded: boolean;
};

import {fontSizeMd, fontWeightNormal} from 'constants/styleVars';

const initialCardStyles = {
  base: {
    fontSize: `${fontSizeMd}`,
    fontWeight: `${fontWeightNormal}`,
    fontFamily: 'Inter, sans-serif',
    color: INPUT_COLOR,
  },
  placeholder: {
    color: INPUT_COLOR,
  },
  error: {
    color: INPUT_COLOR,
  },
};

// Hotfix to set adyen clientKey if config.yml file not found on server
enum EHosts {
  PRODUCTION = 'newwork.hrs.com',
}

const isProductionEnv = window.location.host === EHosts.PRODUCTION;

const clientKey = isProductionEnv
  ? process.env.REACT_APP_LIVE_CLIENT_KEY
  : process.env.REACT_APP_TEST_CLIENT_KEY;

const environment = isProductionEnv
  ? process.env.REACT_APP_LIVE_PAYMENT_ENVIRONMENT
  : process.env.REACT_APP_TEST_PAYMENT_ENVIRONMENT;

const translations = {
  en_US: {
    'creditCard.holderName': 'Name on card',
    'creditCard.holderName.placeholder': '',
    'creditCard.numberField.title': 'Credit card number*',
    'creditCard.numberField.placeholder': '',
    'creditCard.expiryDateField.title': 'Expiry date*',
    'creditCard.expiryDateField.placeholder': '',
    'creditCard.cvcField.title': 'CVC / CVV*',
    'creditCard.cvcField.placeholder': '',
    'creditCard.cvcField.placeholder.3digits': '',
    'creditCard.cvcField.placeholder.4digits': '',
    houseNumberOrName: 'House number*',
    street: 'Street*',
    city: 'City*',
    postalCode: 'Postal code*',
    country: 'Country*',
    'select.country': '',
  },
};

const initialState: TPaymentReducer = {
  resultCode: null,
  paymentMethodsResponse: undefined,
  config: {
    paymentMethodsConfiguration: {
      card: {
        hasHolderName: true,
        name: 'Credit or debit card',
        styles: initialCardStyles,
      },
    },
    locale: 'en_US',
    translations,
    showPayButton: false,
    clientKey,
    environment,
  },
  action: null,
  isPaymentInProcess: false,
  isPaymentMethodRemoved: false,
  isPaymentMethodAdded: false,
  adyenComponent: null,
  defaultCreditCardId: '',
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    clearPayment(state) {
      state.action = null;
      state.resultCode = null;
      state.isPaymentInProcess = false;
    },
    setPaymentsMethod(state, action: PayloadAction<any>) {
      state.paymentMethodsResponse = action.payload;
      state.isPaymentInProcess = false;
    },
    setPaymentAction(state, action: PayloadAction<any>) {
      state.action = action.payload;
      state.resultCode = action.payload.resultCode;
    },
    setIsPaymentInProcess(state, action: PayloadAction<boolean>) {
      state.isPaymentInProcess = action.payload;
    },
    setDefaultCreditCardId(state, action: PayloadAction<string>) {
      state.defaultCreditCardId = action.payload;
    },
    setAdyenComponent(state, action: PayloadAction<any>) {
      state.adyenComponent = action.payload;
    },
    setAdyenConfigEnvVariables(
      state,
      action: PayloadAction<IAdyenConfigVariables>,
    ) {
      state.config.clientKey = action.payload.clientKey;
      state.config.environment = action.payload.environment;
    },
    removePaymentMethod(state, action: PayloadAction<string>) {
      const storedPaymentMethods =
        state.paymentMethodsResponse?.storedPaymentMethods?.filter(
          (item: Record<string, any>) => item.uuid !== action.payload,
        ) || [];

      if (state.paymentMethodsResponse) {
        state.paymentMethodsResponse = {
          ...state.paymentMethodsResponse,
          storedPaymentMethods,
        };
      }

      state.isPaymentInProcess = false;
      state.isPaymentMethodRemoved = true;
    },
    setIsPaymentMethodRemoved(state, action: PayloadAction<boolean>) {
      state.isPaymentMethodRemoved = action.payload;
    },
    setIsPaymentMethodAdded(state, action: PayloadAction<boolean>) {
      state.isPaymentMethodAdded = action.payload;
    },
  },
});

export const {
  setAdyenComponent,
  setAdyenConfigEnvVariables,
  setIsPaymentMethodRemoved,
  clearPayment,
  setPaymentsMethod,
  setPaymentAction,
  setIsPaymentInProcess,
  setDefaultCreditCardId,
  removePaymentMethod,
  setIsPaymentMethodAdded,
} = paymentSlice.actions;

export default paymentSlice.reducer;
