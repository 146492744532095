import {useCallback, useEffect, useMemo, useState} from 'react';

import {useAppDispatch, useAppSelector} from 'store/hooks';
import {searchVenuesAction} from 'store/venues/actions';
import {searchWorkDesksAction} from 'store/workDesks/apiActions';
import {ITimeData} from 'types/dto/ISearch.types';
import {TDay, TVenuesQuerySearchData} from 'types/search';
import {makeMultiSearchPayload} from 'view/components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/utils';
import {useQuery} from 'hooks/useQuery';
import {TBedroomsCatering} from 'view/components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/components/AddAccommodationRequest/types';
import {useMultiSearchData} from '../hooks/useMultiSearchData';
import {EResourcesType} from 'types/dto/IExtras.type';
import {useCommonExtras} from '../hooks/useCommonExtras';
import {addShortList} from 'store/offers/offersSlice';
import LocalStorageService from 'infra/common/localStorage.service';
import {checkExtrasData} from '../Offer/helpers';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import {checkBoolean} from 'utils/stringUtils';
import {ERoomType} from 'types/dto/ERoomType.type';

interface IProps {
  initialSearchData: TVenuesQuerySearchData & {
    initialTimeData: ITimeData[];
    meetingRequestData: TDay[];
  };
  startDate: string;
  endDate: string;
  bedroomsCatering: TBedroomsCatering;
}

export function useVenueSearchAction({
  initialSearchData,
  startDate,
  endDate,
  bedroomsCatering,
}: IProps) {
  const dispatch = useAppDispatch();

  const {isMultiSearchOpen} = useQuery();

  const [extrasOption] = useCommonExtras();

  const [searchInitUserRole, setSearchInitUserRole] = useState<
    EUserRoleCognito | ''
  >('');
  const [searchUserId, setSearchUserId] = useState<number | null>(null);

  const corporateAccountId = useAppSelector(({app}) => app.corporateAccountId);

  const role = useAppSelector(({app}) => app.user.role);

  const isSearchListLoaded = useAppSelector(
    ({search}) => search.isSearchListLoaded,
  );

  const isFastSearchEnabled = checkBoolean(
    LocalStorageService.getByKey('isFastSearchEnabled'),
  );

  const filteredBedroomExtras = useMemo(
    () => extrasOption.filter((item) => item.type === EResourcesType.BEDROOM),
    [extrasOption],
  );

  const initVenueSearchAction = useCallback(() => {
    const isMultiRoom = initialSearchData.multiRooms;
    const isWorkDesks = initialSearchData.roomType === ERoomType.WORK_SPACE;

    if (isMultiRoom && !initialSearchData.meetingRequestData.length) return;

    // ToDo check how prevent searchVenuesAction working twice without isSearchListLoaded flag
    if (!isSearchListLoaded) {
      const {multiSearchPayload} = makeMultiSearchPayload({
        meetingRequestData: initialSearchData.meetingRequestData,
        bedroomsCatering,
        filteredBedroomExtras,
      });

      const modifyMultiSearchPayload = multiSearchPayload.map((day) => {
        return {
          ...day,
          roomFilters: day.roomFilters?.map((room) => {
            return {
              ...room,
              extras: [],
            };
          }),
        };
      });

      const filterDays = isFastSearchEnabled
        ? modifyMultiSearchPayload
        : multiSearchPayload;

      const searchData = {
        ...initialSearchData,
        startDate: startDate || initialSearchData.startDate,
        endDate: endDate || initialSearchData.endDate,
        searchString: '', // needs to be empty string!
      };

      isWorkDesks
        ? dispatch(searchWorkDesksAction(searchData))
        : dispatch(
            searchVenuesAction({
              ...searchData,
              filterDays,
            }),
          );
    }

    setSearchInitUserRole(role);
    setSearchUserId(corporateAccountId);

    const storedShortList = LocalStorageService.getByKey('shortList') || '';

    /**
     * reset shortList items after SEARCH ON PAGE REFRESH & add search props for compare page
     * implemented in pair with SummaryRequestBlock
     */
    if (storedShortList) {
      dispatch(
        addShortList({
          ...JSON.parse(storedShortList),
          checkIn: startDate || initialSearchData.startDate,
          checkOut: endDate || initialSearchData.endDate,
          participants: initialSearchData.meetingRoomCapacity,
          isMultiDay: isMultiRoom,
          ...checkExtrasData(initialSearchData.meetingRequestData),
        }),
      );
    }
  }, [
    isFastSearchEnabled,
    bedroomsCatering,
    corporateAccountId,
    dispatch,
    endDate,
    filteredBedroomExtras,
    initialSearchData,
    role,
    startDate,
    isSearchListLoaded,
  ]);

  useEffect(() => {
    if (!searchInitUserRole && !isMultiSearchOpen && extrasOption.length) {
      // init on 1st page load
      initVenueSearchAction();
    }
  }, [
    dispatch,
    extrasOption.length,
    initVenueSearchAction,
    isMultiSearchOpen,
    searchInitUserRole,
    role,
  ]);

  useEffect(() => {
    if (
      searchInitUserRole === EUserRoleCognito.ROLE_GUEST &&
      role === EUserRoleCognito.ROLE_CUSTOMER &&
      corporateAccountId
    ) {
      // re-fetch data if user has logged in
      initVenueSearchAction();
    }

    if (
      searchInitUserRole === EUserRoleCognito.ROLE_CUSTOMER &&
      role === EUserRoleCognito.ROLE_GUEST
    ) {
      // reset init flag if user has logged out
      setSearchInitUserRole(role);
      setSearchUserId(null);
    }
  }, [
    corporateAccountId,
    initVenueSearchAction,
    role,
    searchInitUserRole,
    searchUserId,
  ]);

  useMultiSearchData();
}
