import React, {useState} from 'react';
import styled from 'styled-components';
import {useAppSelector} from 'store/hooks';

import NW2Button from 'view/components/NW2Button';
import ExtraItem from './ExtraItem';

import {IExtrasResponse} from 'types/dto/IExtras.type';
import {IExtraState, ISummaryExtra} from 'types/dto/IBooking.types';
import {getCurrencySymbol} from 'utils/stringUtils';
import {offsetDef} from 'styles/configs/offset';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';
import {StyledExtrasList} from '../Space.styles';

type TProps = {
  availableExtras: IExtrasResponse[];
  chosenExtras: ISummaryExtra[];
  extrasState: IExtraState[];
  initMaxToShow: number;
  isFoodAndBeverage?: boolean;
  participants?: number;
  checkInDate?: string;
  checkOutDate?: string;
  onExtraChangeQuantity: (extraId: number, quantityValue: number) => void;
  isBedrooms?: boolean;
};

export const StyledButton = styled(NW2Button)`
  margin-top: ${offsetDef};
`;

const ExtrasList = ({
  availableExtras,
  chosenExtras,
  extrasState,
  initMaxToShow,
  onExtraChangeQuantity,
  isBedrooms,
}: TProps) => {
  const [isShowMore, setIsShowMore] = useState(false);

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  const {venueDetails} = useVenueDetailsData();

  const chosenExtrasOnly = availableExtras?.filter(
    (extra) =>
      chosenExtras?.some(
        ({accommodationExtraId}) =>
          accommodationExtraId === extra.accommodationExtraId,
      ) || extra.price === 0,
  );

  const noChosenExtrasOnly = availableExtras?.filter(
    (extra) =>
      !chosenExtras?.some(
        ({accommodationExtraId}) =>
          accommodationExtraId === extra.accommodationExtraId,
      ) && extra.price !== 0,
  );

  const sortedChosenExtrasOnly = chosenExtrasOnly?.sort(
    (a, b) => b.price - a.price,
  );

  const allSortedExtras = [...sortedChosenExtrasOnly, ...noChosenExtrasOnly];

  const chosenExtrasQuantity = chosenExtrasOnly?.length;

  const maxToShow =
    initMaxToShow >= chosenExtrasQuantity
      ? initMaxToShow
      : chosenExtrasQuantity;

  const isMoreMax = allSortedExtras.length > maxToShow;

  const extrasToShow =
    isMoreMax && !isShowMore
      ? allSortedExtras.slice(0, maxToShow)
      : allSortedExtras;

  const showMoreHandler = () => setIsShowMore(!isShowMore);

  return (
    <StyledExtrasList>
      {extrasToShow.map((extra) => (
        <ExtraItem
          key={`${extra.accommodationExtraId}}`}
          extra={extra}
          operationalTimes={venueDetails.operationalTimes}
          currencySymbol={getCurrencySymbol(venueDetails.currency)}
          onExtraChangeQuantity={onExtraChangeQuantity}
          extrasState={extrasState}
          chosenExtras={chosenExtras}
          isBedrooms={isBedrooms}
        />
      ))}
      {isMoreMax && !isShowMore && (
        <StyledButton
          size='responsiveAdaptive'
          buttonType='tertiary'
          onClick={showMoreHandler}
          fullWidth={isMobile}
        >
          Show more options
        </StyledButton>
      )}
    </StyledExtrasList>
  );
};

export default ExtrasList;
