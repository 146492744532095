import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-final-form';
import styled from 'styled-components';
import _get from 'lodash/get';

import NW2Button from 'view/components/NW2Button';

import {PATH_TO_REDUCER_GLOBAL_VENUE_DATA} from 'constants/venue';
import {clearAdyenOnBoardingErrorList} from 'store/venue/actions';

import {Group} from 'styles/basicStyledComponents/group';
import {whiteColor} from 'styles/configs/colors';
import {offsetDef, offsetXXLg} from 'styles/configs/offset';
import {headerZIndex, sectionBoxShadow} from 'styles/configs/variables';

const FromActionWrapper = styled(Group)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${headerZIndex};

  padding: ${offsetDef} ${offsetXXLg};

  background: ${whiteColor};
  box-shadow: ${sectionBoxShadow};
`;

type TProps = {
  onEditorHide: () => void;
  isFormSending: boolean;
  isFormDirty: boolean;
  initialValues: Record<string, unknown>;
};

export default function FormSectionActionButtons({
  onEditorHide,
  isFormSending,
  isFormDirty,
  initialValues,
}: TProps) {
  const form = useForm();
  const dispatch = useDispatch();

  const hasMissingFields: boolean = useSelector((state) =>
    _get(
      state,
      `${PATH_TO_REDUCER_GLOBAL_VENUE_DATA}.missingBusinessInfo.hasMissingFields`,
    ),
  );

  const adyenOnBoardingErrorList = useSelector((state) =>
    _get(
      state,
      `${PATH_TO_REDUCER_GLOBAL_VENUE_DATA}.adyenOnBoardingErrorList`,
    ),
  );

  const hasAdyenOnBoardingErrors = adyenOnBoardingErrorList.length > 0;

  const handleOnCancel = () => {
    onEditorHide();
    if (hasMissingFields) {
      form.reset(initialValues);
    }
    if (hasAdyenOnBoardingErrors) {
      dispatch(clearAdyenOnBoardingErrorList());
    }
  };

  return (
    <FromActionWrapper justify='flex-end'>
      <NW2Button
        buttonType='secondary'
        onClick={handleOnCancel}
        size='responsiveAdaptive'
        data-testid='form-cancel-button'
      >
        Cancel
      </NW2Button>
      <NW2Button
        type='submit'
        buttonType='primary'
        loading={isFormSending}
        disabled={!isFormDirty}
        size='responsiveAdaptive'
        data-testid='form-save-button'
      >
        Save changes
      </NW2Button>
    </FromActionWrapper>
  );
}
