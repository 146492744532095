import styled from 'styled-components';

import {
  lgBp,
  mdBp,
  offsetDef,
  offsetSm,
  offsetXLg,
  whiteColor,
} from 'constants/styleVars';
import {SEARCH_FORM_LEFT_PADDING} from './constants';

export const Container = styled.div<{minimized: boolean}>`
  ${({minimized}) =>
    minimized &&
    `display: flex;
     align-items: center;
    `};
`;

export const Content = styled.div<{minimized: boolean}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  border-radius: 10px;
  background-color: ${whiteColor};
  padding: ${offsetDef} ${SEARCH_FORM_LEFT_PADDING}; // is also used by search predictions dropdown!
  margin-bottom: ${offsetDef};
  width: 100%;
  transition: all 0.4s;
  position: relative;

  @media (min-width: ${lgBp}px) {
    & > div {
      display: flex;
    }
  }

  ${({minimized}) =>
    minimized &&
    `padding: ${offsetSm} ${offsetXLg};
         margin-bottom: 0;
     
         & > div {
          margin-bottom: 0;
          padding-bottom: 0;

          &:first-of-type {
            border: none;
            padding-right: 0;
            padding-bottom: 0;
            margin-bottom: 0;
            display: flex;
          }

        label {
          display: none
        }
     }`};
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-gap: ${offsetDef};
  grid-template-columns: 1fr;

  & > button:last-of-type {
    grid-row: 1;
  }

  @media (min-width: ${mdBp}px) {
    grid-template-columns: repeat(2, max-content);

    & > button:last-of-type {
      grid-row: auto;
    }
  }
`;
