import React, {ReactNode, useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _get from 'lodash/get';
import {useFormState} from 'react-final-form';
import {useAppSelector} from 'store/hooks';

import Icon from 'view/components/Icon';
import NW2ModalDrawer from 'view/components/NW2ModalDrawer';
import NW2SummaryBlock from 'view/components/NW2SummaryBlock';

import {LinkTerms} from 'view/components/NW2SummaryBlock/components/LinkTerms';
import {useBookingSummaryModal} from 'view/venue/components/NW2BookingSummary/hooks';
import {setTotalAmount, setTotalDrawerVisible} from 'store/venues/actions';
import {
  EAccommodationType,
  IDayResponse,
  IExtraResponse,
} from 'types/dto/IPublicVenue';
import {
  formatFloatWithOneNumber,
  getFilteredUnitsByEventType,
} from 'utils/venueUtils';
import {getCurrencySymbol} from 'utils/stringUtils';
import {ButtonConfirm} from 'view/venue/components/NW2BookingSummary/components/ButtonConfirm';
import {
  IPreviewUnitResponse,
  TGroupedUnitsByDay,
} from 'types/dto/IBooking.types';

import {IOfferRequestDay, IOfferUnitExtra, IRequestDay} from 'types/offer';
import {useGroupRequest} from 'view/venue/hooks/useGroupRequest';
import {useLoggedInUser} from 'hooks/useLoggedInUser';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';
import {getPreviewTotalPrice} from 'view/venue/NW2BookingPreview/components/NW2BookingTotal/helpers';
import {
  ExtrasCount,
  FooterMobile,
  FooterMobileLeft,
  FooterMobilePrice,
  FooterMobilePriceCost,
  FooterMobilePriceTitle,
  FooterMobileRight,
  FooterMobileTitle,
  IconContainer,
} from './OfferRequestTotal.styles';
import {useRequestSummaryContent} from '../hooks/useRequestSummaryContent';
import {
  EBookableWith,
  EResourcesCode,
  EResourcesType,
  IExtrasOption,
} from 'types/dto/IExtras.type';
import {IOfferRequestUnitExtra} from '../../OfferRequestReviewUnitCard/types';
import {moveToEndWhere} from 'view/venue/helpers/spaces';
import {useSelectedRoomId} from 'view/venue/hooks/useSelectedRoomId';

const CART_INDICATOR_MAX_COUNT = 9;

interface IProps {
  checkInDate?: string;
  checkOutDate?: string;
  currency: string;
  isTotalSticky: boolean;
  venueAddress: string;
  venueName: string;
  venueCoverImage: {
    url: string;
    name: string;
  };
  linkTermsText?: string;
  btnSubmitText?: string;
  isButtonSubmitDesktopHidden?: boolean;
  isIconCartHidden?: boolean;
  isOfferPreview?: boolean;
  isOfferRequest?: boolean;
  customMobileButtons?: ReactNode; // custom actions for opened drawer state
  totalPrice?: number;
  preArrivals?: IOfferRequestDay[];
  postEvents?: IOfferRequestDay[];
  isAgentRole?: boolean;
  isGroupRequest?: boolean;
}

export function OfferRequestTotal({
  currency,
  isTotalSticky,
  venueCoverImage,
  venueName,
  venueAddress,
  linkTermsText,
  btnSubmitText,
  isButtonSubmitDesktopHidden,
  isIconCartHidden,
  customMobileButtons,
  isOfferRequest,
  isGroupRequest,
  totalPrice,
  isOfferPreview,
  preArrivals,
  postEvents,
  isAgentRole,
}: IProps) {
  const dispatch = useDispatch();

  const {isLoggedInUser} = useLoggedInUser();
  const {isTotalGroupRequest, isSingleRegisteredVenue, isMultiRequest} =
    useGroupRequest();

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const previewUnits: TGroupedUnitsByDay[] = useSelector((state) =>
    _get(state, `venuesReducer.bookingPreview.bookingMaskPreviewUnits`, []),
  );
  const isDrawerShowed: boolean = useSelector((state) =>
    _get(state, 'venuesReducer.bookingPreview.isTotalDrawerVisible'),
  );
  const offerDetails = useAppSelector(({offers}) => offers.offerDetails);

  const participants = useAppSelector(
    ({search}) => search.searchCriteria.meetingRoomCapacity,
  );

  const {venueDetails} = useVenueDetailsData();
  const accommodationType = venueDetails.accommodationType;

  const formState = useFormState();
  const {submitting} = formState;

  const getPreviewExtras = useCallback(() => {
    const extras: IExtraResponse[] = [];
    previewUnits.forEach((venueUnit: TGroupedUnitsByDay) => {
      venueUnit.units.forEach((unit: IPreviewUnitResponse) => {
        unit.chosenExtras.forEach((extra) => {
          extras.push(extra);
        });
      });
    });
    return extras;
  }, [previewUnits]);

  const getPreviewTotalPriceMemo = useCallback(
    () => totalPrice ?? getPreviewTotalPrice(previewUnits, participants),
    [previewUnits, totalPrice, participants],
  );
  // hide price when CORPORATE_OFFICE
  const isOffice: boolean =
    accommodationType === EAccommodationType.CORPORATE_OFFICE;
  const extras = getPreviewExtras();
  const extrasCount = extras.length;
  const cartExtrasValue =
    extrasCount > CART_INDICATOR_MAX_COUNT
      ? `${CART_INDICATOR_MAX_COUNT}+`
      : extrasCount;

  const totalAmount = getPreviewTotalPriceMemo();
  const longSumValue = totalAmount > 999;
  const totalSum = useMemo((): string => {
    const totalAmountText = formatFloatWithOneNumber(+totalAmount.toFixed(2));

    return `${totalAmount ? totalAmountText : '-'} ${getCurrencySymbol(
      currency,
    )}`;
  }, [currency, totalAmount]);

  useEffect(() => {
    dispatch(setTotalAmount({value: totalAmount, currency}));
  }, [dispatch, totalAmount, currency]);

  const onToggleDrawer = useCallback(() => {
    dispatch(setTotalDrawerVisible(!isDrawerShowed));
  }, [dispatch, isDrawerShowed]);

  // modals
  const {isModalShowed, modalTitle, modalBody, onModalClose, onModalShow} =
    useBookingSummaryModal();

  const termsText = linkTermsText || 'By proceeding you accept HRS NewWork ';

  const getOfferRequestUnitExtras = useCallback(
    (unitExtras: (IOfferUnitExtra | IExtraResponse)[]) => {
      const list = unitExtras.map((extra) => {
        const {quantity, price, bookableWith, isEnabled} =
          extra as IExtraResponse;

        const totalPrice =
          !isEnabled || !bookableWith.includes(EBookableWith.MEETING_ROOM)
            ? null
            : !price
            ? 0
            : price * quantity;

        return {
          ...extra,
          quantity,
          totalPrice,
          currencySymbol: currency,
        };
      }) as unknown as IOfferRequestUnitExtra[];
      // .filter((extra) => extra?.type === type) as IOfferRequestUnitExtra[];

      return moveToEndWhere(list, 'code', EResourcesCode.WIFI);
    },
    [currency],
  );

  const extrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );
  const getUnitExtrasOfferPreview = useCallback(
    (
      unitExtras: (IOfferUnitExtra | IExtraResponse)[],
      type: EResourcesType,
    ) => {
      const list = (unitExtras as IOfferUnitExtra[])
        .map(({code, quantity, totalPrice}) => {
          const extra = extrasOption.find((extra) => extra.code === code);

          if (extra) {
            return {
              ...extra,
              quantity,
              totalPrice: totalPrice === null ? null : totalPrice?.value,
              currencySymbol: totalPrice?.currency,
            };
          }
        })
        .filter((extra) => extra?.type === type) as IOfferRequestUnitExtra[];

      return moveToEndWhere(list, 'code', EResourcesCode.WIFI);
    },
    [extrasOption],
  );

  const [getSelectedVenueUnitId] = useSelectedRoomId();

  const {headerSection, unitsRequestSection, totalPriceSection} =
    useRequestSummaryContent({
      currency,
      requestedDays: isOfferPreview
        ? getFilteredUnitsByEventType(offerDetails.days)
        : (
            getFilteredUnitsByEventType(venueDetails.days) as IDayResponse[]
          ).map(
            ({
              bedrooms,
              checkIn,
              checkOut,
              eventType,
              foodAndBeverage,
              rooms,
            }) => {
              const items = rooms.flatMap(({units}) => {
                const selectedVenueUnitId = getSelectedVenueUnitId({
                  venueId: venueDetails.accommodationId,
                  checkIn,
                });

                return units.filter(({isOptimalPrice, unitId}) =>
                  selectedVenueUnitId
                    ? unitId === selectedVenueUnitId
                    : isOptimalPrice,
                );
              });

              return {
                bedrooms,
                checkIn,
                checkOut,
                eventType,
                foodAndBeverage,
                items,
              } as unknown as IRequestDay;
            },
          ),
      isTotalPriceVisible:
        isSingleRegisteredVenue ||
        isOfferPreview ||
        (isTotalGroupRequest && (!isOffice || !!totalPrice)),
      totalPrice: totalAmount,
      venueAddress,
      venueName,
      venueCoverImage,
      preArrivals,
      postEvents,
      onModalShow,
      getUnitExtras: isOfferPreview
        ? getUnitExtrasOfferPreview
        : getOfferRequestUnitExtras,
      isAlternative: isMultiRequest && !isOfferPreview,
      isOfferRequest,
      isGroupRequest,
      isOfferPreview,
    });

  const buttonConfirm = (
    <ButtonConfirm
      isRfp={false}
      isMobile={isMobile}
      submitting={submitting}
      isOffice={isOffice}
      isDrawerShowed={isDrawerShowed}
      extrasCount={extrasCount}
      btnText={btnSubmitText}
      isOfferRequest={isOfferRequest}
      isOfferPreview={isOfferPreview}
      isLoggedInUser={isLoggedInUser}
    />
  );

  const hasCart = !isIconCartHidden && !!extrasCount;

  return (
    <>
      <NW2SummaryBlock
        isSticky={isTotalSticky}
        isMobile={isMobile}
        isDrawerShowed={isDrawerShowed}
        setIsDrawerShowed={onToggleDrawer}
        headerSection={headerSection}
        totalPriceSection={
          <>
            {!!totalPrice && totalPriceSection}
            {!isOffice && isMobile && <LinkTerms text={termsText} />}
          </>
        }
        unitsSection={unitsRequestSection}
        linkTermsText={termsText}
        buttonConfirm={!isButtonSubmitDesktopHidden && buttonConfirm}
        footerMobileContent={
          !totalPrice ? (
            buttonConfirm
          ) : (
            <FooterMobile
              hasCart={hasCart}
              isOfferPreview={isOfferPreview}
              hasCustomActions={!!customMobileButtons && isDrawerShowed}
            >
              {hasCart && (
                <IconContainer>
                  <ExtrasCount
                    longExtrasCount={extrasCount > CART_INDICATOR_MAX_COUNT}
                  >
                    {cartExtrasValue}
                  </ExtrasCount>
                  <Icon icon='NW2_SHOP_CART' NW2Gray100Color />
                </IconContainer>
              )}

              {!!customMobileButtons && isDrawerShowed ? (
                customMobileButtons
              ) : (
                <>
                  <FooterMobileLeft onClick={onToggleDrawer}>
                    {!isOffice && (
                      <>
                        <FooterMobileTitle>Total price:</FooterMobileTitle>

                        <FooterMobilePrice>
                          <FooterMobilePriceCost longSum={longSumValue}>
                            {totalSum}
                          </FooterMobilePriceCost>
                          <FooterMobilePriceTitle>
                            / per stay
                          </FooterMobilePriceTitle>
                        </FooterMobilePrice>
                      </>
                    )}
                  </FooterMobileLeft>

                  {!isAgentRole && (
                    <FooterMobileRight>{buttonConfirm}</FooterMobileRight>
                  )}
                </>
              )}
            </FooterMobile>
          )
        }
      />

      <NW2ModalDrawer
        isMobile={isMobile}
        isShowed={isModalShowed}
        header={modalTitle}
        body={modalBody}
        onClose={onModalClose}
        drawerHeight='auto'
      />
    </>
  );
}
