import styled from 'styled-components';

import {
  borderColor,
  borderRadiusDef,
  cardBoxShadow,
  offsetSm,
  offsetDef,
  whiteColor,
  NW2Primary,
  formItemDropdownContainerZIndex,
} from 'constants/styleVars';

export const Dropdown = styled.ul`
  list-style: none;
  position: absolute;
  padding: 0;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  z-index: ${formItemDropdownContainerZIndex};
  background: ${whiteColor};
  border-radius: ${borderRadiusDef};
  box-shadow: ${cardBoxShadow};
`;

export const DropdownItem = styled.li<{active?: boolean}>`
  background: ${({active}) => (active ? NW2Primary : 'transparent')};
  padding: ${offsetDef};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${offsetSm};

  &:hover {
    background: ${NW2Primary};
  }

  & + & {
    border-top: 1px solid ${borderColor};
  }
`;
