import _get from 'lodash/get';

import {EAccommodationType} from 'types/dto/IPublicVenue';
import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {useAppSelector} from '../store/hooks';

const useAccommodationType = () => {
  const accommodationType: EAccommodationType = useAppSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.accommodationType`),
  );

  return accommodationType;
};

export default useAccommodationType;
