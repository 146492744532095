import React, {ReactNode} from 'react';

import CancelPolicyCard from 'view/components/CancelPolicyCard/CancelPolicyCard';
import ActionCardClosable from 'view/components/ActionCardClosable';
import Icon from 'view/components/Icon';

import {warnColor} from 'constants/styleVars';
import {ECancelPolicyInfoCardComponent} from './types';

const actionCardClosableIconProps = {
  size: 16,
  warn: true,
};

type TProps = {
  text: ReactNode;
  bgColor?: string;
  componentType?: ECancelPolicyInfoCardComponent;
};

const CancelPolicyInfoCard = ({
  text,
  bgColor,
  componentType = ECancelPolicyInfoCardComponent.CANCEL_POLICY_CARD,
}: TProps) => {
  if (!text) return null;

  return componentType ===
    ECancelPolicyInfoCardComponent.ACTION_CARD_CLOSABLE ? (
    <ActionCardClosable
      isClosable={false}
      borderColor={warnColor}
      icon='STOPWATCH'
      iconProps={actionCardClosableIconProps}
      description={text}
    />
  ) : (
    <CancelPolicyCard
      text={text}
      icon={<Icon warn icon='STOPWATCH' />}
      bgColor={bgColor}
    />
  );
};

export default CancelPolicyInfoCard;
