import React from 'react';

import NW2Button from 'view/components/NW2Button';
import Icon from 'view/components/Icon';

import {StyledButtonRequest} from '../SpacesListBlock/components/MeetingRoomTotalPrice.styles';
import {useAppSelector} from 'store/hooks';
import {ERoomType} from 'types/dto/ERoomType.type';
import {getButtonText} from '../AnchorBlock/helpers';
import {EVenueType} from 'types/dto/IPublicVenue';
import {useIsRequestForOfferBtnVisible} from 'view/venue/hooks/useIsRequestForOfferBtnVisible';

interface IProps {
  hasBedroom?: boolean;
  isOffice: boolean;
  isShortListed: boolean;
  onRedirectToBooking: () => void;
  isMultiRoomsBookingNotAvailable: boolean;
  onRequestForOfferClick: () => void;
  isBothShowed: boolean;
}
export function ActionButtons({
  hasBedroom,
  isOffice,
  isShortListed,
  onRedirectToBooking,
  isMultiRoomsBookingNotAvailable,
  onRequestForOfferClick,
  isBothShowed,
}: IProps) {
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const roomType = useAppSelector(({search}) => search.searchCriteria.roomType);
  const venueDetails = useAppSelector(({venueDetails}) => venueDetails.details);
  const venueType = venueDetails?.type;
  const selectedMeetingRoom = useAppSelector(
    ({venueDetails}) => venueDetails.selectedRoom,
  );

  const isMeetingRoom = roomType === ERoomType.MEETING_ROOM;

  const isMultiDay = (venueDetails?.days.length as number) > 1;
  const isMultiRoom = venueDetails?.days.some(({rooms}) => rooms.length > 1);

  const isSelectButtonEnabled = !isMultiDay && !isMultiRoom;

  const btnText = getButtonText({
    isMultiRoomsBookingNotAvailable,
    isSelectedMeetingRoomNotAvailable: false,
    hasBedroom,
    isMeetingRoom,
    isOffice,
    isRtb: isSelectButtonEnabled
      ? selectedMeetingRoom.isRtb
      : venueType === EVenueType.RTB,
  });

  const [isRequestForOfferBtnVisible] = useIsRequestForOfferBtnVisible({
    roomType,
    isCorporateVenue: isOffice,
  });

  const shortListBtn = (
    <StyledButtonRequest
      buttonType={isShortListed ? 'chip' : 'tertiary'}
      onClick={onRequestForOfferClick}
      icon={isShortListed && <Icon icon='NW2_TICK' transparent size={14} />}
      active={isShortListed}
      fullWidth
      noUppercase
    >
      {isShortListed ? 'Shortlisted' : 'Request for offer'}
    </StyledButtonRequest>
  );

  return isBothShowed ? (
    <>
      {isRequestForOfferBtnVisible && !isMobile && shortListBtn}

      <NW2Button
        id='GoToBooking'
        buttonType={isShortListed ? 'secondary' : 'primary'}
        onClick={onRedirectToBooking}
        disabled={isMultiRoomsBookingNotAvailable}
        inverted={isShortListed}
        fullWidth={hasBedroom}
      >
        {btnText}
      </NW2Button>
    </>
  ) : isRequestForOfferBtnVisible ? (
    shortListBtn
  ) : null;
}
