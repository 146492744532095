import {useCallback, useEffect, useMemo, useState} from 'react';

import {
  EResourcesCode,
  EResourcesType,
  IExtrasOption,
} from 'types/dto/IExtras.type';
import {
  IPreviewPrices,
  IRequestMeeting,
  TExtraPriceField,
  TGetBedroomSubtotalPrice,
} from '../types';
import {FREE} from 'constants/venue';
import {getRoomRentalPriceByDateRange} from 'utils/venueUtils';

interface IProps {
  activeUnitId: string;
  activeUnit: IRequestMeeting;
  requestUnits: IRequestMeeting[];
  priceHour: string;
  priceHalfDay: string;
  priceDay: string;
  extrasOptions: IExtrasOption[];
  equipmentPrices?: TExtraPriceField;
  cateringPrices?: TExtraPriceField;
  equipmentFirstUnitPrices?: TExtraPriceField;
  cateringFirstUnitPrices?: TExtraPriceField;
  bedroomsPrices?: TExtraPriceField;
  isMeeting: boolean;
}
export function usePreviewPrices({
  activeUnitId,
  activeUnit,
  requestUnits = [],
  extrasOptions,
  equipmentPrices = {},
  cateringPrices = {},
  bedroomsPrices = {},
  equipmentFirstUnitPrices,
  cateringFirstUnitPrices,
  priceHour,
  priceHalfDay,
  priceDay,
  isMeeting,
}: IProps): IPreviewPrices {
  const {
    checkIn,
    checkOut,
    extras = [],
    bedrooms = [],
    foodAndBeverage = [],
  } = activeUnit;

  const allExtras = useMemo(
    () => [...extras, ...bedrooms, ...foodAndBeverage],
    [bedrooms, extras, foodAndBeverage],
  );

  const [unitsSubtotal, setUnitsSubtotal] = useState<Record<string, number>>(
    {},
  );

  const [roomRentalPrices, setRoomRentalPrices] = useState<
    Record<string, number>
  >({});

  const formExtras = Object.assign(
    {},
    equipmentPrices,
    cateringPrices,
    bedroomsPrices,
  );

  const formExtrasFirstUnit = Object.assign(
    {},
    equipmentFirstUnitPrices,
    cateringFirstUnitPrices,
  );

  const isSomeExtrasPriceEmpty = useMemo(() => {
    const allEquipmentPrices = Object.values({
      ...equipmentPrices,
      ...equipmentFirstUnitPrices,
    });

    const allCateringPrices = Object.values({
      ...cateringPrices,
      ...cateringFirstUnitPrices,
    });

    return !!(
      (extras.length && allEquipmentPrices.some((price) => !price)) ||
      (foodAndBeverage.length && allCateringPrices.some((price) => !price))
    );
  }, [
    cateringFirstUnitPrices,
    cateringPrices,
    equipmentFirstUnitPrices,
    equipmentPrices,
    extras.length,
    foodAndBeverage.length,
  ]);

  const roomRentalPrice = useMemo(
    () =>
      getRoomRentalPriceByDateRange({
        priceHour,
        priceHalfDay,
        priceDay,
        checkOut,
        checkIn,
      }),
    [checkIn, checkOut, priceDay, priceHalfDay, priceHour],
  );

  const daySubtotal = useMemo(() => {
    if (isMeeting && !roomRentalPrice) return 0;

    const mappedExtras = allExtras.map((item) => {
      const extraItem = extrasOptions.find(({code}) => code === item.code);
      const isBedroom = extraItem?.type === EResourcesType.BEDROOM;
      const quantity = item.quantity;
      const formItemPrice =
        formExtrasFirstUnit[extraItem?.name || ''] ||
        formExtras[(isBedroom ? extraItem?.code : extraItem?.name) || ''];

      const isFree = formItemPrice === FREE;

      const price = isBedroom
        ? Number(bedroomsPrices[extraItem?.code])
        : formItemPrice
        ? isFree
          ? 0
          : Number(formItemPrice)
        : '';

      return {
        ...extraItem,
        quantity,
        price,
      };
    });

    if (mappedExtras.some(({price}) => !price && price !== 0)) {
      // not all extras prices filled
      return 0;
    }

    // calculation
    const paidExtras = mappedExtras.filter(({price}) => price !== 0);

    return paidExtras.reduce(
      (acc, curr) =>
        acc +
        (curr.code === EResourcesCode.WIFI
          ? Number(curr.price)
          : Number(curr.price) * curr.quantity),
      isMeeting ? roomRentalPrice : 0,
    );
  }, [
    isMeeting,
    roomRentalPrice,
    allExtras,
    extrasOptions,
    formExtrasFirstUnit,
    formExtras,
    bedroomsPrices,
  ]);

  const setRoomPrice = useCallback((id: string, price: number) => {
    setRoomRentalPrices((prev) => ({
      ...prev,
      [id]: price,
    }));
  }, []);

  useEffect(() => {
    if (!activeUnitId) return;

    setRoomPrice(activeUnitId, roomRentalPrice);
  }, [activeUnitId, roomRentalPrice, setRoomPrice]);

  useEffect(() => {
    if (!activeUnitId) return;

    if (allExtras.length) {
      setUnitsSubtotal((prev) => ({...prev, [activeUnitId]: daySubtotal}));
    } else {
      setUnitsSubtotal((prev) => ({...prev, [activeUnitId]: roomRentalPrice}));
    }
  }, [activeUnitId, allExtras.length, daySubtotal, roomRentalPrice]);

  const removeUnitSubtotalPrice = useCallback((unitId: string) => {
    setUnitsSubtotal((prev) => ({...prev, [unitId]: 0}));
  }, []);

  useEffect(() => {
    if (!isMeeting) return;

    if (
      unitsSubtotal[activeUnitId] &&
      (isSomeExtrasPriceEmpty || !roomRentalPrices[activeUnitId])
    ) {
      // reset subtotal if some extra price isn't filled
      setUnitsSubtotal((prev) => ({...prev, [activeUnitId]: 0}));
    }
  }, [
    activeUnitId,
    isMeeting,
    isSomeExtrasPriceEmpty,
    roomRentalPrices,
    unitsSubtotal,
  ]);

  const totalPrice = useMemo(() => {
    const subTotalValues = Object.values(unitsSubtotal);

    if (
      requestUnits?.length === subTotalValues.length &&
      subTotalValues.every((v) => v > 0)
    ) {
      return subTotalValues.reduce((acc, subTotal) => acc + subTotal, 0);
    }

    return 0;
  }, [requestUnits?.length, unitsSubtotal]);

  const getBedroomSubtotalPrice = useCallback(
    ({unitExtras, requestedUnitId, prices}: TGetBedroomSubtotalPrice) => {
      if (!prices) return 0;

      const pricesValues = Object.values(prices);
      if (
        pricesValues.length !== unitExtras.length ||
        pricesValues.some((price) => !price)
      )
        return 0;

      const price = Object.entries(prices).reduce((acc, [code, price]) => {
        const extraItem = unitExtras.find((extra) => extra.code === code);

        // final-form bug, it sets not existed values from prev state
        if (!extraItem) return 0;

        return acc + Number(price) * extraItem.quantity;
      }, 0);

      if (price) {
        setUnitsSubtotal((prev) => ({...prev, [requestedUnitId]: price}));
      }

      return price;
    },
    [],
  );

  return {
    roomRentalPrices,
    unitsSubtotal,
    totalPrice,
    getBedroomSubtotalPrice,
    removeUnitSubtotalPrice,
    setRoomPrice,
  };
}
