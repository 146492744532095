import {ERoomType} from 'types/dto/ERoomType.type';
import {ALLOWED_DAYS_DIFFERENCE_FOR_RFP} from 'constants/days';
import {useGetSearchDateDifference} from './useGetSearchDateDifference';

interface IProps {
  roomType: ERoomType;
  isCorporateVenue: boolean;
}
export function useIsRequestForOfferBtnVisible({
  roomType,
  isCorporateVenue,
}: IProps): [boolean] {
  const [diffInDays] = useGetSearchDateDifference();

  if (diffInDays > ALLOWED_DAYS_DIFFERENCE_FOR_RFP) return [false];

  const isRequestForOfferBtnVisible =
    roomType === ERoomType.MEETING_ROOM && !isCorporateVenue;

  return [isRequestForOfferBtnVisible];
}
