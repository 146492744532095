import axios from 'axios';
import {message} from 'antd';
import {DateTime} from 'luxon';

import LocalStorageService from 'infra/common/localStorage.service';

import {
  IAddFormErrorSection,
  IConfirmBookingFailure,
  IConfirmBookingRequest,
  IGetRegistrationLinkDataFailure,
  IGetRegistrationLinkDataRequest,
  IGetRegistrationLinkDataSuccess,
  IGetVenueDetailsFailure,
  IGetVenueDetailsRequest,
  IGetVenueDetailsSuccess,
  IRemoveFormErrorSection,
  ISearchVenuesFailure,
  ISearchVenuesRequest,
  ISearchVenuesSuccess,
  ISetDatepickerDetailsShowed,
  ISetDetailsDatepickerShowed,
  ISetDetailsNoOffersShowed,
  ISetGoogleMapRefetch,
  ISetGoogleMapVisibleRadius,
  ISetIsExtendedMeetingRoomsPopupVisible,
  ISetIsGoogleMapExpanded,
  ISetIsVenueDetailsMapVisible,
  ISetIsVenuesListOpened,
  ISetMeetingRoomSeatingPlan,
  ISetPreviewData,
  ISetSpaceDetailsId,
  ISetTotalAmount,
  ISetTotalDrawerVisible,
  ISetVenueUnitBookingStatistics,
  TPreviewData,
  TRegistrationLinkData,
  IGetMultiVenueDetailsSuccess,
  TSetExtraBookingPreviewValuePayload,
  IClearVenuesResults,
} from './types';
import {
  IPreviewUnitResponse,
  TGroupedUnitsByDay,
} from 'types/dto/IBooking.types';
import {
  IGetVenueUnitBookingStatisticsByDatesPayload,
  ISetVenueUnitBookingStatusPayload,
  IVenueUnitBookingStatisticsItem,
  TVenueUnitBookingStatistics,
} from 'types/dto/IVenueUnitBookingStatisticsByDate.type';

import {IExtraResponse, IPublicVenue} from 'types/dto/IPublicVenue';
import {ERoomType} from 'types/dto/ERoomType.type';
import {AppDispatch, RootState} from 'store/types';
import {TContainer} from 'app';
import {setMultiSearchPayload} from 'store/search/searchSlice';
import {EResourcesType} from 'types/dto/IExtras.type';
import {EFormValidationName, IVenue} from 'types/venue';
import {TAmount} from '../payment/types';
import {EIgnoreInterceptorCodes} from 'utils/axiosInterceptors/types';
import {setVenueAlreadyExists} from 'store/venue/actions';
import {getISOString} from 'utils/dateUtils';
import {ApiType, createBaseUrl} from 'infra/common/http.service';
import {
  IMergedVenue,
  IVenuesListResponse,
  TSearchCriteriaRoom,
  TSearchCriteriaRooms,
  TSearchVenuePayload,
  TSearchVenuesDay,
} from 'types/search';
import {TSelectedPackageItem} from 'types/dto/IPackages.type';
import {setVenueDetails} from 'store/venueDetails/venueDetailsSlice';

export const CLEAR_VENUE_DETAILS = 'CLEAR_VENUE_DETAILS';
export const SEARCH_VENUES_REQUEST = 'SEARCH_VENUES_REQUEST';
export const SEARCH_VENUES_SUCCESS = 'SEARCH_VENUES_SUCCESS';
export const CLEAR_VENUES_RESULT = 'CLEAR_VENUES_RESULT';
export const SEARCH_VENUES_FAILURE = 'SEARCH_VENUES_FAILURE';
export const GET_VENUE_DETAILS_REQUEST = 'GET_VENUE_DETAILS_REQUEST';
export const GET_VENUE_DETAILS_SUCCESS = 'GET_VENUE_DETAILS_SUCCESS';
export const GET_MULTI_VENUE_DETAILS_SUCCESS =
  'GET_MULTI_VENUE_DETAILS_SUCCESS';
export const GET_VENUE_DETAILS_FAILURE = 'GET_VENUE_DETAILS_FAILURE';
export const GET_BOOKING_FAILURE = 'GET_BOOKING_FAILURE';
export const CONFIRM_BOOKING_REQUEST = 'CONFIRM_BOOKING_REQUEST';
export const CONFIRM_BOOKING_FAILURE = 'CONFIRM_BOOKING_FAILURE';
export const SET_VENUE_UNIT_BOOKING_STATISTICS =
  'SET_VENUE_UNIT_BOOKING_STATISTICS';
export const SET_GOOGLE_MAP_REFETCH = 'SET_GOOGLE_MAP_REFETCH';
export const SET_GOOGLE_MAP_VISIBLE_RADIUS = 'SET_GOOGLE_MAP_VISIBLE_RADIUS';
export const SET_IS_GOOGLE_MAP_EXPANDED = 'SET_IS_GOOGLE_MAP_EXPANDED';
export const SET_IS_VENUE_DETAILS_MAP_VISIBLE =
  'SET_IS_VENUE_DETAILS_MAP_VISIBLE';
export const SET_IS_EXTENDED_MEETING_ROOMS_POPUP_VISIBLE =
  'SET_IS_EXTENDED_MEETING_ROOMS_POPUP_VISIBLE';
export const SET_IS_VENUES_LIST_OPENED = 'SET_IS_VENUES_LIST_OPENED';
export const SET_DETAILS_DATEPICKER_SHOWED = 'SET_DETAILS_DATEPICKER_SHOWED';
export const SET_SPACE_DETAILS_ID = 'SET_SPACE_DETAILS_ID';
export const SET_SEARCH_CRITERIA = 'SET_SEARCH_CRITERIA';
export const SET_DETAILS_NO_OFFERS_SHOWED = 'SET_DETAILS_NO_OFFERS_SHOWED';
export const SET_DATEPICKER_DETAILS_SHOWED = 'SET_DATEPICKER_DETAILS_SHOWED';
export const SET_MEETING_ROOM_SEATING_PLAN = 'SET_MEETING_ROOM_SEATING_PLAN';
export const SET_PREVIEW_DATA = 'SET_PREVIEW_DATA';
export const ADD_PREVIEW_ERROR_FORM_SECTION = 'ADD_PREVIEW_ERROR_FORM_SECTION';
export const SET_PREVIEW_TOTAL_DRAWER_VISIBLE =
  'SET_PREVIEW_TOTAL_DRAWER_VISIBLE';
export const REMOVE_PREVIEW_ERROR_FORM_SECTION =
  'REMOVE_PREVIEW_ERROR_FORM_SECTION';
export const SET_PREVIEW_TOTAL_AMOUNT = 'SET_PREVIEW_TOTAL_AMOUNT';
export const GET_REGISTRATION_LINK_DATA_REQUEST =
  'GET_REGISTRATION_LINK_DATA_REQUEST';
export const GET_REGISTRATION_LINK_DATA_SUCCESS =
  'GET_REGISTRATION_LINK_DATA_SUCCESS';
export const GET_REGISTRATION_LINK_DATA_FAILURE =
  'GET_REGISTRATION_LINK_DATA_FAILURE';
export const SET_OFFER_PAGE = 'SET_OFFER_PAGE';

export const confirmBookingRequest = (): IConfirmBookingRequest => ({
  type: CONFIRM_BOOKING_REQUEST,
});

export const confirmBookingFailure = (
  payload: string | null,
): IConfirmBookingFailure => ({
  type: CONFIRM_BOOKING_FAILURE,
  payload,
});

export const clearVenueDetails = () => ({
  type: CLEAR_VENUE_DETAILS,
});

export const getVenueDetailsRequest = (): IGetVenueDetailsRequest => ({
  type: GET_VENUE_DETAILS_REQUEST,
});

export const getVenueDetailsSuccess = (payload: {
  response: IVenue;
  isMultiRooms: boolean;
}): IGetVenueDetailsSuccess => ({
  type: GET_VENUE_DETAILS_SUCCESS,
  payload,
});

export const getMultiVenueDetailsSuccess = (payload: {
  response: any[];
}): IGetMultiVenueDetailsSuccess => ({
  type: GET_MULTI_VENUE_DETAILS_SUCCESS,
  payload,
});

export const getVenueDetailsFailure = (
  payload: string,
): IGetVenueDetailsFailure => ({
  type: GET_VENUE_DETAILS_FAILURE,
  payload,
});

export const searchVenuesRequest = (): ISearchVenuesRequest => ({
  type: SEARCH_VENUES_REQUEST,
});

export const searchVenuesSuccess = (
  payload: IVenuesListResponse,
): ISearchVenuesSuccess => ({
  type: SEARCH_VENUES_SUCCESS,
  payload,
});

export const clearVenuesResults = (): IClearVenuesResults => ({
  type: CLEAR_VENUES_RESULT,
});

export const searchVenuesFailure = (
  payload: string | null,
): ISearchVenuesFailure => ({
  type: SEARCH_VENUES_FAILURE,
  payload,
});

export const getBookingFailure = (payload: string): any => ({
  type: GET_BOOKING_FAILURE,
  payload,
});

export interface IVenueDetailsData {
  id: string | number;
  startDate: string;
  endDate: string;
  roomType: ERoomType;
  latitude?: string;
  longitude?: string;
  limit?: number;
  meetingRoomCapacity?: number;
  page?: number;
  searchString?: string;
  multiRooms?: boolean;
  filterDays?: TSearchVenuesDay[];
}

export interface IMultiDayVenueDetailsRequestData {
  accommodationIds: number[];
  filterData: TSearchVenuesDay[];
  withoutPackages?: boolean;
}

export interface IVenueDetailsDataWithDays extends IVenueDetailsData {
  days?: TSearchCriteriaRooms[];
}

export const setVenueUnitBookingStatistics = (
  payload: TVenueUnitBookingStatistics,
): ISetVenueUnitBookingStatistics => ({
  type: SET_VENUE_UNIT_BOOKING_STATISTICS,
  payload,
});

export const setGoogleMapRefetch = (
  payload: boolean,
): ISetGoogleMapRefetch => ({
  type: SET_GOOGLE_MAP_REFETCH,
  payload,
});

export const setGoogleMapVisibleRadius = (
  payload: number,
): ISetGoogleMapVisibleRadius => ({
  type: SET_GOOGLE_MAP_VISIBLE_RADIUS,
  payload,
});

export const setSpaceDetailsId = (
  payload: number | null,
): ISetSpaceDetailsId => ({
  type: SET_SPACE_DETAILS_ID,
  payload,
});

export const setIsGoogleMapExpanded = (
  payload: boolean,
): ISetIsGoogleMapExpanded => ({
  type: SET_IS_GOOGLE_MAP_EXPANDED,
  payload,
});

export const setIsVenueDetailsMapVisible = (
  payload: boolean,
): ISetIsVenueDetailsMapVisible => ({
  type: SET_IS_VENUE_DETAILS_MAP_VISIBLE,
  payload,
});

export const setIsExtendedMeetingRoomsPopupVisible = (
  payload: boolean,
): ISetIsExtendedMeetingRoomsPopupVisible => ({
  type: SET_IS_EXTENDED_MEETING_ROOMS_POPUP_VISIBLE,
  payload,
});

export const setIsVenuesListOpened = (
  payload: boolean,
): ISetIsVenuesListOpened => ({
  type: SET_IS_VENUES_LIST_OPENED,
  payload,
});

export const setDetailsDatepickerMobileShowed = (
  payload: boolean,
): ISetDetailsDatepickerShowed => ({
  type: SET_DETAILS_DATEPICKER_SHOWED,
  payload,
});

export const setVenueDetailsNoOffersShowed = (
  payload: boolean,
): ISetDetailsNoOffersShowed => ({
  type: SET_DETAILS_NO_OFFERS_SHOWED,
  payload,
});

export const setMeetingRoomSeatingPlan = (
  payload: string,
): ISetMeetingRoomSeatingPlan => ({
  type: SET_MEETING_ROOM_SEATING_PLAN,
  payload,
});

export const searchVenuesAction =
  (data: TSearchVenuePayload) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {venuesContainer}: TContainer,
  ): Promise<void> => {
    dispatch(searchVenuesRequest());

    const corporateAccountId = getState().app.corporateAccountId;
    const payload = {
      ...data,
      corporateAccountId,
    };

    venuesContainer.searchAndCalculate({
      payload,
      onSuccess: (response: IVenuesListResponse) => {
        dispatch(searchVenuesSuccess(response));
        dispatch(setMultiSearchPayload(payload.filterDays || []));
      },
      onError: (error: any) => dispatch(searchVenuesFailure(error)),
    });
  };

export const getPublicVenue =
  (payload: {id: string | null; isMultiRooms: boolean}): any =>
  (dispatch: any, _: unknown, {venueContainer}: TContainer): void => {
    const {id, isMultiRooms} = payload;
    dispatch(getVenueDetailsRequest());
    venueContainer.getPublicVenueById({
      payload: id,
      onSuccess: (response: any) => {
        dispatch(
          getVenueDetailsSuccess({
            response,
            isMultiRooms,
          }),
        );
      },
      onError: () => {},
    });
  };

export const setMultiRoomUnitDetails = (
  filterHits: TSearchCriteriaRoom[] = [],
) =>
  filterHits.map(
    ({
      // eventType,
      capacity,
      setupStyle,
      extras,
      unitId,
    }) => ({
      // [EEventType.POST_EVENT, EEventType.PRE_ARRIVAL].includes(eventType)
      ...(unitId
        ? {} // we don't need to set up unitId for pre- & post- arrivals
        : {unitId}), // todo check
      // eventType,
      unitFilter: {
        capacity,
        setupStyle,
        extras,
      },
    }),
  );

// export const getMultiDayVenueDetailsAction =
//   (data: IMultiDayVenueDetailsRequestData) =>
//   (
//     dispatch: AppDispatch,
//     getState: () => RootState,
//     {venuesContainer}: TContainer,
//   ) => {
//     dispatch(getVenueDetailsRequest());
//
//     venuesContainer.getMultiDayVenueDetails({
//       payload: data,
//       onSuccess: (response: IMultiDayPublicVenue) => {
//         dispatch(getMultiDayVenueDetailsSuccess(response));
//       },
//       onError: (error: any) => {
//         const errorMessage =
//           error?.data?.reasonCode ||
//           error?.message ||
//           'Failed to fetch venue data!';
//         dispatch(getVenueDetailsFailure(errorMessage));
//       },
//     });
//   };

export const getVenueDetailsAction =
  (
    data: IVenueDetailsData & {
      isAllSpaces?: boolean;
      days?: TSearchCriteriaRooms[];
    },
    isMultiRooms: boolean,
  ) =>
  (
    dispatch: AppDispatch,
    getState: () => RootState,
    {venuesContainer}: TContainer,
  ) => {
    dispatch(getVenueDetailsRequest());

    const state = getState();

    //TODO: change to IMergedVenue[]
    // @ts-ignore
    const venueGlobalList: IPublicVenue[] = state.venuesReducer.venueGlobalList;

    const venue = venueGlobalList.find(
      (item) => Number(item.accommodationId) === Number(data.id),
    );
    const locallySavedMultiRoomPayload = LocalStorageService.getByKey(
      'multiVenueOfferPayload',
    );

    if (data.multiRooms) {
      const multiRoomPayload =
        !venue && locallySavedMultiRoomPayload
          ? JSON.parse(locallySavedMultiRoomPayload)
          : {
              accommodationId: data.id,
              units: setMultiRoomUnitDetails(venue?.filterHits),
            };

      LocalStorageService.setByKey(
        'multiVenueOfferPayload',
        JSON.stringify(multiRoomPayload),
      );

      venuesContainer.getMultiSearchVenueDetails({
        payload: multiRoomPayload,
        onSuccess: (response: IVenue) => {
          dispatch(getVenueDetailsSuccess({response, isMultiRooms}));
        },
        onError: (error: any) => {
          const errorMessage =
            error?.data?.reasonCode ||
            error?.message ||
            'Failed to fetch venue data!';
          dispatch(getVenueDetailsFailure(errorMessage));
        },
      });
    }
  };

interface IGetMultiVenueDetailsAction {
  data: IVenueDetailsDataWithDays[];
  getSelectedVenueUnitId: (props: {
    venueId: number;
    checkIn: string;
  }) => number | undefined;
  withoutPackages?: boolean;
}

export const getMultiVenueDetailsAction =
  ({
    data,
    getSelectedVenueUnitId,
    withoutPackages = true,
  }: IGetMultiVenueDetailsAction) =>
  (
    dispatch: AppDispatch,
    getState: () => RootState,
    {venuesContainer}: TContainer,
  ) => {
    dispatch(getVenueDetailsRequest());

    const state = getState();

    const locallySavedMultiVenuesPayload =
      LocalStorageService.getByKey('multiVenuesPayload');

    const multiRoomPayload = data.reduce(
      (
        result: (IMergedVenue | IMultiDayVenueDetailsRequestData)[],
        item: IVenueDetailsDataWithDays,
      ) => {
        const findVenue = (venue: IMergedVenue) =>
          Number(venue.accommodationId) === Number(item.id);
        const findVenueFromLocalStorage = (
          venue: IMultiDayVenueDetailsRequestData,
        ) => {
          if (venue) {
            return Number(venue.accommodationIds[0]) === Number(item.id);
          }
        };

        const venueGlobalList: IMergedVenue[] =
          // @ts-ignore
          state.venuesReducer.venueGlobalList;
        const venue = venueGlobalList.find(findVenue);

        const parsedDataFromLocalStorage =
          locallySavedMultiVenuesPayload &&
          JSON.parse(locallySavedMultiVenuesPayload);
        const venueFromLocalStorage = parsedDataFromLocalStorage?.find(
          findVenueFromLocalStorage,
        );

        // @ts-ignore
        const filterDays: TSearchVenuesDay[] = state.venuesReducer.filterDays;

        // When singleRoom was selected on venueDetails
        const updatedFilterDays = filterDays.map((day) => ({
          ...day,
          roomFilters: day.roomFilters?.map((room) => {
            const selectedVenueUnitId = getSelectedVenueUnitId({
              venueId: +item.id,
              checkIn: room.checkIn,
            });

            return {
              ...room,
              ...(selectedVenueUnitId ? {unitId: selectedVenueUnitId} : {}),
            };
          }),
        }));

        return [
          ...result,
          !venue && locallySavedMultiVenuesPayload
            ? {...venueFromLocalStorage, withoutPackages}
            : {
                accommodationIds: [item.id],
                filterData: venue ? updatedFilterDays : [],
                withoutPackages,
              },
        ];
      },
      [],
    );

    LocalStorageService.setByKey(
      'multiVenuesPayload',
      JSON.stringify(multiRoomPayload),
    );

    venuesContainer.getMultiDayMultiVenueDetails({
      payload: multiRoomPayload,
      onSuccess: async (data) => {
        Promise.all(data).then((result) => {
          dispatch(getMultiVenueDetailsSuccess({response: result}));
        });
      },
      onError: (error: any) => {
        const errorMessage =
          error?.data?.reasonCode ||
          error?.message ||
          'Failed to fetch venue data!';
        dispatch(getVenueDetailsFailure(errorMessage));
      },
    });
  };

export const getMultiVenueDetailsMultiRequest =
  (data: IMultiDayVenueDetailsRequestData[]) =>
  (
    dispatch: AppDispatch,
    _: () => RootState,
    {venuesContainer}: TContainer,
  ) => {
    dispatch(getVenueDetailsRequest());

    venuesContainer.getMultiDayMultiVenueDetails({
      payload: data,
      onSuccess: async (data) => {
        Promise.all(data).then((result) => {
          //setting single venue details for UI creation
          dispatch(setVenueDetails(result[0]));
          dispatch(getMultiVenueDetailsSuccess({response: result}));
        });
      },
      onError: (error: any) => {
        const errorMessage =
          error?.data?.reasonCode ||
          error?.message ||
          'Failed to fetch venue data!';
        dispatch(getVenueDetailsFailure(errorMessage));
      },
    });
  };

export const removeMultiVenueDetailsItem =
  (venueId: number | string) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();

    const filterItems = <T extends {accommodationId: number}>({
      accommodationId,
    }: T) => {
      return accommodationId !== venueId;
    };

    const updatedMultiVenueDetails =
      // @ts-ignore
      state.venuesReducer.multiVenueDetails.filter(filterItems);

    dispatch(
      getMultiVenueDetailsSuccess({
        response: updatedMultiVenueDetails,
      }),
    );
  };

export const getVenueUnitBookingStatisticsByDates =
  (payload: IGetVenueUnitBookingStatisticsByDatesPayload): any =>
  (dispatch: any, _: unknown, {venuesContainer}: TContainer): void => {
    const {requestDate, venueId} = payload;
    const FROM_DATE_OFFSET = 7; // previous month days
    const TO_DATE_OFFSET = 14; // next month days
    const daysInMonth = DateTime.fromISO(requestDate).daysInMonth || 30;
    const selectedDate = DateTime.fromISO(requestDate).day;
    const fromDate = getISOString(
      DateTime.fromISO(requestDate).plus({
        days: 1 - selectedDate - FROM_DATE_OFFSET,
      }),
    );
    const toDate = getISOString(
      DateTime.fromISO(requestDate).plus({
        days: daysInMonth - selectedDate + TO_DATE_OFFSET,
      }),
    );
    const requestData = {
      venueId,
      from: fromDate,
      to: toDate,
    };
    venuesContainer.getVenueUnitBookingStatisticsByDates({
      payload: requestData,
      onSuccess: (data: IVenueUnitBookingStatisticsItem[] = []) => {
        const itemMap = data.reduce(
          (
            acc: TVenueUnitBookingStatistics,
            item: IVenueUnitBookingStatisticsItem,
          ) => {
            acc[item.date] = item;
            return acc;
          },
          {},
        );
        dispatch(setVenueUnitBookingStatistics(itemMap));
      },
      onError: () => {
        message.error({
          type: 'error',
          content: 'Oops! Something has gone wrong!',
          key: 'errorMessage',
        });
      },
    });
  };

export const setVenueUnitBookingStatus =
  (actionPayload: ISetVenueUnitBookingStatusPayload): any =>
  (dispatch: any, _: unknown, {venuesContainer}: TContainer): void => {
    venuesContainer.setVenueUnitBookingStatus({
      payload: actionPayload,
      onSuccess: () => {
        const {
          venueId,
          payload: {date},
        } = actionPayload;
        dispatch(
          getVenueUnitBookingStatisticsByDates({
            requestDate: date || getISOString(DateTime.now()),
            venueId,
          }),
        );
      },
      onError: () => {
        message.error({
          type: 'error',
          content: 'Oops! Something has gone wrong!',
          key: 'errorMessage',
        });
      },
    });
  };

export const setDatepickerDetailsShowed = (
  payload: boolean,
): ISetDatepickerDetailsShowed => ({
  type: SET_DATEPICKER_DETAILS_SHOWED,
  payload,
});

export const setBookingPreview = (payload: TPreviewData): ISetPreviewData => ({
  type: SET_PREVIEW_DATA,
  payload,
});

const updateSelectedExtras = (
  extra: IExtraResponse,
  allExtras: IExtraResponse[],
) => {
  const isUpdateExistedValue = allExtras.some(
    (item) => item.code === extra.code,
  );
  return isUpdateExistedValue
    ? allExtras
        .map((chosenExtra) => {
          if (extra.code !== chosenExtra.code) return chosenExtra;
          return extra;
        })
        .filter((item) => item.quantity)
    : // .filter((item) => item.chosenQuantity)
      [...allExtras, extra];
};

export const updateBookingPreview =
  (payload: TSetExtraBookingPreviewValuePayload) =>
  (dispatch: AppDispatch, getState: any) => {
    const bookingPreviewUnits: TGroupedUnitsByDay[] =
      getState().venuesReducer.bookingPreview.bookingMaskPreviewUnits;
    const deskPreviewUnits: TGroupedUnitsByDay[] =
      getState().venuesReducer.bookingPreview.deskPreviewUnits;

    const previewUnitsState: TGroupedUnitsByDay[] = bookingPreviewUnits?.length
      ? bookingPreviewUnits
      : deskPreviewUnits;

    const {dayId, unitId, extra, checkInDate, checkOutDate} = payload;
    const isFoodAndBeverage =
      extra.extraType === EResourcesType.FOOD_AND_BEVERAGE;

    const shouldUpdateUnit = (unit: IPreviewUnitResponse) => {
      return (
        !isFoodAndBeverage &&
        unit.unitId === unitId &&
        unit.checkInDate === checkInDate &&
        unit.checkOutDate === checkOutDate
      );
    };

    const updateUnitChosenExtras = (unit: IPreviewUnitResponse) => {
      const chosenExtras = updateSelectedExtras(
        extra,
        unit.chosenExtras || unit.extras,
      );
      // filter chosenExtras if index is greater than 0 and extraType is not FOOD_AND_BEVERAGE
      const filteredChosenExtras = chosenExtras.filter(({extraType}) => {
        return extraType !== EResourcesType.FOOD_AND_BEVERAGE;
      });

      return {
        ...unit,
        chosenExtras: filteredChosenExtras,
      };
    };

    const bookingMaskPreviewUnits = previewUnitsState.map(
      ({foodAndBeverage, units, ...item}, index) => {
        if (index !== dayId) return {foodAndBeverage, units, ...item};

        const updatedUnits = units.map((unit) =>
          shouldUpdateUnit(unit) ? updateUnitChosenExtras(unit) : unit,
        );

        const updatedFoodAndBeverage = isFoodAndBeverage
          ? updateSelectedExtras(extra, foodAndBeverage)
          : foodAndBeverage;

        return {
          foodAndBeverage: updatedFoodAndBeverage,
          units: updatedUnits,
          ...item,
          unitBookings: updatedUnits,
        };
      },
    );

    dispatch(setBookingPreview({bookingMaskPreviewUnits}));
  };

export const addPackageBookingPreview =
  () => (dispatch: AppDispatch, getState: any) => {
    const state = getState();
    const previewUnitsState: TGroupedUnitsByDay[] =
      state.venuesReducer.bookingPreview.bookingMaskPreviewUnits;
    const selectedPackages = state.customer.customerSelectedPackages;
    const bookingMaskPreviewUnits = previewUnitsState.map((unit, index) => ({
      ...unit,
      selectedPackage: selectedPackages?.[index],
    }));
    dispatch(setBookingPreview({bookingMaskPreviewUnits}));
  };

export const deletePackageBookingPreview =
  (dayIndex: number, unitId: number) =>
  (dispatch: AppDispatch, getState: any) => {
    const previewUnitsState: TGroupedUnitsByDay[] =
      getState().venuesReducer.bookingPreview.bookingMaskPreviewUnits;
    const customerSelectedPackages =
      getState().customer.customerSelectedPackages;
    const bookingMaskPreviewUnits = previewUnitsState.map((unit, index) => {
      return dayIndex === index
        ? {
            ...unit,
            selectedPackage: customerSelectedPackages[index].map(
              ([id, packageData]: TSelectedPackageItem) =>
                id === unitId ? [id, null] : [id, packageData],
            ),
          }
        : unit;
    });
    dispatch(setBookingPreview({bookingMaskPreviewUnits}));
  };

export const addErrorFormSection = (
  payload: EFormValidationName,
): IAddFormErrorSection => ({
  type: ADD_PREVIEW_ERROR_FORM_SECTION,
  payload,
});

export const removeErrorFormSection = (
  payload: EFormValidationName,
): IRemoveFormErrorSection => ({
  type: REMOVE_PREVIEW_ERROR_FORM_SECTION,
  payload,
});

export const setTotalDrawerVisible = (
  payload: boolean,
): ISetTotalDrawerVisible => ({
  type: SET_PREVIEW_TOTAL_DRAWER_VISIBLE,
  payload,
});

export const setTotalAmount = (payload: TAmount): ISetTotalAmount => ({
  type: SET_PREVIEW_TOTAL_AMOUNT,
  payload,
});

//hmd onboarding link
export const getRegistrationLinkDataRequest =
  (): IGetRegistrationLinkDataRequest => ({
    type: GET_REGISTRATION_LINK_DATA_REQUEST,
  });

export const getRegistrationLinkDataSuccess = (
  payload: TRegistrationLinkData,
): IGetRegistrationLinkDataSuccess => ({
  type: GET_REGISTRATION_LINK_DATA_SUCCESS,
  payload,
});

export const getRegistrationLinkDataFailure = (
  payload: string,
): IGetRegistrationLinkDataFailure => ({
  type: GET_REGISTRATION_LINK_DATA_FAILURE,
  payload,
});

export const getHMDRegistrationLink =
  ({
    accommodationId,
    isMultiRooms,
    requestId,
    onError,
    onGetLinkCallback,
  }: {
    accommodationId: number;
    isMultiRooms: boolean;
    requestId: string;
    onError?: () => void;
    onGetLinkCallback?: (link?: string) => void;
  }) =>
  (
    dispatch: AppDispatch,
    getState: any,
    {venueContainer}: TContainer,
  ): void => {
    dispatch(getVenueDetailsRequest());
    venueContainer.getPublicVenueById({
      payload: accommodationId,
      onSuccess: async (response: IVenue) => {
        dispatch(getVenueDetailsSuccess({response, isMultiRooms}));
        dispatch(getRegistrationLinkDataRequest());

        try {
          const hkey: string = getState().venuesReducer.venueDetails.hkey;
          const unInterceptedRequest = axios.create();

          const {data} = await unInterceptedRequest.get(
            createBaseUrl(
              ApiType.Inventory,
              `venues/hkey/${hkey}/onboarding-link?requestId=${requestId}`,
            ),
          );

          dispatch(getRegistrationLinkDataSuccess(data));
          onGetLinkCallback?.(data.registrationLink);
        } catch (error: any) {
          dispatch(getRegistrationLinkDataFailure(error?.response));

          if (
            error?.response?.data?.reasonCode ===
            EIgnoreInterceptorCodes.ACCOUNT_ALREADY_REGISTERED
          ) {
            // set venue already exists for handling it on the Login page
            dispatch(setVenueAlreadyExists(true));
            onError?.();
          }

          onGetLinkCallback?.();
        }
      },
    });
  };
