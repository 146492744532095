const ConfigService = {
  DOMAIN: process.env.REACT_APP_DEVELOPMENT_DOMAIN,
  ENV: process.env.REACT_APP_ENV,
  PROTOCOL: process.env.REACT_APP_PROTOCOL,
  NGROK: '',
  REACT_APP_LOGIN_URL: '',
};

const GATEWAY_BASE = () => {
  if (process.env.NODE_ENV === 'development')
    return `${ConfigService.PROTOCOL}://${ConfigService.DOMAIN}`;
  return '';
};

const GATEWAY = () => {
  return `${GATEWAY_BASE()}/nwrk-gateway/`;
};

const INVENTORY = () => {
  return `${GATEWAY_BASE()}/inventory/`;
};

const AVAILABILITY = () => {
  return `${GATEWAY_BASE()}/availability/`;
};

const SEARCH = () => {
  return `${GATEWAY_BASE()}/search/`;
};

const OFFERS = () => {
  return `${GATEWAY_BASE()}/offers/`;
};

const NOTIFICATION = () => {
  return `${GATEWAY_BASE()}/notification/`;
};

const COMMON = () => {
  return `${GATEWAY_BASE()}/`;
};

const WORKDESKSSEARCH = () => {
  return `${GATEWAY_BASE()}/work-desks-search/`;
};

export {
  ConfigService,
  INVENTORY,
  AVAILABILITY,
  SEARCH,
  GATEWAY,
  OFFERS,
  NOTIFICATION,
  COMMON,
  WORKDESKSSEARCH,
};
