import {useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as supplierBookings from 'store/bookings/actions';
import * as bookingsSupplier from 'store/bookingsSupplier/actions';
import * as offersApiActions from 'store/offers/apiActions';
import * as offersSlice from 'store/offers/offersSlice';
import * as venueDetailsSlice from 'store/venueDetails/venueDetailsSlice';
import * as venueDetailsApiActions from 'store/venueDetails/apiActions';
import * as customer from 'store/customer/apiActions';

export const useActions = () => {
  const dispatch = useDispatch();
  return useMemo(
    () =>
      bindActionCreators(
        {
          ...supplierBookings,
          ...bookingsSupplier,
          ...offersApiActions,
          ...offersSlice,
          ...customer,
          ...venueDetailsSlice,
          ...venueDetailsApiActions,
        },
        dispatch,
      ),
    [dispatch],
  );
};
