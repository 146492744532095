import React from 'react';
import styled from 'styled-components';

import Icon from 'view/components/Icon';
import {Link} from 'view/components/Typography';
import {offsetDef} from 'constants/styleVars';
import {StyledCustomList} from './NW2BookingConfirmationActionLinks.styles';
import {Group} from 'styles/basicStyledComponents/group';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';

const StyledLink = styled(Link)<{isVisible?: boolean}>`
  display: ${({isVisible}) => (isVisible ? 'block' : 'none')};

  > div {
    pointer-events: none;
  }
`;

const TextWrapper = styled.span`
  word-break: break-word;
`;

function hasProtocol(url: string): boolean {
  const protocolRegex = /^[a-z]+:\/\//i;
  return protocolRegex.test(url);
}

const NW2BookingConfirmationActionLinks = () => {
  const {venueDetails} = useVenueDetailsData();

  const contact = venueDetails.contact || venueDetails.legalContact;

  if (!contact) return null;

  const phone: string = contact.phone;
  const email: string = contact.email;

  const list = [
    {
      value: (
        <>
          <Link href={`tel: ${phone}`}>{phone}</Link>
        </>
      ),
    },
    {
      value: (
        <Link href={`mailto: ${email}`}>
          <TextWrapper>{email}</TextWrapper>
        </Link>
      ),
    },
    {
      value: (
        <Group gap={offsetDef}>
          <StyledLink
            isVisible={contact.websiteUrl}
            href={
              hasProtocol(contact.websiteUrl)
                ? contact.websiteUrl
                : `http://${contact.websiteUrl}`
            }
            rel='noreferrer'
            target='_blank'
          >
            <Icon icon='NW2_NETWORK' size={20} />
          </StyledLink>
          <StyledLink
            isVisible={contact.twitterUrl}
            href={contact.twitterUrl}
            rel='noreferrer'
            target='_blank'
          >
            <Icon icon='NW2_TWITTER' size={20} />
          </StyledLink>
          <StyledLink
            isVisible={contact.facebookUrl}
            href={contact.facebookUrl}
            rel='noreferrer'
            target='_blank'
          >
            <Icon icon='NW2_FACEBOOK' size={20} />
          </StyledLink>
          <StyledLink
            isVisible={contact.instagramUrl}
            href={contact.instagramUrl}
            rel='noreferrer'
            target='_blank'
          >
            <Icon icon='NW2_INSTAGRAM' size={20} />
          </StyledLink>
        </Group>
      ),
    },
  ];

  return (
    <StyledCustomList list={list} listStyle='noStyles' offset={offsetDef} />
  );
};

export default NW2BookingConfirmationActionLinks;
