import React from 'react';

import DateUtils, {DateFormats} from 'utils/dateUtils';

import {useAppSelector} from 'store/hooks';

import {Wrapper, StyledParagraph} from './AccommodationBlock.styles';
import {EEventType} from 'types/venue';

type TProps = {
  date?: string;
  eventType: EEventType;
};

const PrePostDateBlock = ({date, eventType}: TProps) => {
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const formattedDate = DateUtils.getDateWithoutHours(
    date,
    DateFormats['1 Jan 2023'],
  );

  return (
    <Wrapper isMobile={isMobile}>
      <StyledParagraph>
        {eventType === EEventType.PRE_ARRIVAL && 'Pre-arrival'}
        {eventType === EEventType.POST_EVENT && 'Post-event'}
      </StyledParagraph>
      <span>{formattedDate}</span>
    </Wrapper>
  );
};

export default PrePostDateBlock;
