import React from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';
import {TablePaginationConfig} from 'antd/lib/table';
import {
  ColumnsType,
  FilterValue,
  SorterResult,
  TableRowSelection,
} from 'antd/es/table/interface';

import BookingTodayOverviewTableSection from 'view/supplier/Bookings/BookingsList/components/BookingTodayOverviewTableSection';

import {IBooking} from 'types/dto/IBooking.types';
import {EBookingFilterType, IBookingType} from 'types/dto/IBookingType.type';
import {useActions} from 'hooks/useActions';

type TProps = {
  columns: ColumnsType<IBooking> | Record<string, ColumnsType<IBooking>>;
  rowSelection?: TableRowSelection<IBooking>;
};

function BookingTodayOverview({columns, rowSelection}: TProps) {
  const {getBookingsAction} = useActions();

  const bookingFilterType = EBookingFilterType.WORK_SPACE;

  const bookingsType: IBookingType = useSelector((state) =>
    _get(state, 'bookingReducer.bookingsType'),
  );

  const {data, isLoading} = useSelector((state) =>
    _get(state, `bookingReducer[${bookingsType}]`),
  );

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Record<string, string | any[]>>,
    tableDataKey = '',
  ) => {
    getBookingsAction({
      bookingsType: bookingsType,
      tableDataKey,
      pagination,
      filters,
      sortField: sorter.field,
      sortOrder: sorter.order,
    });
  };

  return (
    <>
      {Object.keys(data[bookingFilterType]).map((tableDataKey) => {
        return (
          <BookingTodayOverviewTableSection
            key={tableDataKey}
            tableDataKey={tableDataKey}
            isLoading={isLoading}
            handleTableChange={handleTableChange}
            bookingsType={bookingsType}
            columns={columns as Record<string, ColumnsType<IBooking>>}
            rowSelection={rowSelection}
          />
        );
      })}
    </>
  );
}

export default BookingTodayOverview;
