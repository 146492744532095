import React from 'react';
import styled from 'styled-components';

import Icon from 'view/components/Icon';
import useVenue from 'view/venue/hooks/useVenue';

import {TextValue} from 'view/components/TextValue';
import {StyledSpan} from 'view/components/Typography';
import {IPublicVenue} from 'types/dto/IPublicVenue';
import {getAddressStringFromLocation} from 'utils/stringUtils';

import {
  fontSizeXXSm,
  fontWeightBold,
  offsetDef,
  pagePadding,
  xLgBp,
} from 'constants/styleVars';
import {IVenue} from 'types/venue';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';

const MainContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: ${offsetDef};
  margin-top: calc(${pagePadding} * 2);
`;

const CopyContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${pagePadding};
  margin-top: ${offsetDef};

  > div {
    display: flex;
    flex-direction: column;
    line-height: 1.4;
  }
`;

const StyledIcon = styled(Icon)`
  display: none;
  @media (min-width: ${xLgBp}px) {
    display: block;
  }
`;

const legalDetailsList = ({name, legalContact}: IPublicVenue | IVenue) => [
  {
    title: 'Legal entity name',
    body: name,
  },
  {
    title: 'Company registration number',
    body: legalContact?.taxNumber,
  },
  {
    title: 'Legal entity address',
    body: getAddressStringFromLocation(legalContact),
  },
];

export function PaymentNote() {
  const [venue] = useVenue();

  const {venueDetails} = useVenueDetailsData();

  return (
    <MainContainer>
      <StyledIcon icon='NW2_CREDIT_CARD' NW2Green500Color />
      <div>
        <TextValue size='small' bold NW2Green500Color>
          No prepayment necessary
        </TextValue>
        <TextValue size='small' NW2Green500Color>
          Nothing will be charged or blocked on your credit card before the day
          of your booking
        </TextValue>
        <CopyContainer>
          {legalDetailsList(
            venueDetails.id || venueDetails.accommodationId
              ? venueDetails
              : venue,
          ).map(({title, body}) => (
            <div key={title}>
              <StyledSpan
                fontSize={fontSizeXXSm}
                NW2Gray600Color
                fontWeight={fontWeightBold}
              >
                {title}
              </StyledSpan>
              <StyledSpan fontSize={fontSizeXXSm} NW2Gray600Color>
                {body}
              </StyledSpan>
            </div>
          ))}
        </CopyContainer>
      </div>
    </MainContainer>
  );
}
