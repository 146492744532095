import {notification} from 'antd';
import {Store} from 'redux';
import {CognitoUserSession} from 'amazon-cognito-identity-js';

import tokenService from 'infra/common/token.service';
import {EIgnoreInterceptorCodes} from './types';
import {Routes} from 'constants/routes';
import {getMessageFromResponseError} from '../queryUtils';
import {AppDispatch, RootState} from 'store/types';
import {signOutUser} from 'store/app/apiActions';
import {setExpiresAt, setToken} from 'store/app/appSlice';
import {appContainer} from 'app';

const ignoreInterceptorCodes = [
  EIgnoreInterceptorCodes.UNAUTHORIZED_FETCH_VENUE_DETAILS_DATA_ERROR,
  EIgnoreInterceptorCodes.FORBIDDEN_ACCESS_TO_VENUE_DETAILS_ERROR,
  EIgnoreInterceptorCodes.AUTHORIZATION_FAILED,
  EIgnoreInterceptorCodes.PASSWORD_INVALID,
  EIgnoreInterceptorCodes.ADYEN_ONBOARDING_ERRORS,
];
// eslint-disable-next-line
export const errorInterceptor = (dispatch: AppDispatch) => (error: any) => {
  if (!error.response) {
    notification.error({
      message: 'Oops! Something has gone wrong with request to the server!',
    });
    return Promise.reject(error);
  }

  /*
   * Add your interceptors here.
   */

  // Ignore specific backend errors in interceptors.
  // Sometimes backend errors should be handled in a component,
  // rather than on interceptors level.
  // Responses with below reasonCode will be ignored by interceptors,
  // so such errors should be handled on component level.
  const reasonCode = error?.response?.data?.reasonCode;
  if (reasonCode && ignoreInterceptorCodes.includes(reasonCode)) {
    return Promise.reject(error.response);
  }

  const errorText = getMessageFromResponseError(error);
  if (error.response.status === 401) {
    if (errorText) {
      notification.error({
        message: errorText,
      });
    }
  } else if (error.response.status === 500) {
    notification.error({
      message:
        'Oops! Something has gone wrong! Please notify support of error 500!',
    });
  } else if (errorText) {
    notification.error({
      message: errorText,
    });
  }

  return Promise.reject(error.response);
};

export const responseInterceptor =
  // eslint-disable-next-line
  (dispatch: AppDispatch) => (response: any) => {
    try {
      // handle response here
    } catch (e) {}

    return Promise.resolve(response);
  };

export const requestInterceptor =
  (store: Store<RootState>) => async (config: any) => {
    const token = store.getState().app.token;
    const expiresAt = store.getState().app.user.expiresAt;
    const now = Date.now();

    if (expiresAt && expiresAt < now) {
      const dispatch: AppDispatch = store.dispatch;

      await appContainer.getCurrentSession({
        onSuccess: (cognitoUserSession: CognitoUserSession) => {
          const idToken = cognitoUserSession.getIdToken().getJwtToken();
          const {expiresAt} = tokenService.getUserData(idToken);
          dispatch(setToken(idToken));
          dispatch(setExpiresAt(expiresAt));
        },
        onError: () => {
          notification.error({
            message: 'Oops! Session has expired. Please log in again.',
          });
          dispatch(signOutUser());
          window.location.href = Routes.login;
        },
      });
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  };

export const requestErrorInterceptor =
  // eslint-disable-next-line
  (store: Store<RootState>) => (error: any) => {
    // Do something with request error
    return Promise.reject(error);
  };
