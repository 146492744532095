import React from 'react';

import MeetingRequestsAccordionItem from '../MeetingRequestsAccordionItem';
import MeetingRequestSectionTitle from '../MeetingRequestSectionTitle';
import MeetingRoomContent from './components/MeetingRoomContent';
import {TMeetingRoomItem} from 'types/search';

import {AddRoomButton} from './AddMeetingRequest.styles';

type TProps = {
  isSectionExpanded: boolean;
  isRemovableAll: boolean;
  meetingRooms: TMeetingRoomItem[];
  isDayToDelete: boolean;
  isMobile: boolean;
  addMeetingRoom: () => void;
  deleteDay: () => void;
  deleteMeetingRoom: (roomIndex: number) => void;
  setIsSectionExpanded: (isSectionExpanded: boolean) => void;
  deleteAllMeetingRoom: () => void;
  deleteFoodBeverage: () => void;
  setDefaultRoomList: () => void;
  setSelectedEquipment: (
    roomIndex: number,
    formData?: Record<string, any>,
  ) => void;
  isAccommodationEnabled: boolean;
  isCustomerChangedFoodAndBeverage: boolean;
  isCustomerChangedAccommodation: boolean;
};

const AddMeetingRequest = ({
  isSectionExpanded,
  setIsSectionExpanded,
  isRemovableAll,
  meetingRooms,
  addMeetingRoom,
  deleteDay,
  isDayToDelete,
  isMobile,
  deleteMeetingRoom,
  deleteAllMeetingRoom,
  setDefaultRoomList,
  setSelectedEquipment,
  isAccommodationEnabled,
  isCustomerChangedFoodAndBeverage,
  isCustomerChangedAccommodation,
}: TProps) => {
  const isRooms = !!meetingRooms.length;
  const isSingleRoom = meetingRooms.length === 1;

  const onToggleMeetingSection = () => {
    if (isRooms) {
      deleteAllMeetingRoom();
      deleteDay();
    } else {
      setDefaultRoomList();
    }
  };

  return (
    <MeetingRequestsAccordionItem
      isDivider={false}
      expandable={isRooms}
      isExpanded={isSectionExpanded}
      onExpandHandler={setIsSectionExpanded}
      title={
        <MeetingRequestSectionTitle
          label='Meeting'
          isSwitchActive={isRooms}
          onClickSectionHandler={onToggleMeetingSection}
          isSwitchDisabled={!isDayToDelete}
        />
      }
      content={
        <>
          {meetingRooms.map((room, index) => (
            <MeetingRoomContent
              key={room.timeStart + index}
              meetingRoom={room}
              indexOfRoom={index}
              isSingleRoom={isSingleRoom}
              isRemovableAll={isRemovableAll}
              deleteMeetingRoom={deleteMeetingRoom}
              setSelectedEquipment={setSelectedEquipment}
              isAccommodationEnabled={isAccommodationEnabled}
              isCustomerChangedFoodAndBeverage={
                isCustomerChangedFoodAndBeverage
              }
              isCustomerChangedAccommodation={isCustomerChangedAccommodation}
            />
          ))}
          <AddRoomButton
            size={isMobile ? 'large' : 'small'}
            fullWidth={isMobile}
            onClick={addMeetingRoom}
          >
            Add meeting room
          </AddRoomButton>
        </>
      }
    />
  );
};

export default AddMeetingRequest;
