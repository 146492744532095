import React, {useMemo} from 'react';

import {SummaryTotalPrice} from 'view/venue/components/NW2BookingSummary/components/SummaryTotalPrice/SummaryTotalPrice';

import {getCurrencySymbol} from 'utils/stringUtils';
import {EBookingSummaryTheme} from 'view/components/NW2SummaryBlock/types';
import {ISummaryUnit} from 'types/dto/IBooking.types';
import {
  EOfferStatus,
  ERequestStatus,
  IOfferRequestDay,
  IOfferUnitExtra,
  IRequestDay,
} from 'types/offer';
import {OfferRequestReviewUnitCard} from 'view/venue/Offer/components/OfferRequestReviewUnitCard/OfferRequestReviewUnitCard';
import {EResourcesType} from 'types/dto/IExtras.type';
import {NW2BookingUnitCard} from 'view/venue/components/NW2BookingSummary/components/NW2BookingUnitCard';
import {useAppSelector} from 'store/hooks';
import {formatFloatWithOneNumber} from 'utils/venueUtils';
import {MultiVenueHeader} from 'view/venue/components/NW2BookingSummary/components/MultiVenueHeader/MultiVenueHeader';
import {SummaryVenueSection} from 'view/venue/components/NW2BookingSummary/components/VenueSummarySection/VenueSummarySection';
import {useGroupRequest} from 'view/venue/hooks/useGroupRequest';
import {EBookingModal} from 'view/venue/components/NW2BookingSummary/hooks/useBookingSummaryModal';
import {IOfferRequestUnitExtra} from '../../OfferRequestReviewUnitCard/types';
import {IExtraResponse} from 'types/dto/IPublicVenue';

const getSummaryAccommodationUnits = (
  extras: IOfferUnitExtra[],
  extraType?: EResourcesType,
) => [
  {
    chosenExtras: extras?.map(({accommodationExtraId, quantity, ...rest}) => ({
      ...rest,
      ...(extraType && {extraType}),
      accommodationExtraId,
      chosenQuantity: quantity,
      quantity,
    })),
  } as unknown as ISummaryUnit,
];

interface IProps {
  currency: string;
  venueAddress: string;
  venueName: string;
  venueCoverImage: {
    url: string;
    name: string;
  };
  theme?: EBookingSummaryTheme;
  status?: ERequestStatus | EOfferStatus;
  requestedDays: IRequestDay[];
  isOffer?: boolean;
  isAlternative?: boolean;
  isOfferRequest?: boolean;
  isOfferPreview?: boolean;
  preArrivals?: IOfferRequestDay[];
  postEvents?: IOfferRequestDay[];
  isGroupRequest?: boolean;
  isTotalPriceVisible?: boolean;
  onModalShow: (arg: EBookingModal) => void;
  totalPrice?: number;
  getUnitExtras: (
    unitExtras: (IOfferUnitExtra | IExtraResponse)[],
    type: EResourcesType,
  ) => IOfferRequestUnitExtra[];
}
export function useRequestSummaryContent({
  currency,
  venueAddress,
  venueName,
  venueCoverImage,
  theme = EBookingSummaryTheme.DARK,
  requestedDays,
  isOffer,
  preArrivals,
  postEvents,
  status,
  totalPrice,
  isAlternative,
  isOfferRequest,
  isOfferPreview,
  isGroupRequest,
  isTotalPriceVisible,
  onModalShow,
  getUnitExtras,
}: IProps) {
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  const currencySymbol = getCurrencySymbol(currency);

  const {isMultiRequest, multiVenueIds, multiRequestHeader} = useGroupRequest();

  const customerSelectedPackages = useAppSelector(
    ({customer}) => customer.customerSelectedPackages,
  );

  const headerSection = useMemo(() => {
    return (
      <>
        {multiVenueIds.length > 1 && isGroupRequest ? (
          <MultiVenueHeader
            multiVenueIds={multiVenueIds}
            multiRequestHeader={multiRequestHeader}
            theme={theme}
            isMobile={isMobile}
            isExpandable={isMultiRequest && !isMobile}
          />
        ) : (
          <SummaryVenueSection
            theme={theme}
            venueAddress={venueAddress}
            venueCoverImage={venueCoverImage.url}
            venueName={venueName}
          />
        )}
      </>
    );
  }, [
    isGroupRequest,
    isMobile,
    isMultiRequest,
    multiRequestHeader,
    multiVenueIds,
    theme,
    venueAddress,
    venueCoverImage.url,
    venueName,
  ]);

  const unitsRequestSection = useMemo(() => {
    const makeRequestArrivals = (
      items: IOfferRequestDay[],
      extraType: EResourcesType,
    ) =>
      items.map(({checkIn, checkOut, bedrooms}, index) => {
        const currentPackagesByDay = customerSelectedPackages
          ? customerSelectedPackages[index]
          : null;

        return (
          <NW2BookingUnitCard
            key={checkIn}
            theme={theme}
            units={getSummaryAccommodationUnits(bedrooms, extraType)}
            checkInDate={checkIn}
            checkOutDate={checkOut}
            currencySymbol={currencySymbol}
            index={index}
            isTimeShowed={false}
            showTimeRangeAndParticipants={false}
            isOffer={isOffer}
            editMode={false}
            isPriceHidden={isAlternative}
            isOfferRequest={isOfferRequest}
            customerPackagesByDay={currentPackagesByDay}
            bedrooms={bedrooms}
          />
        );
      });

    const units = requestedDays.map((requestDay, index) => (
      <OfferRequestReviewUnitCard
        theme={theme}
        requestDay={requestDay}
        index={index}
        key={`offer-unit-${index}`}
        status={status}
        currencySymbol={currencySymbol}
        editMode={false}
        isOfferRequest={isOfferRequest}
        isOfferPreview={isOfferPreview}
        isPriceHidden={isAlternative}
        getUnitExtras={getUnitExtras}
        isAlternative={isAlternative && !isOfferPreview}
      />
    ));

    if (preArrivals?.length) {
      const requestPreArrivalUnits = makeRequestArrivals(
        preArrivals,
        EResourcesType.BEDROOM,
      );

      units.unshift(...requestPreArrivalUnits);
    }

    if (postEvents?.length) {
      const requestPostEventUnits = makeRequestArrivals(
        postEvents,
        EResourcesType.BEDROOM,
      );

      units.push(...requestPostEventUnits);
    }

    return units;
  }, [
    currencySymbol,
    customerSelectedPackages,
    getUnitExtras,
    isAlternative,
    isOffer,
    isOfferPreview,
    isOfferRequest,
    postEvents,
    preArrivals,
    requestedDays,
    status,
    theme,
  ]);

  const totalSum = useMemo(
    (): string =>
      totalPrice
        ? `${formatFloatWithOneNumber(totalPrice)} ${currencySymbol}`
        : '',
    [currencySymbol, totalPrice],
  );

  const totalPriceSection = useMemo(
    () =>
      isTotalPriceVisible && (
        <SummaryTotalPrice totalSum={totalSum} onModalShow={onModalShow} />
      ),
    [isTotalPriceVisible, onModalShow, totalSum],
  );

  return {
    headerSection,
    unitsRequestSection,
    totalPriceSection,
  };
}
