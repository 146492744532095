import {useEffect, useMemo, useState} from 'react';
import AdyenCheckout from '@adyen/adyen-web';
import Checkout from '@adyen/adyen-web/dist/types/core';
import {CoreOptions} from '@adyen/adyen-web/dist/types/core/types';

export function useAdyenCheckout(adyenProps: CoreOptions) {
  const [checkout, setCheckout] = useState<Checkout | null>(null);

  useEffect(() => {
    if (!adyenProps.paymentMethodsResponse || checkout || !adyenProps.clientKey)
      return;

    AdyenCheckout(adyenProps).then((res) => setCheckout(res));
  }, [adyenProps, checkout]);

  return useMemo(() => checkout, [checkout]);
}
