import styled from 'styled-components';

import {
  borderRadiusDef,
  borderRadiusLg,
  datepickerZIndex,
  fontSizeMd,
  offsetDef,
} from 'constants/styleVars';
import {reactDatepickerTimeOverrides} from '../NW2DatepickerCommon.styles';

export const timeInputWidth = '55px';
export const timeInputHeight = '22px';

export const StyledWrapper = styled.div`
  position: relative;
  z-index: ${datepickerZIndex};

  // datepicker time overrides
  .react-datepicker {
    border: 0;
    border-radius: ${borderRadiusLg};

    &__input-container {
      input {
        font-size: ${fontSizeMd};
        width: ${timeInputWidth};
        height: ${timeInputHeight};
        padding: 0;
        border-radius: ${borderRadiusDef};
        transition: border-color 0.15s;
        caret-color: transparent;
        cursor: pointer;
        user-select: none;

        &:disabled {
          pointer-events: none;
        }
      }
    }

    &--time-only {
      transform: translateX(-${offsetDef});
    }

    ${reactDatepickerTimeOverrides}
  }
`;
