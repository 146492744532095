import {
  ADD_EXTRA_FAILURE,
  ADD_EXTRA_REQUEST,
  ADD_EXTRA_SUCCESS,
  ClEAR_ADYEN_ONBOARDING_ERROR_LIST,
  CLEAR_SPACE_DELETION_STATUS,
  CLEAR_VENUE_DATA,
  CLEAR_VENUE_POINTS_OF_INTEREST,
  CREATE_ADYEN_ACCOUNT_HOLDER_SUCCESS,
  DELETE_EXTRA_FAILURE,
  DELETE_EXTRA_REQUEST,
  DELETE_EXTRA_SUCCESS,
  DELETE_SPACE_FAILURE,
  DELETE_SPACE_REQUEST,
  EDIT_SPACE_FAILURE,
  EDIT_SPACE_REQUEST,
  GET_AMENITIES_FAILURE,
  GET_AMENITIES_REQUEST,
  GET_AMENITIES_SUCCESS,
  GET_AVAILABLE_EXTRAS_FAILURE,
  GET_AVAILABLE_EXTRAS_REQUEST,
  GET_AVAILABLE_EXTRAS_SUCCESS,
  GET_EXTRAS_OPTION_FAILURE,
  GET_EXTRAS_OPTION_REQUEST,
  GET_EXTRAS_OPTION_SUCCESS,
  GET_SPACE_DELETION_STATUS_FAILURE,
  GET_SPACE_DELETION_STATUS_REQUEST,
  GET_SPACE_DELETION_STATUS_SUCCESS,
  GET_SPACES_FAILURE,
  GET_SPACES_REQUEST,
  GET_SPACES_SUCCESS,
  GET_VENUE_CHARACTERS_FAILURE,
  GET_VENUE_CHARACTERS_REQUEST,
  GET_VENUE_CHARACTERS_SUCCESS,
  GET_VENUE_CURRENCIES_FAILURE,
  GET_VENUE_CURRENCIES_REQUEST,
  GET_VENUE_CURRENCIES_SUCCESS,
  GET_VENUE_TYPES_FAILURE,
  GET_VENUE_TYPES_REQUEST,
  GET_VENUE_TYPES_SUCCESS,
  GET_WORK_IN_PROGRESS_FAILURE,
  GET_WORK_IN_PROGRESS_REQUEST,
  GET_WORK_IN_PROGRESS_SUCCESS,
  REMOVE_VENUE_PHOTO_ERROR,
  REMOVE_VENUE_PHOTO_FROM_LIST,
  SET_ADYEN_ONBOARDING_ERROR_LIST,
  SET_ADYEN_ONBOARDING_LINK,
  SET_COVER_VENUE_PHOTO,
  SET_CREATE_VENUE_STEP,
  SET_EXTRA_TO_EDIT_ID,
  SET_IS_GENERATING_ONBOARDING_LINK,
  SET_IS_VENUE_INFORMATION_CONFIRMED,
  SET_LOADING,
  SET_MISSING_BUSINESS_INFO_FIELDS,
  SET_SAME_LEGAL_CONTACT_VENUE_FIELDS,
  SET_SELECTED_FOR_BOOKING_EXTRAS,
  SET_SPACE_FAILURE,
  SET_SPACE_REQUEST,
  SET_SPACE_SELECTED_TAB,
  SET_SPACE_SUCCESS,
  SET_VENUE,
  SET_VENUE_ALREADY_EXISTS,
  SET_VENUE_AMENITY,
  SET_VENUE_ENTITY_TYPE,
  SET_VENUE_FIELDS,
  SET_VENUE_IMAGES_LIST,
  SET_VENUE_IMAGES_LIST_FROM_RESPONSE,
  SET_VENUE_PHOTO_ERROR,
  SET_VENUE_PHOTO_TO_LIST,
  SET_VENUE_POINTS_OF_INTEREST,
  UPDATE_EXTRA_STATUS_FAILURE,
  UPDATE_EXTRA_STATUS_REQUEST,
  UPDATE_EXTRA_STATUS_SUCCESS,
  UPDATE_EXTRA_SUCCESS,
  GET_ANNOUNCEMENT_SUCCESS,
  ADD_EXTRA_ITEM_SUCCESS,
} from './constants';
import {
  IAddExtraFailure,
  IAddExtraRequest,
  IAddExtraSuccess,
  IClearAdyenOnBoardingErrorList,
  IClearSpaceDeletionStatus,
  IClearVenueData,
  IClearVenuePointsOfInterest,
  ICreateAdyenAccountHolderSuccess,
  IDeleteExtraFailure,
  IDeleteExtraRequest,
  IDeleteExtraSuccess,
  IDeleteSpaceFailureAction,
  IDeleteSpaceRequestAction,
  IEditSpaceFailureAction,
  IEditSpaceRequestAction,
  IGetAmenitiesFailureAction,
  IGetAmenitiesRequestAction,
  IGetAvailableExtrasFailure,
  IGetAvailableExtrasRequest,
  IGetAvailableExtrasSuccess,
  IGetExtrasOptionFailure,
  IGetExtrasOptionRequest,
  IGetExtrasOptionSuccess,
  IGetServicesAmenitiesSuccessAction,
  IGetSpaceDeletionStatusFailureAction,
  IGetSpaceDeletionStatusRequestAction,
  IGetSpaceDeletionStatusSuccessAction,
  IGetSpacesFailureAction,
  IGetSpacesRequestAction,
  IGetSpacesSuccessAction,
  IGetVenueCharactersFailureAction,
  IGetVenueCharactersRequestAction,
  IGetVenueCharactersSuccessAction,
  IGetVenueCurrenciesFailureAction,
  IGetVenueCurrenciesRequestAction,
  IGetVenueCurrenciesSuccessAction,
  IGetVenueTypesFailureAction,
  IGetVenueTypesRequestAction,
  IGetVenueTypesSuccessAction,
  IGetWorkInProgressFailureAction,
  IGetWorkInProgressRequestAction,
  IGetWorkInProgressSuccessAction,
  ISetAdyenOnBoardingErrorList,
  ISetAdyenOnBoardingLink,
  ISetCreateVenueStep,
  ISetExtraToEditId,
  ISetIsGeneratingOnBoardingLink,
  ISetLoading,
  ISetMissingBusinessInfoFields,
  ISetSameVenueLegalContactFieldsPayload,
  ISetSelectedForBookingExtras,
  ISetSpaceFailureAction,
  ISetSpaceRequestAction,
  ISetSpaceSuccessAction,
  ISetVenue,
  ISetVenueAlreadyExists,
  ISetVenueAmenity,
  ISetVenueAmenityPayload,
  ISetVenueEntityType,
  ISetVenuePointsOfInterest,
  ISetVenuePointsOfInterestPayload,
  ISpaceSelectedTab,
  IUpdateAnnouncementStatusPayload,
  IUpdateExtraStatusFailure,
  IUpdateExtraStatusRequest,
  IUpdateExtraStatusSuccess,
  IUpdateExtraSuccess,
  IVenueFieldsPayload,
  IAddExtraItemSuccess,
} from './types';
import {
  ISpaceData,
  IAnnouncement,
  ISpacesData,
  ISpacesDataItem,
  ISpacesDataItemWipPayload,
  IVenue,
  IVenueCurrencies,
  TUpdatePublicVenueBusinessInformationPayload,
  TVenueCharacterType,
  TVenueType,
} from 'types/venue';

import {
  ETopNotificationId,
  IAccountHolderPayload,
  TCreateCorporateVenuePayload,
  TCreateVenuePayload,
} from 'types/app';
import {IImageError} from 'types/dto/Images.types';
import {UploadFile} from 'antd/es/upload/interface';
import {setTopNotification, setUserCustomer} from 'store/app/appSlice';
import {IUser} from 'types/dto/IUser.types';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import {
  EResourcesType,
  IExtrasOption,
  IExtrasRequest,
  IExtrasResponse,
  ISelectedForBookingExtra,
} from 'types/dto/IExtras.type';
import {TImageFile} from 'view/components/ImageUploaderComponent';
import {ESpaceDeletionStatuses} from 'view/venue/NW2VenueProfile/components/NMMSpaces/common/NMMSpacesDetails/components/AdditionalActions/types';
import {TContainer} from 'app';
import {EAmenityType} from 'types/amenities';
import {AppDispatch, RootState} from 'store/types';
import {findMissingLegalData} from 'view/venue/NW2VenueProfile/components/NMMPayout/helpers';
import {TMissingBusinessInfo} from 'types/supplier';
import {EVenueProfilePaymentSections} from 'view/venue/NW2VenueProfile/types';

export const setLoading = (payload: boolean): ISetLoading => ({
  type: SET_LOADING,
  payload,
});

export const clearVenueData = (): IClearVenueData => ({
  type: CLEAR_VENUE_DATA,
});

export const setSpaceSelectedTab = (payload: string): ISpaceSelectedTab => ({
  type: SET_SPACE_SELECTED_TAB,
  payload,
});

export const setCreateVenueStep = (payload: number): ISetCreateVenueStep => ({
  type: SET_CREATE_VENUE_STEP,
  payload,
});

export const setPhotoError = (payload: IImageError | null) => ({
  type: SET_VENUE_PHOTO_ERROR,
  payload,
});

export const removePhotoError = (payload: string) => ({
  type: REMOVE_VENUE_PHOTO_ERROR,
  payload,
});

export const setCoverPhoto = (payload: string) => ({
  type: SET_COVER_VENUE_PHOTO,
  payload,
});
export const setPhotoToList = (payload: UploadFile) => ({
  type: SET_VENUE_PHOTO_TO_LIST,
  payload,
});

export const setVenueImagesList = (payload: TImageFile) => ({
  type: SET_VENUE_IMAGES_LIST,
  payload,
});

export const setVenueImagesListFromResponse = (payload: TImageFile[]) => ({
  type: SET_VENUE_IMAGES_LIST_FROM_RESPONSE,
  payload,
});

export const removePhotoFromList = (payload: string) => ({
  type: REMOVE_VENUE_PHOTO_FROM_LIST,
  payload,
});

export const setVenueFields = (payload: IVenueFieldsPayload) => ({
  type: SET_VENUE_FIELDS,
  payload,
});

export const setSameVenueLegalContactFields = (
  payload: ISetSameVenueLegalContactFieldsPayload,
) => ({
  type: SET_SAME_LEGAL_CONTACT_VENUE_FIELDS,
  payload,
});

export const setIsVenueInformationConfirmed = (payload: boolean) => ({
  type: SET_IS_VENUE_INFORMATION_CONFIRMED,
  payload,
});

export const getAmenitiesRequest = (): IGetAmenitiesRequestAction => ({
  type: GET_AMENITIES_REQUEST,
});

export const getAmenitiesFailure = (
  payload: any,
): IGetAmenitiesFailureAction => ({
  type: GET_AMENITIES_FAILURE,
  payload,
});
export const setSpaceRequest = (): ISetSpaceRequestAction => ({
  type: SET_SPACE_REQUEST,
});

export const setSpaceSuccess = (
  payload: ISpaceData,
): ISetSpaceSuccessAction => {
  return {
    type: SET_SPACE_SUCCESS,
    payload,
  };
};

export const setSpaceFailure = (payload: any): ISetSpaceFailureAction => ({
  type: SET_SPACE_FAILURE,
  payload,
});

export const setVenueEntityType = (payload: string): ISetVenueEntityType => ({
  type: SET_VENUE_ENTITY_TYPE,
  payload,
});

export const editSpaceRequest = (): IEditSpaceRequestAction => ({
  type: EDIT_SPACE_REQUEST,
});
export const editSpaceFailure = (payload: any): IEditSpaceFailureAction => ({
  type: EDIT_SPACE_FAILURE,
  payload,
});

export const getSpacesRequest = (): IGetSpacesRequestAction => ({
  type: GET_SPACES_REQUEST,
});

export const getSpacesSuccess = (
  payload: ISpacesData[],
): IGetSpacesSuccessAction => {
  return {
    type: GET_SPACES_SUCCESS,
    payload,
  };
};

export const getSpacesFailure = (payload: any): IGetSpacesFailureAction => ({
  type: GET_SPACES_FAILURE,
  payload,
});

export const getSpaces =
  (payload: number) =>
  (dispatch: any, _: any, {venueContainer}: TContainer) => {
    dispatch(getSpacesRequest());
    venueContainer.getServicesSpaces({
      payload,
      onSuccess: (data: ISpacesData[]) => dispatch(getSpacesSuccess(data)),
      onError: (error: any) => dispatch(getSpacesFailure(error)),
    });
  };

export const getWorkInProgressRequest =
  (): IGetWorkInProgressRequestAction => ({
    type: GET_WORK_IN_PROGRESS_REQUEST,
  });

export const getWorkInProgressSuccess = (
  payload: ISpacesDataItem[],
): IGetWorkInProgressSuccessAction => {
  return {
    type: GET_WORK_IN_PROGRESS_SUCCESS,
    payload,
  };
};

export const getWorkInProgressFailure = (
  payload: any,
): IGetWorkInProgressFailureAction => ({
  type: GET_WORK_IN_PROGRESS_FAILURE,
  payload,
});

export const getWorkInProgress =
  (payload: number) =>
  (dispatch: any, _: any, {venueContainer}: TContainer) => {
    dispatch(getWorkInProgressRequest());
    venueContainer.getWorkInProgressById({
      payload,
      onSuccess: (data: ISpacesDataItem[]) =>
        dispatch(getWorkInProgressSuccess(data)),
      onError: (error: any) => dispatch(getWorkInProgressFailure(error)),
    });
  };

// SPACE DELETION STATUSES

export const getSpaceDeletionStatusRequest =
  (): IGetSpaceDeletionStatusRequestAction => ({
    type: GET_SPACE_DELETION_STATUS_REQUEST,
  });

export const getSpaceDeletionStatusSuccess = (
  payload: ESpaceDeletionStatuses,
): IGetSpaceDeletionStatusSuccessAction => {
  return {
    type: GET_SPACE_DELETION_STATUS_SUCCESS,
    payload,
  };
};

export const getSpaceDeletionStatusFailure = (
  payload: any,
): IGetSpaceDeletionStatusFailureAction => ({
  type: GET_SPACE_DELETION_STATUS_FAILURE,
  payload,
});

export const getSpaceDeletionStatus =
  (payload: number) =>
  (dispatch: any, _: any, {venueContainer}: TContainer) => {
    dispatch(getSpaceDeletionStatusRequest());
    venueContainer.getSpaceDeletionStatus({
      payload,
      onSuccess: (data: ESpaceDeletionStatuses) =>
        dispatch(getSpaceDeletionStatusSuccess(data)),
      onError: (error: any) => dispatch(getSpaceDeletionStatusFailure(error)),
    });
  };

export const clearSpaceDeletionStatus = (): IClearSpaceDeletionStatus => ({
  type: CLEAR_SPACE_DELETION_STATUS,
});

// SPACE DELETION

export const deleteSpaceRequest = (): IDeleteSpaceRequestAction => ({
  type: DELETE_SPACE_REQUEST,
});

export const deleteSpaceFailure = (
  payload: any,
): IDeleteSpaceFailureAction => ({
  type: DELETE_SPACE_FAILURE,
  payload,
});

export const deleteSpace =
  ({
    spaceId,
    venueId,
    isWip,
  }: {
    spaceId: number;
    venueId: number;
    isWip?: boolean;
  }) =>
  (dispatch: any, _: any, {venueContainer}: TContainer) => {
    dispatch(deleteSpaceRequest());
    venueContainer.deleteSpace({
      payload: spaceId,
      onSuccess: () => {
        dispatch(clearSpaceDeletionStatus());
        if (isWip) {
          dispatch(getWorkInProgress(venueId));
        } else {
          dispatch(getSpaces(venueId));
        }
      },
      onError: (error: any) => dispatch(deleteSpaceFailure(error)),
    });
  };

export const getAmenities =
  (payload: EAmenityType[]) =>
  (dispatch: any, _: any, {venueContainer}: TContainer) => {
    dispatch(getAmenitiesRequest());

    venueContainer.getAmenities({
      payload,
      onSuccess: (data: EAmenityType[]) => dispatch(getAmenitiesSuccess(data)),
      onError: (error: any) => dispatch(getAmenitiesFailure(error)),
    });
  };

export const setNmmSpace =
  ({
    id,
    data,
    onSuccessHandler,
    onErrorHandler,
  }: {
    id: number;
    data: any;
    onSuccessHandler: () => void;
    onErrorHandler: () => void;
  }) =>
  (dispatch: any, _: any, {venueContainer}: TContainer) => {
    dispatch(setSpaceRequest());
    venueContainer.setNmmSpace({
      payload: {
        id,
        data,
      },
      onSuccess: (response: ISpaceData) => {
        dispatch(setSpaceSuccess(response));
        onSuccessHandler();
        dispatch(getSpaces(id));
      },
      onError: (error: any) => {
        dispatch(setSpaceFailure(error));
        onErrorHandler();
      },
    });
  };

export const editNMMSpace =
  (payload: {
    data: ISpacesDataItemWipPayload;
    onSuccessCallback?: () => void;
  }) =>
  (dispatch: any, _: any, {venueContainer}: TContainer) => {
    dispatch(editSpaceRequest());
    venueContainer.editNMMSpace({
      payload,
      onSuccess: (response: ISpacesDataItem) => {
        const accommodationId = response?.accommodationId;
        if (accommodationId) {
          dispatch(getSpaces(accommodationId));
        } else {
          dispatch(setSpaceFailure('unknown error'));
        }

        if (typeof payload?.onSuccessCallback === 'function') {
          payload.onSuccessCallback();
        }
      },
      onError: (error: any) => {
        dispatch(editSpaceFailure(error));
      },
    });
  };

export const setVenue = (payload: IVenue | null): ISetVenue => ({
  type: SET_VENUE,
  payload,
});

export const setMissingBusinessInfoFields = (
  payload: TMissingBusinessInfo,
): ISetMissingBusinessInfoFields => ({
  type: SET_MISSING_BUSINESS_INFO_FIELDS,
  payload,
});

export const getSupplierVenueProfileDetails =
  (id: number) =>
  (
    dispatch: AppDispatch,
    getState: () => RootState,
    {venueContainer}: TContainer,
  ) => {
    venueContainer.getPublicVenueById({
      payload: id,
      onSuccess: (venue: IVenue) => {
        // const isRfp = venue.units.some((unit: IUnit) => unit.isRfp);

        const state = getState();

        const isSupplier =
          state.app.user.role === EUserRoleCognito.ROLE_SUPPLIER;
        const isAgent = state.app.user.role === EUserRoleCognito.ROLE_AGENT;

        if (!isSupplier && !isAgent) return;

        // const missingLegalData = findMissingLegalData({ // commented due payments disabling
        //   legalContact: venue.legalContact,
        //   peopleOfInterest: venue.peopleOfInterest,
        // });

        // if (isRfp) {
        dispatch(
          setTopNotification({
            id: ETopNotificationId.PENDING_REQUEST,
          }),
        );
        // }

        dispatch(getAnnouncementByUserId(venue.userId));
        dispatch(setVenue(venue));
        // dispatch(setMissingBusinessInfoFields(missingLegalData));

        // TODO: do not remove in case we will need adyen in future
        // dispatch(
        //   setTopNotification({
        //     id: ETopNotificationId.VENUE_PAYOUT_STATUS,
        //   }),
        // );
      },
    });
  };

export const setVenueAlreadyExists = (
  payload: boolean,
): ISetVenueAlreadyExists => ({
  type: SET_VENUE_ALREADY_EXISTS,
  payload,
});

export const setVenueAmenity = (
  payload: ISetVenueAmenityPayload,
): ISetVenueAmenity => ({
  type: SET_VENUE_AMENITY,
  payload,
});

export const setVenuePointsOfInterest = (
  payload: ISetVenuePointsOfInterestPayload,
): ISetVenuePointsOfInterest => ({
  type: SET_VENUE_POINTS_OF_INTEREST,
  payload,
});

export const clearVenuePointsOfInterest = (): IClearVenuePointsOfInterest => ({
  type: CLEAR_VENUE_POINTS_OF_INTEREST,
});
export const getCorporateVenueById =
  (id: number) =>
  (dispatch: any, _: any, {venueContainer}: TContainer) => {
    venueContainer.getCorporateVenueById({
      payload: id,
      onSuccess: (venue: IVenue) => {
        if (venue) {
          dispatch(setVenue(venue));
        }
      },
      onError: () => {},
    });
  };

export const updateCorporateVenueById =
  (
    venuePayload: TCreateCorporateVenuePayload,
    onSuccessCallback?: () => void,
  ) =>
  (dispatch: any, _: any, {venueContainer}: TContainer) => {
    venueContainer.updateCorporateVenueById({
      payload: venuePayload,
      onSuccess: (venue: IVenue) => {
        if (venue) {
          dispatch(setVenue(venue));
          dispatch(setLoading(false));

          if (typeof onSuccessCallback === 'function') {
            onSuccessCallback();
          }
        }
      },
      onError: () => {
        dispatch(setLoading(false));
      },
    });
  };

export const getPropertyManagerInfo =
  (id: number) =>
  (dispatch: any, _: any, {venueContainer}: TContainer) => {
    venueContainer.getPropertyManagerInfo({
      payload: id,
      onSuccess: (user: IUser) => {
        if (user) {
          dispatch(
            setUserCustomer({
              ...user,
              companyName: '',
              role: EUserRoleCognito.ROLE_PROPERTY_MANAGER,
            }),
          );
        }
      },
      onError: () => {},
    });
  };

export const getPublicVenueById =
  (id: number) =>
  (dispatch: any, _: any, {venueContainer}: TContainer) => {
    venueContainer.getPublicVenueById({
      payload: id,
      onSuccess: (venue: IVenue) => {
        if (venue) {
          dispatch(setVenue(venue));
        }
      },
      onError: () => {},
    });
  };

//TODO: maybe remove if not needed
export const getCorporateVenues =
  (corporateAccountId: number) =>
  (dispatch: any, _: any, {venueContainer}: TContainer) => {
    venueContainer.getCorporateVenues({
      payload: corporateAccountId,
      onSuccess: (venues: IVenue[]) => {
        if (venues.length) {
          dispatch(setVenue(venues[0]));
        }
      },
      onError: () => {},
    });
  };

export const updatePublicVenueById =
  (
    payload: TCreateVenuePayload | TUpdatePublicVenueBusinessInformationPayload,
    onSuccessCallback?: () => void,
    sectionName?: string,
  ) =>
  (dispatch: any, state: any, {venueContainer}: TContainer) => {
    const updateMethod =
      sectionName === EVenueProfilePaymentSections.BUSINESS_INFORMATION
        ? venueContainer.updatePublicVenueBusinessInformation
        : venueContainer.updateVenueById;
    updateMethod({
      payload,
      onSuccess: (venueData: IVenue) => {
        if (venueData.legalContact && venueData.peopleOfInterest) {
          const missingLegalData = findMissingLegalData({
            legalContact: venueData.legalContact,
            peopleOfInterest: venueData.peopleOfInterest,
          });
          dispatch(setMissingBusinessInfoFields(missingLegalData));
        }
        dispatch(setVenue(venueData));
        dispatch(setLoading(false));

        if (typeof onSuccessCallback === 'function') {
          onSuccessCallback();
        }
      },
      onError: () => {
        dispatch(setLoading(false));
      },
    });
  };

export const updateVenuePhotos =
  ({
    spaceId,
    venueId,
    imageData,
    handleProgress,
    onSuccessCallback,
  }: {
    venueId?: number;
    spaceId?: number;
    imageData: any;
    handleProgress?: (v: number) => void;
    onSuccessCallback?: () => void;
  }) =>
  (dispatch: AppDispatch, _: () => RootState, {venueContainer}: TContainer) => {
    const {images, coverImage, idsToDelete, entityName} = imageData;
    const shouldUpdateImages =
      !!coverImage || !!idsToDelete.length || !!images.length;

    if (shouldUpdateImages) {
      venueContainer.updateVenueImages({
        payload: {
          venueId: venueId || imageData.venueId,
          spaceId,
          images,
          coverImage,
          idsToDelete,
          entityName,
          handleProgress,
        },
        onSuccess: ({data}: {data: UploadFile[]}) => {
          const sortedImages = images.map((image: TImageFile) => {
            // TODO fix images shuffling on BE side
            if (image.uid) {
              return data.find((img) => img.uid === image.uid);
            }
            return data.find((img) => img.fileName === image.name);
          });

          if (typeof onSuccessCallback === 'function') {
            onSuccessCallback();
          }

          dispatch(setVenueImagesListFromResponse(sortedImages));
        },
        onError: () => {},
      });
    }
  };

export const getAmenitiesSuccess = (
  payload: EAmenityType[],
): IGetServicesAmenitiesSuccessAction => ({
  type: GET_AMENITIES_SUCCESS,
  payload,
});

export const updateExtraStatusRequest = (): IUpdateExtraStatusRequest => ({
  type: UPDATE_EXTRA_STATUS_REQUEST,
});

export const updateExtraStatusSuccess = (
  payload: IExtrasResponse,
): IUpdateExtraStatusSuccess => ({
  type: UPDATE_EXTRA_STATUS_SUCCESS,
  payload,
});

export const updateExtraStatusFailure = (): IUpdateExtraStatusFailure => ({
  type: UPDATE_EXTRA_STATUS_FAILURE,
});

export const updateExtraStatus =
  (
    accommodationsId: number,
    extraItemId: number,
    status: boolean,
    onSuccessHandler: () => void,
  ) =>
  (dispatch: any, _: any, {venueContainer}: TContainer) => {
    dispatch(updateExtraStatusRequest());
    venueContainer.updateExtraStatus({
      payload: {accommodationsId, extraItemId, status},
      onSuccess: (response: IExtrasResponse) => {
        dispatch(updateExtraStatusSuccess(response));
        onSuccessHandler();
      },
      onError: () => dispatch(updateExtraStatusFailure()),
    });
  };

export const getExtrasOptionRequest = (): IGetExtrasOptionRequest => ({
  type: GET_EXTRAS_OPTION_REQUEST,
});

export const getExtrasOptionSuccess = (
  payload: IExtrasOption[],
): IGetExtrasOptionSuccess => ({
  type: GET_EXTRAS_OPTION_SUCCESS,
  payload,
});

export const getExtrasOptionFailure = (
  payload: any,
): IGetExtrasOptionFailure => ({
  type: GET_EXTRAS_OPTION_FAILURE,
  payload,
});

export const getExtrasOption =
  (payload: EResourcesType[]) =>
  (dispatch: AppDispatch, _: any, {venueContainer}: TContainer) => {
    dispatch(getExtrasOptionRequest());

    venueContainer.getExtrasOption({
      payload,
      onSuccess: (data: IExtrasOption[]) => {
        dispatch(getExtrasOptionSuccess(data));
      },
      onError: (error: any) => dispatch(getExtrasOptionFailure(error)),
    });
  };

export const getAvailableExtrasRequest = (): IGetAvailableExtrasRequest => ({
  type: GET_AVAILABLE_EXTRAS_REQUEST,
});

export const getAvailableExtrasSuccess = (
  payload: IExtrasResponse[],
): IGetAvailableExtrasSuccess => {
  return {
    type: GET_AVAILABLE_EXTRAS_SUCCESS,
    payload,
  };
};

export const getAvailableExtrasFailure = (
  payload: any,
): IGetAvailableExtrasFailure => ({
  type: GET_AVAILABLE_EXTRAS_FAILURE,
  payload,
});

export const getAvailableExtras =
  (accommodationId: number, onSuccessHandler?: () => void) =>
  (dispatch: any, _: any, {venueContainer}: TContainer) => {
    dispatch(getAvailableExtrasRequest());
    venueContainer.getAvailableExtras({
      payload: accommodationId,
      onSuccess: (data: IExtrasResponse[]) => {
        dispatch(getAvailableExtrasSuccess(data));

        if (typeof onSuccessHandler === 'function') {
          onSuccessHandler();
        }
      },
      onError: (error: any) => dispatch(getAvailableExtrasFailure(error)),
    });
  };

export const addExtraRequest = (): IAddExtraRequest => ({
  type: ADD_EXTRA_REQUEST,
});

export const addExtraSuccess = (
  payload: IExtrasResponse[],
): IAddExtraSuccess => {
  return {
    type: ADD_EXTRA_SUCCESS,
    payload,
  };
};

export const addExtraItemSuccess = (
  payload: IExtrasResponse,
): IAddExtraItemSuccess => {
  return {
    type: ADD_EXTRA_ITEM_SUCCESS,
    payload,
  };
};

export const addExtraFailure = (payload: any): IAddExtraFailure => ({
  type: ADD_EXTRA_FAILURE,
  payload,
});

export const addExtra =
  (
    venueId: number,
    extraId: number,
    data: IExtrasRequest,
    onSuccessHandler: () => void,
  ) =>
  (dispatch: any, _: any, {venueContainer}: TContainer) => {
    dispatch(addExtraRequest());
    venueContainer.addExtra({
      payload: {venueId, extraId, extraData: data},
      onSuccess: (response: IExtrasResponse[]) => {
        dispatch(addExtraSuccess(response));
        onSuccessHandler();
      },
      onError: (error: any) => {
        dispatch(addExtraFailure(error));
      },
    });
  };

export const deleteExtraRequest = (): IDeleteExtraRequest => ({
  type: DELETE_EXTRA_REQUEST,
});

export const deleteExtraSuccess = (payload: number): IDeleteExtraSuccess => {
  return {
    type: DELETE_EXTRA_SUCCESS,
    payload,
  };
};

export const deleteExtraFailure = (): IDeleteExtraFailure => ({
  type: DELETE_EXTRA_FAILURE,
});

export const deleteExtra =
  (
    accommodationId: number,
    extraItemId: number,
    onSuccessHandler: () => void,
  ) =>
  (dispatch: any, _: any, {venueContainer}: TContainer) => {
    dispatch(deleteExtraRequest());
    venueContainer.deleteExtra({
      payload: {accommodationId, extraItemId},
      onSuccess: () => {
        dispatch(deleteExtraSuccess(extraItemId));
        onSuccessHandler();
      },
      onError: () => {
        dispatch(deleteExtraFailure());
      },
    });
  };

export const updateExtraSuccess = (
  payload: IExtrasResponse,
): IUpdateExtraSuccess => {
  return {
    type: UPDATE_EXTRA_SUCCESS,
    payload,
  };
};

export const setExtraToEditId = (
  payload: number | null,
): ISetExtraToEditId => ({
  type: SET_EXTRA_TO_EDIT_ID,
  payload,
});

export const setSelectedForBookingExtras = (
  payload: ISelectedForBookingExtra,
): ISetSelectedForBookingExtras => ({
  type: SET_SELECTED_FOR_BOOKING_EXTRAS,
  payload,
});
export const setAdyenOnBoardingLink = (
  payload: string,
): ISetAdyenOnBoardingLink => ({
  type: SET_ADYEN_ONBOARDING_LINK,
  payload,
});

export const setIsGeneratingOnBoardingLink = (
  payload: boolean,
): ISetIsGeneratingOnBoardingLink => ({
  type: SET_IS_GENERATING_ONBOARDING_LINK,
  payload,
});

export const createAdyenAccountHolderSuccess =
  (): ICreateAdyenAccountHolderSuccess => ({
    type: CREATE_ADYEN_ACCOUNT_HOLDER_SUCCESS,
  });

export const getVenueTypesRequest = (): IGetVenueTypesRequestAction => ({
  type: GET_VENUE_TYPES_REQUEST,
});

export const getVenueTypesSuccess = (
  payload: TVenueType[],
): IGetVenueTypesSuccessAction => {
  return {
    type: GET_VENUE_TYPES_SUCCESS,
    payload,
  };
};

export const getVenueTypesFailure = (
  payload: any,
): IGetVenueTypesFailureAction => ({
  type: GET_VENUE_TYPES_FAILURE,
  payload,
});

export const getVenueTypes =
  () =>
  (dispatch: any, _: any, {venueContainer}: TContainer) => {
    dispatch(getVenueTypesRequest());
    venueContainer.getVenueTypes({
      onSuccess: (data: TVenueType[]) => dispatch(getVenueTypesSuccess(data)),
      onError: (error: any) => dispatch(getVenueTypesFailure(error)),
    });
  };

export const getVenueCharactersRequest =
  (): IGetVenueCharactersRequestAction => ({
    type: GET_VENUE_CHARACTERS_REQUEST,
  });

export const getVenueCharactersSuccess = (
  payload: TVenueCharacterType[],
): IGetVenueCharactersSuccessAction => {
  return {
    type: GET_VENUE_CHARACTERS_SUCCESS,
    payload,
  };
};

export const getVenueCharactersFailure = (
  payload: any,
): IGetVenueCharactersFailureAction => ({
  type: GET_VENUE_CHARACTERS_FAILURE,
  payload,
});

export const getVenueCharacters =
  () =>
  (dispatch: any, _: any, {venueContainer}: TContainer) => {
    dispatch(getVenueCharactersRequest());
    venueContainer.getVenueCharacters({
      onSuccess: (data: TVenueCharacterType[]) =>
        dispatch(getVenueCharactersSuccess(data)),
      onError: (error: any) => dispatch(getVenueCharactersFailure(error)),
    });
  };

export const postCreateAdyenAccountHolder =
  (accountHolderPayload: IAccountHolderPayload) =>
  (dispatch: any, state: any, {venueContainer}: TContainer) => {
    venueContainer.postCreateAdyenAccountHolder({
      payload: accountHolderPayload,
      onSuccess: () => {
        dispatch(createAdyenAccountHolderSuccess());
      },
      onError: () => {},
    });
  };

export const getAdyenAccountHolderLink =
  (userId: string | number) =>
  (dispatch: any, _: any, {venueContainer}: TContainer) => {
    dispatch(setIsGeneratingOnBoardingLink(true));
    const interval = setInterval(() => {
      venueContainer.getAdyenAccountHolderLink({
        payload: userId,
        onSuccess: (response: string) => {
          if (response) {
            dispatch(setAdyenOnBoardingLink(response));
            dispatch(setIsGeneratingOnBoardingLink(false));
            clearInterval(interval);
          }
        },
        onError: () => {
          dispatch(setIsGeneratingOnBoardingLink(false));
        },
      });
    }, 2000);
  };

// currencies
const getVenueCurrenciesRequest = (): IGetVenueCurrenciesRequestAction => ({
  type: GET_VENUE_CURRENCIES_REQUEST,
});

const getVenueCurrenciesSuccess = (
  payload: IVenueCurrencies,
): IGetVenueCurrenciesSuccessAction => ({
  type: GET_VENUE_CURRENCIES_SUCCESS,
  payload,
});

const getVenueCurrenciesFailure = (
  payload: any,
): IGetVenueCurrenciesFailureAction => ({
  type: GET_VENUE_CURRENCIES_FAILURE,
  payload,
});

export const getVenueCurrencies =
  () =>
  (dispatch: any, _: any, {venueContainer}: TContainer) => {
    dispatch(getVenueCurrenciesRequest());
    venueContainer.getVenueCurrencies({
      onSuccess: (data: IVenueCurrencies) =>
        dispatch(getVenueCurrenciesSuccess(data)),
      onError: (error: any) => dispatch(getVenueCurrenciesFailure(error)),
    });
  };
// currencies end

export const setAdyenOnBoardingErrorList = (
  payload: string[],
): ISetAdyenOnBoardingErrorList => ({
  type: SET_ADYEN_ONBOARDING_ERROR_LIST,
  payload,
});

export const clearAdyenOnBoardingErrorList =
  (): IClearAdyenOnBoardingErrorList => ({
    type: ClEAR_ADYEN_ONBOARDING_ERROR_LIST,
  });

export const getAnnouncementSuccess = (payload: IAnnouncement[]) => ({
  type: GET_ANNOUNCEMENT_SUCCESS,
  payload,
});

export const getAnnouncementByUserId =
  (userId: number) =>
  (dispatch: any, _: any, {venueContainer}: TContainer) => {
    venueContainer.getAnnouncementByUserId({
      payload: userId,
      onSuccess: (data: IAnnouncement[]) =>
        dispatch(getAnnouncementSuccess(data)),
      onError: () => {},
    });
  };

export const updateAnnouncementStatus =
  (data: IUpdateAnnouncementStatusPayload) =>
  (dispatch: any, _: any, {venueContainer}: TContainer) => {
    venueContainer.updateAnnouncementStatus({
      payload: data,
      onSuccess: () => {},
      onError: () => {},
    });
  };
