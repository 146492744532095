import {useMemo} from 'react';
import {useAppSelector} from 'store/hooks';
import {EEventType} from 'types/venue';

export const useSearchTypeBedrooms = () => {
  const multiSearchPayload = useAppSelector(
    ({search}) => search.multiSearchPayload,
  );

  const {preArrivals, postEvents} = useMemo(() => {
    return {
      preArrivals: multiSearchPayload.filter(
        ({eventType}) => eventType === EEventType.PRE_ARRIVAL,
      ),
      postEvents: multiSearchPayload.filter(
        ({eventType}) => eventType === EEventType.POST_EVENT,
      ),
    };
  }, [multiSearchPayload]);

  return {preArrivals, postEvents};
};
