export type TAmount = {value: number; currency: string};

export type TDeletePaymentMethodPayload = {
  creditCardId: string;
  customerId: string;
};

export enum RESULT_CODE {
  AUTHENTICATION_NOT_REQUIRED = 'AuthenticationNotRequired',
  REDIRECT_SHOPPER = 'RedirectShopper',
  AUTHORISED = 'Authorised',
  PENDING = 'Pending',
  RECEIVED = 'Received',
  REFUSED = 'Refused',
}

export enum EAdyenCardAction {
  ADD = 'ADD',
  PAYMENT = 'PAYMENT',
}

export type TStoredCard = {
  brand: string;
  expiryMonth: string;
  expiryYear: string;
  holderName: string;
  iban: null | string;
  id: string;
  uuid: string;
  lastFour: string;
  name: string;
  ownerName: null | string;
  shopperEmail: null | string;
  supportedShopperInteractions: string[];
  type: string;
  defaultPaymentMethod: boolean;
};

export interface IAdyenConfigVariables {
  clientKey: string;
  environment: string;
}
