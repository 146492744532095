import styled from 'styled-components';

import {offsetDef, offsetXLg, offsetXXLg, xLgBp} from 'constants/styleVars';

const FlexContainer = styled.div`
  @media (min-width: ${xLgBp}px) {
    display: flex;
    align-items: center;
    gap: ${offsetXLg};
  }
`;

export const SwitchContainer = styled.div`
  margin-bottom: ${offsetXLg};
`;

export const BadgesContainer = styled(FlexContainer)`
  margin-bottom: ${offsetXLg};

  @media (min-width: ${xLgBp}px) {
    gap: ${offsetDef};
    flex-wrap: wrap;
    margin-bottom: 0;
  }
`;

export const HoursContainer = styled(FlexContainer)`
  gap: ${offsetXLg};
  margin-top: ${offsetXXLg};
`;

export const RowItem = styled.div`
  width: 100%;
  margin-bottom: ${offsetDef};

  @media (min-width: ${xLgBp}px) {
    width: 20%;
    margin-bottom: 0;
  }
`;
