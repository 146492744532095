import React from 'react';

import {NW2TimePicker} from './NW2TimePicker';
import {TDateChangeFn} from '../NW2DatePicker.types';
import {TDateType} from 'types/dto/ISearch.types';
import {useTimeRangeValidation} from './useTimeRangeValidation';

interface IProps {
  valueStart: TDateType;
  valueEnd: TDateType;
  onChangeStart: TDateChangeFn;
  onChangeEnd: TDateChangeFn;
  labelStart?: string;
  labelEnd?: string;
}

export function NW2TimeRangePicker({
  valueStart,
  valueEnd,
  onChangeStart,
  onChangeEnd,
  labelStart,
  labelEnd,
}: IProps) {
  const {invalidStartValues, invalidEndValues} = useTimeRangeValidation({
    valueStart,
    valueEnd,
  });

  return (
    <>
      <NW2TimePicker
        selected={valueStart}
        onChange={onChangeStart}
        label={labelStart}
        excludeTimes={invalidStartValues}
        popperPlacement='auto'
      />

      <NW2TimePicker
        selected={valueEnd}
        onChange={onChangeEnd}
        label={labelEnd}
        excludeTimes={invalidEndValues}
        popperPlacement='auto'
      />
    </>
  );
}
