import React, {FC, Suspense, useEffect, useState} from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
} from 'react-router-dom';

import {ThemeProvider} from 'styled-components';
import {useAppSelector} from 'store/hooks';
import Layout from '../Layout/Layout';
import ErrorBoundary from '../Error/Handler';
import RouteContainer from 'view/common/RouteContainer/RouteContainer';
import APP_THEME from 'constants/theme';
import {Routes} from 'constants/routes';
import {RouteComponents} from 'constants/routeComponents';
import {EAppTheme, IFeatureToggleItem} from 'types/app';
import useSetInitialConfig from 'hooks/useSetInitialConfig';
import FeatureToggleProvider from './components/FeatureToggleProvider';
import {COMMON} from 'infra/common/config.service';
import Page404 from 'view/common/Error/Page404';
import NW2Loader from 'view/components/NW2Loader/NW2Loader';
import {EFeatureToggles} from 'constants/featureToggles';
import {useRedirectLinkNavigation} from 'hooks/useRedirectLinkNavigation';

// ROUTES FOR WHOLE APP
const LayoutContainer: FC = () => {
  useRedirectLinkNavigation();

  const themeName: EAppTheme = useAppSelector(({app}) => app.theme);
  const environment = useAppSelector(({app}) => app.environment);

  const [enabledFeatures, setEnabledFeatures] = useState<
    EFeatureToggles[] | null
  >(null);

  useEffect(() => {
    if (!environment) return;

    fetch(`${COMMON()}configuration/feature`)
      .then(async (resp) => {
        const data = await resp.json();

        if (data?.length) {
          const enabledItems: IFeatureToggleItem[] = data.filter(
            ({enable}: IFeatureToggleItem) => enable,
          );

          setEnabledFeatures(enabledItems.map(({id}) => id));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [environment]);

  return (
    <ThemeProvider theme={APP_THEME[themeName]}>
      <FeatureToggleProvider enabledFeatures={enabledFeatures}>
        <Layout>
          <Suspense fallback={<NW2Loader height='100%' />}>
            <Outlet />
          </Suspense>
        </Layout>
      </FeatureToggleProvider>
    </ThemeProvider>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<LayoutContainer />}>
      {RouteComponents.map(({key, ...rest}) => (
        <Route
          key={key}
          errorElement={<Page404 />}
          element={<RouteContainer {...rest} />}
          {...rest}
        />
      ))}
      <Route path='*' element={<Navigate to={Routes.page404} />} />
    </Route>,
  ),
);

const App: FC = () => {
  useSetInitialConfig();
  return (
    <ErrorBoundary>
      <RouterProvider router={router} />
    </ErrorBoundary>
  );
};

export default App;
