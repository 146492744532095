import React from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import Header from '../Header/Header';
import FormSection from './FormSection';
import AnchorMenu from '../../../../components/SupplierLayout/components/AnchorMenu';
import NMMPayoutStatusBlock from './components/NMMPayoutStatusBlock/NMMPayoutStatusBlock';
import NMMPayoutErrorList from './components/NMMPayoutErrorList/NMMPayoutErrorList';

import {IGlobalVenue} from 'types/venue';
import {
  EVenueProfilePaymentSections,
  EVenueSupplierPagesWithHeader,
} from '../../types';
import {PATH_TO_REDUCER_GLOBAL_VENUE_DATA} from 'constants/venue';

import {
  AnchorSections,
  AnchorsWrapper,
} from '../ContactsDetails/ContactsDetails.styles';
import {SectionTitle} from 'view/components/NMMSection/NMMSection.styles';
import {fontSizeXXXLg} from 'styles/configs/typography';
import {offsetXLg} from 'styles/configs/offset';
import {
  PaymentProcessContainer,
  PaymentProcessSection,
  PaymentProcessSubtitle,
} from './NMMPayout.styles';

interface IProps {
  globalVenue: IGlobalVenue;
}

const NMMPayout = ({globalVenue}: IProps) => {
  const PaymentProcessInfoText = [
    {
      subtitle: 'Pre-Arrival',
      items: [
        'Customer creates the booking',
        'HRS is storing the customers credit cards',
        'HRS charges the customers credit card 30 minutes before the start time of the booking',
      ],
    },
    {
      subtitle: 'On-Site - your duties',
      items: [
        'Create invoice for the total booked amount (no open balance for customer)',
        'Handover invoice to customer',
        'If customer consumes any extras on-site, create invoice for extras and charge customer',
      ],
    },
    {
      subtitle: 'Post-Stay',
      items: [
        'HRS pays out net of booked amount (minus commission)',
        'HRS sends invoice for commission to balance out the total booked amount',
      ],
    },
  ];

  const sectionsWithForm = Object.values(EVenueProfilePaymentSections).filter(
    (sectionName) => {
      return (
        sectionName !== EVenueProfilePaymentSections.OPERATING_EXECUTIVES &&
        sectionName !== EVenueProfilePaymentSections.PAYMENT_PROCESS &&
        sectionName !== EVenueProfilePaymentSections.ACCOUNT_STATUS
      );
    },
  );

  const adyenOnBoardingErrorList = useSelector((state) =>
    _get(
      state,
      `${PATH_TO_REDUCER_GLOBAL_VENUE_DATA}.adyenOnBoardingErrorList`,
    ),
  );

  const {payAccStatus, legalContact} = globalVenue.venue;

  const hasAdyenOnBoardingErrors = adyenOnBoardingErrorList.length > 0;

  return (
    <>
      <Header
        globalVenue={globalVenue}
        showHeaderForSection={EVenueSupplierPagesWithHeader.PAYMENT}
      />
      <AnchorsWrapper>
        <AnchorSections isNoSeparators>
          {hasAdyenOnBoardingErrors && (
            <NMMPayoutErrorList errors={adyenOnBoardingErrorList} />
          )}
          <div id={EVenueProfilePaymentSections.ACCOUNT_STATUS}>
            <NMMPayoutStatusBlock
              status={payAccStatus}
              legalEntityType={legalContact.legalEntityType}
            />
          </div>
          {sectionsWithForm.map((sectionName) => {
            return (
              <FormSection
                key={sectionName}
                venue={globalVenue.venue}
                sectionName={sectionName}
              />
            );
          })}
          <PaymentProcessContainer
            id={EVenueProfilePaymentSections.PAYMENT_PROCESS}
          >
            <SectionTitle
              titleFontSize={fontSizeXXXLg}
              titleMarginBottom={offsetXLg}
            >
              The payment process
            </SectionTitle>
            {PaymentProcessInfoText.map((list) => (
              <PaymentProcessSection key={list.subtitle}>
                <PaymentProcessSubtitle>
                  {list.subtitle}:
                </PaymentProcessSubtitle>
                <ul>
                  {list.items.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </PaymentProcessSection>
            ))}
          </PaymentProcessContainer>
        </AnchorSections>
        <AnchorMenu sections={Object.values(EVenueProfilePaymentSections)} />
      </AnchorsWrapper>
    </>
  );
};

export default NMMPayout;
