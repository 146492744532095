import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _get from 'lodash/get';
import {useAppSelector} from 'store/hooks';
import {setIsPaymentMethodAdded} from 'store/payment/paymentSlice';

import NW2Button from 'view/components/NW2Button';
import {NW2AddPaymentMethodModalDrawer} from './NW2AddPaymentMethodModalDrawer';

import {Text, Title} from '../NW2PaymentMethodsView.styles';

export function NW2PaymentMethodsOptions() {
  const [isModalShowed, setIsModalShowed] = useState(false);

  const dispatch = useDispatch();
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const isPaymentMethodAdded = useSelector((state) =>
    _get(state, 'payment.isPaymentMethodAdded'),
  );

  const toggleModal = () => {
    setIsModalShowed((prevState) => !prevState);
  };

  useEffect(() => {
    if (isModalShowed) dispatch(setIsPaymentMethodAdded(false));
  }, [isModalShowed, dispatch]);

  useEffect(() => {
    if (isPaymentMethodAdded) setIsModalShowed(false);
  }, [isPaymentMethodAdded]);

  return (
    <div>
      <Title>More payment methods</Title>
      <Text>Would you like to add a new payment method?</Text>

      <NW2Button buttonType='secondary' onClick={toggleModal}>
        Add new Card
      </NW2Button>

      <NW2AddPaymentMethodModalDrawer
        isMobile={isMobile}
        isShowed={isModalShowed}
        onClose={toggleModal}
      />
    </div>
  );
}
