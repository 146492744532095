import React, {SyntheticEvent} from 'react';
import styled from 'styled-components';

import TextPopover from 'view/venue/CreateVenue/pages/ContactDetails/components/ContactDetailsForm/TextPopover';
import DesktopDatePicker from 'view/components/NW2Datepicker/DesktopDatePicker';
import NW2Switch from 'view/components/NW2Switch';

import {NW2FormItemInput} from 'view/components/NW2FormItem/NW2FormItem';
import {requiredFieldRules} from 'utils/finalFormFieldRules';
import {EPersonOfInterestType} from 'types/venue';
import {ESupplierBusinessInfoFormFields} from 'view/components/NW2Forms/NW2SupplierLegalDetailsForm/types';

import {SectionTitle} from 'view/components/NMMSection/NMMSection.styles';
import {fontSizeSm} from 'styles/configs/typography';
import {offsetXSm} from 'styles/configs/offset';
import {NW2Gray600Color} from 'styles/configs/colors';
import {FormInnerContainer} from 'view/venue/NW2VenueProfile/components/NMMPayout/components/EditPaymentForm/EditPaymentForm.styles';
import {INPUT_HEIGHT} from 'view/components/NW2FormItem/NW2FormItem.styles';

const StyledFormInnerContainer = styled(FormInnerContainer)`
  margin-top: 50px;
`;

const StyledNW2Switch = styled(NW2Switch)`
  flex-wrap: nowrap;
  white-space: nowrap;
`;

export function OperatingExecutiveItem({
  operationExecutiveType,
  hasValidation = true,
  disabled = false,
  isSwitchActiveField,
  handleSwitch,
  isLegalEntityTypeIndividual,
}: {
  operationExecutiveType: EPersonOfInterestType;
  hasValidation?: boolean;
  disabled?: boolean;
  isSwitchActiveField?: boolean;
  isLegalEntityTypeIndividual?: boolean;
  handleSwitch?: (e?: SyntheticEvent) => void;
}) {
  const maxDate = new Date();
  const isSwitchVisible =
    !isLegalEntityTypeIndividual &&
    handleSwitch &&
    typeof isSwitchActiveField === 'boolean';
  const sectionTitleText =
    operationExecutiveType === EPersonOfInterestType.OWNER
      ? `Business ${EPersonOfInterestType[operationExecutiveType]}`
      : EPersonOfInterestType[operationExecutiveType];

  return (
    <>
      <SectionTitle titleMarginBottom='0' titleFontSize={fontSizeSm} gap='0'>
        {sectionTitleText} <TextPopover textIndex={4} marginLeft={offsetXSm} />
      </SectionTitle>

      {isSwitchVisible && (
        <StyledNW2Switch
          label='Same as signatory'
          isActive={isSwitchActiveField}
          onClick={handleSwitch}
          labelPosition='right'
        />
      )}

      <StyledFormInnerContainer isGrid={isLegalEntityTypeIndividual}>
        <NW2FormItemInput
          name={`${EPersonOfInterestType[operationExecutiveType]}.firstName`}
          type='text'
          label='First name*'
          placeholder='First name*'
          labelColor={NW2Gray600Color}
          rules={requiredFieldRules('First name')}
          hasValidation={hasValidation}
          disabled={disabled}
        />
      </StyledFormInnerContainer>
      <FormInnerContainer isGrid={isLegalEntityTypeIndividual}>
        <NW2FormItemInput
          name={`${EPersonOfInterestType[operationExecutiveType]}.lastName`}
          type='text'
          label='Last name*'
          placeholder='Last name*'
          labelColor={NW2Gray600Color}
          rules={requiredFieldRules('Last name')}
          hasValidation={hasValidation}
          disabled={disabled}
        />
      </FormInnerContainer>
      <FormInnerContainer isGrid={isLegalEntityTypeIndividual}>
        <DesktopDatePicker
          inputName={`${EPersonOfInterestType[operationExecutiveType]}.dateOfBirth`}
          inputLabel='Date of birth*'
          maxDate={maxDate}
          isRequired={hasValidation}
          disabled={disabled}
        />
      </FormInnerContainer>

      {isLegalEntityTypeIndividual && (
        <FormInnerContainer>
          <NW2FormItemInput
            name={ESupplierBusinessInfoFormFields.TAX_NUMBER}
            type='text'
            label='Tax ID*'
            placeholder='Tax ID*'
            labelColor={NW2Gray600Color}
            rules={requiredFieldRules('Tax ID', {maxLength: 20})}
          />
          <TextPopover textIndex={3} iconHeight={INPUT_HEIGHT} />
        </FormInnerContainer>
      )}
    </>
  );
}
