import styled from 'styled-components';

import Icon from 'view/components/Icon';

import {
  textColor,
  NW2Gray200Color,
  NW2Gray600Color,
  fontWeightBold,
  offsetXXXXLg,
  offsetXLg,
  offsetSm,
  preXLgBd,
  NW2ErrorLight500Color,
} from 'constants/styleVars';

const CONTENT_WIDTH = '572px';

export const StyledLeftSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const TextBlock = styled.div`
  margin-bottom: ${offsetXLg};

  @media (min-width: ${preXLgBd}px) {
    margin-bottom: 0;
  }
`;

export const StyledIcon = styled(Icon)`
  margin-right: ${offsetSm};
`;

export const Title = styled.div<{hasError?: boolean}>`
  text-transform: uppercase;
  font-weight: ${fontWeightBold};
  color: ${({hasError}) => (hasError ? NW2ErrorLight500Color : textColor)};
  margin-bottom: ${offsetSm};

  @media (min-width: ${preXLgBd}px) {
    max-width: 150px;
    margin-bottom: 0;
  }
`;

export const SubTitle = styled.div<{hasError?: boolean; color?: string}>`
  color: ${({hasError, color}) =>
    color || (hasError ? NW2ErrorLight500Color : NW2Gray600Color)};
`;

export const ItemWrapper = styled.div`
  width: 100%;
  padding-bottom: ${offsetXXXXLg};
  position: relative;

  & + & {
    padding-top: ${offsetXXXXLg};

    &:before {
      content: '';
      width: 100%;
      border-top: 1px solid ${NW2Gray200Color};
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  @media (min-width: ${preXLgBd}px) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    & + &:before {
      max-width: ${CONTENT_WIDTH};
    }
  }
`;

export const Content = styled.div`
  width: 100%;

  @media (min-width: ${preXLgBd}px) {
    max-width: ${CONTENT_WIDTH};
  }
`;

export const Divider = styled.div`
  border-top: 1px solid ${NW2Gray200Color};
  padding-bottom: ${offsetXXXXLg};
`;
