import React, {memo, SyntheticEvent} from 'react';
import styled from 'styled-components';

import Icon from 'view/components/Icon';
import Image from 'view/components/Image';

import PlaceholderImg from 'img/shortlist-placeholder.png';
import {useActions} from 'hooks/useActions';
import {NW2Gray200Color} from 'constants/styleVars';
import {ButtonRemove} from '../../ShortList.styles';

const ITEM_SIZE = '48px';
const ITEM_BORDER_RADIUS = '6px';

const Wrapper = styled.li`
  position: relative;
  width: ${ITEM_SIZE};
  height: ${ITEM_SIZE};
  border: 1px solid ${NW2Gray200Color};
  border-radius: ${ITEM_BORDER_RADIUS};
`;

const commonImageProps = {
  width: '100%',
  height: '100%',
  borderRadius: ITEM_BORDER_RADIUS,
};

interface IProps {
  id?: number;
  img?: string;
  isPlaceholder?: boolean;
}
export const ShortListItem = memo(({id, img, isPlaceholder}: IProps) => {
  const {removeShortListItem} = useActions();

  const onRemove = (e: SyntheticEvent) => {
    if (!id) return;

    e.preventDefault();
    removeShortListItem(id);
  };

  return (
    <Wrapper>
      {isPlaceholder ? (
        <Image
          src={PlaceholderImg}
          alt={`shortlist-venue-placeholder`}
          {...commonImageProps}
        />
      ) : (
        <>
          <Image
            src={img}
            alt={`shortlist-venue-${id}`}
            {...commonImageProps}
          />
          <ButtonRemove role='button' tabIndex={0} onClick={onRemove}>
            <Icon icon='NW2_CLOSE' size={8} />
          </ButtonRemove>
        </>
      )}
    </Wrapper>
  );
});
