import styled, {css} from 'styled-components';
import {Button, Checkbox, Form} from 'antd';

import TextValue from 'view/components/TextValue';
import Icon from 'view/components/Icon';

import {
  blackColor,
  fontSizeMd,
  fontSizeXLg,
  fontSizeXSm,
  fontSizeXXXLg,
  fontWeightBold,
  fontWeightNormal,
  NW2Gray200Color,
  NW2Gray600Color,
  offsetDef,
  offsetXLg,
  offsetXSm,
  offsetXXXSm,
  secondaryColor,
  xlg,
} from 'constants/styleVars';
import {SecondaryButton} from 'view/components/Button';
import NW2Button from 'view/components/NW2Button/NW2Button';

const sharedMargin = css`
  margin-top: ${offsetDef};
`;

export const StyledTextValue = styled(TextValue)`
  margin: ${fontSizeXSm} 0 ${offsetDef} 0;
`;

export const StyledRegisterTextValue = styled(StyledTextValue)`
  text-align: left;
`;

export const StyledSecondaryButton = styled(SecondaryButton)`
  width: 100%;
  ${sharedMargin};
`;

export const StyledForm = styled(Form)`
  > div {
    margin-bottom: 0;
  }
  > * + * {
    ${sharedMargin};
  }
`;

export const StyledButton = styled(Button)`
  max-width: 100%;
  width: 100%;
`;

export const FormGroup = styled.div<{
  columnNumber: number;
  columnNumberXLg?: number;
  gap?: number | string;
  alignItems?: string;
}>`
  display: grid;
  grid-template-columns: repeat(${({columnNumber}) => columnNumber}, 1fr);
  grid-gap: ${({gap}) => (typeof gap === 'number' ? `${gap}px` : gap || 0)};
  align-items: ${({alignItems}) => alignItems || 'initial'};

  @media (min-width: ${xlg}) {
    grid-template-columns: repeat(
      ${({columnNumber, columnNumberXLg}) => columnNumberXLg || columnNumber},
      1fr
    );
  }

  > div {
    margin: 0;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  color: ${secondaryColor};
  align-items: flex-start;

  & > span {
    font-size: ${fontSizeXSm};
  }
`;

export const AuthModalFooter = styled.div`
  padding: ${offsetDef} 0 0;
  margin-top: ${offsetDef};
  border-top: 1px solid ${NW2Gray200Color};
`;

export const ModalContainer = styled.div`
  text-align: center;

  * + & {
    ${sharedMargin}
  }
`;

export const drawerBodyStyle = {
  textAlign: 'center',
};

export const modalBodyStyles = {
  textAlign: 'center' as const,
};

export const StyledIcon = styled(Icon)`
  justify-self: center;
  color: ${blackColor};

  > svg {
    width: 120px;
    height: 35px;
  }
`;

export const StyledNW2ButtonLink = styled(NW2Button)`
  justify-self: center;
`;

export const StyledNW2Button = styled(NW2Button)`
  margin: ${offsetXSm} 0;
`;

export const TitleContainer = styled.div`
  margin: ${fontSizeXLg} 0 ${fontSizeXXXLg} 0;
`;

export const LoginContentWrapper = styled.div`
  text-align: center;
`;

export const StepCountTitle = styled.div`
  font-size: ${fontSizeXSm};
  color: ${NW2Gray600Color};
  margin-bottom: ${offsetXXXSm};
  font-weight: ${fontWeightNormal};
`;

export const CustomCheckboxLabel = styled.span`
  text-align: left;
`;

export const MainAuthTitle = styled.div`
  font-size: ${fontSizeXLg};
  font-weight: ${fontWeightBold};
`;

export const BoldTextValue = styled(StyledTextValue)`
  text-align: left;
  font-weight: ${fontWeightBold};
`;

export const RegisterModalFooter = styled(AuthModalFooter)`
  display: flex;
  flex-direction: column;
`;

export const ForgotPasswordContainer = styled.div`
  font-size: ${fontSizeMd};
  margin-bottom: ${offsetXLg};
  display: grid;
  gap: ${offsetXLg};
  grid-template-columns: 1fr;
`;
