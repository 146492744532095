import React, {useEffect} from 'react';
import {useField, useForm} from 'react-final-form';
import styled from 'styled-components';

import useLegalEntityField from '../../../hooks/useLegalEntityField';

import {ESupplierBusinessInfoFormFields} from 'view/components/NW2Forms/NW2SupplierLegalDetailsForm/types';
import {OperatingExecutiveItem} from './OperatingExecutiveItem';
import {EPersonOfInterestType} from 'types/venue';

import {pagePadding} from 'styles/configs/offset';
import {FormGroup} from 'view/auth/utils/auth.styles';

const SignatoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  gap: ${pagePadding};
`;

export function OperatingExecutivesBlock() {
  const form = useForm();
  const businessSwitchActiveField = useField(
    ESupplierBusinessInfoFormFields.BUSINESS_OWNER_SWITCH,
  );
  const controllerSwitchActiveField = useField(
    ESupplierBusinessInfoFormFields.CONTROLLER_SWITCH,
  );
  const signatoryField = useField(EPersonOfInterestType.SIGNATORY);
  const ownerField = useField(EPersonOfInterestType.OWNER);
  const controllerField = useField(EPersonOfInterestType.CONTROLLER);

  const isLegalEntityTypeIndividual = useLegalEntityField();

  const isBusinessSwitchActiveField = businessSwitchActiveField.input.value;
  const isControllerSwitchActiveField = controllerSwitchActiveField.input.value;

  const signatorySharedFieldsValue = {
    firstName: signatoryField.input.value.firstName,
    lastName: signatoryField.input.value.lastName,
    dateOfBirth: signatoryField.input.value.dateOfBirth,
  };

  const handleBusinessSwitchActive = () => {
    form.change(
      ESupplierBusinessInfoFormFields.BUSINESS_OWNER_SWITCH,
      !isBusinessSwitchActiveField,
    );
    if (!isBusinessSwitchActiveField) {
      form.change(EPersonOfInterestType.OWNER, {
        ...ownerField.input.value,
        ...signatorySharedFieldsValue,
      });
    }
  };
  const handleControllerSwitchActive = () => {
    form.change(
      ESupplierBusinessInfoFormFields.CONTROLLER_SWITCH,
      !isControllerSwitchActiveField,
    );
    if (!isControllerSwitchActiveField) {
      form.change(EPersonOfInterestType.CONTROLLER, {
        ...controllerField.input.value,
        ...signatorySharedFieldsValue,
      });
    }
  };

  useEffect(() => {
    if (isLegalEntityTypeIndividual && isBusinessSwitchActiveField) {
      form.change(ESupplierBusinessInfoFormFields.BUSINESS_OWNER_SWITCH, false);
    }
  }, [isLegalEntityTypeIndividual, isBusinessSwitchActiveField, form]);

  return (
    <FormGroup
      columnNumber={1}
      columnNumberXLg={isLegalEntityTypeIndividual ? 2 : 3}
      gap={pagePadding}
      alignItems='start'
    >
      {!isLegalEntityTypeIndividual && (
        <SignatoryWrapper>
          <OperatingExecutiveItem
            operationExecutiveType={EPersonOfInterestType.SIGNATORY}
            isLegalEntityTypeIndividual={isLegalEntityTypeIndividual}
          />
        </SignatoryWrapper>
      )}
      <FormGroup columnNumber={1} gap={pagePadding}>
        <OperatingExecutiveItem
          operationExecutiveType={EPersonOfInterestType.OWNER}
          hasValidation={!isBusinessSwitchActiveField}
          disabled={isBusinessSwitchActiveField}
          isSwitchActiveField={isBusinessSwitchActiveField}
          handleSwitch={handleBusinessSwitchActive}
          isLegalEntityTypeIndividual={isLegalEntityTypeIndividual}
        />
      </FormGroup>
      {!isLegalEntityTypeIndividual && (
        <FormGroup columnNumber={1} gap={pagePadding}>
          <OperatingExecutiveItem
            operationExecutiveType={EPersonOfInterestType.CONTROLLER}
            hasValidation={!isControllerSwitchActiveField}
            disabled={isControllerSwitchActiveField}
            isSwitchActiveField={isControllerSwitchActiveField}
            handleSwitch={handleControllerSwitchActive}
            isLegalEntityTypeIndividual={isLegalEntityTypeIndividual}
          />
        </FormGroup>
      )}
    </FormGroup>
  );
}
