import React from 'react';
import LeftSideItem from './LeftSideItem';
import TextValue from 'view/components/TextValue';

import {fontSizeMd} from 'constants/styleVars';
import {Group} from 'styles/basicStyledComponents/group';

type TProps = {
  isHidden?: boolean;
};

const ItemPaymentInformation = ({isHidden}: TProps) => {
  if (isHidden) return null;

  return (
    <LeftSideItem
      item={{
        title: 'Payment information',
        children: (
          <Group direction='column'>
            <TextValue fontSize={fontSizeMd} bold>
              Make your payment directly to the venue.
            </TextValue>
            <TextValue fontSize={fontSizeMd}>
              The venue will verify your payment method after your booking has
              been confirmed, and they will contact you directly to arrange for
              it.
            </TextValue>
          </Group>
        ),
      }}
    />
  );
};

export default ItemPaymentInformation;
