export enum EOpeningHoursMode {
  EDIT = 'edit',
  VIEW = 'view',
}

export enum EFieldNames {
  SAME_EVERYDAY = 'sameEveryday',
  OPERATIONAL_TIMES = 'operationalTimes',
  OPEN_HOURS_FROM = 'openHoursFrom',
  OPEN_HOURS_TO = 'openHoursTo',
}
