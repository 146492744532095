import React, {useState} from 'react';

import NW2Switch from 'view/components/NW2Switch';
import NW2Badge from 'view/components/NW2Badge';
import Select from './Select';

import {capitalizeText} from 'utils/stringUtils';
import {IDay} from 'types/dto/IDay.type';
import {EDays} from 'constants/days';
import {
  DEFAULT_OPEN_FROM_HOUR,
  DEFAULT_OPEN_FROM_MINUTE,
  DEFAULT_OPEN_TO_HOUR,
  DEFAULT_OPEN_TO_MINUTE,
  WHOLE_DAY_OPEN_FROM_HOUR,
  WHOLE_DAY_OPEN_FROM_MINUTE,
  WHOLE_DAY_OPEN_TO_HOUR,
  WHOLE_DAY_OPEN_TO_MINUTE,
} from 'constants/NW2OpenHours';
import {
  createHoursOptions,
  filterMaxOptions,
  filterMinOptions,
} from 'utils/operationalHoursUtils';
import {
  RowContainer,
  RowContainerWholeDay,
  RowItem,
} from './OpenHoursRow.styles';

interface IProps {
  operationalTime: IDay;
  name: EDays;
  isLoading?: boolean;
  onBadgeClick: () => void;
  updateFields: (day: string, values: Partial<IDay>) => void;
}

const OpenHoursRow = ({
  operationalTime,
  name,
  isLoading,
  onBadgeClick,
  updateFields,
}: IProps) => {
  const {timeStartHour, timeStartMinutes, timeEndHour, timeEndMinutes, active} =
    operationalTime;

  const initially24HoursAccess =
    timeStartHour == WHOLE_DAY_OPEN_FROM_HOUR &&
    timeStartMinutes == WHOLE_DAY_OPEN_FROM_MINUTE &&
    timeEndHour == WHOLE_DAY_OPEN_TO_HOUR &&
    timeEndMinutes == WHOLE_DAY_OPEN_TO_MINUTE;

  const [wholeDayAccess, setWholeDayAccess] = useState(initially24HoursAccess);

  const handle24HourClick = () => {
    updateFields(name, {
      ['timeStartHour']: wholeDayAccess
        ? DEFAULT_OPEN_FROM_HOUR
        : WHOLE_DAY_OPEN_FROM_HOUR,
      ['timeStartMinutes']: wholeDayAccess
        ? DEFAULT_OPEN_FROM_MINUTE
        : WHOLE_DAY_OPEN_FROM_MINUTE,
      ['timeEndHour']: wholeDayAccess
        ? DEFAULT_OPEN_TO_HOUR
        : WHOLE_DAY_OPEN_TO_HOUR,
      ['timeEndMinutes']: wholeDayAccess
        ? DEFAULT_OPEN_TO_MINUTE
        : WHOLE_DAY_OPEN_TO_MINUTE,
    });

    setWholeDayAccess(!wholeDayAccess);
  };

  const handleOpenFromChange = (value: string) => {
    const time = value.split(':');

    updateFields(name, {
      ['timeStartHour']: time[0],
      ['timeStartMinutes']: time[1],
    });
  };

  const handleOpenToChange = (value: string) => {
    const time = value.split(':');

    updateFields(name, {
      ['timeEndHour']: time[0],
      ['timeEndMinutes']: time[1],
    });
  };

  return !wholeDayAccess ? (
    <>
      <RowContainer isActive={active}>
        <RowItem>
          <NW2Badge
            label={capitalizeText(name)}
            isActive={active}
            fullWidth
            disabled={isLoading}
            onClick={onBadgeClick}
            size='small'
          />
        </RowItem>
        {active && (
          <>
            <RowItem>
              <Select
                name={`openFrom-day`}
                label='From'
                readonly
                options={filterMinOptions(timeEndHour, createHoursOptions())}
                onItemChange={handleOpenFromChange}
                inputProps={{
                  value: `${timeStartHour}:${
                    timeStartMinutes == '0' ? '00' : timeStartMinutes
                  }`,
                  disabled: isLoading,
                }}
              />
            </RowItem>

            <RowItem>
              <Select
                name={`openTo-day`}
                label='To'
                readonly
                options={filterMaxOptions(timeStartHour, createHoursOptions())}
                onItemChange={handleOpenToChange}
                inputProps={{
                  value: `${timeEndHour}:${
                    timeEndMinutes == '0' ? '00' : timeEndMinutes
                  }`,
                  disabled: isLoading,
                }}
              />
            </RowItem>

            <RowItem>
              <NW2Switch
                label='24 hour access'
                isActive={initially24HoursAccess}
                disabled={isLoading}
                onClick={handle24HourClick}
              />
            </RowItem>
          </>
        )}
      </RowContainer>
    </>
  ) : (
    <RowContainerWholeDay>
      <RowItem>
        <NW2Badge
          label={capitalizeText(name)}
          isActive={active}
          fullWidth
          disabled={isLoading}
          onClick={onBadgeClick}
          size='small'
        />
      </RowItem>
      {active && (
        <RowItem>
          <NW2Switch
            label='24 hour access'
            isActive={initially24HoursAccess}
            disabled={isLoading}
            onClick={handle24HourClick}
          />
        </RowItem>
      )}
    </RowContainerWholeDay>
  );
};

export default OpenHoursRow;
