import React, {ChangeEvent, useState} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';
import {useField, useForm} from 'react-final-form';

import NW2ModalDrawer from 'view/components/NW2ModalDrawer';
import ActionCardClosable from 'view/components/ActionCardClosable';
import TextPopover from 'view/venue/CreateVenue/pages/ContactDetails/components/ContactDetailsForm/TextPopover';
import NW2Button from 'view/components/NW2Button';
import CustomList from 'view/components/CustomList';

import {ELegalEntityType, LegalEntityTypeCaption} from 'types/venue';
import {setSelectOptions} from 'view/components/NW2FormItem/components/Select/utils';
import {NW2FormItemSelect} from 'view/components/NW2FormItem/NW2FormItem';
import {ESupplierBusinessInfoFormFields} from 'view/components/NW2Forms/NW2SupplierLegalDetailsForm/types';
import {RootState} from 'store/types';
import {requiredFieldRules} from 'utils/finalFormFieldRules';

import {FormInnerContainer} from 'view/venue/NW2VenueProfile/components/NMMPayout/components/EditPaymentForm/EditPaymentForm.styles';
import {modalSmallWidth} from 'styles/configs/variables';
import {NW2Gray600Color, warnColor} from 'styles/configs/colors';
import {Group} from 'styles/basicStyledComponents/group';
import {offsetSm} from 'styles/configs/offset';
import {INPUT_HEIGHT} from 'view/components/NW2FormItem/NW2FormItem.styles';

const textBusinessToIndividual = (
  <>
    <b>Changing your legal entity type to Individual</b>
    <br />
    For this entity type, some information are no longer required and subject
    for deletion such as
    <br />
    <CustomList
      list={[{value: 'Legal information'}, {value: 'Operating executives'}]}
      size='small'
    />
  </>
);

const textIndividualToBusiness = (
  <>
    <b>Updating your legal entity type to business</b>
    <br />
    For the business entity you chose, we require different information from
    you.
    <br />
    Some information will be deleted, but we also require some additional
    information.
  </>
);

const actionCardClosableIconProps = {
  size: 20,
  warn: true,
};

const arrayOfLegalEntities = [
  {
    key: ELegalEntityType.BUSINESS,
    value: LegalEntityTypeCaption[ELegalEntityType.BUSINESS],
  },
  {
    key: ELegalEntityType.INDIVIDUAL,
    value: LegalEntityTypeCaption[ELegalEntityType.INDIVIDUAL],
  },
];

const legalEntityOptions = setSelectOptions(arrayOfLegalEntities, {
  key: 'key',
  value: 'value',
});

export function LegalEntityBlock({
  isLegalEntityTypeIndividual,
}: {
  isLegalEntityTypeIndividual: boolean;
}) {
  const [isModalShowed, setIsModalShowed] = useState(false);

  const isMobile = useSelector((state: RootState) =>
    _get(state, 'app.deviceType.isMobile'),
  );

  const form = useForm();
  const {input} = useField(
    ESupplierBusinessInfoFormFields.LOCAL_LEGAL_ENTITY_TYPE,
  );

  const onChangeLegalEntity = (
    event: string | ChangeEvent<HTMLSelectElement>,
  ) => {
    setIsModalShowed(true);
    form.change(ESupplierBusinessInfoFormFields.LOCAL_LEGAL_ENTITY_TYPE, event);
  };

  const onModalClose = () => {
    setIsModalShowed(false);
  };

  const onUpdateEntity = () => {
    form.batch(() => {
      form.change(
        ESupplierBusinessInfoFormFields.LEGAL_ENTITY_TYPE,
        input.value,
      );
      if (input.value === LegalEntityTypeCaption[ELegalEntityType.INDIVIDUAL]) {
        form.change(ESupplierBusinessInfoFormFields.COMPANY_NAME, undefined);
        form.change(
          ESupplierBusinessInfoFormFields.REGISTRATION_NUMBER,
          undefined,
        );
      }
    });

    onModalClose();
  };

  const footerModal = (
    <Group gap={offsetSm} justify='flex-end'>
      <NW2Button
        buttonType='secondary'
        onClick={onModalClose}
        size='small'
        inline
      >
        Cancel
      </NW2Button>
      <NW2Button
        buttonType='primary'
        onClick={onUpdateEntity}
        size='small'
        inline
      >
        Update Entity
      </NW2Button>
    </Group>
  );

  return (
    <>
      <FormInnerContainer>
        <NW2FormItemSelect
          name={ESupplierBusinessInfoFormFields.LEGAL_ENTITY_TYPE}
          label='Legal entity*'
          placeholder='Legal entity*'
          options={legalEntityOptions}
          labelColor={NW2Gray600Color}
          rules={requiredFieldRules('Legal entity')}
          onChange={onChangeLegalEntity}
          readonly
        />
        <TextPopover textIndex={0} iconHeight={INPUT_HEIGHT} />
      </FormInnerContainer>

      <NW2ModalDrawer
        isMobile={isMobile}
        isShowed={isModalShowed}
        header='important'
        body={
          <ActionCardClosable
            isClosable={false}
            borderColor={warnColor}
            icon='WARN'
            iconProps={actionCardClosableIconProps}
            description={
              isLegalEntityTypeIndividual
                ? textIndividualToBusiness
                : textBusinessToIndividual
            }
          />
        }
        footer={footerModal}
        onClose={onModalClose}
        drawerHeight='auto'
        modalWidth={modalSmallWidth}
      />
    </>
  );
}
