import styled, {css} from 'styled-components';

import {
  borderRadiusXLg,
  fontSizeMd,
  fontSizeSm,
  fontWeightBold,
  fontWeightExtraBold,
  lineHeightLg,
  lineHeightMd,
  NW2BackIconSize,
  NW2Gray200Color,
  NW2Gray900Color,
  offsetDef,
  offsetSm,
  offsetXLg,
  offsetXXLg,
} from 'constants/styleVars';

const text = css`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  color: ${NW2Gray900Color};
  margin: 0;
`;

export const PlainText = styled.p`
  ${text}
`;

export const TextTitle = styled.h3`
  ${text};
  font-weight: ${fontWeightBold};
  margin-bottom: ${offsetDef};
`;

export const Text = styled.p`
  ${text};
  margin-bottom: ${offsetXXLg};
`;

export const OfferReviewMobileActions = styled.div`
  display: flex;
  gap: ${offsetSm};
  justify-content: space-between;
`;

export const StyledHeader = styled.div`
  display: grid;
  grid-template-columns: ${NW2BackIconSize} 1fr;
  grid-column-gap: ${offsetXLg};
  align-items: center;
  margin-bottom: ${offsetXXLg};
`;

export const StyledTitle = styled.div`
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
  font-weight: ${fontWeightExtraBold};
  text-transform: uppercase;
  letter-spacing: 0.02em;
`;

export const UnitWrapper = styled.div`
  padding: ${offsetXLg};
  border-radius: ${borderRadiusXLg};
  border: 1px solid ${NW2Gray200Color};
`;
