import React, {FC, useEffect, useState} from 'react';
import {Popover} from 'antd';

import {useAppDispatch, useAppSelector} from 'store/hooks';
import Icon from 'view/components/Icon';
import PackagesOptions from './PackagesOptions';

import {ESwitchSizes} from 'view/components/NW2Switch/NW2Switch.types';
import {updateCustomerSelectedPackages} from 'store/customer/customerSlice';
import {isPackageTypeAvailable} from 'view/venue/NW2BookingPreview/helpers';
import {deletePackageBookingPreview} from 'store/venues/actions';

import {
  PackageSwitchContainer,
  StyledInfoIcon,
  StyledPackageSwitch,
  StyledPopoverContent,
  StyledPopoverTitle,
} from '../Space.styles';
import {EPackageType, IPackageResponse} from 'types/dto/IPackages.type';

type TProps = {
  participants: number;
  dayIndex: number;
  unitId: number;
  isSpaceEditMode?: boolean;
  customerBookedPackage?: IPackageResponse;
  meetingDuration?: number;
  guestSelectedPackage?: IPackageResponse;
  isGuestBookingPreviewMode?: boolean;
};
const Packages: FC<TProps> = ({
  dayIndex,
  unitId,
  participants,
  isSpaceEditMode,
  customerBookedPackage,
  meetingDuration,
  guestSelectedPackage,
  isGuestBookingPreviewMode,
}) => {
  const dispatch = useAppDispatch();

  const isMobile: boolean = useAppSelector(({app}) => app.deviceType.isMobile);
  const packages = useAppSelector(({packages}) => packages.packages);

  const isHalfDayAvailable = isPackageTypeAvailable(
    packages,
    EPackageType.HALF_DAY,
  );
  const isFullDayAvailable = isPackageTypeAvailable(
    packages,
    EPackageType.FULL_DAY,
  );
  const isFullDayDinnerAvailable = isPackageTypeAvailable(
    packages,
    EPackageType.FULL_DAY_WITH_DINNER,
  );

  const isPackagesInUnit =
    isHalfDayAvailable || isFullDayAvailable || isFullDayDinnerAvailable;
  const isSwitchDisabled = participants <= 9 || isSpaceEditMode;

  const [isSwitchShown, setIsSwitchShown] = useState(
    isPackagesInUnit || isGuestBookingPreviewMode,
  );

  // TODO: Find better solution to apply package once
  const [defaultPackageApplied, setDefaultPackageApplied] = useState(false);

  useEffect(() => {
    if (!isSwitchShown && !defaultPackageApplied && isPackagesInUnit) {
      setIsSwitchShown(true);
      setDefaultPackageApplied(true);
    }
  }, [defaultPackageApplied, isPackagesInUnit, isSwitchShown]);

  const [isInitialMount, setIsInitialMount] = useState(true);
  const [isPackagesOptions, setIsPackagesOptions] = useState(
    Boolean(customerBookedPackage),
  );
  const [defaultPackageType, setDefaultPackageType] = useState<
    EPackageType | undefined
  >();

  useEffect(() => {
    if (customerBookedPackage) {
      setIsPackagesOptions(true);
    }
  }, [customerBookedPackage]);

  useEffect(() => {
    meetingDuration && meetingDuration <= 6 && isHalfDayAvailable
      ? setDefaultPackageType(EPackageType.HALF_DAY)
      : isFullDayAvailable
      ? setDefaultPackageType(EPackageType.FULL_DAY)
      : isFullDayDinnerAvailable
      ? setDefaultPackageType(EPackageType.FULL_DAY_WITH_DINNER)
      : setIsSwitchShown(false);

    if (isGuestBookingPreviewMode && isPackagesInUnit) {
      setIsSwitchShown(true);
      if (guestSelectedPackage && isInitialMount) {
        setIsPackagesOptions(true);
        setDefaultPackageType(guestSelectedPackage?.type);
        setIsInitialMount(false);
      }
    }
  }, [
    meetingDuration,
    isFullDayAvailable,
    isFullDayDinnerAvailable,
    isHalfDayAvailable,
    isPackagesInUnit,
    guestSelectedPackage,
    isInitialMount,
    isGuestBookingPreviewMode,
  ]);

  const handelPackageOptions = () => {
    // when toggle switched off, remove package data from particular day
    if (isPackagesOptions) {
      dispatch(
        updateCustomerSelectedPackages({
          newPackage: null,
          dayIndex,
          unitId,
        }),
      );
      dispatch(deletePackageBookingPreview(dayIndex, unitId));
    }
    setIsPackagesOptions((prev) => !prev);
  };

  return (
    <>
      {isSwitchShown ? (
        <>
          {!isSpaceEditMode && (
            <PackageSwitchContainer isPackagesOptions={isPackagesOptions}>
              <StyledPackageSwitch
                isActive={isPackagesOptions}
                label='Package option'
                onClick={handelPackageOptions}
                disabled={isSwitchDisabled}
                size={ESwitchSizes.MEDIUM}
              />
              <Popover
                content={
                  <StyledPopoverContent>
                    Package option is only available for meetings of 10 <br />{' '}
                    people or more.
                  </StyledPopoverContent>
                }
                trigger='hover'
                title={<StyledPopoverTitle>Package option</StyledPopoverTitle>}
                placement={isMobile ? 'topRight' : 'topLeft'}
              >
                <StyledInfoIcon>
                  <Icon icon='INFORMATION_CIRCLE' />
                </StyledInfoIcon>
              </Popover>
            </PackageSwitchContainer>
          )}
          {isPackagesOptions && (
            <PackagesOptions
              packages={packages}
              customerBookedPackage={customerBookedPackage}
              isSpaceEditMode={isSpaceEditMode}
              dayIndex={dayIndex}
              unitId={unitId}
              defaultPackageType={defaultPackageType}
            />
          )}
        </>
      ) : null}
    </>
  );
};

export default Packages;
