import React, {useMemo} from 'react';

import BillingAddressView from 'view/components/BillingAddress/BillingAddressView';
import VenueDetailsCard from 'view/venue/NW2BookingConfirmation/components/VenueDetailsCard';
import LeftSideItem from 'view/venue/components/LeftSideContainer/LeftSideItem';
import AboutMeeting from 'view/venue/NW2BookingConfirmation/components/AboutMeeting';
import NW2BookingConfirmationUnitsBlock from 'view/venue/NW2BookingConfirmation/components/NW2BookingConfirmationUnitsBlock';
import BookingConfirmCancellationPolicy from '../BookingConfirmCancellationPolicy/BookingConfirmCancellationPolicy';
import {OfferRequestReviewUnits} from 'view/venue/Offer/components/OfferRequestReviewUnits/OfferRequestReviewUnits';
import OfferOptionDate from 'view/venue/Offer/components/OfferOptionDate/OfferOptionDate';
import ItemPaymentInformation from 'view/venue/components/LeftSideContainer/ItemPaymentInformation';

import {WhatIsNext} from 'view/venue/Offer/components/WhatIsNext/WhatIsNext';
import {Bedrooms} from 'view/venue/NW2BookingPreview/components/Bedrooms/Bedrooms';

import {useAppSelector} from 'store/hooks';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {EBookingStatus} from 'types/booking';
import {ICustomerBillingAddress} from 'types/dto/IUser.types';
import {StyledLeftSideContainer} from 'view/venue/components/LeftSideContainer/LeftSideContainer.styles';
import {
  EGroupStatus,
  EOfferStatus,
  ERequestStatus,
  IOfferRequestDay,
} from 'types/offer';
import {getFilteredUnitsByEventType} from 'utils/venueUtils';

type TProps = {
  orderNumber?: string;
  bookingStatus?: EBookingStatus | ERequestStatus | EOfferStatus;
  billingAddress?: ICustomerBillingAddress;
  meetingName?: string;
  customerDeleted?: boolean;
  isOffer?: boolean;
  isOfferOrRequest?: boolean;
  isGroupRequestPage?: boolean;
  groupStatus?: EGroupStatus | null;
  accommodationType?: EAccommodationType;
  preArrivals?: IOfferRequestDay[];
  postEvents?: IOfferRequestDay[];
};

function BookingConfirmationBlockList({
  orderNumber,
  accommodationType,
  bookingStatus,
  billingAddress,
  customerDeleted,
  meetingName,
  isOffer,
  isOfferOrRequest,
  preArrivals,
  postEvents,
  isGroupRequestPage,
  groupStatus,
}: TProps) {
  const customerBookedUnits = useAppSelector(
    ({customer}) => customer.customerBookedUnits,
  );

  const isPublicVenue = accommodationType === EAccommodationType.VENUE;
  const isVenueContactsHidden = bookingStatus !== EBookingStatus.CONFIRMED;

  const customerMeetingBookedUnits = customerBookedUnits
    ? getFilteredUnitsByEventType(customerBookedUnits)
    : null;

  //TODO: replace with more common solution after cut out unitBookingDtos
  const bookedUnitsCount =
    useMemo(
      () =>
        customerBookedUnits?.reduce((acc, item) => {
          if (item.unitBookings.length) {
            acc += item.unitBookings.length;
          }
          return acc;
        }, 0),
      [customerBookedUnits],
    ) || 1;

  if (!orderNumber) return null;

  return (
    <StyledLeftSideContainer>
      <LeftSideItem
        item={{
          title: 'Venue contact details',
          children: <VenueDetailsCard />,
        }}
        isHidden={isVenueContactsHidden || isOfferOrRequest}
      />
      <LeftSideItem
        item={{
          title: 'Important',
          children: <BookingConfirmCancellationPolicy />,
        }}
        isHidden={!isPublicVenue || isOfferOrRequest}
      />
      <LeftSideItem
        item={{
          title: 'About meeting',
          children: <AboutMeeting meetingName={meetingName} />,
        }}
        isHidden={!meetingName}
      />
      {bookingStatus === ERequestStatus.REQUEST_PENDING && (
        <>
          <LeftSideItem
            item={{
              title: 'Option date',
              children: <OfferOptionDate />,
            }}
          />
        </>
      )}

      {!!preArrivals?.length && (
        <Bedrooms
          rooms={preArrivals}
          title='PRE-ARRIVAL'
          //TODO: bedrooms timeRange
          // hasDates={!isOfferOrRequest}
        />
      )}

      {isOfferOrRequest ? (
        <OfferRequestReviewUnits
          isOffer={isOffer}
          isGroupRequestPage={isGroupRequestPage}
          hasPreArrivals={!!preArrivals?.length}
          hasPostEvents={!!postEvents?.length}
        />
      ) : (
        customerMeetingBookedUnits && (
          <NW2BookingConfirmationUnitsBlock
            customerBookedUnits={customerMeetingBookedUnits}
            isMultiRooms={bookedUnitsCount > 1}
            hasPreArrivals={!!preArrivals?.length}
            hasPostEvents={!!postEvents?.length}
          />
        )
      )}

      {!!postEvents?.length && (
        <Bedrooms
          rooms={postEvents}
          title='POST-EVENT'
          //TODO: bedrooms timeRange
          // hasDates={!isOfferOrRequest}
        />
      )}
      <>
        {/*TODO: task NWRK-2780, NWRK-2781 */}
        {/* do not remove because we may need it in some nearest future */}
        {/*<LeftSideItem*/}
        {/*  item={{*/}
        {/*    title: 'Payment Method',*/}
        {/*    children: <Nw2BookingConfirmationPaymentMethod />,*/}
        {/*  }}*/}
        {/*  isHidden={!isPublicVenue || customerDeleted || isOfferOrRequest}*/}
        {/*/>*/}

        <ItemPaymentInformation
          isHidden={accommodationType === EAccommodationType.CORPORATE_OFFICE}
        />

        <LeftSideItem
          item={{
            title: 'Company address',
            children: (
              <BillingAddressView
                billingAddress={billingAddress}
                showIcon={bookingStatus !== EOfferStatus.OFFER_CONFIRMED}
              />
            ),
          }}
          isHidden={!billingAddress || customerDeleted || isOfferOrRequest}
        />
      </>
      {(bookingStatus === ERequestStatus.REQUEST_PENDING ||
        groupStatus === EGroupStatus.GROUP_REQUEST_PENDING) && (
        <LeftSideItem
          item={{
            title: 'What’s next',
            children: <WhatIsNext isGroupRequest={isGroupRequestPage} />,
          }}
        />
      )}
    </StyledLeftSideContainer>
  );
}

export default BookingConfirmationBlockList;
