import {TImageFile} from 'view/components/ImageUploaderComponent';
import {IVenue} from 'types/venue';
import {UploadFile} from 'antd/es/upload/interface';
import {EFeatureToggles} from 'constants/featureToggles';
import {ERoomType} from 'types/dto/ERoomType.type';
import {EUserRoleCognito} from './dto/EUserRoleCognito';

export enum EAppEnvironment {
  DEMO = 'demo',
  DEV = 'dev',
  FEATURE = 'feature',
  PROD = 'prod',
  QA = 'qa',
  STG = 'stg',
  NMM_DEV = 'nmm_dev',
}

export interface IFeatureToggleItem {
  id: EFeatureToggles;
  enable: boolean;
}

export enum EAppTheme {
  THEME_WHITE = 'THEME_WHITE',
  THEME_DARK = 'THEME_DARK',
}

export type TSharePopupId = 'EMPTY' | 'SHARE_VENUE';
export interface IShareComponentData {
  title: any;
  content: any;
}
export interface IShareComponents {
  SHARE_VENUE: IShareComponentData;
  EMPTY: IShareComponentData;
}
export type TSharePopupProps = {[key: string]: any};
export interface ISharePopup {
  id: TSharePopupId;
  props?: TSharePopupProps;
}

export type TMultiModalActiveComponentId =
  | ''
  | 'adminAddCompany'
  | 'adminAddCompanyUser'
  | 'adminEditCompanyUser'
  | 'adminReassignCompanyUser'
  | 'propManagerWelcomeModal';
export type TMultiModalActiveComponentProps = {[key: string]: any};

export interface IMultiModalActiveComponent {
  id: TMultiModalActiveComponentId;
  props?: TMultiModalActiveComponentProps;
}

export type IMultiModalComponents = {[key: string]: any};

export enum ENW2MultiModalActiveComponentId {
  EMPTY = '',
  IMPRINT_MODAL = 'imprintModal',
  COOKIE_POLICY_MODAL = 'cookiePolicyModal',
  // Terms and Conditions
  SUPPLIER_TERMS_AND_CONDITIONS_MODAL = 'supplierTermsAndConditionsModal',
  CUSTOMER_TERMS_AND_CONDITIONS_MODAL = 'customerTermsAndConditionsModal',
  CORPORATE_USER_TERMS_AND_CONDITIONS_MODAL = 'corporateUserTermsAndConditionsModal',
  // Privacy Policy
  SUPPLIER_PRIVACY_POLICY_MODAL = 'supplierPrivacyPolicyModal',
  CUSTOMER_PRIVACY_POLICY_MODAL = 'customerPrivacyPolicyModal',
  CORPORATE_USER_PRIVACY_POLICY_MODAL = 'corporateUserPrivacyPolicyModal',
  // FAQ
  SUPPLIER_FAQ_MODAL = 'supplierFAQModal',
  CUSTOMER_FAQ_MODAL = 'customerFAQModal',
  CORPORATE_USER_FAQ_MODAL = 'corporateUserFAQModal',
  // Announcement
  ADMIN_ANNOUNCEMENT_ADD_EDIT_MODAL = 'adminAnnouncementAddEditModal',
}

export type TUserRoleNW2MultiModalIdMap = {
  [key in EUserRoleCognito]: ENW2MultiModalActiveComponentId;
};

export type TNW2MultiModalActiveComponentProps = {[key: string]: any};

export interface INW2MultiModalActiveComponent {
  id: ENW2MultiModalActiveComponentId;
  props?: TNW2MultiModalActiveComponentProps;
}

export type INW2MultiModalComponents = {[key: string]: any};

export type TStepMode = 'add' | 'view';

export type TSize = 'small' | 'etc' | 'large';

export type TVerticalAlign = 'flex-start' | 'center' | 'flex-end' | 'stretch';

export interface ICreateUpdateImage {
  coverImage?: string;
  images?: TImageFile[];
  venueId: number;
  spaceId?: number;
  entityName: string;
  idsToDelete?: string[];
  handleProgress?: (v: number) => void;
}

export type TImage = {
  documentType: null;
  entityType: string;
  fileName: string;
  url: string;
  fileType: string;
  id: number;
  isCover: null | boolean;
  name: string;
  size: number;
  uid: string;
  status: string;
  type: string;
};

export enum ESortFields {
  COMPANY = 'COMPANY',
  DATE_END = 'DATE_END',
  DATE_START = 'DATE_START',
  STATUS = 'STATUS',
  UNIT_NAME = 'UNIT_NAME',
}

export enum ETopNotificationId {
  PENDING_REQUEST = 'PENDING_REQUEST',
  POPUP_NOTIFICATION = 'POPUP_NOTIFICATION',
  VENUE_PAYOUT_STATUS = 'VENUE_PAYOUT_STATUS',
}

export type TTopNotificationProps = Record<string, any>;

export interface ITopNotification {
  id: ETopNotificationId;
  props?: TTopNotificationProps;
}

export enum EOpenPasswordModalTypes {
  MODIFY = 'MODIFY',
  REGISTER = 'REGISTER',
}

export type TOpenPasswordModalProps = {
  pageName: string;
  btnOkText: string;
  type: EOpenPasswordModalTypes;
  notificationMessage: string;
};

export type TAuthorizationPopups = {
  openLoginPopup?: boolean;
  openRegisterPopup?: boolean;
  openPasswordPopup?: boolean | TOpenPasswordModalProps;
  openForgotPasswordPopup?: boolean;
  loginTitle?: string;
  loginDescription?: string;
  setCustomerBillingAddressPopup?: boolean;
  isPaymentMethodsPopup?: boolean;
};

export type TDeviceType = {
  width?: number;
  height?: number;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
};

export interface IAccountHolderPayload {
  userId: string | number;
  accommodationId: string | number;
}

export type TInitialLocation = {
  pathname?: string;
  search?: string;
};
export type IVenueWithoutContactAndInterest = Omit<
  IVenue,
  'legalContact' | 'peopleOfInterest'
>;
export type TCreateVenuePayload = {
  venue: IVenue | IVenueWithoutContactAndInterest;
  files?: UploadFile[];
  fieldName?: string;
  isRegistration?: boolean;
  isOfferRequest?: boolean;
};

export type TCreateCorporateVenuePayload = TCreateVenuePayload & {
  accountId: number | string | null;
};

export type TRequestToHRSSupportPayload = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  location: string;
  date?: string;
  participants?: string;
  accommodationName?: string;
  additionalComment?: string;
  subjectPlaceholder?: string;
  roomType?: ERoomType;
};

export enum FeedbackSubjectTypes {
  PROBLEM = 'FEEDBACK',
  RECOMMENDATION = 'RECOMMENDATION',
  QUESTION = 'QUESTION',
  CRASH_REPORT = 'CRASH REPORT',
}

export type TFeedbackFormPayload = {
  feedbackSubject: FeedbackSubjectTypes;
  body: string;
};

export interface ISendFeedbackPayload extends TFeedbackFormPayload {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  fullPath?: string;
}
