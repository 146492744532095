type LocalStorageKey =
  | 'token'
  | 'venueId'
  | 'userId'
  | 'corporateAccountId'
  | 'orderNumber'
  | 'userData'
  | 'orderId'
  | 'AdyenCardAction'
  | 'multiSearchData'
  | 'multiVenueOfferPayload'
  | 'hmdToken'
  | 'selectedOfficeId'
  | 'selectedVenueUnits'
  | 'bedroomsCatering'
  | 'shortList'
  | 'multiVenuesPayload'
  | 'bookingPreviewData'
  | 'isFastSearchEnabled';

const LocalStorageService = {
  setByKey: (key: LocalStorageKey, value: string) =>
    localStorage.setItem(key, value),
  getByKey: (key: LocalStorageKey) => localStorage.getItem(key),
  removeItemByKey: (key: LocalStorageKey) => localStorage.removeItem(key),

  // setTokenAndEntityIdFromBE: (
  //   authResponse: IAuthDataResponse,
  //   dispatch: any,
  // ) => {
  //   const parsedToken = tokenService.parseJwt(authResponse.token);
  //   const userRole = tokenService.getRoles(parsedToken)[0];
  //   const cognitoCustomerId = parsedToken['custom:userId'];
  //   const isSupplier = userRole === EUserRoleCognito.ROLE_SUPPLIER;
  //   const supplierVenueId = authResponse.venueId;
  //   const entityId = isSupplier ? supplierVenueId : cognitoCustomerId;
  //
  //   dispatch(setSupplierVenueId(venueId));
  //
  //   LocalStorageService.setByKey(
  //     isSupplier ? 'venueId' : 'userId',
  //     entityId.toString(),
  //   );
  //
  //   dispatch(setToken(authResponse.token));
  //   LocalStorageService.setByKey('token', authResponse.token);
  //   dispatch(
  //     setCorporateAccountId(
  //       authResponse.corporateAccountId
  //         ? +authResponse.corporateAccountId
  //         : null,
  //     ),
  //   );
  //   LocalStorageService.setByKey(
  //     'corporateAccountId',
  //     authResponse.corporateAccountId || '',
  //   );
  // },
};

export default LocalStorageService;
