import React, {useState} from 'react';

import NMMSection from 'view/components/NMMSection';
import OperationalHoursInfo from 'view/venue/components/OperationalHoursInfo';
import OperationalHoursForm from './components/OpenHoursForm';

import {defaultOperationalTimes} from 'constants/defaultOperationalTimes';
import {IDay} from 'types/dto/IDay.type';
import {EVenueProfileSections} from '../../types';
import {EOpeningHoursMode} from './NW2OpeningHours.types';
import {IVenue} from 'types/venue';
import {useAppSelector} from 'store/hooks';
import {useOperationalTimes} from '../../../hooks/useOperationalTimes';

interface IProps {
  venue: IVenue;
}

const NW2OpeningHours = ({venue}: IProps) => {
  const [mode, setMode] = useState<EOpeningHoursMode>(EOpeningHoursMode.VIEW);

  const {operationalTimes: operationalTimesFromStore} = useAppSelector(
    ({venueDetails}) => venueDetails,
  );

  const openEditMode = () => setMode(EOpeningHoursMode.EDIT);

  const openViewMode = () => setMode(EOpeningHoursMode.VIEW);

  const workDays: IDay[] = operationalTimesFromStore || defaultOperationalTimes;

  useOperationalTimes();

  return (
    <NMMSection
      id={EVenueProfileSections.OPENING_HOURS}
      title='Opening hours'
      subtitle='When we are open'
      isEditOn={mode === EOpeningHoursMode.EDIT}
      onEdit={openEditMode}
    >
      {mode === EOpeningHoursMode.EDIT ? (
        <OperationalHoursForm
          venue={venue}
          workDays={workDays}
          onEnd={openViewMode}
        />
      ) : (
        <OperationalHoursInfo workDays={workDays} />
      )}
    </NMMSection>
  );
};

export default NW2OpeningHours;
