import {EBedroomExtrasNames, EResourcesCode} from 'types/dto/IExtras.type';

export const BEDROOM_EXTRAS = {
  SINGLE: 'Single use guest rooms',
  DOUBLE: 'Double use guest rooms',
};

export const BEDROOM_CATERING = {
  WITH_BREAKFAST: 'With breakfast',
  NO_BREAKFAST: 'No breakfast',
};

export const ACCOMMODATION_FORM_FIELD_NAME = 'accommodation';

export const BEDROOMS_NAMES: {[key in EResourcesCode]?: string} = {
  [EResourcesCode.SINGLE_BEDROOM]: 'Single use guest rooms (no breakfast)',
  [EResourcesCode.SINGLE_BEDROOM_WITH_BREAKFAST]:
    'Single use guest rooms (with breakfast)',
  [EResourcesCode.DOUBLE_BEDROOM]: 'Double use guest rooms (no breakfast)',
  [EResourcesCode.DOUBLE_BEDROOM_WITH_BREAKFAST]:
    'Double use guest rooms (with breakfast)',
};

export const BEDROOMS_NAMES_SHORT: {[key in EResourcesCode]?: string} = {
  [EResourcesCode.SINGLE_BEDROOM]: 'Single use (no breakfast)',
  [EResourcesCode.SINGLE_BEDROOM_WITH_BREAKFAST]: 'Single use (with breakfast)',
  [EResourcesCode.DOUBLE_BEDROOM]: 'Double use (no breakfast)',
  [EResourcesCode.DOUBLE_BEDROOM_WITH_BREAKFAST]: 'Double use (with breakfast)',
};

export const BEDROOMS_NAMES_FROM_BE: {[key in EBedroomExtrasNames]?: string} = {
  [EBedroomExtrasNames.SINGLE_BEDROOM]: 'Single use guest rooms (no breakfast)',
  [EBedroomExtrasNames.SINGLE_BEDROOM_WITH_BREAKFAST]:
    'Single use guest rooms (with breakfast)',
  [EBedroomExtrasNames.DOUBLE_BEDROOM]: 'Double use guest rooms (no breakfast)',
  [EBedroomExtrasNames.DOUBLE_BEDROOM_WITH_BREAKFAST]:
    'Double use guest rooms (with breakfast)',
};
