import React from 'react';

import {useAppSelector} from 'store/hooks';
import {getCurrencySymbol} from 'utils/stringUtils';
import {EBookingStatus} from 'types/booking';
import {formatFloatWithOneNumber} from 'utils/venueUtils';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';
import {
  StyledBottomSection,
  ChargeTitle,
  ChargeSubTitle,
  ChargePrice,
  CancellationTitle,
  ChargeMessage,
} from './NW2BookingConfirmationStatus.styles';

type TProps = {
  status: EBookingStatus;
  message: string;
};

const ChargeSection = ({status, message}: TProps) => {
  const {venueDetails} = useVenueDetailsData();

  const currency: string = venueDetails.currency;

  const refundAmount = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.refundAmount,
  );

  const totalPrice = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.totalPrice,
  );

  const totalCharged =
    !!refundAmount && refundAmount > 0
      ? '0,00'
      : formatFloatWithOneNumber(totalPrice);

  const currencySymbol = getCurrencySymbol(currency);

  const isTitleShowed =
    status !== EBookingStatus.RFP_EXPIRED &&
    status !== EBookingStatus.RFP_DECLINED;

  return (
    <StyledBottomSection>
      <div>
        {isTitleShowed && (
          <CancellationTitle>Cancellation fee</CancellationTitle>
        )}
        <ChargeMessage>{message}</ChargeMessage>
      </div>
      <div>
        <ChargeTitle>Total charged</ChargeTitle>
        <ChargeSubTitle>Includes taxes and fees</ChargeSubTitle>
        <ChargePrice>
          {totalCharged}&nbsp;{currencySymbol}
        </ChargePrice>
      </div>
    </StyledBottomSection>
  );
};

export default ChargeSection;
