import {SyntheticEvent, useEffect, useRef, useState} from 'react';

interface IProps {
  onFocus?: () => void;
  onBlur?: () => void;
}

export function useTimepicker({onFocus, onBlur} = {} as IProps) {
  const [isFocused, setFocused] = useState<boolean>(false);

  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (ref?.current) {
      const el: NodeListOf<HTMLInputElement> = ref.current.querySelectorAll(
        '.react-datepicker__input-container input',
      );

      el.forEach((e: HTMLInputElement) => {
        // set readOnly for inputs
        e.readOnly = true;
      });
    }
  }, []);

  const config = {
    showTimeSelect: true,
    showTimeSelectOnly: true,
    timeIntervals: 60,
    dateFormat: 'HH:mm',
    timeFormat: 'HH:mm',
    timeCaption: '',
    inputProps: {readonly: true},
    onChangeRaw: (e: SyntheticEvent) => {
      // disable input tapping
      e.preventDefault();
    },
    onFocus: (e: SyntheticEvent) => {
      // disable keyboard on mobile device
      const target = e.target as HTMLInputElement;
      target.blur();
    },
    onCalendarOpen: () => {
      setFocused(true);

      if (typeof onFocus === 'function') {
        onFocus();
      }
    },
    onCalendarClose: () => {
      setFocused(false);

      if (typeof onBlur === 'function') {
        onBlur();
      }
    },
  };

  return {
    ref,
    isFocused,
    config,
  };
}
