import {IDay} from 'types/dto/IDay.type';
import {
  DEFAULT_OPEN_FROM,
  DEFAULT_OPEN_TO,
  WHOLE_DAY_OPEN_FROM_HOUR,
  WHOLE_DAY_OPEN_FROM_MINUTE,
  WHOLE_DAY_OPEN_TO_HOUR,
  WHOLE_DAY_OPEN_TO_MINUTE,
} from 'constants/NW2OpenHours';

const useInitialState = (operationalTimes: IDay[]) => {
  const activeWorkDays = operationalTimes.filter((day) => day.active);
  const firstActiveWorkDay = activeWorkDays[0];
  const initiallySameEveryday = activeWorkDays.every(
    (day) =>
      day.timeEndHour === firstActiveWorkDay?.timeEndHour &&
      day.timeEndMinutes === firstActiveWorkDay?.timeEndMinutes &&
      day.timeStartHour === firstActiveWorkDay?.timeStartHour &&
      day.timeStartMinutes === firstActiveWorkDay?.timeStartMinutes,
  );

  const initial24HoursAccess =
    initiallySameEveryday &&
    firstActiveWorkDay?.timeStartHour == WHOLE_DAY_OPEN_FROM_HOUR &&
    firstActiveWorkDay?.timeStartMinutes == WHOLE_DAY_OPEN_FROM_MINUTE &&
    firstActiveWorkDay?.timeEndHour == WHOLE_DAY_OPEN_TO_HOUR &&
    firstActiveWorkDay?.timeEndMinutes == WHOLE_DAY_OPEN_TO_MINUTE;

  const hasInitialTime = !!(initiallySameEveryday && firstActiveWorkDay);

  const initialOpenFrom = hasInitialTime
    ? `${firstActiveWorkDay.timeStartHour}:${
        firstActiveWorkDay.timeStartMinutes == '0'
          ? '00'
          : firstActiveWorkDay.timeStartMinutes
      }`
    : DEFAULT_OPEN_FROM;
  const initialOpenTo = hasInitialTime
    ? `${firstActiveWorkDay.timeEndHour}:${
        firstActiveWorkDay.timeEndMinutes == '0'
          ? '00'
          : firstActiveWorkDay.timeEndMinutes
      }`
    : DEFAULT_OPEN_TO;

  return {
    initiallySameEveryday,
    initial24HoursAccess,
    initialOpenFrom,
    initialOpenTo,
  };
};

export default useInitialState;
