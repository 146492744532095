import styled, {css} from 'styled-components';

import LeftSideItem from 'view/venue/components/LeftSideContainer/LeftSideItem';

import {NW2Card} from 'view/components/NW2Card';
import {
  fontSizeLg,
  fontWeightBold,
  lineHeightXL,
} from 'styles/configs/typography';
import {borderRadiusXLg} from 'styles/configs/variables';
import {offsetDef, offsetXXLg, pagePadding} from 'styles/configs/offset';
import {preLgBd, xLgBp} from 'styles/configs/breakpoints';
import {NW2Gray200Color} from 'styles/configs/colors';

export const StyledNW2Card = styled(NW2Card)`
  grid-template-columns: 162px auto;

  & + & {
    margin-top: ${offsetXXLg};
  }

  @media (max-width: ${preLgBd}px) {
    grid-template-columns: auto;
    > div:nth-child(n) {
      grid-column: auto;
    }
  }
`;

export const Container = styled.div`
  padding: ${pagePadding};
  border-radius: ${borderRadiusXLg};
  border: 1px solid ${NW2Gray200Color};
`;

export const Title = styled.div`
  font-size: ${fontSizeLg};
  font-weight: ${fontWeightBold};
  line-height: ${lineHeightXL};
`;

export const PreviewExtraList = styled.div`
  margin-top: ${offsetDef};
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${offsetXXLg} 0 ${offsetDef};
`;

export const StyledLeftSideItem = styled(LeftSideItem)<{
  paddingTop?: string;
  paddingBottom?: string;
  isNoBorderTop?: boolean;
}>`
  padding-bottom: ${({paddingBottom}) => paddingBottom};
  padding-top: ${({paddingTop}) => paddingTop};

  ${({isNoBorderTop}) =>
    isNoBorderTop &&
    css`
      &:before {
        display: none;
      }
    `};

  & + & {
    padding-top: 0;

    &:before {
      display: none;
    }
  }
`;

export const TimeBlock = styled.div`
  display: flex;
  justify-content: space-between;

  @media (min-width: ${xLgBp}px) {
    flex-direction: column;
  }
`;

export const RoomTitle = styled.div`
  font-size: ${fontSizeLg};
  font-weight: ${fontWeightBold};
  line-height: ${lineHeightXL};
  margin-bottom: ${offsetDef};
`;

export const Wrapper = styled.div`
  &:not(:first-child) {
    margin-top: ${offsetXXLg};
  }
`;
