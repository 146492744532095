import {
  blackColor,
  borderRadiusLg,
  NW2Gray200Color,
  NW2Primary,
  offsetDef,
  offsetSm,
  whiteColor,
  lightBlackColor,
  NW2Gray300Color,
} from 'constants/styleVars';
import iconCheckmark from 'img/icons/checkmark.svg';

export const datePickerDropdownWidth = '96px';
export const datePickerDropdownHeight = '' + '214px';

export const datepickerDropdownContainer = `
  box-shadow: 0 2px 6px -1px ${NW2Gray200Color};
  border-radius: ${borderRadiusLg};
`;

export const datepickerDropdown = `
  height: ${datePickerDropdownHeight};
  background-color: ${whiteColor};
`;

export const datepickerDropdownItemSelected = `
  color: ${blackColor};
  font-weight: normal;
  background-color: ${NW2Primary};

  &:after {
    position: absolute;
    content: '';
    top: 50%;
    right: ${offsetDef};
    width: 16px;
    height: 12px;
    background: url(${iconCheckmark}) no-repeat center;
    transform: translateY(-50%);
  }
`;

export const datepickerDropdownItem = `
  position: relative;
  display: flex;
  align-items: center;
  height: 54px;
  padding: ${offsetSm};
  background-color: ${whiteColor};
  color: ${lightBlackColor};
  transition: background-color 0.15s;
  cursor: pointer;
  
  &:hover {
    background-color: ${NW2Primary};
  }
  
  & + li {
    box-shadow: 0 -1px 0 0 ${NW2Gray200Color};
  }
`;

// datepicker time overrides
export const reactDatepickerTimeOverrides = `
  &__triangle {
    display: none;
  }

  &__time {
    &-container {
      .react-datepicker {
        &__header {
          display: none;
        }

        &__time {
          border-radius: ${borderRadiusLg};
        }
      }
    }

    .react-datepicker {
      &__time-box {
        width: ${datePickerDropdownWidth};
        
        ${datepickerDropdownContainer}

        ul.react-datepicker__time-list {
          ${datepickerDropdown}

          li.react-datepicker__time-list-item {
            ${datepickerDropdownItem}

            &--selected {
              ${datepickerDropdownItemSelected}
            }
        
            &--disabled {
              cursor: default;
              color: ${NW2Gray300Color};
              pointer-events: none;

              &:hover {
                background: none;
              }
            }
          }
        }
      }
    }
  }
`;
