import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useForm, useFormState} from 'react-final-form';
import styled from 'styled-components';

import {useAppSelector, useAppDispatch} from 'store/hooks';
import {setTotalDrawerVisible} from 'store/venues/actions';
import useGetUrlData from '../hooks/useGetUrlData';

import {offsetDef} from 'constants/styleVars';
import {StyledButtonConfirm} from 'view/venue/NW2BookingPreview/components/NW2BookingTotal/NW2BookingTotal.styles';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${offsetDef};
`;

type TProps = {
  isMobile: boolean;
  isDrawerShowed: boolean;
  isOffice: boolean;
  handleSubmitClick?: () => void;
  extrasCount?: number;
  isSubmitActive: boolean;
};

export function ButtonConfirm({
  isMobile,
  isDrawerShowed,
  extrasCount,
  isSubmitActive,
}: TProps) {
  const dispatch = useAppDispatch();
  const formState = useFormState();
  const form = useForm();
  const navigate = useNavigate();
  const {paramsVenueId, paramsOrderNumber} = useGetUrlData();

  const isBookingInProcess = useAppSelector(({customer}) => customer.isLoading);
  const adyenComponent = useAppSelector(({payment}) => payment.adyenComponent);

  const {submitting} = formState;

  const handleSubmitClick = () => {
    // scroll to the section with error
    if (isDrawerShowed) {
      dispatch(setTotalDrawerVisible(false));
    }
  };

  const onClick = () => {
    if (isMobile && !isDrawerShowed) {
      return dispatch(setTotalDrawerVisible(true));
    }

    if (adyenComponent?.submit) {
      adyenComponent.submit();
    }

    form.submit();
    handleSubmitClick();
  };

  const onCancel = () => {
    navigate({
      pathname: `/booking/${paramsOrderNumber}`,
      search: `?venueId=${paramsVenueId}`,
    });
  };

  const buttonText = isBookingInProcess ? 'Loading...' : 'Submit';

  const buttonSize = isMobile ? 'large' : 'medium';

  if (typeof submitting === 'undefined') return null;

  return (
    <Wrapper>
      <StyledButtonConfirm type='button' inverted onClick={onCancel} fullWidth>
        Cancel
      </StyledButtonConfirm>
      <StyledButtonConfirm
        type='button'
        disabled={!isSubmitActive || submitting || isBookingInProcess}
        buttonType='primary'
        hasCart={!!extrasCount}
        onClick={onClick}
        loading={isBookingInProcess}
        fullWidth
        size={buttonSize}
      >
        {buttonText}
      </StyledButtonConfirm>
    </Wrapper>
  );
}
