import React, {FC, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {notification} from 'antd';
import {Form} from 'react-final-form';

import ApiInventoryService from '../../infra/common/apiInventoryService';
import {useAppSelector} from 'store/hooks';
import CustomList from 'view/components/CustomList';
import NW2Button from 'view/components/NW2Button/NW2Button';

import {isPasswordValid} from 'infra/common/venueValidation';
import {IResetPassword} from 'types/dto/IResetPassword.type';
import {Routes} from 'constants/routes';
import {StyledH6, StyledSpan} from 'view/components/Typography';
import {
  list,
  StyledHelpTextContainer,
} from 'view/components/EnterPassword/helper';
import {fontSizeXSm, fontWeightBold, offsetXSm} from 'constants/styleVars';
import {Container} from 'view/components/EnterPassword/helper';
import {NW2FormItemInput} from '../components/NW2FormItem/NW2FormItem';
import {
  StyledTextValue,
  TitleContainer,
  FormGroup,
} from '../auth/utils/auth.styles';

const ChangePassword: FC = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const email = useAppSelector(({app}) => app.user.email);
  const role = useAppSelector(({app}) => app.user.role);

  const moveToAccountDetails = () => {
    navigate({
      pathname: Routes.customerId,
    });
  };

  const changePasswordForCustomer = async (values: Record<string, string>) => {
    const {newPassword, previousPassword} = values;
    if (isPasswordValid(newPassword)) {
      try {
        setIsLoading(true);
        const resetPasswordData: IResetPassword = {
          email: email,
          role: role,
          previousPassword: previousPassword,
          newPassword: newPassword,
        };

        await ApiInventoryService.editPassword(resetPasswordData);

        const notificationOptions = {
          message: 'The password has been successfully changed',
          duration: 0,
        };
        moveToAccountDetails();
        setIsLoading(false);
        notification.success(notificationOptions);
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      const notificationOptions = {
        message:
          'Password must have one letter, one Upper letter, one number, one special symbol and has 8 symbols',
      };
      notification.error(notificationOptions);
    }
  };

  return (
    <Container>
      <TitleContainer>
        <StyledH6>Change your password</StyledH6>
      </TitleContainer>
      <StyledTextValue>Please type a new password below.</StyledTextValue>
      <Form onSubmit={changePasswordForCustomer}>
        {({handleSubmit}) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <FormGroup columnNumber={1} gap={16}>
                <NW2FormItemInput
                  name='previousPassword'
                  placeholder='Current password'
                  label='Current password'
                  type='password'
                />
                <NW2FormItemInput
                  name='newPassword'
                  placeholder='New password'
                  label='New password'
                  type='password'
                />
              </FormGroup>
              <StyledHelpTextContainer>
                <StyledSpan fontWeight={fontWeightBold} fontSize={fontSizeXSm}>
                  Build a strong Password:
                </StyledSpan>
                <CustomList
                  offset={offsetXSm}
                  size='small'
                  list={list}
                  listStyle='ordered'
                />
                <NW2Button
                  loading={isLoading}
                  buttonType='primary'
                  type='submit'
                  fullWidth
                >
                  {isLoading ? 'Loading...' : 'Change password'}
                </NW2Button>
              </StyledHelpTextContainer>
            </form>
          );
        }}
      </Form>
    </Container>
  );
};

export default ChangePassword;
