import React from 'react';

import DateUtils from 'utils/dateUtils';
import Nw2BookingUnits from './NW2BookingUnits';

import {TextValue} from 'view/components/TextValue';
import {ERoomType} from 'types/dto/ERoomType.type';
import {ICustomerBookedUnits} from 'types/dto/IBooking.types';
import {
  StyledLeftSideItem,
  TimeBlock,
} from './NW2BookingConfirmationUnitsBlock.styles';
import {offsetXXLg} from 'constants/styleVars';

interface IProps {
  isMultiRooms: boolean;
  customerBookedUnits: ICustomerBookedUnits[];
  hasPreArrivals?: boolean;
  hasPostEvents?: boolean;
}

function NW2BookingConfirmationUnitsBlock({
  isMultiRooms,
  customerBookedUnits,
  hasPreArrivals,
  hasPostEvents,
}: IProps) {
  return (
    <>
      {customerBookedUnits.map(
        ({checkInDate, unitBookings, foodAndBeverage, bedrooms}, index) => {
          const timeStart = DateUtils.findMinMaxTime(
            unitBookings,
            'checkInDate',
            false,
          );
          const timeEnd = DateUtils.findMinMaxTime(
            unitBookings,
            'checkOutDate',
            true,
          );

          const singleBookingTitle =
            unitBookings[0]?.unitType === ERoomType.MEETING_ROOM
              ? 'Your room'
              : 'Your desk';
          const multiBookingTitle = (
            <>
              <span>Day {index + 1}</span>
              <TimeBlock>
                <TextValue style={{textTransform: 'none'}}>
                  {DateUtils.getDateWithoutHoursFromBE(checkInDate)}
                </TextValue>
                <TextValue style={{textTransform: 'none'}}>
                  ({DateUtils.getHoursAndMinutesFromBE(timeStart)} -{' '}
                  {DateUtils.getHoursAndMinutesFromBE(timeEnd)})
                </TextValue>
              </TimeBlock>
            </>
          );

          return (
            <StyledLeftSideItem
              key={checkInDate}
              paddingTop={
                hasPreArrivals && index === 0 ? '32px !important' : undefined
              }
              paddingBottom={
                !hasPostEvents && index === customerBookedUnits.length - 1
                  ? undefined
                  : `${offsetXXLg} !important`
              }
              isNoBorderTop={hasPreArrivals}
              item={{
                title: isMultiRooms ? multiBookingTitle : singleBookingTitle,
                children: (
                  <Nw2BookingUnits
                    units={unitBookings}
                    bedrooms={bedrooms}
                    foodAndBeverage={foodAndBeverage}
                  />
                ),
              }}
            />
          );
        },
      )}
    </>
  );
}

export default NW2BookingConfirmationUnitsBlock;
