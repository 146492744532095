import React, {MouseEvent} from 'react';
import {Form} from 'react-final-form';

import {NW2FormItemInput} from 'view/components/NW2FormItem/NW2FormItem';
import {NW2FormItemCheckbox} from 'view/components/NW2FormItem/components';
import NW2PasswordFieldWithCheckList from '../NW2PasswordFieldWithCheckList/NW2PasswordFieldWithCheckList';

import {ECheckboxSizes} from '../../components/NW2FormItem/components/NW2FormItemCheckbox/types';
import {IFormRegisterData} from 'types/account';
import {
  emailFieldRules,
  firstNameFieldRules,
  lastNameFieldRules,
  termsConditionsFieldRules,
} from 'utils/finalFormFieldRules';
import {
  useOpenPrivacyPolicyModal,
  useOpenTermsAndConditionsModal,
} from 'view/common/NW2MultiModal/hooks/useOpenInfoPageModal';

import {Link} from 'view/components/Typography';
import {
  CustomCheckboxLabel,
  FormGroup,
  StyledNW2Button,
} from 'view/auth/utils/auth.styles';

type TProps = {
  isSubmitFailed: boolean;
  onFinish: (arg0: IFormRegisterData) => void;
  isLoading: boolean;
};

function FormRegister({isSubmitFailed, onFinish, isLoading}: TProps) {
  const openTermsAndConditionsModal = useOpenTermsAndConditionsModal();
  const openPrivacyPolicyModal = useOpenPrivacyPolicyModal();

  const openTermsAndConditionsModalHandler = (e: MouseEvent) => {
    e.stopPropagation();
    openTermsAndConditionsModal();
  };

  const openPrivacyPolicyModalHandler = (e: MouseEvent) => {
    e.stopPropagation();
    openPrivacyPolicyModal();
  };

  const checkboxLabel = (
    <>
      I have read and agree to the{' '}
      <Link onMouseDown={openTermsAndConditionsModalHandler}>
        terms & conditions
      </Link>{' '}
      and the{' '}
      <Link onMouseDown={openPrivacyPolicyModalHandler}>privacy policy</Link> of
      HRS.
    </>
  );

  return (
    <>
      <Form onSubmit={onFinish}>
        {({handleSubmit, form}) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <FormGroup columnNumber={1} gap={16}>
                <FormGroup columnNumber={2} gap={16}>
                  <NW2FormItemInput
                    name='firstName'
                    type='text'
                    label='First name'
                    placeholder='First name'
                    showAllValidationErrors
                    rules={firstNameFieldRules}
                  />
                  <NW2FormItemInput
                    name='lastName'
                    type='text'
                    label='Last name'
                    placeholder='Last name'
                    showAllValidationErrors
                    rules={lastNameFieldRules}
                  />
                </FormGroup>
                <NW2FormItemInput
                  name='email'
                  type='email'
                  label='Email address'
                  placeholder='Email address'
                  rules={emailFieldRules}
                  showAllValidationErrors
                  highlightAsError={isSubmitFailed}
                />
                <NW2PasswordFieldWithCheckList
                  isSubmitFailed={isSubmitFailed}
                />
                <StyledNW2Button
                  loading={isLoading}
                  buttonType='primary'
                  fullWidth
                  // need this handler to avoid block onClick by onBlur event
                  onMouseDown={() => form.submit()}
                >
                  {isLoading ? 'Loading...' : 'Register'}
                </StyledNW2Button>
                <NW2FormItemCheckbox
                  id='terms'
                  name='terms'
                  size={ECheckboxSizes.SMALL}
                  label={
                    <CustomCheckboxLabel>{checkboxLabel}</CustomCheckboxLabel>
                  }
                  rules={termsConditionsFieldRules}
                  onMouseMode
                />
              </FormGroup>
            </form>
          );
        }}
      </Form>
    </>
  );
}

export default FormRegister;
