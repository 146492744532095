import React from 'react';

import {
  StyledTotalLink,
  TotalFooterLeft,
  TotalFooterRight,
  TotalFooterTop,
  TotalPrice,
  TotalPriceSubTitle,
  TotalPriceTitle,
} from './SummaryTotalPrice.styles';
import {EBookingModal} from '../../hooks/useBookingSummaryModal';

interface IProps {
  onModalShow: (arg: EBookingModal) => void;
  totalSum: string;
}
export function SummaryTotalPrice({totalSum, onModalShow}: IProps) {
  return (
    <TotalFooterTop>
      <TotalFooterLeft>
        <TotalPriceTitle>Total Price</TotalPriceTitle>
        <StyledTotalLink
          buttonType='link'
          size='small'
          onClick={() => onModalShow(EBookingModal.POLICY)}
        >
          Cancellation policy
        </StyledTotalLink>
      </TotalFooterLeft>
      <TotalFooterRight>
        <TotalPrice>{totalSum}</TotalPrice>
        <TotalPriceSubTitle>
          <div>Includes taxes and fees</div>
        </TotalPriceSubTitle>
      </TotalFooterRight>
    </TotalFooterTop>
  );
}
