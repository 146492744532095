import React, {memo} from 'react';

import {formatFloatWithOneNumber} from 'utils/venueUtils';
import {convertExtraNameToEnum} from 'view/components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/utils';
import {EBookingSummaryTheme} from 'view/components/NW2SummaryBlock/types';
import {EDefaultExtras} from 'types/dto/IExtras.type';
import {
  ChangeIcon,
  ExtraUnitPrice,
  FlexContainer,
  HighlightRow,
  UnitName,
  UnitQuantity,
  UnitSubtitle,
  UnitTitle,
} from './NW2BookingUnitCard.styles';

interface IProps {
  currencySymbol?: string;
  name?: string;
  qty?: number;
  isPriceHidden?: boolean;
  totalPrice?: number | null;
  calculatedTotalPrice?: number;
  editMode?: boolean;
  isHighlighted?: boolean;
  isRemoved?: boolean;
  iconArrow?: string;
  colorTheme?: EBookingSummaryTheme;
  isBedroom?: boolean;
}

export const BookingUnitCardExtrasRow = memo(
  ({
    currencySymbol,
    name,
    qty,
    isPriceHidden,
    totalPrice = 0,
    calculatedTotalPrice,
    editMode,
    isHighlighted,
    isRemoved,
    iconArrow = '',
    colorTheme = EBookingSummaryTheme.DARK,
    isBedroom,
  }: IProps) => {
    const getTotalPrice = () => {
      switch (true) {
        case editMode:
          return calculatedTotalPrice;
        default:
          return totalPrice;
      }
    };

    return (
      <HighlightRow
        colorTheme={colorTheme}
        isHighlighted={isHighlighted}
        isRemoved={isRemoved}
      >
        <UnitTitle>
          <UnitSubtitle>
            <UnitQuantity>
              {convertExtraNameToEnum(name) !== EDefaultExtras.wifi &&
                `${qty} x`}
            </UnitQuantity>
            <UnitName> {name}</UnitName>
          </UnitSubtitle>

          {(!isPriceHidden || editMode) && (
            <FlexContainer>
              <ExtraUnitPrice>
                {!totalPrice && !isBedroom ? (
                  totalPrice === null ? (
                    ''
                  ) : (
                    'Free'
                  )
                ) : (
                  <>
                    {formatFloatWithOneNumber(getTotalPrice())} {currencySymbol}
                  </>
                )}
              </ExtraUnitPrice>
              {isHighlighted && (
                <ChangeIcon icon={iconArrow} iconColor={false} marginLeft={6} />
              )}
            </FlexContainer>
          )}
        </UnitTitle>
      </HighlightRow>
    );
  },
);
