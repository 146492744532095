import React, {memo} from 'react';
import {DateTime} from 'luxon';
import DatePicker, {ReactDatePickerProps} from 'react-datepicker';
import {useAppSelector} from 'store/hooks';

import {TDateChangeFn} from '../NW2DatePicker.types';
import {TDateType} from 'types/dto/ISearch.types';

import {StyledWrapper} from './NW2DatePicker.styles';

interface IProps extends ReactDatePickerProps {
  date: TDateType;
  onChange: TDateChangeFn;
  className?: string;
}

export const NW2DatePicker = memo(
  ({date, onChange, className, ...rest}: IProps) => {
    const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

    const config = {
      minDate: DateTime.now().toJSDate(),
      timeIntervals: 60,
      timeFormat: 'HH:mm',
      timeCaption: 'time',
      dateFormat: 'd MMM, yyyy',
      calendarStartDay: 1,
      inline: true,
    };

    return (
      <StyledWrapper className={className} isMobile={isMobile}>
        <DatePicker selected={date} onChange={onChange} {...config} {...rest} />
      </StyledWrapper>
    );
  },
);
