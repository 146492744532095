import React from 'react';
import {Field} from 'react-final-form';

import {
  Select,
  Input,
  ErrorMessage,
  InputRadioButton,
  PhoneNumber,
  SelectList,
} from './components';

import {
  IInputFieldProps,
  IRadioButtonFieldProps,
  ISelectProps,
  ISelectListProps,
} from './types';

import {StyledFieldContainer} from './NW2FormItem.styles';

export const NW2FormItemInput = ({
  name,
  type,
  label,
  labelColor,
  placeholder,
  placeholderIcon,
  rules,
  highlightAsError = false,
  showAllValidationErrors = false,
  variant = 'primary',
  inputSize = 'medium',
  disabled,
  className,
  onChange,
  onBlur,
  hasValidation = true,
  errorMessageMargin,
  ...restInputProps
}: IInputFieldProps) => {
  return (
    <Field name={name} validate={hasValidation ? rules : undefined}>
      {({input, meta}) => {
        const hasError = hasValidation
          ? highlightAsError || (meta.error && meta.touched)
          : false;

        return (
          <div className={className}>
            <Input
              name={name}
              type={type}
              label={label}
              labelColor={labelColor}
              placeholder={placeholder}
              placeholderIcon={placeholderIcon}
              hasError={hasError}
              value={input.value}
              onChange={onChange || input.onChange}
              onBlur={onBlur || input.onBlur}
              inputProps={{...input, disabled}}
              disabled={disabled}
              variant={variant}
              inputSize={inputSize}
              {...restInputProps}
            />
            <ErrorMessage
              hasError={hasError}
              errorData={meta.error}
              showAllValidationErrors={showAllValidationErrors}
              variant={variant}
              inputSize={inputSize}
              margin={errorMessageMargin}
            />
          </div>
        );
      }}
    </Field>
  );
};

export const NW2FormItemRadioButton = ({
  name,
  label,
  labelShift,
  rules,
  highlightAsError = false,
  showAllValidationErrors = false,
  value,
}: IRadioButtonFieldProps) => {
  return (
    <Field
      type='radio'
      name={name}
      component='input'
      validate={rules}
      value={value}
    >
      {({input, meta}) => {
        const hasError = highlightAsError || (meta.error && meta.touched);
        return (
          <StyledFieldContainer>
            <InputRadioButton
              checked={input.checked}
              onChange={input.onChange}
              value={input.value}
              name={input.name}
              label={label}
              inputProps={input}
              hasError={hasError}
              labelShift={labelShift}
            />
            <ErrorMessage
              hasError={hasError}
              errorData={meta.error}
              showAllValidationErrors={showAllValidationErrors}
            />
          </StyledFieldContainer>
        );
      }}
    </Field>
  );
};

export const NW2FormItemSelect = ({
  name,
  label,
  labelColor,
  placeholder,
  rules,
  highlightAsError = false,
  showAllValidationErrors = false,
  className,
  options,
  multiple,
  ...restSelectProps
}: ISelectProps) => {
  return (
    <Field name={name} validate={rules} options={options} multiple={multiple}>
      {({input, meta, options}) => {
        const hasError = highlightAsError || (meta.error && meta.touched);
        return (
          <StyledFieldContainer className={className}>
            <Select
              {...input}
              name={name}
              label={label}
              labelColor={labelColor}
              hasError={hasError}
              placeholder={placeholder}
              options={options}
              inputProps={input}
              {...restSelectProps}
            />

            <ErrorMessage
              hasError={hasError}
              errorData={meta.error}
              showAllValidationErrors={showAllValidationErrors}
            />
          </StyledFieldContainer>
        );
      }}
    </Field>
  );
};

export const NW2FormItemPhoneNumber = ({
  name,
  type,
  label,
  labelColor,
  placeholder,
  placeholderIcon,
  rules,
  highlightAsError = false,
  showAllValidationErrors = false,
  className,
  isCountrySelect,
}: IInputFieldProps) => {
  return (
    <Field
      name={name}
      type={type}
      validate={rules}
      label={label}
      labelColor={labelColor}
      placeholder={placeholder}
      placeholderIcon={placeholderIcon}
      highlightAsError={highlightAsError}
      showAllValidationErrors={showAllValidationErrors}
      className={className}
    >
      {(props) => (
        <PhoneNumber
          name={name}
          type={type}
          isCountrySelect={isCountrySelect}
          {...props}
        />
      )}
    </Field>
  );
};

export const NW2FormItemSelectList = ({
  name,
  rules,
  options,
  multiple,
  maxHeight,
  ...restSelectProps
}: ISelectListProps) => {
  return (
    <Field name={name} validate={rules} options={options} multiple={multiple}>
      {({input, options}) => {
        return (
          <StyledFieldContainer>
            <SelectList
              {...input}
              name={name}
              maxHeight={maxHeight}
              optionsList={options}
              inputValue={input.value}
              {...restSelectProps}
            />
          </StyledFieldContainer>
        );
      }}
    </Field>
  );
};
