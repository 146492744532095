import React, {useCallback} from 'react';
import {useForm} from 'react-final-form';

import TextValue from 'view/components/TextValue';
import NW2FormItemGoogleAutocomplete from 'view/components/NW2FormItemGoogleAutocomplete';

import {TGeocodeAddressData} from 'view/components/NW2SearchSection/components/SearchForm/components/NW2GoogleMapSearchInput/types';

import {SearchContainer} from '../../BillingAddress.styles';

export function SearchWithAutocomplete() {
  const form = useForm();

  const setFormValuesOnSelectAddress = useCallback(
    (searchData: TGeocodeAddressData) => {
      const address = searchData.address || '';
      const streetName = searchData.addressComponents?.streetName;
      const buildingNumber = searchData.addressComponents?.buildingNumber;
      const streetAndBuildingNumber =
        streetName && buildingNumber
          ? `${streetName}, ${buildingNumber}`
          : address;
      form.batch(() => {
        form.change('postCode', searchData.addressComponents?.postalCode);
        form.change('city', searchData.addressComponents?.city);
        form.change('country', searchData.addressComponents?.country);
        form.change('streetHouseNumber', streetAndBuildingNumber);
        form.change('search', searchData.address);
      });
    },
    [form],
  );

  return (
    <SearchContainer>
      <NW2FormItemGoogleAutocomplete
        callbackFunction={setFormValuesOnSelectAddress}
      />
      <TextValue>Or enter manually</TextValue>
    </SearchContainer>
  );
}
