import React from 'react';
import styled from 'styled-components';

import NW2AccordionItem from 'view/components/NW2Accordion/components/NW2AccordionItem';
import MeetingRequestSectionTitle from '../MeetingRequestSectionTitle';
import FoodAndBeverageContent from './components/FoodAndBeverageContent';

import {TMeetingRoomItem, TSelectedOptions} from 'types/search';

type TProps = {
  setSelectedFoodBeverage: (
    formData?: Record<string, any>,
    participants?: number,
  ) => void;
  deleteFoodBeverage: () => void;
  foodBeverageData: TSelectedOptions | null;
  meetingRooms: TMeetingRoomItem[];
  dayIndex: number;
  setEditedFoodBeverageDaysIndex: React.Dispatch<
    React.SetStateAction<number[]>
  >;
};

const StyledNW2AccordionItem = styled(NW2AccordionItem)`
  padding: 0;
`;

function AddFoodBeverageRequest({
  foodBeverageData,
  meetingRooms,
  deleteFoodBeverage,
  setSelectedFoodBeverage,
  setEditedFoodBeverageDaysIndex,
  dayIndex,
}: TProps) {
  const isRooms = !!meetingRooms.length;
  const isFoodBeverageEnabled = !!foodBeverageData && isRooms;

  const toggleFoodAndBeverage = () => {
    if (isFoodBeverageEnabled) {
      deleteFoodBeverage();
    } else {
      setSelectedFoodBeverage();
    }
  };

  return (
    <StyledNW2AccordionItem
      expandable={isFoodBeverageEnabled}
      initiallyExpanded
      isDivider={false}
      title={
        <MeetingRequestSectionTitle
          label='Food & Beverage'
          isSwitchDisabled={!isRooms}
          isSwitchActive={isFoodBeverageEnabled}
          onClickSectionHandler={toggleFoodAndBeverage}
        />
      }
      content={
        isFoodBeverageEnabled ? (
          <FoodAndBeverageContent
            meetingRooms={meetingRooms}
            setSelectedFoodBeverage={setSelectedFoodBeverage}
            foodBeverageData={foodBeverageData}
            setEditedFoodBeverageDaysIndex={setEditedFoodBeverageDaysIndex}
            dayIndex={dayIndex}
          />
        ) : null
      }
    />
  );
}

export default AddFoodBeverageRequest;
