import React from 'react';

import NMMList, {Li} from 'view/components/NMMList';

import {AmenitiesTitle} from '../components/MeetingRoomTotalPrice.styles';

const WhatIsNextBlock = () => {
  return (
    <>
      <AmenitiesTitle>What’s next:</AmenitiesTitle>
      <NMMList>
        <Li>Review your requirements</Li>
        <Li>Venue will provide their offer</Li>
        <Li>Review & make your decision</Li>
      </NMMList>
    </>
  );
};

export default WhatIsNextBlock;
