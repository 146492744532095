import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Checkbox, Col, FormInstance, Row} from 'antd';
import styled from 'styled-components';
import _get from 'lodash/get';

import General from '../General';
import Address from '../Address/Address';
import ContactDetails from '../ContactDetails';
import Hours from '../OperationalHours';
import VenueInfoBlockCard from 'view/components/VenueInfoBlockCard';
import Photos from '../Photos';

import {NW2AmenitiesList} from 'view/components/NW2AmenitiesList/NW2AmenitiesList';
import {PageTitle} from 'view/components/Typography';

import {offsetXSm} from 'constants/styleVars';

import {
  PATH_TO_REDUCER_VENUE_DATA,
  PATH_TO_REDUCER_VENUE_POI_DATA,
} from 'constants/venue';
import {setIsVenueInformationConfirmed} from 'store/venue/actions';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {IAmenity} from 'types/dto/IAmenity';

import {
  useOpenPrivacyPolicyModal,
  useOpenTermsAndConditionsModal,
} from 'view/common/NW2MultiModal/hooks/useOpenInfoPageModal';
import {IPointOfInterest} from 'types/venue';
import {NW2PointsOfInterestView} from 'view/components/NW2PointsOfInterestView';

const StyledCheckbox = styled(Checkbox)`
  margin-right: ${offsetXSm};
`;

type TProps = {
  form: FormInstance;
  accommodationType: EAccommodationType;
};

const Review = ({form, accommodationType}: TProps) => {
  const isExternalVenue = accommodationType === EAccommodationType.VENUE;

  const dispatch = useDispatch();
  const confirmed = useSelector((state) =>
    _get(state, 'venue.isVenueInformationConfirmed'),
  );
  const amenities: IAmenity[] = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.amenities`),
  );
  const pointsOfInterest: IPointOfInterest[] | [] = useSelector((state) =>
    _get(
      state,
      `${PATH_TO_REDUCER_VENUE_DATA}.${PATH_TO_REDUCER_VENUE_POI_DATA}`,
    ),
  );

  const onChange = () => {
    dispatch(setIsVenueInformationConfirmed(!confirmed));
  };

  const openTermsAndConditionsModal = useOpenTermsAndConditionsModal();
  const openPrivacyPolicyModal = useOpenPrivacyPolicyModal();

  return (
    <section>
      <PageTitle>Review details</PageTitle>

      <Row gutter={16}>
        <Col span={24} lg={16}>
          <VenueInfoBlockCard title='General information'>
            <General
              mode='view'
              form={form}
              accommodationType={accommodationType}
            />
          </VenueInfoBlockCard>

          <VenueInfoBlockCard title='Uploaded images'>
            <Photos mode='view' accommodationType={accommodationType} />
          </VenueInfoBlockCard>

          {isExternalVenue && amenities?.length > 0 && (
            <VenueInfoBlockCard title='Amenities offered'>
              <NW2AmenitiesList amenities={amenities} />
            </VenueInfoBlockCard>
          )}

          {isExternalVenue && pointsOfInterest?.length && (
            <VenueInfoBlockCard title='Points of interest'>
              <NW2PointsOfInterestView poiList={pointsOfInterest} />
            </VenueInfoBlockCard>
          )}

          <VenueInfoBlockCard
            title={isExternalVenue ? 'Company details' : 'Contact Details'}
          >
            <ContactDetails
              mode='view'
              form={form}
              accommodationType={accommodationType}
            />
          </VenueInfoBlockCard>
        </Col>

        <Col span={24} lg={8}>
          <VenueInfoBlockCard title='Location'>
            <Address
              mode='view'
              form={form}
              accommodationType={accommodationType}
            />
          </VenueInfoBlockCard>

          <VenueInfoBlockCard title='Working hours'>
            <Hours mode='view' accommodationType={accommodationType} />
          </VenueInfoBlockCard>
        </Col>
      </Row>

      <div>
        <StyledCheckbox checked={confirmed} onChange={onChange} />
        &nbsp;By using this form you confirm that all entered information is
        true, correct and accurate and agree with the storage and handling of
        your data by this website in accordance with our&nbsp;
        <a onClick={openTermsAndConditionsModal}>Terms and Conditions</a>
        &nbsp;and the&nbsp;
        <a onClick={openPrivacyPolicyModal}>Privacy Policy</a>.
      </div>
      <br />
    </section>
  );
};

export default Review;
