import React, {ReactNode, useMemo, useState} from 'react';

import CancelPolicyCard from 'view/components/CancelPolicyCard/CancelPolicyCard';
import CancelPolicyInfoCard from 'view/components/CancelPolicyInfoCard/CancelPolicyInfoCard';
import Icon from 'view/components/Icon';
import {useAppSelector} from 'store/hooks';
import CancellationPolicyTextElement from 'view/components/CancellationPolicyTextElement/CancellationPolicyTextElement';

import {whiteColor} from 'constants/styleVars';

import {
  StyledPolicyCardWrapper,
  StyledRfpCardWrapper,
} from '../NW2BookingSummary.styles';

export enum EBookingModal {
  POLICY = 'POLICY',
  RFP = 'RFP',
}

export const useBookingSummaryModal = (): {
  isModalShowed: boolean;
  onModalShow: (arg: EBookingModal) => void;
  onModalClose: () => void;
  modalTitle: string | null;
  modalBody: ReactNode;
} => {
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  const cancellationPolicyText = useAppSelector(
    (state) => state.customer.cancellationPolicyText,
  );

  const [showedModal, setShowedModal] = useState<EBookingModal | ''>('');

  const onModalShow = (str: EBookingModal) => {
    setShowedModal(str);
  };

  const onModalClose = () => {
    setShowedModal('');
  };

  const modalContent = useMemo(() => {
    switch (showedModal) {
      case EBookingModal.POLICY: {
        return {
          modalTitle: 'Cancellation policy',
          modalBody: (
            <StyledPolicyCardWrapper>
              <CancelPolicyInfoCard
                text={
                  <CancellationPolicyTextElement
                    text={cancellationPolicyText}
                  />
                }
                bgColor={isMobile ? whiteColor : undefined}
              />
            </StyledPolicyCardWrapper>
          ),
        };
      }

      case EBookingModal.RFP: {
        return {
          modalTitle: 'Venue response time',
          modalBody: (
            <StyledRfpCardWrapper>
              <CancelPolicyCard
                text={
                  <span>
                    The venue’s average response time to booking requests is{' '}
                    <b>2 hours</b>.
                  </span>
                }
                icon={<Icon info icon='NW2_WATCH' />}
              />
            </StyledRfpCardWrapper>
          ),
        };
      }

      default: {
        return {
          modalTitle: null,
          modalBody: null,
        };
      }
    }
  }, [isMobile, showedModal, cancellationPolicyText]);

  return {
    isModalShowed: !!showedModal,
    onModalShow,
    onModalClose,
    modalTitle: modalContent.modalTitle,
    modalBody: modalContent.modalBody,
  };
};
