import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import NW2ModalMeetingsTwoColumns from 'view/components/NW2ModalMeetingsTwoColumns/NW2ModalMeetingsTwoColumns';
import NW2MeetingRequestsList from './components/MeetingRequestsList';
import useMeetingRoomData from './hooks/useMeetingRoomData';
import SummaryRequestBlock from './components/SummaryRequestBlock';

import {EResourcesType, IExtrasOption} from 'types/dto/IExtras.type';
import {getExtrasOption} from 'store/venue/actions';
import {checkIsExtraByTypePresent} from './components/utils';
import {useAppDispatch, useAppSelector} from 'store/hooks';

type TProps = {
  onClose: () => void;
};

const ExtendedMeetingRoomsPopup = ({onClose}: TProps) => {
  const dispatch = useAppDispatch();

  const timeData = useAppSelector(({search}) => search.initialTimeData);
  const allExtrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );

  const {FOOD_AND_BEVERAGE, EQUIPMENT, BEDROOM} = EResourcesType;

  const firstDayDate = timeData[0].timeStart;
  const lastDayDate = timeData[timeData.length - 1].timeEnd;

  const isFoodBeverageExtrasPresent = checkIsExtraByTypePresent(
    allExtrasOption,
    FOOD_AND_BEVERAGE,
  );

  const isEquipmentExtrasPresent = checkIsExtraByTypePresent(
    allExtrasOption,
    EQUIPMENT,
  );

  const closePopupHandler = () => onClose();

  useEffect(() => {
    // todo check with useCommonExtras
    if (isFoodBeverageExtrasPresent && isEquipmentExtrasPresent) return;

    dispatch(getExtrasOption([FOOD_AND_BEVERAGE, EQUIPMENT, BEDROOM]));
  }, [
    dispatch,
    isFoodBeverageExtrasPresent,
    isEquipmentExtrasPresent,
    EQUIPMENT,
    FOOD_AND_BEVERAGE,
    BEDROOM,
  ]);

  const meetingRoomData = useMeetingRoomData(timeData);

  return (
    <NW2ModalMeetingsTwoColumns
      title='Let us know your meeting needs'
      onToggle={closePopupHandler}
      colLeft={
        <NW2MeetingRequestsList
          firstDayDate={firstDayDate}
          lastDayDate={lastDayDate}
          {...meetingRoomData}
        />
      }
      colRight={
        <SummaryRequestBlock
          meetingRequestData={meetingRoomData.meetingRequestData}
          bedroomsCatering={meetingRoomData.bedroomsCatering}
          closePopupHandler={closePopupHandler}
          setIsSectionExpanded={meetingRoomData.setIsSectionExpanded}
        />
      }
    />
  );
};

export default ExtendedMeetingRoomsPopup;
