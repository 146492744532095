import styled, {css} from 'styled-components';

import {
  borderRadiusDef,
  borderRadiusLg,
  datepickerZIndex,
  datepickerPopperZIndex,
  fontSizeSm,
  fontSizeXXSm,
  fontSizeMd,
  fontSizeLg,
  NW2Gray200Color,
  NW2Gray500Color,
  NW2Gray900Color,
  NW2Primary,
  offsetSm,
  offsetDef,
  offsetXLg,
  offsetXXLg,
  offsetXXSm,
  offsetXXXSm,
  whiteColor,
  lineHeightLg,
  fontWeightBold,
  mdBp,
} from 'constants/styleVars';
import {reactDatepickerTimeOverrides} from '../NW2DatepickerCommon.styles';

export const StyledWrapper = styled.div<{isFocused: boolean}>(
  ({isFocused}) => css`
    position: relative;

    &:after {
      position: absolute;
      content: '';
      top: 50%;
      transform: translateY(-50%);
      right: 14px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid ${NW2Gray500Color};
      pointer-events: none;

      &:focus-within {
        border-top: none;
        border-bottom: 5px solid ${NW2Primary};
      }
    }

    // datepicker time overrides
    .react-datepicker {
      border: 0;
      border-radius: ${borderRadiusLg};

      &__input-container {
        input {
          font-size: ${fontSizeLg};
          width: 100%;
          height: 54px;
          padding: ${offsetSm} calc(${offsetXXLg} - 4px) ${offsetSm} ${offsetSm};
          border: 1px solid ${isFocused ? NW2Primary : NW2Gray200Color};
          border-radius: ${borderRadiusDef};
          transition: border-color 0.15s;
          caret-color: transparent;
          cursor: pointer;
          user-select: none;

          &:disabled {
            pointer-events: none;
          }

          @media (min-width: ${mdBp}px) {
            height: 46px;
            font-size: ${fontSizeSm};
          }
        }
      }

      ${reactDatepickerTimeOverrides}
    }

    .react-datepicker-popper {
      z-index: ${datepickerPopperZIndex};
    }
  `,
);

export const StyledLabel = styled.span`
  position: absolute;
  font-size: ${fontSizeXXSm};
  line-height: 1;
  font-weight: bold;
  padding: 0 ${offsetXXXSm};
  top: -${offsetXXSm};
  left: ${offsetSm};
  background-color: ${whiteColor};
  border-radius: ${borderRadiusLg};
  z-index: ${datepickerZIndex};
`;

export const StyledTimeTitle = styled.h2`
  margin-bottom: ${offsetDef};
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  color: ${NW2Gray900Color};
  font-weight: ${fontWeightBold};
`;

export const StyledMobileTimeWrapper = styled.div`
  padding: ${offsetXLg} ${offsetDef} ${offsetDef} ${offsetDef};
`;
