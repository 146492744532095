import React, {useEffect, useRef, useState} from 'react';
import {useForm} from 'react-final-form';
import styled, {css} from 'styled-components';

import {DropdownList} from './components/DropdownList';
import {useClickOutside} from 'hooks/useClickOutside';

import {
  IDropdownListOption,
  ISelectProps,
} from 'view/components/NW2FormItem/types';
import {
  StyledInput,
  StyledInputContainer,
  StyledLabel,
  StyledLabelContainer,
} from '../../NW2FormItem.styles';
import {
  blackColor,
  NW2Gray500Color,
  offsetDef,
  offsetXXXLg,
} from 'constants/styleVars';

const Placeholder = styled(StyledInput)<{isMultiple?: boolean}>`
  width: 100%;
  padding-right: ${offsetXXXLg};
  ${({isMultiple}) => isMultiple && 'cursor: pointer;'};

  &:read-only {
    cursor: pointer;
  }
`;

const IconTriangle = styled.span<{isFocused?: boolean}>(({isFocused}) => {
  const iconColor = isFocused ? blackColor : NW2Gray500Color;

  return css`
    position: absolute;
    top: 50%;
    transform: ${isFocused
      ? 'translateY(-50%) rotate(0deg)'
      : 'translateY(-50%) rotate(-180deg)'};
    right: ${offsetDef};
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid ${iconColor};
    transition: transform 0.15s, color 0.15s;
    transform-origin: center center;
    pointer-events: none;
  `;
});

function Select({
  label,
  labelColor,
  name,
  options,
  placeholder,
  hasError,
  inputProps,
  readonly,
  variant = 'primary',
  inputSize = 'medium',
  maxHeight = '202px',
  isCapitalLetterInOptions,
  disabled,
  onChange,
}: ISelectProps) {
  const [focused, setFocused] = useState(false);
  const [optionsList, setOptionsList] = useState<IDropdownListOption[]>([]);
  const selectContainer = useRef(null);
  const form = useForm();
  const isCountryInInput = options.some((el) =>
    el.value.toLowerCase().includes(inputProps.value.toLowerCase()),
  );

  const filterOptionsList = (value: string) => {
    if (!options) return;
    const filteredList = options.filter((item) =>
      item.value.includes(
        isCapitalLetterInOptions
          ? value.charAt(0).toUpperCase() + value.slice(1).trim()
          : value.toLowerCase().trim(),
      ),
    );
    setOptionsList(filteredList);
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    inputProps.onChange(event);
    if (!inputProps.multiple || !readonly)
      filterOptionsList(event.target.value);
  };

  const onClickHandler = () => {
    if (!readonly) {
      form.change(name, '');
      setOptionsList(options);
    }
    setFocused((prev) => !prev);
  };

  const onBlurHandler = () => {
    if (isCountryInInput) {
      setFocused(false);
    } else {
      form.change(name, '');
    }
  };

  useClickOutside(selectContainer, focused, onBlurHandler);

  const placeholderText = focused ? '' : placeholder;

  useEffect(() => {
    if (options) setOptionsList(options);
  }, [options]);

  return (
    <StyledInputContainer ref={selectContainer}>
      {label && (!!inputProps.value || focused) && (
        <StyledLabelContainer hasError={hasError} focused={focused}>
          <StyledLabel
            variant={variant}
            labelColor={labelColor}
            inputSize={inputSize}
            focused={focused}
            disabled={disabled}
          >
            {label}
          </StyledLabel>
        </StyledLabelContainer>
      )}

      <Placeholder
        {...inputProps}
        name={name}
        placeholder={placeholderText}
        onClick={onClickHandler}
        onChange={onChangeHandler}
        value={isCountryInInput ? inputProps.value : ''}
        role='presentation'
        autoComplete='off'
        readOnly={inputProps.multiple || readonly}
        isMultiple={inputProps.multiple}
        hasError={hasError}
        focused={focused}
        variant={variant}
        inputSize={inputSize}
        disabled={disabled}
      />

      <IconTriangle isFocused={focused} />

      <DropdownList
        optionsList={optionsList}
        focused={focused}
        inputValue={inputProps.value}
        name={name}
        onBlurHandler={onBlurHandler}
        onChange={onChange}
        multiple={inputProps.multiple}
        variant={variant}
        maxHeight={maxHeight}
      />
    </StyledInputContainer>
  );
}

export default Select;
