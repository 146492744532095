export const REQUEST_OFFER_LINK_TERMS_TEXT = `The cancellation policy becomes binding only if you
 accept the venue’s offer. By proceeding you accept the HRS `;
export const REQUEST_OFFER_SENT_LINK_TERMS_TEXT = `Cancellation policy becomes binding once request is 
confirmed by the venue. You have already accepted the HRS `;
export const OFFER_SENT_LINK_TERMS_TEXT = `You have already accepted the HRS `;
export const EXPIRES_IN_DAYS = '2 days';
export const VALID_FOR_DAYS = '7 days';
export const SHORTLIST_MAX_ITEMS = 5;
export const COL_WIDTH = 100 / 6 + '%';
export const IMG_HEIGHT = '134px';
