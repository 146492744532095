import React from 'react';
import {useAppSelector} from 'store/hooks';

import {NW2MultiDatePicker} from 'view/components/NW2Datepicker';

import {StyledColumnCalendar} from './NW2SearchDatePicker.styles';
import {ReactDatePickerProps} from 'react-datepicker';

interface IProps extends ReactDatePickerProps {
  isSingleDayDatePicker?: boolean;
}

const NW2DateSection = ({
  startDate,
  endDate,
  onChange,
  isSingleDayDatePicker,
  ...rest
}: IProps) => {
  const isMobile: boolean = useAppSelector(({app}) => app.deviceType.isMobile);

  const config = {
    startDate: startDate,
    endDate: endDate,
    onChange: onChange,
    isSingleDayDatePicker: isSingleDayDatePicker,
    ...rest,
  };

  return (
    <StyledColumnCalendar isMobile={isMobile}>
      <NW2MultiDatePicker {...config} />
    </StyledColumnCalendar>
  );
};

export default NW2DateSection;
