import React, {FC} from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';

import BookingUnitCardTimeRangeAndParticipants from './BookingUnitCardTimeRangeAndParticipants';
import {getPackageExtrasDescriptions} from 'view/venue/NW2BookingPreview/helpers';
import {EBookingSummaryTheme} from 'view/components/NW2SummaryBlock/types';
import {IPackageResponse} from 'types/dto/IPackages.type';
import {getArrowIconByComparing} from '../../../../NW2BookingEdit/helpers';
import {formatFloatWithOneNumber} from 'utils/venueUtils';

import {
  TotalPricePackage,
  Section,
  TotalPrice,
  PricePerPerson,
  UnitQuantity,
  UnitSubtitle,
  UnitName,
  FlexContainer,
  HighlightRow,
  ChangeIcon,
} from './NW2BookingUnitCard.styles';
import {IUnitBooking} from 'types/dto/IBooking.types';

type TProps = {
  selectedPackage?: IPackageResponse | null;
  participants: number;
  unitName: string;
  unitCheckInDate: string;
  unitCheckOutDate: string;
  isPackageInOrder: boolean;
  price?: number | null;
  editMode?: boolean;
  participantsState: {
    isHighlighted: boolean;
    arrowIcon: string;
  };
  timeRangeState: {
    isHighlighted: boolean;
  };
  theme: EBookingSummaryTheme;
  orderUnit?: IUnitBooking;
  totalPriceForPackageSet?: number;
};
const BookingUnitCardPackage: FC<TProps> = ({
  selectedPackage,
  participants,
  unitName,
  unitCheckInDate,
  unitCheckOutDate,
  isPackageInOrder,
  price,
  editMode,
  theme,
  participantsState,
  timeRangeState,
  orderUnit,
  totalPriceForPackageSet,
}) => {
  if (!selectedPackage) return null;

  const {currency} = selectedPackage;

  const priceFromOrder = orderUnit?.packagePrice;
  const participantsFromOrder = orderUnit?.participants;

  const packageEquipment = getPackageExtrasDescriptions(
    selectedPackage,
    'equipment',
  );
  const packageFoodAndBeverage = getPackageExtrasDescriptions(
    selectedPackage,
    'catering',
  );

  const packageEquipmentText = packageEquipment.length
    ? packageEquipment.join(', ')
    : '-';

  const totalPrice = totalPriceForPackageSet
    ? totalPriceForPackageSet
    : price && participants * price;
  const orderTotalPrice =
    priceFromOrder &&
    participantsFromOrder &&
    priceFromOrder * participantsFromOrder;

  const packagePriceState = {
    isHighlighted: editMode && totalPrice !== orderTotalPrice,
    arrow: getArrowIconByComparing(orderTotalPrice, totalPrice),
  };

  const currencySymbol = currency && getSymbolFromCurrency(currency);
  return (
    <>
      <div>{unitName}</div>
      <FlexContainer>
        <BookingUnitCardTimeRangeAndParticipants
          theme={theme}
          participantsState={participantsState}
          participants={participants}
          isMeetingRoom={true}
          timeRangeState={timeRangeState}
          unitCheckInDate={unitCheckInDate}
          unitCheckOutDate={unitCheckOutDate}
        />
      </FlexContainer>
      <Section>Equipment: {packageEquipmentText}</Section>
      <Section>
        <div>Food & Beverage</div>
        <div>
          {packageFoodAndBeverage.map((item: string, index: number) => (
            <li key={index}>{item}</li>
          ))}
        </div>
      </Section>
      <HighlightRow
        colorTheme={theme}
        isHighlighted={packagePriceState.isHighlighted}
      >
        <TotalPricePackage>
          <UnitSubtitle>
            <UnitQuantity>{`${participants} x`}</UnitQuantity>
            <UnitName>Packages</UnitName>
          </UnitSubtitle>
          <TotalPrice>
            <FlexContainer>
              {formatFloatWithOneNumber(totalPrice)} {currencySymbol}
              {packagePriceState.isHighlighted && (
                <ChangeIcon
                  icon={packagePriceState.arrow}
                  iconColor={false}
                  marginLeft={6}
                />
              )}
            </FlexContainer>
            {isPackageInOrder && (
              <PricePerPerson>
                ({formatFloatWithOneNumber(price)} {currencySymbol} p/p)
              </PricePerPerson>
            )}
          </TotalPrice>
        </TotalPricePackage>
      </HighlightRow>
    </>
  );
};

export default BookingUnitCardPackage;
