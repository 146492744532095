import {DateTime} from 'luxon';
import {cloneDeep} from 'lodash';

import {
  ICustomerBookedUnits,
  IExtraState,
  IOrderDay,
  IRecalculationRequest,
  ISummaryExtra,
  ISummaryUnit,
  IUnitBooking,
} from 'types/dto/IBooking.types';
import {
  EPriceType,
  EResourcesType,
  IExtrasResponse,
} from 'types/dto/IExtras.type';
import {EEventType, ERoomSchemaNames, IBookedExtra} from 'types/venue';
import {IUnitBookingDetails} from 'types/bookingOrders';
import {IBookingPreviewFormData} from 'store/venues/types';
import {TSelectedPackageItem} from 'types/dto/IPackages.type';
import {EMPTY_OBJECT} from 'constants/app';

export interface IUnitInfoState {
  checkInDate: string;
  checkOutDate: string;
  participants: number;
  setupStyle: ERoomSchemaNames;
  equipments: IExtraState[];
}

export interface IEditFormState
  extends Omit<IBookingPreviewFormData, 'bookedUnits'>,
    Record<string, any> {
  bookedUnits: IUnitBooking[];
}

export const getPreviewEditTotalPrice = (
  previewUnits: ICustomerBookedUnits[],
) => {
  const totalPrice: number[] = [];
  for (let i = 0; i < previewUnits.length; ++i) {
    const venueUnit = previewUnits[i];

    if (venueUnit.totalPriceForDay) {
      totalPrice.push(venueUnit.totalPriceForDay);
      continue;
    }

    if (venueUnit?.foodAndBeverage?.length) {
      for (let y = 0; y < venueUnit?.foodAndBeverage.length; ++y) {
        const extra = venueUnit?.foodAndBeverage[y];

        if (extra && 'totalPrice' in extra) {
          totalPrice.push(extra.totalPrice as unknown as number);
        }
      }
    }

    for (let q = 0; q < venueUnit.unitBookings.length; ++q) {
      const unit = venueUnit.unitBookings[q];

      if (unit?.unitPrice) {
        totalPrice.push(unit?.unitPrice);
      }

      if (unit?.packagePrice && unit?.participants) {
        totalPrice.push(unit?.packagePrice * unit?.participants);
      }

      if (unit?.chosenExtras) {
        for (let y = 0; y < unit?.chosenExtras.length; ++y) {
          const extra = unit?.chosenExtras[y];

          if (extra?.totalPrice) {
            totalPrice.push(extra.totalPrice);
          }
        }
      }
    }
  }
  return totalPrice.reduce((acc: number, item: number) => acc + item, 0);
};

const getNoBookedExtras = (
  availableExtrasByBookable: IExtrasResponse[],
  bookedExtras: IBookedExtra[],
) =>
  availableExtrasByBookable
    ?.filter(
      (extra) =>
        !bookedExtras?.some(
          ({accommodationExtraId}) =>
            accommodationExtraId === extra.accommodationExtraId,
        ) &&
        (extra.price !== 0 || extra.priceType === EPriceType.FREE),
    )
    .map((extra) => ({
      ...extra,
      bookedQuantity: 0,
      extraId: extra.accommodationExtraId || extra.id,
      extraName: extra.name,
    })) || [];

export const getUnitInfoState = (
  currentUnitBookingId: string,
  customerBookedUnitsToReCalculate: IOrderDay[],
  availableExtrasByBookable: IExtrasResponse[],
  unit?: ISummaryUnit,
) => {
  let currentUnit: IUnitBooking | undefined;

  for (const orderDay of customerBookedUnitsToReCalculate) {
    currentUnit = (orderDay.unitBookings as IUnitBooking[]).find(
      (unit) => unit.unitBookingId === currentUnitBookingId,
    );
    if (currentUnit) {
      break;
    }
  }

  if (!currentUnit && unit) {
    currentUnit = cloneDeep(unit);
  }

  const noBookedExtras = getNoBookedExtras(
    availableExtrasByBookable,
    currentUnit?.bookedExtras as IBookedExtra[],
  );

  const allExtras = currentUnit?.bookedExtras
    ? [...currentUnit.bookedExtras, ...noBookedExtras]
    : noBookedExtras;

  const allFilteredExtras = allExtras.filter(
    (extra) => extra.extraType === EResourcesType.EQUIPMENT,
  );

  const convertedExtrasData: IExtraState[] =
    getExtrasForRequest(allFilteredExtras);

  const {checkInDate, checkOutDate, setupStyle, participants} =
    currentUnit || {};

  return {
    checkInDate,
    checkOutDate,
    setupStyle,
    participants,
    equipments: convertedExtrasData,
  };
};

export const getFoodAndBeverageState = (
  dayIndex: number,
  customerBookedUnitsToReCalculate: IOrderDay[],
  availableExtrasByBookable: IExtrasResponse[],
): IExtraState[] => {
  const currentDay = customerBookedUnitsToReCalculate[dayIndex];

  const noBookedExtras = getNoBookedExtras(
    availableExtrasByBookable,
    currentDay.foodAndBeverage as IBookedExtra[],
  );

  const allFilteredExtras = (
    currentDay?.foodAndBeverage?.length
      ? [...currentDay.foodAndBeverage, ...noBookedExtras]
      : noBookedExtras
  ).filter((extra) => extra.extraType === EResourcesType.FOOD_AND_BEVERAGE);

  return getExtrasForRequest(allFilteredExtras);
};

export const getUpdatedExtras = (
  state: IExtraState[],
  accommodationExtraId: number,
  quantityValue: number,
) =>
  state.reduce((prev, curr) => {
    return curr.accommodationExtraId === accommodationExtraId
      ? [...prev, {...curr, bookedQuantity: quantityValue}]
      : [...prev, curr];
  }, [] as IExtraState[]);

export const getExtrasForRequest = (extras?: Partial<IBookedExtra>[]) =>
  extras?.map(({extraId, accommodationExtraId, bookedQuantity}) => ({
    accommodationExtraId: accommodationExtraId || extraId,
    bookedQuantity,
  })) || [];

export const makeRequestCalculationDataForEquipments = (
  customerBookedUnitsToReCalculate: IOrderDay[],
  unitBookingIdToChange: string,
  extrasState: IExtraState[],
  customerSelectedPackages: TSelectedPackageItem[][],
  unitInfoState?: IUnitInfoState,
) =>
  customerBookedUnitsToReCalculate.map(
    (
      {
        foodAndBeverage,
        unitBookings,
        bedrooms,
        eventType,
        checkInDate,
        checkOutDate,
      },
      dayIndex,
    ) => {
      const isPrePostEvent =
        eventType === EEventType.PRE_ARRIVAL ||
        eventType === EEventType.POST_EVENT;

      const eventDates = isPrePostEvent ? {checkInDate, checkOutDate} : {};
      const units = unitBookings.map(
        ({
          bookedExtras,
          unitBookingId,
          unitId,
          checkInDate,
          checkOutDate,
          participants,
          setupStyle,
          totalPrice,
          unitPrice,
          packageId,
          maxParticipants,
        }) => {
          const type = unitInfoState
            ? EResourcesType.FOOD_AND_BEVERAGE
            : EResourcesType.EQUIPMENT;

          const filteredBookedExtrasByExtraType = bookedExtras?.filter(
            (extra) => extra.extraType === type,
          );

          const otherExtras = getExtrasForRequest(
            filteredBookedExtrasByExtraType,
          );

          const extrasFromState = unitInfoState
            ? unitInfoState.equipments
            : extrasState;

          const filteredExtrasFromState = extrasFromState.filter(
            ({bookedQuantity}) => !!bookedQuantity,
          );

          const changedExtras = [...filteredExtrasFromState, ...otherExtras];

          const updatedPackageId = customerSelectedPackages.length
            ? customerSelectedPackages?.[dayIndex]?.find(
                ([packageUnitId]) => packageUnitId === unitId,
              )?.[1]?.id || packageId
            : packageId;

          const convertedUnit = {
            unitBookingId,
            unitId,
            checkInDate,
            checkOutDate,
            participants,
            setupStyle,
            totalPrice,
            unitPrice,
            maxParticipants,
            unitQuantity: 1,
            bookedExtras: getExtrasForRequest(bookedExtras),
            packageId: updatedPackageId,
          };

          const changedUnit = unitInfoState
            ? {
                ...convertedUnit,
                checkInDate: unitInfoState.checkInDate,
                checkOutDate: unitInfoState.checkOutDate,
                participants: unitInfoState.participants,
                setupStyle: unitInfoState.setupStyle,
                bookedExtras: changedExtras,
              }
            : {
                ...convertedUnit,
                bookedExtras: changedExtras,
              };

          return unitBookingId !== unitBookingIdToChange
            ? convertedUnit
            : changedUnit;
        },
      );

      return {
        units,
        foodAndBeverage: foodAndBeverage,
        bedrooms: bedrooms,
        ...eventDates,
      };
    },
  );

export const makeRequestCalculationDataForExtra = (
  customerBookedUnitsToReCalculate: IOrderDay[],
  dayIndex: number,
  updatedExtra: ISummaryExtra[],
  extraType: 'foodAndBeverage' | 'bedrooms',
): IRecalculationRequest[] =>
  customerBookedUnitsToReCalculate.map((day, index) => {
    const {
      unitBookings,
      foodAndBeverage,
      bedrooms,
      eventType,
      checkInDate,
      checkOutDate,
    } = day;

    const isPrePostEvent =
      eventType === EEventType.PRE_ARRIVAL ||
      eventType === EEventType.POST_EVENT;
    const extras = {
      foodAndBeverage,
      bedrooms,
    };
    const eventDates = isPrePostEvent
      ? {checkInDate, checkOutDate}
      : EMPTY_OBJECT;

    const notChangedExtrasType =
      extraType === 'foodAndBeverage' ? 'bedrooms' : 'foodAndBeverage';

    const isCurrentDay = index === dayIndex;

    if (isCurrentDay) {
      return {
        units: unitBookings,
        [extraType]:
          extraType === 'bedrooms'
            ? updatedExtra
            : updatedExtra.filter(
                ({bookedQuantity}) => bookedQuantity && bookedQuantity > 0,
              ),
        [notChangedExtrasType]: extras[notChangedExtrasType],
        ...eventDates,
      };
    }

    return {
      units: unitBookings,
      [extraType]: extras[extraType],
      [notChangedExtrasType]: extras[notChangedExtrasType],
      ...eventDates,
    };
  });

export const checkIsTimeRangeChanged = (
  checkInDateA: string,
  checkOutDateA: string,
  checkInDateB: string,
  checkOutDateB: string,
) => {
  const getDateFromISO = (date: string) =>
    DateTime.fromISO(date, {
      setZone: true,
    });

  const checkInDateFromISO = getDateFromISO(checkInDateA);
  const checkOutDateFromISO = getDateFromISO(checkOutDateA);
  const currentCheckInDateValue = getDateFromISO(checkInDateB);
  const currentCheckOutDateValue = getDateFromISO(checkOutDateB);

  const isCheckInHourChanged =
    checkInDateFromISO.hour !== currentCheckInDateValue.hour;
  const isCheckOutHourChanged =
    checkOutDateFromISO.hour !== currentCheckOutDateValue.hour;

  return isCheckInHourChanged || isCheckOutHourChanged;
};

export const getArrowIconByComparing = (
  a: number | null | undefined,
  b: number | null | undefined,
) => (a && b ? (a > b ? 'NW2_ARROW_DOWN_THIN' : 'NW2_ARROW_UP_THIN') : '');

export const compareUnitInfoWithOrder = (
  unitPrice: number | undefined,
  participants: number | null | undefined,
  unitCheckInDate: string,
  unitCheckOutDate: string,
  currentUnitFromOrder: IUnitBooking | IUnitBookingDetails,
) => {
  return {
    isUnitPriceHighlighted: currentUnitFromOrder.unitPrice !== unitPrice,
    isParticipantsHighlighted:
      currentUnitFromOrder.participants !== participants,
    isTimeRangeHighlighted: checkIsTimeRangeChanged(
      unitCheckInDate,
      unitCheckOutDate,
      currentUnitFromOrder.checkInDate,
      currentUnitFromOrder.checkOutDate,
    ),
  };
};

export const getCurrentUnit = (
  units: IUnitBooking[],
  unitId: number | undefined,
) =>
  units?.find(
    (unit: IUnitBookingDetails | IUnitBooking) => unit.unitId === unitId,
  );

export const compareExtraWithOrder = (
  accommodationExtraId: number | undefined,
  chosenQuantity: number | undefined,
  totalPrice: number | undefined,
  bookedExtras: Partial<IBookedExtra>[] | undefined,
  extrasList: ISummaryExtra[],
  editMode: boolean,
) => {
  const currentExtraFromOrder = bookedExtras?.find(
    (extra) => extra.accommodationExtraId === accommodationExtraId,
  );

  if (!editMode || (!bookedExtras && !currentExtraFromOrder))
    return {isHighlighted: false, iconArrow: ''};

  const isNewAddedExtraPresent =
    bookedExtras &&
    !currentExtraFromOrder &&
    bookedExtras.length < extrasList.length;

  if (isNewAddedExtraPresent)
    return {
      isHighlighted: true,
      iconArrow: 'NW2_ARROW_UP_THIN',
    };

  const isQuantitySame =
    currentExtraFromOrder?.bookedQuantity === chosenQuantity;

  const isTotalPriceSame = currentExtraFromOrder?.totalPrice === totalPrice;

  const isHighlighted = !isQuantitySame || !isTotalPriceSame;

  const isTotalPriceDecrease =
    totalPrice &&
    currentExtraFromOrder?.totalPrice &&
    currentExtraFromOrder.totalPrice > totalPrice;

  const isQuantityDecrease =
    (chosenQuantity || chosenQuantity === 0) &&
    currentExtraFromOrder?.bookedQuantity &&
    currentExtraFromOrder.bookedQuantity > chosenQuantity;

  const iconArrow =
    isTotalPriceDecrease || isQuantityDecrease
      ? 'NW2_ARROW_DOWN_THIN'
      : 'NW2_ARROW_UP_THIN';

  return {
    isHighlighted,
    iconArrow,
  };
};

export const getBillingAddressDto = <T extends Record<string, any>>(
  values: T,
) => {
  const {
    companyName,
    streetHouseNumber,
    postCode,
    city,
    country,
    costCenter,
    additionalReference,
    uuid = '',
    customerId,
    isDefault,
    isUsed,
  } = values;

  return {
    additionalReference,
    city,
    companyName,
    costCenter,
    country,
    postCode,
    streetHouseNumber,
    uuid,
    customerId,
    isDefault,
    isUsed,
  };
};
