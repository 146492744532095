import styled from 'styled-components';

import {
  fontSizeMd,
  fontSizeSm,
  fontWeightExtraBold,
  lineHeightLg,
  offsetDef,
  offsetXLg,
  offsetXSm,
  offsetXXLg,
  offsetXXSm,
  offsetXXXXXLg,
} from 'constants/styleVars';

export const Wrapper = styled.div`
  padding: ${offsetXLg};
`;

export const Text = styled.p`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  margin-bottom: ${offsetDef};
`;

export const Actions = styled.div`
  margin-top: ${offsetXXLg};
`;

const TITLE_LINE_HEIGHT = '32px';
const CONTAINER_WIDTH = '443px';

export const Container = styled.div`
  width: ${CONTAINER_WIDTH};
`;

export const Title = styled.h1`
  font-size: ${offsetXLg};
  line-height: ${TITLE_LINE_HEIGHT};
  font-weight: ${fontWeightExtraBold};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-bottom: ${offsetXXXXXLg};
`;

export const FormActions = styled.div`
  display: flex;
  padding-top: ${offsetXXLg};
  gap: ${offsetXLg};
`;

export const ColumnWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const SubTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: ${fontSizeSm};
  text-transform: uppercase;
  margin-bottom: ${offsetDef};
  font-weight: ${fontWeightExtraBold};
`;

export const InfoRow = styled.div`
  font-size: ${fontSizeMd};

  &:not(&:last-of-type) {
    margin-bottom: ${offsetXSm};
  }
`;

export const TwoColumnInfoRow = styled(InfoRow)`
  display: grid;
  grid-template-columns: 1fr 1.6fr;
`;

export const GridBox = styled.div<{columnNumber: number; gap?: number}>`
  display: grid;
  grid-template-columns: repeat(${({columnNumber}) => columnNumber}, 1fr);
  ${({gap}) => (gap ? `grid-gap: ${gap}px` : '')};
`;

export const PaymentProcessContainer = styled.div`
  margin-bottom: ${offsetXXXXXLg};
  margin-top: 100px;
`;

export const PaymentProcessSection = styled.div`
  font-size: ${fontSizeMd};
  margin-bottom: ${offsetDef};

  & > ul > li:not(li:last-of-type) {
    margin-bottom: ${offsetXXSm};
  }
`;

export const PaymentProcessSubtitle = styled(SubTitle)`
  margin-bottom: ${offsetXSm};
`;
