import {IDropdownListOption} from 'view/components/NW2FormItem/types';

export const setSelectOptions = <T>(
  options: T[],
  {key, value}: {key: keyof T; value: keyof T},
) => {
  return options.map((item) => ({
    key: item[key],
    value: item[value],
    text: item[value],
  })) as unknown as IDropdownListOption[];
};
