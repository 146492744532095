import _toLower from 'lodash/toLower';
import _startCase from 'lodash/startCase';
import getSymbolFromCurrency from 'currency-symbol-map';
import {DateTime} from 'luxon';

import {ILocation} from 'types/dto/ILocation.types';
import {ICustomerBillingAddress} from 'types/dto/IUser.types';
import {IVenueLegalContact} from 'types/venue';
import {getISOString} from './dateUtils';

export const getAddressStringFromLocation = (
  location?: ILocation | IVenueLegalContact,
  skipFloor = true,
): string => {
  if (!location) return '';
  const {
    buildingNumber = '',
    city = '',
    country = '',
    floors,
    postalCode = '',
    streetName = '',
    region = '',
  } = location;
  const hasFloor = !!floors?.length;
  const shouldShowFloor = !skipFloor && hasFloor;
  return (
    `${buildingNumber ? buildingNumber : ''} ${
      streetName ? streetName + ',' : ''
    } ${city ? city + ',' : ''} ${region ? region + ',' : ''} ${
      country ? country : ''
    } ${postalCode ? postalCode : ''}${
      shouldShowFloor ? ', floor ' + floors : ''
    }`.trim() || '-'
  );
};

export const getAddressStringFromBillingAddress = (
  billingAddress: ICustomerBillingAddress | null,
): string | undefined => {
  if (billingAddress) {
    const {
      companyName = '',
      streetHouseNumber = '',
      postCode = '',
      city = '',
      country = '',
    } = billingAddress;
    return (
      `${
        companyName ? companyName + ',' : ''
      } ${streetHouseNumber}, ${postCode}, ${city}, ${country}`.trim() || '-'
    );
  }
};

export const getCurrencySymbol = (currency: string): string =>
  getSymbolFromCurrency(currency) || '';

export const addLeadingZeroToNumber = (value?: string | number) => {
  const valAsString = `${value}`;
  return !!valAsString && valAsString.length === 1 ? `0${value}` : value;
};

export const encodePlusCharacterInEmail = (email: string) => {
  return email.replaceAll('+', encodeURIComponent('+'));
};

export const removeNonNumberCharacters = (value: string): string => {
  return value.replace(/[^\d]/g, '');
};

export const trimPhoneNumber = (value = ''): string => {
  // return value.replace(/(?<!^)\+|[^\d+]+/g, ''); // safari does not support lookbehinds!
  const hasLeadingPlus = value.charAt(0) === '+';
  const trimmedNumbers = value.replace(/[^\d]+/g, '');
  return hasLeadingPlus ? `+${trimmedNumbers}` : trimmedNumbers;
};

export const capitalizeText = (str: string): string =>
  _startCase(_toLower(str));

export const truncateSimpleText = (
  str: string,
  maxlength: number,
  truncationSymbol = '...',
) =>
  str.length > maxlength ? str.slice(0, maxlength) + truncationSymbol : str;

function isValidDate(d: any) {
  if (isNaN(d)) return false;

  return d instanceof Date;
}

export const formatDate = (
  value: any,
  pattern: string,
  locales = 'default',
): string => {
  if (!value) return '-';
  const date = new Date(value);

  if (!isValidDate(date)) {
    return '';
  }

  const day = date.toLocaleString(locales, {day: 'numeric'});
  const month = date.toLocaleString(locales, {month: 'short'});
  const year = date.toLocaleString(locales, {year: 'numeric'});
  const dateTimeZone = DateTime.fromISO(value).offsetNameShort;

  switch (pattern) {
    case 'MMM':
      return month;

    case 'DDD': {
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      return days[date.getDay()];
    }

    case 'd MMM, YYYY':
      return day + ' ' + month + ', ' + year;

    case 'd MMM, YY':
      return day + ' ' + month + ', ' + year.substring(2);

    case 'DD/MM':
      return `${day}/${date.toLocaleString(locales, {month: '2-digit'})}`;

    case 'DD/MM/YY':
      return `${date.toLocaleString(locales, {
        day: '2-digit',
      })}/${date.toLocaleString(locales, {
        month: '2-digit',
      })}/${date.toLocaleString(locales, {year: '2-digit'})}`;

    case 'hh:mm':
      return DateTime.fromISO(value, {setZone: true}).toLocaleString(
        DateTime.TIME_SIMPLE,
      );

    case 'dd MM':
      return `${day} ${month}`;

    case 'DD/MM/YY at hh:mm (timeZone)':
      return `${date.toLocaleString(locales, {
        day: '2-digit',
      })}/${date.toLocaleString(locales, {
        month: '2-digit',
      })}/${date.toLocaleString(locales, {
        year: '2-digit',
      })} at ${date.toLocaleString(locales, {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      })} (${dateTimeZone})`;

    default:
      return '';
  }
};

export const getDateStringFromTime = (time: string, date: string): string => {
  if (!time || !date) {
    return getISOString(DateTime.now());
  }
  const timeObject = DateTime.fromISO(time);
  const dateObject = DateTime.fromISO(date);
  const newDate = dateObject.set({
    hour: timeObject.hour,
    minute: timeObject.minute,
    second: timeObject.second,
    millisecond: timeObject.millisecond,
  });
  return getISOString(newDate);
};

export const getCancellationPolicyStrings = (data: string) => {
  if (data.includes('<b>')) {
    const mainText = data.substring(0, data.indexOf('<'));
    const extraText = data.slice(data.indexOf('>') + 1, data.lastIndexOf('<'));
    return {mainText, extraText};
  }
  return {mainText: data, extraText: ''};
};

// remove extension from file name
export const removeFileExtension = (str: string) =>
  str.replace(/\.[^/.]+$/, '');

export const matchNumberToSecondDecimal = (value: string | number) =>
  String(value).match(/^-?\d+(?:\.\d{0,2})?/)?.[0];

export const substringBetween = (input: string, start: string, end: string) => {
  if (input.includes(start)) {
    const startIndex = input.indexOf(start) + start.length;
    if (input.includes(end, startIndex)) {
      const endIndex = input.indexOf(end, startIndex);
      return input.substring(startIndex, endIndex);
    }
  }
  return '';
};

export const checkBoolean = <T extends string | null>(val: T) =>
  val?.toString().toLowerCase() === 'true';
