import {DateTime} from 'luxon';
import _uniqBy from 'lodash/uniqBy';

import DateUtils from 'utils/dateUtils';

import {
  EBookableWith,
  EPriceType,
  EResourcesCode,
  EResourcesType,
  IExtrasOption,
  IExtrasResponse,
} from 'types/dto/IExtras.type';
import {
  EOfferStatus,
  ERequestDetailsType,
  ERequestStatus,
  IOfferDay,
  IOfferRequestDay,
  IOfferRequestUnit,
  IOfferUnit,
  IOfferUnitExtra,
  IRequestDay,
  IRequestDayItem,
} from 'types/offer';
import {TDay, TSearchVenuesDay} from 'types/search';
import {
  getDateDay,
  getDays,
} from 'view/supplier/Bookings/BookingDetails/helpers';
import {EEventType, ERoomSchemaNames} from 'types/venue';
import {IOrderDay, TGroupedUnitsByDay} from 'types/dto/IBooking.types';
import {getFoodAndBeverageQuantity} from '../../components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/components/AddFoodBeverageRequest/helpers';
import {getFilteredUnitsByEventType} from 'utils/venueUtils';

export const convertOfferItemsExtrasToVenueExtras = (
  offerExtras: IOfferUnitExtra[],
  extrasOption: IExtrasOption[],
) => {
  return extrasOption
    .filter((extraOption) =>
      offerExtras.some((offerExtra) => offerExtra.code === extraOption.code),
    )
    .map((item): IExtrasResponse => {
      const equipmentItem = item.type === EResourcesType.EQUIPMENT;
      return {
        id: item.id,
        name: item.name,
        accommodationExtraId: item.id,
        extraType: item.type,
        code: item.code,
        description: '',
        bookableWith: [EBookableWith.MEETING_ROOM, EBookableWith.WORK_SPACE],
        priceType: equipmentItem ? EPriceType.PER_ITEM : EPriceType.PER_PERSON,
        price:
          offerExtras.find(
            (offerExtra) => offerExtra.accommodationExtraId === item.id,
          )?.totalPrice?.value || 0,
        isLimited: false,
        isEnabled: true,
        totalPrice: 0,
      };
    });
};

// export const convertExtraResponseToPreviewExtra = (
//   extrasResponse: IExtrasResponse[],
// ): IPreviewExtra[] => {
//   return extrasResponse.map((extra) => ({
//     extraId: extra.extraId,
//     accommodationExtraId: extra.accommodationExtraId,
//     totalPrice: extra.totalPrice,
//     chosenQuantity: extra.quantity || 0,
//     bookedQuantity: extra.quantity,
//     extraType: extra.extraType,
//     name: extra.name,
//     priceType: extra.priceType,
//     price: extra.price,
//     code: extra.code,
//   }));
// };

export const convertOfferExtrasToVenueExtras = (extras: IExtrasOption[]) =>
  extras.map(
    (item): IExtrasResponse => ({
      id: item.id,
      name: item.name,
      accommodationExtraId: item.id,
      extraType: item.type,
      code: item.code,
      description: '',
      bookableWith: [EBookableWith.MEETING_ROOM, EBookableWith.WORK_SPACE],
      priceType: EPriceType.PER_UNIT,
      price: 1, // availableExtras?.find(({code}) => code === item.code)?.price || 0, // todo we should keep 1 for split logic between editable & not extras list
      isLimited: false,
      isEnabled: true,
      totalPrice: 0,
    }),
  );

// export const getMinMaxDateFromUnits = (
//   units: IRequestDay[],
//   attr: 'min' | 'max',
// ) =>
//   new Date(
//     Math[attr](
//       ...units.map(
//         ({checkIn, checkOut}): number =>
//           +new Date(
//             DateUtils.normalizeDateToBackendFormat(
//               attr === 'min' ? checkIn : checkOut,
//             ),
//           ),
//       ),
//     ),
//   );

export const checkOfferRequestRedirectStatus = (status: ERequestStatus) => {
  return [
    ERequestStatus.REQUEST_DECLINED,
    ERequestStatus.REQUEST_EXPIRED,
    ERequestStatus.REQUEST_CANCELED,
  ].includes(status);
};

export const checkExpirationForStatuses = (
  status: ERequestStatus | EOfferStatus,
) =>
  [ERequestStatus.REQUEST_PENDING, EOfferStatus.OFFER_PENDING].includes(status);

// export const getTimeLeft = (
//   respondDay: DateTime,
//   timePeriod: 'hours' | 'days',
// ) => respondDay.diffNow(timePeriod).as(timePeriod);

// export const convertArrivalItemsToSummaryItems = (
//   items: TSearchCriteriaRoom[],
//   allExtrasOption: IExtrasOption[],
// ): IOfferRequestDay[] =>
//   items.map(({checkIn, checkOut, eventType, extras}) => ({
//     checkIn,
//     checkOut,
//     eventType,
//     bedrooms: extras.map((extra) => ({
//       extraId: Number(extra.extraId),
//       extraType: extra.extraType,
//       quantity: extra.quantity,
//       code: allExtrasOption.find((option) => option.id === extra.extraId)
//         ?.code as EResourcesCode,
//     })),
//     foodAndBeverage: [],
//   }));

//TODO: add to tests
export const convertArrivalItemsToPayload = (items: TSearchVenuesDay[]) =>
  items.map(({checkIn, checkOut, eventType, bedrooms}) => ({
    checkIn,
    checkOut,
    eventType,
    bedrooms: bedrooms.map(({extraId, quantity, code}) => ({
      extraId: Number(extraId),
      quantity: quantity,
      code,
    })),
  }));

//TODO: add to tests
export const convertRequestArrivalItemsToSummaryItems = (
  days: (IRequestDay | IOfferDay)[],
  allExtrasOption: IExtrasOption[],
): IOfferRequestDay[] => {
  if (!days) return [];

  return days.map((day) => ({
    ...day,
    bedrooms: day.bedrooms.map((extra) => ({
      ...extra,
      bedrooms: allExtrasOption.find(
        (option) => option.id === extra.accommodationExtraId,
      )?.code as EResourcesCode,
    })),
  }));
};

export const getRequestUnitsFilteredByDay = (
  dates: string[],
  units: IOfferRequestUnit[],
) => {
  const days = getDays(dates);
  return days.reduce((acc: Record<string, IOfferUnit[]>, item) => {
    const arrOfUnits = units.filter(
      ({checkIn}) => getDateDay(checkIn) === item,
    );
    return {...acc, [item]: arrOfUnits};
  }, {});
};

const singleTypeMap = {
  // Request
  [ERequestStatus.REQUEST_DECLINED]: ERequestDetailsType.SINGLE_DECLINED,
  [ERequestStatus.REQUEST_CANCELED]: ERequestDetailsType.SINGLE_CANCELED,
  [ERequestStatus.REQUEST_EXPIRED]: ERequestDetailsType.SINGLE_EXPIRED,
  [ERequestStatus.REQUEST_PENDING]: ERequestDetailsType.SINGLE_PENDING,

  // Offer
  [EOfferStatus.OFFER_PENDING]: ERequestDetailsType.SINGLE_OFFER_PENDING,
  [EOfferStatus.OFFER_DECLINED]: ERequestDetailsType.SINGLE_OFFER_DECLINED,
  [EOfferStatus.OFFER_CONFIRMED]: ERequestDetailsType.SINGLE_OFFER_CONFIRMED,
  [EOfferStatus.OFFER_ACCEPTING_EXPIRED]:
    ERequestDetailsType.SINGLE_OFFER_ACCEPTING_EXPIRED,
};

const multiTypeMap = {
  // Request
  [ERequestStatus.REQUEST_DECLINED]: ERequestDetailsType.MULTI_DECLINED,
  [ERequestStatus.REQUEST_CANCELED]: ERequestDetailsType.MULTI_CANCELED,
  [ERequestStatus.REQUEST_EXPIRED]: ERequestDetailsType.MULTI_EXPIRED,
  [ERequestStatus.REQUEST_PENDING]: ERequestDetailsType.MULTI_PENDING,

  // Offer
  [EOfferStatus.OFFER_PENDING]: ERequestDetailsType.MULTI_OFFER_PENDING,
  [EOfferStatus.OFFER_DECLINED]: ERequestDetailsType.MULTI_OFFER_DECLINED,
  [EOfferStatus.OFFER_CONFIRMED]: ERequestDetailsType.MULTI_OFFER_CONFIRMED,
  [EOfferStatus.OFFER_ACCEPTING_EXPIRED]:
    ERequestDetailsType.MULTI_OFFER_ACCEPTING_EXPIRED,
};

export const getRequestDetailsType = (
  isSingle: boolean,
  status: ERequestStatus | EOfferStatus,
) => {
  const typeMap = isSingle ? singleTypeMap : multiTypeMap;
  return typeMap[status];
};

//TODO: add to tests
export const getDayItems = (days: IRequestDay[]) =>
  days?.length ? days.flatMap(({items}) => items ?? []) : [];

//TODO: add to tests
export const getPrice = (date: (IOfferRequestUnit | IOfferUnitExtra)[]) =>
  date.reduce((acc: number, item: IOfferRequestUnit | IOfferUnitExtra) => {
    if (item.totalPrice) {
      return acc + item.totalPrice.value;
    }
    return acc;
  }, 0);

export const getAllBedrooms = (days: IRequestDay[]) =>
  days?.length ? days.flatMap(({bedrooms}) => bedrooms) : [];

//TODO: add to tests
export const convertOrderDayToOfferRequestDay = (
  dayType: EEventType.POST_EVENT | EEventType.PRE_ARRIVAL,
  orderDays?: IOrderDay[],
) => {
  if (!orderDays) return [];

  const offerRequestDay: IOfferRequestDay[] = orderDays
    ?.filter(({eventType}) => eventType === dayType)
    .map(
      ({
        bedrooms,
        eventType,
        checkInDate,
        checkOutDate,
        orderId,
        foodAndBeverage,
      }) => ({
        eventType: eventType,
        foodAndBeverage: foodAndBeverage as IOfferUnitExtra[],
        bedrooms: bedrooms as IOfferUnitExtra[],
        checkIn: checkInDate,
        checkOut: checkOutDate,
        id: orderId,
      }),
    );

  return offerRequestDay;
};

//TODO: add to tests
export const checkExtrasData = (requestData: TDay[]) => ({
  hasEquipment: requestData
    .flatMap(({rooms}) => rooms)
    .some((item) => !!item.equipmentData?.ids.length),
  hasCatering: requestData.some((item) => !!item.foodBeverageData?.ids.length),
  hasBedrooms: requestData.some((item) => !!item.accommodationData?.ids.length),
});

interface IMakeOfferPreviewUnitsDataExtras extends IRequestDayItem {
  totalSearchPrice: number;
  unitFilter: {
    checkIn: string;
    checkOut: string;
    participants: number;
    setupStyle: ERoomSchemaNames;
    extras: IOfferUnitExtra[];
  };
}

interface IMakeOfferPreviewUnits {
  searchStartDate: string;
  searchEndDate: string;
  days: (IOfferDay | IRequestDay)[];
  extrasOption: IExtrasOption[];
}
//TODO: add to tests
export const makeOfferPreviewUnitsData = ({
  searchStartDate,
  searchEndDate,
  days,
  extrasOption,
}: IMakeOfferPreviewUnits) => {
  const items = getDayItems(days);

  const venueUnits = items.reduce<IMakeOfferPreviewUnitsDataExtras[]>(
    (acc, unit) => {
      const {checkIn, checkOut, setupStyle, participants, extras} = unit;
      const totalSearchPrice = unit.unitPrice?.value; // || 1;

      if (unit.id) {
        acc.push({
          ...unit,
          totalSearchPrice,
          unitFilter: {
            checkIn,
            checkOut,
            participants,
            setupStyle,
            extras,
          },
        });
      }

      return acc;
    },
    [],
  );

  const combinedExtras = _uniqBy(
    items.flatMap(({extras}) => extras),
    'code',
  );

  const availableExtras = convertOfferItemsExtrasToVenueExtras(
    combinedExtras,
    extrasOption,
  );

  // @ts-ignore
  const groupedUnitsByDay = venueUnits.reduce((prev, curr) => {
    const isSameDay = prev.some((el) =>
      DateUtils.isSameDay(el.checkInDate, curr.unitFilter.checkIn),
    );

    const currentDay = getFilteredUnitsByEventType(days).find(
      ({checkIn}) => checkIn === curr.unitFilter.checkIn,
    );

    const chosenExtras = availableExtras
      .filter(({code}) =>
        curr.unitFilter.extras.some((extra) => extra.code === code),
      )
      .map((extra) => {
        const isFoodAndBeverage = currentDay?.foodAndBeverage?.length;
        let foodAndBeverageQuantity = 1;
        if (isFoodAndBeverage) {
          const duration = DateUtils.getTotalHours(
            curr.unitFilter.checkIn,
            curr.unitFilter.checkOut,
          );
          foodAndBeverageQuantity = getFoodAndBeverageQuantity({
            extra,
            duration,
            participants: curr.unitFilter.participants,
          });
        }

        const exactExtra = curr.unitFilter.extras.find(
          (el) => el.code === extra.code,
        );
        const chosenQuantity =
          exactExtra?.quantity ||
          (isFoodAndBeverage && foodAndBeverageQuantity) ||
          1;
        const price = (exactExtra?.totalPrice?.value || 0) / chosenQuantity;

        return {
          ...extra,
          chosenQuantity,
          price,
        };
      });

    const checkInDate =
      curr.unitFilter.checkIn ||
      DateUtils.normalizeDateToBackendFormat(searchStartDate);
    const checkOutDate =
      curr.unitFilter.checkOut ||
      DateUtils.normalizeDateToBackendFormat(searchEndDate);

    const updatedUnit = {
      ...curr,
      unitId: curr.unitId || curr.id,
      checkInDate,
      checkOutDate,
      chosenSetupStyle: curr.unitFilter?.setupStyle || ERoomSchemaNames.BLOCK,
      unitCapacities: [],
      chosenExtras,
    };

    if (!prev.length || !isSameDay) {
      return [
        ...prev,
        {
          checkInDate,
          checkOutDate,
          bedrooms: currentDay?.bedrooms,
          units: [updatedUnit],
          unitBookings: [updatedUnit],
          foodAndBeverage: currentDay?.foodAndBeverage,
        },
      ];
    }

    return prev.map((el) => {
      if (!curr.unitFilter) return el;

      const isSameDay = DateUtils.isSameDay(
        el.checkInDate,
        curr.unitFilter.checkIn,
      );
      const isBefore =
        isSameDay &&
        DateUtils.isBefore(curr.unitFilter.checkIn, el.checkInDate);
      const isAfter =
        isSameDay &&
        DateUtils.isAfter(curr.unitFilter.checkOut, el.checkOutDate);

      const units = isSameDay ? [...el.units, updatedUnit] : el.units;

      return {
        ...el,
        checkInDate: isBefore ? curr.unitFilter.checkIn : el.checkInDate,
        checkOutDate: isAfter ? curr.unitFilter.checkOut : el.checkOutDate,
        units,
        unitBookings: units,
      };
    });
  }, [] as TGroupedUnitsByDay[]) as unknown as TGroupedUnitsByDay[];

  return groupedUnitsByDay.sort(
    (a, b) =>
      DateTime.fromISO(a.checkInDate).diff(
        DateTime.fromISO(b.checkInDate),
        'days',
      ).days,
  );
};
