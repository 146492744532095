import React from 'react';
import styled from 'styled-components';

import {offsetXXXLg} from 'constants/styleVars';
import {NW2Card} from 'view/components/NW2Card';
import Card1Img from 'img/card_1.jpg';
import Card2Img from 'img/card_2.jpg';
import Card3Img from 'img/card_3.jpg';

const Wrapper = styled.div`
  margin-top: ${offsetXXXLg};
`;

function CardsBlock() {
  return (
    <Wrapper>
      <NW2Card
        isPrimaryDecorationElement
        image={Card1Img}
        title='SOLUTION FOR WORK DESKS'
        subTitle={`The days of having to travel to your local office for work are over, instead,
         we provide flexibility and sustainability over typical brick and mortar office locations.`}
        bodyText={`We work closely with you and your company to provide an alternative that suits
         your personal needs and provides decarbonisation solution to the environment.
          Our intuitive search engine takes into the account your location of choice and matches
           it with various, HRS approved, co-working venues that supply plenty of desk spaces for you
            to work from comfortably. Our plentiful venue space providers worldwide quarantee
             reduced business costs and time typically spent travelling to local offices.`}
      />
      <NW2Card
        isGridReverse
        image={Card2Img}
        title='SOLUTION FOR MEETING ROOMS'
        subTitle={`Need a meeting room space for that important business call?
         Or you’d like to get together for a hybrid meeting for a workshop with your team? We gotcha.`}
        bodyText={`Our meeting room providers are there to help you and your team to get the business done whichever way
         suits best your team and your task. NewWork helps you to book a meeting room space within the venues where you
          already have a work desk booked or venture out to one of our hotel providers who can cater for larger get
           togethers with plentiful of extras to choose from making sure your team is well catered for.`}
      />
      <NW2Card
        isGrayDecorationElement
        image={Card3Img}
        title='SOLUTION FOR GROUP STAYS'
        subTitle={`No more lease agreements that tie businesses and their employees to a specific headquarters location.`}
        bodyText={`We work closely with hotels to supply you and your business with meeting spaces in an area that makes
         sense for all of your team members to travel to. By the use of our request based booking you are able to get
          multiple bids and choose the one that works for your budget, location, and travel needs. We can accommodate
           groups of almost any size and provide you with the number of meeting rooms and hotel rooms that are needed
            for the specific events you are planning for.`}
      />
    </Wrapper>
  );
}

export default CardsBlock;
