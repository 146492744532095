import {EPaymentAccountStatus} from 'types/venue';
// import {useSelector} from 'react-redux';
// import _get from 'lodash/get';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useSpaceStatus({isExternalVenue}: {isExternalVenue: boolean}) {
  // const payAccStatus = useSelector((state) =>
  //   _get(state, 'venue.venue.payAccStatus'),
  // );

  // const paymentStatus = isExternalVenue
  //   ? payAccStatus
  //   : EPaymentAccountStatus.ACTIVE;

  const paymentStatus = EPaymentAccountStatus.ACTIVE; // added ACTIVE by default due disabled payment

  return [paymentStatus];
}
