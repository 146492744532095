import styled from 'styled-components';

import {
  PlaceholderIcon,
  StyledInput,
} from 'view/components/NW2FormItem/NW2FormItem.styles';
import {offsetSm, offsetXXXLg} from 'constants/styleVars';

export const Placeholder = styled(StyledInput)<{isMultiple?: boolean}>`
  height: 100%;
  width: 100%;
  text-transform: capitalize;
  padding: ${offsetSm} ${offsetXXXLg} ${offsetSm} ${offsetSm};
  ${({isMultiple}) => isMultiple && 'cursor: pointer;'};
`;

export const StyledPlaceholderIcon = styled(PlaceholderIcon)<{
  isFocused?: boolean;
}>`
  transform: translateY(-50%)
    ${({isFocused}) => (isFocused ? 'rotate(0deg)' : 'rotate(180deg)')};
  transition: 0.25s;
`;
