import styled, {css} from 'styled-components';

import {
  blackColor,
  NW2Gray200Color,
  NW2Gray600Color,
  NW2Gray800Color,
  NW2Gray900Color,
  fontSizeXSm,
  fontSizeSm,
  fontSizeMd,
  fontSizeLg,
  fontSizeXLg,
  fontWeightNormal,
  fontWeightBold,
  fontWeightExtraBold,
  lineHeightSm,
  lineHeightMd,
  lineHeightLg,
  lineHeightXL,
  lineHeightXLg,
  lineHeightXXLg,
  offsetNone,
  offsetXXXSm,
  offsetXXSm,
  offsetXSm,
  offsetSm,
  offset14,
  offsetDef,
  offsetXLg,
  offsetXXLg,
  offsetXXXLg,
  offset80,
  fontSizeXXXLg,
  NW2ErrorLight500Color,
} from 'constants/styleVars';

const ANCHOR_MENU_WIDTH = '208px';

// Left col
export const Block = styled.div`
  margin-bottom: ${offsetXLg};
`;

export const InlineBlock = styled.div`
  display: inline-block;
`;

const text = css`
  font-weight: ${fontWeightNormal};
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  color: ${NW2Gray900Color};
  margin: ${offsetNone};
`;

export const SummaryTitle = styled.div`
  font-weight: ${fontWeightBold};
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
  color: ${NW2Gray600Color};
  margin-bottom: ${offsetXXXSm};
`;

export const SummaryText = styled.div`
  ${text};
`;

export const Text = styled.p`
  ${text};
`;

export const Line = styled.div`
  width: 140px;
  height: 1px;
  background: ${NW2Gray200Color};
  margin-bottom: ${offsetXLg};
`;

export const Link = styled.a`
  font-weight: ${fontWeightBold};
  font-size: ${fontSizeMd};
  line-height: ${offsetXLg};
  color: ${blackColor};
  text-decoration-line: underline;
`;

export const StyledLink = styled(Link)`
  display: block;
  margin: ${offset14} ${offsetNone} ${offsetXSm};
`;

// Right col

export const Container = styled.div`
  display: grid;
  grid-gap: ${offsetXLg};
  grid-template-columns: 1fr ${ANCHOR_MENU_WIDTH};
  margin-bottom: ${offsetXXLg};
`;

export const MainTitle = styled.div`
  font-weight: ${fontWeightExtraBold};
  font-size: ${offsetXLg};
  line-height: ${lineHeightXXLg};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-bottom: ${offsetXXLg};
`;
export const MainSubtitle = styled.div`
  font-size: ${offsetSm};
  margin-top: ${offsetXSm};
`;

export const Wrapper = styled.div`
  display: flex;
  gap: ${offsetXLg};
  margin-bottom: ${offsetXXLg};
  margin-top: ${offsetXXLg};
`;

export const DaysBlock = styled.div`
  min-width: 95px;
`;

export const DayTitle = styled.h3`
  font-weight: ${fontWeightExtraBold};
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin: 0 0 ${offsetXSm};
`;

export const DayContent = styled.span`
  font-weight: ${fontWeightNormal};
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
  color: ${NW2Gray900Color};
`;

export const UnitBlock = styled.div`
  border: 1px solid ${NW2Gray200Color};
  border-radius: ${offsetSm};
  width: 100%;
`;

export const UnitTitle = styled.p`
  font-weight: ${fontWeightBold};
  font-size: ${fontSizeLg};
  line-height: ${lineHeightXL};
  margin-bottom: ${offsetDef};
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ExtrasTitle = styled(UnitTitle)<{margin?: string}>`
  margin: ${({margin}) => margin || `${offsetXLg} 0 ${offsetDef}`};
`;

export const DatePeriod = styled.p<{margin?: string}>`
  font-size: ${fontSizeSm};
  font-weight: ${fontWeightNormal};
  line-height: ${lineHeightMd};
  color: ${NW2Gray900Color};
  margin-top: ${({margin}) => margin || ''};
`;

export const UnitItem = styled.div`
  padding: ${offsetXLg};
`;

export const StyledUnitItem = styled.div`
  padding: ${offsetXLg};
  font-size: ${fontSizeMd};
`;

export const UnitCard = styled.div`
  display: flex;
  gap: ${offsetXLg};
  margin-bottom: ${offsetDef};
`;

export const UnitName = styled.p<{
  isRequest?: boolean;
}>`
  font-weight: ${fontWeightBold};
  font-size: ${fontSizeMd};
  line-height: ${offsetXLg};
  text-decoration-line: ${({isRequest}) => (isRequest ? 'none' : 'underline')};
  color: ${NW2Gray900Color};
  margin-bottom: ${offsetXSm};
`;

export const Paragraph = styled.p`
  font-weight: ${fontWeightNormal};
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
  color: ${NW2Gray900Color};
  margin-bottom: ${offsetXXSm};
`;

export const Span = styled.span`
  font-weight: ${fontWeightBold};
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
  color: ${NW2Gray900Color};
`;

export const UnitPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${offsetXSm};
  font-size: ${fontSizeMd};
`;

export const ExtrasContainer = styled.div<{isPricePerRoom?: boolean}>`
  margin-top: ${({isPricePerRoom}) => (isPricePerRoom ? offsetXSm : '')};
`;

export const Extras = styled.div<{
  isQuantityVisible?: boolean;
  isRequest?: boolean;
  isBedroomMissing?: boolean;
  isPricePerRoom?: boolean;
  isRequestWithUnitId?: boolean;
}>`
  display: grid;
  grid-template-columns: ${({
    isQuantityVisible,
    isRequest,
    isBedroomMissing,
    isRequestWithUnitId,
  }) => {
    // Need such order
    if (!isQuantityVisible) return '1fr 80px';
    if (isRequestWithUnitId || isBedroomMissing) return '20px 12px 1fr 105px';
    if (isRequest) return '20px 12px 1fr';
    if (isQuantityVisible) return '20px 12px 1fr 105px';
    return '1fr 80px';
  }};
  grid-gap: ${offsetXXSm};
  margin-bottom: ${({isPricePerRoom}) =>
    isPricePerRoom ? `${offsetXXXSm}` : `${offsetXXSm}`};
  font-size: ${fontSizeMd};
`;

export const RentalText = styled.p<{isQuantityVisible?: boolean}>`
  font-weight: ${fontWeightNormal};
  font-size: ${fontSizeMd};
  line-height: ${offsetXLg};
  margin: ${offsetNone};
`;

export const ExtrasName = styled(RentalText)<{isQuantityVisible?: boolean}>`
  ${({isQuantityVisible}) =>
    !isQuantityVisible && `margin-left: ${offsetXXXLg}`};
`;

export const StyledSpan = styled.span`
  text-align: right;
`;

export const MissingSpan = styled.span`
  font-size: ${fontSizeMd};
  font-style: italic;
  font-weight: ${fontWeightNormal};
  line-height: ${lineHeightLg};
  color: ${NW2ErrorLight500Color};
  text-align: right;
`;

export const PricePerRoom = styled.div`
  font-size: ${fontSizeSm};
  font-weight: ${fontWeightNormal};
  line-height: ${lineHeightMd};
  color: ${NW2Gray600Color};
  text-align: end;
`;

export const UnitDayPrice = styled.div<{margin?: string}>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: ${({margin}) => margin || `0 ${offsetXLg} ${offsetXLg}`};
  border-top: 1px solid ${NW2Gray200Color};
  padding-top: ${offsetXLg};
`;

export const DaySubTotal = styled.p`
  font-weight: ${fontWeightBold};
  font-size: ${fontSizeLg};
  line-height: ${lineHeightXL};
  margin: ${offsetNone};
`;

export const DaySubTotalPrice = styled.span`
  font-weight: ${fontWeightBold};
  font-size: ${fontSizeLg};
  line-height: ${lineHeightXL};
`;

export const TotalSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TotalTitle = styled.h3`
  font-weight: ${fontWeightBold};
  font-size: ${fontSizeXLg};
  line-height: ${lineHeightXLg};
  margin: ${offsetNone};
`;

export const TotalPrice = styled.div`
  font-weight: ${fontWeightBold};
  font-size: ${offsetXLg};
  line-height: ${lineHeightXXLg};
  text-align: right;
  margin: ${offsetNone};
`;

export const FeesText = styled.span`
  font-weight: ${fontWeightNormal};
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  color: ${NW2Gray800Color};
`;

// After units
export const Section = styled.section`
  &:not(&:first-of-type) {
    margin-top: ${offset80};
  }
`;

export const SubTitle = styled.h4`
  font-weight: ${fontWeightBold};
  font-size: ${fontSizeXLg};
  line-height: ${lineHeightXLg};
  margin: 0 0 ${offsetDef};
`;

export const StyledSubTitle = styled(SubTitle)`
  margin-top: ${offsetDef};
`;

export const StyledText = styled(Text)`
  margin-bottom: ${offsetXSm};
`;

export const StyledTextes = styled(Text)`
  margin-bottom: ${offsetXXLg};
`;

export const BoldSpan = styled.span`
  font-weight: ${fontWeightBold};
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
`;

export const FooterWrapper = styled.div<{withPrice?: boolean}>`
  ${({withPrice}) =>
    withPrice
      ? `
        flex: 1;
        display: grid;
        grid-template-columns: 170px 1fr 252px;
        grid-column-gap: ${offsetXLg};
        align-items: center;`
      : `
        width: 100%;
        display: flex;
        justify-content: flex-end;`}
`;

export const FooterTotalTitle = styled.div`
  font-size: ${fontSizeLg};
  line-height: ${lineHeightXL};
  font-weight: ${fontWeightBold};
`;

export const FooterTotalSubTitle = styled.div`
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  color: ${NW2Gray600Color};
`;

export const FooterTotalPrice = styled.div`
  font-size: ${fontSizeXXXLg};
  line-height: ${lineHeightXXLg};
  font-weight: ${fontWeightBold};
`;
