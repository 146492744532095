import styled from 'styled-components';

import {
  borderRadiusLg,
  fontSizeXSm,
  fontWeightBold,
  lineHeightSm,
  NW2Gray200Color,
  NW2Gray50Color,
  NW2Gray600Color,
  offset14,
  offsetDef,
  offsetXLg,
  offsetXSm,
  NW2Blue50Color,
  NW2SuccessLight50Color,
} from 'constants/styleVars';

export const TableWrapper = styled.div`
  border: 1px solid ${NW2Gray200Color};
  border-radius: ${borderRadiusLg};
`;

export const Table = styled.table`
  width: 100%;
  border-radius: ${borderRadiusLg};
  border-collapse: collapse;
  overflow: hidden;
  position: relative;
`;

export const NoData = styled.div`
  position: absolute;
  bottom: ${offset14};
  left: 50%;
  transform: translateX(-50%);
`;

export const Tr = styled.tr<{hasHover?: boolean; isSelected?: boolean}>`
  border-top: 1px solid ${NW2Gray200Color};
  transition: background-color 0.15s;

  ${({isSelected}) =>
    isSelected &&
    `
    background-color: ${NW2SuccessLight50Color};
    `};

  ${({hasHover}) =>
    hasHover &&
    `
    &:hover {
      background-color: ${NW2Blue50Color};
      cursor: pointer;
    }
  `};
`;

export const TrHead = styled.tr`
  text-align: left;
`;

export const Td = styled.td<{
  hasHover?: boolean;
  isTablet: boolean | undefined;
  isStrikeThroughPrice?: boolean;
  isCustomer?: boolean | undefined;
  textAlign?: string;
}>`
  padding: ${offsetDef} ${offsetXSm};
  ${({textAlign}) => textAlign && `text-align: ${textAlign} !important;`}

  &:nth-of-type(1) {
    padding-left: ${offsetXLg};
    display: ${({isTablet}) => isTablet && 'none'};
  }

  &:nth-of-type(5) {
    display: ${({isTablet}) => isTablet && 'none'};
  }

  &:nth-of-type(6) {
    display: ${({isTablet}) => isTablet && 'none'};
    text-decoration: ${({isStrikeThroughPrice, isCustomer}) =>
      isStrikeThroughPrice && isCustomer && 'line-through solid #21272a'};
  }

  ${({hasHover}) =>
    hasHover &&
    `
    & * {
      cursor: pointer !important;
    }
  `};
`;

export const Th = styled.th<{
  isTablet: boolean | undefined;
  textAlign?: string;
}>`
  padding: 6px ${offsetXSm};
  ${({textAlign}) => textAlign && `text-align: ${textAlign} !important;`}

  &:nth-of-type(1) {
    padding-left: ${offsetXLg};
    display: ${({isTablet}) => isTablet && 'none'};
  }

  &:nth-of-type(5),
  :nth-of-type(6) {
    display: ${({isTablet}) => isTablet && 'none'};
  }
`;

export const THead = styled.thead`
  color: ${NW2Gray600Color};
  background: ${NW2Gray50Color};
  font-weight: ${fontWeightBold};
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  border-radius: ${borderRadiusLg} ${borderRadiusLg} 0 0;
`;
