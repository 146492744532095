import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {ERoomType} from 'types/dto/ERoomType.type';
import {
  EBookingOrders,
  EOrderShowStatus,
  IBookingChanges,
  ISearchOrder,
} from 'types/bookingOrders';
import {IBookingDetails} from 'types/bookingOrders';
import {IAmountOfBookings} from 'types/dto/IBookingType.type';
import {IRequestV2} from 'types/offer';

type TSupplierRecords = {
  totalRecords: number;
};

type TSupplierOrder = {
  [key in ERoomType]?: ISearchOrder[];
} & TSupplierRecords;

type TSupplierOrders = {
  [key in EBookingOrders]: TSupplierOrder;
};

type TBookingInitialState = {
  isLoading: boolean;
  error: any;
  bookingDetails: IBookingDetails | null;
  bookingRequestDetails: IRequestV2 | null;
  bookingChanges: IBookingChanges[];
  amountOfBookings: IAmountOfBookings;
  cancellationPolicy: string;
} & TSupplierOrders;

const initialOrder: TSupplierOrder = {
  [ERoomType.MEETING_ROOM]: [],
  [ERoomType.WORK_SPACE]: [],
  totalRecords: 0,
};

const initialState: TBookingInitialState = {
  [EBookingOrders.UPCOMING_ORDERS]: initialOrder,
  [EBookingOrders.RFP_ORDERS]: initialOrder,
  [EBookingOrders.ORDERS_HISTORY]: initialOrder,
  isLoading: false,
  error: null,
  bookingDetails: null,
  bookingRequestDetails: null,
  bookingChanges: [],
  amountOfBookings: {
    kpiList: {},
    searchTabsCount: {},
  },
  cancellationPolicy: '',
};

const bookingsSupplierSlice = createSlice({
  name: 'bookingsSupplier',
  initialState,
  reducers: {
    getSupplierOrdersLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },

    getSupplierOrdersError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.isLoading = false;
    },

    getSupplierOrdersSuccess(
      state,
      action: PayloadAction<{
        pageItems: ISearchOrder[];
        searchTab: EBookingOrders;
        spaceType: ERoomType;
        totalFilteredRecords: number;
      }>,
    ) {
      const {pageItems, searchTab, spaceType, totalFilteredRecords} =
        action.payload;

      const ordersData = state[searchTab];

      ordersData[spaceType] = pageItems;
      ordersData.totalRecords = totalFilteredRecords;
      state.isLoading = false;
    },

    setBookingOrderDetails: (
      state,
      action: PayloadAction<IBookingDetails | null>,
    ) => {
      state.bookingDetails = action.payload;
    },
    setBookingOrderShowStatus: (
      state,
      action: PayloadAction<EOrderShowStatus>,
    ) => {
      if (state.bookingDetails)
        state.bookingDetails.showStatus = action.payload;
    },

    setBookingOrderChanges: (
      state,
      action: PayloadAction<IBookingChanges[]>,
    ) => {
      state.bookingChanges = action.payload;
    },

    setSupplierAmountOfBookings(
      state,
      action: PayloadAction<IAmountOfBookings>,
    ) {
      state.amountOfBookings = action.payload;
    },

    setCancellationPolicy: (state, action: PayloadAction<string>) => {
      state.cancellationPolicy = action.payload;
    },

    setOrderStatusReadInUpcomingMeetingRoomTab: (state) => {
      state[EBookingOrders.UPCOMING_ORDERS][ERoomType.MEETING_ROOM] = state[
        EBookingOrders.UPCOMING_ORDERS
      ][ERoomType.MEETING_ROOM]?.map((order) =>
        order.orderNumber === state.bookingDetails?.orderNumber
          ? {...order, showStatus: EOrderShowStatus.READ}
          : order,
      );
    },
    removeConfirmedDeclinedRTC: (state) => {
      state[EBookingOrders.RFP_ORDERS][ERoomType.MEETING_ROOM] = state[
        EBookingOrders.RFP_ORDERS
      ][ERoomType.MEETING_ROOM]?.filter(
        (order) => order.orderNumber !== state.bookingDetails?.orderNumber,
      );
    },
  },
});

export const {
  getSupplierOrdersError,
  getSupplierOrdersLoading,
  getSupplierOrdersSuccess,
  setBookingOrderDetails,
  setBookingOrderChanges,
  setSupplierAmountOfBookings,
  setCancellationPolicy,
  setBookingOrderShowStatus,
  setOrderStatusReadInUpcomingMeetingRoomTab,
  removeConfirmedDeclinedRTC,
} = bookingsSupplierSlice.actions;

export default bookingsSupplierSlice.reducer;
