import React, {FC} from 'react';
import styled from 'styled-components';
import {Form} from 'react-final-form';

import {Link} from 'view/components/Typography';
import NW2Button from 'view/components/NW2Button/NW2Button';
import {NW2FormItemInput} from '../../components/NW2FormItem/NW2FormItem';
import SubmitErrorCard from '../../components/SubmitErrorCard/SubmitErrorCard';
import {FormGroup} from '../utils/auth.styles';

import {offsetDef} from '../../../constants/styleVars';
import {emailFieldRules, requiredFieldRules} from 'utils/finalFormFieldRules';
import {LoginUserData} from 'types/account';

const StyledLink = styled(Link)`
  margin-top: ${offsetDef};
  display: inline-block;
`;

interface ILoginDetails {
  isSubmitFailed: boolean;
  isLoading: boolean;
  isValidationEnabled?: boolean;
  openForgotPassword: () => void;
  loginInSystem: (arg0: LoginUserData) => void;
  onFinishFailed?: () => void;
}

const LoginDetails: FC<ILoginDetails> = ({
  isSubmitFailed,
  isLoading,
  openForgotPassword,
  loginInSystem,
}: ILoginDetails) => {
  const submitErrorText =
    'Your e-mail address and / or password do not match, please check your entries.';
  return (
    <>
      {isSubmitFailed && <SubmitErrorCard text={submitErrorText} />}
      <Form onSubmit={loginInSystem}>
        {({handleSubmit}) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <FormGroup columnNumber={1} gap={16}>
                <NW2FormItemInput
                  name='email'
                  type='email'
                  label='Email address'
                  placeholder='Email address'
                  rules={emailFieldRules}
                  highlightAsError={isSubmitFailed}
                  showAllValidationErrors
                />
                <NW2FormItemInput
                  name='password'
                  type='password'
                  label='Password'
                  placeholder='Password'
                  rules={requiredFieldRules('password')}
                  highlightAsError={isSubmitFailed}
                  showAllValidationErrors
                />
                <NW2Button
                  loading={isLoading}
                  type='submit'
                  buttonType='primary'
                  fullWidth
                >
                  {isLoading ? 'Loading...' : 'Log in'}
                </NW2Button>
              </FormGroup>
              <StyledLink onClick={openForgotPassword}>
                Forgot your password?
              </StyledLink>
            </form>
          );
        }}
      </Form>
    </>
  );
};

export default LoginDetails;
