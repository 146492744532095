import React from 'react';
import styled from 'styled-components';

import Icon from 'view/components/Icon';

import {fontSizeMd, fontSizeXSm} from 'styles/configs/typography';
import {offset14, offsetDef, offsetXLg} from 'styles/configs/offset';
import {borderRadiusDef, cardBoxShadow} from 'styles/configs/variables';
import {NW2ErrorLight500Color} from 'styles/configs/colors';

export const ErrorBanner = styled.div`
  font-size: ${fontSizeMd};
  padding: ${fontSizeXSm} ${offsetDef} ${fontSizeXSm} ${offset14};
  margin-bottom: ${offsetXLg};
  border-radius: ${borderRadiusDef};
  border-left: 4px solid ${NW2ErrorLight500Color};
  box-shadow: ${cardBoxShadow};
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${offsetDef};
  align-items: center;

  svg {
    color: ${NW2ErrorLight500Color};
  }
`;

interface IProps {
  errors: string[];
}

const NMMPayoutErrorList = ({errors}: IProps) => (
  <>
    {errors.map((item, index) => (
      <ErrorBanner key={index}>
        <Icon icon='DISABLE' />
        <span>{item}</span>
      </ErrorBanner>
    ))}
  </>
);

export default NMMPayoutErrorList;
